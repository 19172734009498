import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withParams } from '@kopapro/components/commons/router-hoc';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import UdfHtml from '@kopapro/components/shop/udfHtml/udfHtml';
import { getUdfHtmlTitle, getUdfHtmlContent } from '@kopapro-redux/selectors/entities/udfHtml';

interface IProps extends WithTranslation {
  params: any; // from withParams
}

interface StateProps {
  compId: string;
  title: I18nDictionary;
  udfHtml: I18nDictionary;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const compId = props.params['compId'];
  return {
    compId: compId,
    title: getUdfHtmlTitle(state, compId),
    udfHtml: getUdfHtmlContent(state, compId),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type UdfHtmlProps = IProps & StateProps & DispatchProps;
export default withParams(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UdfHtml)));
