import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import GiftRedeemModal from '@kopapro/components/cart/giftRedeemModal/giftRedeemModal';
import { GiftRedemptionData } from '@kopapro-redux/types/cart';

interface ModalProps {
  show: boolean;
  onSubmitHandler: Function;
  onCloseHandler: Function;
}

interface IProps extends WithTranslation, ModalProps {
  title: string;
  list?: GiftRedemptionData[];
  isGift?: boolean;
}

interface StateProps {}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type GiftRedeemModalProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(GiftRedeemModal));
