import { Component, ReactNode } from 'react';
import { BlogListExtProps } from '@kopapro/components/blog/list/list';
import { BlogDetail } from '@kopapro-redux/types/blog';
import { geti18nValue } from '@kopapro/utils/m18';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export class DefaultList extends Component<BlogListExtProps> {
  renderHashTag = (showHashTags: boolean, hashTags: string[]) => {
    if (!showHashTags) {
      return null;
    }
    return (
      <div>
        {hashTags.map((tag: string) => {
          return (
            <span key={`hashtag-${tag}`} className={`me-1`}>
              {'#' + tag}
            </span>
          );
        })}
      </div>
    );
  };

  renderAuthor = (showAuthor: boolean, author: string) => {
    if (!showAuthor) {
      return null;
    }
    return (
      <div className={`mb-1`}>
        <label>{author}</label>
      </div>
    );
  };

  renderDate = (showDate: boolean, lastModifyDate: number) => {
    const { t } = this.props;
    if (!showDate) {
      return null;
    }
    return (
      <div className={`mb-1 text-muted`}>
        <label>{t('{date, full}', { date: lastModifyDate })}</label>
      </div>
    );
  };

  renderItem = (item: BlogDetail): ReactNode => {
    const { showHashTags, showAuthor, showDate, t, openBlogDetail } = this.props;
    const { blogId, detailObj, author, lastModifyDate, hashTags, titleObj } = item;

    return (
      <Col key={`item-${blogId}`}>
        <Card className="item h-100">
          <Card.Body className="d-flex flex-column">
            <Card.Title>{geti18nValue(titleObj)}</Card.Title>
            <div className="content">
              {this.renderHashTag(showHashTags, hashTags)}
              {this.renderAuthor(showAuthor, author)}
              {this.renderDate(showDate, lastModifyDate)}

              <div className="border-top">
                <SafetyDOM html={geti18nValue(detailObj)} />
              </div>
            </div>
            <button
              className="btn btn-outline-main align-self-start mb-1 mt-3"
              onClick={() => {
                openBlogDetail(blogId);
              }}>
              {t(`ce01_pmpcore.react.readMore`)}
            </button>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  render() {
    const { dataList } = this.props;
    return (
      <Row xs={1} md={3} className="blog-list default g-4">
        {dataList.map((item: BlogDetail) => {
          return this.renderItem(item);
        })}
      </Row>
    );
  }
}
