import { Component, ReactNode } from 'react';
import { formatDisplayAmount, getCouponBalance, geti18nValue, resolvePaymentMethodImageURL } from '@kopapro/utils/m18';
import { PaymentProps } from '@kopapro/components/checkout/payment';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import InfoIcon from '@mui/icons-material/Info';
import ShopImage from '@kopapro/components/commons/shopImage';
import { checkoutConfig } from '@kopapro/utils/config';
import { CheckoutPaymentFormData } from '@kopapro-redux/types/checkout';
import { CouponVoucher } from '@kopapro-redux/types/coupon';
import { PaymentMethod, PaymentStoredValue } from '@kopapro-redux/types/paymentMethod';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import { AppImages } from '@kopapro/utils/constants/images';
import utils from '@kopapro-redux/utils/utils';
import classnames from 'classnames';
import CouponModal from '@kopapro/components/checkout/payment/couponModal';
import SpinnerButton from '@kopapro/components/commons/spinnerButton';
import Loading from '@kopapro/components/commons/loading';
import EventUtils from '@kopapro/utils/event';
interface PaymentState {
  formData: CheckoutPaymentFormData;
  selectedPaymentMethod: PaymentMethod | undefined;
  paymentSurcharge: number;
  coupons: CouponVoucher[];
  totalCouponBalance: number;
  sending: boolean;
  errorMessage: string;
  successMessage: string;
  showCouponModal: boolean;
  loadingCoupon: boolean;
}

export default class Payment extends Component<PaymentProps, PaymentState> {
  imageSize = checkoutConfig.payment.imageSize;

  defaultState = {
    formData: {
      paymentId: 0,
    },
    selectedPaymentMethod: undefined,
    paymentSurcharge: 0,
    coupons: [],
    totalCouponBalance: 0,
    sending: false,
    errorMessage: '',
    successMessage: '',
    showCouponModal: false,
    loadingCoupon: false,
  };

  constructor(props: PaymentProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount() {
    if (utils.isNotEmptyM18Id(this.props.paymentId)) {
      this.prepareInitialData();
    }

    if (this.props.userLoggedIn && !this.props.isAllCouponItems && this.props.amountDetails!.totalAmount > 0) {
      this.loadCouponData();
    }
  }

  componentDidUpdate(prevProps: PaymentProps) {
    if (utils.isEmptyList(prevProps.paymentMethods) && utils.isNotEmptyList(this.props.paymentMethods)) {
      this.prepareInitialData();
    }
  }

  prepareInitialData = () => {
    const { paymentId, paymentMethods } = this.props;
    if (utils.isNotEmptyM18Id(paymentId)) {
      const paymentMethod = paymentMethods.find((element) => element.m18Id === paymentId);
      if (paymentMethod) {
        this.checkPaymentSurcharge(paymentId);
      }
      this.setState({
        selectedPaymentMethod: paymentMethod,
        formData: { ...this.state.formData, paymentId },
      });
    }
  };

  loadCouponData = () => {
    const { loadCoupon } = this.props;
    const self = this;

    this.setState({ sending: true, loadingCoupon: true });

    loadCoupon({}, function (isSuccess: boolean, coupons: CouponVoucher[]) {
      if (isSuccess) {
        let totalCouponBalance: number = 0;
        for (let coupon of coupons) {
          totalCouponBalance += getCouponBalance(coupon);
        }
        coupons.sort((a, b) => {
          if (a.svExpDate !== b.svExpDate) {
            return a.svExpDate - b.svExpDate;
          }
          return a.svBalance - b.svBalance;
        });
        self.setState({ sending: false, loadingCoupon: false, totalCouponBalance, coupons });
      } else {
        self.setState({ sending: false, loadingCoupon: false, totalCouponBalance: 0, coupons: [] });
      }
    });
  };

  showCouponModal = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showCouponModal: true });
  };

  // handle coupon modal close
  handleCloseCoupon = () => {
    this.setState({ showCouponModal: false });
  };

  // handle form value change
  selectPaymentMethod = (method: PaymentMethod | undefined) => {
    const paymentId = method?.m18Id || 0;
    this.setState({
      selectedPaymentMethod: method,
      formData: { ...this.state.formData, paymentId },
    });
    this.checkPaymentSurcharge(paymentId);
  };

  checkPaymentSurcharge = (paymentId: number) => {
    const { checkSurcharge } = this.props;
    const self = this;

    checkSurcharge({ paymentId }, function (isSuccess: boolean, paymentSurcharge: number = 0) {
      self.setState({ paymentSurcharge });
    });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { t, savePaymentMethod, amountDetails, paymentMethods } = this.props;
    const self = this;
    event.preventDefault();
    event.stopPropagation();

    const { formData } = this.state;
    const { paymentId } = formData;
    const { outstandAmt, couponAmt } = amountDetails!;

    let errorMessage = '';

    if (utils.isEmptyM18Id(paymentId)) {
      if (outstandAmt <= 0) {
        EventUtils.addPaymentInfo('');
        this.props.componentNext();
        return;
      }

      if (couponAmt <= 0) {
        errorMessage = 'ce01_pmpcore.react.chooseOnePayment';
      } else {
        const outstandAmtMessage = formatDisplayAmount(outstandAmt);
        const couponAmtMessage = formatDisplayAmount(couponAmt);
        errorMessage = `${t('ce01_pmpcore.react.applySVInfoNon', {
          paidAmt: outstandAmtMessage,
          couponAmt: couponAmtMessage,
        })} ${t('ce01_pmpcore.react.chooseOnePayment')}`;
      }
    }

    if (!utils.isEmpty(errorMessage)) {
      this.setState({ errorMessage, successMessage: '' });
      return;
    }

    this.setState({ sending: true, errorMessage: '', successMessage: '' });
    const paymentMethod = paymentMethods.find((element) => element.m18Id === paymentId);
    // dispatch request
    savePaymentMethod({ formData }, function (isSuccess: boolean, message = '') {
      if (isSuccess) {
        EventUtils.addPaymentInfo(paymentMethod?.code);
        //self.setState({ sending: false, successMessage: 'ce01_pmpcore.react.submitSuccess' });
        self.props.componentNext();
      } else {
        self.setState({ sending: false, errorMessage: message });
      }
    });
  };

  // Components
  renderCouponItem = (item: PaymentStoredValue): ReactNode => {
    const { t } = this.props;
    const { amt } = item;
    const amountMessage = formatDisplayAmount(amt, true);

    const popover = (
      <Popover id="popover-basic">
        <Popover.Body>
          <div className="text-truncate">{`${geti18nValue(item.svDescObj)} (#${item.svfCode})`}</div>
          <div>{`${t('ce01_pmpcore.react.used')} ${amountMessage}`}</div>
        </Popover.Body>
      </Popover>
    );

    return (
      <OverlayTrigger
        key={`${item.svId}-${item.svfId}`}
        trigger={['hover', 'focus']}
        placement="bottom"
        overlay={popover}>
        <ShopImage
          className="me-2"
          src={item.photoCode}
          width={this.imageSize}
          height={this.imageSize}
          fallbackImage={AppImages.couponPlaceholder}
        />
      </OverlayTrigger>

      // <div className="mb-2 payment-method-coupon-item" key={`${item.svId}-${item.svfId}`}>
      //   <ShopImage
      //     src={item.photoCode}
      //     width={this.imageSize}
      //     height={this.imageSize}
      //     fallbackImage={AppImages.couponPlaceholder}
      //   />
      // </div>
    );
  };

  renderCoupon(): ReactNode {
    const { t, userLoggedIn, paymentCoupons } = this.props;
    const { loadingCoupon, totalCouponBalance: balance } = this.state;
    if (!userLoggedIn || (!loadingCoupon && balance <= 0)) {
      return null;
    }

    if (loadingCoupon) {
      return (
        <Loading
          messageCode="ce01_pmpcore.kopapro.react.loadingCoupon"
          replaceClass={true}
          className="d-flex align-items-center mb-3"
          small={true}
        />
      );
    }

    let balanceMessage = '';
    if (balance !== 0) {
      const amount = formatDisplayAmount(balance);
      balanceMessage = t('ce01_pmpcore.react.alertOpenSV', { amount: amount });
    }
    return (
      <Form.Group className="mb-3">
        <h6>{t('ce01_pmpcore.react.storedValue')}</h6>
        <p className="text-primary">
          <InfoIcon /> {balanceMessage}
        </p>
        <Button className="mb-3" variant="main" onClick={this.showCouponModal}>
          {t('ce01_pmpcore.react.applySV')}
        </Button>
        <div className="payment-method-coupon">
          {paymentCoupons.map((item) => {
            return this.renderCouponItem(item);
          })}
        </div>
      </Form.Group>
    );
  }

  renderPaymentMethodItem(item: PaymentMethod): ReactNode {
    const { sending, formData } = this.state;
    const { id, m18Id, desc } = item;
    const name = geti18nValue(desc);
    const active = m18Id === formData.paymentId;

    return (
      <div
        key={`payment-method-item-${id}`}
        className={classnames('item', { active: active, diabled: sending })}
        onClick={() => this.selectPaymentMethod(item)}>
        <ShopImage height={this.imageSize} alt="" src={resolvePaymentMethodImageURL(item)} />
        <h5 className="item-name text-truncate">{name}</h5>
      </div>
    );
  }

  renderPaymentMethod(): ReactNode {
    const { amountDetails } = this.props;
    const { outstandAmt } = amountDetails!;
    if (outstandAmt <= 0) {
      return null;
    }

    const { t, paymentMethods } = this.props;
    const { paymentSurcharge: surcharge, selectedPaymentMethod } = this.state;
    const policy = geti18nValue(selectedPaymentMethod?.paymPolicy);
    let paymentSurchargeMessage = '';
    if (surcharge !== 0) {
      const amount = formatDisplayAmount(surcharge);
      paymentSurchargeMessage = t('ce01_pmpcore.react.needPaymentSurcharge', { amount });
    }
    return (
      <Form.Group className="mb-3">
        <h6>{t('ce01_pmpcore.react.chooseOnePayment')}</h6>
        <div className="methods">
          {paymentMethods.map((item) => {
            return this.renderPaymentMethodItem(item);
          })}
        </div>
        <h6 className="text-danger mb-3">{paymentSurchargeMessage}</h6>
        <SafetyDOM html={policy} />
      </Form.Group>
    );
  }

  renderPayment(): ReactNode {
    const { t } = this.props;
    const { successMessage, errorMessage } = this.state;
    return (
      <Card>
        <Card.Body>
          <Card.Title>{t('ce01_pmpcore.kopapro.react.paymentInfo')}</Card.Title>
          <hr />
          <Form noValidate onSubmit={this.handleSubmit} autoComplete="off">
            {this.renderCoupon()}
            {this.renderPaymentMethod()}

            <ErrorMessage message={t(errorMessage)} />
            <SuccessMessage message={t(successMessage)} />

            {this.renderFooter()}
          </Form>
        </Card.Body>
      </Card>
    );
  }

  renderCouponModal(): ReactNode {
    const { paymentCoupons } = this.props;
    return (
      <CouponModal
        coupons={this.state.coupons}
        paymentCoupons={paymentCoupons}
        show={this.state.showCouponModal}
        onCloseHandler={this.handleCloseCoupon}
      />
    );
  }

  renderFooter(): ReactNode {
    const { t } = this.props;
    const { sending, loadingCoupon } = this.state;
    return (
      <div className="chectout-footer">
        <Button
          variant="outline-secondary"
          className="chectout-footer-btn"
          disabled={sending}
          onClick={() => this.props.componentPrevious()}>
          {t('ce01_pmpcore.react.backBtn')}
        </Button>
        <SpinnerButton
          disabled={loadingCoupon}
          spinning={sending}
          variant="main"
          type="submit"
          className="chectout-footer-btn">
          {t('ce01_pmpcore.react.next')}
        </SpinnerButton>
      </div>
    );
  }

  render(): ReactNode {
    return (
      <div className="payment">
        {this.renderPayment()}
        {this.renderCouponModal()}
      </div>
    );
  }
}
