import Utils from "@kopapro/utils/utils";
import React from "react";
import { Component, ReactNode } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WithTranslation } from "react-i18next";
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
interface ModalProps extends WithTranslation {
  title?: string | ReactNode;
  show: boolean;
  body: string | ReactNode;
  closeButtonText?: string;
  confirmButtonText?: string;
  onCloseHandler?: Function;
  onConfirmHandler?: Function;
  showConfirmButton?: boolean;
  showCloseButton?: boolean; // footer only
  isBackdrop?: boolean;
  centered?: boolean;
  scrollable?: boolean;
  modalSize?: 'sm' | 'lg' | 'xl' | undefined;
}

class DefaultModal extends Component<ModalProps> {
  handleShow() {}

  handleClose = () => {
    if (this.props.onCloseHandler) {
      this.props.onCloseHandler();
    }
  };

  handleOnClick = () => {
    const { showConfirmButton, onConfirmHandler } = this.props;
    if (showConfirmButton && onConfirmHandler) {
      onConfirmHandler();
    }
  };

  renderBody = () => {
    const { body } = this.props;
    if (React.isValidElement(body)) {
      return body;
    } else {
      return <SafetyDOM html={body as string} />;
    }
  };

  render(): ReactNode {
    const { title, show, showConfirmButton, showCloseButton, isBackdrop, t, centered, modalSize, scrollable } =
      this.props;
    let { closeButtonText, confirmButtonText } = this.props;
    const showFooter = showConfirmButton || showCloseButton;

    if (Utils.isEmpty(closeButtonText)) {
      closeButtonText = t('ce01_pmpcore.react.close');
    }

    if (Utils.isEmpty(confirmButtonText)) {
      confirmButtonText = t('ce01_pmpcore.react.confirm');
    }

    // default centered
    const isCentered = Utils.isUndefined(centered) || (typeof centered === 'boolean' && centered);

    return (
      <>
        <Modal
          show={show}
          onHide={this.handleClose}
          backdrop={isBackdrop ? 'static' : undefined}
          keyboard={isBackdrop ? false : true}
          centered={isCentered}
          scrollable={typeof scrollable === 'boolean' && scrollable !== false}
          fullscreen={'sm-down'}
          size={modalSize}>
          <Modal.Header closeButton>{title && <Modal.Title>{title}</Modal.Title>}</Modal.Header>
          <Modal.Body>{this.renderBody()}</Modal.Body>
          {showFooter && (
            <Modal.Footer>
              {showCloseButton && (
                <Button variant="outline-secondary" onClick={this.handleClose}>
                  {closeButtonText}
                </Button>
              )}
              {showConfirmButton && (
                <Button variant="main" onClick={this.handleOnClick}>
                  {confirmButtonText}
                </Button>
              )}
            </Modal.Footer>
          )}
        </Modal>
      </>
    );
  }
}
export default DefaultModal;
