import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import TailorModal from '@kopapro/components/productDetail/tailorModal/tailorModal';
import { Product, ProductAttributeType } from '@kopapro-redux/types/products';
import { getProductAttributeTypesByGroupCode } from '@kopapro-redux/selectors/entities/products';
import { getProductsQtyInCart } from '@kopapro-redux/selectors/entities/cart';

interface IProps extends WithTranslation {
  show: boolean;
  groupCode: string;
  products: Product[] | undefined;
  tailorUp: number;
  onCloseHandler: Function;
  onConfirmHandler: Function;
  defaultCode: string;
}

interface DispatchProps {}

interface StateProps {
  attributeTypes: ProductAttributeType[];
  groupQtyInCart: { proId: number; qty: number }[];
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const { groupCode } = props;
  let groupQtyInCart = getProductsQtyInCart(state, { proGpCode: groupCode });
  let attributeTypes: ProductAttributeType[] = getProductAttributeTypesByGroupCode(state, groupCode);
  return { attributeTypes, groupQtyInCart };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type TailorModalProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TailorModal));
