import { BlogType } from '@kopapro-redux/action-types';
import { BlogAction, BlogCategory, BlogState } from '@kopapro-redux/types/blog';

const initialState: BlogState = {
  pending: false,
  error: '',
  blogDetail: {},
  blogList: {},
  blogCategories: {},
};

const blogReducer = (state = initialState, action: BlogAction): BlogState => {
  let payload = action.payload;
  if (!payload) {
    return state;
  }

  let result = null;

  switch (action.type) {
    case BlogType.GET_BLOG_DETAIL_REQUEST:
    case BlogType.GET_BLOG_LIST_REQUEST:
    case BlogType.GET_BLOG_CATEGORIES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case BlogType.RECEIVED_BLOG_DETAIL:
      return {
        ...state,
        pending: false,
      };
    case BlogType.RECEIVED_BLOG_LIST:
      return {
        ...state,
        pending: false,
      };
    case BlogType.RECEIVED_BLOG_CATEGORIES:
      // payload = payload as BlogCategory[];
      result = payload as BlogCategory[];

      const resBlogCate: any = {};
      result.forEach((item: BlogCategory) => {
        resBlogCate[item.code] = { ...item };
      });

      return {
        ...state,
        blogCategories: resBlogCate,
      };
    default:
      return state;
  }
};
export default blogReducer;