import { all, call, put, takeLatest } from "redux-saga/effects";

import { DeliveryMethodType } from "@kopapro-redux/action-types";

import KopaproApi from "@kopapro-redux/api";
import { QueryList } from "@kopapro-redux/types/general";
import { DeliveryMethod } from '@kopapro-redux/types/deliveryMethod';
import { fetchDeliveryMethodsSuccess } from '@kopapro-redux/actions/deliveryMethods';

export function* getDeliveryMethodsSaga() {
  try {
    const resp: QueryList<DeliveryMethod> = yield call(KopaproApi.getDeliveryMethods);

    yield put(fetchDeliveryMethodsSuccess(resp.data));
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
      // show error message or ignore?
    }
  }
}

function* deliveryMethodsSaga() {
  yield all([takeLatest(DeliveryMethodType.GET_DELIVERYMETHODS_REQUEST, getDeliveryMethodsSaga)]);
}

export default deliveryMethodsSaga;
