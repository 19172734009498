import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import NavigationList from '@kopapro/components/pages/account/navigationList/navigationList';

import { getMemberCenterView } from '@kopapro-redux/selectors/entities/member';
import { HomeViewContent } from '@kopapro-redux/types/componentSetting';
import { withLocation } from '@kopapro/components/commons/router-hoc';

interface IProps extends WithTranslation {
  location: any; //withLocation
}

interface DispatchProps {}

interface StateProps {
  contents?: HomeViewContent[];
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    contents: getMemberCenterView(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type NavigationListProps = IProps & StateProps & DispatchProps;
export default withLocation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NavigationList)));
