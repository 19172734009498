import { InputField, InputValue, InputValues } from '@kopapro-redux/types/componentSetting';
import { CaptchaInfo, CaptchaPatterns } from '@kopapro-redux/types/user';
import utils from '@kopapro-redux/utils/utils';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb/breadcrumb';
import Captcha from '@kopapro/components/commons/captcha';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import DefaultModal from '@kopapro/components/commons/modals';
import ShopImage from '@kopapro/components/commons/shopImage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import { LoginProps } from '@kopapro/components/pages/login';
import ForgetPassword from '@kopapro/components/pages/login/forgetPassword';
import { loginConfig as config } from '@kopapro/utils/config';
import { AppImages } from '@kopapro/utils/constants/images';
import { geti18nValue, submitCaptchaDataParams } from '@kopapro/utils/m18';
import { Component, ReactNode } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { M18ViewCheckMsg } from '@kopapro-redux/types/m18View';

interface LoginState {
  formData: {
    inputs: InputValues;
  };
  password: string;
  errorMessage: string;
  successMessage: string;
  sending: boolean;
  showForgetPasswordDialog: boolean;
  captchaNonce: number;
  captchaInfo: CaptchaInfo;
  showBlocklistModal: boolean;
}

export default class Login extends Component<LoginProps, LoginState> {
  defaultState = {};

  showImage = config.showImage;
  reCAPTCHAWidgetId = 'login_recaptcha';
  reCAPTCHAWidget: React.RefObject<any>;

  constructor(props: LoginProps) {
    super(props);
    this.reCAPTCHAWidget = React.createRef();
    this.state = {
      ...this.defaultState,
      formData: { inputs: {} },
      password: '',
      errorMessage: '',
      successMessage: '',
      sending: false,
      showForgetPasswordDialog: false,
      captchaNonce: new Date().getTime(),
      captchaInfo: {
        id: '',
        useCaptcha: this.props.useCaptcha,
        type: this.props.captchaType,
        selectedList: [],
      },
      showBlocklistModal: false,
    };
  }

  componentDidMount(): void {
    const { useCaptcha, captchaType } = this.props;
    const grecaptcha = window.grecaptcha;
    if (useCaptcha && captchaType === 'google') {
      if (grecaptcha) {
        this.onRecaptchaReady();
      } else {
        window.addEventListener('recaptcha_ready', this.onRecaptchaReady);
      }
    }
  }

  componentWillUnmount(): void {
    const { useCaptcha, captchaType } = this.props;
    if (useCaptcha && captchaType === 'google') {
      window.removeEventListener('recaptcha_ready', this.onRecaptchaReady);
    }
  }

  onRecaptchaReady = (e?: Event): void => {
    const { captchaType, captchaPublicKey, userLoggedIn } = this.props;
    const grecaptcha = window.grecaptcha;
    const self = this;
    if (!userLoggedIn && captchaType === 'google' && grecaptcha) {
      grecaptcha.ready(function () {
        const widgetId = grecaptcha.render(self.reCAPTCHAWidgetId, {
          sitekey: captchaPublicKey,
        });
        const captchaInfo = { ...self.state.captchaInfo, id: widgetId };
        self.setState({ captchaInfo });
      });
    }
  };

  handleLoginError = (message: string) => {
    const { useCaptcha, captchaType } = this.props;
    this.setState({
      sending: false,
      password: '',
      errorMessage: message,
      captchaNonce: new Date().getTime(),
    });
    if (useCaptcha && captchaType === 'google' && window.grecaptcha) {
      window.grecaptcha.reset(this.state.captchaInfo.id);
    }
  };

  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t } = this.props;
    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.login'), to: '', active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  // handle form value change
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    let newValue: any = e.currentTarget.value;

    // if not a number, then do not update value
    if (name === 'mobile' && newValue.length > 0 && !/^[0-9\b]+$/.test(newValue)) {
      return;
    }

    let inputValues: InputValues = this.state.formData.inputs || {};
    let inputValue: InputValue = this.getInputValueByKey(name) || {};

    if (inputValue) {
      inputValue = {
        ...inputValue,
        value: newValue,
      };
    }

    inputValues = { ...inputValues, [name]: inputValue };

    const formData = { ...this.state.formData, inputs: inputValues };
    this.setState({ formData });
  };

  // handle password value change
  handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: any = e.currentTarget.value;
    this.setState({ password: newValue });
  };

  isValidEmail = () => {
    let emailInputValue: InputValue = this.getInputValueByKey('email');
    if (emailInputValue) {
      const email: string = (emailInputValue.value as string).trim() || '';
      if (!utils.isEmpty(email) && !utils.isValidEmail(email)) {
        this.setState({ errorMessage: 'ce01_pmpcore.react.invalidEmail' });
        return false;
      }
    }
    return true;
  };

  getRedirectUrl = () => {
    // assumed  ?redirect=xxxx
    const desire = window.location.search.slice(1).split('&')[0].split('=')[1];
    if (utils.isNotEmpty(desire)) {
      if (desire === '/checkout/summary') {
        return '/checkout';
      }
      return desire;
    }
    return '/account';
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { login } = this.props;
    const self = this;
    event.preventDefault();
    event.stopPropagation();

    // check email
    if (!this.isValidEmail()) {
      return;
    }

    this.setState({ sending: true, errorMessage: '', successMessage: '' });
    const { password, formData, captchaInfo } = this.state;
    const { data, params } = submitCaptchaDataParams(captchaInfo);

    // dispatch request
    login({ formData, password, data, params }, function (status: boolean, checkMsg: M18ViewCheckMsg) {
      // update state errorMessage and successMessage
      let message: string = '';
      if (checkMsg) {
        message = checkMsg.msg;
      }

      if (status) {
        // redirect in render when userLoggedIn become true
      } else {
        if (message === 'ce01_pmpcore.react.blacklist') {
          self.showBlocklistModal();
        }
        self.handleLoginError(message);
      }
    });
  };

  getInputValueByKey(key: string): InputValue {
    const { formData } = this.state;
    let inputValues: InputValues = formData?.inputs || {};
    let v1: InputValue = inputValues[key] || {
      inputId: key,
      value: '',
    };
    return v1;
  }

  onForgetPassword = (e: any) => {
    this.setState({ showForgetPasswordDialog: true });
  };

  handleForgetPasswordDialogClose = () => {
    this.setState({ showForgetPasswordDialog: false });
  };

  renderForgetPasswordDialogBody(): ReactNode {
    return <ForgetPassword />;
  }

  showBlocklistModal = () => {
    this.setState({ showBlocklistModal: true });
  };

  handleCloseBlocklistModal = () => {
    this.setState({ showBlocklistModal: false });
  };

  onSelectedCaptcha = (id: string, selectedList: CaptchaPatterns) => {
    const captchaInfo = { ...this.state.captchaInfo, id, selectedList };
    this.setState({ captchaInfo });
  };

  renderCaptcha(): ReactNode {
    const { captchaInfo } = this.state;
    if (captchaInfo.useCaptcha) {
      switch (captchaInfo.type) {
        case 'builtIn':
          return <Captcha onSelected={this.onSelectedCaptcha} nonce={this.state.captchaNonce} />;
        case 'google':
          return <div className="mb-2" ref={this.reCAPTCHAWidget} id={this.reCAPTCHAWidgetId}></div>;
      }
    }
    return null;
  }

  getFormCompleteInputFields = () => {
    let completeInputFields: string[] = [];
    const { inputFields } = this.props;

    if (inputFields) {
      inputFields.forEach((item) => {
        let inputValue: InputValue = this.getInputValueByKey(item.id) || {};
        if (inputValue.value !== '') {
          completeInputFields.push(inputValue.inputId);
        }
      });
    }

    return completeInputFields;
  };

  getFormControl(item: InputField, index: number): ReactNode {
    const { t } = this.props;
    let key: string = item.id;
    let inputValue: InputValue = this.getInputValueByKey(key);
    let completeInputFields: string[] = this.getFormCompleteInputFields();

    let props: any = {};
    let separatorProps: any = {};

    if (index === 0 || !utils.isEmptyJSONObject(completeInputFields)) {
      separatorProps = { ...separatorProps, hidden: true };
    }

    if (!utils.isEmptyJSONObject(completeInputFields)) {
      const field: string = completeInputFields!.find((p) => p === item.id) || '';
      if (field === '') {
        props = { ...props, hidden: true };
      }
    }

    return (
      <div style={{ display: 'flex' }}>
        <div key={index} className="condition-separator" {...separatorProps}>
          {t('ce01_pmpcore.react.condition.or')}
        </div>
        <div style={{ width: '100px' }}>{geti18nValue(item.name)}</div>
        <Form.Control
          key={key}
          className="field"
          // placeholder={geti18nValue(item.name)}
          name={item.id}
          value={inputValue.value}
          {...props}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
        />
      </div>
    );
  }

  renderForm(): ReactNode {
    const { t, inputFields } = this.props;
    const { password, errorMessage, successMessage } = this.state;

    return (
      <Col xs={12} md={6} className="d-md-flex mx-auto">
        <Card className="mx-auto">
          <Card.Body>
            <Form noValidate onSubmit={this.handleSubmit} autoComplete="off">
              <Form.Group as={Col}>
                {inputFields.map((item, index) => {
                  return <div key={index}>{this.getFormControl(item, index)}</div>;
                })}
              </Form.Group>

              <Form.Group as={Col} className="mb-2">
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '100px' }}>{t(`ce01_pmpcore.react.password`)}</div>
                  <Form.Control
                    type="password"
                    // placeholder={t(`ce01_pmpcore.react.password`)}
                    name={'password'}
                    value={password}
                    required
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePasswordChange(e)}
                  />
                </div>

                <Button onClick={this.onForgetPassword} className="p-0" variant="link">
                  {t('ce01_pmpcore.kopapro.react.forgotPassword') + '?'}
                </Button>
              </Form.Group>

              {this.renderCaptcha()}

              <Button disabled={false} variant="main" type="submit" className="w-100 field text-uppercase">
                {t('ce01_pmpcore.react.signInTitle')}
              </Button>
            </Form>
            <Link to="/register" className="btn btn-link w-100 field text-uppercase">
              {t('ce01_pmpcore.react.registerNow')}
            </Link>

            <ErrorMessage message={t(errorMessage)} />
            <SuccessMessage message={t(successMessage)} />
          </Card.Body>
        </Card>
      </Col>
    );
  }

  renderImage(): ReactNode {
    if (!this.showImage) {
      return null;
    }

    const { photoCode } = this.props;

    return (
      <Col xs={12} md={6} className="image-container order-sm-last my-auto">
        <ShopImage src={photoCode} fallbackImage={AppImages.loginPlaceholder} className="main-img ratio ratio-1x1" />
      </Col>
    );
  }

  render(): ReactNode {
    const { t, userLoggedIn, loginSetup } = this.props;
    if (userLoggedIn) {
      return <Navigate to={this.getRedirectUrl()} />;
    }
    return (
      <div id={`login-view`} className={`login page-container container row px-xl-5 `}>
        {this.renderTop()}
        <div className="col-12">
          <h3 className="title">{t('ce01_pmpcore.react.login')}</h3>
        </div>
        {this.renderImage()}
        {this.renderForm()}

        <DefaultModal
          show={this.state.showForgetPasswordDialog}
          title={t('ce01_pmpcore.kopapro.react.forgotPassword') + '?'}
          body={this.renderForgetPasswordDialogBody()}
          onCloseHandler={this.handleForgetPasswordDialogClose}
        />

        <DefaultModal
          show={this.state.showBlocklistModal}
          title={''}
          body={geti18nValue(loginSetup.blacklistMsg)}
          onCloseHandler={this.handleCloseBlocklistModal}
        />
      </div>
    );
  }
}
