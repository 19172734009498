import React, { Component, ReactNode, Fragment } from 'react';
import { NavbarProps } from '@kopapro/components/header/components/navbar';
import HeaderFooterItem from '@kopapro/components/commons/kppHeaderFooterItem';
import { getCustomStyle, geti18nValue, resolveImageURL } from '@kopapro/utils/m18';
import { isM18PreviewMode } from '@kopapro-redux/utils/m18';
import utils from '@kopapro-redux/utils/utils';
import webUtil from '@kopapro/utils/utils';
import { headerConfig } from '@kopapro/utils/config';
import { ShopNavbarLayoutMenuComponent } from '@kopapro-redux/types/shop';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Dropdown from 'react-bootstrap/Dropdown';
import classNames from 'classnames';
import MenuIcon from '@mui/icons-material/Menu';
class Navbar extends Component<NavbarProps> {
  itemProps = {
    imageSize: headerConfig.imageSize,
    iconSize: headerConfig.iconSize as SizeProp,
  };
  maxHeight = 60; // included padding
  ref: React.RefObject<any>;
  moreDropdownMenu: React.RefObject<any>;
  collapsing: boolean;
  bodyWidth: number;
  disableAutoCollapse: boolean;

  constructor(props: NavbarProps) {
    super(props);
    this.ref = React.createRef();
    this.moreDropdownMenu = React.createRef();
    this.collapsing = false;
    this.bodyWidth = document.body.scrollWidth;
    this.disableAutoCollapse = headerConfig.disableAutoCollapse;
  }

  componentDidMount() {
    setTimeout(this.autoCollapse, 100);
    window.addEventListener('resize', this.autoCollapse);
  }

  componentDidUpdate() {
    setTimeout(this.autoCollapse, 100);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.autoCollapse);
  }

  autoCollapse = () => {
    if (this.disableAutoCollapse || this.collapsing) {
      return;
    }
    let counter = 0;
    this.collapsing = true;

    const addCount = function (i: number) {
      setTimeout(function () {}, 1000 * i);
    };

    if (this.ref.current && this.moreDropdownMenu.current && !this.props.showToggleOnly) {
      const nav = this.ref.current;
      let navHeight = nav.clientHeight;
      const maxHeight = Math.min(navHeight, this.maxHeight);

      const nodes = this.ref.current.children;
      const moreDropdown = this.moreDropdownMenu.current;
      const moreDropdownMenu = moreDropdown.children[moreDropdown.children.length - 1];
      moreDropdown.classList.add('d-none');
      let bodyWidth = document.body.scrollWidth;
      let bodyOffsetWidth = document.body.offsetWidth;

      if (navHeight > maxHeight || bodyWidth > bodyOffsetWidth) {
        moreDropdown.classList.remove('d-none');
        nav.classList.remove('w-auto');
        nav.classList.add('w-100');

        while ((navHeight > maxHeight || bodyWidth > bodyOffsetWidth) && counter < 10) {
          //   add child to dropdown
          var children = this.ref.current.children;
          var count = children.length;
          const child = children[count - 2];
          if (child) {
            moreDropdownMenu.prepend(children[count - 2]);
          }
          navHeight = nav.clientHeight;
          addCount(counter++);
          bodyWidth = document.body.scrollWidth;
          bodyOffsetWidth = document.body.offsetWidth;
        }
        nav.classList.add('w-auto');
        nav.classList.remove('w-100');
      } else {
        var collapsed = moreDropdownMenu.children;
        let removed = false;
        if (collapsed.length === 0) {
          moreDropdown.classList.add('d-none');
          this.collapsing = false;
          return;
        }
        while (
          (navHeight < maxHeight || bodyWidth === bodyOffsetWidth) &&
          nodes.length > 0 &&
          collapsed.length > 0 &&
          counter < 10
        ) {
          //  remove child from dropdown
          removed = true;
          collapsed = moreDropdownMenu.children;
          nav.insertBefore(collapsed[0], moreDropdown);
          navHeight = nav.clientHeight;
          addCount(counter++);
          bodyWidth = document.body.scrollWidth;
          bodyOffsetWidth = document.body.offsetWidth;
        }
        if (removed) {
          this.collapsing = false;
          this.autoCollapse();
        }
      }
      if (moreDropdownMenu.children.length === 0) {
        moreDropdown.classList.add('d-none');
      } else {
        moreDropdown.classList.remove('d-none');
      }
    }
    this.collapsing = false;
    this.bodyWidth = document.body.scrollWidth;
  };

  applyCustomStyle = () => {
    const { backgroundColor, fontColor } = this.props.layout!;
    const { banner, bannerFontColor, bannerPic, bannerHeight } = this.props.layout!;
    const style = document.createElement('style');
    style.id = 'custom-nav';

    let backgroundCss = '';
    if (!banner && utils.isNotEmpty(backgroundColor)) {
      backgroundCss = `background-color:${backgroundColor}`;
    }

    let css = `
           .kpp-custom-nav {
                ${backgroundCss}
            }
            
            .kpp-custom-nav.navbar-nav .nav-link,
            .kpp-custom-nav.navbar-nav .show > .nav-link, 
            .kpp-custom-nav.navbar-nav .nav-link.active {
              color: ${banner ? bannerFontColor : fontColor};
            }
        `;

    if (banner) {
      css += `
        .kpp-custom-nav:hover {
          background-color:${backgroundColor};
        }
        .kpp-custom-nav:hover.navbar-nav .nav-link {
          color: ${fontColor};
        }
        .kpp-custom-nav.navbar-nav .nav-link {
          color: ${banner ? bannerFontColor : fontColor};
        }
        @media (min-width: 576px) { 
          .nav-banner {
            background-image:url(${resolveImageURL(bannerPic)});
            width: 100%;
            height:${bannerHeight}px;
          }
        }
      `;
    }
    style.innerHTML = css;
    webUtil.appendStyleElement(style);
  };

  getNavBarClass = () => {
    // if no custom nav bar style, then use header style defined in theme builder
    if (!this.props.layout || !this.props.layout.useUdfStyle) {
      return 'kpp-nav';
    }
    return 'kpp-custom-nav';
  };

  getNabBarStyle = (needBackgroundColor = true) => {
    // return {};
    if (!this.props.layout || !this.props.layout.useUdfStyle) {
      return {};
    }
    const { useUdfStyle, backgroundColor, font, fontColor, fontSize, fontStyle } = this.props.layout!;
    let styleProps: any = { useUdfStyle, backgroundColor, font, fontColor, fontSize, fontStyle };

    return getCustomStyle(styleProps, needBackgroundColor);
  };

  getNabBarFontStyle = () => {
    if (!this.props.layout || !this.props.layout.useUdfStyle) {
      return {};
    }
    const { useUdfStyle, font, fontSize, fontStyle } = this.props.layout!;
    let styleProps: any = { useUdfStyle, font, fontSize, fontStyle };
    return getCustomStyle(styleProps, false);
  };

  getNodeWidth = () => {
    const { layout } = this.props;
    let width = {};
    if( layout && layout.useUdfStyle) {
      if(layout.itemWidthType === 'fixed') {
        width = { minWidth: layout.itemWidth };
      } else {
        width = {paddingRight:layout.itemMargin}
      }
    }
    return width;
  };

  renderNode = (node: ShopNavbarLayoutMenuComponent, isLast: boolean, parent?: ShopNavbarLayoutMenuComponent) => {
    const styles = this.getNabBarStyle();
    const fontStyle = this.getNabBarFontStyle();
    const { layout } = this.props;
    const imageAndFontSize = layout && layout.useUdfStyle && layout.fontSize ? layout.fontSize : 16;
    const width = this.getNodeWidth();
    const hasParentFolder = utils.isNotEmpty(node.folder);
    const isParentFullScreen = parent?.dropDownType === 'fullScreen';
    const isParentExpand = parent?.dropDownDisplayMode === 'expand';
    const element = (
      <HeaderFooterItem
        key={node.compId}
        parentType="header"
        node={node}
        {...this.itemProps}
        itemStyles={{ ...fontStyle, ...width }}
        imageSize={imageAndFontSize}
      />
    );

    if (node.children.length === 0) {
      if (hasParentFolder) {
        return (
          <Dropdown.Item as={'div'} key={node.compId}>
            {element}
          </Dropdown.Item>
        );
      }

      return element;
    }
    const { dropDownType } = node;
    if (dropDownType === 'fullScreen' && !isParentFullScreen) {
      return (
        <Dropdown as={NavItem} key={node.compId} className={`fullwidth-dropdown position-static`} drop={'down'}>
          <Dropdown.Toggle className="text-truncate" style={{ ...fontStyle, ...width }} as={NavLink}>
            {geti18nValue(node.compDesc)}
          </Dropdown.Toggle>

          <Dropdown.Menu style={styles}>
            <div className="container d-flex ">
              {node.children.map((child) => {
                return (
                  <Fragment key={child.compId}>
                    {this.renderNode(child as ShopNavbarLayoutMenuComponent, true, node)}
                  </Fragment>
                );
              })}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    return (
      <Dropdown
        as={NavItem}
        key={node.compId}
        className={classNames({ 'dropdown-item': isParentFullScreen })}
        drop={isParentFullScreen || !hasParentFolder ? 'down' : 'end'}
        show={isParentExpand ? true : undefined}>
        <Dropdown.Toggle className="text-truncate" style={{ ...fontStyle, ...width }} as={NavLink}>
          {geti18nValue(node.compDesc)}
        </Dropdown.Toggle>
        <Dropdown.Menu style={styles}>
          {node.children.map((child) => {
            return (
              <Fragment key={child.compId}>{this.renderNode(child as ShopNavbarLayoutMenuComponent, true)}</Fragment>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  render(): ReactNode {
    const { t, components, layout, showToggleOnly, toggleStyle } = this.props;
    const styles = this.getNabBarStyle();
    const width = this.getNodeWidth();
    let widthControlClass = 'flex-grow-1',
      customWidth = {};
    if (layout) {
      if (layout.useUdfStyle) {
        this.applyCustomStyle();
      } else if (layout.widthType === 'fixed') {
        customWidth = { maxWidth: layout.width };
        widthControlClass = 'flex-wrap';
      }
    }
    let navbarContent = (
      <Nav
        ref={this.ref}
        key="nav"
        style={customWidth}
        className={`d-none d-sm-flex ${widthControlClass}  ${this.getNavBarClass()}`}>
        {components.map((node, index: number) => this.renderNode(node, components.length - 1 === index))}
        {!this.disableAutoCollapse && (
          <Dropdown
            as={NavItem}
            ref={this.moreDropdownMenu}
            key="more"
            style={{ ...styles, background: 'transparent' }}
            drop={'down'}
            className="more-dropdown">
            <Dropdown.Toggle style={{ ...styles, ...width, background: 'transparent' }} as={NavLink}>
              {t('ce01_pmpcore.react.more')}
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount={true} style={styles} className="more-dropdown-menu"></Dropdown.Menu>
          </Dropdown>
        )}
      </Nav>
    );
    let wrapper = <>{navbarContent}</>;
    if (layout && layout.banner) {
      wrapper = React.createElement('div', {
        children: navbarContent,
        className: 'nav-banner',
      });
    }
    let key = 'nav';
    if (isM18PreviewMode()) {
      key = `nav_${new Date().getTime()}`;
    }
    return (
      <div className="w-100" key={key}>
        {wrapper}
        <div key="nav-toggle" className="d-flex d-sm-none">
          <button
            aria-controls="offcanvasNavbar-expand"
            type="button"
            aria-label="Toggle navigation"
            className={classNames('navbar-toggler p-0', {
              'force-display': showToggleOnly,
              collapsed: !showToggleOnly,
            })}
            style={toggleStyle ? toggleStyle : {}}
            onClick={(e) => this.props.toggleDrawer(true)}>
            <MenuIcon sx={toggleStyle ? { fontSize: toggleStyle.fontSize } : {}} />
          </button>
        </div>
      </div>
    );
  }
}

export default Navbar;
