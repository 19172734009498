import { all, call, put, takeLatest } from "redux-saga/effects";

import { ProductType } from "@kopapro-redux/action-types";

import KopaproApi from '@kopapro-redux/api';
import { ProductCategory } from "@kopapro-redux/types/productCategory";
import { QueryList } from "@kopapro-redux/types/general";
import { fetchProductCategoriesSuccess } from "@kopapro-redux/actions/products";
import { CommonAction } from '@kopapro-redux/actions/misc';

export function* getCategoriesSaga(action?: CommonAction) {
  try {
    const resp: QueryList<ProductCategory> = yield call(KopaproApi.getProductCategories);

    yield put(fetchProductCategoriesSuccess(resp.data));

    if (action && action.callback) {
      action.callback(resp.data);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
      // show error message or ignore?
    }
  }
}

function* categoriesSaga() {
    yield all([takeLatest(ProductType.GET_CATEGORIES_REQUEST, getCategoriesSaga)]);
}

export default categoriesSaga;
