import { Component, ReactNode } from "react";
import NavDropdown from "react-bootstrap/esm/NavDropdown";
import utils from '@kopapro-redux/utils/utils';
import { LanguageDropdownProps } from '@kopapro/components/header/components/language';

class Language extends Component<LanguageDropdownProps> {
  changeLanguage = (id: string) => {
    this.props.setLang(id);
    const { currentLang, location, enableLangPath, defaultLang, excludeDefaultLangInURL } = this.props;
    if (enableLangPath && currentLang !== id) {
      let newPath = `${location.pathname}${location.search}`;
      if (!excludeDefaultLangInURL || defaultLang !== id) {
        newPath = `/${id.toLowerCase()}${location.pathname}${location.search}`;
      }
      window.history.pushState({ time: new Date().getTime() }, '', newPath);
    }
  };

  render(): ReactNode {
    const { language, currentLang, style, fontStyle } = this.props;
    if (!language) {
      return null;
    }

    const currentLangText = language.find((lang) => lang.id === currentLang)?.desc;
    const inheritClass = utils.isNotEmptyJSONObject(fontStyle) ? 'inherit' : '';

    return (
      <NavDropdown
        className={`lang-dropdown ${inheritClass}`}
        title={currentLangText}
        id={`offcanvasNavbarDropdown-expand`}
        style={{ ...style, ...fontStyle }}>
        {language.map((lang) => {
          return (
            <NavDropdown.Item
              href="#"
              style={fontStyle}
              key={lang.id}
              disabled={currentLang === lang.id}
              onClick={() => this.changeLanguage(lang.id)}>
              {lang.desc}
            </NavDropdown.Item>
          );
        })}
      </NavDropdown>
    );
  }
}

export default Language;
