import { CheckoutType } from '@kopapro-redux/action-types';
import {
  AddMemberInCheckoutPayload,
  AddDeliveryInCheckoutPayload,
  LoadExtraInfoInCheckoutAction,
  LoadExtraInfoInCheckoutCallback,
  LoadExtraInfoInCheckoutPayload,
  SaveExtraInfoInCheckoutAction,
  SaveExtraInfoInCheckoutPayload,
  CheckDeliveryChargeInCheckoutPayload,
  AddPaymentInCheckoutPayload,
  AddDeliveryInCheckoutAction,
  AddMemberInCheckoutAction,
  CheckDeliveryChargeInCheckoutAction,
  AddPaymentInCheckoutAction,
  LoadCouponInCheckoutPayload,
  LoadCouponInCheckoutAction,
  ApplyCouponInCheckoutAction,
  ApplyCouponInCheckoutPayload,
  CheckPaymentSurchargeInCheckoutAction,
  CheckPaymentSurchargeInCheckoutPayload,
  ConfirmOrderInCheckoutPayload,
  ConfirmOrderInCheckoutAction,
  AddDiscountCodesInCheckoutPayload,
  AddDiscountCodesInCheckoutAction,
} from '@kopapro-redux/types/checkout';

export const addDiscountCodesToOrder = (
  payload: AddDiscountCodesInCheckoutPayload,
  callback?: Function
): AddDiscountCodesInCheckoutAction => {
  return {
    type: CheckoutType.ADD_DISCOUNT_CODES_IN_CHECK_OUT,
    payload,
    callback,
  };
};

// member
export const addMemberToOrder = (
  payload: AddMemberInCheckoutPayload,
  callback?: Function
): AddMemberInCheckoutAction => {
  return {
    type: CheckoutType.ADD_MEMBER_IN_CHECK_OUT,
    payload,
    callback,
  };
};

// delivery
export const addDeliveryToOrder = (
  payload: AddDeliveryInCheckoutPayload,
  callback?: Function
): AddDeliveryInCheckoutAction => {
  return {
    type: CheckoutType.ADD_DELIVERY_IN_CHECK_OUT,
    payload,
    callback,
  };
};

export const checkDeliveryChargeInOrder = (
  payload: CheckDeliveryChargeInCheckoutPayload,
  callback?: Function
): CheckDeliveryChargeInCheckoutAction => {
  return {
    type: CheckoutType.CHECK_DELIVERY_CHARGE_IN_CHECK_OUT,
    payload,
    callback,
  };
};

// payment
export const addPaymentToOrder = (
  payload: AddPaymentInCheckoutPayload,
  callback?: Function
): AddPaymentInCheckoutAction => {
  return {
    type: CheckoutType.ADD_PAYMENT_IN_CHECK_OUT,
    payload,
    callback,
  };
};

export const checkPaymentSurchargeInOrder = (
  payload: CheckPaymentSurchargeInCheckoutPayload,
  callback?: Function
): CheckPaymentSurchargeInCheckoutAction => {
  return {
    type: CheckoutType.CHECK_PAYMENT_SURCHARGE_IN_CHECK_OUT,
    payload,
    callback,
  };
};

export const loadCouponInOrder = (
  payload?: LoadCouponInCheckoutPayload,
  callback?: Function
): LoadCouponInCheckoutAction => {
  return {
    type: CheckoutType.LOAD_COUPON_IN_CHECK_OUT,
    payload,
    callback,
  };
};

export const applyCouponToOrder = (
  payload: ApplyCouponInCheckoutPayload,
  callback?: Function
): ApplyCouponInCheckoutAction => {
  return {
    type: CheckoutType.APPLY_COUPON_IN_CHECK_OUT,
    payload,
    callback,
  };
};

// extra
export const saveCheckoutExtraInfo = (
  payload: SaveExtraInfoInCheckoutPayload,
  callback?: Function
): SaveExtraInfoInCheckoutAction => {
  return {
    type: CheckoutType.SAVE_EXTRA_INFO_IN_CHECK_OUT,
    payload,
    callback,
  };
};

export const loadCheckoutExtraInfo = (
  payload: LoadExtraInfoInCheckoutPayload,
  callback?: LoadExtraInfoInCheckoutCallback
): LoadExtraInfoInCheckoutAction => {
  return {
    type: CheckoutType.LOAD_EXTRA_INFO_IN_CHECK_OUT,
    payload,
    callback,
  };
};

// confirm order
export const confirmOrder = (
  payload: ConfirmOrderInCheckoutPayload,
  callback?: Function
): ConfirmOrderInCheckoutAction => {
  return {
    type: CheckoutType.CONFIRM_ORDER_IN_CHECK_OUT,
    payload,
    callback,
  };
};