import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import SmsMobileModal from '@kopapro/components/commons/verification/smsMobileModal/smsMobileModal';

interface IProps extends WithTranslation {
  show: boolean;
  onCloseHandler: Function;
  onConfirmHandler: Function;
}

interface DispatchProps {}

interface StateProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type SmsMobileProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SmsMobileModal));
