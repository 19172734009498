import { Component, ReactNode } from 'react';
import { SummaryProps } from '@kopapro/components/checkout/summary';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import utils from '@kopapro-redux/utils/utils';
import Row from 'react-bootstrap/Row';
import SpinnerButton from '@kopapro/components/commons/spinnerButton';
import EmailVerifyModal from '@kopapro/components/commons/verification/emailVerifyModal';
import Amounts from '@kopapro/components/order/amounts';
import Items from '@kopapro/components/order/items';
import Delivery from '@kopapro/components/order/delivery';
import Payment from '@kopapro/components/order/payment';
import Extra from '@kopapro/components/order/extra';
import Loading from '@kopapro/components/commons/loading';
import { isM18PreviewMode } from '@kopapro-redux/utils/m18';
import { I18nDictionary } from '@kopapro-redux/types/utilities';

interface SummaryState {
  sending: boolean;
  loadingData: boolean;
  errorMessage: string;
  successMessage: string;
  showEmailVerifyModal: boolean;
  checkedTerm: boolean;
  termMsg: I18nDictionary | undefined;
}

export default class Summary extends Component<SummaryProps, SummaryState> {
  defaultState = {
    sending: false,
    loadingData: true,
    errorMessage: '',
    successMessage: '',
    showEmailVerifyModal: false,
    checkedTerm: false,
    termMsg: undefined,
  };

  constructor(props: SummaryProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount() {
    this.loadDataHandler();
  }

  componentDidUpdate(prevProps: Readonly<SummaryProps>): void {
    if (utils.isEmpty(prevProps.orderId) && utils.isNotEmpty(this.props.orderId)) {
      this.loadDataHandler();
    }
  }

  loadDataHandler = () => {
    const { orderId, loadOrder } = this.props;
    this.setState({ loadingData: true });
    loadOrder({ orderId }, this.loadDataCallback);
  };

  loadDataCallback = (status: boolean, data: any) => {
    const { orderId } = this.props;
    if (status && data.orderId === orderId) {
      this.setState({ loadingData: false });
    }
  };

  showEmailVerifyModal = () => {
    this.setState({ showEmailVerifyModal: true });
  };

  handleConfirmEmailVerifyModal = () => {
    this.setState({ showEmailVerifyModal: false });
    this.confirmOrderHandler();
  };

  handleCloseEmailVerifyModal = () => {
    this.setState({ showEmailVerifyModal: false });
  };

  getVerifyEmail = (): string => {
    const { cartMember, userLoggedIn, userInfo } = this.props;
    if (userLoggedIn) {
      return userInfo.email;
    }
    return cartMember?.email || '';
  };

  confirmOrderHandler = () => {
    const { navigate, confirmOrder } = this.props;
    const self = this;

    const { confirmParam } = this.props;

    this.setState({ sending: true, errorMessage: '', successMessage: '' });

    // dispatch request
    confirmOrder({ saveParam: confirmParam }, function (isSuccess: boolean, message = '', entity: any) {
      if (isSuccess) {
        const { orderId } = entity;
        if (utils.isNotEmpty(orderId)) {
          navigate(`/payment/${orderId}`);
        } else {
          self.setState({ sending: false, successMessage: 'ce01_pmpcore.react.submitSuccess' });
        }
      } else {
        self.setState({ sending: false, errorMessage: message });
      }
    });
  };

  renderAmounts(): ReactNode {
    return <Amounts />;
  }

  renderInfo(): ReactNode {
    const { t, cartMember } = this.props;

    if (utils.isUndefined(cartMember)) {
      return null;
    }

    return (
      <div className="section mb-5">
        <h4 className="sub-title">{t('ce01_pmpcore.kopapro.react.customerInfo')}</h4>
        <span className="fs-5">{`${t('ce01_pmpcore.react.email')}: ${cartMember!.email}`}</span>
      </div>
    );
  }

  renderItems(): ReactNode {
    return <Items />;
  }

  renderDelivery(): ReactNode {
    return <Delivery />;
  }

  renderPayment(): ReactNode {
    return <Payment />;
  }

  renderExtra(): ReactNode {
    return <Extra />;
  }

  renderOrderInformation(): ReactNode {
    const { t } = this.props;
    return (
      <div className="section">
        <h4 className="sub-title">{t('ce01_pmpcore.react.orderInfo')}</h4>
        <Row>
          {this.renderPayment()}
          {this.renderDelivery()}
        </Row>
      </div>
    );
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { orderSetup } = this.props;
    if (orderSetup.isNeedVerify) {
      this.setState({ showEmailVerifyModal: true });
    } else {
      this.confirmOrderHandler();
    }
  };

  // Components
  renderSummary(): ReactNode {
    const { t } = this.props;
    const { successMessage, errorMessage } = this.state;
    return (
      <Card className="mt-5">
        <Card.Body className="my-order">
          <Card.Title className="fs-3 text-center">{t('ce01_pmpcore.kopapro.react.summary')}</Card.Title>
          <Form noValidate onSubmit={this.handleSubmit} autoComplete="off">
            {this.renderInfo()}
            {this.renderItems()}
            {this.renderAmounts()}
            {this.renderOrderInformation()}
            {this.renderExtra()}
            <ErrorMessage message={t(errorMessage)} />
            <SuccessMessage message={t(successMessage)} />

            {this.renderFooter()}
          </Form>
        </Card.Body>
      </Card>
    );
  }

  renderFooter(): ReactNode {
    const { t } = this.props;
    const { sending } = this.state;
    return (
      <div className="chectout-footer mt-5">
        <Button
          variant="outline-secondary"
          className="chectout-footer-btn"
          disabled={sending}
          onClick={() => this.props.componentPrevious()}>
          {t('ce01_pmpcore.react.backBtn')}
        </Button>
        <SpinnerButton
          spinning={sending}
          disabled={sending || isM18PreviewMode()}
          variant="main"
          type="submit"
          className="chectout-footer-btn">
          {t('ce01_pmpcore.react.confirmToBuy')}
        </SpinnerButton>
        <EmailVerifyModal
          show={this.state.showEmailVerifyModal}
          email={this.getVerifyEmail()}
          onConfirmHandler={this.handleConfirmEmailVerifyModal}
          onCloseHandler={this.handleCloseEmailVerifyModal}
        />
      </div>
    );
  }

  render(): ReactNode {
    const { loadingData } = this.state;
    if (loadingData) {
      return <Loading />;
    }
    return <div className="summary">{this.renderSummary()}</div>;
  }
}
