import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import ResetPassword from '@kopapro/components/resetPassword/resetPassword';
import { withTranslation, WithTranslation } from 'react-i18next';

import { withParams, withNavigation } from '@kopapro/components/commons/router-hoc';
import { resetUserPassword } from '@kopapro-redux/actions/user';

interface IProps extends WithTranslation {
  params: any; // from withParams
}

interface StateProps {
  token: string;
}

interface DispatchProps {
  resetPassword: typeof resetUserPassword;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const token = props.params['token'];
  return {
    token,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  resetPassword: resetUserPassword,
};

export type ResetPasswordProps = IProps & StateProps & DispatchProps;
export default withParams(
  withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ResetPassword)))
);
