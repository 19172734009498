import { BlogDetail } from '@kopapro-redux/types/blog';
import { geti18nValue } from '@kopapro/utils/m18';
import { Component, ReactNode } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { BlogListExtProps } from '@kopapro/components/blog/list/list';

export class ImageColumnList extends Component<BlogListExtProps> {
  renderItem = (item: BlogDetail): ReactNode => {
    const { openBlogDetail, showDate, t } = this.props;
    const { titleObj, lastModifyDate, blogId } = item;
    return (
      <Col className="d-flex" key={`item-${blogId}`}>
        <Card className={`mb-3 w-100`}>
          {this.renderHeader(item)}
          <Card.Body>
            {showDate && (
              <div className={`text-muted`}>
                <label>{t('{date, full}', { date: lastModifyDate })}</label>
              </div>
            )}
            <Card.Text
              className={`title fw-bold btn text-start fs-5 p-0 text-break`}
              onClick={() => {
                openBlogDetail(blogId);
              }}>
              {geti18nValue(titleObj)}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  renderHeader = (item: BlogDetail): ReactNode => {
    const { openBlogDetail, renderHeaderComp, square } = this.props;
    const { blogId } = item;

    return (
      <div
        className={`align-self-center w-100 ratio ${square ? 'square-content ratio-1x1' : 'content ratio-16x9'}`}
        onClick={() => {
          openBlogDetail(blogId);
        }}>
        {renderHeaderComp && renderHeaderComp(item)}
      </div>
    );
  };

  render() {
    const { dataList, square } = this.props;

    return (
      <div className={`blog-list image-column${square ? '-square' : ''}`}>
        <Row xs={1} md={3}>
          {dataList.map((item: BlogDetail) => {
            return this.renderItem(item);
          })}
        </Row>
      </div>
    );
  }
}
