import { Component, ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import CloseIcon from '@mui/icons-material/Close';
import { getCustomStyle, geti18nValue } from '@kopapro/utils/m18';
import utils from '@kopapro-redux/utils/utils';
class Announcement extends Component<any> {
  handleClear = () => {
    // dispatch event update props
    this.props.closeAnnouncement();
  };
  render(): ReactNode {
    const { showAnnouncement, content, enableStyle, style } = this.props;

    const announcement = geti18nValue(content);

    if (!showAnnouncement || utils.isEmpty(announcement)) {
      return null;
    }

    const customStyle = getCustomStyle({ ...style, useUdfStyle: enableStyle }, true);

    return (
      <div className={` announcements border-bottom ${this.props.className}`} style={customStyle}>
        <div className="container w-100 mw-100 py-2">
          <div className="row position-relative">
            <div className="mx-auto py-0 col col-11">
              <div className="body text-left text-center">{announcement}</div>
              <Button
                variant="dark-link"
                className="close-btn position-absolute top-0 end-0 p-0"
                onClick={this.handleClear}>
                <CloseIcon sx={{ color: customStyle.color }} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Announcement;
