import utils from '@kopapro/utils/utils';
import ErrorIcon from '@mui/icons-material/Error';

interface Props {
  message: string;
  className?: string;
  isShow?: boolean; // default show
}

function ErrorMessage(props: Props) {
  const { message, isShow } = props;
  if (utils.isEmpty(message) || (utils.isDefined(isShow) && isShow === false)) {
    return null;
  }
  return (
    <p className={`text-danger ${props.className}`}>
      <ErrorIcon /> {message}
    </p>
  );
}
export default ErrorMessage;
