import { Component, ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EmailVerifyModalProps } from '@kopapro/components/commons/verification/emailVerifyModal';
import { Col, Form } from 'react-bootstrap';
import ErrorMessage from '@kopapro/components/commons/errorMessage';

interface EmailVerifyModalState {
  verifyId: string;
  verifyCode: string;
  errorMessage: string;
  resendCounter: number;
}

export default class EmailVerifyModal extends Component<EmailVerifyModalProps, EmailVerifyModalState> {
  defaultState = {
    verifyId: '',
    verifyCode: '',
    errorMessage: '',
    resendCounter: 60,
  };
  timer: any = null;

  constructor(props: EmailVerifyModalProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidUpdate(prevProps: Readonly<EmailVerifyModalProps>, prevState: Readonly<EmailVerifyModalState>): void {
    if (prevProps.show !== this.props.show && this.props.show) {
      this.getVerifyId();
    }
  }

  startResendCounter = () => {
    this.setState({ resendCounter: 60 });
    this.clearCounter();
    this.timer = setInterval(() => {
      let resendCounter: number = this.state.resendCounter;
      if (resendCounter > 0) {
        resendCounter = resendCounter - 1;
        this.setState({ resendCounter });
      } else {
        this.clearCounter();
      }
    }, 1000);
  };

  clearCounter = () => {
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
  };

  handleResend = () => {
    this.getVerifyId();
  };

  handleClose = () => {
    if (this.props.onCloseHandler) {
      this.props.onCloseHandler();
    }
  };

  handleOnClick = () => {
    this.sendVerifyCode();
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: any = e.currentTarget.value;
    this.setState({ verifyCode: newValue });
  };

  getVerifyId = () => {
    const { requestEmail, email } = this.props;
    const self = this;

    this.startResendCounter();

    // dispatch request
    requestEmail({ email }, function (status: boolean, verifyId: string) {
      if (status) {
        self.setState({ verifyId });
      }
    });
  };

  sendVerifyCode = () => {
    const { verifyEmail, onConfirmHandler } = this.props;
    const { verifyId, verifyCode } = this.state;
    const self = this;

    // dispatch request
    verifyEmail({ veriId: verifyId, veriNum: verifyCode }, function (status: boolean) {
      if (status) {
        onConfirmHandler();
      } else {
        self.setState({ errorMessage: 'ce01_pmpcore.react.errorVeriCode' });
      }
    });
  };

  render(): ReactNode {
    const { t, show, email } = this.props;
    const { resendCounter } = this.state;
    const counterLabel: string = resendCounter > 0 ? ` (${resendCounter}s)` : '';

    return (
      <Modal
        show={show}
        onHide={this.handleClose}
        backdrop={'static'}
        keyboard={false}
        centered={true}
        fullscreen={'sm-down'}>
        <Modal.Header>
          {<Modal.Title>{t('ce01_pmpcore.react.verificationCodeSent', { email: email })}</Modal.Title>}
        </Modal.Header>
        <Form noValidate autoComplete="off">
          <Modal.Body>
            <p className="text-danger">{t('ce01_pmpcore.react.smsVerifyMsg2')}</p>
            <div className={`sms-verify`}>
              <Form.Group as={Col}>
                <Form.Control
                  className="field"
                  placeholder={t(`ce01_pmpcore.react.verifyPlaceHolder`)}
                  name={'verifyCode'}
                  value={this.state.verifyCode}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                />
              </Form.Group>

              <Form.Group as={Col} className={'resend'}>
                <Button variant="link" className="px-0" onClick={this.handleResend} disabled={resendCounter > 0}>
                  {t('ce01_pmpcore.react.resend') + counterLabel}
                </Button>
              </Form.Group>
              <ErrorMessage message={t(this.state.errorMessage)} />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="outline-secondary" onClick={this.handleClose}>
              {t('ce01_pmpcore.react.backBtn')}
            </Button>
            <Button variant="primary" className="btn-main" onClick={this.handleOnClick}>
              {t('ce01_pmpcore.react.submitBtn')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
