import { RootState } from '@kopapro-redux/store';
import { isKopaproExpired } from '@kopapro-redux/selectors/app';
import { getAboutUs } from '@kopapro-redux/selectors/entities/aboutUs';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, MapStateToProps } from 'react-redux';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import { geti18nValue } from '@kopapro/utils/m18';
import classNames from 'classnames';

// HOC
function ExpiredPage(aboutUs: I18nDictionary) {
  const aboutus = geti18nValue(aboutUs);
  return (
    <>
      <div className="my-5 py-5 d-flex align-items-center justify-content-center flex-grow-1 flex-column">
        <CloudOffIcon sx={{ fontSize: 100 }} />
        <h4 className="">請聯繫店主。</h4>
        <h4 className="">Please contact the store owner.</h4>
        <div className={classNames({ 'mt-5': aboutus.length > 0 })}>
          <SafetyDOM html={aboutus} />
        </div>
      </div>
    </>
  );
}

function withExpiredScreen(WrappedComponent: any) {
  class Screen extends Component<any> {
    render() {
      if (this.props.expired) {
        console.log(this.props.aboutUs);
        return ExpiredPage(this.props.aboutUs);
      }
      return <WrappedComponent {...this.props} />;
    }
  }

  return withTranslation()(connect(mapStateToProps, null)(Screen));
}

interface IProps extends WithTranslation {}

interface StateProps {
  expired: boolean;
  aboutUs: I18nDictionary;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    expired: isKopaproExpired(state),
    aboutUs: getAboutUs(state),
  };
};

export default withExpiredScreen;
