
import { ProductType } from '@kopapro-redux/action-types';
import {
  GetProductFailure,
  GetProductFailurePayload,
  GetProductRequest,
  GetProductSuccess,
  GetProductSuccessPayload,
  Product,
  GetProductPayload,
  ProductFilter,
  LoadProductCommentPayload,
  GetProductCommentPayload,
  GetProductCommentCallback,
  LoadProductCommentCallback,
} from '@kopapro-redux/types/products';
import { ProductCategory } from '@kopapro-redux/types/productCategory';
import { QueryList } from '@kopapro-redux/types/general';
import { CommonAction } from '@kopapro-redux/actions/misc';

export const getProductRequest = (payload: GetProductPayload, callback?: Function): GetProductRequest => ({
  type: ProductType.GET_PRODUCT_REQUEST,
  payload,
  callback,
});

export const getProductByIdRequest = (payload: GetProductPayload, callback?: Function): GetProductRequest => ({
  type: ProductType.GET_PRODUCT_BY_ID_REQUEST,
  payload,
  callback,
});

export const getProductSuccess = (payload: GetProductSuccessPayload): GetProductSuccess => ({
  type: ProductType.RECEIVED_PRODUCT,
  payload,
});

export const getProductFailure = (payload: GetProductFailurePayload): GetProductFailure => ({
  type: ProductType.GET_PRODUCT_FAILURE,
  payload,
});

export const fetchProductsByCategory = (payload: any, callback?: Function) => ({
  type: ProductType.GET_PRODUCTS_BY_CATEGORY_REQUEST,
  payload,
  callback,
});

export const fetchProductsByCategorySuccess = (payload: QueryList<Product>, m18ProductCategoryId: number) => ({
  type: ProductType.RECEIVED_PRODUCTS_BY_CATEGORY,
  payload,
  m18ProductCategoryId,
});

export const fetchProductCategories = (payload?: any, callback?: Function): CommonAction => {
  return {
    type: ProductType.GET_CATEGORIES_REQUEST,
    payload,
    callback,
  };
};

export const fetchProductCategoriesSuccess = (payload: ProductCategory[]) => ({
  type: ProductType.RECEIVED_CATEGORIES,
  payload,
});

export const applyFilter = (payload: ProductFilter) => ({
  type: ProductType.APPLY_FILTER,
  payload,
});

export const clearFilter = () => ({
  type: ProductType.CLEAR_FILTER,
  payload: {},
});

export const searchProduct = (payload: any, callback?: Function) => ({
  type: ProductType.SEARCH_PRODUCT_REQUEST,
  payload,
  callback,
});

export const receivedProducts = (payload: Product[]) => ({
  type: ProductType.RECEIVED_PRODUCTS,
  payload,
});

export const checkProductInWishlist = (payload: { proId: number }, callback?: Function) => ({
  type: ProductType.CHECK_PRODUCT_IN_WISHLIST,
  payload,
  callback,
});

export const addProductToWishlist = (payload: { proId: number; noticeStock: boolean }, callback?: Function) => ({
  type: ProductType.ADD_PRODUCT_TO_WISHLIST,
  payload,
  callback,
});

export const removeProductFromWishlist = (payload: { proId: number }, callback?: Function) => ({
  type: ProductType.REMOVE_PRODUCT_FROM_WISHLIST,
  payload,
  callback,
});

export const loadProductComment = (payload: LoadProductCommentPayload, callback?: LoadProductCommentCallback) => ({
  type: ProductType.LOAD_PRODUCT_COMMENT,
  payload,
  callback,
});

export const getProductCommentDetail = (payload: GetProductCommentPayload, callback?: GetProductCommentCallback) => ({
  type: ProductType.GET_PRODUCT_COMMENT_DETAIL,
  payload,
  callback,
});