import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Footer from '@kopapro/components/footer/footer';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ShopLayoutMenuComponent, ShopLayoutStyle } from '@kopapro-redux/types/shop';
import { getFooter, getFooterComponents, getMetaBasicSetup, getShopInfo } from '@kopapro-redux/selectors/entities/shop';
import withConfigurable from '@kopapro/components/preview/configurable';
import { M18RnMeta } from '@kopapro-redux/utils/constant';
import { subscribeNewsletter } from '@kopapro-redux/actions/user';

interface IProps extends WithTranslation {}

interface StateProps {
  layout: ShopLayoutStyle | undefined;
  components: ShopLayoutMenuComponent[];
  planLevel: string;
  copyRightOwner: string;
  backgroundImage: string;
  hideComponents: boolean;
}

interface DispatchProps {
  subscribe: typeof subscribeNewsletter;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const setup = getMetaBasicSetup(state);
  const shopInfo = getShopInfo(state);

  return {
    layout: getFooter(state).layout,
    components: getFooterComponents(state),
    planLevel: setup.planLevel,
    copyRightOwner: setup.copyRightOwner,
    backgroundImage: shopInfo?.footerBanner || '',
    hideComponents: false, // true for manager checkin only
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  subscribe: subscribeNewsletter,
};

const configurableProps = {
  m18CompId: M18RnMeta.UDFFOOTERMENU,
};

export type FooterProps = IProps & StateProps & DispatchProps;

const component = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Footer));
export default withConfigurable(component, configurableProps);
