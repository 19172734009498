import { Component, ReactNode } from 'react';
import { PaymentProps } from '@kopapro/components/order/payment';
import utils from '@kopapro-redux/utils/utils';
import ShopImage from '@kopapro/components/commons/shopImage';
import { orderConfig } from '@kopapro/utils/config';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import DefaultModal from '@kopapro/components/commons/modals';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { PaymentStoredValue } from '@kopapro-redux/types/paymentMethod';
import { AppImages } from '@kopapro/utils/constants/images';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { resolvePaymentMethodImageURL } from '@kopapro/utils/m18';
import { formatBaseAmount } from '@kopapro/utils/m18';
interface PaymentState {
  showModal: boolean;
}

export default class Payment extends Component<PaymentProps, PaymentState> {
  defaultState = {
    showModal: false,
  };
  imageSize = orderConfig.payment.imageSize;
  showMethodName = orderConfig.payment.showMethodName;
  constructor(props: PaymentProps) {
    super(props);
    this.state = { ...this.defaultState };
  }

  showTerm = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showModal: true });
  };

  // handle term modal close
  handleClose = () => {
    this.setState({ showModal: false });
  };

  renderPaymethod(): ReactNode {
    const { t, payment } = this.props;

    const { paymDetail } = payment!;
    const { desc, paymPolicy } = paymDetail!;
    const paymPolicyStr = geti18nValue(paymPolicy);
    const methodName = geti18nValue(desc);
    const showTerm = utils.isNotEmpty(paymPolicyStr);
    if (utils.isEmpty(methodName)) {
      return null;
    }
    return (
      <>
        <ShopImage src={resolvePaymentMethodImageURL(paymDetail)} height={this.imageSize} caption={methodName} />
        {this.showMethodName && <p className="image-text">{methodName}</p>}
        {showTerm && (
          <Button className="modal-btn link-secondary" onClick={this.showTerm} variant="link">
            {t('ce01_pmpcore.kopapro.react.order.showTermPolicy')}
            <HelpOutlineIcon fontSize="small" />
          </Button>
        )}
      </>
    );
  }

  renderCouponItem = (item: PaymentStoredValue): ReactNode => {
    const { t } = this.props;
    const desc = `${geti18nValue(item.svDescObj)} (#${item.svfCode})`;
    return (
      <div className="mb-2" key={`${item.svId}-${item.svfId}`}>
        <ShopImage
          src={item.photoCode}
          width={this.imageSize}
          height={this.imageSize}
          fallbackImage={AppImages.couponPlaceholder}
        />
        <span className="text-truncate d-block" title={desc}>
          {desc}
        </span>
        <div>{`${t('ce01_pmpcore.react.used')} ${formatBaseAmount(item.amt)}`}</div>
      </div>
    );
  };

  renderCoupon(): ReactNode {
    const { t, payment } = this.props;

    const { paymentCoupons } = payment!;
    if (utils.isEmptyList(paymentCoupons)) {
      return null;
    }

    return (
      <div className="mt-4">
        <h5>{t('ce01_pmpcore.react.storedValue')}</h5>
        {paymentCoupons.map((item) => {
          return this.renderCouponItem(item);
        })}
      </div>
    );
  }

  render(): ReactNode {
    const { t, payment } = this.props;
    if (utils.isUndefined(payment)) {
      return null;
    }

    const { paymDetail } = payment!;
    const { paymPolicy } = paymDetail;
    return (
      <>
        <Col xs={12} sm={6} md className="mb-4">
          <h5>{t('ce01_pmpcore.react.paymentMethod')}</h5>

          {this.renderPaymethod()}
          {this.renderCoupon()}
        </Col>
        <DefaultModal
          show={this.state.showModal}
          title={t('ce01_pmpcore.react.order.paymentPolicyTitle')}
          centered={true}
          body={geti18nValue(paymPolicy)}
          onCloseHandler={this.handleClose}
        />
      </>
    );
  }
}
