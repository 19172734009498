import { withTranslation, WithTranslation } from 'react-i18next';
import { LogoListContent } from '@kopapro-redux/types/componentSetting';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import LogoList from '@kopapro/components/shop/logoList/logoList';
import { getDataByComponent } from '@kopapro-redux/selectors/entities/shop';

interface IProps extends WithTranslation {
  compId: string;
  compName: string;
  showIn: boolean;
}

interface StateProps {
  content?: LogoListContent;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const { compId, compName } = props;
  return {
    content: getDataByComponent(state, { compId, compName })?.logoList,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type LogoListProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LogoList));
