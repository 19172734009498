import keyMirror from "@kopapro-redux/utils/key-mirror";

export default keyMirror({
  CHANGE_LANGUAGE: null,
  CHANGE_CURRENCY: null,
  SET_CURRENCY: null,
  SET_DISPLAY_LANGUAGE: null,
  SET_USERINFO: null,
  SET_SESSIONID: null,
  SUMBIT_CONTACT_US: null,
  SUBSCRIBE_NEWSLETTER: null,
  RESET_PASSWORD: null,
  LOAD_REGISTER_FORM: null,
  REGISTER: null,
  GET_LOGIN_INFO: null,
  LOGIN: null,
  AUTO_LOGIN: null,
  LOGOUT: null,
  REFRESH_CAPTCHA: null,
  GET_CAPTCHA: null,
  FORGOT_PASSWORD: null,
  UPLOAD_TEMP_IMAGE: null,
  REQUEST_SMS: null,
  VERIFY_SMS: null,
  REQUEST_EMAIL: null,
  VERIFY_EMAIL: null,
});