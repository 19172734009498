import { Component, ReactNode } from 'react';
import { AmountsProps } from '@kopapro/components/cart/amounts';
import { formatAmount } from '@kopapro/utils/m18';
import { Currency } from '@kopapro-redux/utils/constant';
import ListGroup from 'react-bootstrap/ListGroup';
import { geti18nValue } from '@kopapro-redux/utils/m18';

interface AmountState {}

export default class Amounts extends Component<AmountsProps, AmountState> {
  amountText = (amount: number = 0, positive: boolean = true) => {
    return `${positive ? '' : '-'} ${formatAmount(amount, Currency.SELECTED)}`;
  };

  renderRow(label: string, amount: number = 0, positive: boolean = true, props: any = {}): ReactNode {
    const displayAmount = this.amountText(amount, positive);
    return (
      <ListGroup.Item className='border-0 pt-0 pb-0'>
        <div className="text-end" {...props}>
          {`${label} : ${displayAmount}`}
        </div>
      </ListGroup.Item>
    );
  }

  render(): ReactNode {
    const { t, amountDetails, paymentId, paymentMethods } = this.props;
    const {
      serviceSurchargeAmt,
      shipFeeAmt,
      pmSurchargeAmt,
      promoDiscAmt,
      promoDiscDescObj,
      totalAmount,
      couponAmt,
      outstandAmt,
    } = amountDetails!;
    const payment = paymentMethods.find((item) => {return item.id === paymentId})

    return (
      <ListGroup className="mb-3">
        {serviceSurchargeAmt > 0 &&
          this.renderRow(t('ce01_pmpcore.kopapro.react.serviceSurchargeAmt'), serviceSurchargeAmt)}

        {shipFeeAmt > 0 && this.renderRow(t('ce01_pmpcore.kopapro.react.shipFee'), shipFeeAmt)}

        {pmSurchargeAmt > 0 &&
          this.renderRow(
            `${geti18nValue(payment?.desc!)} ${t('ce01_pmpcore.kopapro.react.paymSurchargeAmt')}`,
            pmSurchargeAmt
          )}

        {promoDiscAmt > 0 && (
          <>
            {this.renderRow(t('ce01_pmpcore.kopapro.react.promoDiscAmt'), promoDiscAmt, false, {
              className: 'text-danger text-end',
            })}
            <ListGroup.Item className="border-0 pt-0 pb-0">
              <div className={'text-danger text-end'}>{`${geti18nValue(promoDiscDescObj)}`}</div>
            </ListGroup.Item>
          </>
        )}

        {this.renderRow(t('ce01_pmpcore.kopapro.react.totalAmount'), totalAmount)}

        {couponAmt > 0 &&
          this.renderRow(t('ce01_pmpcore.kopapro.react.couponAmt'), couponAmt, false, {
            className: 'text-danger text-end',
          })}

        {outstandAmt > 0 && this.renderRow(t('ce01_pmpcore.kopapro.react.netPayment'), outstandAmt)}
      </ListGroup>
    );
  }
}
