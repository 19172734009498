import { RootState } from '@kopapro-redux/store';
import { getComponentSetting, getShopRegisterSetup } from '@kopapro-redux/selectors/entities/shop';
import { RegisterViewContent } from '@kopapro-redux/types/componentSetting';
import { defaultViewContent, getInputFormatInView, getInputFormatListInView } from '@kopapro-redux/utils/m18View';
import utils from '@kopapro-redux/utils/utils';
import { ShopRegisterSetup } from '@kopapro-redux/types/shop';
import { createSelector } from 'reselect';
import { I18nDictionary } from '@kopapro-redux/types/utilities';

export const getRegisterView = (state: RootState) => {
  const registerView: RegisterViewContent = defaultViewContent;
  const registerViewList = getComponentSetting(state).regview;

  if (registerViewList && utils.isNotEmptyList(registerViewList)) {
    return registerViewList[0];
  }
  return registerView;
};

export const getRegisterInputFormat = createSelector([getRegisterView], (registerView) => {
  return getInputFormatInView(registerView);
});

export const getRegisterInputFormatList = createSelector([getRegisterView], (registerView) => {
  return getInputFormatListInView(registerView);
});

export const getRegisterSetupLogo = (state: RootState) => {
  const registerInfo: ShopRegisterSetup = getShopRegisterSetup(state);
  let photoCode: string = '';
  if (registerInfo) {
    photoCode = registerInfo.promotionLogo;
  }
  return photoCode;
};

export const isRegisterSetupUseCaptcha = (state: RootState) => {
  const registerInfo: ShopRegisterSetup = getShopRegisterSetup(state);
  let useCaptcha: boolean = false;
  if (registerInfo) {
    useCaptcha = registerInfo.useCaptcha;
  }
  return useCaptcha;
};

export const getRegisterSetupCaptchaType = (state: RootState) => {
  const registerInfo: ShopRegisterSetup = getShopRegisterSetup(state);
  let captchaType: string = '';
  if (registerInfo) {
    captchaType = registerInfo.captchaType;
  }
  return captchaType;
};

export const getRegisterSetupTermAndCond = (state: RootState) => {
  const registerInfo: ShopRegisterSetup = getShopRegisterSetup(state);
  let termAndCond: I18nDictionary = {};
  if (registerInfo) {
    termAndCond = registerInfo.termAndCond;
  }
  return termAndCond;
};
