import ScrollToTopOnMount from "@kopapro/template/ScrollToTopOnMount";
import { Component } from "react";
import KppBreadcrumb from "@kopapro/components/commons/breadcrumb";
import { CategoryProps } from "@kopapro/components/products/category";
import { ProductCategory } from "@kopapro-redux/types/productCategory";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { getCategoryName, resolveImageURL } from '@kopapro/utils/m18';
import { AppImages } from '@kopapro/utils/constants/images';
import { ImageType } from '@kopapro/utils/constants/constants';
export default class ProductCategories extends Component<CategoryProps> {
  componentDidMount() {
    this.props.loadCategories();
    this.props.clearFilter();
  }

  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t } = this.props;
    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.proCategory'), to: '', active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  renderEmptyCategory = (length: number) => {
    const array = Array.from({ length }, (_, i) => i);
    return array.map((x) => {
      return <div className="col ratio-1x1" key={`empty-col-${x}`}></div>;
    });
  };

  getCardImageComponent = (m18Id: number, displayText: string, photoCodes: string[], imageSize: number) => {
    let cardImage = null;
    if (m18Id === 0) {
      const imageCodes = photoCodes.slice(0, imageSize);
      cardImage = (
        <Card.Body>
          <div className="images-in-one">
            {imageCodes.map((image) => {
              return (
                <div key={`all-${image}`} className="sub-image col">
                  <img
                    src={resolveImageURL(image, false, ImageType.mobilePro)}
                    className="img-fluid img-fit"
                    alt={displayText}
                  />
                </div>
              );
            })}
          </div>
        </Card.Body>
      );
    } else {
      const image = resolveImageURL(photoCodes[0], false, ImageType.mobilePro) || AppImages.productPlaceholder;
      cardImage = <Card.Img className="img-fluid img-fit" src={resolveImageURL(image)} alt={displayText} />;
    }
    return cardImage;
  };

  renderSingleCategory = (category: ProductCategory) => {
    const { t } = this.props;
    const { m18Id, proCnt, code } = category;
    let categoryName = getCategoryName(category);
    if (code === 'All') {
      categoryName = t('ce01_pmpcore.react.all');
    }

    const imageSize = Math.min(category.photoCodes.length, 4);
    const displayText = `${categoryName} (${proCnt})`;
    let cardImage = this.getCardImageComponent(m18Id, displayText, category.photoCodes, imageSize);

    return (
      <div className="category-wrapper col ratio-1x1" key={code}>
        <div className="category shadow ">
          <Link to={`/categories/${code}`}>
            <Card>
              {cardImage}
              <Card.ImgOverlay>
                <Card.Title>{displayText}</Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Link>
        </div>
      </div>
    );
  };

  render() {
    const { categories } = this.props;
    return (
      <div className="categories-container page-container container px-xl-5">
        <ScrollToTopOnMount />

        {this.renderTop()}
        <div className="categories row row-cols-2 row-cols-md-3 row-cols-lg-4 ">
          {categories.map((item) => this.renderSingleCategory(item))}
          {this.renderEmptyCategory(3)}
        </div>
      </div>
    );
  }
}
