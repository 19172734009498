import { Component } from 'react';
import { productDetailConfig as config } from '@kopapro/utils/config';
import { ScrollerProps } from '@kopapro/components/productDetail/imageScroller';
import classnames from 'classnames';
import ShopImage from '@kopapro/components/commons/shopImage';
import { ImageType } from '@kopapro/utils/constants/constants';
import { AppImages } from '@kopapro/utils/constants/images';
import { resolveImageURL } from '@kopapro/utils/m18';
import utils from '@kopapro-redux/utils/utils';

export default class ImageScroller extends Component<ScrollerProps> {
  imageSize = config.thumbnailSize;
  isVertical = config.useVerticalScroller;

  renderImageVerticalScroller = () => {
    const { onSelect, selectedImage, images } = this.props;
    if (!this.isVertical) {
      return null;
    }
    return (
      <div className="d-none d-lg-block col-lg-1 pe-0">
        <div className="image-vertical-scroller">
          <div className="d-flex flex-column">
            {images.map((imageCode: string) => {
              let selected = selectedImage !== imageCode ? 'opacity-5' : '';
              return (
                <span className="kpp-link" key={imageCode} onClick={(e) => onSelect(imageCode)}>
                  <ShopImage
                    className={'rounded mb-2 ratio-1x1 ratio ' + selected}
                    width={this.imageSize}
                    height={this.imageSize}
                    src={resolveImageURL(imageCode, false, ImageType.mobilePro)}
                    fallbackImage={AppImages.productPlaceholder}
                  />
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  renderImageHorizontalScroller = () => {
    /* horizontal image list, if use vertical then show in mobile only */
    const { onSelect, selectedImage, images } = this.props;
    return (
      <div className={classnames('row mt-2', { 'd-block d-lg-none': this.isVertical })}>
        <div className="col-12">
          <div className="d-flex flex-nowrap" style={{ overflowX: 'auto' }}>
            {images.map((imageCode: string) => {
              let selected = selectedImage !== imageCode ? 'opacity-5' : '';
              return (
                <span className="mb-2 me-2 kpp-link" key={imageCode} onClick={(e) => onSelect(imageCode)}>
                  <ShopImage
                    className={'cover rounded border ' + selected}
                    width={this.imageSize}
                    height={this.imageSize}
                    alt=""
                    src={resolveImageURL(imageCode, false, ImageType.mobilePro)}
                    fallbackImage={AppImages.productPlaceholder}
                  />
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { images, vertical } = this.props;
    if (utils.isEmptyList(images)) {
      return null;
    }
    if (vertical) {
      return this.renderImageVerticalScroller();
    }
    return this.renderImageHorizontalScroller();
  }
}
