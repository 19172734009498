import { RootState } from '@kopapro-redux/store';
import { getComponentSetting } from '@kopapro-redux/selectors/entities/shop';
import { OrderViewContent } from '@kopapro-redux/types/componentSetting';
import { defaultViewContent, getInputFormatListInView } from '@kopapro-redux/utils/m18View';
import utils from '@kopapro-redux/utils/utils';

export const getOrderView = (state: RootState) => {
  const orderView: OrderViewContent = defaultViewContent;

  const orderViewList = getComponentSetting(state).orderview;
  if (orderViewList && utils.isNotEmptyList(orderViewList)) {
    return orderViewList[0];
  }
  return orderView;
};

export const getOrderViewId = (state: RootState): number => {
  const orderView = getOrderView(state);
  const viewId = orderView?.view?.viewId;
  if (viewId) {
    return Number(viewId);
  }
  return 0;
};

export const getOrderViewInputFormatList = (state: RootState) => {
  const orderView = getOrderView(state);
  return getInputFormatListInView(orderView);
};
