import { Component, ReactNode } from 'react';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import { ResetPasswordProps } from '@kopapro/components/resetPassword';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import utils from '@kopapro/utils/utils';

interface ResetPasswordState {
  formData: {
    password: string;
    confirmPassword: string;
  };
  errorMessage: string;
  successMessage: string;
  sending: boolean;
}

export default class ResetPassword extends Component<ResetPasswordProps, ResetPasswordState> {
  defaultState = {
    formData: {
      password: '',
      confirmPassword: '',
    },
    errorMessage: '',
    successMessage: '',
    sending: false,
  };

  constructor(props: ResetPasswordProps) {
    super(props);

    this.state = this.defaultState;
  }

  renderTop(): ReactNode {
    return <>{this.renderNav()}</>;
  }

  renderNav(): ReactNode {
    const { t } = this.props;

    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.resetPassword'), active: true },
    ];
    return <KppBreadcrumb items={items} />;
  }

  // handle form value change
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const newValue: any = e.currentTarget.value;
    const formData = { ...this.state.formData, [name]: newValue };
    this.setState({ formData });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { resetPassword } = this.props;
    const self = this;
    event.preventDefault();

    // reset form state
    this.setState({ sending: false, errorMessage: '', successMessage: '' });

    const { token } = this.props;
    const { password, confirmPassword } = this.state.formData;
    let errorMessage = '';

    // check empty
    if (utils.isEmpty(password) || utils.isEmpty(confirmPassword)) {
      errorMessage = 'ce01_pmpcore.react.emtpyPassword';
    } else if (password !== confirmPassword) {
      errorMessage = 'ce01_pmpcore.react.passwordFail';
    }

    if (errorMessage !== '') {
      this.setState({ errorMessage });
      return;
    }

    this.setState({ sending: true });
    // dispatch request
    resetPassword({ token, password, confirmPassword }, function (isSuccess: boolean, message: string = '') {
      // update state errorMessage and successMessage
      if (isSuccess) {
        self.setState({ sending: false, successMessage: message });
      } else {
        self.setState({ sending: false, errorMessage: message });
      }
    });
  };

  getFormGroup(fieldName: string, value: string, props: any = {}): ReactNode {
    const { t } = this.props;
    return (
      <Form.Group className="mb-3" as={Col}>
        <Form.Label>{t(`ce01_pmpcore.react.${fieldName}`)}</Form.Label>
        <Form.Control
          placeholder={t(`ce01_pmpcore.react.${fieldName}`)}
          name={fieldName}
          value={value}
          required
          {...props}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
        />
      </Form.Group>
    );
  }

  renderForm(): ReactNode {
    const { t } = this.props;
    const { sending, errorMessage, successMessage } = this.state;
    const { password, confirmPassword } = this.state.formData;
    return (
      <Col className="d-md-flex justify-content-center">
        <Card className={`col-md-6`}>
          <Card.Body>
            <Form noValidate onSubmit={this.handleSubmit} autoComplete="off">
              <h3 className="text-center">{t('ce01_pmpcore.react.resetPassword')}</h3>
              {this.getFormGroup('password', password, { type: 'password', readOnly: sending })}
              {this.getFormGroup('confirmPassword', confirmPassword, { type: 'password', readOnly: sending })}
              <ErrorMessage message={t(errorMessage)} />
              <SuccessMessage message={t(successMessage)} />
              <Button disabled={sending} variant="main" type="submit" className="w-100 text-uppercase">
                {t('ce01_pmpcore.react.submitBtn')}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  render(): ReactNode {
    return (
      <div className="page-container container px-xl-5 reset-password">
        <ScrollToTopOnMount />
        {this.renderTop()}
        {this.renderForm()}
      </div>
    );
  }
}
