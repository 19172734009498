import { createSelector } from 'reselect';
import { RootState } from '@kopapro-redux/store';
import { BlogCategory, GetBlogListPayload } from '@kopapro-redux/types/blog';
import utils from '@kopapro-redux/utils/utils';

export const getBlogDetail = (state: RootState) => {
  return state.kopapro.blog.blogDetail;
};

export const getBlogList = (state: RootState) => {
  return state.kopapro.blog.blogList;
};

export const getBlogCategories = (state: RootState) => {
  return state.kopapro.blog.blogCategories;
};

export const getBlogDetailById = createSelector(
  [getBlogDetail, (state, blogId: number) => blogId],
  (blogDetail, blogId) => {
    if (blogDetail) {
      return blogDetail[blogId];
    }
    return undefined;
  }
);

export const getBlogListByCond = createSelector(
  [getBlogList, (state, conds: GetBlogListPayload) => conds],
  (blogList, conds) => {
    if (blogList) {
      const keyStr: string = utils.genPayloadKey(conds);
      if (blogList[keyStr]) {
        return blogList[keyStr];
      }
    }
    return undefined;
  }
);

export const getBlogListByCateCode = createSelector(
  [getBlogList, getBlogCategories, (state, params: { code: string; conds: object }) => params],
  (blogList, blogCategories, params) => {
    const { code, conds } = params;
    if (blogList && blogCategories && blogCategories[code]) {
      const blogCate: BlogCategory = blogCategories[code];
      const keyStr: string = utils.genPayloadKey({
        blogCat: blogCate.m18Id,
        ...conds,
      });
      if (blogList[keyStr]) {
        return blogList[keyStr];
      }
    }
    return undefined;
  }
);

export const getBlogCategoryByCode = createSelector(
  [getBlogCategories, (state, code: string) => code],
  (blogCategories, code) => {
    if (blogCategories && blogCategories[code]) {
      return blogCategories[code];
    }
    return undefined;
  }
);


export const getBlogCategoryById = createSelector(
  [getBlogCategories, (_, cateId: number) => cateId],
  (blogCategories, cateId) => {
    if (blogCategories) {
      return Object.values(blogCategories).find((category) => category.m18Id === cateId);
    }

    return undefined;
  }
);
