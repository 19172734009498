import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import RichTextEditor from '@kopapro/components/commons/richTextEditor/richTextEditor';
import { getCurrentLang } from '@kopapro-redux/selectors/entities/user';
import { EditorState } from 'react-draft-wysiwyg';

interface IProps extends WithTranslation {
  editorState?: EditorState;
  onEditorStateChange: any;
}

interface StateProps {
  currentLang: string;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    currentLang: getCurrentLang(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type RichTextEditorProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RichTextEditor));
