import { Component, Fragment, ReactNode } from "react";
import { FooterProps } from "@kopapro/components/footer";
import { getCustomStyle } from "@kopapro/utils/m18";
import utils from "@kopapro/utils/utils";
import { ShopLayoutMenuComponent } from '@kopapro-redux/types/shop';
import Container from 'react-bootstrap/esm/Container';

import { footerConfig } from '@kopapro/utils/config';
import HeaderFooterItem from '@kopapro/components/commons/kppHeaderFooterItem';
import { PlanLevel } from '@kopapro/utils/constants/constants';
import NewsletterForm from '@kopapro/components/shop/newsletter/form';
import { M18RnMeta } from '@kopapro/utils/constants/m18';

class Footer extends Component<FooterProps> {
  // vaiable
  iconSize = footerConfig.iconSize;
  imageSize = footerConfig.imageSize;
  isFluid = footerConfig.isFluid;
  itemProps = {
    imageSize: this.imageSize,
    iconSize: this.iconSize,
  };
  getStyle = (needBackgroundColor = false) => {
    const { backgroundImage } = this.props;
    const { useUdfStyle, backgroundColor, font, fontColor, fontSize, fontStyle } = this.props.layout!;

    const styleProps = { backgroundImage, useUdfStyle, backgroundColor, font, fontColor, fontSize, fontStyle };

    return getCustomStyle(styleProps, needBackgroundColor);
  };

  getPoweredBy = () => {
    const { t, planLevel } = this.props;
    let comp = '<a href="https://kopapro.com" target="_blank">KopaPro</a>';
    if (planLevel !== PlanLevel.Enterprise) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t('ce01_pmpcore.react.poweredByKopaPro', { KopaPro: comp }),
          }}
        />
      );
    }
  };

  defaultFooter = () => {
    const { t, copyRightOwner } = this.props;
    const year = new Date().getFullYear();
    return (
      <footer className="mt-auto py-3 bg-light">
        <div className="container d-flex justify-content-center small text-muted">
          <div className=" pe-2">
            {t('ce01_pmpcore.react.copyRightOwner', {
              year,
              company: copyRightOwner,
            })}
          </div>
          {this.getPoweredBy()}
        </div>
      </footer>
    );
  };

  renderNode = (node: ShopLayoutMenuComponent, showBorder: boolean) => {
    const styles = this.getStyle();
    return (
      <Fragment key={node.compId}>
        <div className={`footer-node col`}>
          <h5 style={styles}>
            <HeaderFooterItem parentType="footer" itemStyles={styles} node={node} {...this.itemProps} />
          </h5>
          <ul>
            {node.children.map((child) => {
              return (
                <li key={child.compId} style={styles}>
                  <HeaderFooterItem parentType="footer" node={child} itemStyles={styles} {...this.itemProps} />
                </li>
              );
            })}
          </ul>
        </div>
        {showBorder && <hr className="w-100 clearfix d-md-none text-muted" />}
      </Fragment>
    );
  };

  renderNewsletter(isMobile: boolean = false): ReactNode {
    if (!this.props.layout?.enableNewsletter) {
      return null;
    }

    let extClassName: string = 'd-none d-sm-block';
    if (isMobile) {
      extClassName = 'd-block d-sm-none';
    }
    return (
      <div className={`p-3 ${extClassName}`}>
        <NewsletterForm
          {...this.props}
          showIn="both"
          compId={M18RnMeta.UDFFOOTERMENU}
          compName={M18RnMeta.UDFFOOTERMENU}
          content={this.props.layout?.newsletterContent}
        />
      </div>
    );
  }

  render(): ReactNode {
    const { t, layout, copyRightOwner, components, hideComponents } = this.props;
    if (utils.isUndefined(layout) || (!layout?.hideUdfMenu && !layout?.useUdfMenu)) {
      return this.defaultFooter();
    } else if (layout.hideUdfMenu) {
      return null;
    }

    const year = new Date().getFullYear();

    let styles = this.getStyle(true);
    let copyrightStyles = { ...styles };
    let footerStyles = { ...styles };
    if (styles.backgroundColor || styles.backgroundImage) {
      copyrightStyles.backgroundColor = 'transparent';
      delete copyrightStyles.backgroundImage;
    }
    const { useUdfStyle, widthType, width } = this.props.layout!;
    if (useUdfStyle && widthType === 'fixed') {
      footerStyles = { ...styles, marginLeft: 'auto', marginRight: 'auto', maxWidth: width };
    }

    const itemPerRow = layout.itemPerRow || 4;
    const itemPerRowMobile = layout.itemPerRowMobile || 1;

    return (
      <footer className="footer border-top" style={footerStyles}>
        {!hideComponents && (
          <Container fluid={this.isFluid} className={`${this.props.layout?.enableNewsletter ? 'd-sm-flex' : ''}`}>
            {this.props.layout?.newsletterPosition === 'left' && this.renderNewsletter()}
            {this.props.layout?.newsletterPositionMobile === 'top' && this.renderNewsletter(true)}

            <section>
              <div className={`row row-cols-${itemPerRowMobile} row-cols-md-${itemPerRow}`}>
                {components.map((node, index: number) => this.renderNode(node, (index + 1) % itemPerRowMobile === 0))}
              </div>
            </section>

            {this.props.layout?.newsletterPosition === 'right' && this.renderNewsletter()}
            {this.props.layout?.newsletterPositionMobile === 'bottom' && this.renderNewsletter(true)}
          </Container>
        )}
        <div className="copyright-box" style={copyrightStyles}>
          <div>
            {t('ce01_pmpcore.react.copyRightOwner', {
              year,
              company: copyRightOwner,
            })}
          </div>
          {this.getPoweredBy()}
        </div>
      </footer>
    );
  }
}

export default Footer;
