import { RootState } from '@kopapro-redux/store';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import { createSelector } from 'reselect';


export const getProductCategories = (state: RootState) => {
  return state.kopapro.productCategories.categories;
};

export const getProductCategoryList = (state: RootState) => {
  if (state) {
    const categories = state.kopapro.productCategories.categories;
    return Object.values(categories);
  }
  return [];
};

export const getProductCategoryShortList = createSelector(

    getProductCategoryList,
    (categories) => {
        if (categories) {
            return categories.map(category => {
                return {
                  code: category.code,
                  desc: geti18nValue(category.desc),
                  proCnt: category.proCnt,
                };
            });
        }
        return [];
    }
)


export const getProductCategoryByM18Id = createSelector(
    [
        getProductCategoryList,
        (state, m18Id: number) => m18Id
    ],
    (categories, m18Id) => {
        if (categories) {
            return categories.find((category) => category.m18Id === m18Id) || {};
        }
        return {};
    }
)



// for saga
export const getProductCategoryByCode = (code: string) => createSelector(
    getProductCategories,
    (categories) => {
        if (categories) {
            return categories[code];
        }
        return undefined;
    }
)
