import React from 'react';
import { geti18nValue, resolvePaymentMethodImageURL } from '@kopapro/utils/m18';
import ShopImage from '@kopapro/components/commons/shopImage';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import { PaymentMethodDetailProps } from '@kopapro/components/paymentMethod/detail';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';

type State = {};

class PaymentMethodDetail extends React.Component<PaymentMethodDetailProps> {
  state: State = {};

  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t, item } = this.props;

    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.paymentMethods'), to: '/payment-methods', active: false },
      { text: geti18nValue(item.desc), to: '', active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  render() {
    const { item } = this.props;
    if (!item) {
      return null;
    }

    const { desc, paymPolicy } = item;
    const policy = geti18nValue(paymPolicy);

    return (
      <div className="page-container container px-xl-5">
        <ScrollToTopOnMount />
        {this.renderTop()}
        <div className="card shadow-sm">
          <div className="card-body">
            <div className="text-center">
              <ShopImage src={resolvePaymentMethodImageURL(item)} className="mw-100 mb-3" />
            </div>
            <h5 className="card-title text-center text-dark text-truncate">{geti18nValue(desc)}</h5>
            <SafetyDOM html={policy} />
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentMethodDetail;
