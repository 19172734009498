import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Information from '@kopapro/components/cart/information/information';
import { withTranslation, WithTranslation } from 'react-i18next';

import { loadCartInformation } from '@kopapro-redux/actions/cart';
import { MemberTypeAlert, PromotionAlert } from '@kopapro-redux/types/cart';
import { getMemberTypeAlert, getPromotionAlert } from '@kopapro-redux/selectors/entities/cart';
import { getSelectedCurrency } from '@kopapro-redux/selectors/entities/user';

interface IProps extends WithTranslation {}

interface StateProps {
  promotionAlert?: PromotionAlert;
  memberTypeAlert?: MemberTypeAlert;
  selectedCurrency: string;
}

interface DispatchProps {
  loadInformation: typeof loadCartInformation;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    promotionAlert: getPromotionAlert(state),
    memberTypeAlert: getMemberTypeAlert(state),
    selectedCurrency: getSelectedCurrency(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadInformation: loadCartInformation,
};

export type InformationProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Information));
