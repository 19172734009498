import { Component } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';
import { kopaproConfig as config } from '@kopapro/utils/config';
import { getWindowScrollTop } from '@kopapro/utils/m18';
interface State {
  isOnTop: boolean;
}

export default class ScrollToTop extends Component<any, State> {
  showButton = config.showScrollToTopButton;

  constructor(props: any) {
    super(props);
    this.state = { isOnTop: true };
  }

  componentDidMount() {
    if (this.showButton) {
      document.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.showButton) {
      document.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = (e: Event): void => {
    if (getWindowScrollTop() > 0) {
      this.setState({ isOnTop: false });
    } else {
      this.setState({ isOnTop: true });
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    if (!this.showButton) {
      return null;
    }

    return (
      <Button
        variant="main"
        onClick={this.scrollToTop}
        className={classnames('scroll-to-top-button ', {
          invisible: this.state.isOnTop,
        })}>
        <KeyboardArrowUpIcon fontSize="large" />
      </Button>
    );
  }
}
