import { Component, ReactNode } from 'react';
import { CategoryListProps } from '@kopapro/components/shop/categoryList/index';
import { Container } from 'react-bootstrap';
import { getCompHeight, getCustomStyle, geti18nValue } from '@kopapro/utils/m18';
import { CategoryListItemContent } from '@kopapro-redux/types/componentSetting';
import ShopImage from '@kopapro/components/commons/shopImage';
import withInfoPlate from '@kopapro/components/commons/infoPlate';
import { Link } from 'react-router-dom';
import utils from '@kopapro/utils/utils';

import { AppImages } from '@kopapro/utils/constants/images';

export default class CategoryList extends Component<CategoryListProps> {
  renderHeader = (): ReactNode => {
    const { enableHeaderStyle, headerDto, headerObj } = this.props.content!;
    const headerText = geti18nValue(headerObj);
    if (utils.isEmpty(headerText)) {
      return null;
    }
    let styleTitle = {};
    if (enableHeaderStyle) {
      styleTitle = getCustomStyle({ ...headerDto, useUdfStyle: true });
    }
    return (
      <div className={`category-list-heading`}>
        <p style={{ ...styleTitle }} className={`text-center fs-1`}>
          {headerText}
        </p>
      </div>
    );
  };

  renderItem = (item: CategoryListItemContent): ReactNode => {
    const { heightOption, udfHeight } = this.props.content!;
    const height = getCompHeight(heightOption, udfHeight, 330);
    const { photoCode, textDto, text, textObj, enableTextStyle, url } = item;

    return (
      <Link to={url} className={`text-white`} style={{ maxHeight: height, maxWidth: height }}>
        <ShopImage
          src={photoCode}
          overlay={{
            ...(enableTextStyle ? textDto : { font: '', fontColor: 'white', fontStyle: '', fontSize: 16 }),
            overlay: text,
            overlayObj: textObj,
            useOverlay: true,
          }}
          className={`cover ratio ratio-1x1`}
          needOverlayBG={true}
          fallbackImage={AppImages.productPlaceholder}
        />
      </Link>
    );
  };

  renderCategoryList = (props: CategoryListProps): JSX.Element => {
    const { compId, content } = props;
    const { data, numPerRow } = content!;
    return (
      <>
        {this.renderHeader()}
        <div className="d-flex flex-wrap">
          {data.map((item, i) => {
            return (
              <div key={`${compId}_${item.photoCode}_${i}`} className={`p-3`} style={{ flex: `${100 / numPerRow}%` }}>
                {this.renderItem(item)}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  render(): ReactNode {
    const { compId, content } = this.props;
    if (utils.isUndefined(content) || utils.isEmptyList(content?.data)) {
      return null;
    }
    const { infoPlateDto } = content!;
    const CompWithInfoPlate = withInfoPlate(this.renderCategoryList, infoPlateDto, 'relative');
    return (
      <Container fluid={'md'}>
        <div id={`category-list-${compId}`} className={`category-list`}>
          <CompWithInfoPlate {...this.props} />
        </div>
      </Container>
    );
  }
}
