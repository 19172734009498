import { Product, ProductAttributeType, ProductGroup } from '@kopapro-redux/types/products';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import {
  addProductToWishlist,
  checkProductInWishlist,
  getProductRequest,
  removeProductFromWishlist,
} from '@kopapro-redux/actions/products';

import {
  getProductAttributeTypesByGroupCode,
  getProductByCode,
  getDefaultProduct,
  getProduct,
  getProductGroupsByGpIds,
  getProductsByGroup,
  getProductGroupByCode,
  getProductCodeBySeoUrl,
} from '@kopapro-redux/selectors/entities/products';
import { getCurrentUserInfo, getSelectedCurrency } from '@kopapro-redux/selectors/entities/user';
import { getCustomBuyButtonStyles, getMetaBasicSetup } from '@kopapro-redux/selectors/entities/shop';
import { getProductQtyInCart } from '@kopapro-redux/selectors/entities/cart';
import { RootState } from '@kopapro-redux/store';
import ProductDetail from '@kopapro/components/productDetail/productDetail';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withNavigation, withParams, withLocation } from '@kopapro/components/commons/router-hoc';
import {
  batchModifyItemInCart,
  modifyItemInCart,
  modifyItemInCartWithPrice,
  batchModifyItemInCartAddOn,
  modifyItemInCartMultiple,
} from '@kopapro-redux/actions/cart';
import { BasicStyle, FeaturedProduct } from '@kopapro/kopapro-redux/types/componentSetting';
import { isEnableFacebookComment } from '@kopapro-redux/selectors/entities/facebook';
import { UserInfo } from '@kopapro-redux/types/user';

interface OwnProps extends WithTranslation {
  location: any; //from withLocation
  params: any; // from withParams
  navigate: any; // from withNavigation
  featuredProductCode?: string;
  liteOptions?: FeaturedProduct & { imageHeight?: number };
  lite?: boolean;
}

interface StateProps {
  groupCode: string;
  productCode: string;
  attributeTypes: ProductAttributeType[];
  products: Product[] | undefined;
  productGroup: ProductGroup | undefined;
  relatedItems: ProductGroup[];
  displayProductCode: string;
  selectedCurrency: string;
  buyButtonStyle?: BasicStyle;
  isEnableFacebookComment: boolean;
  userInfo: UserInfo;
  enableProComment: boolean;
  qtyInCart: number;
  seoUrl: string;
}

interface DispatchProps {
  // general
  loadProduct: typeof getProductRequest;
  addToCart: typeof modifyItemInCart;
  addToCartWithPrice: typeof modifyItemInCartWithPrice;
  addToCartBatch: typeof batchModifyItemInCartAddOn;
  addToCartMultiple: typeof modifyItemInCartMultiple;
  //wish list
  checkFavorite: typeof checkProductInWishlist;
  addFavorite: typeof addProductToWishlist;
  removeFavorite: typeof removeProductFromWishlist;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state: RootState, props: OwnProps) => {
  let products, product;
  let productGroup;
  let productGroupCode = '';
  let relatedProGp: number[] = [];
  let qtyInCart = 0;
  const { gpCode, proCode, seoUrl } = props.params; // props.params.item
  let productCode = proCode || props.featuredProductCode || '';
  if (seoUrl) {
    // get product code first
    productCode = getProductCodeBySeoUrl(state, seoUrl);
  }
  const stateProId = props.location.state;
  if (gpCode) {
    productGroupCode = gpCode;
    products = getProductsByGroup(state, gpCode);
    product = stateProId ? getProduct(state, gpCode, stateProId) : getDefaultProduct(state, gpCode);
  } else if (productCode) {
    product = getProductByCode(state, productCode);

    if (product) {
      productGroupCode = product.gpCode;
      products = getProductsByGroup(state, product.gpCode);
    }
  }

  productGroup = getProductGroupByCode(state, productGroupCode);

  // relatedProGp depends on product group, not product variant
  if (product) {
    relatedProGp = product.relatedProGp;
    if (productGroup) {
      qtyInCart = getProductQtyInCart(state, { proGpId: productGroup.m18ProGpId, proId: product.m18ProId });
    }
    if (stateProId) {
      window.history.replaceState(null, '');
    }
  }

  return {
    groupCode: productGroupCode || '',
    productCode,
    attributeTypes: getProductAttributeTypesByGroupCode(state, productGroupCode),
    products,
    relatedItems: getProductGroupsByGpIds(state, relatedProGp),
    productGroup,
    displayProductCode: product?.code || '',
    selectedCurrency: getSelectedCurrency(state),
    buyButtonStyle: getCustomBuyButtonStyles(state),
    isEnableFacebookComment: isEnableFacebookComment(state),
    userInfo: getCurrentUserInfo(state),
    enableProComment: getMetaBasicSetup(state).enableProComment || false,
    qtyInCart,
    seoUrl,
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  loadProduct: getProductRequest,
  addToCart: modifyItemInCart,
  addToCartWithPrice: modifyItemInCartWithPrice,
  addToCartBatch: batchModifyItemInCartAddOn,
  checkFavorite: checkProductInWishlist,
  addFavorite: addProductToWishlist,
  removeFavorite: removeProductFromWishlist,
  addToCartMultiple: modifyItemInCartMultiple,
};

export type ContainerProps = OwnProps & StateProps & DispatchProps;

const connected = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProductDetail));

export default withNavigation(withLocation(withParams(connected)));
