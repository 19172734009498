import { Component } from 'react';
import { MainImageProps } from '@kopapro/components/productDetail/mainImage';
import ShopImage from '@kopapro/components/commons/shopImage';
import { AppImages } from '@kopapro/utils/constants/images';
import { geti18nValue, resolveImageURL } from '@kopapro/utils/m18';
import React from 'react';
import utils from '@kopapro-redux/utils/utils';
import { ImageType } from '@kopapro/utils/constants/constants';
import classNames from 'classnames';
import { current } from '@reduxjs/toolkit';

export default class ImageScroller extends Component<MainImageProps> {
  imageContainerRef: React.RefObject<any>;
  imageRef: React.RefObject<any>;

  constructor(props: MainImageProps) {
    super(props);
    this.imageContainerRef = React.createRef();
    this.imageRef = React.createRef();
  }

  zoom = (e: any) => {
    if (this.imageContainerRef.current) {
      const zoomer = this.imageContainerRef.current;
      const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = zoomer;
      const offsetX = e.offsetX ? e.offsetX : e.pageX;
      const offsetY = e.offsetY ? e.offsetY : e.pageY;
      const x = ((offsetX - offsetLeft) / offsetWidth) * 100;
      const y = ((offsetY - offsetTop) / offsetHeight) * 100;
      let image = zoomer.children[0];
      const nodes = zoomer.childNodes as Node[];
      nodes.forEach((node) => {
        if (node.nodeName === 'IMG') {
          image = node;
        }
      });

      if (image.naturalWidth > offsetWidth && image.naturalHeight > offsetHeight) {
        // zoomer.style.backgroundPosition = x + '% ' + y + '%';
        zoomer.style.backgroundSize = 'cover';
        // zoomer.style.cursor = 'zoom-in';
      } else {
        zoomer.style.backgroundSize = 'cover';
        zoomer.style.cursor = 'default';
      }
    }
  };

  renderPromotionTag = () => {
    let icons;
    const { proIconsMore } = this.props;
    if (!utils.isEmptyList(proIconsMore.icon)) {
      icons = (
        <div
          className=" d-flex py-2 position-absolute"
          style={{ top: '0', left: '0.5rem', flexFlow: 'column', zIndex: 2 }}>
          {proIconsMore.icon.map((icon) => {
            return (
              <ShopImage
                width="40"
                className="mb-1"
                key={icon}
                src={resolveImageURL(icon, false, ImageType.mobilePro)}
              />
            );
          })}
        </div>
      );
    }
    return icons;
  };

  renderPromotionTagLabel = () => {
    const { proIconsMore } = this.props;
    let iconTextBox;
    const iconsText = geti18nValue(proIconsMore.textLabel);
    if (iconsText.length > 0) {
      iconTextBox = <div className="text-center text-break p-1 bg-dark text-white">{iconsText}</div>;
    }

    return iconTextBox;
  };

  dupArray = (arr: string[] | undefined, times: number): string[] => {
    if (arr !== undefined) {
      return Array(times)
        .fill([...arr])
        .reduce((a, b) => a.concat(b));
    }
    return [];
  };

  render() {
    const { image, lite, imageHeight, productType, images } = this.props;

    let resolvedImage = resolveImageURL(image);

    let resolvedImages = images?.map((elem) => resolveImageURL(elem));
    const resolvedImagesCyc = this.dupArray(resolvedImages, 30);

    const isEmptyImage = utils.isEmpty(resolvedImage);
    let fallbackImage = AppImages.productPlaceholder;
    if (productType === 'coupon') {
      fallbackImage = AppImages.couponPlaceholder;
    }

    const zoom = !isEmptyImage ? this.zoom : () => {};
    if (lite) {
      return (
        <div style={{ height: imageHeight }}>
          {this.renderPromotionTag()}
          <ShopImage
            className="border rounded mw-100"
            alt=""
            height={imageHeight}
            // width={imageHeight}
            width={'auto'}
            src={resolvedImage}
            fallbackImage={fallbackImage}
          />
          {this.renderPromotionTagLabel()}
        </div>
      );
    }

    return (
      <>
        <div
          ref={this.imageContainerRef}
          className={classNames('img-container rounded position-relative', { zoom: !isEmptyImage })}
          onMouseMove={zoom}
          /*
          onMouseEnter={async () => {
            resolvedImagesCyc?.forEach((elem, i) => {
              window.setTimeout(() => {
                this.imageContainerRef.current.style.backgroundImage = `url(${elem})`;
              }, i * 2000);
            });
          }}
          onMouseLeave={() => {
            this.imageContainerRef.current.style.backgroundImage = `url(${resolvedImage})`;
            let id: number = window.setTimeout(() => {}, 0);
            while (id--) {
              clearTimeout(id);
            }
          }}
          */
          style={{ backgroundImage: `url(${resolvedImage})`, transition: 'all 0.5s ease-out' }}>
          {this.renderPromotionTag()}
          <ShopImage
            ref={this.imageRef}
            className="border rounded ratio ratio-1x1"
            alt=""
            src={resolvedImage}
            fallbackImage={fallbackImage}
            images={resolvedImages}
          />
        </div>
        {this.renderPromotionTagLabel()}
      </>
    );
  }
}
