import { ReactNode } from 'react';
import { geti18nValue } from '@kopapro/utils/m18';
import { Card, Row, Col } from 'react-bootstrap';
import ShopImage from '@kopapro/components/commons/shopImage';
import { AppImages } from '@kopapro/utils/constants/images';
import { CouponsProps } from '@kopapro/components/pages/account/coupons';
import Query, { QueryState } from '@kopapro/components/pages/account/query';
import { CouponItem } from '@kopapro-redux/types/coupon';
import { Link } from 'react-router-dom';
import { CouponsContent } from '@kopapro-redux/types/member';
import Loading from '@kopapro/components/commons/loading';
import RemindMessage from '@kopapro/components/commons/remindMessage';
interface CouponsState extends QueryState {}

export default class Coupons extends Query<CouponsProps, CouponsState> {
  override usePagination = false;

  override fetching = false;

  override defaultState = {
    formData: {},
    imageMap: {},
    errorFields: {},
    sending: false,
    errorMessage: '',
    successMessage: '',
    query: { queryId: '', querySize: 0 },
    startPoint: 1,
    dataCount: 9,
    data: [],
    page: 0,
  };

  imageSize = 150;

  constructor(props: CouponsProps) {
    super(props);
    this.state = this.defaultState;
  }

  renderCouponItem = (item: CouponItem): ReactNode => {
    const { t } = this.props;
    const { svId, proId } = item;
    const { svDescObj, photoCode } = item;
    return (
      <Col key={`coupon-item-${svId}`} md="6">
        <Card className="mb-3">
          <div className="d-flex">
            <ShopImage
              height={this.imageSize}
              width={this.imageSize}
              alt=""
              src={photoCode}
              fallbackImage={AppImages.couponPlaceholder}
            />
            <Card.Body className="d-flex flex-column justify-content-between w-50">
              <h5 className="mb-3 text-truncate">{geti18nValue(svDescObj)}</h5>

              <div className="text-end">
                <Link to={`/account/coupons/${svId}`} className="btn btn-outline-secondary me-3">
                  {t('ce01_pmpcore.react.details')}
                </Link>
                <Link to={`/proDetail/0/${proId}`} className="btn btn-outline-main">
                  {t('ce01_pmpcore.react.buy')}
                </Link>
              </div>
            </Card.Body>
          </div>
        </Card>
      </Col>
    );
  };

  renderBody(): ReactNode {
    const { sending, data } = this.state;
    const { t } = this.props;
    if (sending) {
      return <Loading />;
    }
    if (data.length > 0) {
      const content: CouponsContent = data[0].detail[0].content;
      const coupons: CouponItem[] = content.items;
      return (
        <Row>
          {coupons.map((item) => {
            return this.renderCouponItem(item);
          })}
        </Row>
      );
    }

    return <RemindMessage className={`text-center`} message={t(`ce01_pmpcore.react.emptyRecord`)} />;
  }

  render(): ReactNode {
    const { viewName } = this.props;
    return (
      <Card className="border-0 account-coupons">
        <Card.Body>
          <Card.Title>{geti18nValue(viewName)}</Card.Title>
          <hr />

          {this.renderBody()}
        </Card.Body>
      </Card>
    );
  }
}
