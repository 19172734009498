import { Component } from "react";
import Pagination from "react-bootstrap/Pagination";

interface PagerProps {
  current: number;
  itemPerPage: number;
  total: number;
  onPageChange: Function;
}
export default class KppPager extends Component<PagerProps> {
  generateItem = (page: number) => {
    const { current, onPageChange } = this.props;
    const pageIndex = page - 1;
    const currentPage = current + 1;
    return (
      <Pagination.Item active={currentPage === page} key={page} onClick={(e) => onPageChange(pageIndex)}>
        {page}
      </Pagination.Item>
    );
  };

  renderPageItems = (pageNumbers: number[]) => {
    const max = pageNumbers.length;
    const { current } = this.props;
    const currentPage = current + 1;
    let elements = [this.generateItem(1)];

    if (current > 3) {
      elements.push(<Pagination.Ellipsis key="first-ellipsis" />);
    }

    let r = 2,
      r1 = currentPage - r,
      r2 = currentPage + r;

    for (let i = r1 > 2 ? r1 : 2; i <= Math.min(max, r2); i++) {
      elements.push(this.generateItem(i));
    }

    if (r2 + 1 < max) {
      elements.push(<Pagination.Ellipsis key="last-ellipsis" />);
    }
    if (r2 < max) {
      elements.push(this.generateItem(max));
    }
    return elements;
  };

  render() {
    const { itemPerPage, total } = this.props;
    const nPages = Math.ceil(total / itemPerPage);
    const pageNumbers = Array.from(Array(nPages + 1).keys()).slice(1);

    return (
      <nav aria-label="Page navigation example" className="ms-auto">
        <Pagination className="my-0">{this.renderPageItems(pageNumbers)}</Pagination>
      </nav>
    );
  }
}
