import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Google from '@kopapro/components/misc/integration/google/google';

import { getGoogleMeta, getRecaptchaSetup } from '@kopapro-redux/selectors/entities/google';
import { GoogleSetup } from '@kopapro-redux/types/google';

interface IProps {}

interface StateProps {
  setupRecaptcha: boolean;
  google?: GoogleSetup;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    setupRecaptcha: getRecaptchaSetup(state),
    google: getGoogleMeta(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type GoogleProps = IProps & StateProps & DispatchProps;
export default connect(mapStateToProps, mapDispatchToProps)(Google);
