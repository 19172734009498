import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import EmailVerifyModal from '@kopapro/components/commons/verification/emailVerifyModal/emailVerifyModal';
import { verifyEmail, requestEmail } from '@kopapro-redux/actions/user';

interface IProps extends WithTranslation {
  show: boolean;
  email: string;
  onCloseHandler: Function;
  onConfirmHandler: Function;
}

interface DispatchProps {
  requestEmail: typeof requestEmail;
  verifyEmail: typeof verifyEmail;
}

interface StateProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  requestEmail,
  verifyEmail,
};

export type EmailVerifyModalProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EmailVerifyModal));
