import { Component, ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { SmsMobileProps as SmsMobileModalProps } from '@kopapro/components/commons/verification/smsMobileModal';
import { Col, Form, Row } from 'react-bootstrap';
import CountrySelect from '@kopapro/components/commons/inputs/countrySelect';
import utils from '@kopapro/utils/utils';
import ErrorMessage from '@kopapro/components/commons/errorMessage';

interface SmsMobileModalState {
  mobile: string;
  mobileCountry: string;
  errorMessage: string;
}

export default class SmsMobileModal extends Component<SmsMobileModalProps, SmsMobileModalState> {
  defaultState = { mobile: '', mobileCountry: '852', errorMessage: '' };

  constructor(props: SmsMobileModalProps) {
    super(props);
    this.state = {
      ...this.defaultState,
    };
  }

  handleClose = () => {
    if (this.props.onCloseHandler) {
      this.props.onCloseHandler();
    }
  };

  handleOnClick = () => {
    if (utils.isEmpty(this.state.mobile)) {
      this.setErrorState('ce01_pmpcore.react.missingPhonerNum');
      return;
    }

    if (this.props.onCloseHandler) {
      this.props.onConfirmHandler(this.state.mobileCountry, this.state.mobile);
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    let newValue: any = e.currentTarget.value;

    // if not a number, then do not update value
    if (name === 'mobile' && newValue.length > 0 && !/^[0-9\b]+$/.test(newValue)) {
      return;
    }

    this.setState({ ...this.state, [name]: newValue as string });
  };

  setErrorState = (message: string) => {
    this.setState({
      errorMessage: message,
    });
  };

  handleSelect = (value: string) => {
    this.setState({ mobileCountry: value });
  };

  render(): ReactNode {
    const { t, show } = this.props;
    let mobile: string = this.state.mobile;
    let mobileCountry: string = this.state.mobileCountry;
    let title: string = 'ce01_pmpcore.react.smsVerifyMsg';

    return (
      <Modal
        show={show}
        onHide={this.handleClose}
        backdrop={'static'}
        keyboard={false}
        centered={true}
        fullscreen={'sm-down'}>
        <Modal.Header>{<Modal.Title>{t(title)}</Modal.Title>}</Modal.Header>
        <Form noValidate autoComplete="off">
          <Modal.Body>
            <p>{t('ce01_pmpcore.react.smsVerifyMsg2')}</p>

            <Row className={'mb-3 sms-mobile'}>
              <Form.Group as={Col}>
                <CountrySelect
                  value={mobileCountry}
                  isSearchable={false}
                  isDisabled={false}
                  onChange={this.handleSelect}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  placeholder={t(`ce01_pmpcore.react.inputPhoneNumber`)}
                  name={'mobile'}
                  value={mobile}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                />
              </Form.Group>
            </Row>
            <ErrorMessage message={t(this.state.errorMessage)} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={this.handleClose}>
              {t('ce01_pmpcore.react.backBtn')}
            </Button>
            <Button variant="primary" className="btn-main" onClick={this.handleOnClick}>
              {t('ce01_pmpcore.react.submitBtn')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
