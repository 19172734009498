import { Component, ReactNode } from 'react';
import { LiteProductProps } from '@kopapro/components/shop/featuredProduct';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import utils from '@kopapro/utils/utils';
import ProductDetail from '@kopapro/components/productDetail';
import { Product } from '@kopapro/kopapro-redux/types/products';
import { isM18PreviewMode } from '@kopapro-redux/utils/m18';

type State = {
  productCode: string;
};

export default class FeaturedProduct extends Component<LiteProductProps, State> {
  _isMounted = false;
  constructor(props: LiteProductProps) {
    super(props);
    this.state = { productCode: '' };
  }

  componentDidMount() {
    this._isMounted = true;
    const { content } = this.props;
    if (utils.isDefined(content) && content!.proId > 0) {
      this.props.loadProduct({ proId: content!.proId }, this.productLoadedHandler);
    }
  }

  componentDidUpdate(prevProps: Readonly<LiteProductProps>): void {
    const { content } = this.props;
    if (utils.isDefined(content) && content!.proId > 0) {
      if (utils.isUndefined(prevProps.content) || prevProps.content?.proId != content!.proId) {
        this.props.loadProduct({ proId: content!.proId }, this.productLoadedHandler);
      }
    }
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  productLoadedHandler = (product: Product) => {
    if (this._isMounted && product && !utils.isEmpty(product.code)) {
      this.setState({ productCode: product.code });
    }
  };

  renderMore = (proId: number) => {
    const { t, content } = this.props;
    const { showDetail } = content!;
    if (!showDetail) {
      return null;
    }

    let to = '/categories/All';
    if (proId > 0) {
      to = `/products/item/${this.state.productCode}`;
    }

    return (
      <div className="text-end">
        <Link to={to}>
          <Button variant="link">{t('ce01_pmpcore.react.readMore')}</Button>
        </Link>
      </div>
    );
  };

  getImageSize = () => {
    const { photoSize } = this.props.content!;
    let height = 300;
    switch (photoSize) {
      case 's':
        height = 150;
        break;
      case 'm':
        height = 225;
        break;
      case 'l':
        height = 300;
        break;
      case 'xl':
        height = 375;
        break;
    }
    return height;
  };

  render(): ReactNode {
    const { content } = this.props;
    const { productCode } = this.state;
    if (utils.isUndefined(content) || content?.proId === 0 || utils.isEmpty(productCode)) {
      return null;
    }

    const { proId } = content!;
    return (
      <div className={`container`}>
        <ProductDetail
          lite
          featuredProductCode={productCode}
          liteOptions={{ ...content, imageHeight: this.getImageSize() }}
        />
        {this.renderMore(proId)}
      </div>
    );
  }
}
