import { RootState } from "@kopapro-redux/store";
import { getComponentSetting } from "@kopapro-redux/selectors/entities/shop";
import { I18nDictionary } from '@kopapro-redux/types/utilities';

export const getAboutUs = (state: RootState) => {
  let aboutUs: I18nDictionary = {};
  let aboutUsList = getComponentSetting(state).aboutus;

  if (aboutUsList && aboutUsList.length > 0) {
    return aboutUsList[0];
  }

  return aboutUs;
};
