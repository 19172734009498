
export function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  try {
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  } catch (e) {
    console.warn(e);
  }
}

export function getCookie(cname: string) {
  try {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  } catch (e) {
    console.warn(e);
  }

  return '';
}

export function deleteCookie(cname: string) {
  try {
    document.cookie = `${cname}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
  } catch (e) {
    console.warn(e);
  }
}

export function setStorageItem(key: string, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.warn(e);
  }
}

export function getStorageItem(key: string, fallbackValue?: any) {
  try {
    return localStorage.getItem(key) || fallbackValue || '';
  } catch (e) {
    console.warn(e);
  }
  return fallbackValue || '';
}

export function removeStorageItem(key: string) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.warn(e);
  }
}