import { Component, ReactNode } from "react";
import NavDropdown from "react-bootstrap/esm/NavDropdown";
import { CurrencyDropdownProps } from "@kopapro/components/header/components/currency";
import utils from '@kopapro-redux/utils/utils';

class Currency extends Component<CurrencyDropdownProps> {
  render(): ReactNode {
    const { currencies, selectedCurrency, style, fontStyle } = this.props;
    if (utils.isUndefined(currencies)) {
      return null;
    }
    const inheritClass = utils.isNotEmptyJSONObject(fontStyle) ? 'inherit' : '';
    const selectedCurrencyObj = currencies!.find((item) => {
      return item.code === selectedCurrency;
    });
    return (
      <NavDropdown
        className={`currency-dropdown ${inheritClass}`}
        title={utils.isNotEmpty(selectedCurrencyObj?.sym) ? selectedCurrencyObj?.sym : selectedCurrency}
        id={`currency-offcanvasNavbarDropdown-expand`}
        style={{ ...style, ...fontStyle }}>
        {currencies!.map((currency) => {
          return (
            <NavDropdown.Item
              key={currency.m18Id}
              style={fontStyle}
              disabled={selectedCurrency === currency.code}
              onClick={() => this.props.changeCurrency(currency.code)}>
              {utils.isNotEmpty(currency.sym) ? currency.sym : currency.code}
            </NavDropdown.Item>
          );
        })}
      </NavDropdown>
    );
  }
}

export default Currency;
