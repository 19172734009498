import utils from '@kopapro/utils/utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface Props {
  message: string;
  className?: string;
}

function successMessage(props: Props) {
  const { message } = props;

  if (utils.isEmpty(message)) {
    return null;
  }

  return (
    <p className={`text-success ${props.className}`}>
      <CheckCircleIcon /> {message}
    </p>
  );
}
export default successMessage;
