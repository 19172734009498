import { all, call, select, put, takeLatest } from 'redux-saga/effects';

import { UserType } from '@kopapro-redux/action-types';
import KopaproApi from '@kopapro-redux/api';
import { ChangeLanguage, CaptchaPatterns, UserInfo, LoadRegisterFormAction } from '@kopapro-redux/types/user';
import { setDisplayLanguage, setCurrency, setSessionId } from '@kopapro-redux/actions/user';
import { isLanguageValid } from '@kopapro-redux/selectors/entities/shop';
import { isCurrencyValid } from '@kopapro-redux/selectors/entities/currency';
import i18next from 'i18next';
import { CommonAction } from '@kopapro-redux/actions/misc';
import { InputFormatList, InputValues } from '@kopapro-redux/types/componentSetting';
import { getOrderComboLookupSaga } from '@kopapro-redux/sagas/entities/orderSaga';
import { getStorageItem } from '@kopapro-redux/utils/cookies';
import utils from '@kopapro-redux/utils/utils';
import { getRegisterInputFormatList } from '@kopapro-redux/selectors/entities/register';
import { getCheckoutComboLookupSaga } from '@kopapro-redux/sagas/entities/checkoutSaga';
import { defaultUserInfo } from '@kopapro-redux/reducers/entities/user';
import { cartRemovedSaga, reloadCartSaga } from '@kopapro-redux/sagas/entities/cartSaga';
import { getCartId, getCartItems } from '@kopapro-redux/selectors/entities/cart';
import { addMemberToOrder } from '@kopapro-redux/actions/checkout';
import { AddMemberInCheckoutPayload } from '@kopapro-redux/types/checkout';
import { CartItem } from '@kopapro-redux/types/cart';
import { batchModifyItemInCart } from '@kopapro-redux/actions/cart';
import { M18ViewCheckMsg } from '@kopapro-redux/types/m18View';
import { getM18ComboList, getM18LookupList } from '@kopapro-redux/actions/m18Option';
import { UdfInputFieldType } from '@kopapro-redux/utils/constant';
import { resetStorageDataSaga } from '@kopapro-redux/sagas/miscSaga';

/*
TODO:
 2. define route and sub
*/

function* changeLanguageSaga(action: ChangeLanguage) {
  const isValidLang: boolean = yield select(isLanguageValid, action.lang);

  if (isValidLang) {
    // update cookie and state
    yield put(setDisplayLanguage(action.lang));
    yield call(getCheckoutComboLookupSaga);
    yield call(getOrderComboLookupSaga);

    //update i18next
    handleI18n(action.lang);
  }
}

function* sendContactUsSaga(action: CommonAction) {
  const { compId, data } = action.payload;
  const callback = action.callback;

  try {
    const response: { message: string; status: boolean } = yield call(KopaproApi.sendContactUs, compId, data);
    if (callback) {
      callback(response.status, response.message);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* subscribeNewsletterSaga(action: CommonAction) {
  const { email } = action.payload;
  const callback = action.callback;

  try {
    const response: { message: string; status: boolean } = yield call(KopaproApi.subscribeNewsletter, email);
    if (callback) {
      callback(response.status, response.message);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* changeCurrencySaga(action: CommonAction) {
  const { currency } = action.payload;

  try {
    const isValid: boolean = yield select(isCurrencyValid, currency);
    if (isValid) {
      // update cookie and state
      yield put(setCurrency(currency));
    }
  } catch (e) {
    console.error(e);
  }
}

function* resetPasswordSaga(action: CommonAction) {
  const { token, password, confirmPassword } = action.payload;
  const callback = action.callback;
  try {
    const response: { msg: string; status: boolean } = yield call(
      KopaproApi.resetUserPassword,
      token,
      password,
      confirmPassword
    );
    if (callback) {
      callback(response.status, response.msg);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* registerSaga(action: CommonAction) {
  const { formData, password, acceptMarket, data, params } = action.payload;
  const callback = action.callback;
  let inputs: InputValues = formData.inputs || {};

  try {
    const response: {
      status: boolean;
      checkMsg: M18ViewCheckMsg;
      needSMS: boolean;
      paramId: string;
      inputs: InputValues;
    } = yield call(KopaproApi.register, inputs, password, acceptMarket, data, params);

    if (callback) {
      callback(response.status, response.checkMsg, response.needSMS, response.paramId, response.inputs);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* requestSMSSaga(action: CommonAction) {
  const { mobile } = action.payload;
  const callback = action.callback;

  try {
    const response: {
      status: boolean;
      smsId: string;
    } = yield call(KopaproApi.requestSMS, mobile);
    if (callback) {
      callback(response.status, response.smsId);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* verifySMSSaga(action: CommonAction) {
  const { paramId, smsId, smsVeri } = action.payload;
  const callback = action.callback;
  try {
    const response: {
      status: boolean;
      checkMsg: M18ViewCheckMsg;
      smsStatus: boolean;
    } = yield call(KopaproApi.verifySMS, paramId, smsId, smsVeri);

    if (response.status) {
      yield call(getLoginInfoSaga, action);
    }

    if (callback) {
      callback(response.status, response.checkMsg, response.smsStatus);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* getLoginInfoSaga(action: CommonAction) {
  const callback = action.callback;
  const sessionId = getStorageItem('sessionId');
  if (utils.isEmpty(sessionId)) {
    return;
  }

  try {
    const response: { status: boolean; msg: string; userInfo: UserInfo } = yield call(KopaproApi.getLoginInfo);

    if (response.status) {
      const userInfo: UserInfo = response.userInfo;
      if (userInfo) {
        yield put(setSessionId(sessionId, userInfo));
      }
    }

    if (callback) {
      callback(response.status, response.msg, response.userInfo);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* loginSaga(action: CommonAction) {
  const { formData, password, data, params } = action.payload;
  const callback = action.callback;
  const inputs: InputValues = formData.inputs || {};

  try {
    const response: {
      status: boolean;
      userInfo: UserInfo;
      lastOrderId: string;
      checkMsg: M18ViewCheckMsg;
      sessionID: string;
    } = yield call(KopaproApi.login, inputs, password, data, params);

    if (response.status) {
      const userInfo: UserInfo = response.userInfo;
      const sessionId: string = response.sessionID;
      if (userInfo) {
        yield put(setSessionId(sessionId, userInfo));
      }

      // handle user order
      const currentOrderId: string = yield select(getCartId);
      const userOrderId: string = response.lastOrderId;

      if (utils.isNotEmpty(currentOrderId) && utils.isNotEmpty(userOrderId)) {
        // merge order
        const cartItems: CartItem[] = yield select(getCartItems);
        const items = cartItems.filter((item) => !item.isGift && !item.isPackSubPro && !item.isService);
        yield put(batchModifyItemInCart({ orderId: userOrderId, items: items }));
      } else if (utils.isNotEmpty(userOrderId)) {
        // load latest order
        yield call(reloadCartSaga, userOrderId);
      } else if (utils.isNotEmpty(currentOrderId)) {
        // load current order
        const payload: AddMemberInCheckoutPayload = {
          memId: userInfo.uid,
        };
        yield put(addMemberToOrder(payload));
      }
    }

    if (callback) {
      callback(response.status, response.checkMsg);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
    if (callback) {
      callback(false);
    }
  }
}

export function* autoLoginSaga() {
  const sessionId = getStorageItem('sessionId');
  if (utils.isEmpty(sessionId)) {
    return;
  }

  try {
    const response: {
      status: boolean;
      userInfo: UserInfo;
      lastOrderId: string;
    } = yield call(KopaproApi.autoLogin, sessionId);

    if (response.status) {
      const userInfo: UserInfo = response.userInfo;
      const lastOrderId: string = response.lastOrderId;
      if (userInfo) {
        yield put(setSessionId(sessionId, userInfo));
        yield call(reloadCartSaga, lastOrderId);
      }
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* logoutSaga(action: CommonAction) {
  const callback = action.callback;
  const sessionId = getStorageItem('sessionId');
  if (utils.isEmpty(sessionId)) {
    return;
  }

  try {
    const response: { status: boolean } = yield call(KopaproApi.logout, sessionId);
    if (response.status) {
      yield put(setSessionId('', defaultUserInfo));
      yield call(resetStorageDataSaga);
      yield call(cartRemovedSaga);
      if (callback) {
        callback();
      }
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function* forgotPasswordSaga(action: CommonAction) {
  const { email } = action.payload;
  const callback = action.callback;
  try {
    const response: { status: boolean; checkMsg: M18ViewCheckMsg } = yield call(KopaproApi.forgotPassword, email);
    if (callback) {
      callback(response.status, response.checkMsg);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* refreshCaptchaSaga(action: CommonAction) {
  const { captchaID } = action.payload;
  const callback = action.callback;
  try {
    const response: { captchaID: string; pattern: CaptchaPatterns; question: string[] } = yield call(
      KopaproApi.refreshCaptcha,
      captchaID
    );

    if (callback) {
      callback(response.captchaID, response.pattern, response.question);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* uploadTempImageSaga(action: CommonAction) {
  const { file } = action.payload;
  const callback = action.callback;

  try {
    const data = new FormData();
    data.append('file', file);

    const response: { status: boolean; fileName: string } = yield call(KopaproApi.uploadTempImage, data);

    if (callback) {
      callback(response.status, response.fileName);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

export function* loadRegisterFormSaga(action: LoadRegisterFormAction) {
  try {
    //  load combo or lookup
    const extraFields: InputFormatList = yield select(getRegisterInputFormatList);
    yield call(getComboLookupSaga, extraFields, true, true);
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* getComboLookupSaga(extraFields: InputFormatList, isNeedLookup = true, iNeedCombo = false) {
  if (utils.isNotEmptyList(extraFields)) {

    const calls: any[] = [];
    extraFields.forEach((inputFormat) => {
      const { type, pattern } = inputFormat;

      if (iNeedCombo && UdfInputFieldType.COMBO === type) {
        calls.push(put(getM18ComboList({ pattern })));
      }

      if (isNeedLookup && UdfInputFieldType.LOOKUP === type) {
        calls.push(put(getM18LookupList({ pattern })));
      }
    });

    yield all(calls);
  }
}

function* requestEmailSaga(action: CommonAction) {
  const { email } = action.payload;
  const callback = action.callback;

  try {
    const response: { veriId: string } = yield call(KopaproApi.requestEmail, email);
    if (callback) {
      callback(utils.isNotEmpty(response.veriId), response.veriId);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* verifyEmailSaga(action: CommonAction) {
  const { veriId, veriNum } = action.payload;
  const callback = action.callback;
  try {
    const response: { status: boolean } = yield call(KopaproApi.verifyEmail, veriId, veriNum);
    if (callback) {
      callback(response.status);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* userSaga() {
  yield all([takeLatest(UserType.CHANGE_LANGUAGE, changeLanguageSaga)]);
  yield all([takeLatest(UserType.SUMBIT_CONTACT_US, sendContactUsSaga)]);
  yield all([takeLatest(UserType.SUBSCRIBE_NEWSLETTER, subscribeNewsletterSaga)]);
  yield all([takeLatest(UserType.CHANGE_CURRENCY, changeCurrencySaga)]);
  yield all([takeLatest(UserType.RESET_PASSWORD, resetPasswordSaga)]);
  yield all([takeLatest(UserType.REGISTER, registerSaga)]);
  yield all([takeLatest(UserType.GET_LOGIN_INFO, getLoginInfoSaga)]);
  yield all([takeLatest(UserType.LOGIN, loginSaga)]);
  yield all([takeLatest(UserType.AUTO_LOGIN, autoLoginSaga)]);
  yield all([takeLatest(UserType.LOGOUT, logoutSaga)]);
  yield all([takeLatest(UserType.REFRESH_CAPTCHA, refreshCaptchaSaga)]);
  yield all([takeLatest(UserType.FORGOT_PASSWORD, forgotPasswordSaga)]);
  yield all([takeLatest(UserType.UPLOAD_TEMP_IMAGE, uploadTempImageSaga)]);
  yield all([takeLatest(UserType.LOAD_REGISTER_FORM, loadRegisterFormSaga)]);
  yield all([takeLatest(UserType.REQUEST_SMS, requestSMSSaga)]);
  yield all([takeLatest(UserType.VERIFY_SMS, verifySMSSaga)]);
  yield all([takeLatest(UserType.REQUEST_EMAIL, requestEmailSaga)]);
  yield all([takeLatest(UserType.VERIFY_EMAIL, verifyEmailSaga)]);
}

export const handleI18n = (lang: string) => {
  if (lang) {
    i18next.changeLanguage(lang);
  }
};

export default userSaga;
