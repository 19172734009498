import Items from '@kopapro-original/components/order/items/items';
import { ReactNode } from 'react';
import { resolveProductItemImageURL } from '@kopapro/utils/m18';
import ShopImage from '@kopapro/components/commons/shopImage';
import { cartItemConfig } from '@kopapro/utils/config';
import { OrderItem } from '@kopapro-redux/types/order';
import { AppImages } from '@kopapro/utils/constants/images';
import ListGroup from 'react-bootstrap/ListGroup';
import CommentIcon from '@mui/icons-material/Comment';

class CustomItems extends Items {
  renderItem = (item: OrderItem): ReactNode => {
    const { t, isAllowComment } = this.props;
    const { photoCode, isPackSubPro, isExclude } = item;
    if (isExclude) {
      return null;
    }

    const remarkArr: string[] = item.remarks.split('<br>');

    return (
      <ListGroup.Item key={`${item.proId}-${item.lot}`} className="cart-item">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="position-relative">
              <ShopImage
                src={resolveProductItemImageURL(photoCode, item.isSV)}
                height={cartItemConfig.imageSize}
                width={cartItemConfig.imageSize}
                className="cover"
                fallbackImage={AppImages.productPlaceholder}
              />
            </div>

            <div className="ms-3">
              {this.renderNameAndAttribute(item)}
              <div className="mt-1">{this.renderProductTypeIcon(item)}</div>
              <div className="mt-1">{this.renderPrice(item)}</div>

              {remarkArr &&
                remarkArr.map((elem, key) => {
                  return <div className="mt-1">{elem}</div>;
                })}
            </div>
          </div>
        </div>
        {isAllowComment && !isPackSubPro && (
          <div className="my-2">
            <button className="btn px-2" onClick={() => this.showCommentModal(item)}>
              <CommentIcon /> {t('ce01_pmpcore.react.comment')}
            </button>
          </div>
        )}
        <div className="my-2 d-flex justify-content-end">{this.renderQuantity(item)}</div>

        <div className="my-2 d-flex justify-content-between">
          {this.renderPromotion(item)}
          {this.renderAmount(item)}
        </div>

        <div className="d-flex justify-content-end">{this.renderProductReturned(item)}</div>
      </ListGroup.Item>
    );
  };
}

export default CustomItems;
