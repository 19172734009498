import keyMirror from '@kopapro-redux/utils/key-mirror';

export default keyMirror({
  GET_BLOG_DETAIL_REQUEST: null,
  RECEIVED_BLOG_DETAIL: null,
  GET_BLOG_LIST_REQUEST: null,
  RECEIVED_BLOG_LIST: null,
  GET_BLOG_CATEGORIES_REQUEST: null,
  RECEIVED_BLOG_CATEGORIES: null,
  //  TODO handle error
});
