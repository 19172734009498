import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import CouponModal from '@kopapro/components/checkout/payment/couponModal/couponModal';
import { CouponVoucher } from '@kopapro-redux/types/coupon';
import { applyCouponToOrder } from '@kopapro-redux/actions/checkout';
import { PaymentStoredValue } from '@kopapro-redux/types/paymentMethod';

interface ModalProps {
  show: boolean;
  onCloseHandler?: Function;
}

interface IProps extends WithTranslation, ModalProps {
  coupons: CouponVoucher[];
  paymentCoupons: PaymentStoredValue[];
}

interface StateProps {}

interface DispatchProps {
  applyCoupon: typeof applyCouponToOrder;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  applyCoupon: applyCouponToOrder,
};

export type CouponModalProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CouponModal));
