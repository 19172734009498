import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from "@kopapro-redux/store";
import ProductList from "@kopapro/components/products/productList/productList";
import { withTranslation, WithTranslation } from 'react-i18next';

import { getProductCategories, getProductCategoryList } from '@kopapro-redux/selectors/entities/productCategories';
import { fetchProductCategories, fetchProductsByCategory } from '@kopapro-redux/actions/products';
import { ProductCategory } from '@kopapro-redux/types/productCategory';
import { withParams, withSearchParams, withNavigation } from '@kopapro/components/commons/router-hoc';
import { getProductSetupContent } from '@kopapro-redux/selectors/entities/shop';

interface IProps extends WithTranslation {
  navigate: any;
  params: any; // from withParams
  searchParams: any; // from withSearchParams
  isSearch?: boolean; // for searching page
  lite?: boolean; // for home page component
  column?: number;
  row?: number;
  isDefault?: boolean;
}

interface StateProps {
  category: ProductCategory;
  categories: ProductCategory[];
  categoryCode: string;
  categoryId?: number;
  keyword: string;
  proSortBy: string;
}

interface DispatchProps {
  loadCategories: typeof fetchProductCategories;
  loadProducts: typeof fetchProductsByCategory;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  let categoryCode = props.params.code;

  const keyword = props.searchParams[0].get('q') || '';
  if (!categoryCode || props.isSearch) {
    categoryCode = 'All';
  }
  const category = getProductCategories(state)[categoryCode];

  let proSortBy = 'pop';
  const setup = getProductSetupContent(state);
  if (setup) {
    proSortBy = setup.proDefSortingOption || 'pop';
  }
  return {
    categoryCode,
    category,
    categories: getProductCategoryList(state),
    keyword,
    proSortBy,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadCategories: fetchProductCategories,
  loadProducts: fetchProductsByCategory,
};

export type ProductListProps = IProps & StateProps & DispatchProps;
export default withSearchParams(
  withNavigation(withParams(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProductList))))
);
