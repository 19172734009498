import { WithTranslation } from 'react-i18next';
import Select, { components, OptionProps } from 'react-select';
import { callingCodeOptions, ConuntryOption } from '@kopapro/utils/constants/mobileCountries';

interface Props extends WithTranslation {
  value: string;
  onChange: Function;
  isSearchable: boolean;
  isDisabled: boolean;
}
const CountrySelect = ({ onChange, isDisabled, value, isSearchable, t }: Props) => {
  const customOption = (props: OptionProps<ConuntryOption>) => {
    const { data } = props;
    const { value, name } = data;
    return (
      <components.Option
        {...props}
        children={
          <div className="d-flex">
            <div className="right">{`+${value}`}</div>
            <div>{name}</div>
          </div>
        }
      />
    );
  };

  const onSelect = (option: ConuntryOption | null) => {
    onChange(option ? option.value : '');
  };
  const trimedValue = value.replace('+', '');
  const selectedOption = callingCodeOptions.find((option) => option.value === trimedValue);

  return (
    <Select<ConuntryOption>
      className="react-select-container"
      classNamePrefix="select"
      placeholder={t('ce01_pmpcore.react.mobileCountry')}
      value={selectedOption}
      isSearchable={isSearchable}
      name="mobileCountry"
      options={callingCodeOptions}
      onChange={onSelect}
      isDisabled={isDisabled}
      components={{ Option: customOption }}
    />
  );
};

export default CountrySelect;
