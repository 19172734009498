import { Component } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
export default class WhatsappChat extends Component<any> {
  render() {
    const { number } = this.props;
    return (
      <a href={`https://wa.me/${number}`} className="whatsapp_float" target="_blank" rel="noopener noreferrer">
        <WhatsAppIcon className="whatsapp-icon" />
      </a>
    );
  }
}
