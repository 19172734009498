import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import Announcement from '@kopapro/components/header/components/announcement/announcement';
import { closeAnnouncement } from '@kopapro-redux/actions/misc';
import { showAnnouncement } from '@kopapro-redux/selectors/app';
import { getAnnouncementData } from '@kopapro-redux/selectors/entities/shop';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import { BasicStyle } from '@kopapro-redux/types/componentSetting';
interface IProps extends WithTranslation {}

interface StateProps {
  showAnnouncement: boolean;
  content: I18nDictionary;
  enableStyle: boolean;
  style?: BasicStyle;
}

interface DispatchProps {
  closeAnnouncement: typeof closeAnnouncement;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const data = getAnnouncementData(state);

  return {
    showAnnouncement: showAnnouncement(state),
    content: data.announcement,
    enableStyle: data.enableStyle,
    style: data.style,
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  closeAnnouncement,
};

export type CurrencyDropdownProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Announcement));
