import keyMirror from "@kopapro-redux/utils/key-mirror";

export default keyMirror({
  GET_SHOP_META_REQUEST: null,
  GET_SHOP_META_SUCCESS: null,
  GET_SHOP_LAYOUT_SUCCESS: null,
  GET_SHOP_LAYOUT_FAILURE: null,
  GET_SHOP_COMPONENTS_SUCCESS: null,
  GET_SHOP_COMPONENTS_FAILURE: null,
  GET_SHOP_SETUP_SUCCESS: null,
  GET_SHOP_SETUP_FAILURE: null,

  GET_SHOP_BANNER_REQUEST: null,
  RECEIVED_SHOP_BANNERS: null,

  SHOW_SHOP_SPLASH: null,
});