import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withParams, withNavigation } from '@kopapro/components/commons/router-hoc';
import { fetchSinglePageOrder } from '@kopapro-redux/actions/order';
import OnePageCheckout from '@kopapro/components/order/onePageCheckout/onePageCheckout';
import { getCartItems } from '@kopapro-redux/selectors/entities/cart';
import { CartItem } from '@kopapro-redux/types/cart';
interface IProps extends WithTranslation {
  params: any; // from withParams
  navigate: any;
}

interface StateProps {
  orderToken: string;
  items: CartItem[] | undefined;
}

interface DispatchProps {
  fetchSinglePageOrder: typeof fetchSinglePageOrder;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const orderToken = props.params['orderToken'] || '';
  return {
    orderToken,
    items: getCartItems(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  fetchSinglePageOrder: fetchSinglePageOrder,
};

export type OnePageCheckoutProps = IProps & StateProps & DispatchProps;
export default withParams(
  withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OnePageCheckout)))
);
