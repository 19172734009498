import { Component, ReactNode } from 'react';
import { PreviewProps } from '@kopapro/components/preview';
import utils from '@kopapro-redux/utils/utils';
import { M18RnMeta } from '@kopapro-redux/utils/constant';
import AssistiveTouch from '@kopapro/components/preview/assistiveTouch';
import { isNotM18PreviewMode } from '@kopapro-redux/utils/m18';

interface PreviewState {}

const setupCompIds: string[] = [M18RnMeta.SETUP];

const layoutCompIds: string[] = [
  M18RnMeta.SHOP,
  M18RnMeta.LOGIN,
  M18RnMeta.REG,
  M18RnMeta.HOME,
  M18RnMeta.ORDER,
  M18RnMeta.UDFMENU,
  M18RnMeta.UDFFOOTERMENU,
];

const componentSettingCompIds: string[] = [
  M18RnMeta.SHOPINFO,
  M18RnMeta.PROSETUP,
  M18RnMeta.POLICY,
  M18RnMeta.ABOUTUS,

  M18RnMeta.HTML,
  M18RnMeta.BLOGPOST,
  M18RnMeta.CATELIST,
  M18RnMeta.FEATUREDCATE,
  M18RnMeta.GALLERY,
  M18RnMeta.IMAGEWITHTEXT,
  M18RnMeta.IMAGEWITHTEXTOVERLAY,
  M18RnMeta.LOGOLIST,
  M18RnMeta.FEATUREDPRO,
  M18RnMeta.NEWSLETTER,
  M18RnMeta.NEWSLETTERPAGE,
  M18RnMeta.CONTACTUS,
  M18RnMeta.CONTACTUSPAGE,
  M18RnMeta.UDFHTML,

  M18RnMeta.LOGINVIEW,
  M18RnMeta.REGVIEW,
  M18RnMeta.HOMEVIEW,
  M18RnMeta.ORDERVIEW,
];

export default class Preview extends Component<PreviewProps, PreviewState> {
  componentDidMount() {
    if (isNotM18PreviewMode()) {
      return;
    }
    this.createRefreshControl();
  }

  createRefreshControl() {
    const self = this;
    let control = {
      triggerRefresh(target: string) {
        self.updatePreviewData(target);
      },
    };
    if (window) {
      window.parent.portalRefreshControl = control;
    }
  }

  updatePreviewData(data: string) {
    if (utils.isNotEmpty(data)) {
      const { m18CompId, m18Store: m18Data, path } = utils.parseJSON(data);

      console.log('update preview data');
      console.log(`m18CompId : ${m18CompId}`);
      console.log(`m18Store :`);
      console.log(m18Data);

      if (setupCompIds.includes(m18CompId)) {
        this.props.updateSetup(m18Data);
      }

      if (layoutCompIds.includes(m18CompId)) {
        this.props.updateLayout({ m18CompId, m18Data });
      }

      if (componentSettingCompIds.includes(m18CompId)) {
        this.props.updateComponentSetting({ m18CompId, m18Data });
      }

      if (M18RnMeta.BANNER === m18CompId) {
        this.props.updateBanners({ m18CompId, m18Data });
      }

      if (utils.isNotEmpty(path)) {
        this.props.navigate(path);
      }

      if (M18RnMeta.REGVIEW === m18CompId) {
        this.props.loadRegisterFormData({});
      }
    }
  }

  render(): ReactNode {
    if (isNotM18PreviewMode()) {
      return null;
    }
    return (
      <>
        <AssistiveTouch />
      </>
    );
  }
}
