import { all, call, put, takeEvery } from 'redux-saga/effects';

import { M18OptionType } from '@kopapro-redux/action-types';
import KopaproApi from '@kopapro-redux/api';
import { M18ComboList, M18LookupList } from '@kopapro-redux/types/m18Option';
import { CommonAction } from '@kopapro-redux/actions/misc';
import { comboUpdated, lookupUpdated } from '@kopapro-redux/actions/m18Option';

type M18ComboResponse = {
  list: M18ComboList;
  status: boolean;
};

type M18LookupResponse = {
  list: M18LookupList;
  status: boolean;
};

function* getM18ComboListSaga(action: CommonAction) {
  const { pattern } = action.payload;
  try {
    const response: { status: boolean; list: M18ComboList } = yield call(KopaproApi.getM18ComboList, pattern);
    yield call(comboUpdatedSaga, pattern, response);
  } catch (e) {
    console.error(e);
  }
}

function* getM18LookupListSaga(action: CommonAction) {
  const { pattern } = action.payload;
  try {
    const response: { status: boolean; list: M18LookupList } = yield call(KopaproApi.getM18LookupList, pattern);
    yield call(lookupExtraUpdatedSaga, pattern, response);
  } catch (e) {
    console.error(e);
  }
}

function* comboUpdatedSaga(pattern: string, resp: M18ComboResponse) {
  yield put(comboUpdated({ ...resp, pattern: pattern }));
}

function* lookupExtraUpdatedSaga(pattern: string, resp: M18LookupResponse) {
  yield put(lookupUpdated({ ...resp, pattern: pattern }));
}

function* m18OptionSaga() {
  yield all([takeEvery(M18OptionType.GET_M18_COMBO_LIST_REQUEST, getM18ComboListSaga)]);
  yield all([takeEvery(M18OptionType.GET_M18_LOOKUP_LIST_REQUEST, getM18LookupListSaga)]);
}

export default m18OptionSaga;
