import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import LiteProductList from '@kopapro/components/shop/liteProductList/liteProductList';
import { withTranslation, WithTranslation } from 'react-i18next';

import { getProductSetupContent, getDataByComponent } from '@kopapro-redux/selectors/entities/shop';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import { M18RnMeta } from '@kopapro/utils/constants/m18';
import { FeaturedCategory } from '@kopapro-redux/types/componentSetting';

interface IProps extends WithTranslation {
  compId: string;
  compName: string;
  showIn: string;
}

interface DispatchProps {}

interface StateProps {
  title: I18nDictionary | string;
  content?: FeaturedCategory;
  isDefault: boolean;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const { compId, compName } = props;

  let content;
  let isDefault = compId === M18RnMeta.PRODUCT;
  let title: I18nDictionary | string = '';
  if (isDefault) {
    title = getProductSetupContent(state)?.proPageTitle || '';
  } else {
    content = getDataByComponent(state, { compId, compName })?.featuredCate;
  }
  return {
    title,
    content,
    isDefault,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type LiteProductListProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LiteProductList));
