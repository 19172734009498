import Spinner from 'react-bootstrap/Spinner';
import { WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  showMessage?: boolean;
  messageCode?: string;
  className?: string;
  replaceClass?: boolean;
  small?: boolean;
}

function Loading({
  showMessage = true,
  messageCode = 'ce01_pmpcore.kopapro.react.loading',
  className = '',
  replaceClass = false,
  small = false,
  t,
}: Props) {
  let classname = 'loading ' + className;
  if (!replaceClass) {
    classname += ' d-flex align-items-center justify-content-center my-5 py-5';
  }

  const fontSize = small ? '' : 'fs-5';

  return (
    <div className={classname}>
      <Spinner animation="border" className="me-2" size={small ? 'sm' : undefined} />
      {showMessage && <span className={fontSize}>{t(messageCode)}</span>}
    </div>
  );
}
export default Loading;
