import { Component, ReactNode } from 'react';
import { CheckoutCartInfoProps } from '@kopapro/components/checkout/cartInfo';
import Amounts from '@kopapro/components/cart/amounts';
import ShopImage from '@kopapro/components/commons/shopImage';
import { formatAmount, geti18nValue } from '@kopapro/utils/m18';
import { Currency } from '@kopapro-redux/utils/constant';
import { CartItem } from '@kopapro-redux/types/cart';
import RedeemIcon from '@mui/icons-material/Redeem';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import classnames from 'classnames';
import Badge from 'react-bootstrap/Badge';
import DiscountCodeSection from '@kopapro/components/checkout/discountCode';

export default class CartInfo extends Component<CheckoutCartInfoProps> {
  renderAmount(item: CartItem): ReactNode {
    const { isPackSubPro, isGift, isRedemption } = item;
    if (isPackSubPro || (isGift && !isRedemption)) {
      return null;
    }

    const amount: any = formatAmount(item.amt, Currency.SELECTED);
    return <div>{amount}</div>;
  }

  renderProductTypeIcon(item: CartItem): ReactNode {
    const { t } = this.props;
    const { isPackSubPro, isRedemption, isGift } = item;

    let remark: ReactNode = null;
    if (isPackSubPro) {
      return (
        <>
          <Inventory2OutlinedIcon fontSize="small" /> {t('ce01_pmpcore.react.packageProduct')}
        </>
      );
    } else if (isRedemption) {
      return (
        <>
          <RedeemIcon fontSize="small" /> {t('ce01_pmpcore.react.redemption')}
        </>
      );
    } else if (isGift) {
      return (
        <>
          <RedeemIcon fontSize="small" /> {t('ce01_pmpcore.react.gift')}
        </>
      );
    }

    return remark;
  }

  render(): ReactNode {
    const { items, allowEditDiscount, currentSection } = this.props;
    return (
      <div>
        {/* cart items */}
        <div className="cart-info">
          {items!.map((item, index) => {
            const { proCode, photoCode, descObj, attrObj, qty } = item;

            return (
              <div key={`${proCode}-${index}`} className={classnames('d-flex item')}>
                <div className="position-relative">
                  <ShopImage src={photoCode} width="50" height={50} className="contain border" />
                  <Badge className="d-inline  qty-badge" pill bg="secondary">
                    {qty}
                  </Badge>
                </div>
                <div className="d-flex flex-column flex-grow-1 mx-3">
                  <span className="text-break">{geti18nValue(descObj)}</span>
                  <span className="small text-muted">{geti18nValue(attrObj)}</span>
                  <span className="small">{this.renderProductTypeIcon(item)}</span>
                </div>
                {this.renderAmount(item)}
              </div>
            );
          })}
        </div>

        <hr />

        {/* discount code */}
        <DiscountCodeSection allowEditDiscount={allowEditDiscount} currentSection={currentSection} />

        {/* amount total */}
        <Amounts />
      </div>
    );
  }
}
