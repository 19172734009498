import { geti18nValue, getCustomStyle } from '@kopapro/utils/m18';
import { Component, ReactNode } from 'react';
import { NewsletterProps } from '@kopapro/components/shop/newsletter';
import utils from '@kopapro/utils/utils';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import NewspaperIcon from '@mui/icons-material/Newspaper';

interface NewsletterState {
  formData: {
    email: string;
  };
  sending: boolean;
  errorMessage: string;
  successMessage: string;
  showModal: boolean;
}

export default class NewsletterForm extends Component<NewsletterProps, NewsletterState> {
  defaultState = {
    formData: {
      email: '',
    },
    sending: false,
    errorMessage: '',
    successMessage: '',
    showModal: false,
  };

  constructor(props: NewsletterProps) {
    super(props);
    this.state = { ...this.defaultState };
  }

  // handle form value change
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    let newValue: any = e.currentTarget.value;

    const formData = { ...this.state.formData, [name]: newValue };
    this.setState({ formData });
  };

  resetForm = () => {
    this.setState({ formData: this.defaultState.formData, errorMessage: '' });
  };

  // handle submit request
  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { subscribe } = this.props;
    const self = this;
    event.preventDefault();
    event.stopPropagation();
    const email = this.state.formData.email.trim();

    if (utils.isEmpty(email)) {
      this.setState({ errorMessage: 'ce01_pmpcore.react.emptyEmail' });
      return;
    } else if (!utils.isValidEmail(email)) {
      this.setState({ errorMessage: 'ce01_pmpcore.react.invalidEmail' });
      return;
    }

    this.setState({ sending: true, errorMessage: '', successMessage: '' });

    // dispatch request
    subscribe({ email }, function (isSuccess: boolean, message = '') {
      // update state errorMessage and successMessage
      if (isSuccess) {
        self.setState({ sending: false, successMessage: 'ce01_pmpcore.react.submitSuccess' });
        self.resetForm();
      } else {
        self.setState({ sending: false, errorMessage: message });
      }
    });
  };

  renderTitle(): ReactNode {
    const { titleObj } = this.props.content!;
    const { enableTitleStyle, titleDto } = this.props.content!;

    const { t } = this.props;
    const title = geti18nValue(titleObj) || t('ce01_pmpcore.react.subscribeToNewsletter');

    let style = {};
    if (enableTitleStyle) {
      const { font, fontColor, fontSize, fontStyle } = titleDto!;

      style = getCustomStyle({ font, fontColor, fontSize, fontStyle, useUdfStyle: true });
    }
    return <h5 style={style}>{title}</h5>;
  }

  renderDescription(): ReactNode {
    const { descObj } = this.props.content!;
    const { enableDescStyle, descDto } = this.props.content!;

    const desc = geti18nValue(descObj);

    let style = {};
    if (enableDescStyle) {
      const { font, fontColor, fontSize, fontStyle } = descDto!;

      style = getCustomStyle({ font, fontColor, fontSize, fontStyle, useUdfStyle: true });
    }
    return <label style={style}>{desc}</label>;
  }

  renderButtonLabel(): ReactNode {
    const { buttonLabelObj } = this.props.content!;
    const { enableButtonLabelStyle, buttonLabelDto } = this.props.content!;

    const { t } = this.props;
    const buttonLabel = geti18nValue(buttonLabelObj) || t('ce01_pmpcore.react.subscribe');

    let style = {};
    if (enableButtonLabelStyle) {
      const { font, fontColor, fontSize, fontStyle, backgroundColor } = buttonLabelDto!;

      style = getCustomStyle(
        {
          font,
          fontColor,
          fontSize,
          fontStyle,
          backgroundColor,
          useUdfStyle: true,
        },
        true
      );
    }

    const { sending } = this.state;
    return (
      <Button
        disabled={sending}
        variant="main"
        type="submit"
        className="d-flex ms-2 text-uppercase align-items-center text-nowrap"
        style={style}>
        <NewspaperIcon className="me-1" /> {buttonLabel}
      </Button>
    );
  }

  renderEmailInput(): ReactNode {
    const { t } = this.props;
    return (
      <Form.Control
        className="w-auto"
        placeholder={t(`ce01_pmpcore.react.email`)}
        name={'email'}
        value={this.state.formData.email}
        type="email"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
        required
      />
    );
  }

  renderForm(): ReactNode {
    const { t } = this.props;
    const { successMessage, errorMessage } = this.state;

    return (
      <Col xs={12} md={6}>
        <Form className="d-flex" noValidate action="/" onSubmit={this.handleSubmit} autoComplete="off">
          {this.renderEmailInput()}
          {this.renderButtonLabel()}
        </Form>
        <ErrorMessage message={t(errorMessage)} />
        <SuccessMessage message={t(successMessage)} />
      </Col>
    );
  }

  render(): ReactNode {
    if (utils.isUndefined(this.props.content)) {
      return null;
    }

    return (
      <>
        {this.renderTitle()}
        {this.renderDescription()}
        {this.renderForm()}
      </>
    );
  }
}
