import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import Wishlist from '@kopapro/components/pages/account/wishlist/wishlist';

import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';
import { getMemberCenterViewById } from '@kopapro-redux/selectors/entities/member';
import { HomeView } from '@kopapro/utils/constants/constants';
import { getMemberQueryData, queryMemberRequest } from '@kopapro-redux/actions/member';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import { getInputFormatListInView } from '@kopapro-redux/utils/m18View';
import { InputFormatList } from '@kopapro-redux/types/componentSetting';
import { removeProductFromWishlist } from '@kopapro-redux/actions/products';
import withAccount from '@kopapro/components/pages/account/accountHoc';

interface IProps extends WithTranslation {
  navigate: any;
}

interface DispatchProps {
  queryRequest: typeof queryMemberRequest;
  getQueryData: typeof getMemberQueryData;
  removeWishlist: typeof removeProductFromWishlist;
}

interface StateProps {
  userLoggedIn: boolean;
  viewId: string | number;
  viewName: I18nDictionary;
  m18Fields: InputFormatList;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const compId = HomeView.ProductWishList;
  const content = getMemberCenterViewById(state, compId);
  return {
    userLoggedIn: userLoggedIn(state),
    viewId: content?.view.viewId || '',
    viewName: content?.view.viewName || { en: '' },
    m18Fields: getInputFormatListInView(content),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  queryRequest: queryMemberRequest,
  getQueryData: getMemberQueryData,
  removeWishlist: removeProductFromWishlist,
};

export type WishlistProps = IProps & StateProps & DispatchProps;

const connectedWishlist = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Wishlist));
export default withNavigation(withAccount(connectedWishlist, HomeView.ProductWishList));
