import { ReactNode } from 'react';
import { formatUnitPriceWithSelectedCurrency, geti18nValue } from '@kopapro/utils/m18';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ShopImage from '@kopapro/components/commons/shopImage';
import { AppImages } from '@kopapro/utils/constants/images';
import { WishlistProps } from '@kopapro/components/pages/account/wishlist';
import Query, { QueryState } from '@kopapro/components/pages/account/query';
import { MemberQueryItem, WishlistContent } from '@kopapro-redux/types/member';
import { memberCenterConfig } from '@kopapro/utils/config';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from '@kopapro/components/commons/loading';

interface WishlistState extends QueryState {}

export default class Wishlist extends Query<WishlistProps, WishlistState> {
  override usePagination = memberCenterConfig.wishlist.usePagination;

  override fetching = false;

  override defaultState = {
    formData: {},
    imageMap: {},
    errorFields: {},
    sending: false,
    errorMessage: '',
    successMessage: '',
    query: { queryId: '', querySize: 0 },
    startPoint: 1,
    dataCount: 10,
    data: [],
    page: 0,
  };

  imageSize = 200;

  constructor(props: WishlistProps) {
    super(props);
    this.state = this.defaultState;
  }

  removeWishlistItem = (proId: number) => {
    const { removeWishlist } = this.props;
    const self = this;

    removeWishlist({ proId }, function (isSuccess: boolean) {
      if (isSuccess) {
        self.handleQuery();
      }
    });
  };

  renderIconWithMessage = (isShow: boolean, messageCode: string) => {
    const { t } = this.props;
    if (isShow) {
      return (
        <div>
          <InfoIcon /> {t(messageCode)}
        </div>
      );
    }
    return null;
  };

  renderWishlistItem = (item: MemberQueryItem): ReactNode => {
    const detail = item.detail;
    const info: WishlistContent = detail[0].content;

    const { proId, proUp, proName, attrName, isSoldOut, expired, storePublish, photoCode } = info;

    const productName = geti18nValue(proName);
    const attributes = geti18nValue(attrName);
    const unitPrice = formatUnitPriceWithSelectedCurrency(proUp);
    return (
      <Col key={`${info.proId}`} className="mb-3 product-item">
        <Card className="shadow-sm h-100">
          <ShopImage
            className="main-img card-img-top cover ratio ratio-1x1"
            height={this.imageSize}
            alt=""
            src={photoCode}
            fallbackImage={AppImages.productPlaceholder}
          />
          <Card.Body className=" pb-0">
            <div>
              {expired && <span className="text-truncate d-block">{productName}</span>}
              {!expired && (
                <Link to={`/proDetail/0/${proId}`} className="text-truncate d-block">
                  {productName}
                </Link>
              )}
              <div className="card-text text-muted">
                <span className="item-attribute">{attributes}</span>
                <span className="d-block mt-1"> {unitPrice}</span>
                {this.renderIconWithMessage(isSoldOut, 'ce01_pmpcore.react.soldOut')}
                {this.renderIconWithMessage(expired, 'ce01_pmpcore.react.expired')}
                {this.renderIconWithMessage(!storePublish, 'ce01_pmpcore.react.unpublished')}
              </div>
            </div>
          </Card.Body>
          <div className="text-end">
            <button className="btn px-2" onClick={() => this.removeWishlistItem(info.proId)}>
              <DeleteIcon fontSize="small" />
            </button>
          </div>
        </Card>
      </Col>
    );
  };

  renderBody(): ReactNode {
    const { sending, data } = this.state;

    if (sending) {
      return <Loading />;
    }
    return (
      <>
        <Row xs={2} md={3} xl={4}>
          {data.map((item) => {
            return this.renderWishlistItem(item);
          })}
        </Row>

        {/* pagination */}
        {this.renderPagination()}
      </>
    );
  }

  render(): ReactNode {
    const { viewName } = this.props;
    return (
      <Card className="border-0 account-wishlist">
        <Card.Body>
          <Card.Title>{geti18nValue(viewName)}</Card.Title>
          <hr />

          {this.renderBody()}
        </Card.Body>
      </Card>
    );
  }
}
