import { PaymentMethodType } from "@kopapro-redux/action-types";
import { PaymentMethod } from '@kopapro-redux/types/paymentMethod';

export const fetchPaymentMethodsSuccess = (payload: PaymentMethod[]) => ({
  type: PaymentMethodType.RECEIVED_PAYMENTMETHODS,
  payload,
});

export const getPaymentMethods = () => ({
  type: PaymentMethodType.GET_PAYMENTMETHODS_REQUEST,
});
