import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import Payment from '@kopapro/components/checkout/payment/payment';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { PaymentMethod, PaymentStoredValue } from '@kopapro-redux/types/paymentMethod';
import { getPaymentMethodList } from '@kopapro-redux/selectors/entities/paymentMethods';
import { addPaymentToOrder, checkPaymentSurchargeInOrder, loadCouponInOrder } from '@kopapro-redux/actions/checkout';
import {
  getCartAmountDetails,
  getCartPaymentId,
  getCartPaymentCoupons,
  isAllCouponItems,
} from '@kopapro-redux/selectors/entities/cart';
import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';
import { CartAmountDetails } from '@kopapro-redux/types/cart';

interface IProps extends WithTranslation {
  navigate: any;
  componentPrevious: Function;
  componentNext: Function;
}

interface StateProps {
  userLoggedIn: boolean;
  paymentId: number;
  paymentCoupons: PaymentStoredValue[];
  paymentMethods: PaymentMethod[];
  amountDetails?: CartAmountDetails;
  isAllCouponItems: boolean;
}

interface DispatchProps {
  savePaymentMethod: typeof addPaymentToOrder;
  checkSurcharge: typeof checkPaymentSurchargeInOrder;
  loadCoupon: typeof loadCouponInOrder;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    userLoggedIn: userLoggedIn(state),
    paymentId: getCartPaymentId(state),
    paymentCoupons: getCartPaymentCoupons(state),
    paymentMethods: getPaymentMethodList(state),
    amountDetails: getCartAmountDetails(state),
    isAllCouponItems: isAllCouponItems(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  savePaymentMethod: addPaymentToOrder,
  checkSurcharge: checkPaymentSurchargeInOrder,
  loadCoupon: loadCouponInOrder,
};

export type PaymentProps = IProps & StateProps & DispatchProps;
export default withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Payment)));
