import { ShippingRegionType } from '@kopapro-redux/action-types';
import { ShippingRegion } from '@kopapro-redux/types/shippingRegion';

export const fetchShippingRegionSuccess = (payload: ShippingRegion[]) => ({
  type: ShippingRegionType.RECEIVED_SHIPPING_REGION,
  payload,
});

export const getShippingRegion = () => ({
  type: ShippingRegionType.GET_SHIPPING_REGION_REQUEST,
});
