import { geti18nValue, getCustomStyle } from '@kopapro/utils/m18';
import { Component, ReactNode } from 'react';
import { NewsletterProps } from '@kopapro/components/shop/newsletter';
import utils from '@kopapro/utils/utils';
import NewsletterForm from '@kopapro/components/shop/newsletter/form';

export default class Newsletter extends Component<NewsletterProps> {
  render(): ReactNode {
    const { compId } = this.props;

    if (utils.isUndefined(this.props.content)) {
      return null;
    }

    return (
      <div className="container">
        <div id={`newsetter-${compId}`} className={`newsetter row ignore-height-xs `}>
          <NewsletterForm {...this.props} />
        </div>
      </div>
    );
  }
}
