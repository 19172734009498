import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getMemberCenterViewById, getMemberCenterViewDefaultContact } from '@kopapro-redux/selectors/entities/member';
import { InputFormatList } from '@kopapro-redux/types/componentSetting';
import withAccount from '@kopapro/components/pages/account/accountHoc';
import Contact from '@kopapro/components/pages/account/contact/contact';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import utils from '@kopapro-redux/utils/utils';
import { isMemberContactPageContent } from '@kopapro-redux/utils/m18';
import { getInputFormatListInView } from '@kopapro-redux/utils/m18View';
import { withSearchParams } from '@kopapro/components/commons/router-hoc';
import { getM18Combo, getM18Lookup } from '@kopapro-redux/selectors/entities/m18Option';
import { M18ComboList, M18LookupList } from '@kopapro-redux/types/m18Option';
import { getContactInfo, loadMemberForm, updateContactInfo } from '@kopapro-redux/actions/member';
import { UserInfo } from '@kopapro-redux/types/user';
import { getCurrentUserInfo } from '@kopapro-redux/selectors/entities/user';
import { uploadTempImage } from '@kopapro-redux/actions/user';
import { HomeView } from '@kopapro/utils/constants/constants';

interface IProps extends WithTranslation {
  searchParams: any; // from withSearchParams
}

interface DispatchProps {
  loadData: typeof loadMemberForm;
  getContactInfo: typeof getContactInfo;
  updateContactInfo: typeof updateContactInfo;
  uploadTempImage: typeof uploadTempImage;
}

interface StateProps {
  viewId: string | number;
  viewName: I18nDictionary;
  m18Fields: InputFormatList;
  userInfo: UserInfo;

  combo?: { [k: string]: M18ComboList };
  lookup?: { [k: string]: M18LookupList };
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const compId = props.searchParams[0].get('id') || '';
  let content = getMemberCenterViewById(state, compId);

  if (utils.isUndefined(content) || !isMemberContactPageContent(content)) {
    content = getMemberCenterViewDefaultContact(state);
  }

  return {
    viewId: content?.view.viewId || '',
    viewName: content?.view.viewName || { en: '' },
    m18Fields: getInputFormatListInView(content),
    userInfo: getCurrentUserInfo(state),
    combo: getM18Combo(state),
    lookup: getM18Lookup(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadData: loadMemberForm,
  getContactInfo: getContactInfo,
  updateContactInfo: updateContactInfo,
  uploadTempImage: uploadTempImage,
};

export type ContactProps = IProps & StateProps & DispatchProps;

const connectedContact = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Contact));
export default withSearchParams(withAccount(connectedContact, HomeView.ChangeContact));
