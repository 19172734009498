import { CartItem } from '@kopapro-redux/types/cart';
import ShopImage from '@kopapro/components/commons/shopImage';
import { geti18nValue, resolveProductItemImageURL } from '@kopapro/utils/m18';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppImages } from '@kopapro/utils/constants/images';
import { Link } from 'react-router-dom';

import Minicart from '@kopapro-original/components/header/components/minicart/minicart';

class CustomMiniCart extends Minicart {
  renderItems = () => {
    const { items } = this.props;
    if (!items) {
      return null;
    }

    return items.map((item: CartItem, index: number) => {
      const showDeleteButton = this.canDelete(item);
      const { proCode, isPackSubPro, isPackParentPro, isExclude } = item;

      if (isExclude) {
        return null;
      }

      let subProducts = null;

      if (isPackSubPro) {
        return null;
      } else if (isPackParentPro) {
        const isGiftOrRedemption = item.isRedemption || item.isGift;
        subProducts = this.subProductContent(item, isGiftOrRedemption);
      }
      return (
        <li key={`${proCode}_${index}`} className="clearfix cart-item">
          <Link to={this.getProductLink(item)} state={item.proId ? item.proId : null}>
            <ShopImage
              height={this.productImageSize}
              width={this.productImageSize}
              src={resolveProductItemImageURL(item.photoCode, item.isSV)}
              className="contain"
              fallbackImage={AppImages.productPlaceholder}
            />
          </Link>
          <div className="information">
            <div className="item-name-box">
              <Link to={this.getProductLink(item)} state={item.proId ? item.proId : null} className="item-name ">
                {geti18nValue(item.descObj)}
              </Link>

              {showDeleteButton && (
                <span role="button" onClick={(e) => this.removeProductHandler(item)} className="ms-auto">
                  <DeleteIcon />
                </span>
              )}
            </div>
            {this.renderAttribute(item)}
            <div className="d-flex">
              <span className="item-price">{this.renderPrice(item)}</span>
              <span className="item-quantity ms-auto align-self-end">x {item.qty}</span>
            </div>

            {/* 
            <div className="d-flex">
              <span className="item-price">{item.remarks}</span>
            </div>
            */}

            {subProducts}
          </div>
        </li>
      );
    });
  };
}

export default CustomMiniCart;
