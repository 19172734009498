import { Component } from 'react';
import createDOMPurify from 'dompurify';
import { safetyEmbedUrlConfig } from '@kopapro/utils/config';
import { isM18PreviewMode } from '@kopapro-redux/utils/m18';
const DOMPurify = createDOMPurify(window);

interface SafetyDOMProps {
  className?: string;
  html: string;
}

export default class SafetyDOM extends Component<SafetyDOMProps> {
  resolveHtml = (html: string = ''): string => {
    DOMPurify.addHook('beforeSanitizeAttributes', (node) => {
      if (node.tagName === 'IFRAME') {
        const src = node.getAttribute('src') || '';
        const obj = Object.values(safetyEmbedUrlConfig).find((element) => src.startsWith(element));
        if (!obj) {
          if (node.parentNode) {
            node.parentNode.removeChild(node);
          }
        }
      } else if (isM18PreviewMode() && node.parentNode && node.tagName === 'A') {
        const div = document.createElement("div");
        div.innerHTML = node.innerHTML;
        node.parentNode.replaceChild(div, node);
      }
    });

    const pureMsg = DOMPurify.sanitize(html, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['target'],
    });

    return pureMsg;
  };

  render() {
    const { html, className } = this.props;
    const safetyHtml = this.resolveHtml(html || '');

    return (
      <div
        className={`custom-content-box ${className ? className : ''}`}
        dangerouslySetInnerHTML={{ __html: safetyHtml as string }}
      />
    );
  }
}
