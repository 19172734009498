import { format } from 'date-fns';
import { store } from '@kopapro-redux/store';
import { HomeViewContent, InputView } from '@kopapro-redux/types/componentSetting';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import KopaproApi from '@kopapro-redux/api';
import { HomeView } from '@kopapro/utils/constants/constants';
import utils from '@kopapro-redux/utils/utils';

export const isM18PreviewMode = (): boolean => {
  return KopaproApi.getMode() === 'preview';
};

export const isNotM18PreviewMode = (): boolean => {
  return !isM18PreviewMode();
};

export const triggerM18Action = (m18ActionCommand: string) => {
  if (isM18PreviewMode()) {
    if (window) {
      const myView = window.parent.myView;
      if (myView) {
        myView.triggerAction(m18ActionCommand);
      }
    }
  }
};

export function geti18nValue(data: I18nDictionary | string): string {
  const state = store.getState();
  const { lang } = state.kopapro.user;
  let i18nValue = '';

  if (typeof data === 'string') {
    return data;
  } else if (typeof data === 'object') {
    i18nValue = data[lang] || '';

    if (i18nValue.length === 0) {
      i18nValue =
        Object.values(data).find((v) => {
          if (v) {
            return true;
          }
          return false;
        }) || '';
    }
  }

  return i18nValue;
}

export function roundQty(qty: number): number {
  // TODO: might need to support non integer qty
  return Number(qty.toFixed(0));
}

export const formatDate = (datetime: Date | number): string => {
  const date = new Date(datetime);
  if (date.toString() !== 'Invalid Date') {
    return format(date, 'yyyy-MM-dd');
  }
  return date.toString();
};

export const formatTime = (datetime: Date | number): string => {
  const date = new Date(datetime);
  if (date.toString() !== 'Invalid Date') {
    return format(date, 'hh:mm:ss');
  }
  return date.toString();
};

export const generateLink = (content: HomeViewContent, skipParam?: boolean) => {
  const { compId, view } = content;
  const { viewType, viewModule, viewFormat, isDefault } = view;
  const searchParam = isDefault || skipParam ? '' : `?id=${compId}`;

  if (compId === HomeView.ProductWishList) {
    return '/account/wishlist';
  } else if (compId === HomeView.MyEventSchedule) {
    return '/account/schedule';
  } else if (compId === HomeView.MyPrepaidCoupon) {
    return '/account/coupons';
  } else if (compId === HomeView.MyBonusPoints) {
    return '/account/reward';
  } else if (compId === HomeView.ChangePassword) {
    return '/account/password';
  } else if (isMemeberPurchasePage(viewType, viewModule)) {
    return `/account/orders` + searchParam;
  } else if (isMemberAddressPage(viewType, viewModule, viewFormat)) {
    return `/account/address` + searchParam;
  } else if (isMemberContactPage(viewType, viewModule, viewFormat)) {
    return `/account/information` + searchParam;
  } else if (/^\d+$/.test(compId)) {
    // should be the last condition
    return '/account/form' + searchParam;
  }

  return '/account';
};

export const isPageEnabled = (views: HomeViewContent[] | undefined, pageLink: string): boolean => {
  if (utils.isUndefined(views)) {
    return false;
  }
  const isFind = views!.some((view) => {
    return generateLink(view, true) === pageLink;
  });
  return isFind;
};

export const isMemberPurchasePageContent = (content: HomeViewContent | undefined): boolean => {
  if (content && content.view) {
    const { viewType, viewModule } = content.view;
    return isMemeberPurchasePage(viewType, viewModule);
  }
  return false;
};

export const isMemeberPurchasePage = (viewType: string, viewModule: string): boolean => {
  if (viewType === 'pmpcoreCard' && viewModule === 'pos') {
    return true;
  }
  return false;
};

export const isMemberAddressPageContent = (content: HomeViewContent | undefined): boolean => {
  if (content && content.view) {
    const { viewType, viewModule, viewFormat } = content.view;
    return isMemberAddressPage(viewType, viewModule, viewFormat);
  }
  return false;
};

export const isMemberAddressPage = (viewType: string, viewModule: string, viewFormat: InputView): boolean => {
  if (viewType === 'pmpcoreForm' && viewModule === 'poscoreMember') {
    return Object.values(viewFormat.inputFormat).find((input) => input.field === 'pmpcoreDefDelivery') !== undefined;
  }
  return false;
};

export const isMemberContactPageContent = (content: HomeViewContent | undefined): boolean => {
  if (content && content.view) {
    const { viewType, viewModule, viewFormat } = content.view;
    return isMemberContactPage(viewType, viewModule, viewFormat);
  }
  return false;
};

export const isMemberContactPage = (viewType: string, viewModule: string, viewFormat: InputView): boolean => {
  if (viewType === 'pmpcoreForm' && viewModule === 'poscoreMember') {
    return Object.values(viewFormat.inputFormat).find((input) => input.field === 'pmpcoreDefDelivery') === undefined;
  }
  return false;
};


export const findObject = (src: any, targetProp: string): any => {
  let result: any = undefined;
  if (src instanceof Array) {
    for (const nested of src) {
      result = findObject(nested, targetProp);
      if (result) {
        break;
      }
    }
  } else {
    for (var prop in src) {
      if (prop === targetProp) {
        return src[prop];
      } else if (src[prop] instanceof Object || src[prop] instanceof Array) {
        result = findObject(src[prop], targetProp);
      }
      if (result) {
        break;
      }
    }
  }
  return result;
};

export const fillObject = (src: any, search: string, targetProp: string, value: any) => {
  if (src instanceof Array) {
    for (const nested of src) {
      fillObject(nested, search, targetProp, value);
    }
  } else {
    for (var prop in src) {
      if (prop === search) {
        src[search][targetProp] = value;
      } else if (src[prop] instanceof Object || src[prop] instanceof Array) {
        fillObject(src[prop], search, targetProp, value);
      }
    }
  }
};

//
export const keepValueFromSource = (src: any, dist: any, search: string, props: string): any => {
  const value = (findObject(src, search) || {})[props];
  let result = { ...dist };
  fillObject(result, search, props, value);
  return result;
};