import { MiscType } from '@kopapro-redux/action-types';

export interface CommonAction {
    type: string;
    payload?: any;
    callback?: Function;
}


// init kopapro shop
export const initDataRequest = () => ({
    type: MiscType.INIT_SHOP_REQUEST
});

export const initializedData = () => ({
  type: MiscType.SHOP_INITIALIZED,
});

export const updateKopaproExpired = (status: boolean) => ({
  type: MiscType.UPDATE_KOPAPRO_EXPIRED,
  status,
});

export const checkUpdate = (isInit: boolean) => ({
  type: MiscType.CHECK_SHOP_UPDATE,
  isInit,
});

export const shopUpdating = (status: boolean) => ({
  type: MiscType.SHOP_UPDATING,
  status,
});

export const closeAnnouncement = () => ({
  type: MiscType.CLOSE_ANNOUNCEMENT,
});