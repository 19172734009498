import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Payment from '@kopapro/components/payment/payment';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withNavigation, withParams } from '@kopapro/components/commons/router-hoc';
import { getOrder as loadOrder, processOnlinePayment } from '@kopapro-redux/actions/order';
import { getOrder } from '@kopapro-redux/selectors/entities/order';

interface IProps extends WithTranslation {
  navigate: any;
  params: any;
}

interface StateProps {
  orderId: string;
  orderCode: string;
  outstandingAmount: number;
  orderStatus: string;
  isRequirePayment: boolean;
  payStatus: string;
  isOnlinePayment: boolean;
}

interface DispatchProps {
  loadOrder: typeof loadOrder;
  getOnlinePayment: typeof processOnlinePayment;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const orderId = props.params['orderId'] || '';

  const order = getOrder(state);
  if (order && order.orderId === orderId) {
    return {
      orderId,
      orderCode: order.orderInfo.orderCode,
      outstandingAmount: order.amountDetails.outstandAmt,
      orderStatus: order.orderInfo.orderStatus,
      payStatus: order.orderInfo.payStatus,
      isRequirePayment: order.orderInfo.isRequirePayment,
      isOnlinePayment: order.payment?.paymDetail.online || false,
    };
  }

  return {
    orderId,
    orderCode: '',
    outstandingAmount: 0,
    orderStatus: '',
    isRequirePayment: false,
    isPaymentSettled: false,
    payStatus: '',
    isOnlinePayment: false,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadOrder,
  getOnlinePayment: processOnlinePayment,
};

export type PaymentProps = IProps & StateProps & DispatchProps;
export default withParams(withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Payment))));
