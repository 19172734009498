import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withSearchParams } from '@kopapro/components/commons/router-hoc';
import Orders from '@kopapro/components/pages/account/orders/orders';

import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';
import { getMemberCenterViewById, getMemberCenterViewDefaultOrders } from '@kopapro-redux/selectors/entities/member';
import { InputFormatList } from '@kopapro-redux/types/componentSetting';

import withAccount from '@kopapro/components/pages/account/accountHoc';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import utils from '@kopapro-redux/utils/utils';
import { getInputFormatListInView } from '@kopapro-redux/utils/m18View';
import { getMemberQueryData, queryMemberRequest } from '@kopapro-redux/actions/member';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import { isMemberPurchasePageContent } from '@kopapro-redux/utils/m18';
import { HomeView } from '@kopapro/utils/constants/constants';

interface IProps extends WithTranslation {
  navigate: any;
  searchParams: any; // from withSearchParams
}

interface DispatchProps {
  queryRequest: typeof queryMemberRequest;
  getQueryData: typeof getMemberQueryData;
}

interface StateProps {
  userLoggedIn: boolean;
  viewId: string | number;
  viewName: I18nDictionary;
  m18Fields: InputFormatList;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const compId = props.searchParams[0].get('id') || '';
  let content = getMemberCenterViewById(state, compId);
  if (utils.isUndefined(content) || !isMemberPurchasePageContent(content)) {
    content = getMemberCenterViewDefaultOrders(state);
  }
  return {
    userLoggedIn: userLoggedIn(state),
    viewId: content?.view.viewId || '',
    viewName: content?.view.viewName || { en: '' },
    m18Fields: getInputFormatListInView(content),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  queryRequest: queryMemberRequest,
  getQueryData: getMemberQueryData,
};

export type OrdersProps = IProps & StateProps & DispatchProps;

const connectedOrders = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Orders));
export default withNavigation(withSearchParams(withAccount(connectedOrders, HomeView.MyPurchase)));
