import { Component } from 'react';

export type State = {
  data: string;
  list?: string[];
};

export default function withState(WrappedComponent: any) {
  return class extends Component<any, State> {
    constructor(props: any) {
      super(props);
      this.state = {
        data: '123',
      };
    }

    updateState = (
      newState:
        | State
        | ((prevState: Readonly<State>, props: Readonly<any>) => State | Pick<State, keyof State> | null)
        | Pick<State, keyof State>
        | null
    ) => {
      this.setState({ ...newState });
    };

    render() {
      return <WrappedComponent {...this.props} updateLocalState={this.updateState} stateData={this.state} />;
    }
  };
}
