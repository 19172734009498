import { Component, ReactNode } from 'react';
import { OnePageCheckoutProps } from '@kopapro/components/order/onePageCheckout';
import Loading from '@kopapro/components/commons/loading';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import { ListGroup } from 'react-bootstrap';
import ProductDetail from '@kopapro/components/productDetail';
import { OnePageCheckoutData } from '@kopapro-redux/types/order';
import ShareButtons from '@kopapro/components/commons/shareButtons';
import Checkout from '@kopapro/components/checkout';
import Cart from '@kopapro/components/cart';
import utils from '@kopapro/utils/utils';

/*
TODO:
 1. lookup lable when change language
*/

interface OnePageCheckoutState {
  loadingData: boolean;
  data: OnePageCheckoutData | undefined;
  productCodes: string[];
  checkoutSection: string;
}

const defaultOnePageCheckoutSections = { Cart: 'cart', Checkout: 'checkout' };

export default class OnePageCheckout extends Component<OnePageCheckoutProps, OnePageCheckoutState> {
  defaultState = {
    loadingData: true,
    productCodes: [],
    data: undefined,
    checkoutSection: defaultOnePageCheckoutSections.Cart,
  };

  constructor(props: OnePageCheckoutProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount() {
    const { fetchSinglePageOrder, orderToken } = this.props;
    fetchSinglePageOrder({ orderToken }, this.loadSinglePageOrderCallback);
  }

  componentDidUpdate(prevProps: Readonly<OnePageCheckoutProps>): void {
    if (!this.state.loadingData && JSON.stringify(prevProps.items) !== JSON.stringify(this.props.items)) {
      this.onePageCartBack();
    }
  }

  loadSinglePageOrderCallback = (status: boolean, data: OnePageCheckoutData, onePageProductCodes: string[]) => {
    const { navigate } = this.props;
    if (status) {
      const productCodes = onePageProductCodes || [];
      utils.setDocumentTitle(data.title);
      this.setState({ loadingData: false, data, productCodes });
    } else {
      navigate('/page-not-found');
    }
  };

  renderTitle(): ReactNode {
    const { data } = this.state;
    if (data) {
      const { dDesc } = data;
      return (
        <h4 className="text-center">
          <SafetyDOM html={geti18nValue(dDesc)} />
        </h4>
      );
    }
    return null;
  }

  renderShareBtn(): ReactNode {
    const { title } = this.state.data!;
    return (
      <div className="mt-1">
        <ShareButtons url={window.location.href} title={title} />
      </div>
    );
  }

  renderFeaturedProduct(): ReactNode {
    const { productCodes } = this.state;

    return (
      <div>
        <ListGroup variant="flush">
          {productCodes.map((item: string) => {
            return (
              <ListGroup.Item key={`one-page-item-${item}`} className="col d-flex">
                <div className={`container`}>
                  <ProductDetail
                    lite
                    featuredProductCode={item}
                    liteOptions={{ imageHeight: 300, showBuyNow: false, showShareBtn: false }}
                  />
                </div>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
        <hr className="my-1" />
      </div>
    );
  }

  renderCart(): ReactNode {
    const { checkoutSection } = this.state;
    if (checkoutSection === defaultOnePageCheckoutSections.Checkout) {
      return <Checkout onePage onePageBackToCartHandler={this.onePageCartBack} />;
    } else if (checkoutSection === defaultOnePageCheckoutSections.Cart) {
      return <Cart onePage onePageCartNext={this.onePageCartNext} />;
    }
  }

  onePageCartBack = () => {
    this.setState({ checkoutSection: defaultOnePageCheckoutSections.Cart });
  };
  onePageCartNext = () => {
    this.setState({ checkoutSection: defaultOnePageCheckoutSections.Checkout });
  };

  render(): ReactNode {
    const { loadingData } = this.state;
    if (loadingData) {
      return <Loading />;
    }
    return (
      <div className="page-container container px-xl-5 my-order">
        <ScrollToTopOnMount />
        {this.renderTitle()}
        {this.renderShareBtn()}
        {this.renderFeaturedProduct()}
        {this.renderCart()}
      </div>
    );
  }
}
