import React, { useRef, useState, useEffect } from 'react';
import { format, set, parse, isValid } from 'date-fns';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Divider } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { animateScroll } from '@kopapro/utils/animateScroll';
import classNames from 'classnames';
import { WithTranslation } from 'react-i18next';
interface valueType {
  label: string;
  value: number;
}

const getFormat = function (hideSecond: boolean, isTwelveHour: boolean): string {
  let formatPattern = 'HH:mm';

  if (!hideSecond) {
    formatPattern += ':ss';
  }

  if (isTwelveHour) {
    formatPattern += ' aaa';
    formatPattern.toLowerCase();
  }

  return formatPattern;
};

const generateMap = function (length: number, start = 1) {
  let result: { [k: string]: valueType } = {};

  Array(length)
    .fill(0)
    .forEach((item, i) => {
      const value = start + i;
      const label = String(value).padStart(2, '0');
      result[label] = { label, value };
    });
  return result;
};

function compareNumbers(a: valueType, b: valueType) {
  return a.value - b.value;
}

const MERIDIAN_MAP = { am: { label: 'am', value: 1, index: 0 }, pm: { label: 'pm', value: 2, index: 1 } };
const MERDIAN_LIST = Object.values(MERIDIAN_MAP);

export interface TimePicker extends WithTranslation {
  placeholder?: string;
  showClockIcon?: boolean;
  useTwelveHourFormat?: boolean;
  hideSecond?: boolean;
  allowBackdrop?: boolean;
  value: Date | null;
  onChange?: Function;
  onError?: Function;
  className?: string;
  // children: React.ReactNode
}

const TimePicker = ({
  placeholder = 'hh:mm',
  showClockIcon = true,
  useTwelveHourFormat = false,
  hideSecond = true,
  allowBackdrop = true,
  value = null,
  onChange,
  onError,
  className = '',
  t,
}: TimePicker) => {
  const [cachedText, setCachedText] = useState(''); // cached input value for restore
  const [displayText, setDisplayText] = useState(''); // user input / changed by picker
  const [showDropdown, setShowDropdown] = useState(false);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [meridian, setMeridian] = useState(MERIDIAN_MAP['am']);
  const hourWrapper = useRef<HTMLDivElement>(null);
  const minuteWrapper = useRef<HTMLDivElement>(null);
  const secondWrapper = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formatPattern = getFormat(hideSecond, useTwelveHourFormat);
    let pattern = formatPattern.replace('aaa', 'aa');

    if (inputValue.length > pattern.length) {
      return;
    }
    //
    if (inputValue.length === 0) {
      setDisplayText('');
      setCachedText('');
      if (onChange) {
        onChange(null);
      }
      return;
    }

    let test = inputValue.replaceAll(':', '');
    if (useTwelveHourFormat) {
      test = test.replace('a', '');
      test = test.replace('m', '');
      test = test.replace('p', '');
      test = test.replaceAll(' ', '');
    }

    // if not digit
    if (!/^\d+$/.test(test)) {
      return;
    }

    // hh:mm hh:mm am hh:mm:ss
    let time = parse(inputValue, formatPattern, new Date());

    // fast check input format
    if (isValid(time) && inputValue !== format(time, formatPattern)) {
      time = new Date(NaN);
    }
    if (onChange) {
      onChange(time);
    }

    if (onError) {
      if (isValid(time)) {
        onError(null);
      } else {
        onError('invalidTime');
      }
    }

    // try parse time, if valid
    setDisplayText(inputValue);
    setCachedText(inputValue);
  };

  const resetPicker = () => {
    setHour(0);
    setMinute(0);
    setSecond(0);
    setMeridian(MERIDIAN_MAP['am']);
  };

  const handleClockIconClick = () => {
    if (showDropdown) {
      // revert value
      setDisplayText(cachedText);
      setShowDropdown(false);
    } else if (inputRef.current) {
      setShowDropdown(true);
    }
  };

  const handleButtonClick = () => {
    const time = getPickerDateValue();
    const timeValue = formatTime(time);
    if (timeValue != displayText) {
      setDisplayText(timeValue);
      setCachedText(timeValue);
    }
    if (onChange) {
      onChange(time);
    }
    if (onError) {
      onError(null);
    }
    setShowDropdown(false);
  };

  const scrollTo = (wrapperRef: React.RefObject<HTMLDivElement>, animate: boolean) => {
    let wrapper = wrapperRef.current;

    if (wrapper) {
      const parent = wrapper.parentElement;
      let selected = wrapper.querySelector('.active') as HTMLElement;
      if (parent && selected) {
        const targetPosition = selected.offsetTop + 128 - parent.clientHeight - parent.offsetTop;

        if (targetPosition !== parent.scrollTop) {
          if (animate) {
            animateScroll({ targetPosition, initialPosition: parent.scrollTop, duration: 200 }, parent);
          } else {
            parent.scrollTo({ top: targetPosition });
          }
        }
      }
    }
  };

  const formatTime = (date: Date) => {
    let formatPattern = getFormat(hideSecond, useTwelveHourFormat);
    return format(date, formatPattern);
  };

  const scrollToValue = (animate = true) => {
    scrollTo(hourWrapper, animate);
    scrollTo(minuteWrapper, animate);
    scrollTo(secondWrapper, animate);
  };

  const getPickerDateValue = () => {
    let a = 'am';
    let h = hour;
    if (meridian?.value) a = meridian.label;
    if (useTwelveHourFormat && a === 'pm') {
      h += 12;
    }
    const time = set(new Date(), { hours: h, minutes: minute, seconds: second });
    return time;
  };

  useEffect(() => {
    if (showDropdown) {
      scrollToValue(false);
    }
  }, [showDropdown]);

  useEffect(() => {
    const time = getPickerDateValue();

    if (hour || minute || second || meridian) {
      let textValue = formatTime(time);
      // only update when using dropdown
      if (showDropdown) {
        setDisplayText(textValue);
      }
    }
  }, [hour, minute, second, meridian]);

  useEffect(() => {
    scrollTo(hourWrapper, true);
  }, [hour]);

  useEffect(() => {
    scrollTo(minuteWrapper, true);
  }, [minute]);

  useEffect(() => {
    scrollTo(secondWrapper, true);
  }, [second]);

  useEffect(() => {
    if (value && isValid(value)) {
      const hours = value.getHours(); // 0-23
      setHour(value.getHours());
      setMinute(value.getMinutes());
      setSecond(value.getSeconds());

      if (hours < 12) {
        setMeridian(MERIDIAN_MAP['am']);
      } else {
        setMeridian(MERIDIAN_MAP['pm']);
      }
      const newTextValue = formatTime(value);
      if (newTextValue !== displayText) {
        setDisplayText(newTextValue);
        setCachedText(newTextValue);
      }
    }
  }, [value]);

  const hourList = Object.values(generateMap(useTwelveHourFormat ? 12 : 24, useTwelveHourFormat ? 1 : 0)).sort(
    compareNumbers
  );
  const minSecList = Object.values(generateMap(60, 0)).sort(compareNumbers);

  return (
    <div className={`time-picker-dropdown d-flex ${className}`}>
      <div className={`d-flex position-relative flex-column align-items-end w-100`}>
        <Form.Control
          type="text"
          value={displayText}
          ref={inputRef}
          onChange={changeHandler}
          placeholder={placeholder ? placeholder : ''}
        />

        {showClockIcon && (
          <InputAdornment position="end">
            <IconButton aria-label="close time picker" onClick={handleClockIconClick} edge="end">
              <AccessTimeIcon />
            </IconButton>
          </InputAdornment>
        )}
        <Card className={classNames('dropdown-wrapper shadow', { show: showDropdown })}>
          <div className="options-container">
            <div className="option-wrapper">
              <div className="time-picker-options" ref={hourWrapper}>
                {hourList.map((option) => (
                  <div
                    className={classNames('time-picker-option', { active: hour === option.value })}
                    key={`hour-option-${option.value}`}
                    onClick={(e) => {
                      setHour(option.value);
                    }}>
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
            <div className="option-wrapper">
              <div className="time-picker-options" ref={minuteWrapper}>
                {minSecList.map((option) => (
                  <div
                    className={classNames('time-picker-option', { active: minute === option.value })}
                    key={`min-option-${option.value}`}
                    onClick={(e) => {
                      setMinute(option.value);
                    }}>
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
            <div className={`option-wrapper ${hideSecond ? 'd-none' : ''}`}>
              <div className="time-picker-options" ref={secondWrapper}>
                {minSecList.map((option) => (
                  <div
                    className={classNames('time-picker-option', { active: second === option.value })}
                    key={`sec-option-${option.value}`}
                    onClick={(e) => {
                      setSecond(option.value);
                    }}>
                    {option.label}
                  </div>
                ))}
              </div>
            </div>

            {useTwelveHourFormat && (
              <div className="option-wrapper">
                <div className="time-picker-options">
                  {MERDIAN_LIST.map((option) => (
                    <div
                      className={classNames('time-picker-option', {
                        active: meridian && meridian?.value && meridian.index === option.index,
                      })}
                      key={`merd-option-${option.value}`}
                      onClick={(e) => setMeridian(option)}>
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <Divider />

          <div className="d-flex p-0 pt-2 justify-content-end">
            <Button variant="primary" className="btn-main" onClick={handleButtonClick}>
              {t('ce01_pmpcore.react.confirm')}
            </Button>
          </div>
        </Card>
      </div>
      {showDropdown && allowBackdrop ? <div className="backdrop-overlay" onClick={handleClockIconClick}></div> : null}
    </div>
  );
};

export default TimePicker;
