import { M18OptionType } from '@kopapro-redux/action-types';
import { M18OptionState, UpdateM18Combo, UpdateM18Lookup } from '@kopapro-redux/types/m18Option';

const initialState: M18OptionState = {
  combo: {},
  lookup: {},
};

export function handleM18ComboReceived(nextState: M18OptionState, payload: UpdateM18Combo) {
  const { status, pattern, list } = payload;
  if (status) {
    if (list) {
      return {
        ...nextState,
        combo: { ...nextState.combo, [pattern]: list },
      };
    }
  }
  return nextState;
}

export function handleM18LookupReceived(nextState: M18OptionState, payload: UpdateM18Lookup) {
  const { status, pattern, list } = payload;
  if (status) {
    if (list) {
      return {
        ...nextState,
        lookup: { ...nextState.lookup, [pattern]: list },
      };
    }
  }
  return nextState;
}

const m18OptionReducer = (state = initialState, action: any): M18OptionState => {
  switch (action.type) {
    case M18OptionType.COMBO_UPDATED:
      return handleM18ComboReceived(state, action.payload);

    case M18OptionType.LOOKUP_UPDATED:
      return handleM18LookupReceived(state, action.payload);

    default:
      return {
        ...state,
      };
  }
};

export default m18OptionReducer;