import Delivery from '@kopapro-original/components/checkout/delivery/delivery';
import { Component, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { formatDisplayAmount, geti18nValue, formatDate, formatTime, parseDatetime } from '@kopapro/utils/m18';
import { DeliveryProps } from '@kopapro/components/checkout/delivery';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import utils from '@kopapro/utils/utils';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import PickUp from '@kopapro/components/checkout/delivery/pickup';
import { DeliveryMethod, PickupAddress } from '@kopapro-redux/types/deliveryMethod';
import ShopImage from '@kopapro/components/commons/shopImage';
import { checkoutConfig as config } from '@kopapro/utils/config';
import { ShippingRegionOption } from '@kopapro-redux/types/shippingRegion';
import { CheckoutDeliveryFormData } from '@kopapro-redux/types/checkout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DefaultModal from '@kopapro/components/commons/modals';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Select from 'react-select';
import CountrySelect from '@kopapro/components/commons/inputs/countrySelect';
import SpinnerButton from '@kopapro/components/commons/spinnerButton';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { AppImages } from '@kopapro/utils/constants/images';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TimePicker from '@kopapro/components/commons/time-picker-dropdown';
import { isDate, toDate, startOfDay, format } from 'date-fns';
import KopaproApi from '@kopapro-redux/api';
import { I18nDictionary } from '@kopapro-redux/types/utilities';

interface DeliveryState {
  memberData: {
    email: string;
  };
  formData: CheckoutDeliveryFormData;
  expectedDate: Date | null;
  expectedTime: Date | null;
  dateErrorCode: any;
  timeErrorCode: any;
  isPickUp: boolean;
  selectedDeliveryMethod?: DeliveryMethod;
  selectedPickupAddress?: PickupAddress;
  selectedShippingRegion?: ShippingRegionOption;
  deliveryCharge: number;
  sending: boolean;
  errorMessage: string;
  successMessage: string;
  showModal: boolean;
  maxLeadTime: number;

  expectedPickupTimeSlot: string;
  nonWorkingDay: number[];
  nonWorkingDateDeli: number[];
  nonWorkingDatePickup: number[];
  deliveryDate: Date | null;
  deliveryTime: Date | null;
  expectedDeliveryTimeSlot: string;

  deliveryCondition: I18nDictionary | undefined;
}

class CustomDelivery extends Delivery {
  validateBeforeSubmit = () => {
    const { isRequireDelivery } = this.props;

    const {
      formData,
      memberData,
      selectedDeliveryMethod,
      expectedDate,
      expectedTime,
      dateErrorCode,
      timeErrorCode,
      expectedPickupTimeSlot,
      isPickUp,
      deliveryDate,
      deliveryTime,
      expectedDeliveryTimeSlot,
    } = this.state;
    const { shipId, shipRegionId, mobile } = formData;
    let email: string = memberData.email.trim();

    // check member data
    if (!this.props.userLoggedIn) {
      if (utils.isEmpty(email)) {
        return 'ce01_pmpcore.react.emptyEmail';
      } else if (!utils.isValidEmail(email)) {
        return 'ce01_pmpcore.react.invalidEmail';
      }
    }

    if (!isPickUp) {
      if (deliveryDate === null) {
        return 'ce01_pmpcore.kopapro.react.fieldInvalidDateTime';
      }

      if (expectedDeliveryTimeSlot === 'na') {
        return 'ce01_pmpcore.kopapro.react.fieldInvalidDateTime';
      }
    }

    if (utils.isEmpty(mobile)) {
      return 'ce01_pmpcore.kopapro.react.missMobile';
    }

    // check delivery data
    if (isRequireDelivery) {
      const { firstName, lastName, shipAd1 } = formData;
      if (utils.isEmptyM18Id(shipId)) {
        return 'ce01_pmpcore.react.chooseOneDelivery';
      }
      if (utils.isEmptyM18Id(shipRegionId)) {
        if (selectedDeliveryMethod && selectedDeliveryMethod.selfPickUp) {
          return 'ce01_pmpcore.kopapro.react.chooseRegionAndPickUpAddress';
        }
        return 'ce01_pmpcore.react.missShipRegion';
      }
      if (utils.isEmpty(firstName) || utils.isEmpty(lastName)) {
        return 'ce01_pmpcore.kopapro.react.missFirstLastName';
      }

      if (utils.isEmpty(shipAd1)) {
        if (selectedDeliveryMethod && selectedDeliveryMethod.enableAddr) {
          return 'ce01_pmpcore.kopapro.react.missShipAddress';
        }
      }

      if (expectedDate === null) {
        if (selectedDeliveryMethod && selectedDeliveryMethod.selfPickUp) {
          return 'ce01_pmpcore.kopapro.react.fieldInvalidDateTime';
        }
      }

      if (expectedPickupTimeSlot === 'na') {
        if (selectedDeliveryMethod && selectedDeliveryMethod.selfPickUp) {
          return 'ce01_pmpcore.kopapro.react.fieldInvalidDateTime';
        }
      }

      if (
        !utils.isValidDate(expectedDate, true) ||
        !utils.isValidDate(expectedTime, true) ||
        dateErrorCode === 'maxDate' ||
        timeErrorCode === 'invalidTime'
      ) {
        return 'ce01_pmpcore.kopapro.react.fieldInvalidDateTime';
      } else if (dateErrorCode) {
        return 'ce01_pmpcore.kopapro.react.dateAfterTodayOnly';
      }

      if (utils.isValidDate(expectedDate, false) && utils.isValidDate(expectedTime, false)) {
        const dateStr = format(expectedDate!, 'yyyy-MM-dd');
        const timeStr = format(expectedTime!, 'HH:mm:ss');
        const expectedDateTime = new Date(`${dateStr} ${timeStr}`);
        const currentDateTime = new Date();
        if (expectedDateTime.getTime() < currentDateTime.getTime()) {
          return 'ce01_pmpcore.kopapro.react.dateAfterTodayOnly';
        }
      }
    }
    return '';
  };

  getTwaDeliveryFormGroup(label: string, name: string, props: any = {}): ReactNode {
    const { t } = this.props;
    const { sending, formData, deliveryAddress } = this.state;
    const required = props.required || false;
    const value = deliveryAddress;
    const isHidden = this.isHiddenIfPickUp(name);
    return (
      <Form.Group key={props.name} className={classnames('mb-3', { 'd-none': isHidden })}>
        <Form.Label className="delivery-input-label" required={required}>
          {t(label)}
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          className="field"
          name={'deliveryAddress'}
          value={value}
          {...props}
          disabled={props.disabled || sending}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleTwaAddressChange(e)}
        />
      </Form.Group>
    );
  }

  handleTwaAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = e.currentTarget.value;
    this.setState({ deliveryAddress: newValue });

    const newValueFirst = newValue.substring(0, 100);
    const newValueSecond = newValue.substring(101, 200);
    const newValueThird = newValue.substring(201, 300);
    const newValueFourth = newValue.substring(301, 400);

    this.setState({
      formData: {
        ...this.state.formData,
        shipAd1: newValueFirst,
        shipAd2: newValueSecond,
        shipAd3: newValueThird,
        shipAd4: newValueFourth,
      },
    });
  };

  renderForm(): ReactNode {
    const { t, shippingRegionOptions } = this.props;
    const { sending, selectedShippingRegion, selectedDeliveryMethod } = this.state;
    const { mobile, mobileCountry } = this.state.formData;

    const allowOverwrite = selectedShippingRegion?.detail.allowOverwrite || false;
    const isCountryReadonly = !allowOverwrite && utils.isNotEmpty(selectedShippingRegion?.detail.country);
    const isProvinceReadonly = !allowOverwrite && utils.isNotEmpty(selectedShippingRegion?.detail.province);
    const isCityReadonly = !allowOverwrite && utils.isNotEmpty(selectedShippingRegion?.detail.city);
    const isZipcodeReadonly = !allowOverwrite && utils.isNotEmpty(selectedShippingRegion?.detail.zipcode);

    const enableAddr = selectedDeliveryMethod?.enableAddr || false;
    const enableMobile = selectedDeliveryMethod?.enableMobile || false;
    const enableRemark = selectedDeliveryMethod?.enableRemark || false;
    const selfPickup = selectedDeliveryMethod?.selfPickUp || false;
    return (
      <div className="delivery-address">
        {!selfPickup && (
          <Row>
            <Form.Group className="mb-3">
              <Form.Label className="delivery-input-label" required={true}>
                {t('ce01_pmpcore.react.shipRegion')}
              </Form.Label>
              <Select<ShippingRegionOption>
                placeholder={t('ce01_pmpcore.react.select')}
                className="react-select-container"
                classNamePrefix="select"
                value={selectedShippingRegion}
                isSearchable={true}
                name="shipping-region"
                options={shippingRegionOptions}
                isDisabled={selfPickup || sending}
                onChange={this.selectedShippingRegion}
                noOptionsMessage={({ inputValue }) => t('ce01_pmpcore.react.noOptions')}
              />
            </Form.Group>
          </Row>
        )}

        <Row>
          {this.getTwaDeliveryFormGroup('ce01_kpptwa.deliveryAddress', 'shipAd1', {
            maxLength: 400,
            disabled: selfPickup,
            required: enableAddr,
          })}
        </Row>

        {this.renderExpectedInputs()}
        <Row>
          {this.getDeliveryFormGroup('ce01_pmpcore.react.firstName', 'firstName', {
            maxLength: 100,
            required: true,
          })}
          {this.getDeliveryFormGroup('ce01_pmpcore.react.lastName', 'lastName', {
            maxLength: 100,
            required: true,
          })}
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} lg={9} className="delivery-input">
            <Form.Label className="delivery-input-label" required={true}>
              {t('ce01_pmpcore.kopapro.react.contactMobile')}
            </Form.Label>
            <InputGroup>
              <Form.Control
                className="field"
                name="mobile"
                value={mobile}
                maxLength={30}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleAddressChange(e)}
                disabled={sending}
              />
            </InputGroup>
          </Form.Group>
        </Row>
        {enableRemark && (
          <Row>
            {this.getDeliveryFormGroup(
              'ce01_pmpcore.react.shipRemark',
              'shipRemark',
              {
                maxLength: 1000,
                as: 'textarea',
              },
              12
            )}
          </Row>
        )}
      </div>
    );
  }

  disableSpecificDatesPickup = (date: Date) => {
    const { nonWorkingDay, nonWorkingDatePickup } = this.state;

    if (nonWorkingDay.includes(date.getDay())) {
      return true;
    }

    if (nonWorkingDatePickup.includes(date.getTime())) {
      return true;
    }

    return false;
  };

  componentDidMount() {
    this.prepareInitialData();
    this.prepareMaxLeadTime();
    this.prepareNonWorkingPeriod();
    this.prepareDeliveryCondition();
  }

  componentDidUpdate(prevProps: DeliveryProps, prevState: Readonly<DeliveryState>) {
    if (
      prevState.selectedDeliveryMethod !== this.state.selectedDeliveryMethod ||
      prevState.selectedPickupAddress !== this.state.selectedPickupAddress ||
      prevState.selectedShippingRegion !== this.state.selectedShippingRegion
    ) {
      this.prepareNonWorkingPeriod();
    }

    if (
      (utils.isEmptyList(prevProps.deliveryMethods) && utils.isNotEmptyList(this.props.deliveryMethods)) ||
      (utils.isEmptyList(prevProps.shippingRegionOptions) && utils.isNotEmptyList(this.props.shippingRegionOptions))
    ) {
      this.prepareInitialData();
      return;
    }

    if (this.state.isPickUp != prevState.isPickUp && utils.isNotEmpty(this.state.errorMessage)) {
      this.setState({ errorMessage: '' });
    }
  }

  prepareMaxLeadTime = async () => {
    const { orderId } = this.props;
    const res = await KopaproApi.callServlet(orderId, 'KpptwaServletExtension', 'getMaxLeadTime', {});
    if (res) {
      this.setState({ maxLeadTime: res.maxLeadTime });
    }
  };

  prepareDeliveryCondition = async () => {
    const { orderId } = this.props;
    const res = await KopaproApi.callServlet(orderId, 'KpptwaServletExtension', 'getDeliveryCondition', {});
    if (res) {
      this.setState({ deliveryCondition: res.deliveryCondition });
    }
  };

  prepareNonWorkingPeriod = async () => {
    const { orderId } = this.props;
    const { selectedPickupAddress } = this.state;
    const args = {
      shopId: selectedPickupAddress?.shopId || 0,
    };
    const res = await KopaproApi.callServlet(orderId, 'KpptwaServletExtension', 'getNonWorkingDate', args);
    if (res) {
      this.setState({ expectedDate: null });
      this.setState({ nonWorkingDay: res.nonWorkingDayArr });
      this.setState({ nonWorkingDateDeli: res.nonWorkingDateDeliArr });
      this.setState({ nonWorkingDatePickup: res.nonWorkingDatePickupArr });
    }
  };

  addDate = (date: Date, dayToAdd: number) => {
    let curDate = new Date();
    curDate.setDate(date.getDate() + dayToAdd);
    return curDate;
  };

  setExpectedDeliveryTimeSlot = (expectedTimeSlot: string) => {
    this.setState({ expectedDeliveryTimeSlot: expectedTimeSlot });

    let tDate = new Date();
    let hour = 13;
    let min = 0;
    switch (expectedTimeSlot) {
      case '1000':
        hour = 10;
        min = 0;
        break;
      case '1200':
        hour = 12;
        min = 0;
        break;
      case '1400':
        hour = 14;
        min = 0;
        break;
      case '1600':
        hour = 16;
        min = 0;
        break;
      default:
        hour = 10;
        min = 0;
    }

    tDate.setHours(hour, min, 0);

    if (tDate === null) {
      this.setState({ deliveryTime: tDate });
    } else if (isDate(tDate)) {
      this.setState({ deliveryTime: toDate(tDate) });
    }
  };

  setExpectedPickupTimeSlot = (expectedTimeSlot: string) => {
    this.setState({ expectedPickupTimeSlot: expectedTimeSlot });

    let tDate = new Date();
    let hour = 13;
    let min = 0;
    switch (expectedTimeSlot) {
      case '1300':
        hour = 13;
        min = 0;
        break;
      case '1330':
        hour = 13;
        min = 30;
        break;
      case '1400':
        hour = 14;
        min = 0;
        break;
      case '1430':
        hour = 14;
        min = 30;
        break;
      case '1500':
        hour = 15;
        min = 0;
        break;
      case '1530':
        hour = 15;
        min = 30;
        break;
      case '1600':
        hour = 16;
        min = 0;
        break;
      case '1630':
        hour = 16;
        min = 30;
        break;
      case '1700':
        hour = 17;
        min = 0;
        break;
      case '1730':
        hour = 17;
        min = 30;
        break;
      default:
        hour = 13;
        min = 0;
    }

    tDate.setHours(hour, min, 0);

    if (tDate === null) {
      this.setState({ expectedTime: tDate });
    } else if (isDate(tDate)) {
      this.setState({ expectedTime: toDate(tDate) });
    }
  };

  renderMethods(): ReactNode {
    const { t, deliveryMethods } = this.props;
    const { deliveryCharge: charge, selectedDeliveryMethod, isPickUp } = this.state;
    let deliveryChargeMessage = '';
    if (charge !== 0) {
      const amount = formatDisplayAmount(charge);
      deliveryChargeMessage = t('ce01_pmpcore.react.needDeliveryCharge', { amount });
    }
    const showTerm = selectedDeliveryMethod?.showTerm;
    const filteredOptions = deliveryMethods.filter((method) => method.selfPickUp == isPickUp);
    const hideIfOnlyOne = filteredOptions.length <= 1;
    return (
      <Form.Group className="mb-3">
        <>
          {!hideIfOnlyOne && (
            <>
              <h6>
                {isPickUp ? t('ce01_pmpcore.kopapro.react.chooseOnePickup') : t('ce01_pmpcore.react.chooseOneDelivery')}
              </h6>

              <div className="methods">
                {filteredOptions.map((item) => {
                  return this.renderDeliveryMethodItem(item);
                })}
              </div>
            </>
          )}
        </>

        {showTerm && (
          <div>
            <Button className="modal-btn link-secondary" onClick={this.showTerm} variant="link">
              {t('ce01_kpptwa.deliveryCondition')}
              <HelpOutlineIcon fontSize="small" />
            </Button>
          </div>
        )}
        <h6 className="text-danger mb-3">{deliveryChargeMessage}</h6>
      </Form.Group>
    );
  }

  setDeliveryDate = (deliveryDate: any) => {
    if (deliveryDate === null) {
      this.setState({ deliveryDate });
    } else if (isDate(deliveryDate)) {
      this.setState({ deliveryDate: toDate(deliveryDate) });
    }
  };

  disableSpecificDatesDelivery = (date: Date) => {
    const { nonWorkingDateDeli } = this.state;

    if (nonWorkingDateDeli.includes(date.getTime())) {
      return true;
    }

    return false;
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { saveDelivery } = this.props;
    const self = this;
    event.preventDefault();

    const { formData, memberData, expectedDate, expectedTime, deliveryDate, deliveryTime, isPickUp } = this.state;
    let email: string = memberData.email.trim();

    let errorMessage = this.validateBeforeSubmit();

    if (!utils.isEmpty(errorMessage)) {
      this.setState({ errorMessage, successMessage: '' });
      return;
    }

    this.setState({ sending: true, errorMessage: '', successMessage: '' });

    let pickDate = '',
      pickTime = '';
    let deliDate = '',
      deliTime = '';

    if (isPickUp) {
      if (expectedDate) {
        pickDate = formatDate(expectedDate);
      }
      if (expectedTime) {
        pickTime = formatTime(expectedTime);
      }

      saveDelivery(
        { email, formData: { ...formData, pickDate, pickTime, deliDate: '', deliTime: '' } },
        function (isSuccess: boolean, message = '') {
          if (isSuccess) {
            self.setState({ sending: false, successMessage: 'ce01_pmpcore.react.submitSuccess' });
            self.props.componentNext();
          } else {
            self.setState({ sending: false, errorMessage: message });
          }
        }
      );
    } else {
      if (deliveryDate) {
        deliDate = formatDate(deliveryDate);
      }
      if (deliveryTime) {
        deliTime = formatTime(deliveryTime);
      }

      saveDelivery(
        { email, formData: { ...formData, deliDate, deliTime, pickDate: '', pickTime: '' } },
        function (isSuccess: boolean, message = '') {
          if (isSuccess) {
            self.setState({ sending: false, successMessage: 'ce01_pmpcore.react.submitSuccess' });
            self.props.componentNext();
          } else {
            self.setState({ sending: false, errorMessage: message });
          }
        }
      );
    }
  };

  renderExpectedInputs = () => {
    const self = this;
    const { t } = this.props;
    const { selectedDeliveryMethod, selectedPickupAddress, sending, maxLeadTime, isPickUp } = this.state;
    const selfPickup = selectedDeliveryMethod?.selfPickUp || false;
    const dateWithLeadTime = startOfDay(this.addDate(new Date(), maxLeadTime + 1));

    if (!isPickUp) {
      return (
        <Row>
          <Form.Group className="mb-3" as={Col} lg={6}>
            <DatePicker
              value={this.state.deliveryDate}
              inputFormat="yyyy-MM-dd"
              onChange={(value) => this.setDeliveryDate(value)}
              // disablePast={true}
              minDate={dateWithLeadTime}
              onError={function (reason: any) {
                self.setState({ dateErrorCode: reason });
              }}
              shouldDisableDate={this.disableSpecificDatesDelivery}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Form.Group as={Col} lg={12} className={'mb-3 twa-date-picker'}>
                  <Form.Label className="delivery-input-label align-self-start">
                    {t('ce01_pmpcore.kopapro.react.expectedDateTime')}
                  </Form.Label>
                  <Form.Control ref={inputRef} {...inputProps} />
                  {InputProps?.endAdornment}
                </Form.Group>
              )}
              disabled={sending}
            />
          </Form.Group>
          <Form.Group className="mb-3" as={Col} lg={6}>
            <Form.Label className="delivery-input-label align-self-start">{''}</Form.Label>
            <Form.Select
              className="kopapro-select-option"
              style={{ width: '70%' }}
              defaultValue={'na'}
              onChange={(e) => this.setExpectedDeliveryTimeSlot(e.target.value)}>
              <option key={'na'} value={'na'}>
                {' -- '}
              </option>
              <option key={'1000'} value={'1000'}>
                {'10:00am - 11:59am'}
              </option>
              <option key={'1200'} value={'1200'}>
                {'12:00pm - 1:59pm'}
              </option>
              <option key={'1400'} value={'1400'}>
                {'2:00pm - 3:59pm'}
              </option>
              <option key={'1600'} value={'1600'}>
                {'4:00pm - 6:00pm'}
              </option>
            </Form.Select>
          </Form.Group>
        </Row>
      );
    } else if (selfPickup && selectedPickupAddress) {
      return (
        <Row>
          <Form.Group className="mb-3" as={Col} lg={6}>
            <DatePicker
              value={this.state.expectedDate}
              inputFormat="yyyy-MM-dd"
              onChange={(value) => this.setExpectedDate(value)}
              // disablePast={true}
              minDate={dateWithLeadTime}
              onError={function (reason: any) {
                self.setState({ dateErrorCode: reason });
              }}
              shouldDisableDate={this.disableSpecificDatesPickup}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Form.Group as={Col} lg={12} className={'mb-3 twa-date-picker'}>
                  <Form.Label className="delivery-input-label align-self-start">
                    {t('ce01_pmpcore.kopapro.react.expectedDateTime')}
                  </Form.Label>
                  <Form.Control ref={inputRef} {...inputProps} />
                  {InputProps?.endAdornment}
                </Form.Group>
              )}
              disabled={sending}
            />
          </Form.Group>
          <Form.Group className="mb-3" as={Col} lg={6}>
            <Form.Label className="delivery-input-label align-self-start">{''}</Form.Label>
            <Form.Select
              className="kopapro-select-option"
              style={{ width: '70%' }}
              defaultValue={'na'}
              onChange={(e) => this.setExpectedPickupTimeSlot(e.target.value)}>
              <option key={'na'} value={'na'}>
                {' -- '}
              </option>
              <option key={'1300'} value={'1300'}>
                {'1:00pm - 1:29pm'}
              </option>
              <option key={'1330'} value={'1330'}>
                {'1:30pm - 1:59pm'}
              </option>
              <option key={'1400'} value={'1400'}>
                {'2:00pm - 2:29pm'}
              </option>
              <option key={'1430'} value={'1430'}>
                {'2:30pm - 2:59pm'}
              </option>
              <option key={'1500'} value={'1500'}>
                {'3:00pm - 3:29pm'}
              </option>
              <option key={'1530'} value={'1530'}>
                {'3:30pm - 3:59pm'}
              </option>
              <option key={'1600'} value={'1600'}>
                {'4:00pm - 4:29pm'}
              </option>
              <option key={'1630'} value={'1630'}>
                {'4:30pm - 4:59pm'}
              </option>
              <option key={'1700'} value={'1700'}>
                {'5:00pm - 5:29pm'}
              </option>
              <option key={'1730'} value={'1730'}>
                {'5:30pm - 6:00pm'}
              </option>
            </Form.Select>
          </Form.Group>
        </Row>
      );
    }

    return null;
  };

  render(): ReactNode {
    const { t } = this.props;
    const { selectedDeliveryMethod, successMessage, errorMessage, deliveryCondition } = this.state;
    const term = geti18nValue(selectedDeliveryMethod?.term);

    const deliCondDisplay = geti18nValue(deliveryCondition);

    return (
      <div className="delivery">
        <Card>
          <Card.Body>
            <Form noValidate onSubmit={this.handleSubmit} autoComplete="off">
              <div className="mb-5">{this.renderMember()}</div>
              <div>{this.renderDelivery()}</div>

              <ErrorMessage message={t(errorMessage)} />
              <SuccessMessage message={t(successMessage)} />

              {this.renderFooter()}
            </Form>
          </Card.Body>
        </Card>

        <DefaultModal
          show={this.state.showModal}
          title={t('ce01_kpptwa.deliveryCondition')}
          body={deliCondDisplay}
          onCloseHandler={this.handleClose}
        />
      </div>
    );
  }
}

export default CustomDelivery;
