import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import CheckoutExtra from '@kopapro/components/preview/checkout-extra/checkout-extra';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { getCartId } from '@kopapro-redux/selectors/entities/cart';
import withConfigurable from '@kopapro/components/preview/configurable';
import { M18RnMeta } from '@kopapro-redux/utils/constant';

interface IProps extends WithTranslation {
  navigate: any;
}

interface StateProps {
  orderId: string;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    orderId: getCartId(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

const configurableProps = {
  m18CompId: M18RnMeta.ORDERVIEW,
};

export type CheckoutExtraProps = IProps & StateProps & DispatchProps;

const component = withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CheckoutExtra)));
export default withConfigurable(component, configurableProps);
