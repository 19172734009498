import { HomeViewContent } from '@kopapro-redux/types/componentSetting';
import { geti18nValue } from '@kopapro/utils/m18';
import {
  generateLink,
  isMemeberPurchasePage,
  isMemberAddressPage,
  isMemberContactPage,
} from '@kopapro-redux/utils/m18';
import utils from '@kopapro/utils/utils';
import { Component, ReactNode } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { HomeView } from '@kopapro/utils/constants/constants';
import { NavigationListProps } from '@kopapro/components/pages/account/navigationList';

export default class NavigationList extends Component<NavigationListProps> {
  isActiveItem = (content: HomeViewContent) => {
    const { location } = this.props;
    const pathname = location.pathname + location.search;
    const contentPathname = generateLink(content);
    const { compId, view } = content;
    const { viewType, viewModule, viewFormat } = view;
    let isLinkMatch = pathname === contentPathname;

    if (compId === HomeView.MyPrepaidCoupon) {
      isLinkMatch = pathname.startsWith(contentPathname);
    }
    if (isLinkMatch) {
      if (compId === HomeView.ProductWishList) {
        return true;
      } else if (compId === HomeView.MyEventSchedule) {
        return true;
      } else if (compId === HomeView.MyPrepaidCoupon) {
        return true;
      } else if (compId === HomeView.ChangePassword) {
        return true;
      } else if (compId === HomeView.MyBonusPoints) {
        return true;
      } else if (isMemeberPurchasePage(viewType, viewModule)) {
        return true;
      } else if (isMemberAddressPage(viewType, viewModule, viewFormat)) {
        return true;
      } else if (isMemberContactPage(viewType, viewModule, viewFormat)) {
        return true;
      }
    }

    return false;
  };

  render(): ReactNode {
    const { contents } = this.props;

    if (utils.isUndefined(contents)) {
      return null;
    }
    return (
      <ListGroup as="ul" className="member-nav-list">
        {contents!.map((content: HomeViewContent) => {
          const { viewName } = content.view;

          return (
            <ListGroup.Item
              key={content.compId}
              as={Link}
              to={generateLink(content)}
              active={this.isActiveItem(content)}>
              {geti18nValue(viewName)}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  }
}
