import { Component, ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import { TailorModalProps } from '@kopapro/components/productDetail/tailorModal';
import { Product, ProductAttributeValue } from '@kopapro-redux/types/products';
import utils from '@kopapro/utils/utils';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Currency } from '@kopapro-redux/utils/constant';
import { formatUnitPrice, getProductDetailPricing } from '@kopapro/utils/m18';
import { productDetailConfig as config } from '@kopapro/utils/config';

import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import Alert from 'react-bootstrap/Alert';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface TailorModalState {
  currentProduct: Product | undefined;
  qty: number;
  errorMessage: string;
}

export default class TailorModal extends Component<TailorModalProps, TailorModalState> {
  defaultState = {};
  maxOrderQty = config.maxOrderQty;

  constructor(props: TailorModalProps) {
    super(props);
    this.state = {
      currentProduct: undefined,
      qty: 1,
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.initCurrentProduct();
  }

  componentDidUpdate(prevProps: TailorModalProps, prevState: TailorModalState) {
    const { groupCode, defaultCode } = this.props;
    if (prevProps.groupCode !== groupCode || prevProps.defaultCode !== defaultCode) {
      this.initCurrentProduct();
    }
  }

  handleClose = () => {
    this.setErrorState('');
    if (this.props.onCloseHandler) {
      this.props.onCloseHandler();
    }
  };

  handleOnClick = () => {
    if (this.props.onConfirmHandler) {
      this.setErrorState('');
      const { t, groupQtyInCart } = this.props;
      const { currentProduct, qty } = this.state;
      const { bDesc, m18ProId, stkQty, unlimitQty, hideStockLevel } = currentProduct!;

      const info = groupQtyInCart.find((data) => data.proId === m18ProId);
      const needToCheckQty = !unlimitQty && !hideStockLevel;
      const cartQty = info ? info.qty : 0;
      if (needToCheckQty && cartQty + qty > stkQty) {
        const productName = geti18nValue(bDesc);
        let message: string = t('ce01_pmpcore.react.notEnoughStock', { product: productName });
        this.setErrorState(message);
        return;
      }

      this.props.onConfirmHandler(currentProduct, qty);
    }
  };

  initCurrentProduct = () => {
    const { products, defaultCode } = this.props;
    let product = products!.find((p) => p.code === defaultCode);
    if (!product) {
      product = products!.find((p) => p.defPro === true);
    }
    this.setCurrentProduct(product);
  };

  setErrorState = (message: string) => {
    this.setState({
      errorMessage: message,
    });
  };

  updateQty = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: any = e.currentTarget.value;
    if (newValue.length > 0 && !utils.isIntegerString(newValue)) {
      return;
    }

    if (utils.isEmpty(newValue)) {
      this.setState({ qty: 0 });
    } else {
      this.setState({
        qty: Math.min(Math.max(1, parseInt(newValue || 1)), this.maxOrderQty),
      });
    }
  };

  onBlurChange = () => {
    this.qtyButtonHandler(0);
  };

  qtyButtonHandler = (change: number) => {
    const { qty } = this.state;
    const minQty = 1;
    let value = Math.min(Math.max(minQty, qty + change), this.maxOrderQty);
    if (value !== qty) {
      this.setState({ qty: value });
    }
  };

  getCalculatedUp = () => {
    const { tailorUp, attributeTypes } = this.props;
    const currentAttributes = this.state.currentProduct!.attributes;
    let sum = utils.getSafetyNumber(tailorUp);

    attributeTypes.forEach((attribute) => {
      const { m18Id, values } = attribute;
      const value = currentAttributes[m18Id];
      let matchedAttribute = values.find((v) => v.m18Id === value);
      if (matchedAttribute) {
        sum += utils.getSafetyNumber(matchedAttribute.charge);
      }
    });
    return sum;
  };

  onOptionsClick = (value: ProductAttributeValue) => {
    // current
    const { t, products } = this.props;
    const { attributes } = this.state.currentProduct!;
    const { proAttrTypeM18Id, m18Id } = value;

    const newAttributes = { ...attributes, [proAttrTypeM18Id]: m18Id };

    const selectedProduct = products?.find((product) => {
      const attributesJSON = JSON.stringify(newAttributes);
      const productAttributesJSON = JSON.stringify(product.attributes);

      if (attributesJSON === productAttributesJSON) {
        return product;
      }
      return undefined;
    });

    if (selectedProduct) {
      this.setCurrentProduct(selectedProduct);
      this.setErrorState('');
    } else {
      toast.dismiss();
      toast.error(t('ce01_pmpcore.react.combinationExpired'));
    }
  };

  setCurrentProduct = (product: Product | undefined) => {
    this.setState({
      currentProduct: product,
      qty: 1,
    });
  };

  renderMoq = () => {
    const { t } = this.props;
    let moq = utils.getSafetyNumber(this.state.currentProduct!.moq);
    if (moq > 1) {
      return <Form.Text className="text-muted">{t('ce01_pmpcore.react.minQrderQty', { qty: moq })}</Form.Text>;
    }
    return null;
  };

  renderStockQuantity = () => {
    const { t } = this.props;
    const { currentProduct } = this.state;

    if (currentProduct) {
      const { stkQty, unlimitQty, hideStockLevel } = currentProduct;

      if (unlimitQty || hideStockLevel) {
        return null;
      }

      let displayQty: number = utils.getSafetyNumber(stkQty);
      if (displayQty < 0) {
        displayQty = 0;
      }

      return <span className="text-muted fs-6 my-1">({t('ce01_pmpcore.react.remainQty', { qty: displayQty })})</span>;
    }
    return null;
  };

  renderAttributePanel(): ReactNode {
    const { currentProduct } = this.state;
    if (!currentProduct) {
      return null;
    }

    const isCustomPrice = this.isCustomPrice();

    const { attributeTypes } = this.props;
    const attributes = currentProduct.attributes;

    return (
      <div>
        {attributeTypes.map((type) => {
          let attributeValues: ProductAttributeValue[] = type.values;
          let chargeAmt: number = 0;

          if (attributes) {
            const attributeValue = attributeValues?.find((item) => item.m18Id === attributes[type.m18Id]);
            chargeAmt = utils.getSafetyNumber(attributeValue?.charge);
          }
          const amountSymbol = chargeAmt < 0 ? '-' : '+';
          let priceColumn = null;
          if (!isCustomPrice) {
            priceColumn = (
              <Col className="text-end">
                <span className="label">
                  {`${amountSymbol}` + formatUnitPrice(Math.abs(chargeAmt), Currency.SELECTED)}
                </span>
              </Col>
            );
          }

          return (
            <div key={type.code} className="product-tailor">
              <Form.Group>
                <Row>
                  <Col>
                    <span className="label">{geti18nValue(type.desc)}</span>
                  </Col>
                  {priceColumn}
                </Row>
              </Form.Group>
              <Form.Group>
                <Row className="mt-2 mb-2" xs={2} sm={4}>
                  {attributes &&
                    attributeValues.map((value) => {
                      const activeValue = attributes[type.m18Id];
                      const isActive = value.m18Id === activeValue;
                      const charge = utils.getSafetyNumber(value.charge);
                      return (
                        <Col key={value.code} className={`product-tailor-item text-center`}>
                          <Button
                            onClick={this.onOptionsClick.bind(null, value)}
                            variant={isActive ? 'main' : 'outline-secondary'}
                            className="w-100">
                            {geti18nValue(value.desc)}
                          </Button>
                          <span>{`${charge < 0 ? '-' : '+'}${formatUnitPrice(
                            Math.abs(charge),
                            Currency.SELECTED
                          )}`}</span>
                        </Col>
                      );
                    })}
                </Row>
              </Form.Group>
            </div>
          );
        })}

        <Row>
          <Col className="text-end">
            {this.renderPrice()}
            <h4 className="mt-1 mb-1">{formatUnitPrice(currentProduct.up, Currency.SELECTED)}</h4>
          </Col>
        </Row>
      </div>
    );
  }

  renderQuantityPanel(isSoldOut: boolean): ReactNode {
    const { t } = this.props;
    const { qty, currentProduct } = this.state;
    const { up } = currentProduct!;

    if (isSoldOut) {
      return this.renderSoldOut();
    }
    const title: string = t('ce01_pmpcore.react.quantity');
    const totalAmount: number = utils.getSafetyNumber(up) * qty;

    return (
      <div className="product-tailor pt-3 border-top">
        <div className="d-flex flex-column">
          <div>
            <h4 className="mb-3">
              {title} {this.renderStockQuantity()}
            </h4>
          </div>

          <div className="d-flex">
            <Form.Group className="text-center">
              <InputGroup className="flex-nowrap quantity-input-group">
                <Button variant="link" className="less-btn" onClick={(e) => this.qtyButtonHandler(-1)}>
                  <RemoveIcon />
                </Button>
                <Form.Control
                  style={{ width: 75 }}
                  className="text-center"
                  value={qty}
                  onChange={this.updateQty}
                  onBlur={this.onBlurChange}
                  max={this.maxOrderQty}
                />
                <Button variant="link" className="add-btn" onClick={(e) => this.qtyButtonHandler(1)}>
                  <AddIcon />
                </Button>
              </InputGroup>
              {this.renderMoq()}
            </Form.Group>
            <div className="text-end align-self-end flex-grow-1">
              <h4 className="mt-1 mb-1">
                {t('ce01_pmpcore.react.total')}: {formatUnitPrice(totalAmount, Currency.SELECTED)}
              </h4>
            </div>
          </div>
        </div>

        <ErrorMessage message={t(this.state.errorMessage)} />
      </div>
    );
  }

  renderSoldOut = () => {
    const { t } = this.props;
    const { soldOut, acceptSoldout, soldOutReminder } = this.state.currentProduct!;

    const soldOutMessage = geti18nValue(soldOutReminder);
    let soldOutAlert: string = '';

    if (acceptSoldout && !utils.isEmpty(soldOutMessage)) {
      soldOutAlert = soldOutMessage;
    }

    if (soldOut) {
      return (
        <div>
          <h4>
            <InfoIcon /> {t('ce01_pmpcore.react.soldOut')}
          </h4>
          {!utils.isEmpty(soldOutAlert) && (
            <Alert variant="warning">
              <ErrorOutlineIcon /> {soldOutAlert}
            </Alert>
          )}
        </div>
      );
    }
    return null;
  };

  allowToBuy = () => {
    const { acceptSoldout, soldOut, stkQty } = this.state.currentProduct!;

    const stockQty = utils.getSafetyNumber(stkQty);
    if (!acceptSoldout && (soldOut || stockQty <= 0)) {
      return false;
    }

    return true;
  };

  isCustomPrice = () => {
    const calculatedUp = this.getCalculatedUp();
    let originalUp = utils.getSafetyNumber(this.state.currentProduct!.oriUp);

    return originalUp !== calculatedUp;
  };

  renderPrice = () => {
    const item = this.state.currentProduct!;
    const { regularPrice, salesPrice } = getProductDetailPricing(item);

    let offPrice: any = <>{formatUnitPrice(salesPrice, Currency.SELECTED)}</>;
    if (regularPrice > salesPrice) {
      offPrice = (
        <h4 className="text-muted text-decoration-line-through">{formatUnitPrice(regularPrice, Currency.SELECTED)}</h4>
      );
    }

    return offPrice;
  };

  render(): ReactNode {
    const { currentProduct } = this.state;
    if (!currentProduct) {
      return null;
    }

    const { t, show, tailorUp } = this.props;

    const allowToBuy = this.allowToBuy();
    let title: string = geti18nValue(currentProduct.bDesc);
    const isCustomPrice = this.isCustomPrice();

    return (
      <Modal
        show={show}
        onHide={this.handleClose}
        backdrop={true}
        keyboard={false}
        centered={true}
        size={'lg'}
        fullscreen={'sm-down'}>
        <Modal.Header>
          <Modal.Title className="w-100 d-flex">
            <span className="flex-grow-1 text-break">{title}</span>
            {!isCustomPrice && <span>{formatUnitPrice(tailorUp, Currency.SELECTED)}</span>}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="border-top border-bottom">
          {this.renderAttributePanel()}
          {this.renderQuantityPanel(!allowToBuy)}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-secondary" onClick={this.handleClose}>
            {t('ce01_pmpcore.react.backBtn')}
          </Button>
          {allowToBuy && (
            <Button variant="primary" className="btn-main" onClick={this.handleOnClick}>
              {t('ce01_pmpcore.react.buy')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}
