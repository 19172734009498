// @ts-nocheck

import { FacebookProps } from '@kopapro/components/misc/integration/facebook';
import WhatsappChat from '@kopapro/components/misc/whatsappChat';

export default function Facebook(props: FacebookProps) {
  const { facebook } = props;
  if (!facebook) {
    return null;
  }

  const { facebookComment, facebookMessenger, facebookPixel, wtsAppMessenger } = facebook!;

  // facebook comment
  if (facebookComment && facebookComment.isEnable && facebookComment.appId) {
    const initSdk = function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];

      if (d.getElementById(id)) return;
      let meta = d.createElement('meta');
      meta.property = 'fb:app_id';
      meta.setAttribute('property', 'fb:app_id');
      meta.content = facebookComment.appId;
      fjs.parentNode!.insertBefore(meta, fjs);
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v8.0&appId=${facebookComment.appId}&autoLogAppEvents=1`;
      fjs.parentNode!.insertBefore(js, fjs);
      // <meta property="fb:app_id" content="{YOUR_APP_ID}" />
    };
    initSdk(document, 'script', 'facebook-jssdk');
  }

  // facebook pixel
  const { pixelId } = facebookPixel;
  if (facebookPixel && facebookPixel.isEnable && pixelId) {
    const initPixel = function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    };
    initPixel(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', pixelId);
    fbq('track', 'PageView');
  }

  if (facebookMessenger && facebookMessenger.isEnable) {
    let lang = facebookMessenger.defaultLocale;
    // trust server value
    if (!lang) {
      lang = 'en_US';
    }

    const initCustomerChat = function (d, s, id) {
      let js: HTMLScriptElement;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.async = true;
      js.defer = true;
      js.src = `https://connect.facebook.net/${lang}/sdk/xfbml.customerchat.js#xfbml=1&version=v2.12&autoLogAppEvents=1`;

      fjs.parentNode.insertBefore(js, fjs);
    };
    initCustomerChat(document, 'script', 'facebook-customerchat-jssdk');
  }

  const renderFacebookPixel = function () {
    const { isEnable, pixelId } = facebookPixel || {};
    if (isEnable && pixelId) {
      return (
        <noscript>
          <img
            alt=""
            height="1"
            width="1"
            style={{ display: 'none' }}
            src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
          />
        </noscript>
      );
    }
    return null;
  };
  const renderChatBoxComponent = function () {
    const { isEnable, pageId, themeColor, greetingText } = facebookMessenger || {};
    const { isEnable: isWhatsappEnable, phoneNumber } = wtsAppMessenger || {};
    if (isEnable && pageId) {
      return (
        <div
          className="fb-customerchat"
          page_id={pageId}
          theme_color={themeColor ? themeColor : ''}
          logged_in_greeting={greetingText ? greetingText : 'Hello'}
          logged_out_greeting={greetingText ? greetingText : 'Hello'}></div>
      );
    } else if (isWhatsappEnable && phoneNumber) {
      return <WhatsappChat number={phoneNumber} />;
    }

    return null;
  };

  return (
    <>
      <div id="fb-root"></div>
      {renderFacebookPixel()} {renderChatBoxComponent()}
    </>
  );
}
