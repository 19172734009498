import { ShopLayout, ShopLayoutStyle } from "@kopapro-redux/types/shop";
import { PreviewType, ShopMetaType } from '@kopapro-redux/action-types';
import { handlePreviewMetaLayout } from '@kopapro-redux/reducers/preview';

const defaultLayout: ShopLayoutStyle = {
  backgroundColor: '#fff3f3',
  banner: false,
  bannerFontColor: '',
  bannerHeight: 50,
  bannerPic: '',
  font: 'none',
  fontColor: '#b26060',
  fontSize: 15,
  fontStyle: 'bold;',
  itemAnimation: 'none',
  itemMargin: 50,
  itemWidth: 120,
  itemWidthType: 'auto',
  useUdfMenu: false,
  useUdfStyle: false,
  width: 1000,
  widthType: 'full',
};

const initialState: ShopLayout = {
  udffootermenu: {
    comps: [],
    layout: defaultLayout,
  },
};

const layoutsRedcuer = (state = initialState, action: any): ShopLayout => {
  switch (action.type) {
    case ShopMetaType.GET_SHOP_LAYOUT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case PreviewType.PREVIEW_SHOP_LAYOUT:
      return handlePreviewMetaLayout(state, action.payload.m18CompId, action.payload.m18Data);

    default:
      return {
        ...state,
      };
  }
};
export default layoutsRedcuer;