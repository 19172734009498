import { RootState } from "@kopapro-redux/store";
import { PaymentMethod } from '@kopapro-redux/types/paymentMethod';
import { createSelector } from "reselect";

export const getPaymentMethods = (state: RootState) => {
  return state.kopapro.paymentMethods.methods;
};

export const getPaymentMethodList = (state: RootState) => {
  let list: PaymentMethod[] = [];
  if (state) {
    const methods = state.kopapro.paymentMethods.methods;
    list = Object.values(methods);
  }
  return list;
};

export const getPaymentMethodByCode = createSelector(
  [getPaymentMethods, (state, code: string) => code],
  (methods, code) => {
    if (methods) {
      return methods[code];
    }
    return undefined;
  }
);
