import { Component, ReactNode } from 'react';
import { WildCardProps } from '@kopapro/components/preview/wildCard';
import AddIcon from '@mui/icons-material/Add';
import { triggerM18Action } from '@kopapro-redux/utils/m18';
import { M18RnMeta } from '@kopapro/utils/constants/m18';
import utils from '@kopapro-redux/utils/utils';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { isNotM18PreviewMode } from '@kopapro-redux/utils/m18';

export default class WildCard extends Component<WildCardProps> {
  editComponent = () => {
    const { prevM18CompId } = this.props;
    let wildCardCompId = M18RnMeta.WILDCARD;
    if (utils.isNotEmpty(prevM18CompId)) {
      wildCardCompId = `${M18RnMeta.WILDCARD}@@prev=${prevM18CompId}`;
    }
    triggerM18Action(`portalAction_${wildCardCompId}`);
  };

  render(): ReactNode {
    if (isNotM18PreviewMode()) {
      return null;
    }

    const { t } = this.props;
    return (
      <div>
        <div className="d-flex justify-content-center">
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>{t('ce01_pmpcore.kopapro.react.clickHereToAddWlidCard')}</Tooltip>}>
            <Button variant="outline-secondary" className="w-10 border-1" onClick={this.editComponent}>
              <AddIcon fontSize="large" />
            </Button>
          </OverlayTrigger>
        </div>
        {<hr />}
      </div>
    );
  }
}
