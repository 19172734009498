import { fork, all } from 'redux-saga/effects'
import productsSaga from "@kopapro-redux/sagas/entities/productsSaga";
import categoriesSaga from "@kopapro-redux/sagas/entities/productCategorySaga";
import shopMetaSaga from "@kopapro-redux/sagas/entities/shopMetaSaga";
import userSaga from "@kopapro-redux/sagas/entities/userSaga";
import miscSaga from "@kopapro-redux/sagas/miscSaga";
import cartSaga from "@kopapro-redux/sagas/entities/cartSaga";
import deliveryMethodSaga from '@kopapro-redux/sagas/entities/deliveryMethodSaga';
import paymentMethodSaga from '@kopapro-redux/sagas/entities/paymentMethodSaga';
import shippingRegionSaga from '@kopapro-redux/sagas/entities/shippingRegionSaga';
import orderSaga from '@kopapro-redux/sagas/entities/orderSaga';
import m18OptionSaga from '@kopapro-redux/sagas/entities/m18OptionSaga';
import checkoutSaga from '@kopapro-redux/sagas/entities/checkoutSaga';
import blogSaga from '@kopapro-redux/sagas/entities/blogSaga';
import memberSaga from '@kopapro-redux/sagas/entities/memberSaga';
import bonusPointsSaga from '@kopapro-redux/sagas/entities/bonusPointsSaga';

export default function* rootSaga() {
  yield all([
    fork(productsSaga),
    fork(shopMetaSaga),
    fork(userSaga),
    fork(categoriesSaga),
    fork(miscSaga),
    fork(cartSaga),
    fork(deliveryMethodSaga),
    fork(paymentMethodSaga),
    fork(shippingRegionSaga),
    fork(orderSaga),
    fork(m18OptionSaga),
    fork(checkoutSaga),
    fork(blogSaga),
    fork(memberSaga),
    fork(bonusPointsSaga),
  ]);
}