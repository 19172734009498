import React, { Component } from 'react';
import { geti18nValue } from '@kopapro/utils/m18';
import { UdfHtmlProps } from '@kopapro/components/shop/udfHtml';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb/breadcrumb';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';

interface UdfHtmlState {}

export default class UdfHtml extends Component<UdfHtmlProps, UdfHtmlState> {
  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t, title } = this.props;

    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: geti18nValue(title), to: '', active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  render() {
    const { compId, udfHtml } = this.props;

    return (
      <div className="page-container container px-xl-5">
        {this.renderTop()}
        <div id={`udfHtml-${compId}`}>
          <SafetyDOM html={geti18nValue(udfHtml)} />
        </div>
      </div>
    );
  }
}
