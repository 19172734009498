import { geti18nValue } from '@kopapro/utils/m18';
import { Component, ReactNode } from 'react';
import { ContactUsPageProps } from '@kopapro/components/pages/contactUs';
import utils from '@kopapro/utils/utils';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb/breadcrumb';
import ContactUsForm from '@kopapro/components/shop/contactUs/form';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';

export default class ContactUsPage extends Component<ContactUsPageProps> {
  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t } = this.props;
    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.contactUs'), to: '', active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  render(): ReactNode {
    if (utils.isUndefined(this.props.content)) {
      return null;
    }
    const { t } = this.props;
    const { headerHtml, footerHtml } = this.props.content!;

    return (
      <div id={`contact-us-page`} className={`contact-us-page page-container container px-xl-5`}>
        <ScrollToTopOnMount />
        {this.renderTop()}

        <div className="col-12 text-center">
          <h3 className="title">{t('ce01_pmpcore.react.contactUs')}</h3>
        </div>

        <SafetyDOM html={geti18nValue(headerHtml)} />

        <div className={`row ignore-height-xs`}>
          <ContactUsForm showIn="both" {...this.props} />
        </div>

        <SafetyDOM html={geti18nValue(footerHtml)} />
      </div>
    );
  }
}
