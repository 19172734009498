import { CartItem } from '@kopapro-redux/types/cart';
import { Component, ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import ShopImage from '@kopapro/components/commons/shopImage';
import {
  formatAmount,
  formatUnitPrice,
  geti18nValue,
  getRetailUnitPrice,
  isShowRetailUnitPrice,
  resolveProductItemImageURL,
} from '@kopapro/utils/m18';
import DeleteIcon from '@mui/icons-material/Delete';
import { MiniCartProps } from '@kopapro/components/header/components/minicart';
import { AppImages } from '@kopapro/utils/constants/images';
import { Currency } from '@kopapro-redux/utils/constant';
import { Link } from 'react-router-dom';
import utils from '@kopapro/utils/utils';
import { minicartConfig } from '@kopapro/utils/config';
import RedeemIcon from '@mui/icons-material/Redeem';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import EventUtils from '@kopapro/utils/event';

export default class Minicart extends Component<MiniCartProps> {
  // ce01_pmpcore.react.emtpyCart
  productImageSize = 70;
  amountOptions = minicartConfig;

  componentDidUpdate() {
    const { popper } = this.props;
    if (popper) {
      popper.scheduleUpdate();
    }
  }

  removeProductHandler = (item: CartItem) => {
    EventUtils.removeFromCart(item);
    this.props.removeFromCart({ proId: item.proId });
  };

  checkOut = () => {
    const { navigate } = this.props;
    this.props.triggerHideCart();
    navigate('/cart');
  };

  //if reuse, put it in utils/m18
  canDelete = (item: CartItem): boolean => {
    const { disableDeletePro, isGift, isPackSubPro } = item;
    if (disableDeletePro) {
      return false;
    }
    if (!isGift && !isPackSubPro) {
      return true;
    }
    return false;
  };

  renderPrice = (item: CartItem) => {
    const { t } = this.props;
    const { oriUp, adpUp, up, isGift, isRedemption, isPackSubPro } = item;

    let remark: ReactNode = null;
    if (isPackSubPro) {
      return (
        <>
          <Inventory2OutlinedIcon /> {t('ce01_pmpcore.react.packageProduct')}
        </>
      );
    } else if (isRedemption) {
      remark = (
        <>
          <RedeemIcon /> {t('ce01_pmpcore.react.redemption')}
        </>
      );
    } else if (isGift) {
      return (
        <>
          <RedeemIcon /> {t('ce01_pmpcore.react.gift')}
        </>
      );
    }

    let price: any = formatUnitPrice(up, Currency.SELECTED);

    const isShowRetail = isShowRetailUnitPrice(oriUp, up, adpUp);
    const retailUp = getRetailUnitPrice(oriUp, up, adpUp);

    if (isShowRetail) {
      price = (
        <>
          <div>
            <del>{formatUnitPrice(retailUp, Currency.SELECTED)}</del>
          </div>
          <div className="text-danger">{formatUnitPrice(up, Currency.SELECTED)}</div>
          {remark}
        </>
      );
    }
    return price;
  };

  renderAttribute = (item: CartItem) => {
    const { attrObj } = item;
    const attrString = geti18nValue(attrObj);

    if (utils.isEmpty(attrString)) {
      return null;
    }

    return (
      <div>
        {attrString.split(',').map((subAttribute, i) => {
          return (
            <span className="d-block text-muted" key={`${item.proCode}_attr_${i}`}>
              {subAttribute}
            </span>
          );
        })}
      </div>
    );
  };

  getProductLink = (item: CartItem) => {
    const { proGpCode, proCode, proGpId, proId } = item;

    if (!utils.isEmpty(proGpCode)) {
      return `/products/${proGpCode}`;
    } else if (!utils.isEmpty(proCode)) {
      return `/products/item/${proCode}`;
    }

    return `/proDetail/${proGpId}/${proId}`;
  };

  subProductContent = (parentItem: CartItem, isGiftOrRedemption: boolean) => {
    const parentPromoInfos = parentItem.promoInfos;
    const promotionId = parentItem.ppId;
    const parentId = parentItem.proId;
    const parentLot = parentItem.lot;
    const { items } = this.props;
    const subProducts = items
      ?.filter((item) => {
        if (!isGiftOrRedemption) {
          return item.isPackSubPro && item.packParentProId === parentId && item.ppId === 0 && item.lot === parentLot;
        } else if (parentPromoInfos.length > 0 && item.promoInfos.length > 0) {
          const { giftSubCat } = item.promoInfos[0];
          const parentPromo = parentPromoInfos[0];
          return (
            item.packParentProId === parentId && item.ppId === promotionId && parentPromo.giftSubCat === giftSubCat
          );
        }
        return false;
      })
      .map((item, index) => {
        return (
          <div key={item.proId + '_' + index}>
            <div>
              {item.qty} x {geti18nValue(item.descObj)}
            </div>
            <div className="ms-3"> {this.renderAttribute(item)} </div>
          </div>
        );
      });

    return subProducts;
  };

  renderItems = () => {
    const { items } = this.props;
    if (!items) {
      return null;
    }

    return items.map((item: CartItem, index: number) => {
      const showDeleteButton = this.canDelete(item);
      const { proCode, isPackSubPro, isPackParentPro, isExclude } = item;

      if (isExclude) {
        return null;
      }

      let subProducts = null;

      if (isPackSubPro) {
        return null;
      } else if (isPackParentPro) {
        const isGiftOrRedemption = item.isRedemption || item.isGift;
        subProducts = this.subProductContent(item, isGiftOrRedemption);
      }
      return (
        <li key={`${proCode}_${index}`} className="clearfix cart-item">
          <Link to={this.getProductLink(item)} state={item.proId ? item.proId : null}>
            <ShopImage
              height={this.productImageSize}
              width={this.productImageSize}
              src={resolveProductItemImageURL(item.photoCode, item.isSV)}
              className="contain"
              fallbackImage={AppImages.productPlaceholder}
            />
          </Link>
          <div className="information">
            <div className="item-name-box">
              <Link to={this.getProductLink(item)} state={item.proId ? item.proId : null} className="item-name ">
                {geti18nValue(item.descObj)}
              </Link>

              {showDeleteButton && (
                <span role="button" onClick={(e) => this.removeProductHandler(item)} className="ms-auto">
                  <DeleteIcon />
                </span>
              )}
            </div>
            {this.renderAttribute(item)}
            <div className="d-flex">
              <span className="item-price">{this.renderPrice(item)}</span>
              <span className="item-quantity ms-auto align-self-end">x {item.qty}</span>
            </div>
            {subProducts}
          </div>
        </li>
      );
    });
  };

  getAmountText = (amount: number = 0, positive: boolean = true) => {
    return `${positive ? '' : '-'} ${amount}`;
  };

  renderAmountRow(amount: number = 0, messCode: string, show = true, showIfZero: boolean = false): ReactNode {
    const { t } = this.props;
    if (!show) {
      return null;
    }
    if (!showIfZero && amount <= 0) {
      return null;
    }

    const formattedAmount = formatAmount(amount, Currency.SELECTED);
    let prefix = '';
    if (messCode.includes('promoDiscAmt')) {
      prefix = '-';
    }

    return <span className="d-block">{t(messCode, { amount: `${prefix}${formattedAmount}` })}</span>;
  }

  renderCartFooter = () => {
    const { t, amountDetails } = this.props;
    if (utils.isUndefined(amountDetails)) {
      return null;
    }
    const { serviceSurchargeAmt, shipFeeAmt, pmSurchargeAmt, promoDiscAmt, totalAmount } = amountDetails!;

    const { showServiceSurcharge, showDeliveryFee, showPaymentMethodSurcharge, showPromotionDiscountAmount } =
      this.amountOptions;
    return (
      <div className="clearfix shopping-cart-footer">
        <div className="shopping-cart-amount">
          <div>
            {this.renderAmountRow(serviceSurchargeAmt, 'ce01_pmpcore.react.serviceSurchargeAmt', showServiceSurcharge)}
          </div>
          <div>{this.renderAmountRow(shipFeeAmt, 'ce01_pmpcore.react.shipFee', showDeliveryFee)}</div>
          <div>
            {this.renderAmountRow(pmSurchargeAmt, 'ce01_pmpcore.react.paymSurchargeAmt', showPaymentMethodSurcharge)}
          </div>
          <div className="text-danger">
            {this.renderAmountRow(promoDiscAmt, 'ce01_pmpcore.react.promoDiscAmt', showPromotionDiscountAmount)}
          </div>

          <div className="shopping-cart-total text-dark">
            <span className="pe-1">{t('ce01_pmpcore.react.total')}:</span>
            <span>{formatAmount(totalAmount, Currency.SELECTED)}</span>
          </div>
        </div>
      </div>
    );
  };

  render(): ReactNode {
    const { t, items } = this.props;
    if (!items || items.length === 0) {
      return (
        <div className="mini-shopping-cart">
          <div>{t('ce01_pmpcore.react.emtpyCart')}</div>
        </div>
      );
    }

    return (
      <div className="mini-shopping-cart ">
        <div className="shopping-cart">
          <ul className="shopping-cart-items">{this.renderItems()}</ul>
          {this.renderCartFooter()}
          <Button className="btn-main w-100 " onClick={() => this.checkOut()}>
            {t('ce01_pmpcore.kopapro.react.checkout')}
          </Button>
        </div>
      </div>
    );
  }
}
