import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from "@kopapro-redux/store";
import Language from "@kopapro/components/header/components/language/language";
import { withTranslation, WithTranslation } from 'react-i18next';

import { ShopLanguageSetup } from '@kopapro-redux/types/shop';
import { getLanguages } from '@kopapro-redux/selectors/entities/shop';
import { getCurrentLang } from '@kopapro-redux/selectors/entities/user';
import { changeLanguage } from '@kopapro-redux/actions/user';
import { getMetaBasicSetup } from '@kopapro-redux/selectors/entities/shop';
import { withLocation } from '@kopapro/components/commons/router-hoc';
interface IProps extends WithTranslation {
  location: any;
  style?: any;
  fontStyle?: any;
}

interface StateProps {
  language: ShopLanguageSetup[] | undefined;
  currentLang: string;
  defaultLang: string;
  enableLangPath: boolean;
  excludeDefaultLangInURL: boolean;
}

interface DispatchProps {
  setLang: typeof changeLanguage;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const { language: defaultLang, enableLangPath, excludeDefaultLangInURL } = getMetaBasicSetup(state);
  return {
    language: getLanguages(state),
    currentLang: getCurrentLang(state),
    defaultLang,
    enableLangPath,
    excludeDefaultLangInURL,
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  setLang: changeLanguage,
};

export type LanguageDropdownProps = IProps & StateProps & DispatchProps;
export default withLocation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Language)));
