import { Component, ReactNode } from 'react';
import { DiscountCodeSectionProps } from '@kopapro/components/checkout/discountCode';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import utils from '@kopapro-redux/utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import InputGroup from 'react-bootstrap/InputGroup';
import { getCookie } from '@kopapro-redux/utils/cookies';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import SpinnerButton from '@kopapro/components/commons/spinnerButton';

interface State {
  sending: boolean;
  inputValue: string;
  errorMessage: string;
}

export default class DiscountCodeSection extends Component<DiscountCodeSectionProps, State> {
  constructor(props: DiscountCodeSectionProps) {
    super(props);
    this.state = { sending: false, inputValue: '', errorMessage: '' };
  }

  componentDidMount(): void {
    this.loadDiscountCodeLink();
  }

  componentDidUpdate(prevProps: Readonly<DiscountCodeSectionProps>): void {
    if (prevProps.currentSection !== this.props.currentSection) {
      this.setState({ errorMessage: '' });
    }
  }

  loadDiscountCodeLink = () => {
    const discCode = getCookie('discCode');
    if (utils.isNotEmpty(discCode)) {
      let discountCodeList = [...this.props.discountCodes];
      if (!discountCodeList.includes(discCode)) {
        discountCodeList.push(discCode);
      }
      this.addDiscountCodesHandler(discountCodeList);
    }
  };

  addDiscountCodesHandler = (discountCodeList: string[]) => {
    const { addDiscountCodes, allowMultiDiscountCode } = this.props;
    const self = this;

    if (!allowMultiDiscountCode && discountCodeList.length > 1) {
      return;
    }

    const sortedArray1 = discountCodeList.sort();
    const sortedArray2 = this.props.discountCodes.sort();
    if (
      sortedArray1.length === sortedArray2.length &&
      sortedArray1.every(function (value, index) {
        return value === sortedArray2[index];
      })
    ) {
      return;
    }

    this.setState({ sending: true, errorMessage: '' });

    // dispatch request
    addDiscountCodes(
      { discCodes: discountCodeList },
      function (isSuccess: boolean, message: string, invalidCode: boolean) {
        if (isSuccess && !invalidCode && utils.isEmpty(message)) {
          self.setState({ sending: false, inputValue: '' });
        } else {
          if (invalidCode) {
            self.setState({ sending: false, errorMessage: message });
          } else {
            // unknown error, but usually should be error about cart
            self.props.navigate('/cart');
          }
        }
      }
    );
  };

  handleApplyDiscountCodes = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { inputValue } = this.state;

    if (utils.isEmpty(inputValue)) {
      this.setState({ errorMessage: 'ce01_pmpcore.kopapro.react.emptyDiscountCode' });
      return;
    }

    let discountCodeList = [...this.props.discountCodes];
    if (!discountCodeList.includes(inputValue)) {
      discountCodeList.push(inputValue);
    }

    this.addDiscountCodesHandler(discountCodeList);
  };

  handleRemoveDiscountCodes = (discountCode: string) => {
    this.setState({ sending: true, errorMessage: '' });

    const discountCodeList = this.props.discountCodes.filter((code) => {
      return code !== discountCode;
    });

    this.addDiscountCodesHandler(discountCodeList);
  };

  renderDiscountCode = (discountCode: string) => {
    const { allowEditDiscount } = this.props;
    const { sending } = this.state;
    return (
      <div key={discountCode}>
        <DiscountOutlinedIcon />
        <span className="text-break">{discountCode}</span>
        {allowEditDiscount && (
          <Button
            key={discountCode}
            variant="dark-link"
            className="me-3 p-0"
            onClick={() => this.handleRemoveDiscountCodes(discountCode)}
            disabled={sending}>
            <CloseIcon />
          </Button>
        )}
      </div>
    );
  };

  onDiscountCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;

    if (inputValue.length > 100) {
      return;
    }

    if (utils.isEmpty(inputValue) || utils.isAlphanumeric(inputValue)) {
      this.setState({ inputValue });
    }
  };

  render(): ReactNode {
    const { t, discountCodes, allowMultiDiscountCode, allowEditDiscount, hideErrorMessage } = this.props;
    const { sending, inputValue, errorMessage } = this.state;
    let form = null;

    if (allowEditDiscount && (discountCodes.length === 0 || allowMultiDiscountCode)) {
      form = (
        <Form onSubmit={this.handleApplyDiscountCodes} noValidate>
          <InputGroup className="mb-3">
            <Form.Control
              className="me-2"
              type="discCode"
              placeholder={t('ce01_pmpcore.react.discCode')}
              value={inputValue}
              onChange={this.onDiscountCodeChange}
              disabled={sending}
              maxLength={100}
            />
            <SpinnerButton variant="secondary" type="submit" spinning={sending}>
              {t('ce01_pmpcore.react.apply')}
            </SpinnerButton>
          </InputGroup>
        </Form>
      );
    }

    const showDivider = form || errorMessage.length > 0 || discountCodes.length > 0;
    return (
      <div className={`discount-code-section`}>
        {form}
        {!hideErrorMessage &&
          errorMessage.split('\n').map((subMessage) => {
            return <ErrorMessage key={`error-${subMessage}`} message={t(subMessage)} className="text-break" />;
          })}
        {discountCodes.map((code) => {
          return this.renderDiscountCode(code);
        })}
        {showDivider && <hr />}
      </div>
    );
  }
}
