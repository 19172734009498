import { BonusPointsSetupType } from '@kopapro-redux/action-types';
import { BonusPointsSetupAction, BonusPointsSetupState, BonusPointsSetup } from '@kopapro-redux/types/bonusPoints';

const initialState: BonusPointsSetupState = {
  pending: false,
  data: { id: 0 },
  error: '',
};

export function handleBonusPointsSetupReceived(nextState: BonusPointsSetupState, list: BonusPointsSetup[]) {
  const methods: any = list ? list[0] : [];
  nextState.data = methods;
  return nextState;
}

const bonusPointsSetupReducer = (state = initialState, action: BonusPointsSetupAction): BonusPointsSetupState => {
  switch (action.type) {
    case BonusPointsSetupType.RECEIVED_BONUS_POINTS_SSETUP:
      return handleBonusPointsSetupReceived({ ...state, pending: false }, action.payload as BonusPointsSetup[]);

    default:
      return state;
  }
};

export default bonusPointsSetupReducer;
