import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { RootState } from "@kopapro-redux/store";
import { withTranslation, WithTranslation } from "react-i18next";

import { getPolicy } from '@kopapro-redux/selectors/entities/policy';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import Policy from '@kopapro/components/shop/policy/policy';

interface IProps extends WithTranslation {}

interface StateProps {
  policy: I18nDictionary;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    policy: getPolicy(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type PolicyProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Policy));
