import Footer from '@kopapro-original/components/footer/footer';
import { FooterProps } from '@kopapro-custom/components/footer';
import { ReactNode } from 'react';
import utils from '@kopapro/utils/utils';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

class CustomFooter extends Footer {
  constructor(props: FooterProps) {
    super(props);
  }

  onButtonClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {

    this.props.toggleDemo();
    this.props.updateLocalState({ data: new Date().toISOString() });
  };

  renderTestingMessage = () => {
    const { showDemoMessage } = this.props;
    return (
      <>
        {showDemoMessage && <Alert variant="warning">Demo Custom Footer</Alert>}
        <Button variant="info" onClick={this.onButtonClick}>
          {showDemoMessage ? 'Hide' : 'Show'} Alert
        </Button>
      </>
    );
  };

}

export default CustomFooter;
