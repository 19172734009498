import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Home from '@kopapro/components/home/home';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ShopComponent } from '@kopapro-redux/types/shop';
import { getDisplayShopComponents, getShopInfo, isShowShopSplash } from '@kopapro-redux/selectors/entities/shop';
import { withLocation, withSearchParams } from '@kopapro/components/commons/router-hoc';
import { ShopSplash } from '@kopapro-redux/types/componentSetting';
import { showShopSplash } from '@kopapro-redux/actions/shop';

interface IProps extends WithTranslation {
  location: any; // useLocation
  searchParams: any; // from withSearchParams
}

interface StateProps {
  components: ShopComponent[];
  discCode?: string;
  splash?: ShopSplash;
  isShowSplash: boolean;
}

interface DispatchProps {
  showSplash: typeof showShopSplash;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const components = getDisplayShopComponents(state) || [];
  const discCode = props.searchParams[0].get('discCode') || '';
  const splash = getShopInfo(state)?.splash;
  const isShowSplash = isShowShopSplash(state);
  return {
    components,
    discCode,
    splash,
    isShowSplash,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  showSplash: showShopSplash,
};

export type HomeProps = IProps & StateProps & DispatchProps;
export default withLocation(withSearchParams(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Home))));
