import { CartType } from "@kopapro-redux/action-types";
import { CommonAction } from "@kopapro-redux/actions/misc";
import { CartState, GiftRedemptionData, MemberTypeAlert, PromotionAlert } from '@kopapro-redux/types/cart';
import { OrderStatus } from '@kopapro-redux/utils/constant';
import { getStorageItem, removeStorageItem, setStorageItem } from '@kopapro-redux/utils/cookies';
import utils from '@kopapro-redux/utils/utils';

const initialState: CartState = {
  updating: false,
  retrieving: false,
  orderId: '',
  discCodes: [],
  items: [],
  amountDetails: {
    totalAmount: 0,
    outstandAmt: 0,
    promoDiscAmt: 0,
    promoDiscDescObj: { en: '' },
    pmSurchargeAmt: 0,
    shipFeeAmt: 0,
    couponAmt: 0,
    serviceSurchargeAmt: 0,
  },
  orderInfo: {
    orderStatus: 'na',
    payStatus: 'unpaid',
    shipStatus: 'unfulfilled',
  },
  deliveryId: 0,
  paymentId: 0,

  isRequireDelivery: true,
  isRequirePayment: true,
  cartMessage: '',
};

export function handleCartReceived(nextState: CartState, payload: any, saveInCookie = true) {
  const { entity, message } = payload;

  // update store state even status === false
  if (entity && entity.orderId && entity.data) {
    const { orderStatus } = entity;
    if (orderStatus !== OrderStatus.NA) {
      return handleCartRemoved();
    }

    const { orderId } = entity;
    const selectedOrderId = getStorageItem('orderId');
    if (saveInCookie && selectedOrderId !== orderId) {
      setStorageItem('orderId', orderId);
    }

    const { data, discCodes, amtDetail } = entity;
    const { email, deliveryId, delivery, paymentId, paymentSVs } = entity;
    const { isRequireDelivery, isRequirePayment } = entity;
    return {
      ...nextState,
      items: [...data],
      orderId,
      discCodes,
      amountDetails: { ...amtDetail },
      orderInfo: {
        orderStatus: entity.orderStatus || 'na',
        payStatus: entity.payStatus || 'unpaid',
        shipStatus: entity.shipStatus || 'unfulfilled',
      },
      member: { email: email, defaultDelivery: delivery.defShip },
      deliveryId: deliveryId,
      delivery: delivery,
      paymentId: paymentId,
      paymentCoupons: paymentSVs,

      isRequireDelivery: isRequireDelivery,
      isRequirePayment: isRequirePayment,
      cartMessage: message === 'Pass' ? '' : message,
    };
  }
  return nextState;
}

export function handleCartRemoved(saveInCookie = true) {
  const selectedOrderId = getStorageItem('orderId');
  if (saveInCookie && utils.isNotEmpty(selectedOrderId)) {
    removeStorageItem('orderId');
  }
  return initialState;
}

export function handlePromotionAlertUpdated(nextState: CartState, alert: PromotionAlert) {
  return { ...nextState, promotionAlert: alert };
}

export function handleMemberTypeAlertUpdated(nextState: CartState, alert: MemberTypeAlert) {
  return { ...nextState, memberTypeAlert: alert };
}

export function handleRedemptionListUpdated(nextState: CartState, list: GiftRedemptionData[]) {
  return { ...nextState, redemptionList: list };
}

export function handleGiftListUpdated(nextState: CartState, list: GiftRedemptionData[]) {
  return { ...nextState, giftList: list };
}

const cartReducer = (state = initialState, action: CommonAction): CartState => {
  switch (action.type) {
    case CartType.GET_CART_REQUEST:
    case CartType.SET_REQUESTING_CART:
      return {
        ...state,
        retrieving: true,
      };
    case CartType.MODIFY_CART_ITEM_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case CartType.CART_UPDATED:
      return handleCartReceived({ ...state, updating: false, retrieving: false }, action.payload);
    case CartType.CART_REMOVED:
      return handleCartRemoved();
    case CartType.PROMOTION_ALERT_UPDATED:
      return handlePromotionAlertUpdated(state, action.payload.alert);
    case CartType.MEMBER_TYPE_ALERT_UPDATED:
      return handleMemberTypeAlertUpdated(state, action.payload.alert);
    case CartType.REDEMPTION_LIST_UPDATED:
      return handleRedemptionListUpdated(state, action.payload.list);
    case CartType.GIFT_LIST_UPDATED:
      return handleGiftListUpdated(state, action.payload.list);

    default:
      return state;
  }
};

export default cartReducer;