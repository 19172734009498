import { ToastContainerProps } from 'react-toastify';
//  UI control varible in here

import * as defaultConfig from '@kopapro-original/utils/config';

// website setting
export const kopaproConfig = Object.assign(defaultConfig.kopaproConfig, {
  showScrollToTopButton: false,
});

export const homePageConfig = defaultConfig.homePageConfig;

export const footerConfig = defaultConfig.footerConfig;

export const headerConfig = defaultConfig.headerConfig;

export const minicartConfig = defaultConfig.minicartConfig;

export const productDetailConfig = defaultConfig.productDetailConfig;

export const productListConfig = Object.assign(defaultConfig.productListConfig, {
  showFilter: true,
});

export const productItemConfig = defaultConfig.productItemConfig;

export const blogListConfig = defaultConfig.blogListConfig;

export const deliveryMethodItemConfig = defaultConfig.deliveryMethodItemConfig;

export const cartItemConfig = defaultConfig.cartItemConfig;

export const checkoutConfig = defaultConfig.checkoutConfig;

export const paymentConfig = defaultConfig.paymentConfig;

export const orderConfig = defaultConfig.orderConfig;

export const memberCenterConfig = defaultConfig.memberCenterConfig;

// home page components
export const bannerConfig = defaultConfig.bannerConfig;
export const contactUsConfig = defaultConfig.contactUsConfig;
export const loginConfig = defaultConfig.loginConfig;
export const registerConfig = defaultConfig.registerConfig;
export const shareButtonsConfig = defaultConfig.shareButtonsConfig;

export const toastOptions: ToastContainerProps = defaultConfig.toastOptions;

// safetyURL
export const safetyEmbedUrlConfig = defaultConfig.safetyEmbedUrlConfig;

export const cartConfig = Object.assign(defaultConfig.cartConfig, {
  needRefreshCartInInitial: true,
});