import { DeliveryMethodType } from '@kopapro-redux/action-types';
import { DeliveryMethod, DeliveryMethodsAction, DeliveryMethodsState } from '@kopapro-redux/types/deliveryMethod';

const initialState: DeliveryMethodsState = {
  pending: false,
  methods: {},
  error: '',
};

export function handleDeliveryMethodsReceived(nextState: DeliveryMethodsState, list: DeliveryMethod[]) {
  const methods: any = {};
  list.forEach((method) => {
    let temp: any = Object.assign({}, method);
    const code = method.code;
    methods[code] = temp;
  });
  nextState.methods = methods;
  return nextState;
}

const deliveryMethodsReducer = (state = initialState, action: DeliveryMethodsAction): DeliveryMethodsState => {
  switch (action.type) {
    case DeliveryMethodType.RECEIVED_DELIVERYMETHODS:
      return handleDeliveryMethodsReceived({ ...state, pending: false }, action.payload as DeliveryMethod[]);

    default:
      return state;
  }
};

export default deliveryMethodsReducer;
