import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Account from '@kopapro/components/pages/account/account';
import withAccount from '@kopapro/components/pages/account/accountHoc';
import { getMemberCenterDefaultView } from '@kopapro-redux/selectors/entities/member';
import { generateLink } from '@kopapro-redux/utils/m18';

interface IProps {}

interface DispatchProps {}

interface StateProps {
  defaultLink: string;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const defaultView = getMemberCenterDefaultView(state);
  const defaultLink = defaultView ? generateLink(defaultView) : '';
  return {
    defaultLink,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type AccountProps = IProps & StateProps & DispatchProps;
export default withAccount(connect(mapStateToProps, mapDispatchToProps)(Account));



