import BlogPost from '@kopapro/components/shop/blogPost/blogPost';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BlogPostContent } from '@kopapro-redux/types/componentSetting';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { getDataByComponent } from '@kopapro-redux/selectors/entities/shop';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { getBlogCategoryById } from '@kopapro-redux/selectors/entities/blog';
import { BlogCategory } from '@kopapro-redux/types/blog';

interface IProps extends WithTranslation {
  compId: string;
  compName: string;
  showIn: string;
  navigate: any; // withNavigation
}

interface DispatchProps {}

interface StateProps {
  content?: BlogPostContent;
  category?: BlogCategory;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const { compId, compName } = props;
  const content = getDataByComponent(state, { compId, compName })?.blogPost;
  let category;
  if (content) {
    category = getBlogCategoryById(state, content.cateId);
  }

  return {
    content,
    category,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type BlogPostProps = IProps & StateProps & DispatchProps;

export default withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BlogPost)));
