import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { CheckoutType } from '@kopapro-redux/action-types';
import { cartRequireDelivery, getCartId } from '@kopapro-redux/selectors/entities/cart';
import KopaproApi from '@kopapro-redux/api';
import {
  AddDeliveryInCheckoutAction,
  AddDiscountCodesInCheckoutAction,
  AddMemberInCheckoutAction,
  AddPaymentInCheckoutAction,
  ApplyCouponInCheckoutAction,
  CheckDeliveryChargeInCheckoutAction,
  CheckPaymentSurchargeInCheckoutAction,
  ConfirmOrderInCheckoutAction,
  ConfirmOrderParam,
  LoadCouponInCheckoutAction,
  LoadExtraInfoInCheckoutAction,
  SaveExtraInfoInCheckoutAction,
} from '@kopapro-redux/types/checkout';
import { CouponVoucher } from '@kopapro-redux/types/coupon';
import {
  CartResponse,
  cartUpdatedSaga,
  getCartInformationFromOrderSaga,
  getMemberTypeAlertSaga,
  getPromotionAlertSaga,
} from '@kopapro-redux/sagas/entities/cartSaga';
import { InputValues, InputFormatList } from '@kopapro-redux/types/componentSetting';
import { getOrderViewInputFormatList } from '@kopapro-redux/selectors/entities/checkout';
import utils from '@kopapro-redux/utils/utils';
import { UdfInputFieldType } from '@kopapro-redux/utils/constant';
import { getM18ComboList, getM18LookupList } from '@kopapro-redux/actions/m18Option';
import { getCurrentUserInfo } from '@kopapro-redux/selectors/entities/user';
import { UserInfo } from '@kopapro-redux/types/user';

// same as order
type CheckoutResponse = CartResponse & {};

type DiscountCodesResponse = CartResponse & {
  invalidDiscCode: boolean;
};

type DeliveryChargeResponse = {
  data: {
    deliveryCharge: number;
  };
  status: boolean;
};

type PaymentSurchargeResponse = {
  data: {
    surchargeAmt: number;
  };
  status: boolean;
};

type ExtraResponse = {
  inputs: InputValues;
  status: boolean;
};

export function* addDiscountCodesSaga(action: AddDiscountCodesInCheckoutAction) {
  try {
    const { discCodes } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    const resp: DiscountCodesResponse = yield call(KopaproApi.addDiscountCodesToOrder, orderId, discCodes);

    const resp2: CartResponse = yield call(KopaproApi.retrieveCartItem, resp.entity.orderId);
    if (!resp2.status && resp2.entity && resp2.entity.data) {
      resp.entity.data = [...resp2.entity.data];
      resp.message = resp2.message;
    }
    yield call(cartUpdatedSaga, resp);
    yield call(getPromotionAlertSaga, orderId);
    yield call(getCartInformationFromOrderSaga);

    if (callback) {
      callback(resp.status, resp.message, resp.invalidDiscCode === true);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* addMemberSaga(action: AddMemberInCheckoutAction) {
  try {
    const { memId, email } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    let memberId: number = memId || 0;
    if (utils.isEmptyM18Id(memberId)) {
      let userInfo: UserInfo = yield select(getCurrentUserInfo);
      memberId = userInfo.memId;
    }

    const resp: CheckoutResponse = yield call(KopaproApi.addMemberToOrder, orderId, memberId, email?.trim());
    yield call(cartUpdatedSaga, resp);

    if (callback) {
      callback(resp.status, resp.message);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* addDeliverySaga(action: AddDeliveryInCheckoutAction) {
  try {
    const { email, formData } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);
    const isRequireDelivery: boolean = yield select(cartRequireDelivery);

    const userInfo: UserInfo = yield select(getCurrentUserInfo);
    const memberId: number = userInfo.memId;

    let resp: CheckoutResponse = yield call(KopaproApi.addMemberToOrder, orderId, memberId, email);
    if (resp.status) {
      if (isRequireDelivery) {
        resp = yield call(KopaproApi.addDeliveryToOrder, orderId, formData);
      }
      yield call(cartUpdatedSaga, resp);
    }

    if (callback) {
      callback(resp.status, resp.message);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* checkDeliveryChargeSaga(action: CheckDeliveryChargeInCheckoutAction) {
  try {
    const { shipId, shipRegionId } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    const resp: DeliveryChargeResponse = yield call(KopaproApi.checkDeliveryCharge, orderId, shipId, shipRegionId);

    if (callback) {
      callback(resp.status, resp.data.deliveryCharge);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* addPaymentSaga(action: AddPaymentInCheckoutAction) {
  try {
    const { formData } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    const resp: CheckoutResponse = yield call(KopaproApi.addPaymentToOrder, orderId, formData.paymentId);
    yield call(cartUpdatedSaga, resp);
    yield call(getMemberTypeAlertSaga, orderId);

    if (callback) {
      callback(resp.status, resp.message);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* checkPaymentSurchargeSaga(action: CheckPaymentSurchargeInCheckoutAction) {
  try {
    const { paymentId } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    const resp: PaymentSurchargeResponse = yield call(KopaproApi.checkPaymentSurcharge, orderId, paymentId);

    if (callback) {
      callback(resp.status, resp.data.surchargeAmt);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* loadCouponSaga(action: LoadCouponInCheckoutAction) {
  try {
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    const resp: { status: boolean; details: CouponVoucher[] } = yield call(KopaproApi.loadCoupon, orderId);

    if (callback) {
      callback(resp.status, resp.details);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* applyCouponSaga(action: ApplyCouponInCheckoutAction) {
  try {
    const { formData } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    const resp: CheckoutResponse = yield call(KopaproApi.applyCoupon, orderId, formData.storedValues);
    yield call(cartUpdatedSaga, resp);

    if (callback) {
      callback(resp.status, resp.message);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* loadExtraInfoSaga(action: LoadExtraInfoInCheckoutAction) {
  try {
    const { viewId } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    const resp: ExtraResponse = yield call(KopaproApi.retrieveOrderExtra, orderId, viewId);

    if (callback) {
      callback(resp.status, resp.inputs);
    }
    //  load combo or lookup
    yield call(getCheckoutComboLookupSaga, true, true);
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* getCheckoutComboLookupSaga(isNeedLookup = true, iNeedCombo = false) {
  const extraFields: InputFormatList = yield select(getOrderViewInputFormatList);
  if (utils.isNotEmptyList(extraFields)) {
    // TODO: might be called duplicate requests
    const calls: any[] = [];
    extraFields.forEach((inputFormat) => {
      const { type, pattern } = inputFormat;

      if (iNeedCombo && UdfInputFieldType.COMBO === type) {
        calls.push(put(getM18ComboList({ pattern })));
      }

      if (isNeedLookup && UdfInputFieldType.LOOKUP === type) {
        calls.push(put(getM18LookupList({ pattern })));
      }
    });

    yield all(calls);
  }
}

export function* saveExtraInfoSaga(action: SaveExtraInfoInCheckoutAction) {
  try {
    const { formData, imageList } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    const resp: CheckoutResponse = yield call(KopaproApi.saveOrderExtra, orderId, formData, imageList);
    yield call(cartUpdatedSaga, resp);

    if (callback) {
      callback(resp.status, resp.message);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* confirmOrderSaga(action: ConfirmOrderInCheckoutAction) {
  try {
    const callback = action.callback;

    const orderId: string = yield select(getCartId);
    const saveParam: ConfirmOrderParam = {
      subscribedNews: false,
      saveDeliveryToMem: false,
      ...(action.payload?.saveParam || {}),
    };

    const resp: CheckoutResponse = yield call(KopaproApi.confirmOrder, orderId, saveParam);
    yield call(cartUpdatedSaga, resp);
    if (callback) {
      callback(resp.status, resp.message, resp.entity);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function* checkoutSaga() {
  yield all([takeLatest(CheckoutType.ADD_DISCOUNT_CODES_IN_CHECK_OUT, addDiscountCodesSaga)]);
  yield all([takeLatest(CheckoutType.ADD_MEMBER_IN_CHECK_OUT, addMemberSaga)]);
  yield all([takeLatest(CheckoutType.ADD_DELIVERY_IN_CHECK_OUT, addDeliverySaga)]);
  yield all([takeLatest(CheckoutType.CHECK_DELIVERY_CHARGE_IN_CHECK_OUT, checkDeliveryChargeSaga)]);
  yield all([takeLatest(CheckoutType.ADD_PAYMENT_IN_CHECK_OUT, addPaymentSaga)]);
  yield all([takeLatest(CheckoutType.CHECK_PAYMENT_SURCHARGE_IN_CHECK_OUT, checkPaymentSurchargeSaga)]);
  yield all([takeLatest(CheckoutType.LOAD_COUPON_IN_CHECK_OUT, loadCouponSaga)]);
  yield all([takeLatest(CheckoutType.APPLY_COUPON_IN_CHECK_OUT, applyCouponSaga)]);
  yield all([takeLatest(CheckoutType.LOAD_EXTRA_INFO_IN_CHECK_OUT, loadExtraInfoSaga)]);
  yield all([takeLatest(CheckoutType.SAVE_EXTRA_INFO_IN_CHECK_OUT, saveExtraInfoSaga)]);
  yield all([takeLatest(CheckoutType.CONFIRM_ORDER_IN_CHECK_OUT, confirmOrderSaga)]);
}

export default checkoutSaga;
