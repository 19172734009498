import { Component, ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { SmsVerifyProps as SmsVerifyModalProps } from '@kopapro/components/commons/verification/smsVerifyModal';
import { Col, Form } from 'react-bootstrap';
import { M18ViewCheckMsg } from '@kopapro-redux/types/m18View';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import utils from '@kopapro-redux/utils/utils';
import { geti18nValue } from '@kopapro-redux/utils/m18';

interface SmsVerifyModalState {
  verifyCode: string;
  smsId: string;
  errorMessage: string;
  resendCounter: number;
}

export default class SmsVerifyModal extends Component<SmsVerifyModalProps, SmsVerifyModalState> {
  defaultState = { verifyCode: '', smsId: '', errorMessage: '', resendCounter: 60 };
  timer: any = null;

  constructor(props: SmsVerifyModalProps) {
    super(props);
    this.state = {
      ...this.defaultState,
    };
  }

  componentDidMount() {
    this.getVerificationCode();
  }

  componentWillUnmount() {
    this.clearCounter();
  }

  startResendCounter = () => {
    this.setState({ resendCounter: 60 });
    this.clearCounter();
    this.timer = setInterval(() => {
      let resendCounter: number = this.state.resendCounter;
      if (resendCounter > 0) {
        resendCounter = resendCounter - 1;
        this.setState({ resendCounter });
      } else {
        this.clearCounter();
      }
    }, 1000);
  };

  clearCounter = () => {
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
  };

  handleResend = () => {
    this.getVerificationCode();
  };

  handleClose = () => {
    if (this.props.onCloseHandler) {
      this.props.onCloseHandler();
    }
  };

  handleOnClick = () => {
    this.smsVerify();
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: any = e.currentTarget.value;
    this.setState({ verifyCode: newValue });
  };

  getVerificationCode = () => {
    const { requestSMS, mobile, mobileCountry } = this.props;
    const self = this;
    let phone: string = mobileCountry + mobile;

    this.startResendCounter();

    if (utils.isEmpty(mobile)) {
      return;
    }

    // dispatch request
    requestSMS({ mobile: phone }, function (status: boolean, smsId: string) {
      if (status) {
        self.setState({ smsId });
      }
    });
  };

  smsVerify = () => {
    const { verifySMS, paramId, onConfirmHandler } = this.props;
    const { smsId, verifyCode } = this.state;
    const self = this;

    if (utils.isEmpty(this.state.verifyCode)) {
      this.setErrorState('ce01_pmpcore.react.errorVeriCode');
      return;
    }

    // dispatch request
    verifySMS(
      { paramId, smsId, smsVeri: verifyCode },
      function (status: boolean, checkMsg: M18ViewCheckMsg, smsStatus: boolean) {
        // update state errorMessage and successMessage
        let message: string = '';
        if (checkMsg) {
          message = geti18nValue('ce01_pmpcore.react.errorVeriCode');
        }

        if (status) {
          onConfirmHandler(true);
        } else {
          self.setErrorState(message);
        }
      }
    );
  };

  setErrorState = (message: string) => {
    this.setState({
      errorMessage: message,
    });
  };

  maskMobile = (): string => {
    const { mobileCountry, mobile } = this.props;
    let smsPhone: string = mobile;

    if (smsPhone.length > 2) {
      smsPhone = smsPhone.slice(0, -2);
      smsPhone += '**';
    }

    return '+' + mobileCountry + ' ' + smsPhone;
  };

  render(): ReactNode {
    const { t, show } = this.props;
    const { resendCounter } = this.state;
    let verifyCode: string = this.state.verifyCode;
    let title: string = 'ce01_pmpcore.react.verificationCodeSentPhone';
    let smsPhone: string = this.maskMobile();
    const counterLabel: string = resendCounter > 0 ? ' (' + resendCounter + 's)' : '';

    return (
      <Modal
        show={show}
        onHide={this.handleClose}
        backdrop={'static'}
        keyboard={false}
        centered={true}
        fullscreen={'sm-down'}>
        <Modal.Header>{<Modal.Title>{t(title) + smsPhone}</Modal.Title>}</Modal.Header>
        <Form noValidate autoComplete="off">
          <Modal.Body>
            <p>{t('ce01_pmpcore.react.smsVerifyMsg2')}</p>
            <div className={`sms-verify`}>
              <Form.Group as={Col}>
                <Form.Control
                  className="field"
                  placeholder={t(`ce01_pmpcore.react.smsPlaceHolder`)}
                  name={'verifyCode'}
                  value={verifyCode}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                />
              </Form.Group>

              <Form.Group as={Col} className={'resend'}>
                <Button variant="link" className="px-0" onClick={this.handleResend} disabled={resendCounter > 0}>
                  {t('ce01_pmpcore.react.resend') + counterLabel}
                </Button>
              </Form.Group>
              <ErrorMessage message={t(this.state.errorMessage)} />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="outline-secondary" onClick={this.handleClose}>
              {t('ce01_pmpcore.react.backBtn')}
            </Button>
            <Button variant="primary" className="btn-main" onClick={this.handleOnClick}>
              {t('ce01_pmpcore.react.submitBtn')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
