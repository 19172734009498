import { GeneralViewContent, HomeViewContent } from '@kopapro-redux/types/componentSetting';
import { InputFormat, InputFormatList } from '@kopapro-redux/types/componentSetting';
import utils from '@kopapro-redux/utils/utils';

export const defaultViewContent = {
  compId: '',
  view: {
    viewFormat: {
      groupFormat: {},
      inputFormat: {},
    },
    viewOrder: 0,
    smsVerify: false,
    viewId: '',
    viewName: { en: '' },
    showActionBar: false,
    showMessage: false,
    viewType: '',
    viewModule: '',
    viewIRev: 0,
  },
};

export const getInputFormatInView = (viewContent: GeneralViewContent | HomeViewContent | undefined): InputFormat => {
  if (utils.isDefined(viewContent)) {
    let viewObj = viewContent?.view;
    if (viewObj) {
      let formatObj = viewObj.viewFormat;
      if (formatObj) {
        return formatObj.inputFormat;
      }
    }
  }
  return {};
};

export const getInputFormatListInView = (
  viewContent: GeneralViewContent | HomeViewContent | undefined
): InputFormatList => {
  if (utils.isDefined(viewContent)) {
    const format: InputFormat = getInputFormatInView(viewContent!);
    if (utils.isNotEmptyJSONObject(format)) {
      return Object.values(format).sort((a, b) => {
        return a.order - b.order;
      });
    }
  }
  return [];
};
