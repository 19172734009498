import { Card, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputForm, { InputFormState } from '@kopapro/components/commons/inputs/inputs';
import { M18ViewCheckMsg } from '@kopapro-redux/types/m18View';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import utils from '@kopapro-redux/utils/utils';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import { UpdateInfoFormData } from '@kopapro-redux/types/member';
import { DynamicProps } from '@kopapro/components/pages/account/dynamic';

interface DynamicState extends InputFormState {
  errorMessage: string;
  successMessage: string;
}

export default class Dynamic extends InputForm<DynamicProps, DynamicState> {
  defaultState = { formData: {}, imageMap: {}, errorFields: {}, errorMessage: '', successMessage: '', sending: false };

  constructor(props: DynamicProps) {
    super(props);

    this.state = {
      ...this.defaultState,
    };
  }

  componentDidMount() {
    const { loadData, viewId } = this.props;
    loadData({ viewId }, function () {});
  }

  componentDidUpdate(prevProps: DynamicProps) {
    const { viewId } = this.props;
    if (prevProps.viewId !== viewId) {
      this.setState({
        ...this.defaultState,
      });
    }
  }

  handleUpdate = (event: React.FormEvent<HTMLFormElement>) => {
    const { updateInfo, viewId } = this.props;
    const self = this;
    event.preventDefault();
    event.stopPropagation();

    // handle form data
    const formData: UpdateInfoFormData = {
      viewId: viewId as number,
      inputs: this.toM18Values(),
    };
    const imageMap = this.state.imageMap;
    const imageList: string[] = Object.keys(imageMap).filter((imageCode) => utils.isNotEmpty(imageCode));

    this.setState({ sending: true, errorMessage: '', successMessage: '' });

    // dispatch request
    updateInfo({ formData, imageList }, function (status: boolean, checkMsg: M18ViewCheckMsg) {
      // update state errorMessage and successMessage
      let message: string = '';
      if (checkMsg) {
        const mess: I18nDictionary = checkMsg.info?.mess || { en: '' };
        message = geti18nValue(mess);
      }

      if (status) {
        self.setState({
          sending: false,
          successMessage: 'ce01_pmpcore.react.submitSuccess',
          errorMessage: '',
          formData: {},
          imageMap: {},
          errorFields: {},
        });
      } else {
        self.setErrorState(message);
      }
    });
  };

  setErrorState = (message: string) => {
    this.setState({
      sending: false,
      successMessage: '',
      errorMessage: message,
    });
  };

  render = () => {
    const { t, m18Fields, viewName } = this.props;
    const { successMessage, errorMessage } = this.state;

    return (
      <Card className="border-0">
        <Card.Body>
          <Card.Title>{geti18nValue(viewName)}</Card.Title>
          <hr />
          <Form noValidate onSubmit={this.handleUpdate} autoComplete="off">
            <Form.Group as={Col}>
              {m18Fields.map((item, index) => {
                return <div key={index}>{this.renderM18Field(item, true)}</div>;
              })}
            </Form.Group>
            <div className="text-end">
              <Button variant="main" type="submit">
                {t('ce01_pmpcore.react.update')}
              </Button>
            </div>
            <SuccessMessage message={t(successMessage)} />
            <ErrorMessage message={t(errorMessage)} />
          </Form>
        </Card.Body>
      </Card>
    );
  };
}
