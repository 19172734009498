import { Component, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { AccountProps } from '@kopapro/components/pages/account';
import utils from '@kopapro-redux/utils/utils';

export default class Account<T extends AccountProps, S extends {}> extends Component<T, S> {
  render(): ReactNode {
    if (utils.isNotEmpty(this.props.defaultLink)) {
      return <Navigate to={this.props.defaultLink} replace />;
    }
    return (
      <div className="page-container my-account container my-5">
        <div className="mt-5"></div>
      </div>
    );
  }
}
