import utils from '@kopapro/utils/utils';
import { Card, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputForm, { InputFormState } from '@kopapro/components/commons/inputs/inputs';
import { ChangePasswordProps } from '@kopapro/components/pages/account/changePassword';
import { M18ViewCheckMsg } from '@kopapro-redux/types/m18View';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import SmsMobileModal from '@kopapro/components/commons/verification/smsMobileModal';
import SmsVerifyModal from '@kopapro/components/commons/verification/smsVerifyModal';

interface ChangePasswordState extends InputFormState {
  errorMessage: string;
  successMessage: string;

  // sms
  showSmsMobileDialog: boolean;
  showSmsVerifyDialog: boolean;
  smsParamId: string;
  smsMobileCountry: string;
  smsMobile: string;
}

export default class ChangePassword extends InputForm<ChangePasswordProps, ChangePasswordState> {
  constructor(props: ChangePasswordProps) {
    super(props);

    this.state = {
      formData: {},
      imageMap: {},
      errorFields: {},
      errorMessage: '',
      successMessage: '',
      sending: false,

      // sms
      showSmsMobileDialog: false,
      showSmsVerifyDialog: false,
      smsParamId: '',
      smsMobileCountry: '852',
      smsMobile: '',
    };
  }

  handleUpdate = (event: React.FormEvent<HTMLFormElement>) => {
    const { userInfo, changePassword } = this.props;
    const self = this;
    event.preventDefault();
    event.stopPropagation();

    const newPassword: string = this.getFormFieldValue('newPassword');
    const oldPassword: string = this.getFormFieldValue('oldPassword');
    const recofirmPassword: string = this.getFormFieldValue('recofirmPassword');

    // check password
    if (utils.isEmpty(newPassword)) {
      this.setErrorState('ce01_pmpcore.react.emtpyPassword');
      return;
    }

    if (newPassword !== recofirmPassword) {
      this.setErrorState('ce01_pmpcore.react.passwordFail');
      return;
    }
    // end

    this.setState({ sending: true, errorMessage: '', successMessage: '' });

    // dispatch request
    changePassword(
      { newPassword, oldPassword },
      function (status: boolean, checkMsg: M18ViewCheckMsg, needSMS: boolean, paramId: string) {
        // update state errorMessage and successMessage
        let message: string = '';
        if (checkMsg) {
          const msg: string = checkMsg.msg || '';
          message = geti18nValue(msg);
        }

        if (status) {
          let successMessage: string = '';
          let errorMessage: string = '';
          let showSmsMobileDialog: boolean = false;
          let showSmsVerifyDialog: boolean = false;
          let smsParamId: string = paramId;
          let smsMobileCountry: string = '';
          let smsMobile: string = '';

          if (needSMS) {
            if (utils.isNotEmpty(userInfo.mobileNumber)) {
              showSmsVerifyDialog = true;
              if (utils.isNotEmpty(userInfo.mobileCountry)) {
                smsMobileCountry = userInfo.mobileCountry;
              } else {
                smsMobileCountry = '852';
              }
              smsMobile = userInfo.mobileNumber;
            } else {
              showSmsMobileDialog = true;
            }
          } else {
            successMessage = 'ce01_pmpcore.react.submitSuccess';
            smsParamId = '';
          }

          self.setState({
            sending: false,
            successMessage,
            errorMessage,
            showSmsMobileDialog,
            showSmsVerifyDialog,
            smsParamId,
            smsMobileCountry,
            smsMobile,
          });
        } else {
          self.setErrorState(message);
        }
      }
    );
  };

  setErrorState = (message: string) => {
    this.setState({
      sending: false,
      successMessage: '',
      errorMessage: message,
    });
  };

  // sms mobile dialog
  handleSmsMobileDialogClose = () => {
    this.setState({ showSmsMobileDialog: false, smsMobileCountry: '', smsMobile: '', smsParamId: '' });
  };

  handleSmsMobileDialogConfirm = (smsMobileCountry: string, smsMobile: string) => {
    if (!utils.isEmpty(smsMobileCountry) && !utils.isEmpty(smsMobile)) {
      this.setState({ showSmsMobileDialog: false, showSmsVerifyDialog: true, smsMobileCountry, smsMobile });
    }
  };

  // sms verify dialog
  handleSmsVerifyDialogClose = () => {
    this.setState({ showSmsVerifyDialog: false, smsMobileCountry: '', smsMobile: '', smsParamId: '' });
  };

  handleSmsVerifyDialogConfirm = (status: boolean) => {
    if (status) {
     let successMessage: string = 'ce01_pmpcore.react.submitSuccess';
     let smsParamId: string = '';
     this.setState({
       sending: false,
       successMessage,
       showSmsVerifyDialog: false,
       smsParamId,
       smsMobileCountry: '',
       smsMobile: '',
     });
    }
  };

  render = () => {
    const { t, m18Fields, content } = this.props;
    const {
      successMessage,
      errorMessage,
      showSmsMobileDialog,
      showSmsVerifyDialog,
      smsMobileCountry,
      smsMobile,
      smsParamId,
    } = this.state;
    const viewName: I18nDictionary = content?.view.viewName || {};
    const viewTitle = geti18nValue(viewName);

    return (
      <>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>{viewTitle}</Card.Title>
            <hr />
            <Form noValidate onSubmit={this.handleUpdate} autoComplete="off">
              <Form.Group as={Col}>
                {m18Fields.map((item, index) => {
                  return <div key={index}>{this.renderM18Field(item, true)}</div>;
                })}
              </Form.Group>
              <div className="text-end">
                <Button variant="main" type="submit">
                  {t('ce01_pmpcore.react.update')}
                </Button>
              </div>
              <SuccessMessage message={t(successMessage)} />
              <ErrorMessage message={t(errorMessage)} />
            </Form>
          </Card.Body>
        </Card>
        {showSmsMobileDialog && (
          <SmsMobileModal
            show={showSmsMobileDialog}
            onConfirmHandler={this.handleSmsMobileDialogConfirm}
            onCloseHandler={this.handleSmsMobileDialogClose}
          />
        )}
        {showSmsVerifyDialog && (
          <SmsVerifyModal
            show={showSmsVerifyDialog}
            mobileCountry={smsMobileCountry}
            mobile={smsMobile}
            paramId={smsParamId}
            onConfirmHandler={this.handleSmsVerifyDialogConfirm}
            onCloseHandler={this.handleSmsVerifyDialogClose}
          />
        )}
      </>
    );
  };
}
