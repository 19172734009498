import { PreviewType } from '@kopapro-redux/action-types';
import { ShopSetup } from '@kopapro-redux/types/shop';

export const updatePreviewMetaSetup = (payload: ShopSetup) => ({
  type: PreviewType.PREVIEW_SHOP_SETUP,
  payload,
});

export const updatePreviewMetaLayout = (payload: { m18CompId: string; m18Data: any }) => ({
  type: PreviewType.PREVIEW_SHOP_LAYOUT,
  payload,
});

export const updatePreviewMetaComponentSetting = (payload: { m18CompId: string; m18Data: any }) => ({
  type: PreviewType.PREVIEW_SHOP_COMPONENT_SETTING,
  payload,
});

export const updatePreviewMetaBanners = (payload: { m18CompId: string; m18Data: any }) => ({
  type: PreviewType.PREVIEW_SHOP_BANNERS,
  payload,
});
