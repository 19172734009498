import { Component, ReactNode } from 'react';
import { InfoProps } from '@kopapro/components/order/info';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import utils from '@kopapro-redux/utils/utils';
import Badge from 'react-bootstrap/Badge';
import { getOrderStatusMesscode, getPayStatusMesscode, getShipStatusMesscode } from '@kopapro/utils/m18';
interface InfoState {}

export default class Info extends Component<InfoProps, InfoState> {
  getCourierText = (): string => {
    const { orderInfo } = this.props;
    const { couriers } = orderInfo!;
    let result: string = '';
    if (utils.isNotEmptyList(couriers)) {
      result = couriers.map((courier) => `${courier.code} (${courier.name})`).join(', ');
    }
    return result;
  };

  getOrderStatus = (): string => {
    const { t, orderInfo } = this.props;
    if (utils.isUndefined(orderInfo)) {
      return '';
    }
    const { orderStatus } = orderInfo!;
    return t(getOrderStatusMesscode(orderStatus));
  };

  getPayStatus = (): string => {
    const { t, orderInfo } = this.props;
    if (utils.isUndefined(orderInfo)) {
      return '';
    }
    const { payStatus } = orderInfo!;
    return t(getPayStatusMesscode(payStatus));
  };

  getShipStatus = (): string => {
    const { t, orderInfo, delivery } = this.props;
    if (utils.isUndefined(orderInfo)) {
      return '';
    }
    if (utils.isUndefined(delivery) || utils.isEmptyM18Id(delivery?.deliveryId)) {
      return '';
    }
    const { shipStatus } = orderInfo!;
    return t(getShipStatusMesscode(shipStatus));
  };

  render(): ReactNode {
    const { t, orderInfo, userLoggedIn } = this.props;
    let { orderCode: code, orderDate, orderRemark } = orderInfo!;
    const courier = this.getCourierText();
    const orderStatus = this.getOrderStatus();
    const payStatus = this.getPayStatus();
    const shipStatus = this.getShipStatus();

    return (
      <>
        <h3>
          {t('ce01_pmpcore.react.orderCodeTitle', { code })} - {orderStatus}
          <Badge pill>{payStatus}</Badge>
          <Badge pill>{shipStatus}</Badge>
        </h3>
        <p>{t('ce01_pmpcore.react.orderDate', { date: t('{ date, full }', { date: orderDate }) })}</p>
        {!userLoggedIn && <p>{`${t('ce01_pmpcore.kopapro.react.customerEmail')} : ${orderInfo?.customerEmail}`}</p>}
        {utils.isNotEmpty(courier) && <p>{`${t('ce01_pmpcore.react.courierTrace')} : ${courier}`}</p>}
        {utils.isNotEmpty(orderRemark) && (
          <>
            <p>{t('ce01_pmpcore.react.orderRemark')}</p>
            <div>
              <SafetyDOM html={orderRemark} />
            </div>
          </>
        )}
      </>
    );
  }
}
