import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import FeaturedProduct from '@kopapro/components/shop/featuredProduct/featuredProduct';
import { withTranslation, WithTranslation } from 'react-i18next';

import { getDataByComponent } from '@kopapro-redux/selectors/entities/shop';
import { FeaturedProduct as FeaturedProductType } from '@kopapro-redux/types/componentSetting';
import { getProductByIdRequest } from '@kopapro-redux/actions/products';

interface IProps extends WithTranslation {
  compId: string;
  compName: string;
  showIn: string;
}

interface DispatchProps {
  loadProduct: typeof getProductByIdRequest;
}

interface StateProps {
  content?: FeaturedProductType;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const { compId, compName } = props;

  return {
    content: getDataByComponent(state, { compId, compName })?.content,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = { loadProduct: getProductByIdRequest };

export type LiteProductProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FeaturedProduct));
