import { Component, ReactNode } from 'react';
import { LogoListProps } from '@kopapro/components/shop/logoList/index';
import { getCustomStyle, geti18nValue } from '@kopapro/utils/m18';
import { LogoListItemContent } from '@kopapro-redux/types/componentSetting';
import ShopImage from '@kopapro/components/commons/shopImage';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import utils from '@kopapro/utils/utils';

export default class LogoList extends Component<LogoListProps> {
  renderHeading(): ReactNode {
    const { headerObj, enableHeaderStyle, headerDto } = this.props.content!;

    let styleTitle = {};
    if (enableHeaderStyle) {
      styleTitle = getCustomStyle({ ...headerDto, useUdfStyle: true });
    }
    return (
      <div className={`text-center`}>
        <p style={{ ...styleTitle }} className={`fs-1`}>
          {geti18nValue(headerObj)}
        </p>
      </div>
    );
  }

  renderItem(item: LogoListItemContent): ReactNode {
    const { photoCode, url } = item;
    const imageComp: ReactNode = <ShopImage src={photoCode} className={`cover ratio ratio-1x1 img-fluid`} />;
    if (utils.isEmpty(url)) {
      return imageComp;
    }
    return <Link to={url}>{imageComp}</Link>;
  }

  render(): ReactNode {
    const { compId, content } = this.props;
    if (utils.isUndefined(content)) {
      return null;
    }
    const { data } = content!;
    return (
      <Container fluid={`md`}>
        <div id={`logo-list-${compId}`}>
          <Row>{this.renderHeading()}</Row>
          <Row className="justify-content-center" xs={3} md={3} lg={4}>
            {data.map((item, i) => {
              return (
                <Col key={`${compId}_${item.photoCode}_${i}`} className={`p-2`}>
                  {this.renderItem(item)}
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    );
  }
}
