import { OverlayData } from '@kopapro-redux/types/componentSetting';
import { getCustomStyle, geti18nValue, resolveImageURL } from '@kopapro/utils/m18';
import { Component, Fragment, ReactNode } from 'react';
import Image from 'react-bootstrap/Image';
import Placeholder from 'react-bootstrap/Placeholder';
import { AppImages } from '@kopapro/utils/constants/images';
import React from 'react';
import { ProductGroup } from '@kopapro-redux/types/products';

type Props = {
  src: string;
  className?: string;
  style?: any;
  width?: string | number;
  height?: string | number;
  caption?: string;
  alt?: string;
  overlay?: OverlayData;
  fallbackImage?: string; // if error then use
  needOverlayBG?: boolean;
  isPro?: boolean;
  proItem?: ProductGroup;
  images?: string[] | undefined;
};

class ShopImage extends Component<Props> {
  imageRef: React.RefObject<any>;

  state = {
    isLoaded: false,
    isError: false,
    index: 0,
    intervalId: 0,
  };

  constructor(props: Props) {
    super(props);
    this.imageRef = React.createRef();
  }

  handleImageLoaded() {
    this.setState({ isLoaded: true, isError: false });
  }

  handleImageError() {
    this.setState({ isLoaded: true, isError: true });
  }

  changePhoto() {
    const { images, src } = this.props;
    let startIndex = 0;

    images?.forEach((image, key) => {
      if (image === src) {
        startIndex = key;
      }
    });
    this.setState({ index: startIndex });

    if (images !== undefined) {
      let intId = setInterval(() => {
        if (startIndex === images?.length - 1) {
          startIndex = 0;
        } else {
          startIndex = startIndex + 1;
        }
        this.setState({ index: startIndex });
      }, 2000);

      this.setState({ intervalId: intId });
    }
  }

  clearIntervalFunc() {
    const { intervalId } = this.state;
    clearInterval(intervalId);
    this.setState({ index: 0, intervalId: 0 });
  }

  render(): ReactNode {
    const { fallbackImage: propsFallbackImage, overlay, needOverlayBG, isPro, proItem, images, ...rest } = this.props;
    const { index, intervalId } = this.state;
    let fallbackImage = this.props.fallbackImage || AppImages.placeholder;
    let src = resolveImageURL(this.props.src) || resolveImageURL(fallbackImage);

    if (!src || src === '') {
      return null;
    }
    let caption = '';
    let captionStyle = {};
    const { height, width } = this.props;
    if (overlay && overlay.useOverlay) {
      caption = geti18nValue(overlay.overlayObj) || overlay.overlay;
      const { fontColor, fontSize, fontStyle, font } = overlay;
      captionStyle = getCustomStyle({ fontColor, fontSize, fontStyle, font, useUdfStyle: true });
    }

    if (this.state.isError) {
      src = resolveImageURL(fallbackImage);
    }

    if (!this.state.isLoaded) {
      return (
        <Placeholder as="div" animation="glow" className={this.props.className} style={{ color: 'gray' }}>
          <Placeholder className="img-placeholder" xs={12} style={{ minHeight: height, width }} />
          <img
            alt="placeholder"
            style={{ display: 'none' }}
            src={src}
            onLoad={this.handleImageLoaded.bind(this)}
            onError={this.handleImageError.bind(this)}
          />
        </Placeholder>
      );
    }

    if (caption && caption.length > 0) {
      return (
        <div className="shop-image">
          {needOverlayBG && <div className="overlay-background" />}
          <div className="caption" style={captionStyle}>
            {caption}
          </div>
          <img {...rest} src={src} />
        </div>
      );
    }

    if (images !== undefined) {
      if (intervalId !== 0) {
        src = images[index];
      }

      if (src === '') {
        src = resolveImageURL(fallbackImage);
      }

      return (
        <Fragment>
          <Image
            {...rest}
            src={src}
            onMouseEnter={this.changePhoto.bind(this)}
            onMouseLeave={this.clearIntervalFunc.bind(this)}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Image {...rest} src={src} />
      </Fragment>
    );
  }
}

// export default React.memo(ShopImage);
export default ShopImage;
