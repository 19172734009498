import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Gallery from '@kopapro/components/shop/gallery/gallery';
import { GalleryContent } from '@kopapro-redux/types/componentSetting';
import { getDataByComponent } from '@kopapro-redux/selectors/entities/shop';

interface IProps extends WithTranslation {
  compId: string;
  compName: string;
  showIn: string;
}

interface StateProps {
  content?: GalleryContent;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const { compId, compName } = props;
  return {
    content: getDataByComponent(state, { compId, compName })?.gallery,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type GalleryProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Gallery));
