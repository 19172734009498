import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";

import { RootState } from "@kopapro-redux/store";
import { withTranslation, WithTranslation } from "react-i18next";
import { withParams } from "@kopapro/components/commons/router-hoc";
import DeliveryMethodDetail from "@kopapro/components/deliveryMethod/detail/detail";
import { getDeliveryMethodByCode } from '@kopapro-redux/selectors/entities/deliveryMethods';
import { DeliveryMethod } from '@kopapro-redux/types/deliveryMethod';

interface IProps extends WithTranslation {
  params: any; // from withParams
  item: DeliveryMethod;
}

interface StateProps {}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const code = props.params["code"];
  return {
    item: getDeliveryMethodByCode(state, code),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type DeliveryMethodDetailProps = IProps & StateProps & DispatchProps;
export default withParams(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DeliveryMethodDetail)));
