import { GalleryProps } from '@kopapro/components/shop/gallery';
import withInfoPlate from '@kopapro/components/commons/infoPlate';
import React, { Component, ReactNode } from 'react';
import { GalleryItemData } from '@kopapro-redux/types/componentSetting';
import ShopImage from '@kopapro/components/commons/shopImage';
import { getCompHeight } from '@kopapro/utils/m18';
import { Col, Container, Row } from 'react-bootstrap';
import utils from '@kopapro/utils/utils';
import { Link } from 'react-router-dom';

export default class Gallery extends Component<GalleryProps> {
  renderItem = (galleryItem: GalleryItemData, height: string | number) => {
    const { photoCode, overlayDto, url } = galleryItem;
    if (url.length === 0) {
      return <ShopImage src={photoCode} height={height} overlay={overlayDto} className="cover " />;
    }
    return (
      <Link to={url}>
        <ShopImage src={photoCode} height={height} overlay={overlayDto} className="cover " />
      </Link>
    );
  };

  renderGallery = (props: GalleryProps): JSX.Element => {
    const { compId } = props;
    const { data, heightOption, udfHeight } = this.props.content!;
    const height = getCompHeight(heightOption, udfHeight, 300);
    return (
      <Row className={`text-center g-0`}>
        {data.map((item, i) => {
          return (
            <Col className="" key={`${compId}_${item.photoCode}_${i}`}>
              {this.renderItem(item, height)}
            </Col>
          );
        })}
      </Row>
    );
  };

  render(): ReactNode {
    const { compId, content } = this.props;
    if (utils.isUndefined(content)) {
      return null;
    }
    const CompWithInfoPlate = withInfoPlate(this.renderGallery, content?.infoPlateDto!);
    return (
      <Container fluid={'md'}>
        <div id={`gallery-${compId}`} className={`gallery `}>
          <CompWithInfoPlate {...this.props} />
        </div>
      </Container>
    );
  }
}
