import { createSelector } from 'reselect';
import { RootState } from '@kopapro-redux/store';
import { ShippingRegionOptions } from '@kopapro-redux/types/shippingRegion';
import { geti18nValue } from '@kopapro-redux/utils/m18';

export const getShippingRegion = (state: RootState) => {
  return state.kopapro.shippingRegion.data;
};

export const getShippingRegionList = createSelector(getShippingRegion, (data) => {
  if (data) {
    return Object.values(data);
  }
  return [];
});

export const getShippingRegionOptions = createSelector(getShippingRegionList, (list) => {
  if (list) {
    const option: ShippingRegionOptions = [];

    for (let obj of list) {
      let m18Id = obj.m18Id;
      let desc = geti18nValue(obj.desc);
      option.push({
        label: desc,
        value: m18Id.toString(),
        detail: obj,
      });
    }
    return option;
  }
  return [];
});

export const getShippingRegionByM18Id = createSelector(
  [getShippingRegion, (state, m18Id: number) => m18Id],
  (data, m18Id) => {
    if (data) {
      return data[m18Id];
    }
    return undefined;
  }
);
