import { Component, ReactNode, useContext } from 'react';
import { SidebarProps } from '@kopapro/components/header/components/sidebar';
import { ShopName, Language, Currency, Search } from '@kopapro/components/header/components';
import HeaderFooterItem from '@kopapro/components/commons/kppHeaderFooterItem';
import ShopImage from '@kopapro/components/commons/shopImage';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { getCustomStyle, geti18nValue } from '@kopapro/utils/m18';
import utils from '@kopapro-redux/utils/utils';

import { headerConfig } from '@kopapro/utils/config';
import { LayoutComponent, ShopNavbarLayoutMenuComponent } from '@kopapro-redux/types/shop';
import { GridLayoutElement, GridLayoutRow } from '@kopapro-redux/types/componentSetting';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { AccordionContext } from 'react-bootstrap';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { M18RnMeta } from '@kopapro-redux/utils/constant';

type ToggleProp = {
  children?: ReactNode;
  eventKey: string;
  callback?: Function;
  style?: any;
};
// Custom Accordion Button
function AccordionToggle({ children, eventKey, callback, style }: ToggleProp) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;
  let icon = <ExpandLessIcon />;
  if (!isCurrentEventKey) {
    icon = <ExpandMoreIcon />;
  }

  return (
    <button
      type="button"
      className="accordion-button collapsed d-flex justify-content-between"
      style={style}
      onClick={decoratedOnClick}>
      {children} {icon}
    </button>
  );
}

class Sidebar extends Component<SidebarProps> {
  itemProps = {
    imageSize: headerConfig.imageSize,
    iconSize: headerConfig.iconSize as SizeProp,
  };

  getComponentByElement = (element?: GridLayoutElement) => {
    if (!element) {
      return null;
    }
    const name = element.type;
    const { companyName, logo, components, navBarStyle, closeDrawer } = this.props;
    const inheritClass = utils.isNotEmptyJSONObject(navBarStyle) ? 'inherit' : '';
    const { font, width, height, marginLeft, marginRight, marginBottom, marginTop } = element;
    const fontStyle = getCustomStyle({ ...font, useUdfStyle: true }, true);
    const marginStyle = { marginLeft, marginRight, marginBottom, marginTop };
    if (name === 'ShopName') {
      return (
        <ShopName
          key={name}
          name={companyName}
          enableNameStyle={true}
          style={marginStyle}
          nameStyle={{ ...font, useUdfStyle: true }}
        />
      );
    } else if (name === 'ShopLogo') {
      return <ShopImage key={name} src={logo} width={width} height={height} style={marginStyle} />;
    } else if (name === 'SearchTextBar') {
      const iconStyle = { color: element.font.iconColor, fontSize: width };
      return (
        <div key={name} className="d-flex">
          <Search basic closeDrawer={closeDrawer} marginStyle={marginStyle} iconStyle={iconStyle} />
        </div>
      );
    } else if (name === 'CurrencySelect') {
      return <Currency key={name} fontStyle={fontStyle} />;
    } else if (name === 'LanguageSelect') {
      return <Language key={name} fontStyle={fontStyle} />;
    } else if (name === 'ShopMenu') {
      return (
        <ListGroup key="navbar" style={navBarStyle} className={`flex-grow-1 ${inheritClass}`}>
          {components.map((node, index) => {
            return this.renderNavbarItem(node, true);
          })}
        </ListGroup>
      );
    }

    return <span key={name}>{name}</span>;
  };

  renderGridCell = (cell: any, key: string) => {
    const { getElementClass } = this.props;
    const { elements } = this.props.gridLayout!;
    const { elementDisplay, elements: elementNames, flexWidth } = cell;

    return (
      <div
        key={`cell_${key}`}
        style={{ flex: flexWidth }}
        className={'d-flex w-100  ' + (getElementClass ? getElementClass(elementDisplay) : '')}>
        {elementNames.map((name: string) => {
          const element = elements.find((element) => element.type === name);
          return this.getComponentByElement(element);
        })}
      </div>
    );
  };

  renderNavbarItem = (node: LayoutComponent, isFirstLevel = false) => {
    const { children, compId, compDesc } = node as ShopNavbarLayoutMenuComponent;
    const { closeDrawer, navBarStyle } = this.props;
    let itemProps = { ...this.itemProps };
    if (node.compName === M18RnMeta.UDFSOCIALMEDIA) {
      itemProps.imageSize = navBarStyle.fontSize;
    }
    if (children.length === 0) {
      return <HeaderFooterItem key={compId} parentType="header" node={node} {...itemProps} onClick={closeDrawer} />;
    }
    return (
      <Accordion className={`${isFirstLevel ? '' : 'nested'}`} key={compId}>
        <Accordion.Item eventKey="0">
          <AccordionToggle eventKey={'0'} style={navBarStyle}>
            {geti18nValue(compDesc)}
          </AccordionToggle>
          <Accordion.Body>
            {children.map((node) => {
              return this.renderNavbarItem(node);
            })}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  renderGridDrawerContent = () => {
    const { rows } = this.props.gridLayout!;
    return (
      <Box sx={{ width: 250 }} role="presentation" className="sidebar">
        {rows.map((row: GridLayoutRow, index: number) => {
          const { height, cells, backgroundColor } = row;
          let rowStyle: any = { height, backgroundColor };
          const isNavBarRow = cells.find((cell: any) => cell.elements.includes('ShopMenu')) !== undefined;
          if (isNavBarRow) {
            delete rowStyle.height;
          }
          return (
            <div key={`row_${index}`} className={`d-flex`} style={rowStyle}>
              {this.renderGridCell(cells[0], `${index}`)}
            </div>
          );
        })}
      </Box>
    );
  };

  // default drawer
  renderDrawerContent = () => {
    const { logo, companyName, components, closeDrawer, navBarStyle } = this.props;

    const inheritClass = utils.isNotEmptyJSONObject(navBarStyle) ? 'inherit' : '';
    return (
      <Box sx={{ width: 250 }} role="presentation" className="sidebar">
        <div className="p-2 border-bottom">
          <div className="d-flex navbar-brand">
            <ShopImage src={logo} height="30" width="30" />
            <ShopName name={companyName} />
          </div>
        </div>

        <div key="search" className="d-flex">
          <Search basic closeDrawer={closeDrawer} />
        </div>
        <ListGroup style={navBarStyle} className={`${inheritClass}`}>
          {components.map((node, index) => {
            return this.renderNavbarItem(node, true);
          })}
        </ListGroup>
        <div key="static" className="d-flex justify-content-center">
          <Language />
          <Currency />
        </div>
      </Box>
    );
  };

  render(): ReactNode {
    const { open, closeDrawer, gridLayout } = this.props;

    const drawerContent = gridLayout ? this.renderGridDrawerContent() : this.renderDrawerContent();

    return (
      <Drawer anchor="left" open={open} onClose={closeDrawer}>
        {drawerContent}
      </Drawer>
    );
  }
}

export default Sidebar;
