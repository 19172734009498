import { Component } from "react";
import { DeliveryMethodListProps } from "@kopapro/components/deliveryMethod/list";
import DeliveryMethodItem from "@kopapro/components/deliveryMethod/item";
import KppBreadcrumb from "@kopapro/components/commons/breadcrumb";
import ScrollToTopOnMount from "@kopapro/template/ScrollToTopOnMount";

export default class DeliveryMethodList extends Component<DeliveryMethodListProps> {
  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t } = this.props;

    const items = [
      { text: t("ce01_pmpcore.react.home"), to: "/", active: false },
      { text: t("ce01_pmpcore.react.deliveryMethods"), to: "/delivery-methods", active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  render() {
    const { list: deliveryMethods } = this.props;

    return (
      <div className="delivery-method-list-container page-container container px-xl-5">
        <ScrollToTopOnMount />
        {this.renderTop()}
        <div className="delivery-method-list row">
          <div className="d-flex flex-column h-100">
            <div className={"row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-3 mb-4 flex-shrink-0 "}>
              {deliveryMethods.map((item) => {
                return <DeliveryMethodItem key={item.code} item={item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
