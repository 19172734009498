import { FC } from 'react';
import { ShopLayoutComponent } from '@kopapro-redux/types/shop';
import { geti18nValue, getRouteTarget, resolveImageURL } from '@kopapro/utils/m18';
import { M18RnMeta } from '@kopapro/utils/constants/m18';
import { AppImages } from '@kopapro/utils/constants/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import utils from '@kopapro/utils/utils';
import { IconName, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';
interface Props {
  parentType: string;
  node: ShopLayoutComponent;
  imageSize: number;
  iconSize: string;
  itemStyles?: any;
  isDropdown?: boolean;
  onClick?: Function;
}

const HeaderFooterItem: FC<Props> = ({ parentType, node, itemStyles, imageSize, iconSize, isDropdown, onClick }) => {
  const { compName, socialMediaType, contentType } = node;
  const text = geti18nValue(node.compDesc);
  const target = getRouteTarget(compName, node);
  const isFooter = parentType === 'footer';

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  let navigate = useNavigate();

  // simple case
  if (isFooter && contentType === 'span') {
    return <span></span>;
  }

  if (isFooter && target === '') {
    return <span>{text}</span>;
  }

  let useComponent: any = Nav.Link;
  if (isDropdown) {
    useComponent = NavDropdown;
  }
  if (isFooter) {
    useComponent = Link;
  }

  // handle social media
  if (compName === M18RnMeta.UDFSOCIALMEDIA && node.socialMediaIcon) {
    const socialNetworks = Object.values(M18RnMeta.SOCIAL_MEDIA_TYPES);
    const exists = utils.isDefined(socialNetworks.find((socialMedia) => socialMedia === socialMediaType));
    let iconOrImages = null;
    if (exists) {
      // if no icon support
      if (socialMediaType === 'mewe' || socialMediaType === 'instagram') {
        let socialImageSize = itemStyles?.fontSize || imageSize;
        iconOrImages = (
          <img
            alt="social icon"
            src={resolveImageURL(AppImages[`${socialMediaType}Icon`])}
            style={{ width: socialImageSize, height: socialImageSize }}
          />
        );
      } else {
        let fontStyleProps = {};
        if (itemStyles && itemStyles.fontSize) {
          fontStyleProps = { style: { fontSize: itemStyles.fontSize } };
        } else if (iconSize) {
          fontStyleProps = { size: iconSize as SizeProp };
        }
        iconOrImages = (
          <FontAwesomeIcon
            {...fontStyleProps}
            icon={['fab', socialMediaType as IconName]}
            className={`icon-${socialMediaType}`}
          />
        );
      }

      if (isFooter) {
        useComponent = 'a';
      }

      return React.createElement(
        useComponent,
        {
          href: target,
          style: itemStyles,
          onClick: handleClick,
          target: '_blank',
        },
        iconOrImages
      );
    }
  }

  const handleHashLink = (e: any) => {
    e.preventDefault();
    handleClick();
    const href = e.target.getAttribute('data-href');
    navigate({ pathname: '/' }, { state: { scrollTo: href } });
  };

  let props: any = {
    style: itemStyles,
    onClick: handleClick,
  };

  if (target !== '') {
    props = { ...props, ...{ as: Link, to: target } };
    if (target[0] === '#') {
      props = { ...props, onClick: handleHashLink, 'data-href': target };
    }
  }

  return React.createElement(useComponent, props, text);
};

export default HeaderFooterItem;
