import ProductList from '@kopapro/components/products/productList';
import { Component, ReactNode } from 'react';
import { LiteProductListProps } from '@kopapro/components/shop/liteProductList';
import { getCustomStyle, geti18nValue } from '@kopapro/utils/m18';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import utils from '@kopapro/utils/utils';

export default class LiteProductList extends Component<LiteProductListProps> {
  renderHeader = () => {
    const { title, content } = this.props;

    let headerTitle = title;
    let style: any = {};
    if (utils.isDefined(content)) {
      const { headerDto, headerObj, enableHeaderStyle } = content!;
      headerTitle = headerObj;
      if (enableHeaderStyle) {
        const { font, fontColor, fontSize, fontStyle } = headerDto!;
        style = getCustomStyle({ font, fontColor, fontSize, fontStyle, useUdfStyle: true });
      }
    }

    return (
      <h5 className="mb-3" style={style}>
        {geti18nValue(headerTitle)}
      </h5>
    );
  };

  renderMore = (categoryId: number) => {
    const { t, content } = this.props;

    if (content && !content.showViewAll) {
      return null;
    }

    let to = '/categories/All';
    if (categoryId > 0) {
      to = `/proPanel/${categoryId}`;
    }

    return (
      <div className="text-end">
        <Link to={to}>
          <Button variant="link">{t('ce01_pmpcore.react.more')}</Button>
        </Link>
      </div>
    );
  };

  render(): ReactNode {
    const { content, isDefault } = this.props;

    let categoryId = 0;

    let listProps: any = { isDefault };
    if (utils.isDefined(content)) {
      const { cateId, numOfColumn, numOfRow } = content!;
      listProps = { ...listProps, column: numOfColumn, row: numOfRow };
      categoryId = cateId;
    }

    return (
      <div className={`container flex-column`}>
        {this.renderHeader()}
        <ProductList lite categoryId={categoryId} {...listProps} />
        {this.renderMore(categoryId)}
      </div>
    );
  }
}
