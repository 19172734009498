import { RootState } from '@kopapro-redux/store';
import utils from '@kopapro-redux/utils/utils';
import { createSelector } from 'reselect';
import { getOrderViewInputFormatList } from '@kopapro-redux/selectors/entities/checkout';

export const getCart = (state: RootState) => {
  return state.kopapro.cart;
};

export const getCartId = (state: RootState) => {
  return state.kopapro.cart.orderId;
};

export const cartUpdating = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.updating;
  }
  return false;
});
export const isCartRetrieving = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.retrieving;
  }
  return false;
});


export const getCartItems = createSelector(
  [getCart, (_, skipExclude: boolean = true) => skipExclude],
  (cart, skipExclude) => {
    if (cart) {
      if (skipExclude) {
        return cart.items.filter((item) => !item.isExclude);
      }
      return cart.items;
    }
  }
);

export const getCartMessage = createSelector([getCart], (cart) => {
  if (cart) {
    return cart.cartMessage;
  }
  return '';
});

export const getNumberOfItemsInCart = createSelector(getCart, (cart) => {
  if (cart) {
    let newItems = cart.items.filter((item) => {
      return !item.isPackSubPro;
    });
    return newItems.length;
  }
  return 0;
});

export const getProductQtyInCart = createSelector(
  [getCart, (state, params: { proGpId: number; proId: number }) => params],
  (cart, params) => {
    if (cart) {
      let item = cart.items.find((item) => {
        return item.proGpId === params.proGpId && item.proId === params.proId;
      });
      return item ? item.qty : 0;
    }
    return 0;
  }
);

export const getProductsQtyInCart = createSelector(
  [getCart, (state, params: { proGpCode: string }) => params],
  (cart, params) => {
    if (cart) {
      return cart.items
        .filter((item) => {
          return item.proGpCode === params.proGpCode;
        })
        .map((item) => {
          return { proId: item.proId, qty: item.qty };
        });
    }
    return [];
  }
);

export const getCartDiscountCodes = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.discCodes.filter((code) => utils.isNotEmpty(code));
  }
  return [];
});

export const getCartAmountDetails = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.amountDetails;
  }
});

export const getCartMember = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.member;
  }
});

export const getCartDeliveryId = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.deliveryId;
  }
  return 0;
});

export const getCartDelivery = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.delivery;
  }
});

export const getCartPaymentId = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.paymentId;
  }
  return 0;
});

export const getCartPaymentCoupons = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.paymentCoupons || [];
  }
  return [];
});

export const cartRequireDelivery = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.isRequireDelivery;
  }
  return true;
});

export const cartRequirePayment = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.isRequirePayment;
  }
  return true;
});

export const cartRequireExtra = (state: RootState) => {
  const inputs = getOrderViewInputFormatList(state);
  if (utils.isNotEmptyList(inputs)) {
    return true;
  }
  return false;
};

export const getPromotionAlert = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.promotionAlert;
  }
});

export const getMemberTypeAlert = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.memberTypeAlert;
  }
});

export const getRedemptionList = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.redemptionList;
  }
});

export const getGiftList = createSelector(getCart, (cart) => {
  if (cart) {
    return cart.giftList;
  }
});

export const isAllCouponItems = createSelector(getCartItems, (items) => {
  if (items) {
    for (let item of items) {
      if (!item.isSV && item.amt >= 0) {
        return false;
      }
    }
  }
  return true;
});
