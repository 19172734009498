import { RootState } from '@kopapro-redux/store';

export const isAppinitializing = (state: RootState) => {
  return state.kopapro.app.initializing;
};

export const isUpdating = (state: RootState) => {
  return state.kopapro.app.updating;
};


export const isKopaproExpired = (state: RootState) => {
  return state.kopapro.app.expired;
};

export const showAnnouncement = (state: RootState) => {
  return state.kopapro.app.showAnnouncement;
};