import { BlogType } from '@kopapro-redux/action-types';
import { BlogCategory, BlogDetail, GetBlogDetailPayload, GetBlogListPayload } from '@kopapro-redux/types/blog';
import { CommonAction } from '@kopapro-redux/actions/misc';

export const getBlogDetailRequest = (payload: GetBlogDetailPayload, callback?:Function): CommonAction => ({
  type: BlogType.GET_BLOG_DETAIL_REQUEST,
  payload,
  callback
});

export const fetchBlogDetailSuccess = (payload: BlogDetail) => ({
  type: BlogType.RECEIVED_BLOG_DETAIL,
  payload,
});

export const getBlogListRequest = (payload: GetBlogListPayload, callback?: Function): CommonAction => ({
  type: BlogType.GET_BLOG_LIST_REQUEST,
  payload,
  callback,
});

export const fetchBlogListSuccess = (payload: BlogDetail[], oriPayload: GetBlogListPayload) => ({
  type: BlogType.RECEIVED_BLOG_LIST,
  payload,
  oriPayload,
});

export const fetchBlogCategoriesSuccess = (payload: BlogCategory[]) => ({
  type: BlogType.RECEIVED_BLOG_CATEGORIES,
  payload,
});

export const fetchBlogCategories = (payload?: any, callback?: Function): CommonAction => {
  return {
    type: BlogType.GET_BLOG_CATEGORIES_REQUEST,
    payload,
    callback,
  };
};