import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from "@kopapro-redux/store";
import ProductItem from "@kopapro/components/products/item/item";
import { withTranslation, WithTranslation } from 'react-i18next';

import { ProductGroup } from '@kopapro-redux/types/products';
import { fetchProductCategories } from '@kopapro-redux/actions/products';
import { getProductGroupById } from '@kopapro/kopapro-redux/selectors/entities/products';
import { getSelectedCurrency } from '@kopapro/kopapro-redux/selectors/entities/user';

interface IProps extends WithTranslation {
  code?: string;
  proId?: number;
  imageHeight?: string;
  buyButton?: boolean; // default depends on config
}

interface StateProps {
  item?: ProductGroup;
  currency: string;
}

interface DispatchProps {
  loadCategories: typeof fetchProductCategories;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  let item: ProductGroup | undefined;

  if (props.proId) {
    item = getProductGroupById(state, props.proId);
  } else if (props.code) {
    item = state.kopapro.products.productGroups[props.code];
  }

  return {
    item,
    currency: getSelectedCurrency(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
    loadCategories: fetchProductCategories
};


export type ProductItemProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProductItem));
