import { RootState } from '@kopapro-redux/store';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import { createSelector } from 'reselect';
import { getM18Combo, getM18Lookup } from '@kopapro-redux/selectors/entities/m18Option';
import { UdfInputFieldType } from '@kopapro-redux/utils/constant';
import { OrderExtraInfoField } from '@kopapro-redux/types/order';
import { getCurrentLang } from '@kopapro-redux/selectors/entities/user';
export const getOrder = (state: RootState) => {
  return state.kopapro.order;
};

export const getOrderItems = createSelector(getOrder, (order) => {
  if (order) {
    return order.items;
  }
});

export const getNumberOfItemsInOrder = createSelector(getOrder, (order) => {
  if (order) {
    return order.items.length;
  }
  return 0;
});

export const getOrderAmountDetails = createSelector(getOrder, (order) => {
  if (order) {
    return order.amountDetails;
  }
});

export const getOrderCurrencySymbol = (state: RootState) => {
  const items = getOrderItems(state);
  if (items && items.length > 0) {
    return items[0].curSym;
  }
  return '';
};

export const getOrderInfo = createSelector(getOrder, (order) => {
  if (order) {
    return order.orderInfo;
  }
});

export const getOrderDelivery = createSelector(getOrder, (order) => {
  if (order) {
    return order.delivery;
  }
});

export const getOrderPayment = createSelector(getOrder, (order) => {
  if (order) {
    return order.payment;
  }
});

export const getOrderExtraInfo = createSelector(getOrder, (order) => {
  if (order) {
    return order.extraInfo;
  }
});

export const getOrderExtraInfoFields = createSelector(getOrder, (order) => {
  if (order) {
    return order.extraInfo?.fields || [];
  }
});

export const getDisplayOrderExtraInfo = createSelector(
  [getOrderExtraInfo, getM18Combo, getM18Lookup, getCurrentLang],
  (extraInfo, combo, lookup, lang) => {
    extraInfo?.fields.forEach((field: OrderExtraInfoField) => {
      const { type, pattern, value } = field;
      if (UdfInputFieldType.COMBO === type) {
        if (combo && combo[pattern] && combo[pattern][value as string]) {
          field.displayValue = geti18nValue(combo[pattern][value as string]);
        }
      }

      if (UdfInputFieldType.LOOKUP === type) {
        if (lookup && lookup[pattern] && lookup[pattern][Number(value)]) {
          field.displayValue = lookup[pattern][Number(value)].label;
        }
      }
    });
    return extraInfo;
  }
);

export const getDisplayOrderExtraInfoFields = createSelector([getDisplayOrderExtraInfo], (extraInfo) => {
  return extraInfo?.fields;
});
