import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import Item from '@kopapro/components/cart/cartItem/cartItem';
import { CartItem } from '@kopapro-redux/types/cart';
import {
  modifyItemInCart,
  removeProductFromCart,
  selectGiftRedemptionFromCart,
} from '@kopapro/kopapro-redux/actions/cart';
import { loadCartInformation } from '@kopapro-redux/actions/cart';
import { getSelectedCurrency } from '@kopapro-redux/selectors/entities/user';
import { cartUpdating } from '@kopapro-redux/selectors/entities/cart';
interface IProps extends WithTranslation {
  item: CartItem;
  updatePending: Function;
}

interface StateProps {
  selectedCurrency: string;
  cartUpdating: boolean;
}

interface DispatchProps {
  changeItemQuantity: typeof modifyItemInCart;
  removeFromCart: typeof removeProductFromCart;
  loadInformation: typeof loadCartInformation;
  selectGiftRedemption: typeof selectGiftRedemptionFromCart;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    selectedCurrency: getSelectedCurrency(state),
    cartUpdating: cartUpdating(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  changeItemQuantity: modifyItemInCart,
  removeFromCart: removeProductFromCart,
  loadInformation: loadCartInformation,
  selectGiftRedemption: selectGiftRedemptionFromCart,
};

export type CartItemProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Item));
