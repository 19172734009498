import { ReactNode } from 'react';
import { Card, ListGroup, Form } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import DiamondIcon from '@mui/icons-material/Diamond';
import SearchIcon from '@mui/icons-material/Search';
import { RewardProps } from '@kopapro/components/pages/account/reward';
import Query, { QueryState } from '@kopapro/components/pages/account/query';
import Loading from '@kopapro/components/commons/loading';
import utils from '@kopapro/utils/utils';
import { Trans } from 'react-i18next';
import { formatDate, geti18nValue } from '@kopapro-redux/utils/m18';
import { BonusPoints, BonusPointsOtherBalance } from '@kopapro-redux/types/bonusPoints';
import { BonusPointsLedger, BonusPointsLedgerData } from '@kopapro-redux/types/bonusPoints';
import Pager from '@kopapro/components/commons/pager';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import RemindMessage from '@kopapro/components/commons/remindMessage';

interface RewardState extends QueryState {
  ledger: {
    sending: boolean;
    inputValue: string | number;
    data: BonusPointsLedgerData[];
    displayData: BonusPointsLedgerData[];
    displayCount: number;
    page: number;
  };
}

export default class Reward extends Query<RewardProps, RewardState> {
  override usePagination = false;

  override fetching = false;

  override defaultState = {
    formData: {},
    imageMap: {},
    errorFields: {},
    sending: false,
    errorMessage: '',
    successMessage: '',
    query: { queryId: '', querySize: 0 },
    startPoint: 1,
    dataCount: 9,
    data: [],
    page: 0,
    ledger: {
      sending: false,
      inputValue: 1,
      data: [],
      displayData: [],
      displayCount: 6,
      page: 0,
    },
  };

  constructor(props: RewardProps) {
    super(props);
    this.state = this.defaultState;
  }

  override componentDidMount() {
    this.handleQuery();
    this.loadLedgerRecords();
    if (!this.usePagination) {
      document.addEventListener('scroll', this.handleScroll);
    }
  }

  override componentDidUpdate(prevProps: Readonly<RewardProps>): void {
    const prev_svId = prevProps.bonusPointsSetup.svId || 0;
    const now_svId = this.props.bonusPointsSetup.svId || 0;
    if (utils.isNotEmptyM18Id(now_svId) && prev_svId !== now_svId) {
      this.loadLedgerRecords();
    }
  }

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: any = e.currentTarget.value;
    if (newValue.length > 0 && !utils.isIntegerString(newValue)) {
      return;
    }
    if (utils.isEmpty(newValue)) {
      this.setLedgerState({ inputValue: '' });
    } else {
      const tempValue = Math.max(1, parseInt(newValue || 1));
      if (tempValue <= 99) {
        this.setLedgerState({
          inputValue: tempValue,
        });
      }
    }
  };

  onInputBlur = () => {
    let qty = 0;
    if (typeof this.state.ledger.inputValue === 'number') {
      qty = this.state.ledger.inputValue as number;
    }
    let value = Math.max(1, qty);
    if (value !== qty) {
      this.setLedgerState({ inputValue: value });
    }
  };

  setErrorState = (message: string) => {
    this.setState({
      sending: false,
      successMessage: '',
      errorMessage: message,
    });
  };

  setLedgerState = (value: { [key: string]: any }) => {
    this.setState({ ledger: { ...this.state.ledger, ...value } });
  };

  onLedgerPageChange = (newPage: number) => {
    const count: number = newPage * this.state.ledger.displayCount;
    const displayData = this.state.ledger.data.slice(count, count + this.state.ledger.displayCount);
    this.setLedgerState({ page: newPage, displayData });
  };

  loadLedgerRecords = () => {
    const { loadLedger, bonusPointsSetup } = this.props;
    const self = this;

    let svId: number = bonusPointsSetup.svId || 0;
    if (utils.isEmptyM18Id(svId)) {
      return;
    }

    const startDate = new Date();
    const diff = Math.max(1, Number(this.state.ledger.inputValue.toString()));
    startDate.setFullYear(startDate.getFullYear() - diff);
    const endDate = new Date();

    if (startDate === null) {
      this.setErrorState('ce01_pmpcore.kopapro.react.askInputDate');
      return;
    } else if (formatDate(startDate) === 'Invalid Date') {
      this.setErrorState('ce01_pmpcore.kopapro.react.fieldInvalidDate');
      return;
    }

    if (endDate === null) {
      this.setErrorState('ce01_pmpcore.kopapro.react.askInputDate');
      return;
    } else if (formatDate(endDate) === 'Invalid Date') {
      this.setErrorState('ce01_pmpcore.kopapro.react.fieldInvalidDate');
      return;
    }

    if (startDate > endDate) {
      this.setErrorState('ce01_pmpcore.react.sDateLaterEDate');
      return;
    }

    this.setLedgerState({ sending: true });

    loadLedger({ svId, startDate, endDate }, function (isSuccess: boolean, content: BonusPointsLedger) {
      if (isSuccess && content) {
        const data = content.data || [];
        const displayData = data.slice(0, self.state.ledger.displayCount);
        self.setLedgerState({ sending: false, data, displayData, page: 0 });
      } else {
        self.setLedgerState({ sending: false, data: [], displayData: [], page: 0 });
      }
    });
  };

  // summary
  renderSummary(): ReactNode {
    const { t, bonusPointsSetup } = this.props;

    let displayName: string = t('ce01_pmpcore.kopapro.react.rewards.bonusPoints');
    if (bonusPointsSetup) {
      displayName = geti18nValue(bonusPointsSetup.balanceDisplayName!);
    }

    let balance: number = 0;
    let unit: string = '';
    const { data } = this.state;
    if (data && data.length > 0) {
      const content: BonusPoints = data[0].detail[0].content;
      displayName = geti18nValue(content.displayName);
      balance = content.currentBalance;
      unit = geti18nValue(content.unit);
    }
    let balanceStr: string = t('ce01_pmpcore.kopapro.react.rewards.point', { count: balance });
    if (utils.isNotEmpty(unit)) {
      balanceStr = `${balance} ${unit}`;
    }
    return (
      <div className="d-flex justify-content-center mb-2">
        <Card className="border-0">
          <Card.Body>
            <Card.Title className="pt-3 pb-2 text-muted text-center">{displayName}</Card.Title>
            <div className="d-flex justify-content-center">
              <div className="card-icon d-flex align-items-center text-info me-2">
                <DiamondIcon sx={{ fontSize: 32 }} />
              </div>
              <span className="text-center fs-3">{balanceStr}</span>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }

  renderSummaryMore(): ReactNode {
    const { t } = this.props;
    let balanceList: BonusPointsOtherBalance[] = [];

    const { data } = this.state;
    let unit: string = '';
    if (data && data.length > 0) {
      const content: BonusPoints = data[0].detail[0].content;
      balanceList = content.otherBalance;
      unit = geti18nValue(content.unit);
    }

    return (
      <ListGroup variant="flush">
        {balanceList.map((item, index) => {
          let balanceStr: string = t('ce01_pmpcore.kopapro.react.rewards.point', { count: item.balance });
          if (utils.isNotEmpty(unit)) {
            balanceStr = `${item.balance} ${unit}`;
          }
          return (
            <ListGroup.Item key={index} className="d-flex justify-content-between">
              <span>{geti18nValue(item.udfLabel)}</span>
              <span className="text-nowrap text-success">{balanceStr}</span>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  }

  // histroy
  renderHistory(): ReactNode {
    const { t } = this.props;
    const { ledger } = this.state;
    const { sending, inputValue } = ledger;

    const input = (
      <Form.Control
        type="number"
        min={1}
        max={99}
        value={inputValue}
        onChange={this.onInputChange}
        onBlur={(e) => this.onInputBlur()}
        className="hide-spin-button mx-2"
        style={{ width: 50 }}
      />
    );
    return (
      <>
        <div className="d-flex align-items-center justify-content-end mb-3">
          <Trans t={t} i18nKey="ce01_pmpcore.kopapro.react.rewards.filter" components={[input]} />
          <Button className="ms-2" variant="main" disabled={sending} onClick={() => this.loadLedgerRecords()}>
            <SearchIcon />
            {t('ce01_pmpcore.react.search')}
          </Button>
        </div>
        {this.renderHistoryDetail()}
        {/* pagination */}
        {this.renderHistoryPagination()}
      </>
    );
  }

  renderHistoryDetailColumnName(type: string, value: I18nDictionary): ReactNode {
    let comp: ReactNode = <th>{geti18nValue(value)}</th>;
    return comp;
  }

  renderHistoryDetailComponent(type: string, value: any): ReactNode {
    let comp: ReactNode = <td>{geti18nValue(value)}</td>;
    return comp;
  }

  renderHistoryDetail(): ReactNode {
    const { t, bonusPointsSetup } = this.props;
    const ledgerSetup = bonusPointsSetup ? bonusPointsSetup.ledger || [] : [];

    const { ledger } = this.state;
    const { sending } = ledger;
    if (sending) {
      return <Loading />;
    }

    const data: BonusPointsLedgerData[] = ledger.displayData || [];
    if (!data || data.length <= 0) {
      return <RemindMessage className={`text-center`} message={t(`ce01_pmpcore.react.emptyRecord`)} />;
    }

    const compsData: ReactNode[][] = [];
    for (let obj of data) {
      const compData: ReactNode[] = [];
      for (let key of ledgerSetup) {
        let type = key.columnType;
        let value = obj[type];
        let comp = this.renderHistoryDetailComponent(type, value);
        compData.push(comp);
      }
      compsData.push(compData);
    }
    return (
      <Table responsive size="sm">
        <thead>
          <tr>
            {ledgerSetup.map((item) => {
              let comp = this.renderHistoryDetailColumnName(item.columnType, item.columnName);
              return comp;
            })}
          </tr>
        </thead>
        <tbody>
          {compsData.map((components) => {
            return (
              <tr>
                {components.map((component) => {
                  return component;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderHistoryPagination = () => {
    const { sending } = this.state.ledger;
    if (!sending) {
      const { t } = this.props;
      const { page, displayCount } = this.state.ledger;
      const size = this.state.ledger.data.length;
      const pageSize = displayCount;

      const indexOfLastRecord = (page + 1) * pageSize;
      const indexOfFirstRecord = indexOfLastRecord - pageSize;

      return (
        <div className="d-flex align-items-center mt-auto">
          <span className="text-muted">
            {t('ce01_pmpcore.kopapro.react.pagination.showNumber', {
              currentFirstRecord: `${size <= 0 ? 0 : indexOfFirstRecord + 1}`,
              currentLastRecord: `${Math.min(indexOfLastRecord, size)}`,
              size,
            })}
          </span>
          <nav aria-label="Page navigation" className="ms-auto">
            <Pager current={page} itemPerPage={pageSize} total={size} onPageChange={this.onLedgerPageChange} />
          </nav>
        </div>
      );
    }
  };

  render(): ReactNode {
    const { t, viewId, viewJsonParam, bonusPointsSetup } = this.props;
    if (utils.isEmpty(viewId.toString())) {
      return null;
    }

    let showBalanceByPeriod: boolean = false;
    let showBalanceInLedger: boolean = false;

    if (viewJsonParam) {
      showBalanceByPeriod = !viewJsonParam.bpHideBalance;
      showBalanceInLedger = !viewJsonParam.bpHideLedger;
    } else if (bonusPointsSetup) {
      showBalanceByPeriod = !bonusPointsSetup.hideBalance;
      showBalanceInLedger = !bonusPointsSetup.hideLedger;
    }

    return (
      <Card className="border-0 account-rewards">
        <Card.Body>
          <Card.Title>{t('ce01_pmpcore.kopapro.react.rewards.title')}</Card.Title>
          <hr />

          <div className="mb-5">
            {showBalanceByPeriod && this.renderSummary()}
            {showBalanceByPeriod && this.renderSummaryMore()}
          </div>

          <div className="mb-5">{showBalanceInLedger && this.renderHistory()}</div>
        </Card.Body>
      </Card>
    );
  }
}
