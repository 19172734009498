import ImageScroller from '@kopapro/components/productDetail/imageScroller/imageScroller';

interface IProps {
  images: string[];
  selectedImage: string;
  vertical: boolean;
  onSelect: Function;
}
export type ScrollerProps = IProps;

export default ImageScroller;
