import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Facebook from '@kopapro/components/misc/integration/facebook/facebook';

import { getFacebookMeta } from '@kopapro-redux/selectors/entities/facebook';
import { FacebookSetup } from '@kopapro-redux/types/facebook';

interface IProps {}

interface StateProps {
  facebook?: FacebookSetup;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    facebook: getFacebookMeta(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type FacebookProps = IProps & StateProps & DispatchProps;
export default connect(mapStateToProps, mapDispatchToProps)(Facebook);
