import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Sidebar from '@kopapro/components/header/components/sidebar/sidebar';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withLocation } from '@kopapro/components/commons/router-hoc';
import { ShopNavbarLayoutMenuComponent } from '@kopapro-redux/types/shop';
import { getShopName, getNavbarComponents, getShopInfo } from '@kopapro-redux/selectors/entities/shop';
import { geti18nValue } from '@kopapro/utils/m18';
import { getCurrentLang } from '@kopapro-redux/selectors/entities/user';
import { GridLayout } from '@kopapro-redux/types/componentSetting';

interface IProps extends WithTranslation {
  open: boolean;
  closeDrawer: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  navBarStyle: any;
  getElementClass?: Function;
}

interface StateProps {
  components: ShopNavbarLayoutMenuComponent[];
  companyName: string;
  logo: string;
  lang: string;
  gridLayout?: GridLayout;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const shopInfo = getShopInfo(state);

  return {
    components: getNavbarComponents(state) as ShopNavbarLayoutMenuComponent[],
    companyName: geti18nValue(getShopName(state)),
    logo: shopInfo ? shopInfo.logo : '',
    lang: getCurrentLang(state),
    gridLayout: shopInfo?.enableMobileSideBarGridLayout ? shopInfo.mobileSideBarGridLayout : undefined,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type SidebarProps = IProps & StateProps & DispatchProps;
export default withLocation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Sidebar)));
