import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import Captcha from '@kopapro/components/commons/captcha/captcha';
import { refreshCaptcha } from '@kopapro-redux/actions/user';
import { getLoginSetupCaptchaType } from '@kopapro/kopapro-redux/selectors/entities/login';

interface IProps extends WithTranslation {
  onSelected: Function;
  nonce: number;
}

interface DispatchProps {
  refreshCaptcha: typeof refreshCaptcha;
}

interface StateProps {
  captchaType: string;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  let captchaType: string = getLoginSetupCaptchaType(state);
  return { captchaType };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  refreshCaptcha: refreshCaptcha,
};

export type CaptchaProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Captcha));
