import { Card, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputForm, { InputFormState } from '@kopapro/components/commons/inputs/inputs';
import { M18ViewCheckMsg } from '@kopapro-redux/types/m18View';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import { AddressProps } from '@kopapro/components/pages/account/address';
import utils from '@kopapro-redux/utils/utils';
import { InputValues } from '@kopapro-redux/types/componentSetting';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import { UpdateInfoFormData } from '@kopapro-redux/types/member';
import { FormFieldValue } from '@kopapro/components/commons/inputs/inputs';

interface AddressState extends InputFormState {
  errorMessage: string;
  successMessage: string;

  disabledList: string[];
}

export default class Address extends InputForm<AddressProps, AddressState> {
  constructor(props: AddressProps) {
    super(props);

    this.state = {
      formData: {},
      imageMap: {},
      errorFields: {},
      errorMessage: '',
      successMessage: '',
      sending: false,

      disabledList: [],
    };
  }

  componentDidMount() {
    this.loadInitialData();

    const { loadData, viewId } = this.props;
    loadData({ viewId }, function () {});
  }

  componentDidUpdate(prevProps: AddressProps) {
    const { viewId } = this.props;
    if (prevProps.viewId !== viewId) {
      this.loadInitialData();
    } else {
      const key: string = this.getFormFieldInputId('pmpcoreShipRegionId') || '';
      const v1: FormFieldValue = this.state.formData[key];

      if (utils.isDefined(v1)) {
        this.updateDisabledList(v1.value);
      }
    }
  }

  loadInitialData = () => {
    const { getAddress, viewId } = this.props;
    const self = this;

    this.setState({ sending: true });

    getAddress({ viewId }, function (status: boolean, inputs: InputValues) {
      if (!inputs) {
        inputs = {};
      }
      self.setState({ formData: inputs, sending: false });

      let key: string = self.getFormFieldInputId('pmpcoreShipRegionId') || '';
      let v1: FormFieldValue = inputs[key];
      if (v1) {
        self.updateDisabledList(v1.value);
      }
    });
  };

  handleUpdate = (event: React.FormEvent<HTMLFormElement>) => {
    const { updateAddress, viewId } = this.props;
    const self = this;
    event.preventDefault();
    event.stopPropagation();

    // handle form data
    const formData: UpdateInfoFormData = {
      viewId: viewId as number,
      inputs: this.toM18Values(),
    };
    const imageMap = this.state.imageMap;
    const imageList: string[] = Object.keys(imageMap).filter((imageCode) => utils.isNotEmpty(imageCode));

    this.setState({ sending: true, errorMessage: '', successMessage: '' });

    // dispatch request
    updateAddress({ formData, imageList }, function (status: boolean, checkMsg: M18ViewCheckMsg) {
      // update state errorMessage and successMessage
      let message: string = '';
      if (checkMsg) {
        message = geti18nValue(checkMsg.msg);
        if (checkMsg.info) {
          const mess: I18nDictionary = checkMsg.info?.mess || { en: '' };
          message = geti18nValue(mess);
        }
      }

      if (status) {
        self.setState({
          sending: false,
          successMessage: 'ce01_pmpcore.react.submitSuccess',
          errorMessage: '',
        });
      } else {
        self.setErrorState(message);
      }
    });
  };

  setErrorState = (message: string) => {
    this.setState({
      sending: false,
      successMessage: '',
      errorMessage: message,
    });
  };

  override updateFormData = (id: string, newValue: any) => {
    let inputValue: FormFieldValue = this.getInputValue(id);
    inputValue = {
      ...inputValue,
      value: newValue,
    };
    let formDataState = { ...this.state.formData, [id]: inputValue };

    // extension
    let list: FormFieldValue[] = Object.values(this.updateAddressFormData(id, newValue));
    list.forEach((item) => {
      formDataState = { ...formDataState, [item.inputId]: item };
    });
    // end of extension

    const formData: { [key: string]: FormFieldValue } = { ...formDataState };
    this.setState({ formData });
  };

  updateAddressFormData = (id: string, newValue: any) => {
    let key: string = this.getFormFieldInputId('pmpcoreShipRegionId') || '';
    const { shipRegionList } = this.props;
    let formDataState = {};

    if (id === key) {
      let countryId: string = this.getFormFieldInputId('pmpcoreCountry') || '';
      let provinceId: string = this.getFormFieldInputId('pmpcoreProvince') || '';
      let cityId: string = this.getFormFieldInputId('pmpcoreCity') || '';
      let zipcodeId: string = this.getFormFieldInputId('pmpcoreZipcode') || '';
      const shipRegionItem = shipRegionList!.find((p) => p.m18Id === parseInt(newValue));

      if (shipRegionItem) {
        // country
        let inputCountry: FormFieldValue = this.getInputValue(countryId);
        inputCountry = {
          ...inputCountry,
          value: shipRegionItem.country,
        };
        formDataState = { ...formDataState, [countryId]: inputCountry };

        // province
        let inputProvince: FormFieldValue = this.getInputValue(provinceId);
        inputProvince = {
          ...inputProvince,
          value: shipRegionItem.province,
        };
        formDataState = { ...formDataState, [provinceId]: inputProvince };

        // city
        let inputCity: FormFieldValue = this.getInputValue(cityId);
        inputCity = {
          ...inputCity,
          value: shipRegionItem.city,
        };
        formDataState = { ...formDataState, [cityId]: inputCity };

        // zipcode
        let inputZipcode: FormFieldValue = this.getInputValue(zipcodeId);
        inputZipcode = {
          ...inputZipcode,
          value: shipRegionItem.zipcode,
        };
        formDataState = { ...formDataState, [zipcodeId]: inputZipcode };

        // update disabled list
        this.updateDisabledList(newValue);
      }
    }

    return formDataState;
  };

  updateDisabledList = (shipRegionValue: string) => {
    const { shipRegionList } = this.props;
    let disabledList: string[] = [];
    const current = this.state.disabledList;

    let countryId: string = this.getFormFieldInputId('pmpcoreCountry') || '';
    let provinceId: string = this.getFormFieldInputId('pmpcoreProvince') || '';
    let cityId: string = this.getFormFieldInputId('pmpcoreCity') || '';
    let zipcodeId: string = this.getFormFieldInputId('pmpcoreZipcode') || '';

    let shipRegionItem = shipRegionList!.find((p) => p.m18Id === parseInt(shipRegionValue));

    if (shipRegionItem) {
      // disabled (country)
      if (!shipRegionItem.allowOverwrite && utils.isNotEmpty(shipRegionItem.country)) {
        disabledList.push(countryId);
      }

      // disabled (province)
      if (!shipRegionItem.allowOverwrite && utils.isNotEmpty(shipRegionItem.province)) {
        disabledList.push(provinceId);
      }

      // disabled (city)
      if (!shipRegionItem.allowOverwrite && utils.isNotEmpty(shipRegionItem.city)) {
        disabledList.push(cityId);
      }

      // disabled (zipcode)
      if (!shipRegionItem.allowOverwrite && utils.isNotEmpty(shipRegionItem.zipcode)) {
        disabledList.push(zipcodeId);
      }
    }
    if (JSON.stringify(current) !== JSON.stringify(disabledList)) {
      this.setState({ disabledList });
    }
  };

  render = () => {
    const { t, m18Fields, viewName } = this.props;
    const { successMessage, errorMessage, disabledList } = this.state;

    return (
      <Card className="border-0">
        <Card.Body>
          <Card.Title>{geti18nValue(viewName)}</Card.Title>
          <hr />
          <Form noValidate onSubmit={this.handleUpdate} autoComplete="off">
            <Form.Group as={Col}>
              {m18Fields.map((item, index) => {
                const disabledItem = disabledList.find((e) => e === item.id);
                let disabled: boolean = false;
                if (disabledItem) {
                  disabled = true;
                }
                return <div key={index}>{this.renderM18Field(item, true, { disabled })}</div>;
              })}
            </Form.Group>
            <div className="text-end">
              <Button variant="main" type="submit">
                {t('ce01_pmpcore.react.update')}
              </Button>
            </div>
            <SuccessMessage message={t(successMessage)} />
            <ErrorMessage message={t(errorMessage)} />
          </Form>
        </Card.Body>
      </Card>
    );
  };
}
