import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from "@kopapro-redux/store";
import ContactUs from "@kopapro/components/shop/contactUs/contactUs";
import { withTranslation, WithTranslation } from 'react-i18next';

import { ContactUsContent } from '@kopapro-redux/types/componentSetting';
import { getDataByComponent } from '@kopapro-redux/selectors/entities/shop';
import { submitContactUs } from '@kopapro-redux/actions/user';

interface IProps extends WithTranslation {
  compId: string;
  compName: string;
  showIn: string;
}

interface DispatchProps {
  submit: typeof submitContactUs;
}

interface StateProps {
  content: ContactUsContent | undefined;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const { compId, compName } = props;
  const data = getDataByComponent(state, { compId, compName })?.content;
    return {
        content: data
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
    submit: submitContactUs,
};


export type ContactUsProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactUs));



