import { Component, ReactNode } from 'react';
import { BlogPostProps } from '@kopapro/components/shop/blogPost/index';
import utils from '@kopapro/utils/utils';
import BlogList from '@kopapro/components/blog/list';
import { Container } from 'react-bootstrap';
import { getCustomStyle, geti18nValue } from '@kopapro/utils/m18';

export default class BlogPost extends Component<BlogPostProps> {
  openFullBlogList = () => {
    const { navigate, category } = this.props;
    if (utils.isDefined(category)) {
      navigate('/blogs/' + category!.code);
    } else {
      navigate('/blogs');
    }
  };

  renderHeading = (): ReactNode => {
    const { headerObj, enableHeaderStyle, headerDto } = this.props.content!;

    let styleTitle = {};
    if (enableHeaderStyle) {
      styleTitle = getCustomStyle({ ...headerDto, useUdfStyle: true });
    }

    return (
      <div>
        <p style={{ ...styleTitle }} className={`fs-1`}>
          {geti18nValue(headerObj)}
        </p>
      </div>
    );
  };

  render() {
    const { compId, content, t } = this.props;
    if (utils.isUndefined(content)) {
      return null;
    }

    const { cateId, displayMode, showAuthor, showDate, showYearMonth, showViewAll } = content!;
    return (
      <Container fluid={'md'}>
        <div id={`blog-post-${compId}`} className={`blog-post`}>
          {this.renderHeading()}
          <BlogList
            categoryId={cateId}
            displayMode={displayMode}
            showAuthor={showAuthor}
            showDate={showDate}
            showHashTags={true}
            showYearMonth={showYearMonth}
            length={content!.numOfBlog}
          />
          {showViewAll && (
            <div className="align-self-end my-2 text-end">
              <button
                className="btn btn-outline-main"
                onClick={() => {
                  this.openFullBlogList();
                }}>
                {t(`ce01_pmpcore.react.viewAll`)}
              </button>
            </div>
          )}
        </div>
      </Container>
    );
  }
}
