import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import SmsVerifyModal from '@kopapro/components/commons/verification/smsVerifyModal/smsVerifyModal';
import { requestSMS, verifySMS } from '@kopapro-redux/actions/user';

interface IProps extends WithTranslation {
  show: boolean;
  mobileCountry: string;
  mobile: string;
  paramId: string;
  onCloseHandler: Function;
  onConfirmHandler: Function;
}

interface DispatchProps {
  requestSMS: typeof requestSMS;
  verifySMS: typeof verifySMS;
}

interface StateProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  requestSMS,
  verifySMS,
};

export type SmsVerifyProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SmsVerifyModal));
