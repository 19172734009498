import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Order from '@kopapro/components/order/order';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withParams, withNavigation } from '@kopapro/components/commons/router-hoc';
import { cancelOrder, getOrder } from '@kopapro-redux/actions/order';
import { OrderItem, OrderPayment } from '@kopapro-redux/types/order';
import { getOrderInfo, getOrderItems, getOrderPayment } from '@kopapro-redux/selectors/entities/order';
import { getShopOrderSetup } from '@kopapro-redux/selectors/entities/shop';

interface IProps extends WithTranslation {
  params: any; // from withParams
  navigate: any;
}

interface StateProps {
  orderId: string;
  orderStatus: string;
  payStatus: string;
  shipStatus: string;
  hideCancelOrder: boolean;
  items?: OrderItem[];
  payment?: OrderPayment;
}

interface DispatchProps {
  loadOrder: typeof getOrder;
  cancelOrder: typeof cancelOrder;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const orderId = props.params['orderId'] || '';
  return {
    orderId: orderId,
    orderStatus: getOrderInfo(state)?.orderStatus || 'na',
    payStatus: getOrderInfo(state)?.payStatus || 'unpaid',
    shipStatus: getOrderInfo(state)?.shipStatus || 'unfulfilled',
    hideCancelOrder: getShopOrderSetup(state).disableCancelOrder,
    items: getOrderItems(state),
    payment: getOrderPayment(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadOrder: getOrder,
  cancelOrder,
};

export type OrderProps = IProps & StateProps & DispatchProps;
export default withParams(withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Order))));
