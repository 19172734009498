import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Newsletter from '@kopapro/components/shop/newsletter/newsletter';
import { withTranslation, WithTranslation } from 'react-i18next';

import { NewsletterContent } from '@kopapro-redux/types/componentSetting';
import { getDataByComponent } from '@kopapro-redux/selectors/entities/shop';
import { subscribeNewsletter } from '@kopapro-redux/actions/user';

interface IProps extends WithTranslation {
  compId: string;
  compName: string;
  showIn: string;
}

interface DispatchProps {
  subscribe: typeof subscribeNewsletter;
}

interface StateProps {
  content: NewsletterContent | undefined;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const { compId, compName } = props;
  const data = getDataByComponent(state, { compId, compName })?.content;
  return {
    content: data,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  subscribe: subscribeNewsletter,
};

export type NewsletterProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Newsletter));
