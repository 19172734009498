import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from "@kopapro-redux/store";
import Category from "@kopapro/components/products/category/category";
import { withTranslation, WithTranslation } from 'react-i18next';

import { getProductCategoryList } from '@kopapro-redux/selectors/entities/productCategories';
import { clearFilter, fetchProductCategories } from '@kopapro-redux/actions/products';
import { ProductCategory } from '@kopapro-redux/types/productCategory';

interface IProps extends WithTranslation {}

interface StateProps {
  categories: ProductCategory[];
}

interface DispatchProps {
  loadCategories: typeof fetchProductCategories;
  clearFilter: typeof clearFilter;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    categories: getProductCategoryList(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadCategories: fetchProductCategories,
  clearFilter: clearFilter,
};

export type CategoryProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Category));
