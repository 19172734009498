import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from "@kopapro-redux/store";
import { withTranslation, WithTranslation } from 'react-i18next';
import Filter from "@kopapro/components/products/productList/filters/filters";
import { getProductFilters, isFilterEnabled } from '@kopapro-redux/selectors/entities/shop';
import { ProQuickSearch } from '@kopapro-redux/types/componentSetting';
import { applyFilter } from '@kopapro-redux/actions/products';
import { getProductCategories, getProductCategoryShortList } from '@kopapro-redux/selectors/entities/productCategories';
import { ProductCategory } from '@kopapro-redux/types/productCategory';
import { getAppliedFilter } from '@kopapro-redux/selectors/entities/products';
import { ProductFilter } from '@kopapro-redux/types/products';
import { isSameAsBaseCurrency, getBaseCurrency } from '@kopapro-redux/selectors/entities/currency';
interface IProps extends WithTranslation {
  categoryCode: string; // from withParams
  onFilterApplied: Function;
  hideCategory?: boolean;
  isMobile?: boolean;
}

interface StateProps {
  filterData: ProQuickSearch | undefined;
  isFilterEnabled: boolean;
  category: ProductCategory;
  appliedFilter?: ProductFilter;
  categories: { code: string; desc: string; proCnt: number }[];
  isSameAsBaseCurrency: boolean;
  baseCurrencyCode: string;
  priceMin: string;
  priceMax: string;
}

interface DispatchProps {
  applyFilter: typeof applyFilter;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  let categoryCode = props.categoryCode;
  const category = getProductCategories(state)[categoryCode];
  const all = getProductCategories(state)['All'];
  return {
    appliedFilter: getAppliedFilter(state),
    filterData: getProductFilters(state),
    isFilterEnabled: isFilterEnabled(state),
    category,
    categories: getProductCategoryShortList(state),
    baseCurrencyCode: getBaseCurrency(state)?.code || '',
    isSameAsBaseCurrency: isSameAsBaseCurrency(state),
    priceMin: all.priceMin,
    priceMax: all.priceMax,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  applyFilter: applyFilter,
};


export type FilterProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Filter));
