import { ReactNode } from 'react';
import { resolveProductItemImageURL } from '@kopapro/utils/m18';
import ShopImage from '@kopapro/components/commons/shopImage';
import { cartItemConfig } from '@kopapro/utils/config';
import DeleteIcon from '@mui/icons-material/Delete';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { AppImages } from '@kopapro/utils/constants/images';
import { CartItemProps } from '@kopapro/components/cart/cartItem';
import CartItem from '@kopapro-original/components/cart/cartItem/cartItem';

class CustomCartItem extends CartItem {
  constructor(props: CartItemProps) {
    super(props);
  }

  render(): ReactNode {
    const { sending, editing } = this.state;
    const { item, cartUpdating } = this.props;

    const photoCode = item.photoCode;
    const remark = item.remarks;

    const remarkArr: string[] = remark.split('<br>');

    return (
      <ListGroup.Item className="cart-item">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <Link to={this.getProductLink()} state={item.proId ? item.proId : null}>
              <ShopImage
                src={resolveProductItemImageURL(photoCode, item.isSV)}
                height={cartItemConfig.imageSize}
                width={cartItemConfig.imageSize}
                className="cover"
                fallbackImage={AppImages.productPlaceholder}
              />
            </Link>
            <div className="ms-3">
              {this.renderNameAndAttribute()}
              <div className="mt-1">{this.renderProductTypeIcon()}</div>
              <div className="mt-1">{this.renderPrice()}</div>
              {remarkArr &&
                remarkArr.map((elem, key) => {
                  return <div className="mt-1">{elem}</div>;
                })}
            </div>
          </div>
          {!item.disableDeletePro && (
            <div className="text-end">
              <button
                className="btn px-2"
                onClick={() => this.removeProductHandler()}
                disabled={sending || editing || cartUpdating}>
                <DeleteIcon />
              </button>
            </div>
          )}
        </div>

        <div className="mt-2 mb-2 d-flex justify-content-end">{this.renderQuantity()}</div>

        <div className="mt-2 mb-2 d-flex justify-content-between">
          {this.renderPromotion()}
          {this.renderAmount()}
        </div>

        <div className="mt-2 mb-2">{this.renderProductError()}</div>
      </ListGroup.Item>
    );
  }
}

export default CustomCartItem;
