import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import WildCard from '@kopapro/components/preview/wildCard/wildCard';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IProps extends WithTranslation {
  prevM18CompId?: string;
}

interface StateProps {}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type WildCardProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WildCard));
