import { Dictionary } from '@kopapro-redux/types/utilities';

export const CurrencyDigit: Dictionary<number> = {
  ADP: 0,
  AFN: 0,
  ALL: 0,
  AMD: 2,
  BHD: 3,
  BIF: 0,
  BYN: 2,
  BYR: 0,
  CAD: 2,
  CHF: 2,
  CLF: 4,
  CLP: 0,
  COP: 2,
  CRC: 2,
  CZK: 2,
  DEFAULT: 2,
  DJF: 0,
  DKK: 2,
  ESP: 0,
  GNF: 0,
  GYD: 2,
  HUF: 2,
  IDR: 2,
  IQD: 0,
  IRR: 0,
  ISK: 0,
  ITL: 0,
  JOD: 3,
  JPY: 0,
  KMF: 0,
  KPW: 0,
  KRW: 0,
  KWD: 3,
  LAK: 0,
  LBP: 0,
  LUF: 0,
  LYD: 3,
  MGA: 0,
  MGF: 0,
  MMK: 0,
  MNT: 2,
  MRO: 0,
  MUR: 2,
  NOK: 2,
  OMR: 3,
  PKR: 2,
  PYG: 0,
  RSD: 0,
  RWF: 0,
  SEK: 2,
  SLL: 0,
  SOS: 0,
  STD: 0,
  SYP: 0,
  TMM: 0,
  TND: 3,
  TRL: 0,
  TWD: 2,
  TZS: 2,
  UGX: 0,
  UYI: 0,
  UYW: 4,
  UZS: 2,
  VEF: 2,
  VND: 0,
  VUV: 0,
  XAF: 0,
  XOF: 0,
  XPF: 0,
  YER: 0,
  ZMK: 0,
  ZWD: 0,
};
