import { RatingProps } from '@kopapro/components/productDetail/review';
import Button from 'react-bootstrap/Button';
import utils from '@kopapro-redux/utils/utils';
import React from 'react';
import DefaultModal from '@kopapro/components/commons/modals';
import Ratings from '@kopapro/components/commons/ratings';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { AppImages } from '@kopapro/utils/constants/images';
import ShopImage from '@kopapro/components/commons/shopImage';
import { resolveImageURL } from '@kopapro/utils/m18';
import { ProductReviewInfo } from '@kopapro-redux/types/products';
import { ImageType } from '@kopapro/utils/constants/constants';

interface ReviewState {
  showModal: boolean;
  results: string[];
  info: ProductReviewInfo[];
}

export default class ProductReview extends React.Component<RatingProps, ReviewState> {
  defaultState = {
    info: [],
    results: [],
  };

  constructor(props: RatingProps) {
    super(props);
    this.state = { ...this.defaultState, showModal: false };
  }

  componentDidMount() {
    const { loadProductComment, getProductComment, products } = this.props;
    const self = this;

    if (products) {
      const proIdList = products.map((item) => {
        return item.m18ProId;
      });

      loadProductComment(
        {
          proIdList,
        },
        function (status: boolean, queryId: string, querySize: number) {
          if (querySize) {
            getProductComment(
              { proIdList, queryId, startPoint: 1, dataCount: querySize },
              function (status: boolean, results: string[], info: ProductReviewInfo[]) {
                self.setState({ results, info });
              }
            );
          }
        }
      );
    }
   
  }

  showAll = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showModal: true });
  };

  // handle term modal close
  handleClose = () => {
    this.setState({ showModal: false });
  };

  renderItems = (info: any[], results: any[], canScroll: boolean) => {
    const { t } = this.props;
    return info.map((item: any, index: number) => {
      const message = results[index];
      const { postTime, score, displayName } = item;
      return (
        <div key={postTime} className="mt-4 review">
          <div className="text-muted">
            <AccountCircleRoundedIcon />
            {displayName}
          </div>
          <div className="d-flex align-items-end">
            <Ratings rating={score} />
            <span className="ps-2">
              <small>{t('{ date, short }', { date: postTime })}</small>
            </span>
          </div>
          <SafetyDOM className={`content ${canScroll ? 'scollable-content' : ''}`} html={message} />
        </div>
      );
    });
  };

  getModalTitle = () => {
    const { t, imageCode, productName } = this.props;

    return (
      <div className="d-flex">
        <ShopImage
          className={'rounded shadow-sm me-2'}
          width={50}
          height={50}
          src={resolveImageURL(imageCode, false, ImageType.mobilePro)}
          fallbackImage={AppImages.productPlaceholder}
        />
        <div className="d-flex flex-column fs-6">
          <div>{productName}</div>
          <div>{t('ce01_pmpcore.react.comment')}</div>
        </div>
      </div>
    );
  };

  render = () => {
    const { t } = this.props;
    const { info, results } = this.state;

    if (utils.isEmptyList(info)) {
      return null;
    }

    const hasMore = info.length > 3;
    const allContent = this.renderItems(info, results, true);

    let average =
      info.reduce((accumulator, object) => {
        return accumulator + object.score;
      }, 0) / info.length;

    average = Math.round(10 * average) / 10;
    return (
      <div className="reviews mb-4">
        <h4 className="review-title text-muted my-4">
          {t('ce01_pmpcore.react.comment')} - <Ratings rating={average} size={20} />
        </h4>

        <div className="">
          {this.renderItems(info.slice(0, 3), results.slice(0, 3), false)}
          {hasMore && (
            <div>
              <Button onClick={this.showAll} variant="link px-0">
                {t(`ce01_pmpcore.react.readMore`)}
              </Button>
            </div>
          )}
        </div>
        <DefaultModal
          show={this.state.showModal}
          title={this.getModalTitle()}
          body={<div className="reviews">{allContent}</div>}
          centered={true}
          scrollable={true}
          onCloseHandler={this.handleClose}
        />
      </div>
    );
  };
}
