import { FC, useState } from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { OnApproveActions, OnApproveData } from '@paypal/paypal-js';
import { PayStatus } from '@kopapro-redux/utils/constant';
import { PayPalButtonProps } from '@kopapro/components/payment/paypal';
import Loading from '@kopapro/components/commons/loading';
import utils from '@kopapro-redux/utils/utils';
import ErrorMessage from '@kopapro/components/commons/errorMessage';

const PayPalButton: FC<PayPalButtonProps> = ({
  t,
  navigate,
  clientId,
  currency,
  language,
  paypalOrderId,
  kppOrderId,
  approvePayPal,
}) => {
  const [isApproving, setIsApproving] = useState(false);
  const [message, setMessage] = useState('');

  const createOrder = async () => {
    const result = await Promise.resolve(paypalOrderId);
    return result;
  };

  const onCaptureSuccess = (status: boolean, res: any) => {
    // check paypal error first
    if (status && res.entity && res.entity.payStatus === PayStatus.PAID) {
      navigate(`/order/${kppOrderId}`, { replace: true });
    }
  };

  const onApprove = async (data: OnApproveData, actions: OnApproveActions) => {
    setIsApproving(true);
    approvePayPal({ orderId: kppOrderId }, function (status: boolean, res: any) {
      if (utils.isNotEmpty(res.message)) {
        setMessage(res.message);
        setIsApproving(false);
        if (res.message === 'INSTRUMENT_DECLINED') {
          return actions.restart();
        }
        setIsApproving(false);
      } else {
        onCaptureSuccess(status, res);
      }
    });
    /*
     // Three cases to handle:
      //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
      //   (2) Other non-recoverable errors -> Show a failure message
      //   (3) Successful transaction -> Show confirmation or thank you
      // This example reads a v2/checkout/orders capture response, propagated from the server
      // You could use a different API or structure for your 'orderData'
      var errorDetail = Array.isArray(orderData.details) && orderData.details[0];
      if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
         return actions.restart(); // Recoverable state, per:

         // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
      }
      if (errorDetail) {
         var msg = 'Sorry, your transaction could not be processed.';
         if (errorDetail.description)
            msg += '\n\n' + errorDetail.description;
         if (orderData.debug_id)
            msg += ' (' + orderData.debug_id + ')';
         return alert(msg); // Show a failure message
      }
      // Show a success message
      if (onCaptureSuccess && typeof onCaptureSuccess == 'function') {
         onCaptureSuccess();
      }
    */

    // handle fail?

    return;
  };

  // assumed locale is correct
  let locale = language.replace('-', '_');
  if (locale === '' || locale === 'en') {
    locale = 'en_US';
  }
  if (isApproving) {
    return <Loading showMessage={false} />;
  } else if (utils.isNotEmpty(message) && message !== 'INSTRUMENT_DECLINED') {
    return <ErrorMessage message={t('ce01_pmpcore.kopapro.react.paypal.error.unknown', { error: message })} />;
  }

  return (
    <PayPalScriptProvider
      options={{
        locale: locale,
        'client-id': clientId,
        currency: currency,
      }}>
      <div style={{ textAlign: 'center', marginLeft: 30, marginRight: 30 }}>
        <PayPalButtons createOrder={createOrder} onApprove={onApprove} />
      </div>
      {message === 'INSTRUMENT_DECLINED' && (
        <ErrorMessage message={t('ce01_pmpcore.kopapro.react.paypal.error.INSTRUMENT_DECLINED')} />
      )}
    </PayPalScriptProvider>
  );
};

export default PayPalButton;
