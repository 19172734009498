import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import ContactUsPage from '@kopapro/components/pages/contactUs/contactUs';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ContactUsContent } from '@kopapro-redux/types/componentSetting';
import { getDataByComponent } from '@kopapro-redux/selectors/entities/shop';
import { submitContactUs } from '@kopapro-redux/actions/user';
import { M18RnMeta } from '@kopapro/utils/constants/m18';

interface IProps extends WithTranslation {}

interface DispatchProps {
  submit: typeof submitContactUs;
}

interface StateProps {
  compId: string;
  compName: string;
  content: ContactUsContent | undefined;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const compName = M18RnMeta.CONTACTUSPAGE;
  const compId = M18RnMeta.CONTACTUSPAGE;
  const data = getDataByComponent(state, { compId, compName })?.content;
  return {
    compId,
    compName,
    content: data,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  submit: submitContactUs,
};

export type ContactUsPageProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactUsPage));
