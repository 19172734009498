import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import { ContainerProps } from '@kopapro/components/productDetail';
import { formatUnitPrice, getCustomStyle, geti18nValue, getProductDetailPricing } from '@kopapro/utils/m18';
import { Product, ProductAttributeValue, ProductGroup } from '@kopapro-redux/types/products';
import ProductItem from '@kopapro/components/products/item';
import React from 'react';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb/breadcrumb';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import utils from '@kopapro/utils/utils';
import { productDetailConfig as config } from '@kopapro/utils/config';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Currency } from '@kopapro-redux/utils/constant';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ShareButtons from '@kopapro/components/commons/shareButtons';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TailorModal from '@kopapro/components/productDetail/tailorModal';

import InfoIcon from '@mui/icons-material/Info';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import HourglassBottom from '@mui/icons-material/HourglassBottom';
import DefaultModal from '@kopapro/components/commons/modals';
import Review from '@kopapro/components/productDetail/review';
import ImageScroller from '@kopapro/components/productDetail/imageScroller';
import MainImage from '@kopapro/components/productDetail/mainImage';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import { toast } from 'react-toastify';
import Alert from 'react-bootstrap/Alert';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { isBefore, isAfter } from 'date-fns';
import { NIL } from 'uuid';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import { GreetingPlateObject, AccessoryOptionObject, VariantOption } from '@kopapro-redux/types/other';
import SpinnerButton from '@kopapro/components/commons/spinnerButton';
import EventUtils from '@kopapro/utils/event';
import { BasicStyle } from '@kopapro-redux/types/componentSetting';

type State = {
  selectedImageCode: string;
  currentProduct: Product | undefined;
  qty: number | string;
  showTailorModal: boolean;
  showAdditionalInfoModal: boolean;
  isFavorite: boolean;
  errorMessage: string;
  staticText: I18nDictionary | undefined;
  leadTime: number;
  greetingMsg: string;

  greetingPlate: GreetingPlateObject | undefined;
  greetingPlateId: number;
  greetingPlateCharge: number;

  addCharge: number;
  accessoryOptions: Array<AccessoryOptionObject>;
  accessoryOptionIds: Array<number>;
  variants: Array<VariantOption>;
  selectedVariantMess: I18nDictionary | undefined;
  earlyMsg: string;
  addingInCartFlag: number; // <=0 : not adding, 1 = addcartonly, 2= add cart + go to cart
  showWarning: boolean;
};

class ProductDetail extends React.Component<ContainerProps, State> {
  showQtyInput = config.showQtyInput;
  maxOrderQty = config.maxOrderQty;

  state: State = {
    selectedImageCode: '',
    currentProduct: undefined,
    qty: 1,
    showTailorModal: false,
    showAdditionalInfoModal: false,
    isFavorite: false,
    errorMessage: '',
    staticText: undefined,
    leadTime: 0,
    greetingMsg: '',
    greetingPlate: undefined,
    greetingPlateId: 0,
    greetingPlateCharge: 0,
    addCharge: 0,
    accessoryOptions: [],
    accessoryOptionIds: [],
    variants: [],
    selectedVariantMess: undefined,
    earlyMsg: '',
    addingInCartFlag: 0,
    showWarning: false,
  };

  componentDidMount() {
    const { groupCode, productCode, seoUrl } = this.props;
    this.props.loadProduct({ gpCode: groupCode, proCode: productCode, seoUrl }, this.loadProductCallback);
  }

  componentDidUpdate(prevProps: ContainerProps, prevState: State) {
    const { groupCode, productCode, lite, seoUrl } = this.props;
    const self = this;

    // url route change
    if (prevProps.groupCode !== groupCode || prevProps.productCode !== productCode || prevProps.seoUrl !== seoUrl) {
      this.setState({ selectedImageCode: '', currentProduct: undefined, qty: 1, errorMessage: '' });
      this.props.loadProduct({ gpCode: groupCode, proCode: productCode, seoUrl }, this.loadProductCallback);
      if (!lite) {
        window.scrollTo(0, 0);
      }
    }

    // set currentProduct
    const { currentProduct } = this.state;
    const { products, displayProductCode } = this.props;

    const isDataLoaded = utils.isDefined(products) && displayProductCode && utils.isUndefined(currentProduct);
    const isDisplayProductCodeChanged = prevProps.displayProductCode !== displayProductCode;
    // wait for data ready or default display product changed
    if (isDataLoaded || isDisplayProductCodeChanged) {
      if (products) {
        const product = products!.find((p) => p.code === displayProductCode);
        this.setCurrentProduct(product);
      }
    }

    // currentProduct changed
    if (utils.isDefined(currentProduct)) {
      if (
        utils.isUndefined(prevState.currentProduct) ||
        prevState.currentProduct!.m18ProId !== currentProduct!.m18ProId
      ) {
        this.props.checkFavorite({ proId: currentProduct!.m18ProId }, function (status: boolean) {
          self.setState({ isFavorite: status });
        });
      }
    }

    const { isEnableFacebookComment } = this.props;
    if (window.FB && isEnableFacebookComment) {
      window.FB.XFBML.parse();
    }

    if (currentProduct && !lite) {
      utils.setDocumentTitle(geti18nValue(currentProduct.bDesc));
    }
  }

  loadProductCallback = (data: { products: { [k: number]: Product } }) => {
    if (!this.props.lite && (!data || Object.values(data.products).length === 0)) {
      this.props.navigate('/page-not-found');
      return;
    }
  };

  setSelectedImageCode = (imageCode: string) => {
    this.setState({
      selectedImageCode: imageCode,
    });
  };

  setCurrentProduct = (product: Product | undefined) => {
    if (product) {
      EventUtils.viewProduct(product);
    }
    this.setState({
      currentProduct: product,
      selectedImageCode: product?.defImage || '',
    });
  };

  renderFacebookComment = () => {
    const { isEnableFacebookComment } = this.props;
    if (!isEnableFacebookComment) {
      return null;
    }
    return <div className="fb-comments" data-numposts="3" data-width="100%"></div>;
  };

  renderReviews = () => {
    const { products, enableProComment } = this.props;
    if (!enableProComment || !this.state.currentProduct) {
      return null;
    }
    const { defImage, bDesc } = this.state.currentProduct!;
    return <Review products={products} productName={geti18nValue(bDesc)} imageCode={defImage} />;
  };

  updateQty = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: any = e.currentTarget.value;
    if (newValue.length > 0 && !utils.isIntegerString(newValue)) {
      return;
    }
    if (utils.isEmpty(newValue)) {
      this.setState({ qty: '' });
    } else {
      this.setState({
        qty: Math.min(Math.max(1, parseInt(newValue || 1)), this.maxOrderQty),
      });
    }
  };

  qtyButtonHandler = (change: number) => {
    let qty = 0;
    if (typeof this.state.qty === 'number') {
      qty = this.state.qty as number;
    }
    let value = Math.min(Math.max(1, qty + change), this.maxOrderQty);
    if (value !== qty) {
      this.setState({ qty: value });
    }
  };

  isOpeningToBuy = () => {
    const { openToBuyNow, openToBuyStartDate, openToBuyEndDate } = this.state.currentProduct!;
    const current = new Date();
    const isBetweenOpenDate = isAfter(current, openToBuyStartDate) && isBefore(current, openToBuyEndDate);

    if (!openToBuyNow && !isBetweenOpenDate) {
      return false;
    }
    return true;
  };

  allowToBuy = () => {
    const { acceptSoldout, soldOut, stkQty } = this.state.currentProduct!;
    const { storeHidden, isTailor } = this.props.productGroup!;
    if (isTailor) {
      return true;
    }
    if (!this.isOpeningToBuy()) {
      return false;
    }
    const stockQty = utils.getSafetyNumber(stkQty);
    if (!acceptSoldout && (soldOut || stockQty <= 0)) {
      return false;
    } else if (storeHidden) {
      return false;
    }

    return true;
  };

  openAdditionalInfoModal = () => {
    this.setState({ showAdditionalInfoModal: true });
  };

  handleModalClose = () => {
    this.setState({ showAdditionalInfoModal: false });
  };

  buyButtonHandler = (goToCheckout = false) => {
    this.setErrorState('');
    let qty = 1; // at least add 1
    if (typeof this.state.qty === 'number') {
      qty = this.state.qty as number;
    }
    const { t, qtyInCart, productGroup } = this.props;
    const { bDesc, stkQty, unlimitQty, hideStockLevel, acceptSoldout, maxQty } = this.state.currentProduct!;

    if (productGroup && productGroup.isTailor) {
      this.setState({ showTailorModal: true });
      return;
    }

    // check stock
    if (!unlimitQty && !hideStockLevel && !acceptSoldout) {
      if (Number(stkQty) < qty + qtyInCart) {
        const productName = geti18nValue(bDesc);
        let message: string = t('ce01_pmpcore.react.notEnoughStock', { product: productName });
        this.setErrorState(message);
        return;
      }
    }
    // check max qty per order
    const maxQtyPerOrder = utils.getSafetyNumber(maxQty);
    if (maxQtyPerOrder > 0 && maxQtyPerOrder < qty + qtyInCart) {
      let message: string = t('ce01_pmpcore.react.overMaxQty', { qty: maxQtyPerOrder });
      this.setErrorState(message);
      return;
    }

    //
    if (!this.isOpeningToBuy()) {
      const { openToBuyStartDate, openToBuyEndDate } = this.state.currentProduct!;
      const startDateText = t('{date, full}', { date: openToBuyStartDate });
      const endDateText = t('{date, full}', { date: openToBuyEndDate });
      this.setErrorState(t('ce01_pmpcore.react.msgOpenToBuyError', { sDate: startDateText, eDate: endDateText }));
      return;
    }

    this.handleBuyAction(goToCheckout);
  };

  handleBuyAction = (goToCheckout = false) => {
    const { navigate, t } = this.props;
    const { bDesc } = this.state.currentProduct!;
    const { currentProduct, qty: stateValue } = this.state;
    const self = this;
    let qty = 1;
    if (typeof stateValue === 'number') {
      qty = stateValue as number;
    }
    if (currentProduct) {
      this.setState({ addingInCartFlag: goToCheckout ? 2 : 1 });
      EventUtils.addToCart(currentProduct, qty);
      this.props.addToCart({ qty, proId: currentProduct!.m18ProId }, () => {
        self.setState({ addingInCartFlag: 0 });
        if (goToCheckout) {
          navigate('/cart');
        } else {
          const event = new Event('addToCart');
          const productName = geti18nValue(bDesc);
          document.dispatchEvent(event);
          toast.dismiss();
          toast.success(
            <div className="text-break">{t('ce01_pmpcore.react.addToCart', { product: productName })}</div>
          );
        }
      });
    }
  };

  renderNav = () => {
    const { t } = this.props;
    const { currentProduct } = this.state;
    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.proCategory'), to: '/categories', active: false },
    ];

    if (currentProduct) {
      items.push({ text: geti18nValue(currentProduct.bDesc), to: '', active: true });
    }
    return <KppBreadcrumb items={items} />;
  };

  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  onOptionsClick = (value: ProductAttributeValue) => {
    // current
    const { t, products } = this.props;
    const { attributes } = this.state.currentProduct!;
    const { proAttrTypeM18Id, m18Id } = value;

    const newAttributes = { ...attributes, [proAttrTypeM18Id]: m18Id };

    const selectedProduct = products?.find((product) => {
      const attributesJSON = JSON.stringify(newAttributes);
      const productAttributesJSON = JSON.stringify(product.attributes);
      return attributesJSON === productAttributesJSON;
    });

    if (selectedProduct) {
      this.setCurrentProduct(selectedProduct);
      this.setErrorState('');
    } else {
      toast.dismiss();
      toast.error(t('ce01_pmpcore.react.combinationExpired'));
    }
  };

  setErrorState = (message: string) => {
    this.setState({
      errorMessage: message,
    });
  };

  renderAttributeValues = (values: ProductAttributeValue[], activeValue: number) => {
    // onclick switch the showing product
    return values.map((value) => {
      const isActive = value.m18Id === activeValue;
      return (
        <Button
          onClick={this.onOptionsClick.bind(null, value)}
          size="sm"
          key={value.code}
          className="mb-2 me-2"
          disabled={isActive}
          variant={isActive ? 'main' : 'outline-secondary'}>
          {geti18nValue(value.desc)}
        </Button>
      );
    });
  };

  renderAttribute = () => {
    const { attributeTypes } = this.props;
    const { attributes } = this.state.currentProduct!;

    if (!attributeTypes || attributeTypes.length === 0) {
      return null;
    }
    return (
      <div className="mb-3 attibute-group">
        {attributeTypes.map((attr) => {
          const activeValue = attributes[attr.m18Id];
          return (
            <Col key={attr.code} className="mb-2">
              <h6 className="mb-1 text-muted">{geti18nValue(attr.desc)}</h6>
              <div className="row">
                <div>{this.renderAttributeValues(attr.values, activeValue)}</div>
              </div>
            </Col>
          );
        })}
      </div>
    );
  };

  renderHashTags = () => {
    const { currentProduct } = this.state;
    const { hashTags } = currentProduct!;
    if (utils.isEmptyList(hashTags)) {
      return null;
    }

    return (
      <div className="mt-2">
        {hashTags.map((tag) => {
          return `#${tag} `;
        })}
      </div>
    );
  };

  renderAdditionalInfo = () => {
    const { lite } = this.props;
    const { proAddInfo } = this.state.currentProduct!;
    const title = geti18nValue(proAddInfo.titleObj);
    if (lite || utils.isEmpty(title)) {
      return null;
    }
    return (
      <div className="text-left">
        <span className="kpp-link" onClick={this.openAdditionalInfoModal}>
          {title} <InfoOutlinedIcon />
        </span>
      </div>
    );
  };

  showOpeningInfo = () => {
    const { t } = this.props;
    const { openToBuyStartDate, openToBuyEndDate } = this.state.currentProduct!;
    const endYear = new Date(openToBuyEndDate).getFullYear();
    if (this.isOpeningToBuy()) {
      return null;
    }
    const startDateText = t('{date, full}', { date: openToBuyStartDate });
    const endDateText = t('{date, full}', { date: openToBuyEndDate });
    let message = t('ce01_pmpcore.kopapro.react.msgOpenToBuyStart', { sDate: startDateText });
    if (endYear !== 9999) {
      message = t('ce01_pmpcore.kopapro.react.msgOpenToBuyRange', { sDate: startDateText, eDate: endDateText });
    }
    return (
      <h4>
        <HourglassBottom /> {message}
      </h4>
    );
  };

  renderUnpublishInfo = () => {
    const { t } = this.props;
    const { storeHidden } = this.props.productGroup!;
    const { showUnpblishDate, unpublishDate, unpublishDateDesc } = this.state.currentProduct!;
    if (storeHidden) {
      return (
        <h4>
          <InfoIcon /> {t('ce01_pmpcore.react.unpublished')}
        </h4>
      );
    }
    const desc = geti18nValue(unpublishDateDesc);

    if (!showUnpblishDate) {
      return null;
    }

    const dateStr = t('{ date, full }', { date: unpublishDate });
    const dateMsg = t('ce01_pmpcore.react.unpublishDateInfo', { date: dateStr });

    return (
      <div className="p-0 mb-2">
        <p className="m-0 fs-5">{desc}</p>
        <hr className="my-1" />
        <p className="m-0 small">
          <HourglassBottom />
          {dateMsg}
        </p>
      </div>
    );
  };

  renderMoq = () => {
    const { t } = this.props;
    const { moq } = this.state.currentProduct!;

    if (utils.getSafetyNumber(moq) === 0) {
      return null;
    }
    return <p className="fs-6"> {t('ce01_pmpcore.react.minQrderQty', { qty: utils.getSafetyNumber(moq) })}</p>;
  };

  renderStockQuantity = () => {
    const { t } = this.props;
    const { stkQty, unlimitQty, hideStockLevel } = this.state.currentProduct!;

    if (unlimitQty || hideStockLevel) {
      return null;
    }

    let displayQty: number = utils.getSafetyNumber(stkQty);
    if (displayQty < 0) {
      displayQty = 0;
    }

    return <p className="fs-6">{t('ce01_pmpcore.react.remainQty', { qty: displayQty })}</p>;
  };

  renderPrice = () => {
    const { t } = this.props;
    const item = this.state.currentProduct!;
    const { memTypeShortName, hideMemTypeExpiryDate, memTypeExpiryDate } = item;
    const { regularPrice, salesPrice, isShowMemberUpDesc } = getProductDetailPricing(item);

    let memberPriceDesc = geti18nValue(memTypeShortName);
    let offPrice: any = <>{formatUnitPrice(salesPrice, Currency.SELECTED)}</>;
    if (regularPrice > salesPrice) {
      const memTypeExpiryDateMsg =
        t('ce01_pmpcore.react.until') + ' ' + t('{ date, full }', { date: memTypeExpiryDate });

      offPrice = (
        <>
          <div className="me-1">
            <del>{formatUnitPrice(regularPrice, Currency.SELECTED)}</del>
          </div>
          <div className="text-danger">
            {isShowMemberUpDesc ? memberPriceDesc + ': ' : ''}
            {formatUnitPrice(salesPrice, Currency.SELECTED)}
          </div>
          {isShowMemberUpDesc && !hideMemTypeExpiryDate && (
            <div className="text-danger fs-6 small">{memTypeExpiryDateMsg}</div>
          )}
        </>
      );
    }

    return offPrice;
  };

  renderSoldOut = () => {
    const { t, productGroup } = this.props;
    const { soldOut, acceptSoldout, soldOutReminder } = this.state.currentProduct!;
    const soldOutMessage = geti18nValue(soldOutReminder);
    let soldOutAlert = null;

    if (productGroup?.isTailor) {
      return null;
    }
    if (acceptSoldout && !utils.isEmpty(soldOutMessage.trim())) {
      soldOutAlert = (
        <Alert variant="warning">
          <ErrorOutlineIcon /> {soldOutMessage}
        </Alert>
      );
    }

    if (soldOut) {
      return (
        <div>
          <h4>
            <InfoIcon /> {t('ce01_pmpcore.react.soldOut')}
          </h4>
          {soldOutAlert}
        </div>
      );
    }
    return null;
  };

  updateFavorite = () => {
    const { t } = this.props;
    const { isFavorite, currentProduct } = this.state;
    if (currentProduct) {
      toast.dismiss();
      if (!isFavorite) {
        EventUtils.addToWishlist(currentProduct);
        this.props.addFavorite({ proId: currentProduct.m18ProId, noticeStock: true });
        this.setState({ isFavorite: true });
        toast.info(t('ce01_pmpcore.kopapro.react.addedToWishlist'));
      } else {
        this.props.removeFavorite({ proId: currentProduct.m18ProId });
        this.setState({ isFavorite: false });
        toast.info(t('ce01_pmpcore.kopapro.react.removedFromWishlist'));
      }
    }
  };

  renderWishListInfo = () => {
    const { lite } = this.props;
    const { isFavorite } = this.state;
    const { t, userInfo } = this.props;

    if (lite || userInfo.memId <= 0) {
      return null;
    }

    return (
      <div className="mt-1">
        {!isFavorite && (
          <Button variant="link" className="ps-0" onClick={(e) => this.updateFavorite()}>
            <FavoriteBorderIcon className="pe-1 wishlist" />
            {t('ce01_pmpcore.react.addToWishlist')}
          </Button>
        )}
        {isFavorite && (
          <Button variant="link" className="ps-0" onClick={(e) => this.updateFavorite()}>
            <FavoriteIcon className="pe-1 wishlist" />
            {t('ce01_pmpcore.react.removeFromWishlist')}
          </Button>
        )}
      </div>
    );
  };

  renderQtyInput = () => {
    const { liteOptions, productGroup } = this.props;
    const { qty } = this.state;
    const { showChectOut, showQtySelector } = liteOptions || {};
    const { isTailor } = productGroup!;
    const isAllowToBuy = this.allowToBuy();

    if (!isTailor && isAllowToBuy && this.showQtyInput && showChectOut !== false && showQtySelector !== false) {
      return (
        <div className="d-flex mb-3">
          <Form.Group className="text-center">
            <InputGroup className="flex-nowrap quantity-input-group">
              <Button variant="link" className="less-btn" onClick={(e) => this.qtyButtonHandler(-1)}>
                <RemoveIcon />
              </Button>
              <Form.Control
                value={qty}
                type="number"
                onChange={this.updateQty}
                onBlur={(e) => this.qtyButtonHandler(0)}
                max={this.maxOrderQty}
              />
              <Button variant="link" className="add-btn" onClick={(e) => this.qtyButtonHandler(1)}>
                <AddIcon />
              </Button>
            </InputGroup>
          </Form.Group>
        </div>
      );
    }
  };

  renderContent = (currentProduct: Product) => {
    const { t, liteOptions, lite, productGroup } = this.props;
    const { showChectOut, showShareBtn, showVariant, showBuyNow } = liteOptions || {};

    if (utils.isUndefined(productGroup)) {
      return null;
    }
    const { isTailor } = productGroup!;
    // undefind !== false is true
    const { bDesc } = currentProduct;
    const isAllowToBuy = this.allowToBuy();
    const productName = geti18nValue(bDesc);

    return (
      <div className="d-flex flex-column h-100">
        <h2 className="mb-3 text-break">{productName}</h2>
        <div className="mb-4 fs-4">{this.renderPrice()}</div>
        {this.renderAdditionalInfo()}
        {this.showOpeningInfo()}
        {this.renderUnpublishInfo()}
        {!isTailor && this.renderStockQuantity()}
        {!isTailor && showVariant !== false && this.renderAttribute()}
        {!isTailor && this.renderMoq()}
        {this.renderSoldOut()}
        {this.renderQtyInput()}
        {showChectOut !== false && isAllowToBuy && (
          <div className="row g-3">
            <Col>{this.renderBuyButton(t('ce01_pmpcore.kopapro.react.addToCart'))}</Col>
            <Col>
              {!isTailor && showBuyNow !== false && this.renderBuyButton(t('ce01_pmpcore.kopapro.react.buyNow'), true)}
            </Col>
          </div>
        )}
        <ErrorMessage message={this.state.errorMessage} />
        {this.renderWishListInfo()}
        {showShareBtn !== false && (
          <div className="mt-1">
            <ShareButtons url={window.location.href} title={productName} />
          </div>
        )}
        {!lite && (
          <>
            <h4 className="mt-3 pb-2 border-bottom">{t('ce01_pmpcore.react.detailDesc')}</h4>
            <div className="lead flex-shrink-0 product-description">
              <SafetyDOM html={geti18nValue(currentProduct.dDesc)} />
            </div>
          </>
        )}
        {this.renderTailorModal()}
      </div>
    );
  };

  handleTailorModalConfirm = (currentProduct: Product | undefined, qty: number) => {
    if (currentProduct && qty !== 0) {
      const self = this;
      this.setState({ currentProduct, qty, showTailorModal: false }, function () {
        self.handleBuyAction(false);
      });
    }
  };

  handleTailorModalClose = () => {
    this.setState({ showTailorModal: false });
  };

  renderTailorModal = () => {
    const { showTailorModal, currentProduct } = this.state;
    const { products, groupCode, productGroup } = this.props;
    const tailorUp = productGroup?.tailorUp || 0;
    return (
      <TailorModal
        show={showTailorModal}
        products={products}
        groupCode={groupCode}
        tailorUp={tailorUp}
        defaultCode={currentProduct?.code || ''}
        onConfirmHandler={this.handleTailorModalConfirm}
        onCloseHandler={this.handleTailorModalClose}
      />
    );
  };

  renderBuyButton = (label: string, isGoToCheckout = false) => {
    const { buyButtonStyle } = this.props;
    let buttonProps: any = {
      variant: isGoToCheckout ? 'main' : 'outline-main',
    };

    if (utils.isDefined(buyButtonStyle)) {
      const { height, ...rest } = buyButtonStyle as BasicStyle;
      let customStyle = getCustomStyle({ ...rest, useUdfStyle: true }, true);
      buttonProps = {
        style: { ...customStyle, borderColor: buyButtonStyle?.fontColor },
      };
    }

    const { addingInCartFlag } = this.state;

    let isSpinning = false;
    if (isGoToCheckout && addingInCartFlag === 2) {
      isSpinning = true;
    }
    if (!isGoToCheckout && addingInCartFlag === 1) {
      isSpinning = true;
    }

    return (
      <SpinnerButton
        {...buttonProps}
        showLabel={true}
        spinning={isSpinning}
        disabled={addingInCartFlag > 0}
        onClick={this.buyButtonHandler.bind(null, isGoToCheckout)}
        className="py-2 w-100">
        {label}
      </SpinnerButton>
    );
  };

  renderSubProductList = (subtitle: string, products?: ProductGroup[], productIds?: number[]) => {
    const { t } = this.props;

    if (utils.isEmptyList(products) && utils.isEmptyList(productIds)) {
      return null;
    }

    let items;
    if (productIds) {
      items = productIds.map((id: number) => {
        return <ProductItem key={'' + id} proId={id} />;
      });
    } else if (products) {
      items = products.map((item: ProductGroup) => {
        return <ProductItem key={item.code} code={item.code} />;
      });
    }

    return (
      <div className="row product-list">
        <div className="col-md-12 mb-4">
          <h4 className="text-muted my-4">{t(subtitle)}</h4>
          <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3">{items}</div>
        </div>
      </div>
    );
  };

  renderLite = () => {
    const { liteOptions, products } = this.props;
    const { imageHeight } = liteOptions!;
    const { currentProduct, selectedImageCode } = this.state;

    if (!products || !currentProduct) {
      return null;
    }

    return (
      <div className="row mb-4">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-12 mb-4 text-center img-container">
              <div className="d-inline-block position-relative">
                <MainImage
                  image={selectedImageCode}
                  lite={true}
                  proIconsMore={currentProduct!.proIconsMore}
                  imageHeight={imageHeight}
                  productType={currentProduct?.coupon ? 'coupon' : ''}
                />
              </div>
            </div>
            {this.renderHashTags()}
          </div>
        </div>

        <div className="col-lg-5">{this.renderContent(currentProduct!)}</div>
      </div>
    );
  };

  render() {
    const { relatedItems, products, lite } = this.props;
    const { currentProduct, selectedImageCode } = this.state;

    if (lite) {
      return this.renderLite();
    }

    let components = null;
    if (products && currentProduct) {
      const images = currentProduct?.images;
      const imageScrollerProps = {
        images,
        vertical: true,
        onSelect: this.setSelectedImageCode,
        selectedImage: selectedImageCode,
      };
      components = (
        <>
          <div className="row mb-4">
            <ImageScroller {...imageScrollerProps} />
            <div className="col-lg-6">
              <div className="row">
                <div className="col-12 mb-4 ">
                  <MainImage
                    image={selectedImageCode}
                    proIconsMore={currentProduct!.proIconsMore}
                    productType={currentProduct?.coupon ? 'coupon' : ''}
                  />
                  {this.renderHashTags()}
                </div>
              </div>
              <ImageScroller {...imageScrollerProps} vertical={false} />
            </div>

            <div className="col-lg-5">{this.renderContent(currentProduct)}</div>
          </div>
          {/* facebook comment */}
          {this.renderFacebookComment()}
          {this.renderReviews()}
          {this.renderSubProductList('ce01_pmpcore.react.packageProduct', undefined, currentProduct.packagePro)}
          {this.renderSubProductList('ce01_pmpcore.react.suggestedProduct', relatedItems)}

          <DefaultModal
            centered={true}
            show={this.state.showAdditionalInfoModal}
            body={geti18nValue(currentProduct.proAddInfo.detail)}
            onCloseHandler={this.handleModalClose}
          />
        </>
      );
    }
    return (
      <div className="page-container container px-xl-5 product-detail">
        <ScrollToTopOnMount />
        {this.renderTop()}
        {components}
      </div>
    );
  }
}

export default ProductDetail;
