import { ReactNode } from 'react';
import {
  formatDate,
  formatToCurrencyByM18Id,
  geti18nValue,
  getOrderStatusMesscode,
  getPayStatusMesscode,
  getShipStatusMesscode,
} from '@kopapro/utils/m18';
import { Card, Col, Row, ListGroup, Form } from 'react-bootstrap';
import { OrdersProps } from '@kopapro/components/pages/account/orders';
import { MemberQueryItem, OrdersContent } from '@kopapro-redux/types/member';
import Button from 'react-bootstrap/Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SearchIcon from '@mui/icons-material/Search';
import { CriteriaFieldType } from '@kopapro-redux/utils/constant';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import { InputField } from '@kopapro-redux/types/componentSetting';
import { memberCenterConfig } from '@kopapro/utils/config';
import Query, { QueryState } from '@kopapro/components/pages/account/query';
import utils from '@kopapro-redux/utils/utils';

interface OrdersState extends QueryState {}

export default class Orders extends Query<OrdersProps, OrdersState> {
  override usePagination = memberCenterConfig.orders.usePagination;

  override fetching = false;

  override defaultState = {
    formData: {},
    imageMap: {},
    errorFields: {},
    sending: false,
    errorMessage: '',
    successMessage: '',
    query: { queryId: '', querySize: 0 },
    startPoint: 1,
    dataCount: 10,
    data: [],
    page: 0,
  };

  constructor(props: OrdersProps) {
    super(props);

    if (props.m18Fields.length > 0) {
      let newFormData = {};
      for (let field of props.m18Fields) {
        const { type, id, cond } = field;
        if (type === CriteriaFieldType.DATE) {
          let value;
          if (cond === 'lessOrEqual') {
            value = memberCenterConfig.orders.defaultStartDate;
          }
          if (cond === 'largerOrEqual') {
            value = memberCenterConfig.orders.defaultEndDate;
          }
          newFormData = {
            ...newFormData,
            [id]: {
              inputId: id,
              value,
            },
          };
        }
      }
      this.state = { ...this.defaultState, formData: newFormData };
    } else {
      this.state = this.defaultState;
    }
  }

  getDateFields = () => {
    return this.props.m18Fields.filter((field) => field.type === CriteriaFieldType.DATE);
  };

  override toM18Values = () => {
    const fields = this.getDateFields();
    let copy = { ...this.state.formData };

    fields.forEach((field) => {
      const { id } = field;
      const fieldValue = this.getFormFieldValue(id) || '';
      copy[id] = { inputId: id, value: formatDate(fieldValue) };
    });
    return copy;
  };

  override validate = (): boolean => {
    let errorMessage = '';
    this.getDateFields().forEach((field) => {
      const { id } = field;
      const fieldValue = this.getFormFieldValue(id) || '';
      if (fieldValue === '') {
        errorMessage = 'ce01_pmpcore.kopapro.react.askInputDate';
      } else if (formatDate(fieldValue) === 'Invalid Date') {
        errorMessage = 'ce01_pmpcore.kopapro.react.fieldInvalidDate';
      }
    });
    this.setState({ errorMessage });
    return utils.isEmpty(errorMessage);
  };

  override renderM18Field = (item: InputField): ReactNode => {
    const { id, name, required } = item;
    const label = geti18nValue(name);
    const input = this.renderM18Input(item);
    const errorMessage = this.renderM18InputError(item);
    return (
      <Form.Group key={`m18-field-${id}`} className="mb-3" as={Col} controlId={id} md={6}>
        <Form.Label required={required}>{label}</Form.Label>
        {input}
        {errorMessage}
      </Form.Group>
    );
  };

  renderOrderItem = (item: MemberQueryItem) => {
    const { t } = this.props;
    const detail = item.detail;
    const info: OrdersContent = detail[0].content;
    const orderId = info.orderId;
    const orderCode = info.orderCode;
    const orderDate = t('{ date, full }', { date: info.orderDate });
    const orderStatus = info.orderStatus;
    const payStatus = info.payStatus;
    const shipStatus = info.shipStatus;
    const curId = info.curId;
    const canDelivery = info.canDelivery;
    const amount = formatToCurrencyByM18Id(info.totalAmount, curId);
    return (
      <ListGroup.Item ref={this.listRef} key={`${orderCode}`} className="order-item">
        <div className="d-flex flex-column flex-sm-row">
          <div className="flex-grow-1">
            <h5 className="order-code">
              {`#${orderCode}`}{' '}
              <span className={`order-status ${orderStatus}`}>{`${t(getOrderStatusMesscode(orderStatus))}`}</span>
            </h5>
            <div className="d-flex flex-column">
              <span>
                <CreditCardIcon />
                {`${t('ce01_pmpcore.react.payStatus')} : ${t(getPayStatusMesscode(payStatus))}`}
              </span>
              {canDelivery && (
                <span>
                  <LocalShippingIcon />
                  {`${t('ce01_pmpcore.react.shipStatus')} : ${t(getShipStatusMesscode(shipStatus))}`}
                </span>
              )}
              <span className="mt-2"> {orderDate}</span>
            </div>
          </div>
          <div className="d-flex align-items-center mt-2 mt-sm-0  flex-sm-column align-items-sm-end justify-content-between">
            <span className="fs-5"> {amount}</span>
            <Button
              variant="outline-main"
              onClick={() => {
                this.props.navigate(`/order/${orderId}`);
              }}>
              {t('ce01_pmpcore.react.viewOrder')}
            </Button>
          </div>
        </div>
      </ListGroup.Item>
    );
  };

  render(): ReactNode {
    const { t, viewName, m18Fields } = this.props;
    const { sending, data, errorMessage } = this.state;
    return (
      <Card className="border-0 account-orders">
        <Card.Body>
          <Card.Title>{geti18nValue(viewName)}</Card.Title>
          <hr />

          <Row>
            {m18Fields.map((item) => {
              return this.renderM18Field(item);
            })}
          </Row>

          <ErrorMessage message={t(errorMessage)} />

          <div className="text-end">
            <Button className="mb-3" variant="main" disabled={sending} onClick={() => this.handleQuery()}>
              <SearchIcon />
              {t('ce01_pmpcore.react.search')}
            </Button>
          </div>

          <ListGroup>
            {data.map((item) => {
              return this.renderOrderItem(item);
            })}
          </ListGroup>

          {/* pagination */}
          {this.renderPagination()}
        </Card.Body>
      </Card>
    );
  }
}
