// if selector cannot get the latest state
// https://redux.js.org/usage/structuring-reducers/immutable-update-patterns

import { ProductType } from "@kopapro-redux/action-types";
import {
  GetProductFailurePayload,
  GetProductSuccessPayload,
  ProductAttributeType,
  ProductFilter,
  ProductGroup,
  ProductsActions,
  ProductsState,
} from '@kopapro-redux/types/products';
import { QueryList } from '@kopapro-redux/types/general';

const initialState: ProductsState = {
  pending: false,
  products: {},
  productGroups: {},
  error: '',
};

function mapAttribute(attrType: any, attrValue: any, products: any): ProductAttributeType[] {
  const result: ProductAttributeType[] = [];

  const attrTypes = Object.values(attrType);
  const attrValues = Object.values(attrValue);

  attrTypes.forEach((t: any) => {
    const values = attrValues.filter((value: any) => t.m18Id === value.proAttrTypeM18Id);

    result.push({
      ...t,
      values,
    });
  });

  return result;
}

export function handleProductReceived(
  nextState: ProductsState,
  data: any,
  relatedProducts: ProductGroup[],
  packageProducts: ProductGroup[]
) {
  const { products, proGroup, isTailor, tailorUp, attrValue, attrType } = data;
  const productList = Object.values(products || []);
  let productGroupCode = proGroup.code as string;
  let newState = { ...nextState };
  let attrTypes = mapAttribute(attrType, attrValue, products);
  if (!newState.attributes) {
    newState.attributes = {};
  }
  newState.attributes = {
    ...newState.attributes,
    [productGroupCode]: { code: productGroupCode, attrTypes },
  };

  productList.forEach((product: any) => {
    let temp: any = Object.assign({ gpCode: proGroup.code }, product);
    const id = '' + product.code;
    let productsState = { ...newState.products, [id]: temp };
    newState = { ...newState, products: productsState };
  });

  let productGroupsState = { ...newState.productGroups, [productGroupCode]: { ...proGroup, isTailor, tailorUp } };
  newState = { ...newState, productGroups: productGroupsState };

  [...relatedProducts, ...packageProducts].forEach((group) => {
    const code = group.code;
    const newData = { ...newState.productGroups, [code]: group };
    newState = { ...newState, productGroups: newData };
  });

  // newState = { ...newState };

  return newState;
}

export function productsInCategory(nextState: ProductsState, action: ProductsActions) {
  const result = action.payload as QueryList<ProductGroup>;
  // const id = action!.m18ProductCategoryId || -1; // change to search_id?
  result.data.forEach((group) => {
    const code = group.code;
    // temp.id = id;
    nextState.productGroups[code] = group;
  });
  return nextState;
}

const productsReducer = (state = initialState, action: ProductsActions): ProductsState => {
  let payload = action.payload;
  if (!payload) {
    return state;
  }

  switch (action.type) {
    case ProductType.GET_PRODUCT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ProductType.RECEIVED_PRODUCT:
      payload = payload as GetProductSuccessPayload;
      return handleProductReceived(
        { ...state, pending: false },
        payload.data,
        payload!.relatedItems,
        payload!.packageProducts
      );
    case ProductType.RECEIVED_PRODUCTS_BY_CATEGORY:
      return productsInCategory({ ...state, pending: false }, action);
    // example
    case ProductType.APPLY_FILTER:
      payload = payload as ProductFilter;
      return { ...state, appliedFilter: payload };
    case ProductType.CLEAR_FILTER:
      return { ...state, appliedFilter: undefined };
    case ProductType.GET_PRODUCT_FAILURE:
      payload = payload as GetProductFailurePayload;
      return {
        ...state,
        pending: false,
        products: {},
        error: payload.error,
      };
    default:
      return state;
  }
};

export default productsReducer;