import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { InputFormatList } from '@kopapro-redux/types/componentSetting';
import { getShopRegisterSetup } from '@kopapro-redux/selectors/entities/shop';
import Register from '@kopapro/components/pages/register/register';
import {
  getRegisterInputFormatList,
  getRegisterSetupCaptchaType,
  getRegisterSetupLogo,
  getRegisterSetupTermAndCond,
  isRegisterSetupUseCaptcha,
} from '@kopapro-redux/selectors/entities/register';
import { loadRegisterForm, register, uploadTempImage } from '@kopapro-redux/actions/user';
import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import { M18ComboList, M18LookupList } from '@kopapro-redux/types/m18Option';
import { getM18Combo, getM18Lookup } from '@kopapro-redux/selectors/entities/m18Option';
import withConfigurable from '@kopapro/components/preview/configurable';
import { M18RnMeta } from '@kopapro-redux/utils/constant';

interface IProps extends WithTranslation {
  navigate: any; // withNavigation
}

interface DispatchProps {
  register: typeof register;
  loadData: typeof loadRegisterForm;
  uploadTempImage: typeof uploadTempImage;
}

interface StateProps {
  userLoggedIn: boolean;
  photoCode: string;
  useCaptcha: boolean;
  captchaType: string;
  termAndCond: I18nDictionary;
  captchaPublicKey: string;

  m18Fields: InputFormatList;
  combo?: { [k: string]: M18ComboList };
  lookup?: { [k: string]: M18LookupList };
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const useCaptcha: boolean = isRegisterSetupUseCaptcha(state);
  const captchaType: string = getRegisterSetupCaptchaType(state);
  const termAndCond: I18nDictionary = getRegisterSetupTermAndCond(state);
  const photoCode = getRegisterSetupLogo(state);
  const register = getShopRegisterSetup(state);

  return {
    userLoggedIn: userLoggedIn(state),
    ...register,
    photoCode,
    useCaptcha,
    captchaType,
    termAndCond,

    m18Fields: getRegisterInputFormatList(state),
    combo: getM18Combo(state),
    lookup: getM18Lookup(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  register,
  loadData: loadRegisterForm,
  uploadTempImage: uploadTempImage,
};

const configurableProps = {
  m18CompId: M18RnMeta.REGVIEW,
};

export type RegisterProps = IProps & StateProps & DispatchProps;
const component = withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Register)));
export default withConfigurable(component, configurableProps);