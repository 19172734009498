import { M18ComboList, M18LookupList } from '@kopapro-redux/types/m18Option';
import {
  formatTime,
  formatDate,
  geti18nValue,
  getM18ComboOptions,
  getM18LookupOptions,
  resolveTempImageURL,
} from '@kopapro/utils/m18';
import { CriteriaFieldType, UdfInputFieldType } from '@kopapro-redux/utils/constant';
import { Component, ReactNode } from 'react';
import { InputField } from '@kopapro-redux/types/componentSetting';
import { InputFormatList } from '@kopapro-redux/types/componentSetting';
import { WithTranslation } from 'react-i18next';
import utils from '@kopapro-redux/utils/utils';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormCheck from 'react-bootstrap/FormCheck';
import InputGroup from 'react-bootstrap/InputGroup';
import ShopImage from '@kopapro/components/commons/shopImage';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { uploadTempImage } from '@kopapro-redux/actions/user';
import CountrySelect from '@kopapro/components/commons/inputs/countrySelect';
import UploadButton from '@kopapro/components/commons/inputs/uploadButton';

export interface InputFormProps extends WithTranslation {
  m18Fields: InputFormatList;
  combo?: { [k: string]: M18ComboList };
  lookup?: { [k: string]: M18LookupList };
  uploadTempImage?: typeof uploadTempImage;
}

export type FormFieldValue = {
  inputId: string;
  value: any;
  error?: string;
};

export interface InputFormState {
  sending: boolean;
  formData: {
    [inputId: string]: FormFieldValue;
  };
  imageMap: { [key: string]: string };
  errorFields: {
    [key: string]: string;
  };
}

export default class InputForm<T extends InputFormProps, S extends InputFormState> extends Component<T, S> {
  imageSize = 50;

  // handle form value change
  handleMobileCountry = (id: string, value: string) => {
    let newValue = value;

    const currentValue = this.getFormFieldValue(id).toString();
    if (utils.isNotEmpty(currentValue) && currentValue.indexOf('@@') >= 0) {
      const mobile = currentValue.split('@@')[1];
      newValue = `${newValue}@@${mobile}`;
    } else {
      newValue = `${newValue}@@`;
    }

    this.updateFormData(id, newValue);
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const id = e.currentTarget.name;
    let newValue: any = e.currentTarget.value;

    if (type === UdfInputFieldType.CHECKBOX) {
      newValue = e.currentTarget.checked;
    } else if (type === UdfInputFieldType.NUMBER) {
      const v = newValue;
      const input = e.currentTarget;
      if (input.hasAttribute('min')) {
        const min = parseFloat(input.min);
        if (v < min) {
          newValue = min;
        }
      }
      if (input.hasAttribute('max')) {
        const max = parseFloat(input.max);
        if (v > max) {
          newValue = max;
        }
      }
    } else if (type === UdfInputFieldType.MOBILE) {
      // Type = mobile, value should be mobileCountry@@mobile
      const currentValue = this.getFormFieldValue(id).toString();
      if (utils.isNotEmpty(currentValue) && currentValue.indexOf('@@') >= 0) {
        const mobileCountry = currentValue.split('@@')[0];
        newValue = `${mobileCountry}@@${newValue}`;
      }
    }

    this.updateFormData(id, newValue);
  };

  handleUpload = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const self = this;
    e.preventDefault();
    e.stopPropagation();

    const files = e.target.files;
    if (files && files[0]) {
      const file = files[0];
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        e.target.value = '';
        return;
      }
      const { uploadTempImage } = this.props;
      if (uploadTempImage) {
        // dispatch request
        uploadTempImage({ file }, function (isSuccess: boolean, fileName: string) {
          // update state errorMessage and successMessage
          if (isSuccess) {
            self.updateFormData(id, fileName);
            self.setState({ imageMap: { ...self.state.imageMap, [id]: fileName } });
          } else {
            e.target.value = '';
          }
        });
      }
    }
  };

  checkRequiredFields = (): string[] => {
    const { m18Fields } = this.props;
    let result: string[] = [];
    const emptyValuesFields = m18Fields.filter((field) => {
      const { id, required, type } = field;
      if (required) {
        if (
          type === UdfInputFieldType.COLOR ||
          type === UdfInputFieldType.COMBO ||
          type === UdfInputFieldType.LOOKUP ||
          type === UdfInputFieldType.CHECKBOX
        ) {
          return false;
        }

        let strValue = this.getFormFieldValue(id);
        if (utils.isEmpty(strValue)) {
          return true;
        }
        if (type === UdfInputFieldType.MOBILE) {
          if (strValue.indexOf('@@') > 0) {
            let mobile = strValue.split('@@')[1];
            if (utils.isEmpty(mobile)) {
              return true;
            }
          }
        }
      }
      return false;
    });
    result = emptyValuesFields.map((field) => field.id);
    return result;
  };

  checkFieldsValidation = (): FormFieldValue[] => {
    const { t, m18Fields } = this.props;
    const stateValues = this.state.formData;

    let result: FormFieldValue[] = [];
    for (let field of m18Fields) {
      const { id, type, required, maxLength, minNumber, maxNumber } = field;
      let min = minNumber || -99;
      let max = maxNumber || 99;
      if (required) {
        let values = stateValues[id];
        if (values) {
          let isValid: boolean = true;
          let errorMessage: string = '';

          let val = values.value;
          switch (type) {
            case UdfInputFieldType.NUMBER:
              let numValue: number = Number(val);
              if (numValue < min) {
                isValid = false;
                errorMessage = t('ce01_pmpcore.kopapro.react.fieldOverMin', { min: min });
              }
              if (numValue > max) {
                isValid = false;
                errorMessage = t('ce01_pmpcore.kopapro.react.fieldOverMax', { max: max });
              }
              break;

            case UdfInputFieldType.STRING:
              let strValue: string = val.toString();
              if (strValue.length > maxLength) {
                isValid = false;
                errorMessage = 'ce01_pmpcore.kopapro.react.fieldOverMaxLength';
              }
              break;

            case UdfInputFieldType.DATE:
              let maxDate: Date = new Date('9999-12-31');
              let minDate: Date = new Date('1900-01-01');
              let strDate: string = val.toString();
              let dateValue: Date = new Date(strDate);
              if (dateValue.getTime() < minDate.getTime() || dateValue.getTime() > maxDate.getTime()) {
                isValid = false;
                errorMessage = 'ce01_pmpcore.kopapro.react.fieldInvalidDate';
              }
              break;
          }

          if (!isValid) {
            result.push({
              inputId: id,
              value: '',
              error: errorMessage,
            });
          }
        }
      }
    }
    return result;
  };

  updateFormData = (id: string, newValue: any) => {
    let inputValue: FormFieldValue = this.getInputValue(id);
    inputValue = {
      ...inputValue,
      value: newValue,
    };
    let formDataState = { ...this.state.formData, [id]: inputValue };
    const formData: { [key: string]: FormFieldValue } = { ...formDataState };
    this.setState({ formData });
  };

  // Getter
  getImageValue = (value: any): string => {
    if (value.indexOf('file') <= -1) {
      return value.toString();
    }
    return resolveTempImageURL(value.toString());
  };

  getTimeValue = (value: any): Date => {
    if (utils.isTimeText(value)) {
      return new Date(`2000-01-01T${value}`);
    }
    return value;
  };

  getMobileValue = (value: any, isMobileCountry: boolean = false) => {
    let newValue = value;
    if (utils.isNotEmpty(value) && value.indexOf('@@') >= 0) {
      const mobileCountry = value.split('@@')[0];
      const mobile = value.split('@@')[1];
      if (isMobileCountry) {
        newValue = mobileCountry;
      } else {
        newValue = mobile;
      }
    }
    return newValue;
  };

  //
  getInputValue = (id: string): FormFieldValue => {
    const { formData } = this.state || {};
    let v1: FormFieldValue = formData[id] || {
      inputId: id,
      value: '',
    };
    return v1;
  };

  getFormFieldValue = (id: string): any => {
    return this.getInputValue(id).value;
  };

  getFormFieldInputId = (fieldName: string) => {
    const { m18Fields } = this.props;
    const field = m18Fields!.find((p) => p.field === fieldName);
    return field?.id;
  };

  // Components
  getFormControl(item: InputField, props: any = {}): ReactNode {
    const { id, type, maxLength } = item;
    return (
      <Form.Control
        key={id}
        className="field"
        name={id}
        maxLength={maxLength}
        {...props}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e, type)}
      />
    );
  }

  renderM18Input = (item: InputField, inputProps?: any): ReactNode => {
    const { combo, lookup } = this.props;
    const sending = this.state.sending;
    const { id, type, pattern, required } = item;

    const name = geti18nValue(item.name);
    const value = this.getFormFieldValue(id);
    const placeholder = `${name}${required ? '*' : ''}`;

    const props = { readOnly: sending, required, placeholder: name, value, ...inputProps };

    switch (type) {
      case UdfInputFieldType.FILE:
        return null;
      case UdfInputFieldType.HTML:
        return null;
      case UdfInputFieldType.STRING:
        break;
      case UdfInputFieldType.MEMO:
        return this.getFormControl(item, { ...props, as: 'textarea' });
      case UdfInputFieldType.COLOR:
        return this.getFormControl(item, { ...props, type: 'color' });
      case UdfInputFieldType.TIME:
        return (
          <TimePicker
            openTo="hours"
            views={['hours', 'minutes', 'seconds']}
            inputFormat="HH:mm:ss"
            value={this.getTimeValue(value)}
            onChange={(value) => this.updateFormData(id, value)}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <div className="d-flex align-items-end flex-column time-picker">
                <Form.Control ref={inputRef} {...inputProps} />
                {InputProps?.endAdornment}
              </div>
            )}
            disabled={sending}
          />
        );
      case UdfInputFieldType.DATE:
        return (
          <DatePicker
            value={value}
            inputFormat="yyyy-MM-dd"
            onChange={(value) => this.updateFormData(id, value)}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <div className="d-flex align-items-end flex-column date-picker">
                <Form.Control ref={inputRef} {...inputProps} />
                {InputProps?.endAdornment}
              </div>
            )}
            disabled={sending}
          />
        );
      case UdfInputFieldType.NUMBER:
        return this.getFormControl(item, {
          ...props,
          type: 'number',
          min: item.minNumber,
          max: item.maxNumber,
        });

      case UdfInputFieldType.CHECKBOX:
        return (
          <FormCheck
            key={id}
            className="field"
            placeholder={name}
            type="checkbox"
            name={id}
            required={required}
            checked={utils.getBooleanValue(value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e, type)}
            disabled={sending}
          />
        );

      case UdfInputFieldType.IMAGE:
        return (
          <div>
            {value && (
              <ShopImage
                src={this.getImageValue(value)}
                width={this.imageSize}
                height={this.imageSize}
                className="mb-2 img-thumbnail"
              />
            )}

            <UploadButton
              key={id}
              disabled={sending}
              accept="image/png, image/jpeg"
              multiple={false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleUpload(e, id)}
            />
          </div>
        );

      case UdfInputFieldType.COMBO:
        const comboOptions = getM18ComboOptions(combo, pattern) || [];
        return (
          <Form.Select
            key={id}
            className="react-select-container"
            value={value}
            aria-label={name}
            onChange={(e) => this.updateFormData(id, e.target.value)}
            disabled={sending}>
            {comboOptions.map((item) => {
              return (
                <option key={item.label} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </Form.Select>
        );

      case UdfInputFieldType.LOOKUP:
        const lookupOptions = getM18LookupOptions(lookup, pattern) || [];
        return (
          <Form.Select
            key={id}
            className="react-select-container"
            value={value}
            aria-label={name}
            onChange={(e) => this.updateFormData(id, e.target.value)}
            disabled={sending}>
            {lookupOptions.map((item) => {
              return (
                <option key={item.label} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </Form.Select>
        );

      case UdfInputFieldType.MOBILE:
        return (
          <InputGroup>
            <CountrySelect
              value={this.getMobileValue(value, true)}
              isSearchable={false}
              onChange={(value: string) => this.handleMobileCountry(id, value)}
              isDisabled={sending}
            />
            <Form.Control
              key={id}
              className="field"
              name={id}
              maxLength={item.maxLength}
              readOnly={sending}
              required={required}
              placeholder={placeholder}
              value={this.getMobileValue(value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e, type)}
            />
          </InputGroup>
        );

      case UdfInputFieldType.PASSWORD:
        return this.getFormControl(item, { ...props, type: 'password' });

      // criteria
      case CriteriaFieldType.DATE:
        return (
          <DatePicker
            value={value}
            inputFormat="yyyy-MM-dd"
            onChange={(value) => this.updateFormData(id, value)}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <div className="d-flex align-items-end flex-column date-picker">
                <Form.Control ref={inputRef} {...inputProps} />
                {InputProps?.endAdornment}
              </div>
            )}
            disabled={sending}
          />
        );
    }

    return this.getFormControl(item, props);
  };

  toM18Values = () => {
    const { m18Fields, combo, lookup } = this.props;
    let copy = { ...this.state.formData };

    m18Fields.forEach((field) => {
      const { id, type, pattern } = field;
      const fieldValue = this.getFormFieldValue(id) || '';
      switch (type) {
        case UdfInputFieldType.TIME:
          copy = { ...copy, [id]: { inputId: id, value: formatTime(fieldValue) } };
          break;
        case UdfInputFieldType.CHECKBOX:
          if (utils.isEmpty(fieldValue)) {
            copy = { ...copy, [id]: { inputId: id, value: false } };
          }
          break;
        case UdfInputFieldType.DATE:
          let value = fieldValue;
          if (utils.isEmpty(value)) {
            value = new Date();
          }
          copy = { ...copy, [id]: { inputId: id, value: formatDate(fieldValue) } };
          break;
        case UdfInputFieldType.COLOR:
          if (utils.isEmpty(fieldValue)) {
            copy = { ...copy, [id]: { inputId: id, value: '#000000' } };
          }
          break;
        case UdfInputFieldType.COMBO:
          if (utils.isEmpty(fieldValue)) {
            const comboOptions = getM18ComboOptions(combo, pattern) || [];
            if (comboOptions.length > 0) {
              copy = { ...copy, [id]: { inputId: id, value: comboOptions[0].value } };
            }
          }
          break;
        case UdfInputFieldType.LOOKUP:
          if (utils.isEmpty(fieldValue)) {
            const lookupOptions = getM18LookupOptions(lookup, pattern) || [];
            if (lookupOptions.length > 0) {
              copy = { ...copy, [id]: { inputId: id, value: lookupOptions[0].value } };
            }
          }
          break;
      }
    });
    return copy;
  };

  renderM18InputError = (item: InputField): ReactNode => {
    const { t } = this.props;
    const id = item.id;

    const errorFields = this.state.errorFields;
    const error = errorFields[id];
    if (utils.isEmptyJSONObject(error)) {
      return null;
    }

    const errorMsg = error;
    if (utils.isEmpty(errorMsg)) {
      return null;
    }

    return <Form.Text className="text-danger">{t(`${errorMsg}`)}</Form.Text>;
  };

  renderM18Field = (item: InputField, isHorizontal: boolean = false, inputProps?: any): ReactNode => {
    const { id, name, required } = item;
    const label = geti18nValue(name);
    const input = this.renderM18Input(item, inputProps);
    const errorMessage = this.renderM18InputError(item);

    if (isHorizontal) {
      return (
        <Form.Group key={`m18-field-${id}`} className="mb-3" as={Row} controlId={id}>
          <Form.Label column sm={4} required={required}>
            {label}
          </Form.Label>
          <Col className="align-self-center" sm={8}>
            {input}
          </Col>
          {errorMessage}
        </Form.Group>
      );
    }
    return (
      <Form.Group key={`m18-field-${id}`} className="mb-3" as={Col} controlId={id}>
        <Form.Label required={required}>{label}</Form.Label>
        {input}
        {errorMessage}
      </Form.Group>
    );
  };

  render(): ReactNode {
    return <></>;
  }
}
