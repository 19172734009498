import keyMirror from '@kopapro-redux/utils/key-mirror';

export default keyMirror({
  QUERY_MEMBER_REQUEST: null,
  GET_MEMBER_QUERY_DATA: null,
  CHANGE_PASSWORD: null,
  LOAD_MEMBER_FORM: null,
  GET_INFO: null,
  CREATE_INFO: null,
  UPDATE_INFO: null,
  LOAD_COUPON_VOUCHERS_LIST: null,
  LOAD_COUPON_VOUCHER_LEDGER: null,
  LOAD_BONUS_POINTS_LEDGER: null,
});
