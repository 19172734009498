import { createSelector } from 'reselect';
import { RootState } from '@kopapro-redux/store';
import { getSelectedCurrency } from '@kopapro-redux/selectors/entities/user';
import utils from '@kopapro-redux/utils/utils';
import { getPosPerference } from '@kopapro-redux/selectors/entities/shop';

// currency and exchange rate
export const getMetaCurrencySetup = (state: RootState) => {
  return state.kopapro.shopMeta.setup.currency;
};

export const getCurrencies = createSelector([getMetaCurrencySetup], (data) => {
  if (data) {
    return data;
  }
  return undefined;
});

export const isCurrencyValid = createSelector(
  [getCurrencies, (state, currency: string) => currency],
  (currencies, currency) => {
    if (currencies && currency) {
      return currencies.some((item) => item.code === currency);
    }
    return false;
  }
);
export const getDisplayCurrency = createSelector([getCurrencies, getSelectedCurrency], (currencies, currency) => {
  if (currencies) {
    return currencies.find((item) => item.code === currency);
  }
  return undefined;
});


export const getBaseCurrency = createSelector([getCurrencies], (currencies) => {
  if (currencies) {
    return currencies.find((item) => item.isDom === true || item.isDom === 1);
  }
  return undefined;
});

export const getCurrencyByM18Id = createSelector(
  [getCurrencies, (state, m18Id: number) => m18Id],
  (currencies, m18Id) => {
    if (currencies) {
      return currencies.find((item) => item.m18Id === m18Id);
    }
    return undefined;
  }
);

export const isSameAsBaseCurrency = createSelector([getBaseCurrency, getSelectedCurrency], (base, currency) => {
  if (base) {
    return base.code === currency;
  }
  return false;
});

// base unit price is dollar in HKD
export const convertToDisplayUnitPrice = (state: RootState, baseUnitPrice: number) => {
  const currency = getDisplayCurrency(state);
  if (currency) {
    const { rate } = currency;
    return roundUnitPrice(state, baseUnitPrice / rate);
  }

  return baseUnitPrice;
};

// base amount is dollar in HKD
export const convertToDisplayAmount = (state: RootState, baseAmount: number) => {
  const currency = getDisplayCurrency(state);
  if (currency) {
    const { rate } = currency;
    return roundAmount(state, baseAmount / rate);
  }

  return baseAmount;
};

// format price, amount, currency and etc

export const roundAmount = (state: RootState, value: number | string) => {
  const amount = utils.getSafetyNumber(value);
  const perference = getPosPerference(state);
  let decimal = 2;
  let roundType = 'round';
  if (perference) {
    decimal = perference.amtDeci || 0;
    roundType = perference.roundType;
  }
  const multiplier = Math.pow(10, decimal);
  if (roundType === 'round') {
    return Math.round(amount * multiplier) / multiplier;
  } else if (roundType === 'ceil') {
    return Math.ceil(amount * multiplier) / multiplier;
  } else if (roundType === 'floor') {
    return Math.floor(amount * multiplier) / multiplier;
  }
  return Math.round(amount * multiplier) / multiplier;
};

export const roundUnitPrice = (state: RootState, value: number | string): number => {
  const unitPrice = utils.getSafetyNumber(value);
  const perference = getPosPerference(state);
  let decimal = 2;
  if (perference) {
    decimal = perference.upDeci || 0;
  }
  return Number(unitPrice.toFixed(decimal));
};
