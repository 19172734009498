import { Component } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
export default class KppBreadcrumb extends Component<any> {
  render() {
    const { items } = this.props;
    if (!items || !Array.isArray(items) || items.length === 0) {
      return null;
    }

    return (
      <Breadcrumb>
        {items.map((item, i) => {
          const { text, to, active } = item;

          return (
            <Breadcrumb.Item
              className="text-truncate"
              key={`breadcrumb-${i}`}
              linkAs={Link}
              active={active}
              linkProps={{ to }}>
              {text}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  }
}
