import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import { geti18nValue, formatUnitPrice, getProductDetailPricing, getSelectedCurrencySym } from '@kopapro/utils/m18';
import React from 'react';
import DefaultModal from '@kopapro/components/commons/modals';
import ImageScroller from '@kopapro/components/productDetail/imageScroller';
import MainImage from '@kopapro/components/productDetail/mainImage';
import { toast } from 'react-toastify';
import KopaproApi from '@kopapro-redux/api';
import ProductDetail from '@kopapro-original/components/productDetail/productDetail';
import Col from 'react-bootstrap/Col';
import utils from '@kopapro/utils/utils';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import ShareButtons from '@kopapro/components/commons/shareButtons';
import { Product } from '@kopapro-redux/types/products';
import { Currency } from '@kopapro-redux/utils/constant';
import { BatchModifyItemInCartAddonPayload, ModifyItemInCartPayload } from '@kopapro-redux/types/cart';
import EventUtils from '@kopapro/utils/event';

class CustomProductDetail extends ProductDetail {
  initData = async () => {
    const { products } = this.props;
    if (products) {
      const curProduct = products[0];
      if (curProduct) {
        const m18ProId = curProduct.m18ProId;
        const classArgs = { proId: m18ProId };

        const res = await KopaproApi.callServlet('0', 'KpptwaServletExtension', 'getProExtraInfo', classArgs);

        const res2 = await KopaproApi.callServlet('0', 'KpptwaServletExtension', 'getGreetingPlate', classArgs);

        const res3 = await KopaproApi.callServlet('0', 'KpptwaServletExtension', 'getAccessoryOption', classArgs);

        const res4 = await KopaproApi.callServlet('0', 'KpptwaServletExtension', 'getWeightOption', classArgs);

        this.setState({
          staticText: res.staticText,
          leadTime: res.leadTime,
          greetingPlate: res2.greetPlateObj,
          accessoryOptions: res3.accessArray,
          variants: res4.accessArray,
          selectedVariantMess: res4.grpDescMessI18,
        });
      }
    }
  };

  loadProductCallback = (data: { products: { [k: number]: Product } }) => {
    if (!this.props.lite && (!data || Object.values(data.products).length === 0)) {
      this.props.navigate('/page-not-found');
      return;
    }
    this.initData();
  };

  // componentDidMount() {
  //   const { groupCode, productCode } = this.props;
  //   this.props.loadProduct({ gpCode: groupCode, proCode: productCode }, this.loadProductCallback);
  //   // this.initData();
  // }

  handleBuyAddRemark = async (goToCheckout = false) => {
    const { t, orderId } = this.props;
    const { leadTime, greetingPlateCharge, greetingPlate, greetingMsg } = this.state;
    const { currentProduct, greetingPlateId, accessoryOptionIds, qty } = this.state;

    let greetingStr = '';
    let greetingChargeStr: any = '';

    if (greetingPlateCharge > 0) {
      greetingStr = t('ce01_kpptwa.msgStr1') + ' ' + greetingMsg;
      greetingChargeStr = formatUnitPrice(greetingPlateCharge, Currency.SELECTED);
    }

    const orderStr = t('ce01_kpptwa.order') + t('ce01_kpptwa.pickup') + ' ' + this.getDateWithLeadTime(leadTime);

    const classArgs = {
      proId: currentProduct!.m18ProId,
      earliestTakeMessage: orderStr,
      greetMessage: greetingStr,
      greetChargeMessage: greetingChargeStr,
      greetPlateId: greetingPlateId,
      accessoryOptionIds: accessoryOptionIds,
      qty: qty,
      greetingPlateCharge: greetingPlateCharge,
      inputGreetMsg: greetingMsg,
      curSym: getSelectedCurrencySym(),
    };
    const res = await KopaproApi.callServlet(orderId, 'KpptwaServletExtension', 'applyMessages', classArgs);
    if (res) {
      this.afterBuyAction(goToCheckout);
    }
  };

  validateGreetingMsgInput = () => {
    let count = 0;
    const { greetingMsg } = this.state;

    for (let i = 0; i < greetingMsg.length; i++) {
      const charCode = greetingMsg.charAt(i);

      let regExp = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g;
      let isChinese = regExp.test(charCode);

      if (isChinese) {
        count += 2.5;
      } else {
        count += 1;
      }
    }

    if (count > 20) {
      return false;
    }

    return true;
  };

  handleBuyAction = (goToCheckout = false) => {
    const { currentProduct, qty: stateValue, greetingPlateId, accessoryOptionIds } = this.state;

    const proceed = this.validateGreetingMsgInput();

    if (!proceed) {
      this.setState({ showWarning: true });
      return;
    }

    this.setState({ showWarning: false });

    let qty = 1;
    if (typeof stateValue === 'number') {
      qty = stateValue as number;
    }

    if (currentProduct) {
      this.setState({ addingInCartFlag: goToCheckout ? 2 : 1 });
      EventUtils.addToCart(currentProduct, qty);

      let proList: any[] = [];
      let accessoryProList: number[] = [];

      proList.push({ proId: currentProduct!.m18ProId, qty: qty });
      if (greetingPlateId > 0) {
        proList.push({ proId: greetingPlateId, qty: qty });
      }
      if (accessoryOptionIds.length > 0) {
        accessoryOptionIds.forEach((accessoryOptionId, i) => {
          if (accessoryOptionId === greetingPlateId) {
            proList.push({ proId: accessoryOptionId, qty: qty * 2 });
          } else {
            proList.push({ proId: accessoryOptionId, qty: qty });
          }

          accessoryProList.push(accessoryOptionId);
        });
      }

      this.props.addToCartMultiple({ proList: proList }, () => {
        this.handleBuyAddRemark(goToCheckout);
      });
    }
    /*
      this.props.addToCart({ qty, proId: currentProduct!.m18ProId }, () => {
        if (greetingPlateId === 0 && accessoryOptionIds.length === 0) {
          this.handleBuyAddRemark(goToCheckout);
        } else {
          if (greetingPlateId > 0) {
            if (accessoryOptionIds.length === 0) {
              this.props.addToCart({ qty, proId: greetingPlateId }, () => {
                this.handleBuyAddRemark(goToCheckout);
              });
            } else {
              this.props.addToCart({ qty, proId: greetingPlateId }, () => {
                accessoryOptionIds.forEach((accessoryOptionId, i) => {
                  if (accessoryOptionId > 0) {
                    if (i === accessoryOptionIds.length - 1) {
                      this.props.addToCart({ qty, proId: accessoryOptionId }, () => {
                        this.handleBuyAddRemark(goToCheckout);
                      });
                    } else {
                      this.props.addToCart({ qty, proId: accessoryOptionId });
                    }
                  }
                });
              });
            }
          } else {
            accessoryOptionIds.forEach((accessoryOptionId, i) => {
              if (accessoryOptionId > 0) {
                if (i === accessoryOptionIds.length - 1) {
                  this.props.addToCart({ qty, proId: accessoryOptionId }, () => {
                    this.handleBuyAddRemark(goToCheckout);
                  });
                } else {
                  this.props.addToCart({ qty, proId: accessoryOptionId });
                }
              }
            });
          }
        }
      });
    }
    */
  };

  afterBuyAction = async (goToCheckout = false) => {
    const self = this;
    const { navigate, t } = this.props;
    const { bDesc } = this.state.currentProduct!;

    self.setState({ addingInCartFlag: 0 });
    if (goToCheckout) {
      navigate('/cart');
    } else {
      const event = new Event('addToCart');
      const productName = geti18nValue(bDesc);
      document.dispatchEvent(event);
      toast.dismiss();
      toast.success(<div className="text-break">{t('ce01_pmpcore.react.addToCart', { product: productName })}</div>);
    }
  };

  getDateWithLeadTime = (leadTime: number) => {
    const date = new Date();
    date.setDate(date.getDate() + leadTime);

    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();

    let mmStr = '';
    let ddStr = '';

    if (dd < 10) ddStr = '0' + dd.toString();
    if (mm < 10) mmStr = '0' + mm.toString();
    const formattedToday = dd + '/' + mm + '/' + yyyy;

    return formattedToday;
  };

  modifyChargeGreetPlate = (charge: number, greetPlateId: number, value: boolean) => {
    let { addCharge } = this.state;
    if (value) {
      addCharge += charge;
      this.setState({ greetingPlateId: greetPlateId, greetingPlateCharge: charge });
    } else {
      addCharge -= charge;
      this.setState({ greetingPlateId: 0, greetingPlateCharge: 0 });
    }

    this.setState({ addCharge: addCharge });
  };

  modifyChargeAccessory = (charge: number, accessoryOptionId: number, value: boolean) => {
    let { addCharge, accessoryOptionIds } = this.state;
    if (value) {
      addCharge += charge;
      let curArr = [...accessoryOptionIds];

      if (accessoryOptionId > 0) {
        curArr.push(accessoryOptionId);
      }

      this.setState({ accessoryOptionIds: curArr });
    } else {
      addCharge -= charge;

      let curArr = accessoryOptionIds.filter((item) => {
        return item !== accessoryOptionId;
      });

      this.setState({ accessoryOptionIds: curArr });
    }

    this.setState({ addCharge: addCharge });
  };

  changeVariant = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const groupCode = event.target.value;
    this.props.navigate(`/products/${groupCode}`);
  };

  renderPrice = () => {
    const { t } = this.props;
    const item = this.state.currentProduct!;
    const { memTypeShortName, hideMemTypeExpiryDate, memTypeExpiryDate } = item;
    const { regularPrice, salesPrice, isShowMemberUpDesc } = getProductDetailPricing(item);
    const { addCharge } = this.state;

    let memberPriceDesc = geti18nValue(memTypeShortName);
    let offPrice: any = <>{formatUnitPrice(salesPrice + addCharge, Currency.SELECTED)}</>;
    if (regularPrice > salesPrice) {
      const memTypeExpiryDateMsg =
        t('ce01_pmpcore.react.until') + ' ' + t('{ date, full }', { date: memTypeExpiryDate });

      offPrice = (
        <>
          <div className="me-1">
            <del>{formatUnitPrice(regularPrice, Currency.SELECTED)}</del>
          </div>
          <div className="text-danger">
            {isShowMemberUpDesc ? memberPriceDesc + ': ' : ''}
            {formatUnitPrice(salesPrice, Currency.SELECTED)}
          </div>
          {isShowMemberUpDesc && !hideMemTypeExpiryDate && (
            <div className="text-danger fs-6 small">{memTypeExpiryDateMsg}</div>
          )}
        </>
      );
    }

    return offPrice;
  };

  renderExtraInfo = (productName: String) => {
    const { staticText, leadTime, greetingPlate, accessoryOptions, variants, selectedVariantMess, greetingPlateId } =
      this.state;

    const { t, groupCode } = this.props;

    const staticTextDisplay = geti18nValue(staticText);
    const formattedToday = this.getDateWithLeadTime(leadTime);
    const variantDescDisplay = geti18nValue(selectedVariantMess);
    const orderStr1 = t('ce01_kpptwa.order');
    const orderStr2 = t('ce01_kpptwa.pickup');

    const msgStr1 = t('ce01_kpptwa.msgStr1');
    const msgStr2 = t('ce01_kpptwa.msgStr2');

    const addOnStr1 = t('ce01_kpptwa.needAddOn');

    let curVariantDisplay = '';

    if (variants !== undefined) {
      variants.forEach((variant) => {
        if (variant.proCode === groupCode) {
          curVariantDisplay = geti18nValue(variant.variantMess);
        }
      });
    }

    return (
      <div>
        {variants !== undefined && curVariantDisplay !== '' ? (
          <div>
            <div>
              {variantDescDisplay}: {curVariantDisplay}
            </div>
            <select
              value={groupCode}
              style={{
                width: '60%',
                height: '40px',
                margin: '5px',
                borderStyle: 'solid',
                borderColor: 'grey',
                borderRadius: '5px',
              }}
              onChange={this.changeVariant}>
              {variants &&
                variants.map((variant, key) => {
                  return <option value={variant.proCode}>{geti18nValue(variant.variantMess)}</option>;
                })}
            </select>
          </div>
        ) : null}

        {greetingPlate && greetingPlate.greetPlateId > 0 && (
          <div style={{ marginBottom: '5px' }}>
            <input
              type="checkbox"
              id="greetPlate"
              name="greetPlate"
              value="greetPlate"
              onChange={(e) =>
                this.modifyChargeGreetPlate(greetingPlate.addCharge, greetingPlate.greetPlateId, e.target.checked)
              }
              style={{ marginRight: '10px' }}
            />
            <label>{geti18nValue(greetingPlate.descMess)}</label>
          </div>
        )}

        {greetingPlate && greetingPlate.greetPlateId > 0 && <div>{msgStr1}</div>}
        {greetingPlate && greetingPlate.greetPlateId > 0 && greetingPlateId > 0 && (
          <input
            type="text"
            id="msgInput"
            name="msgInput"
            placeholder={msgStr2}
            readOnly={false}
            onChange={(e) => {
              this.setState({ greetingMsg: e.currentTarget.value });
            }}
            style={{
              width: '95%',
              height: '40px',
              margin: '5px',
              borderStyle: 'solid',
              borderColor: 'lightgrey',
              borderRadius: '5px',
            }}></input>
        )}
        {greetingPlate && greetingPlate.greetPlateId > 0 && greetingPlateId <= 0 && (
          <input
            type="text"
            id="msgInput"
            name="msgInput"
            placeholder={msgStr2}
            readOnly={true}
            style={{
              width: '95%',
              height: '40px',
              margin: '5px',
              borderStyle: 'solid',
              borderColor: 'lightgrey',
              borderRadius: '5px',
              background: 'lightgrey',
            }}></input>
        )}

        {accessoryOptions !== undefined && accessoryOptions.length > 0 && (
          <div style={{ marginTop: '10px', marginBottom: '5px' }}>{addOnStr1}</div>
        )}

        {accessoryOptions &&
          accessoryOptions.map((elem, key) => {
            return (
              <div style={{ marginBottom: '5px' }}>
                <input
                  type="checkbox"
                  onChange={(e) => this.modifyChargeAccessory(elem.addCharge, elem.accessoryOptionId, e.target.checked)}
                  style={{ marginRight: '10px' }}
                />
                <label>{geti18nValue(elem.descMess)}</label>
              </div>
            );
          })}

        <div className="mb-3 text-break" style={{ marginTop: '10px', fontWeight: 'bold' }}>
          {staticTextDisplay}
        </div>
        {leadTime > 0 && (
          <div className="mb-3 text-break" style={{ fontWeight: 'bold' }}>
            {orderStr1} <span style={{ color: 'red' }}>{productName}</span> {orderStr2} {formattedToday}
          </div>
        )}
      </div>
    );
  };

  renderContent = (currentProduct: Product) => {
    const { t, liteOptions, lite, productGroup } = this.props;
    const { showWarning } = this.state;
    const { showChectOut, showShareBtn, showVariant, showBuyNow } = liteOptions || {};

    if (utils.isUndefined(productGroup)) {
      return null;
    }
    const { isTailor } = productGroup!;
    // undefind !== false is true
    const { bDesc } = currentProduct;
    const isAllowToBuy = this.allowToBuy();
    const productName = geti18nValue(bDesc);

    return (
      <div className="d-flex flex-column h-100">
        <h2 className="mb-3 text-break">{productName}</h2>
        <div className="mb-4 fs-4">{this.renderPrice()}</div>
        {this.renderAdditionalInfo()}
        {this.showOpeningInfo()}
        {this.renderUnpublishInfo()}
        {!isTailor && this.renderStockQuantity()}
        {!isTailor && showVariant !== false && this.renderAttribute()}
        {!isTailor && this.renderMoq()}

        {this.renderExtraInfo(productName)}

        {this.renderSoldOut()}
        {this.renderQtyInput()}
        {/*this.renderGreetingPlate()*/}
        <ErrorMessage isShow={showWarning} message={t('ce01_kpptwa.msgWarning')} />

        {showChectOut !== false && isAllowToBuy && (
          <div className="row g-3">
            <Col>{this.renderBuyButton(t('ce01_pmpcore.kopapro.react.addToCart'))}</Col>
            <Col>
              {!isTailor && showBuyNow !== false && this.renderBuyButton(t('ce01_pmpcore.kopapro.react.buyNow'), true)}
            </Col>
          </div>
        )}
        <ErrorMessage message={this.state.errorMessage} />
        {this.renderWishListInfo()}
        {showShareBtn !== false && (
          <div className="mt-1">
            <ShareButtons url={window.location.href} title={productName} />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { relatedItems, products, lite, t } = this.props;
    const { currentProduct, selectedImageCode } = this.state;
    if (lite) {
      return this.renderLite();
    }

    let components = null;
    let images = null;
    if (products && currentProduct) {
      images = currentProduct?.images;
      const imageScrollerProps = {
        images,
        vertical: true,
        onSelect: this.setSelectedImageCode,
        selectedImage: selectedImageCode,
      };
      components = (
        <>
          <div className="row mb-4">
            <ImageScroller {...imageScrollerProps} />
            <div className="col-lg-6">
              <div className="row">
                <div className="col-12 mb-4 ">
                  <MainImage
                    images={images}
                    image={selectedImageCode}
                    proIconsMore={currentProduct!.proIconsMore}
                    productType={currentProduct?.coupon ? 'coupon' : ''}
                  />
                  {this.renderHashTags()}
                </div>
              </div>
              <ImageScroller {...imageScrollerProps} vertical={false} />
            </div>

            <div className="col-lg-5">{this.renderContent(currentProduct)}</div>
          </div>
          {/* facebook comment */}
          {this.renderFacebookComment()}
          {this.renderReviews()}

          {!lite && (
            <>
              <div style={{ width: '80%', margin: 'auto' }}>
                <h4 className="mt-3 pb-2">
                  <u>
                    <strong>{t('ce01_pmpcore.react.detailDesc')}</strong>
                  </u>
                </h4>
                <div className="lead flex-shrink-0 product-description">
                  <SafetyDOM html={geti18nValue(currentProduct.dDesc)} />
                </div>
              </div>
            </>
          )}
          {this.renderSubProductList('ce01_pmpcore.react.packageProduct', undefined, currentProduct.packagePro)}
          {this.renderSubProductList('ce01_pmpcore.react.suggestedProduct', relatedItems)}
          {this.renderTailorModal()}
          <DefaultModal
            centered={true}
            show={this.state.showAdditionalInfoModal}
            body={geti18nValue(currentProduct.proAddInfo.detail)}
            onCloseHandler={this.handleModalClose}
          />
        </>
      );
    }

    return (
      <div className="page-container container px-xl-5 product-detail">
        <ScrollToTopOnMount />
        {this.renderTop()}
        {components}
      </div>
    );
  }
}

export default CustomProductDetail;
