import { BasicStyle } from "@kopapro-redux/types/componentSetting";
import { getCustomStyle } from '@kopapro/utils/m18';
import { Component, ReactNode } from 'react';

interface IProps {
  name: string;
  enableNameStyle?: boolean;
  nameStyle?: BasicStyle;
  style?: any;
}

export default class ShopName extends Component<IProps> {
  render(): ReactNode {
    const { enableNameStyle, nameStyle, style } = this.props;

    let styles = { ...style };
    if (enableNameStyle) {
      styles = { ...style, ...getCustomStyle({ ...nameStyle, useUdfStyle: true }, false) };
    }

    return (
      <span className="ms-2 text-truncate" style={styles}>
        {this.props.name}
      </span>
    );
  }
}
