import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { triggerM18Action } from '@kopapro-redux/utils/m18';
import WildCard from '@kopapro/components/preview/wildCard';
import Button from 'react-bootstrap/Button';
import { M18RnMeta } from '@kopapro-redux/utils/constant';
import { isNotM18PreviewMode } from '@kopapro-redux/utils/m18';
interface WithConfigurableProps {
  m18CompId: string;
  index?: number;
  canDelete?: boolean;
  needWildCard?: boolean;
}

interface ConfigurableState {
  showIcons: boolean;
}

const withConfigurable = <P extends object>(
  Component: React.ComponentType<P>,
  configurableProp: WithConfigurableProps
) =>
  class WithConfigurable extends React.Component<P, ConfigurableState> {
    defaultState = {
      showIcons: false,
    };

    constructor(props: P) {
      super(props);
      this.state = this.defaultState;
    }

    showIcons = () => {
      this.setState({ showIcons: true });
    };

    closeIcons = () => {
      this.setState({ showIcons: false });
    };

    renderWildCard = (m18CompId: string, index?: number) => {
      if (configurableProp.needWildCard) {
        return <WildCard prevM18CompId={`${m18CompId}@@${index}`} />;
      }
      return null;
    };

    editComponent = () => {
      const { m18CompId } = configurableProp;
      triggerM18Action(`portalAction_${m18CompId}`);
    };

    deleteComponent = () => {
      const { m18CompId } = configurableProp;
      triggerM18Action(`portalDelete_${m18CompId}`);
    };

    render() {
      const { ...props } = this.props;
      const { m18CompId, index, canDelete } = configurableProp;
      if (isNotM18PreviewMode()) {
        return <Component {...(props as P)} />;
      }
      const footerClass = m18CompId === M18RnMeta.UDFFOOTERMENU ? 'mt-auto' : '';
      return (
        <>
          {this.renderWildCard(m18CompId, index)}
          <div
            id={m18CompId}
            onMouseEnter={() => this.showIcons()}
            onMouseLeave={() => this.closeIcons()}
            className={footerClass}>
            {this.state.showIcons && (
              <div className="position-absolute preview-configurable-button">
                <Button variant="outline-secondary" onClick={() => this.editComponent()} className="ms-3">
                  <EditIcon fontSize="medium" />
                </Button>
                {canDelete && (
                  <Button variant="outline-secondary" onClick={() => this.deleteComponent()} className="ms-3">
                    <DeleteIcon fontSize="medium" />
                  </Button>
                )}
              </div>
            )}
            <Component {...(props as P)} />
          </div>
        </>
      );
    }
  };

export default withConfigurable;
