import { RootState } from '@kopapro-redux/store';
import utils from '@kopapro-redux/utils/utils';
import { createSelector } from 'reselect';

export const getCurrentLang = (state: RootState) => {
  return state.kopapro.user.lang;
};

export const getSelectedCurrency = (state: RootState) => {
  return state.kopapro.user.currency;
};

export const getCurrentUserInfo = (state: RootState) => {
  return state.kopapro.user.userInfo;
};

export const userLoggedIn = createSelector(getCurrentUserInfo, (userInfo) => {
  if (userInfo) {
    return utils.isNotEmptyM18Id(userInfo.uid);
  }
  return false;
});

export const getMemberTypeId = createSelector(getCurrentUserInfo, (userInfo) => {
  if (userInfo) {
    return userInfo.memTypeId;
  }
  return 0;
});