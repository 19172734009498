import React, { Component, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb';
import { CheckoutProps } from '@kopapro/components/checkout';
import Summary from '@kopapro/components/checkout/summary';
import Extra from '@kopapro/components/checkout/extra';
import Delivery from '@kopapro/components/checkout/delivery';
import Payment from '@kopapro/components/checkout/payment';
import { ConfirmOrderParam } from '@kopapro-redux/types/checkout';
import utils from '@kopapro-redux/utils/utils';
import { CheckoutComponents } from '@kopapro/utils/constants/constants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import CartInfo from '@kopapro/components/checkout/cartInfo';
import Loading from '@kopapro/components/commons/loading';
import Cart from '@kopapro/components/cart';
import classnames from 'classnames';
import Information from '@kopapro/components/cart/information';

interface CheckoutState {
  confirmParam: ConfirmOrderParam;
  currentSection: string;
}

const defaultCheckoutSections = [
  CheckoutComponents.Delivery,
  CheckoutComponents.Payment,
  CheckoutComponents.Extra,
  CheckoutComponents.Summary,
];

export default class Checkout extends Component<CheckoutProps, CheckoutState> {
  checkoutSections = [...defaultCheckoutSections];
  defaultState = {
    confirmParam: {
      subscribedNews: false,
      saveDeliveryToMem: false,
    },
    currentSection: CheckoutComponents.Delivery,
  };
  ref: React.RefObject<any>;

  constructor(props: CheckoutProps) {
    super(props);
    this.state = Object.assign({}, this.defaultState, {
      currentSection: props.currentSection || this.defaultState.currentSection,
    });
    this.ref = React.createRef();
  }

  componentDidMount(): void {
    this.prepareSections();
    this.scrollToCheckoutTop();
  }

  componentDidUpdate(prevProps: CheckoutProps) {
    const { currentSection, location } = this.props;
    this.prepareSections();

    // handle navigation from /checkout/summary to /checkout
    const previousLocationState = prevProps.location.state;
    const locationState = location.state;
    const nextIndex = this.checkoutSections.indexOf(locationState);

    if (previousLocationState !== locationState && nextIndex > -1) {
      this.setState({ currentSection: locationState });
      window.history.replaceState(null, '');
    }

    // handle navigation from /checkout to /checkout/summary
    if (prevProps.currentSection !== currentSection && utils.isNotEmpty(currentSection)) {
      this.setState({ currentSection: currentSection as string });
    }
  }

  prepareSections() {
    this.checkoutSections = [...defaultCheckoutSections];
    if (!this.props.isRequirePayment) {
      this.checkoutSections = this.checkoutSections.filter((section) => section !== CheckoutComponents.Payment);
    }
    if (!this.props.isRequireExtra) {
      this.checkoutSections = this.checkoutSections.filter((section) => section !== CheckoutComponents.Extra);
    }
  }

  updateConfirmParam = (param: { [k: string]: boolean }) => {
    this.setState({ confirmParam: { ...this.state.confirmParam, ...param } });
  };

  goToNext = () => {
    this.goToSection(1);
  };

  goToPrevious = () => {
    this.goToSection(-1);
  };

  scrollToCheckoutTop = () => {
    if (this.ref && this.ref.current) {
      window.scrollTo(0, this.ref.current.offsetTop);
    }
  };

  goToSection = (step: number) => {
    this.scrollToCheckoutTop();
    const { onePage, onePageBackToCartHandler } = this.props;
    const currentIndex = this.checkoutSections.indexOf(this.state.currentSection);
    if (currentIndex + step >= this.checkoutSections.length) {
      return;
      // nothing to do
    } else if (currentIndex + step < 0) {
      if (!onePage) {
        this.props.navigate('/cart');
      } else if (onePageBackToCartHandler) {
        onePageBackToCartHandler();
      }
      return;
    }

    const nextSection = this.checkoutSections[currentIndex + step];
    if (!onePage && nextSection === CheckoutComponents.Summary) {
      this.props.navigate('/checkout/summary');
      return;
    }

    const isSummaryPage = this.props.location.pathname.startsWith('/checkout/summary');
    if (isSummaryPage) {
      this.props.navigate('/checkout', { state: nextSection });
      return;
    }

    this.setState({ currentSection: nextSection });
  };

  renderTop(): ReactNode {
    const { onePage } = this.props;
    if (onePage) {
      return null;
    }
    return <>{this.renderNav()}</>;
  }

  renderNav(): ReactNode {
    const { t } = this.props;

    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.checkOut'), active: true },
    ];
    return <KppBreadcrumb items={items} />;
  }

  renderMain(): ReactNode {
    const confirmProps = { confirmParam: this.state.confirmParam, updateConfirmParam: this.updateConfirmParam };
    const controlProps = { componentPrevious: this.goToPrevious, componentNext: this.goToNext };

    switch (this.state.currentSection) {
      case CheckoutComponents.Delivery:
        return <Delivery {...confirmProps} {...controlProps} />;
      case CheckoutComponents.Payment:
        return <Payment {...controlProps} />;
      case CheckoutComponents.Extra:
        return <Extra orderId={this.props.orderId} {...controlProps} />;
      case CheckoutComponents.Summary:
        return <Summary orderId={this.props.orderId} {...confirmProps} {...controlProps} />;
    }
    return null;
  }

  renderCartContent = () => {
    const { currentSection } = this.state;
    const allowEditDiscount =
      currentSection === CheckoutComponents.Delivery || currentSection === CheckoutComponents.Payment;
    return <CartInfo allowEditDiscount={allowEditDiscount} currentSection={currentSection} />;
  };

  renderCartContentForSmallScreen = () => {
    const { t } = this.props;
    return (
      <Accordion className="d-block d-md-none mb-3" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('ce01_pmpcore.react.cart')}</Accordion.Header>
          <Accordion.Body>
            {this.renderCartContent()}
            <Information />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  render(): ReactNode {
    const { orderId, items, retrieving, onePage } = this.props;
    const { currentSection } = this.state;

    const isSummary = currentSection === CheckoutComponents.Summary;

    let content = <Loading />;

    if (!retrieving) {
      if (utils.isEmpty(orderId) || utils.isEmptyList(items)) {
        if (onePage) {
          return <Cart onePage />;
        }
        return <Navigate to="/cart" />;
      }

      content = (
        <>
          {this.renderTop()}
          <Row>
            <Col md={isSummary ? 12 : 7} className={classnames({ 'pe-md-3 pe-lg-4 pe-xl-5': !isSummary })}>
              {!isSummary && this.renderCartContentForSmallScreen()}
              {this.renderMain()}
            </Col>
            {!isSummary && (
              <Col md={5} className="d-none d-md-block border-start ps-md-3 ps-lg-4 ps-xl-5">
                {this.renderCartContent()}
                <Information />
              </Col>
            )}
          </Row>
        </>
      );
    }

    return (
      <div ref={this.ref} id="checkout" className="page-container container-xl px-xl-5 checkout">
        {content}
      </div>
    );
  }
}
