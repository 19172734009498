// import { ReactComponentElement } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';

export function withNavigation<T>(WrappedComponent: React.ComponentType<T>) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const Component = (props: any) => {
    // Fetch the props you want to inject. This could be done with context instead.

    // props comes afterwards so the can override the default ones.
    return <WrappedComponent navigate={useNavigate()} {...(props as T)} />;
  };

  Component.displayName = `withNavigation(${displayName})`;

  return Component;
}

export function withParams<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const Component = (props: any) => {
    return <WrappedComponent params={useParams()} {...(props as T)} />;
  };

  Component.displayName = `withParams(${displayName})`;

  return Component;
}

export function withLocation<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const Component = (props: any) => {
    return <WrappedComponent location={useLocation()} {...(props as T)} />;
  };

  Component.displayName = `withLocation(${displayName})`;

  return Component;
}

export function withSearchParams<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const Component = (props: any) => {
    return <WrappedComponent searchParams={useSearchParams()} {...(props as T)} />;
  };

  Component.displayName = `withSearchParams(${displayName})`;

  return Component;
}
