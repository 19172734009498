import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { RootState } from "@kopapro-redux/store";
import { withTranslation, WithTranslation } from "react-i18next";

import { PaymentMethod } from '@kopapro-redux/types/paymentMethod';
import PaymentMethodItem from "@kopapro/components/paymentMethod/item/item";

interface IProps extends WithTranslation {
  item: PaymentMethod;
  imageHeight?: string;
}

interface StateProps {}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type PaymentMethodItemProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PaymentMethodItem));
