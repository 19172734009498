import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Search from '@kopapro/components/header/components/search/search';
import { withTranslation, WithTranslation } from 'react-i18next';

import { searchProduct } from '@kopapro-redux/actions/products';
import { withNavigation } from '@kopapro/components/commons/router-hoc';

interface IProps extends WithTranslation {
  navigate: any; // from  withNavigation
  basic?: boolean;
  closeDrawer?: Function;
  iconStyle?: any;
  marginStyle?: any;
}

interface StateProps {}

interface DispatchProps {
  searchProduct: typeof searchProduct;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  searchProduct: searchProduct,
};

export type SearchProps = IProps & StateProps & DispatchProps;
export default withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Search)));
