import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InputField } from '@kopapro-redux/types/componentSetting';
import Login from '@kopapro/components/pages/login/login';
import { login } from '@kopapro-redux/actions/user';
import {
  getLoginInputFormatList,
  getLoginSetupCaptchaType,
  getLoginSetupLogo,
  isLoginSetupUseCaptcha,
} from '@kopapro-redux/selectors/entities/login';
import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';
import { getShopLoginSetup } from '@kopapro-redux/selectors/entities/shop';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { ShopLoginSetup } from '@kopapro-redux/types/shop';
import withConfigurable from '@kopapro/components/preview/configurable';
import { M18RnMeta } from '@kopapro-redux/utils/constant';

interface IProps extends WithTranslation {
  navigate: any; // from withNavigation
}

interface DispatchProps {
  login: typeof login;
}

interface StateProps {
  loginSetup: ShopLoginSetup;
  userLoggedIn: boolean;
  inputFields: InputField[];
  photoCode: string;
  useCaptcha: boolean;
  captchaType: string;
  captchaPublicKey: string;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  let inputFields: InputField[] = getLoginInputFormatList(state);
  let useCaptcha: boolean = isLoginSetupUseCaptcha(state);
  let captchaType: string = getLoginSetupCaptchaType(state);
  const photoCode = getLoginSetupLogo(state);
  const login = getShopLoginSetup(state);

  return {
    loginSetup: login,
    userLoggedIn: userLoggedIn(state),
    inputFields,
    photoCode,
    useCaptcha,
    captchaType,
    captchaPublicKey: login.captchaPublicKey,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  login,
};

const configurableProps = {
  m18CompId: M18RnMeta.LOGINVIEW,
};

export type LoginProps = IProps & StateProps & DispatchProps;
const component = withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Login)));
export default withConfigurable(component, configurableProps);