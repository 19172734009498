import { ChangeEvent, FC, useCallback, useEffect, useState, useRef } from "react";
import classnames from 'classnames';
interface MultiRangeSliderProps {
  min: number;
  max: number;
  values: { [name: string]: number };
  onChange: Function;
}

const MultiRangeSlider: FC<MultiRangeSliderProps> = ({ min, max, onChange, values }) => {
  // parent value
  const [minPropVal, setMinPropVal] = useState(Math.max(min, Math.min(values.min, max)));
  const [maxPropVal, setMaxPropVal] = useState(Math.min(max, Math.max(values.max, min)));
  // input control value
  const [minVal, setMinVal] = useState(Math.max(min, Math.min(values.min, max)));
  const [maxVal, setMaxVal] = useState(Math.min(max, Math.max(values.max, min)));
  const minValRef = useRef<HTMLInputElement>(null);
  const maxValRef = useRef<HTMLInputElement>(null);
  const range = useRef<HTMLDivElement>(null);
  const step = 0.01; // get from props later

  const setMinMax = useCallback(
    (min: number, max: number) => {
      setMinVal(Math.max(min, Math.min(values.min, max))); // min <= values.min <= max
      setMaxVal(Math.min(max, Math.max(values.max, min))); // min <= values.max <= max
    },
    [values.min, values.max]
  );

  useEffect(() => {
    setMinMax(min, max);
  }, [min, max, setMinMax]);

  const setDefaultMin = useCallback(
    (minValue: number) => {
      if (minValue !== minPropVal) {
        setMinPropVal(Math.max(min, Math.min(minValue, max)));
      }
    },
    [min, max, minPropVal]
  );

  const setDefaultMax = useCallback(
    (maxValue: number) => {
      if (maxValue !== maxPropVal) {
        setMaxPropVal(Math.min(max, Math.max(maxValue, min)));
      }
    },
    [min, max, maxPropVal]
  );

  useEffect(() => {
    setDefaultMin(values.min);
  }, [values.min, setDefaultMin]);

  useEffect(() => {
    setDefaultMax(values.max);
  }, [values.max, setDefaultMax]);

  // Convert to percentage
  const getPercent = useCallback((value: number) => Math.round(((value - min) / (max - min)) * 100), [min, max]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minPropVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Precede with '+' to convert the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [min, minPropVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxPropVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [max, maxPropVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    if (minVal >= 0 && maxVal >= 0) {
      onChange({ min: minVal, max: maxVal });
    }
  }, [minVal, maxVal, onChange]);

  return (
    <>
      <div className="ranger-slider-container">
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={minPropVal}
          ref={minValRef}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = Math.min(+event.target.value, maxVal - 1);
            setMinVal(value);
            event.target.value = value.toString();
          }}
          className={classnames('thumb thumb--zindex-3', {
            'thumb--zindex-5': minVal > max - 100,
          })}
        />
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={maxPropVal}
          ref={maxValRef}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = Math.max(+event.target.value, minVal + 1);
            setMaxVal(value);
            event.target.value = value.toString();
          }}
          className="thumb thumb--zindex-4"
        />

        <div className="slider">
          <div className="slider__track"></div>
          <div ref={range} className="slider__range"></div>
          <div className="slider__left-value">{minPropVal}</div>
          <div className="slider__right-value">{maxPropVal}</div>
        </div>
      </div>
    </>
  );
};

export default MultiRangeSlider;
