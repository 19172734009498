
import { ShopMetaType } from '@kopapro-redux/action-types';
import { Banner, ShopLayout, ShopSetup } from '@kopapro-redux/types/shop';
import { FailurePayload, QueryList } from '@kopapro-redux/types/general';
import { ShopComponentSetting } from '@kopapro-redux/types/componentSetting';

// meta layout
export const getMetaLayoutSuccess = (payload: ShopLayout) => ({
  type: ShopMetaType.GET_SHOP_LAYOUT_SUCCESS,
  payload
});

export const getMetaLayoutFailure = (payload: FailurePayload) => ({
  type: ShopMetaType.GET_SHOP_LAYOUT_FAILURE,
  payload
});

// meta setup
export const getMetaSetupSuccess = (payload: ShopSetup) => ({
  type: ShopMetaType.GET_SHOP_SETUP_SUCCESS,
  payload
});

export const getMetaSetupFailure = (payload: FailurePayload) => ({
  type: ShopMetaType.GET_SHOP_SETUP_FAILURE,
  payload
});

// meta comp
export const getMetaComponentsSuccess = (payload: ShopComponentSetting) => ({
  type: ShopMetaType.GET_SHOP_COMPONENTS_SUCCESS,
  payload
});

export const getMetaComponentsFailure = (payload: FailurePayload) => ({
  type: ShopMetaType.GET_SHOP_COMPONENTS_FAILURE,
  payload
});

// get all 
export const getMetaRequest = () => ({
  type: ShopMetaType.GET_SHOP_META_REQUEST
});

export const getMetaRequestSuccess = () => ({
  type: ShopMetaType.GET_SHOP_META_SUCCESS
});


// get banners
export const getBannersRequest = () => ({
  type: ShopMetaType.GET_SHOP_BANNER_REQUEST
});

export const receivedShopBanners = (payload: QueryList<Banner>) => ({
  type: ShopMetaType.RECEIVED_SHOP_BANNERS,
  payload
});

// shop splash
export const showShopSplash = () => ({
  type: ShopMetaType.SHOW_SHOP_SPLASH
})

