import React, { Component, ReactNode } from 'react';
import { HomeProps } from '@kopapro/components/home';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';

import {
  BlogPost,
  CategoryList,
  ContactUs,
  Gallery,
  HtmlText,
  ImageWithText,
  LogoList,
  Newsletter,
  ImageWithTextOverlay,
} from '@kopapro/components/shop';
import Banner from '@kopapro/components/shop/banner';
import LiteProductList from '@kopapro/components/shop/liteProductList';
import FeaturedProduct from '@kopapro/components/shop/featuredProduct';
import { getShowInClass } from '@kopapro/utils/m18';
import { setCookie } from '@kopapro-redux/utils/cookies';
import utils from '@kopapro-redux/utils/utils';
import DefaultModal from '@kopapro/components/commons/modals';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import configurable from '@kopapro/components/preview/configurable';
import WildCard from '@kopapro/components/preview/wildCard';
import { M18RnMeta } from '@kopapro/utils/constants/m18';
import { ShopComponent } from '@kopapro-redux/types/shop';
import { isNotM18PreviewMode, isM18PreviewMode } from '@kopapro-redux/utils/m18';

interface HomeState {
  showSplashModal: boolean;
  allowScrollToTop: boolean;
}

export default class Home extends Component<HomeProps, HomeState> {
  defaultState = {
    showSplashModal: false,
    allowScrollToTop: true,
  };

  components: any = {
    ContactUs: ContactUs,
    Newsletter: Newsletter,
    Html: HtmlText,
    Banner: Banner,
    ImageWithText: ImageWithText,
    Product: LiteProductList,
    FeaturedPro: FeaturedProduct,
    FeaturedCate: LiteProductList,
    Gallery: Gallery,
    CateList: CategoryList,
    LogoList: LogoList,
    ImageWithTextOverlay: ImageWithTextOverlay,
    BlogPost: BlogPost,
  };

  constructor(props: HomeProps) {
    super(props);
    this.state = this.defaultState;
  }

  handleShowSplashModal = () => {
    if (!this.props.splash?.enableSplash) {
      return;
    }
    if (this.props.isShowSplash && isNotM18PreviewMode()) {
      return;
    }
    this.setState({ showSplashModal: true });
    this.props.showSplash();
  };

  handleCloseSplashModal = () => {
    this.setState({ showSplashModal: false });
  };

  handleScrollTo = () => {
    const state = this.props.location.state;

    if (state && state.scrollTo && state.scrollTo.length > 1) {
      const target = state.scrollTo.substring(1);

      const element = document.getElementById(target);

      if (element) {
        window.history.replaceState({}, document.title);
        setTimeout(() => {
          window.scrollTo(0, element.offsetTop - 80);
        }, 200);
      }
    }
  };

  componentDidMount() {
    this.handleScrollTo();
    this.handleShowSplashModal();
    this.loadDiscountCodeLink();
  }

  componentDidUpdate(prevProps: Readonly<HomeProps>, prevState: Readonly<HomeState>, snapshot?: any): void {
    this.handleScrollTo();

    if (isM18PreviewMode()) {
      // should occur in preview mode only
      if (this.state.allowScrollToTop) {
        this.setState({ allowScrollToTop: false });
      }
      if (prevProps.splash?.popSplash !== this.props.splash?.popSplash) {
        if (this.props.splash?.popSplash) {
          this.handleShowSplashModal();
        } else {
          this.handleCloseSplashModal();
        }
      }
    }
  }

  loadDiscountCodeLink() {
    const { discCode } = this.props;
    if (utils.isNotEmpty(discCode)) {
      setCookie('discCode', discCode!, 30);
    }
  }

  createConfigurableWrapper = (component: ShopComponent, index: number, Element: any) => {
    if (!isM18PreviewMode()) {
      return <Element {...component} />;
    }
    const m18CompId = `${component.compName}@@${component.compId}`;
    const Wrapper = configurable(React.Fragment, {
      m18CompId,
      index,
      needWildCard: true,
      canDelete: component.compName !== M18RnMeta.PRODUCT,
    });

    return (
      <Wrapper>
        <Element {...component} />
      </Wrapper>
    );
  };

  render(): ReactNode {
    const { components, splash } = this.props;
    const homePageComponents = components.map((component, index) => {
      const TagName = this.components[component.compName];

      if (TagName) {
        const configurableWrapper = this.createConfigurableWrapper(component, index, TagName);
        return (
          <div key={component.compId} className={`home-component ${getShowInClass(component.showIn)}`}>
            {configurableWrapper}
            {<hr key={`${component.compId}_divider`} />}
          </div>
        );
      }
      return null;
    });
    return (
      <div className="page-container">
        {this.state.allowScrollToTop && <ScrollToTopOnMount />}
        {homePageComponents}
        <WildCard />
        <DefaultModal
          show={this.state.showSplashModal}
          body={geti18nValue(splash?.splashHtml || '')}
          scrollable={true}
          onCloseHandler={this.handleCloseSplashModal}
        />
      </div>
    );
  }
}
