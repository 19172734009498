import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { RootState } from "@kopapro-redux/store";
import { withTranslation, WithTranslation } from "react-i18next";

import { getAboutUs } from '@kopapro-redux/selectors/entities/aboutUs';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import AboutUs from '@kopapro/components/shop/aboutUs/aboutUs';

interface IProps extends WithTranslation {}

interface StateProps {
  aboutUs: I18nDictionary;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    aboutUs: getAboutUs(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type AboutUsProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AboutUs));
