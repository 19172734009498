export const PlanLevel = {
   Startup: "Startup",
   Premium: "Premium",
   Enterprise: "Enterprise",
};

export const showInType = {
  desktop: 'onlyDesktop',
  mobile: 'onlyMobile',
};

export const OrderResDialogType = {
   RemoveFullyServEvent: "removeFullyServEvent",
};

export const ProSortBy = {
   POP: { value: "pop", mess: "ce01_pmpcore.react.proSortBy.pop" },
   SALES: { value: "sales", mess: "ce01_pmpcore.react.proSortBy.sales" },
   PRICEUP: { value: "priceUp", mess: "ce01_pmpcore.react.proSortBy.priceUp" },
   PRICEDOWN: {
      value: "priceDown",
      mess: "ce01_pmpcore.react.proSortBy.priceDown",
   },
   LATEST: { value: "latest", mess: "ce01_pmpcore.react.proSortBy.latest" },
};

export const BlogSortBy = {
   RECOM: {
      value: "recommand",
      mess: "ce01_pmpcore.react.blogSortBy.recommended",
   },
   POP: { value: "pop", mess: "ce01_pmpcore.react.blogSortBy.pop" },
   LATEST: { value: "latest", mess: "ce01_pmpcore.react.blogSortBy.latest" },
   OLDEST: { value: "oldest", mess: "ce01_pmpcore.react.blogSortBy.oldest" },
};

// PortalConfig move to ThemeVar
export const PortalConfig = {
   minScreenWidth: 700,
   headerHeight: 70,
   menuHeight: 50,
   navigatorHeight: 50,
};

export const PaymentMethods = {
   STRIPE: "stripe",
   FPS: "fps",
   ALIPAY: "alipay",
   WECHAT_PAY: "wechatpay",
   PAYPAL: "paypal",
};

export const Product = {
   MAX_ORDER_ITEM_PER_PRODUCT: 999999,
};

export const BookAttendStatus = {
   NA: "na",
   ATTEND: "attended",
   NOSHOW: "noShow",
};

export const CheckInOptions = {
   NOT_ALLOW: "notAllow",
   EVENT_MANAGER: "eventManager",
   SERVICE_MANAGER: "serviceManager",
};

export const CheckinSTimeOption = {
   ANY: "any",
   HOUR: "hour",
   MINUTE: "min",
};

export const HomeView = {
  ChangePassword: 'changePassword',
  ProductWishList: 'proWishList',
  MyPrepaidCoupon: 'myPrepaidCoupon',
  MyEventSchedule: 'myEventSchedule',
  MyBonusPoints: 'myBonusPoints',
  MyPurchase: 'myPurchase',
  DeliveryAddress: 'deliveryAddress',
  ChangeContact: 'changeContact',
};

export const ProViewType = {
   ProGp: "proGp",
   Service: "service",
   Event: "event",
};

export const BannerDisplayMode = {
   Default: "def",
   Rolling: "rolling",
   Responsive: "responsive",
};

export const BlogPostDisplayMode = {
   Default: "def",
   VerticalListWithImage: "verticalListWithImage",
   VerticalListWithLargeImage: "verticalListWithLargeImage",
   VerticalListWithDate: "verticalListWithDate",
   ColumnListWithImage: "columnListWithImage",
   ColumnListWithSquareImage: "columnListWithSquareImage",
};

export const BlogReadRight = {
   Public: "openToPublic",
   MembersOnly: "membersOnly",
};

export const ViewDefaultClass = " css-view-1dbjc4n css-1dbjc4n";

export const DefaultFont = [
   "none",
   "Arial, sans-serif",
   "Verdana, sans-serif",
   "Helvetica, sans-serif",
   "Tahoma, sans-serif",
   "Trebuchet MS, sans-serif",
   "Times New Roman, serif",
   "Georgia, serif",
   "Garamond, serif",
   "Courier New, monospace",
   "Brush Script MT, cursive",
];

export const ImageType = {
   webPro: "webPro",
   mobilePro: "mobilePro",
   webGallery: "webGallery",
   mobileGallery: "mobileGallery",
};

export const CheckoutComponents = {
  Delivery: 'delivery',
  Payment: 'payment',
  Extra: 'extra',
  Summary: 'summary',
};

export const CouponVouchersOrderBy = {
  SerialNo: { value: 'svfCode', mess: 'ce01_pmpcore.react.serialNo' },
  PurchaseDate: { value: 'svfSourceDate', mess: 'ce01_pmpcore.react.purchaseDate' },
  SvAmount: { value: 'svfIniBal', mess: 'ce01_pmpcore.react.svAmount' },
  Balance: {
    value: 'svBalance',
    mess: 'ce01_pmpcore.react.balance',
  },
  ExpiredDate: { value: 'svExpDate', mess: 'ce01_pmpcore.react.expiredDate' },
};