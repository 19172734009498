import { Component } from 'react';
import { geti18nValue } from '@kopapro/utils/m18';
import { BlockProps } from '@kopapro/components/pages/account/block';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';

export default class Block extends Component<BlockProps> {
  render() {
    const { content } = this.props;

    return (
      <div className="block px-3">
        <SafetyDOM html={geti18nValue(content)} />
      </div>
    );
  }
}
