import meweIcon from '@kopapro/resources/images/meweIcon.png';
import instagramIcon from '@kopapro/resources/images/igIcon.png';
import productPlaceholder from '@kopapro/resources/images/noImage.png';
import placeholder from "@kopapro/resources/images/noImage_icon.png";
import couponPlaceholder from '@kopapro/resources/images/coupon.png';
import loginPlaceholder from '@kopapro/resources/images/login_promotion.png';
import registerPlaceholder from '@kopapro/resources/images/reg_promotion.png';
import refreshIcon from '@kopapro/resources/images/reload.png';
import stripepayIcon from '@kopapro/resources/images/ipayasia_icon.png';
import fpsIcon from '@kopapro/resources/images/fps_en.png';
import pickup from '@kopapro/resources/images/pickup.png';

export const AppImages = {
  meweIcon,
  instagramIcon,
  productPlaceholder,
  placeholder,
  couponPlaceholder,
  loginPlaceholder,
  registerPlaceholder,
  refreshIcon,
  stripepayIcon,
  fpsIcon,
  pickup,
};

