import { OrderType } from '@kopapro-redux/action-types';
import { CommonAction } from '@kopapro-redux/actions/misc';
import { OrderState, OrderExtraInfoField, OrderExtraInfoValue } from '@kopapro-redux/types/order';
import { Dictionary } from '@kopapro-redux/types/utilities';

const initialState: OrderState = {
  updating: false,
  orderId: '',
  discCodes: [],
  items: [],
  amountDetails: {
    totalAmount: 0,
    outstandAmt: 0,
    promoDiscAmt: 0,
    promoDiscDescObj: { en: '' },
    pmSurchargeAmt: 0,
    shipFeeAmt: 0,
    couponAmt: 0,
    serviceSurchargeAmt: 0,
  },
  orderInfo: {
    orderCode: '',
    orderDate: 0,
    orderStatus: 'na',
    payStatus: 'unpaid',
    shipStatus: 'unfulfilled',
    couriers: [],
    customerEmail: '',
    orderRemark: '',
    isPaymentSettled: false,
    isRequirePayment: false,
  },
};

export function handleOrderReceived(nextState: OrderState, payload: any) {
  const { status, entity } = payload;
  if (status) {
    const { data, orderId, discCodes, amtDetail } = entity;
    const { deliveryId, delivery, paymentId, payment, paymentSVs } = entity;
    const { extraViewId, extraView } = entity;
    const inputFormat = extraView?.viewFormat?.inputFormat || {};
    const inputFormatArray: OrderExtraInfoField[] = Object.values(inputFormat) || [];
    return {
      ...nextState,
      items: [...data],
      orderId,
      discCodes,
      amountDetails: { ...amtDetail },
      orderInfo: {
        orderCode: entity.orderCode || '',
        orderDate: entity.orderDate || 0,
        orderStatus: entity.orderStatus || 'na',
        payStatus: entity.payStatus || 'unpaid',
        shipStatus: entity.shipStatus || 'unfulfilled',
        couriers: entity.couriers || [],
        customerEmail: entity.email || '',
        orderRemark: entity.orderRemark || '',
        isPaymentSettled: entity.isPaymentSettled || false,
        isRequirePayment: entity.isRequirePayment || false,
      },
      delivery: { deliveryId: deliveryId, ...delivery },
      payment: { paymentId: paymentId, paymentCoupons: paymentSVs, ...payment },
      extraInfo: { viewId: extraViewId, fields: inputFormatArray },
    };
  }
  return nextState;
}

export function handleOrderExtraReceived(nextState: OrderState, values: Dictionary<OrderExtraInfoValue>) {
  const { extraInfo } = nextState;

  if (extraInfo) {
    const fields = [...extraInfo.fields];
    fields.forEach((field) => {
      const { id } = field;

      if (values && values[id]) {
        field.value = values[id].value;
        field.displayValue = values[id].value;
      }
    });
    extraInfo.fields = [...fields];
    return {
      ...nextState,
      extraInfo: { ...extraInfo },
    };
  }
  return nextState;
}

const orderReducer = (state = initialState, action: CommonAction): OrderState => {
  switch (action.type) {
    case OrderType.ORDER_UPDATED:
      return handleOrderReceived({ ...state, updating: false }, action.payload);

    case OrderType.ORDER_EXTRA_UPDATED:
      return handleOrderExtraReceived({ ...state, updating: false }, action.payload);

    default:
      return state;
  }
};
export default orderReducer;