import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { RootState } from "@kopapro-redux/store";
import { withTranslation, WithTranslation } from "react-i18next";

import { DeliveryMethod } from '@kopapro-redux/types/deliveryMethod';
import { getDeliveryMethodList } from '@kopapro-redux/selectors/entities/deliveryMethods';
import DeliveryMethodList from "@kopapro/components/deliveryMethod/list/list";

interface IProps extends WithTranslation {}

interface StateProps {
  list: DeliveryMethod[];
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    list: getDeliveryMethodList(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type DeliveryMethodListProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DeliveryMethodList));
