import React from 'react';
import { InfoPlateData } from '@kopapro-redux/types/componentSetting';
import { getCustomStyle, geti18nValue } from '@kopapro/utils/m18';
import { Dictionary } from '@kopapro-redux/types/utilities';
import utils from '@kopapro-redux/utils/utils';

function withInfoPlate<T>(
  WrappedComponent: React.ComponentType<T>,
  infoPlateData?: InfoPlateData,
  positionType: 'absolute' | 'relative' = 'absolute'
) {
  return (props: any) => {
    const { infoPlateObj } = infoPlateData!;
    const infoPlateContent = geti18nValue(infoPlateObj);
    let plateStyle: Dictionary<string> = {};
    if (infoPlateData && infoPlateData?.useInfoPlate && utils.isNotEmpty(infoPlateContent)) {
      plateStyle = getCustomStyle(
        { ...infoPlateData, useUdfStyle: true, backgroundColor: infoPlateData?.plateColor },
        true
      );
    } else {
      return <WrappedComponent {...props} />;
    }

    return (
      <div className={`info-plate`}>
        <WrappedComponent {...props} />
        <div className={`info-plate-${positionType} info-plate-${infoPlateData?.animationClass}`}>
          <h5 className={`p-2 text-center`} style={{ ...plateStyle }}>
            {geti18nValue(infoPlateObj)}
          </h5>
        </div>
      </div>
    );
  };
}

export default withInfoPlate;
