import { Component, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import { CartProps } from '@kopapro/components/cart';
import CartItem from '@kopapro/components/cart/cartItem';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import utils from '@kopapro-redux/utils/utils';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import Amounts from '@kopapro/components/cart/amounts';
import Information from '@kopapro/components/cart/information';
import SpinnerButton from '@kopapro/components/commons/spinnerButton';
import GiftRedeemModal from '@kopapro/components/cart/giftRedeemModal';
import { GiftRedemptionDataKey } from '@kopapro-redux/types/cart';
import DefaultModal from '@kopapro/components/commons/modals';
import { geti18nValue, isM18PreviewMode } from '@kopapro-redux/utils/m18';
import Loading from '@kopapro/components/commons/loading';
import ErrorIcon from '@mui/icons-material/Error';
import DiscountCodeSection from '@kopapro/components/checkout/discountCode';
import { cartConfig } from '@kopapro/utils/config';

interface CartState {
  sending: boolean;
  successMessage: string;
  errorMessage: string;
  showRedemptionModal: boolean;
  showGiftModal: boolean;
  showReminderModal: boolean;
  pendingItems: string[];
}

export default class Cart extends Component<CartProps, CartState> {
  defaultState = {
    sending: false,
    errorMessage: '',
    successMessage: '',
    showRedemptionModal: false,
    showGiftModal: false,
    showReminderModal: true,
    pendingItems: [],
  };

  constructor(props: CartProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount(): void {
    if (cartConfig.needRefreshCartInInitial) {
      this.props.refreshCart({});
    }
  }

  componentDidUpdate(prevProps: Readonly<CartProps>, prevState: Readonly<CartState>, snapshot?: any): void {
    if (isM18PreviewMode()) {
      if (prevProps.orderSetup.popReminder !== this.props.orderSetup.popReminder) {
        if (this.props.orderSetup.popReminder) {
          this.showReminderModal();
        } else {
          this.handleConfirmReminderModal();
        }
      }
    }
    this.isValidCart();
  }

  updatePendingItem = (code: string, isModified: boolean) => {
    let pendingItems = [...this.state.pendingItems];

    if (isModified) {
      pendingItems.push(code);
    } else {
      pendingItems = pendingItems.filter((e) => e !== code);
    }
    let unique = pendingItems.filter((v, i, a) => a.indexOf(v) === i);
    this.setState({ pendingItems: [...unique] });
  };

  showRedemptionModal = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showRedemptionModal: true });
  };

  handleCloseRedemptionModal = () => {
    this.setState({ showRedemptionModal: false });
  };

  handleSubmitRedemptionModal = (redeemList: GiftRedemptionDataKey[], callback: Function) => {
    const { applyRedemption } = this.props;
    applyRedemption({ redeemList }, async function (isSuccess: boolean, message = '') {
      if (callback) {
        callback(isSuccess, message);
      }
    });
  };

  showGiftModal = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showGiftModal: true });
  };

  handleCloseGiftModal = () => {
    this.setState({ showGiftModal: false });
  };

  handleSubmitGiftModal = (giftList: GiftRedemptionDataKey[], callback: Function) => {
    const { applyGift } = this.props;
    applyGift({ giftList }, async function (isSuccess: boolean, message = '') {
      if (callback) {
        callback(isSuccess, message);
      }
    });
  };

  showReminderModal = () => {
    this.setState({ showReminderModal: true });
  };

  handleConfirmReminderModal = () => {
    this.setState({ showReminderModal: false });
  };

  handleCloseReminderModal = () => {
    this.props.navigate('/');
  };

  renderTop(): ReactNode {
    const { onePage } = this.props;
    if (onePage) {
      return null;
    }
    return <>{this.renderNav()}</>;
  }

  renderNav(): ReactNode {
    const { t } = this.props;

    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.shoppingCart'), active: true },
    ];
    return <KppBreadcrumb items={items} />;
  }

  renderAmountInfo(): ReactNode {
    return <Amounts />;
  }

  renderInformation(): ReactNode {
    return <Information />;
  }

  handlePrevious = () => {
    const { navigate } = this.props;
    navigate('/');
  };

  isValidCart = (): boolean => {
    const { items, userLoggedIn } = this.props;
    const { sending, errorMessage } = this.state;
    let newErrorMessage = '';
    if (!userLoggedIn) {
      let hasSVPro = items!.some((item) => item.isSV);
      let hasServiceEvent = items!.some((item) => item.isService);

      if (hasSVPro) {
        newErrorMessage = 'ce01_pmpcore.kopapro.react.buySV';
      }
      if (hasServiceEvent) {
        newErrorMessage = 'ce01_pmpcore.kopapro.react.buyServEvent';
      }

      if (sending !== false || errorMessage !== newErrorMessage) {
        this.setState({
          sending: false,
          errorMessage: newErrorMessage,
        });
      }

      if (utils.isNotEmpty(newErrorMessage)) {
        return false;
      }
    }
    return true;
  };

  handleNext = () => {
    const { navigate, checkCart, onePage, onePageCartNext, items, userLoggedIn } = this.props;
    const self = this;
    this.setState({ sending: true, errorMessage: '', successMessage: '' });

    if (!this.isValidCart()) {
      return;
    }

    // dispatch request
    checkCart({}, function (isSuccess: boolean, message: string = '') {
      // update state errorMessage and successMessage
      if (isSuccess) {
        if (onePage && onePageCartNext) {
          onePageCartNext();
        } else {
          navigate('/checkout');
        }
      } else {
        self.setState({ sending: false, errorMessage: message });
      }
    });
  };

  renderFooter(): ReactNode {
    const { t, updating } = this.props;
    const { sending, pendingItems } = this.state;
    return (
      <div className="cart-footer">
        <Button
          variant="btn btn-link"
          className="cart-footer-btn"
          disabled={updating || sending}
          onClick={() => this.handlePrevious()}>
          {t('ce01_pmpcore.react.browseOther')}
        </Button>
        <SpinnerButton
          variant="main"
          className="cart-footer-btn"
          disabled={updating || sending || pendingItems.length > 0}
          spinning={sending}
          onClick={() => this.handleNext()}>
          {t('ce01_pmpcore.react.next')}
        </SpinnerButton>
      </div>
    );
  }

  renderEmpty(): ReactNode {
    const { t } = this.props;

    const homeLink = (
      <Link to={`/`}>
        <h5>{t('ce01_pmpcore.react.shoppingCartMsg.linkText')}</h5>
      </Link>
    );
    return (
      <div>
        <h1>{t('ce01_pmpcore.react.shoppingCart')}</h1>
        <h5>{t('ce01_pmpcore.react.shoppingCartNoItem')}</h5>
        <Trans t={t} i18nKey="ce01_pmpcore.kopapro.react.shoppingCartMsg" components={[homeLink]} />
      </div>
    );
  }

  renderError = () => {
    const { t, cartMessage, location } = this.props;
    const { errorMessage } = this.state;

    let errorComponent = <ErrorMessage message={t(cartMessage || errorMessage)} />;
    if (
      errorMessage === 'ce01_pmpcore.kopapro.react.buySV' ||
      errorMessage === 'ce01_pmpcore.kopapro.react.buyServEvent'
    ) {
      const redirect = location.pathname;
      const login = <Link to={`/login?redirect=${redirect}`}>{t('mac_rnbase.react.login')}</Link>;
      errorComponent = (
        <div className="text-danger">
          <ErrorIcon /> <Trans t={t} i18nKey={errorMessage} components={[login]} />
        </div>
      );
    }
    return errorComponent;
  };

  renderMain(): ReactNode {
    const { t, items, updating } = this.props;
    const { redemptionList, giftList } = this.props;
    const { sending, successMessage } = this.state;

    return (
      <Card className="mt-3">
        <Card.Body className="p-2 p-sm-3">
          <Card.Title>{t('ce01_pmpcore.react.cart')}</Card.Title>
          <hr />

          <ListGroup className="mb-3">
            {items?.map((item, index) => {
              const id = `${item.proId}-${item.lot}`;
              const key = `${id}-${index}`;

              return <CartItem key={key} item={item} updatePending={this.updatePendingItem} />;
            })}
          </ListGroup>
          {cartConfig.showDiscountCodeInput && (
            <Row>
              <Col sm={{ span: 6, offset: 6 }} xl={{ span: 4, offset: 8 }}>
                <DiscountCodeSection allowEditDiscount={true} hideErrorMessage={true} />
              </Col>
            </Row>
          )}
          <div className="d-flex flex-column flex-sm-row  justify-content-between">
            <div className="mb-3 mb-sm-0">
              {redemptionList && redemptionList.length > 0 && (
                <Button
                  variant="outline-info"
                  disabled={updating || sending}
                  onClick={(e) => this.showRedemptionModal(e)}>
                  {t('ce01_pmpcore.react.redemption')}
                </Button>
              )}
              {giftList && giftList.length > 0 && (
                <Button
                  className="ms-2"
                  variant="outline-info"
                  disabled={updating || sending}
                  onClick={(e) => this.showGiftModal(e)}>
                  {t('ce01_pmpcore.react.gift')}
                </Button>
              )}
            </div>

            {this.renderAmountInfo()}
          </div>

          <Row>{this.renderInformation()}</Row>

          {this.renderError()}
          <SuccessMessage message={t(successMessage)} />

          {this.renderFooter()}
        </Card.Body>
      </Card>
    );
  }

  render(): ReactNode {
    const { t, orderSetup, items, retrieving, onePage } = this.props;
    let modals = null;
    let content = this.renderMain();

    if (retrieving) {
      content = <Loading />;
    } else if (utils.isEmptyList(items)) {
      content = this.renderEmpty();
    } else {
      modals = (
        <>
          <GiftRedeemModal
            title={'ce01_pmpcore.react.redemption'}
            list={this.props.redemptionList}
            show={this.state.showRedemptionModal}
            onSubmitHandler={this.handleSubmitRedemptionModal}
            onCloseHandler={this.handleCloseRedemptionModal}
          />
          <GiftRedeemModal
            title={'ce01_pmpcore.react.gift'}
            list={this.props.giftList}
            show={this.state.showGiftModal}
            isGift={true}
            onSubmitHandler={this.handleSubmitGiftModal}
            onCloseHandler={this.handleCloseGiftModal}
          />
          {orderSetup.enableCheckOutReminder && (
            <DefaultModal
              show={this.state.showReminderModal}
              title={t('ce01_pmpcore.kopapro.react.reminder')}
              body={geti18nValue(orderSetup.checkOutReminder)}
              isBackdrop={true}
              scrollable={true}
              showConfirmButton={true}
              onConfirmHandler={this.handleConfirmReminderModal}
              showCloseButton={true}
              onCloseHandler={this.handleCloseReminderModal}
              closeButtonText={t('ce01_pmpcore.kopapro.react.backToHome')}
            />
          )}
        </>
      );
    }

    return (
      <div className="page-container container-md px-xl-5 cart">
        {!onePage && <ScrollToTopOnMount />}
        {this.renderTop()}
        {content}
        {modals}
      </div>
    );
  }
}
