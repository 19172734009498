import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import Payment from '@kopapro/components/order/payment/payment';
import { getOrderPayment } from '@kopapro-redux/selectors/entities/order';
import { OrderPayment } from '@kopapro-redux/types/order';

interface IProps extends WithTranslation {}

interface StateProps {
  payment?: OrderPayment;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    payment: getOrderPayment(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type PaymentProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Payment));
