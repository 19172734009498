import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import Info from '@kopapro/components/order/info/info';
import { getOrderDelivery, getOrderInfo } from '@kopapro-redux/selectors/entities/order';
import { OrderDelivery, OrderInfo } from '@kopapro-redux/types/order';
import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';

interface IProps extends WithTranslation {}

interface StateProps {
  userLoggedIn: boolean;
  orderInfo?: OrderInfo;
  delivery?: OrderDelivery;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    userLoggedIn: userLoggedIn(state),
    orderInfo: getOrderInfo(state),
    delivery: getOrderDelivery(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type InfoProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Info));
