import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import CommentModal from '@kopapro/components/order/items/commentModal/commentModal';
import { OrderItem } from '@kopapro-redux/types/order';
import { addProductComment } from '@kopapro-redux/actions/order';
interface ModalProps {
  show: boolean;
  onCloseHandler?: Function;
}

interface IProps extends WithTranslation, ModalProps {
  orderId: string;
  item: OrderItem;
}

interface StateProps {}

interface DispatchProps {
  addComment: typeof addProductComment;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  addComment: addProductComment,
};

export type CommentModalProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CommentModal));
