import React, { Component, ReactNode } from 'react';
import { BlogDetailProps } from '@kopapro/components/blog/detail/index';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb';
import YouTube from '@kopapro/components/commons/youtube/youtube';
import ShopImage from '@kopapro/components/commons/shopImage';
import { getCustomStyle, geti18nValue } from '@kopapro/utils/m18';
import utils from '@kopapro/utils/utils';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import { Row } from 'react-bootstrap';
import DefaultModal from '@kopapro/components/commons/modals';
import ShareButtons from '@kopapro/components/commons/shareButtons';
import { BlogDetail as BlogDetailType } from '@kopapro-redux/types/blog';
import Placeholder from 'react-bootstrap/Placeholder';

interface BlogDetailStats {
  blogDetail: BlogDetailType;
}

export default class BlogDetail extends Component<BlogDetailProps, BlogDetailStats> {
  defaultState: BlogDetailStats = {
    blogDetail: {
      id: 0,
      lastModifyDate: 0,
      blogId: 0,
      code: '',
      headerType: 'text',
      photoCode: '',
      youtubeVideoId: '',
      title: '',
      detail: '',
      author: '',
      authorLogo: '',
      coverText: '',
      textSize: 'm',
      textColor: '',
      textBackgroundColor: '',
      hashTags: [],
      showYearMonth: false,
      readRight: 'openToPublic',
      relatedBlogCate: [],
      memberOnlyMsg: {},
      titleObj: {},
      detailObj: {},
    },
  };

  constructor(props: BlogDetailProps) {
    super(props);
    this.state = this.defaultState;
  }

  callbackFromRequest = (blogDetail: BlogDetailType) => {
    this.setState({ blogDetail: blogDetail });
  };

  componentDidMount = () => {
    const { params, loadBlogDetail } = this.props!;
    loadBlogDetail({ blogId: params['blogId'] }, this.callbackFromRequest);
  };

  componentDidUpdate = () => {
    const { blogDetail } = this.state;
    const title = geti18nValue(blogDetail.titleObj);
    utils.setDocumentTitle(title);
  };

  getFontSize = (textSize: 'm' | 'l'): number => {
    switch (textSize) {
      case 'l':
        return 70;
      default:
        return 50;
    }
  };

  renderTop = (): ReactNode => {
    return <>{this.renderNav()}</>;
  };

  renderNav = (): ReactNode => {
    const { t } = this.props;
    const { titleObj, relatedBlogCate } = this.state.blogDetail;

    let blogCateCode = '';
    let blogCateDesc = t('ce01_pmpcore.react.blog');
    if (this.props.blogCategories) {
      const categoryId = relatedBlogCate[0];
      const category = Object.values(this.props.blogCategories).find((item) => {
        return item.m18Id === categoryId;
      });
      blogCateCode = category?.code || '';
      blogCateDesc = geti18nValue(category?.desc);
    }

    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: blogCateDesc, to: `/blogs/${blogCateCode}`, active: false },
      { text: geti18nValue(titleObj), active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  renderHeader = (isRestricted: boolean): ReactNode => {
    const { headerType, youtubeVideoId, photoCode, coverText, textSize, textColor, textBackgroundColor } =
      this.state.blogDetail;

    if (isRestricted) {
      return (
        <div className={`header text-center col-12 text-center`}>
          <div className={`h-100 d-flex justify-content-center align-items-center`}>
            <Placeholder className="h-100 w-100" as="div" animation="glow">
              <Placeholder className="h-100 w-100" xs={12} />
            </Placeholder>
          </div>
        </div>
      );
    }

    let headerComp: ReactNode = <></>;
    switch (headerType) {
      case 'youtube':
        headerComp = <YouTube width={`100%`} height={`100%`} videoId={youtubeVideoId} />;
        break;
      case 'photo':
        if (utils.isEmpty(photoCode)) {
          headerComp = <div style={{ backgroundColor: 'lightgray', height: '100%' }} />;
        } else {
          headerComp = <ShopImage src={photoCode} width={`100%`} height={`100%`} />;
        }
        break;
      case 'text':
        let textStyle = getCustomStyle(
          {
            useUdfStyle: true,
            fontSize: this.getFontSize(textSize),
            fontColor: textColor,
          },
          false
        );

        headerComp = (
          <div
            style={{ backgroundColor: textBackgroundColor }}
            className={`h-100 d-flex justify-content-center align-items-center text-break p-2`}>
            <h5 style={{ ...textStyle }}> {coverText}</h5>
          </div>
        );
        break;
      default:
        return <></>;
    }
    return <div className={`header text-center col-12`}>{headerComp}</div>;
  };

  renderTitle = (): ReactNode => {
    const { titleObj } = this.state.blogDetail;
    return <h3 className={`fw-bold mt-4`}>{this.getDisplayContent(geti18nValue(titleObj))}</h3>;
  };

  renderHashTags = (isRestricted: boolean): ReactNode => {
    const { hashTags, hashTagsObj } = this.state.blogDetail;
    if (isRestricted) {
      return <></>;
    }
    if (utils.isEmptyList(hashTags) && utils.isEmptyList(hashTagsObj)) {
      return <></>;
    }
    return (
      <div className={`mb-4`}>
        {hashTags.map((tag) => {
          return (
            <span key={`blog-hashtag-${tag}`} className={`ms-1 me-1`}>
              {'#' + tag}
            </span>
          );
        })}
        {hashTagsObj &&
          hashTagsObj.map((tagObj) => {
            let tag = geti18nValue(tagObj);
            return (
              <span key={`blog-hashtagobj-${tag}`} className={`ms-1 me-1`}>
                {'#' + tag}
              </span>
            );
          })}
      </div>
    );
  };

  getDisplayContent = (text: string): ReactNode => {
    const { userLoggedIn } = this.props;
    const { readRight } = this.state.blogDetail;
    const isRestricted = readRight === 'membersOnly' && !userLoggedIn;

    if (isRestricted) {
      return (
        <Placeholder as="span" animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
      );
    }
    return text;
  };

  renderAuthor = (isRestricted: boolean): ReactNode => {
    const { t } = this.props;
    const { author, authorLogo, lastModifyDate, title } = this.state.blogDetail;
    const modifyDate = t('{ date, full }', { date: lastModifyDate });
    let logo = <ShopImage src={authorLogo} width={50} height={50} className={`cover rounded-circle`} />;
    if (isRestricted) {
      logo = (
        <Placeholder as="div" animation="glow" className={` rounded-circle`}>
          <Placeholder className="img-placeholder" xs={12} style={{ minHeight: 50, width: 50 }} />
        </Placeholder>
      );
    }

    return (
      <div className={`d-flex justify-content-between flex-column flex-md-row`}>
        <div className={`d-flex align-items-center`}>
          {logo}
          <div className={`d-flex flex-column ms-2`}>
            <label>{this.getDisplayContent(author)}</label>
            <label>{this.getDisplayContent(modifyDate)}</label>
          </div>
        </div>
        <ShareButtons url={window.location.href} title={title} />
      </div>
    );
  };

  renderDetail = (isRestricted: boolean): ReactNode => {
    if (isRestricted) {
      return (
        <Placeholder as="p" animation="glow">
          <Placeholder xs={12} />
          <Placeholder xs={12} />
          <Placeholder xs={12} />
        </Placeholder>
      );
    }
    const { detailObj } = this.state.blogDetail;
    return <SafetyDOM html={geti18nValue(detailObj)} />;
  };

  render(): ReactNode {
    if (!this.state.blogDetail) {
      return <></>;
    }
    const { userLoggedIn, t, navigate } = this.props;
    const { readRight, memberOnlyMsg } = this.state.blogDetail;
    const isRestricted = readRight === 'membersOnly' && !userLoggedIn;

    return (
      <div className={`blog-detail page-container container px-xl-5`}>
        <ScrollToTopOnMount />
        {this.renderTop()}
        <div className={`card`}>
          <div className={`card-body`}>
            <Row> {this.renderAuthor(isRestricted)} </Row>
            <Row> {this.renderTitle()} </Row>
            <Row> {this.renderHashTags(isRestricted)} </Row>
            <Row className={`justify-content-center`}>{this.renderHeader(isRestricted)}</Row>
            <hr />
            {this.renderDetail(isRestricted)}
          </div>
        </div>
        <DefaultModal
          show={isRestricted}
          body={<SafetyDOM html={geti18nValue(memberOnlyMsg)} />}
          centered={true}
          isBackdrop={true}
          showConfirmButton={true}
          confirmButtonText={t('ce01_pmpcore.react.memberLogin')}
          onConfirmHandler={() => {
            const redirect = this.props.location.pathname;
            navigate(`/login?redirect=${redirect}`);
          }}
          showCloseButton={true}
          closeButtonText={t('ce01_pmpcore.react.later')}
          onCloseHandler={() => {
            navigate(-1);
          }}
        />
      </div>
    );
  }
}
