import React, { Component, ReactNode } from 'react';
import { AssistiveTouchProps } from '@kopapro/components/preview/assistiveTouch';
import { M18RnMeta } from '@kopapro-redux/utils/constant';
import Draggable from 'react-draggable';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { triggerM18Action } from '@kopapro-redux/utils/m18';
import utils from '@kopapro-redux/utils/utils';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

interface AssistiveTouchState {}

type TabItem = {
  label: string;
  path: string;
  m18CompId: string;
  relatedPath?: string[];
  requiredLogin?: boolean;
  disableWhenLoggedIn?: boolean;
};

const tabList: TabItem[] = [
  { label: 'ce01_pmpcore.react.home', path: '/', m18CompId: M18RnMeta.SHOP },
  {
    label: 'ce01_pmpcore.react.contactUs',
    path: '/contact-us',
    m18CompId: M18RnMeta.CONTACTUSPAGE,
  },
  {
    label: 'ce01_pmpcore.react.subscribe',
    path: '/subscribe',
    m18CompId: M18RnMeta.NEWSLETTERPAGE,
  },
  {
    label: 'ce01_pmpcore.react.memberLogin',
    path: '/login',
    m18CompId: M18RnMeta.LOGINVIEW,
    disableWhenLoggedIn: true,
  },
  {
    label: 'ce01_pmpcore.react.memberSignup',
    path: '/register',
    m18CompId: M18RnMeta.REGVIEW,
    disableWhenLoggedIn: true,
  },
  {
    label: 'ce01_pmpcore.react.memberCenter',
    path: '/account',
    m18CompId: M18RnMeta.HOMEVIEW,
    relatedPath: [
      // '/account',
      '/account/orders',
      '/account/coupons',
      '/account/wishlist',
      '/account/address',
      '/account/password',
      '/account/information',
      '/account/form',
      '/account/reward',
    ],
    requiredLogin: true,
  },
  {
    label: 'ce01_pmpcore.react.orderPage',
    path: '/cart',
    m18CompId: M18RnMeta.ORDERVIEW,
    relatedPath: ['/checkout', '/checkout/summary'],
  },
  {
    label: 'ce01_pmpcore.kopapro.react.orderPageExtra',
    path: '/preview/checkout-extra',
    m18CompId: M18RnMeta.ORDERVIEW,
  },
];

export default class AssistiveTouch extends Component<AssistiveTouchProps, AssistiveTouchState> {
  defaultState = {};

  draggableRef: React.RefObject<any>;

  constructor(props: AssistiveTouchProps) {
    super(props);
    this.state = this.defaultState;
    this.draggableRef = React.createRef();
  }

  componentDidUpdate(prevProps: Readonly<AssistiveTouchProps>, prevState: Readonly<AssistiveTouchState>): void {
    const previousPath = prevProps.location.pathname;
    const newPath = this.props.location.pathname;
    if (utils.isNotEmpty(newPath) && previousPath !== newPath) {
      const oldItem = this.getTabItem(previousPath);
      const item = this.getTabItem(newPath);
      if (item) {
        if (oldItem && oldItem.m18CompId === item.m18CompId) {
          return;
        }
        this.editComponent(item.m18CompId, item.path);
      }
    }
  }

  editComponent = (m18CompId: string, path: string) => {
    triggerM18Action(`portalAction_${m18CompId}`);
    this.props.navigate(path);
  };

  getTabItem = (locationPath: string): TabItem | undefined => {
    const item = tabList.find((item) => {
      if (item.path === locationPath || (item.relatedPath && item.relatedPath.includes(locationPath))) {
        return true;
      }
      return false;
    });
    return item;
  };

  isDisabled = (item: TabItem) => {
    const { userLoggedIn } = this.props;

    if (!userLoggedIn && item.requiredLogin) {
      return true;
    } else if (userLoggedIn && item.disableWhenLoggedIn) {
      return true;
    }

    return false;
  };

  renderItem = (item: TabItem, index: number) => {
    const { t } = this.props;
    const isDisabled = this.isDisabled(item);

    const dropdownItem = (
      <Dropdown.Item
        key={`${item.m18CompId}-${index}`}
        disabled={isDisabled}
        onClick={() => this.editComponent(item.m18CompId, item.path)}>
        {t(item.label)}
      </Dropdown.Item>
    );

    if (!isDisabled) {
      return dropdownItem;
    }

    let tooltipMessage = 'ce01_pmpcore.kopapro.react.preview.askLogin';
    if (item.disableWhenLoggedIn) {
      tooltipMessage = 'ce01_pmpcore.kopapro.react.preview.askLogout';
    }

    return (
      <OverlayTrigger
        placement={'left'}
        key={`${item.m18CompId}-${index}`}
        overlay={<Tooltip className="preview-tooltip">{t(tooltipMessage)}</Tooltip>}>
        <span className="d-inline-block">{dropdownItem}</span>
      </OverlayTrigger>
    );
  };

  render(): ReactNode {
    const { t } = this.props;
    const locationPath = this.props.location.pathname;
    const chosen = this.getTabItem(locationPath);

    return (
      <Draggable nodeRef={this.draggableRef}>
        <DropdownButton
          ref={this.draggableRef}
          variant="main"
          menuVariant="dark"
          className="assistive-touch"
          style={{ zIndex: 100000 }}
          title={t(chosen?.label || 'ce01_pmpcore.kopapro.react.otherPage')}>
          {tabList.map(this.renderItem)}
        </DropdownButton>
      </Draggable>
    );
  }
}
