import ScrollToTopOnMount from "@kopapro/template/ScrollToTopOnMount";
import React, { Component, Fragment } from 'react';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb';
import { ProductListProps } from '@kopapro/components/products/productList';
import { getCategoryName, getWindowScrollTop } from '@kopapro/utils/m18';
import { ProSortBy } from '@kopapro/utils/constants/constants';
import FilterMenu from '@kopapro/components/products/productList/filters';
import Accordion from 'react-bootstrap/Accordion';
import ProductItem from '@kopapro/components/products/item';
import { QueryList } from '@kopapro-redux/types/general';
import { Product } from '@kopapro-redux/types/products';
import Pager from '@kopapro/components/commons/pager';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { productListConfig as config } from '@kopapro/utils/config';
import utils from '@kopapro/utils/utils';
import Card from 'react-bootstrap/Card';
import classnames from 'classnames';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import { geti18nValue } from '@kopapro-redux/utils/m18';

interface ProductListState {
  page: number;
  count: number;
  sortBy: string;
  pageSize: number;
  query: QueryList<Product> | undefined;
  items: Product[];
  flavorId: number;
  weightId: number;
  excludeProduct: number[];
  notDisplayList: number[];
}

export default class ProductList extends Component<ProductListProps, ProductListState> {
  usePagination = config.usePagination;
  breakpoint = 992;
  fetching = false;
  _isMounted = false;
  lastWindowWidth = 0;
  defaultState = {
    page: 0,
    count: 0,
    pageSize: config.pageSize,
    query: undefined,
    sortBy: 'pop',
    items: [],
    flavorId: 0,
    weightId: 0,
    excludeProduct: [],
    notDisplayList: [],
  };

  listRef: React.RefObject<any>;

  constructor(props: ProductListProps) {
    super(props);
    this.state = { ...this.defaultState, pageSize: this.getPageSize(), sortBy: this.getSortBy() };
    this.listRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadProducts();
    if (!this.usePagination) {
      document.addEventListener('scroll', this.handleScroll);
    } else {
      window.addEventListener('resize', this.handleResize);
    }
  }

  isSearchResultPage = () => {
    const { isSearch } = this.props;
    return utils.isDefined(isSearch) && isSearch === true;
  };

  componentDidUpdate(prevProps: ProductListProps) {
    const { categoryCode, keyword, categoryId } = this.props;
    const { categoryCode: prevCategoryCode, keyword: prevKeyword, categoryId: prevCategoryId } = prevProps;
    if (prevCategoryCode !== categoryCode || prevKeyword !== keyword || prevCategoryId !== categoryId) {
      this.setState({ page: 0 });
      this.loadProducts({ page: 0 });
    }

    // preview mode, col, row changed
    const { lite, column, row } = this.props;
    const { column: prevColumn, row: prevRow } = prevProps;

    if (lite && (column !== prevColumn || row !== prevRow)) {
      const self = this;
      const newState = { pageSize: this.getPageSize(), page: 0 };
      this.setState({ ...newState }, function () {
        self.loadProducts({ page: 0 });
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  getSortBy = () => {
    const { proSortBy } = this.props;
    return proSortBy || 'pop';
  };

  getPageSize = () => {
    const { lite, column, row } = this.props;
    if (lite) {
      let pageSize = 8;

      if (utils.isNumber(column) && utils.isNumber(row)) {
        pageSize = utils.getSafetyNumber(column) * utils.getSafetyNumber(row);
      }
      return pageSize;
    }
    if (window.innerWidth >= this.breakpoint) {
      return config.pageSize;
    }
    return config.mobilePageSize;
  };

  onPageChange = (newPage: number) => {
    this.setState({ ...this.state, page: newPage });
    const { listRef, usePagination } = this;
    this.loadProducts({ page: newPage }, function () {
      if (usePagination) {
        window.scrollTo(0, listRef.current.offsetTop - 50);
      }
    });
  };

  onSortByChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const newValue = e.currentTarget.value;
    this.setState({ ...this.state, sortBy: newValue, page: 0 });
    this.loadProducts({ sortBy: newValue });
  };

  onFilterApplied = () => {
    this.setState({ page: 0 });
    this.loadProducts();
  };

  loadProducts = (newState = {}, callback?: Function) => {
    const { keyword, navigate } = this.props;

    const query = {
      categoryId: this.props.categoryId,
      code: this.props.categoryCode,
      pageSize: this.state.pageSize,
      sortBy: this.state.sortBy,
      page: this.state.page,
      keyword: this.isSearchResultPage() ? keyword : '',
    };
    const batchCallback = (data: QueryList<Product> | Error, errorParams: any) => {
      this.fetching = false;
      if (data instanceof Error) {
        if (errorParams.redirect) {
          navigate('/categories');
        }
        return;
      }
      this.onFetchHandler(data as QueryList<Product>);
      if (callback) {
        callback();
      }
      this.fetching = false;
    };
    this.fetching = true;
    this.props.loadProducts({ ...query, ...newState }, batchCallback);
  };

  onFetchHandler = (result: QueryList<Product>) => {
    const { page, items } = this.state;
    const { data } = result;
    if (this._isMounted) {
      if (!this.usePagination && page !== 0) {
        this.setState({ query: result, items: [...items, ...data] });
      } else {
        this.setState({ query: result, items: data });
      }
    }
  };

  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  handleScroll = (e: Event): void => {
    if (!this.isSearchResultPage() && !this.props.lite) {
      const { query, items } = this.state;
      const scroll = window.innerHeight + getWindowScrollTop();
      const ref = this.listRef.current.offsetTop + this.listRef.current.offsetHeight;

      const isScrolledToBottom = scroll >= ref;
      const canFetchMoreData = query && query.querySize > items.length;

      if (!this.fetching && isScrolledToBottom && canFetchMoreData) {
        this.onPageChange(this.state.page + 1);
      }
    }
  };

  handleResize = (e: Event): void => {
    if (!this.props.lite) {
      const isChangedtoLargerScreen = window.innerWidth >= this.breakpoint && this.lastWindowWidth < this.breakpoint;
      const isChangedtoSmallScreen = window.innerWidth < this.breakpoint && this.lastWindowWidth >= this.breakpoint;
      const { pageSize, mobilePageSize } = config;
      const currentPage = this.state.page;

      if (isChangedtoLargerScreen) {
        const newPage = Math.ceil((currentPage * mobilePageSize) / pageSize);
        this.setState({ pageSize, page: Math.max(0, newPage - 1) }, this.loadProducts);
      } else if (isChangedtoSmallScreen) {
        const newPage = Math.ceil((currentPage * pageSize) / mobilePageSize);
        this.setState({ pageSize: mobilePageSize, page: Math.max(0, newPage - 1) }, this.loadProducts);
      }

      this.lastWindowWidth = window.innerWidth;
    }
  };

  renderNav = () => {
    const { t, category, keyword } = this.props;
    let categoryName = getCategoryName(category);
    if (category.code === 'All') {
      categoryName = t('ce01_pmpcore.react.all');
    }

    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.proCategory'), to: '/categories', active: false },
      {
        text: this.isSearchResultPage() ? `${t('ce01_pmpcore.react.searchResults')} ${keyword}` : categoryName,
        to: '',
        active: true,
      },
    ];
    return <KppBreadcrumb items={items} />;
  };

  renderLeft = () => {
    const { categoryCode } = this.props;
    if (!config.showFilter) {
      return null;
    }

    return (
      <div className="d-none d-lg-block col-lg-3">
        <div className="border shadow-sm">
          <FilterMenu
            categoryCode={categoryCode}
            onFilterApplied={this.onFilterApplied}
            hideCategory={this.isSearchResultPage()}
          />
        </div>
      </div>
    );
  };

  getEmptyMessage = () => {
    if (this.isSearchResultPage()) {
      return 'ce01_pmpcore.react.search.noResult';
    }
    return 'ce01_pmpcore.react.emptyRecord';
  };

  renderSorting = () => {
    const { t } = this.props;
    if (this.isSearchResultPage()) {
      return null;
    }
    return (
      <div className="row mb-3">
        <Form.Group as={Col} xs lg={4} className="d-lg-block" controlId="sortBy">
          {/* <Form.Label>{t('ce01_pmpcore.react.proSortBy')}</Form.Label> */}
          <Form.Select
            className="kopapro-select"
            defaultValue={this.props.proSortBy}
            onChange={(e) => this.onSortByChange(e)}>
            {Object.values(ProSortBy).map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {t(item.mess)}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </div>
    );
  };

  renderPagination = () => {
    if (this.usePagination && !this.isSearchResultPage()) {
      const { t } = this.props;
      let { page, pageSize, query } = this.state;
      const queryResult = query || { querySize: 0, data: [] };
      const { querySize: size } = queryResult;

      const indexOfLastRecord = (page + 1) * pageSize;
      const indexOfFirstRecord = indexOfLastRecord - pageSize;

      return (
        <div className="d-flex align-items-center ">
          <span className="text-muted ">
            {t('ce01_pmpcore.kopapro.react.pagination.showNumber', {
              currentFirstRecord: `${size <= 0 ? 0 : indexOfFirstRecord + 1}`,
              currentLastRecord: `${Math.min(indexOfLastRecord, size)}`,
              size,
            })}
          </span>
          <nav aria-label="Page navigation" className="ms-auto">
            <Pager current={page} itemPerPage={pageSize} total={size} onPageChange={this.onPageChange} />
          </nav>
        </div>
      );
    }
  };

  getDynamicRow = () => {
    const { lite, column, isDefault } = this.props;
    let columnsPerRow = lite ? 4 : 3;
    // from 1 to 5 in theme builder
    if (column) {
      columnsPerRow = column;
    }

    if (lite && isDefault) {
      return `row-cols-2 row-cols-md-${columnsPerRow}`;
    }

    return `row-cols-${columnsPerRow} custom-row-cols`;
  };

  renderItems = () => {
    let { lite, column, row } = this.props!;
    const { items: products } = this.state;
    let liteStyle = this.getDynamicRow();

    let list = products.map((product, i) => {
      console.log(product);
      return <ProductItem key={product.code} code={product.code} imageHeight="unset" />;
    });
    if (lite && column && row) {
      const col = column ? column : 2; // prevent typescript checking
      list = Array.from(Array(row)).map((_, i) => {
        const productsPerRow = products.slice(i * col, col * (i + 1));
        if (productsPerRow.length === 0) {
          return <Fragment key={i}></Fragment>;
        }
        return (
          <div
            key={i}
            className={classnames('row g-3 mb-4 flex-shrink-0 justify-content-center', { [liteStyle]: lite })}>
            {productsPerRow.map((product, i) => {
              return <ProductItem key={product.code} code={product.code} imageHeight="unset" />;
            })}
          </div>
        );
      });
      return <div ref={this.listRef}>{list}</div>;
    }

    const cols = config.showFilter ? 3 : 4;
    const colsClass = `row-cols-2 row-cols-md-${cols}`;

    return (
      <div
        ref={this.listRef}
        className={classnames('row g-3 mb-4 flex-shrink-0', {
          [colsClass]: !lite,
          [liteStyle]: lite,
        })}>
        {list}
      </div>
    );
  };

  render() {
    const { t, categoryCode, lite, category } = this.props;
    const { items: products } = this.state;
    const showFilter = config.showFilter;
    let items = this.renderItems();

    if (lite) {
      return <div className="product-list-container lite">{items}</div>;
    }

    if (!category) {
      return null;
    }
    return (
      <div className="product-list-container page-container container px-xl-5">
        <ScrollToTopOnMount />
        {this.renderTop()}
        {/* Filter Menu Top for mobile / tablet device */}

        {showFilter && (
          <div className="row mb-3 d-block d-lg-none">
            <div className="col-12">
              <Accordion defaultActiveKey="">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{t('ce01_pmpcore.react.filter')}</Accordion.Header>
                  <Accordion.Body>
                    <FilterMenu
                      categoryCode={categoryCode}
                      onFilterApplied={this.onFilterApplied}
                      hideCategory={this.isSearchResultPage()}
                      isMobile={true}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        )}
        <div className="product-list row">
          {/* Filter Menu Left */}
          {this.renderLeft()}

          <div className={classnames({ 'col-lg-9': showFilter, 'col-lg-12': !showFilter })}>
            <div className="d-flex flex-column h-100">
              <SafetyDOM html={geti18nValue(category.header)} />
              {this.renderSorting()}
              <SafetyDOM html={geti18nValue(category.eshopDesc)} />
              {!this.fetching && utils.isEmptyList(products) && <Card body> {t(this.getEmptyMessage())}</Card>}
              {items}
              {/* pagination */}
              {this.renderPagination()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
