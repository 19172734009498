import React from 'react';
import { DeliveryMethodDetailProps } from '@kopapro/components/deliveryMethod/detail';
import { geti18nValue, resolveImageURL } from '@kopapro/utils/m18';
import ShopImage from '@kopapro/components/commons/shopImage';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import { ImageType } from '@kopapro/utils/constants/constants';

type State = {};

class DeliveryMethodDetail extends React.Component<DeliveryMethodDetailProps> {
  state: State = {};

  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t, item } = this.props;

    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.deliveryMethods'), to: '/delivery-methods', active: false },
      { text: geti18nValue(item.desc), to: '', active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  render() {
    const { item } = this.props;
    if (!item) {
      return null;
    }

    const { term: body, image: imagePath, desc } = item;
    let bodyStr: string = '';

    if (body) {
      bodyStr = geti18nValue(body);
    }

    return (
      <div className="page-container container px-xl-5">
        <ScrollToTopOnMount />
        {this.renderTop()}
        <div className="card shadow-sm">
          <div className="card-body">
            <div className="text-center">
              <ShopImage src={resolveImageURL(imagePath, false, ImageType.webPro)} className="mw-100 mb-3" />
            </div>
            <h5 className="card-title text-center text-dark text-truncate">{geti18nValue(desc)}</h5>
            <SafetyDOM html={bodyStr} />
          </div>
        </div>
      </div>
    );
  }
}

export default DeliveryMethodDetail;
