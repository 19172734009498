import { OrderType } from '@kopapro-redux/action-types';
import { CommonAction } from '@kopapro-redux/actions/misc';

export const getOrder = (payload: any, callback?: Function): CommonAction => {
  return {
    type: OrderType.GET_ORDER_REQUEST,
    payload,
    callback,
  };
};

// from server
export const orderUpdated = (payload: any, callback?: Function): CommonAction => {
  return {
    type: OrderType.ORDER_UPDATED,
    payload,
    callback,
  };
};

export const orderExtraUpdated = (payload: any, callback?: Function): CommonAction => {
  return {
    type: OrderType.ORDER_EXTRA_UPDATED,
    payload,
    callback,
  };
};

export const cancelOrder = (payload: any, callback?: Function): CommonAction => {
  return {
    type: OrderType.CANCEL_ORDER_REQUEST,
    payload,
    callback,
  };
};

export const processOnlinePayment = (payload: any, callback?: Function): CommonAction => {
  return {
    type: OrderType.GET_ONLINE_PAYMENT_REQUEST,
    payload,
    callback,
  };
};

export const approvePayPal = (payload: any, callback?: Function): CommonAction => {
  return {
    type: OrderType.APPROVE_PAYPAL_REQUEST,
    payload,
    callback,
  };
};

export const addProductComment = (
  payload: { orderId: string; proId: number; score: number; comment: string },
  callback?: Function
): CommonAction => {
  return {
    type: OrderType.ADD_PRODUCT_COMMENT_REQUEST,
    payload,
    callback,
  };
};

export const fetchSinglePageOrder = (payload: { orderToken: string }, callback?: Function): CommonAction => {
  return {
    type: OrderType.GET_SINGLE_PAGE_ORDER,
    payload,
    callback,
  };
};