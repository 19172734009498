import React, { Component, Fragment, ReactNode } from 'react';
import { SearchProps } from '@kopapro/components/header/components/search';
import classnames from 'classnames';
import Nav from 'react-bootstrap/Nav';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import SearchIcon from '@mui/icons-material/Search';
import { Option, TypeaheadManagerChildProps } from 'react-bootstrap-typeahead/types/types';
import { RenderMenuProps } from 'react-bootstrap-typeahead/types/components/Typeahead';
import { QueryList } from '@kopapro/kopapro-redux/types/general';
import { Product, ProductGroup } from '@kopapro/kopapro-redux/types/products';
import { geti18nValue, getProductUrl, formatUnitPrice } from '@kopapro/utils/m18';
import ShopImage from '@kopapro/components/commons/shopImage';
import { AppImages } from '@kopapro/utils/constants/images';
import { Currency } from '@kopapro-redux/utils/constant';
import utils from '@kopapro/utils/utils';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
interface State {
  showInputbox: boolean;
  isLoading: boolean;
  options: Option[];
  searchInputValue: string;
}

class Search extends Component<SearchProps, State> {
  productImageSize = 60;
  minLength = 1;
  searchRef: React.RefObject<any>;

  goToSearchResultTimeout: any = null;
  constructor(props: SearchProps) {
    super(props);
    this.state = { showInputbox: false, isLoading: false, options: [], searchInputValue: '' };
    this.searchRef = React.createRef();
  }

  onSearchIconClick = () => {
    if (!this.state.showInputbox) {
      this.setState({ showInputbox: true });
      if (this.searchRef.current) {
        this.searchRef.current.focus();
      }
    }
  };

  onSearchBlur = () => {
    this.searchRef.current.clear();
    this.setState({ showInputbox: false });
  };

  onSelected = (selected: Array<Object | string>) => {
    const { navigate } = this.props;
    if (selected.length === 1) {
      if (this.goToSearchResultTimeout) {
        clearTimeout(this.goToSearchResultTimeout);
      }
      const product = selected[0];
      this.searchRef.current.blur();
      navigate(getProductUrl(product as ProductGroup));
    }
  };

  onKeyDown = (e: any) => {
    const { navigate } = this.props;

    if (e.key === 'Enter') {
      const keyword = this.searchRef.current.getInput().value;
      if (!utils.isEmpty(keyword) && this.state.options.length > 0) {
        const searchRef = this.searchRef;
        this.goToSearchResultTimeout = setTimeout(() => {
          searchRef.current.blur();
          navigate(`/products/search?q=${keyword}`);
        }, 500);
      }
    }
  };

  handleSearch = (query: string) => {
    this.setState({ isLoading: true });
    const { searchProduct, basic, navigate, closeDrawer } = this.props;
    if (basic && query.trim().length > 0) {
      navigate(`/products/search?q=${query}`);
      if (closeDrawer) {
        closeDrawer();
      }
      return;
    }
    searchProduct({ keyword: query, pageSize: 20 }, (result: QueryList<Product>) => {
      const options = result.data;
      this.setState({ options });
      this.setState({ isLoading: false });
    });
  };

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchInputValue: e.currentTarget.value });
  };

  renderMenu = (results: Option[], menuProps: RenderMenuProps, state: TypeaheadManagerChildProps) => {

    let width = Math.min(window.innerWidth - 24, 400);

    return (
      <Menu
        className="search-items"
        {...menuProps}
        // style={{ ...menuProps.style, minWidth: menuProps.style?.width, width: 'unset' }}>
        style={{ ...menuProps.style, width }}>
        {results.map((result: any, index) => (
          <MenuItem option={result} key={result.code} position={index} className="search-item">
            <ShopImage
              height={this.productImageSize}
              width={this.productImageSize}
              src={result.image}
              fallbackImage={AppImages.productPlaceholder}
              className="contain"
            />
            <div className="item-name-box flex-grow-1">
              <span className="item-name text-truncate">{geti18nValue(result.desc) || result.code}</span>
              <span className="item-code text-truncate">{result.code}</span>
            </div>
            <div className="d-flex align-items-center">
              <span className="item-price">{formatUnitPrice(result.up, Currency.SELECTED)}</span>
            </div>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  render(): ReactNode {
    const { t, basic, marginStyle, iconStyle } = this.props;
    if (basic) {
      return (
        <Form
          style={marginStyle}
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSearch(this.state.searchInputValue);
          }}>
          <InputGroup className="p-2 pe-0">
            <Form.Control
              value={this.state.searchInputValue}
              placeholder={t('ce01_pmpcore.kopapro.react.searchProduct')}
              onChange={this.onInputChange}
            />
            <Button variant="outline-secondary" type="submit" className="border-0">
              <SearchIcon sx={iconStyle} />
            </Button>
          </InputGroup>
        </Form>
      );
    }

    return (
      <>
        <AsyncTypeahead
          id="product-search"
          ref={this.searchRef}
          placeholder={t('ce01_pmpcore.kopapro.react.searchProduct')}
          className={classnames('search-input', {
            show: this.state.showInputbox,
          })}
          inputProps={{ autoComplete: 'off' }}
          filterBy={() => true}
          isLoading={this.state.isLoading}
          minLength={this.minLength}
          labelKey="code"
          renderMenu={this.renderMenu}
          onSearch={this.handleSearch}
          emptyLabel={t('ce01_pmpcore.kopapro.react.search.noResult.short')}
          searchText={t('ce01_pmpcore.kopapro.react.searching')}
          promptText=""
          options={this.state.options}
          onChange={this.onSelected}
          onBlur={this.onSearchBlur}
          selectHint={(shouldSelectHint: boolean, e: any): boolean => {
            return false;
          }}
          onKeyDown={this.onKeyDown}
          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
            // @ts-ignore
            <Form.Control
              {...inputProps}
              ref={(node) => {
                inputRef(node);
                referenceElementRef(node);
              }}
              type="text"
            />
          )}
        />
        <Nav.Link className="d-flex align-self-center search-icon" style={marginStyle} onClick={this.onSearchIconClick}>
          <SearchIcon sx={iconStyle} />
        </Nav.Link>
      </>
    );
  }
}

export default Search;
