import Button, { ButtonProps } from 'react-bootstrap/Button';
import Spinner, { SpinnerProps } from 'react-bootstrap/Spinner';

export interface SpinnerButtonProps extends ButtonProps {
  spinning?: boolean;
  spinnerProps?: SpinnerProps;
  showLabel?: boolean;
  // children: React.ReactNode
}

function SpinnerButton(props: SpinnerButtonProps) {
  const { children, spinning, spinnerProps, showLabel, ...rest } = props;
  return (
    <Button {...rest} disabled={props.disabled || spinning}>
      {spinning && (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            {...spinnerProps}
            className="me-1"
          />
          {showLabel && children}
        </>
      )}

      {!spinning && children}
    </Button>
  );
}
export default SpinnerButton;
