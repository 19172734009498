import { ShopComponentSetting } from '@kopapro-redux/types/componentSetting';
import { Banner, ShopLayout, ShopSetup } from '@kopapro-redux/types/shop';

export function handlePreviewMetaSetup(nextState: ShopSetup, setup: ShopSetup) {
  if (setup) {
    return { ...nextState, ...setup };
  }
  return nextState;
}

export function handlePreviewMetaLayout(nextState: ShopLayout, m18CompId: string, m18Data: any) {
  if (m18Data) {
    const key = m18CompId.toLocaleLowerCase();
    return { ...nextState, [key]: { ...m18Data } };
  }
  return nextState;
}

export function handlePreviewMetaComponentSetting(nextState: ShopComponentSetting, m18CompId: string, m18Data: any) {
  if (m18Data) {
    const key = m18CompId.toLocaleLowerCase();
    return { ...nextState, [key]: m18Data };
  }
  return nextState;
}

export function handlePreviewMetaBanners(nextState: any, m18CompId: string, m18Data: any) {
  if (m18Data && m18Data.length > 0) {
    let newState: { [key: string]: any } = {};

    const banners = m18Data as Banner[];
    banners.forEach((banner) => {
      const { meta, compId, displayMode } = banner;
      newState[banner.compId] = Object.assign({}, { meta, compId, displayMode });
    });

    return newState;
  }
  return nextState;
}