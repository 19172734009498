import { Component, ReactNode } from 'react';
import { RichTextEditorProps } from '@kopapro/components/commons/richTextEditor';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const locales = { en: 'en', 'zh-TW': 'zh_tw', 'zh-CN': 'zh' };

interface RichTextEditorState {}

const hashtagConfig = {
  separator: ' ',
  trigger: '#',
};

export const convertStringToEditorState = (value: string | undefined): EditorState => {
  if (value) {
    const blocksFromHtml = htmlToDraft(value);
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
  }
  return EditorState.createEmpty();
};

export const convertEditorStateToString = (editorState: EditorState | undefined): string => {
  if (editorState) {
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()), hashtagConfig);
    return value;
  }
  return '';
};

export default class RichTextEditor extends Component<RichTextEditorProps, RichTextEditorState> {
  render(): ReactNode {
    const { currentLang } = this.props;
    const locale = locales[currentLang as keyof typeof locales] || 'en';
    return (
      <Editor
        editorState={this.props.editorState}
        wrapperClassName="rich-text-editor-wrapper"
        editorClassName="rich-text-editor-editor "
        toolbarClassName="rich-text-editor-toolbar"
        onEditorStateChange={this.props.onEditorStateChange}
        hashtag={hashtagConfig}
        localization={{ locale }}
        toolbar={{
          // Remover Emoji
          options: [
            'inline',
            'colorPicker',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'link',
            'embedded',
            'remove',
            'history',
          ],
        }}
      />
    );
  }
}
