import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Review from '@kopapro/components/productDetail/review/review';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getProductCommentDetail, loadProductComment } from '@kopapro-redux/actions/products';
import { Product } from '@kopapro-redux/types/products';

interface IProps extends WithTranslation {
  products: Product[] | undefined;
  productName: string;
  imageCode: string;
}

interface StateProps {}

interface DispatchProps {
  loadProductComment: typeof loadProductComment;
  getProductComment: typeof getProductCommentDetail;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadProductComment: loadProductComment,
  getProductComment: getProductCommentDetail,
};

export type RatingProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Review));
