import { combineReducers } from "redux";
import setupRedcuer from "@kopapro-redux/reducers/entities/shopMeta/setup";
import layoutsRedcuer from "@kopapro-redux/reducers/entities/shopMeta/layout";
import componentSettingReducer from '@kopapro-redux/reducers/entities/shopMeta/componentSetting';
import bannerReducer from '@kopapro-redux/reducers/entities/shopMeta/banners';
import { ShopMetaType } from '@kopapro-redux/action-types';

const initialState = {
  initializing: true,
  showSplash: false,
};
function statusReducer(state = initialState, action: any) {
  // logic here
  switch (action.type) {
    case ShopMetaType.GET_SHOP_META_SUCCESS:
      return {
        ...state,
        initializing: false,
      };
    case ShopMetaType.SHOW_SHOP_SPLASH:
      return {
        ...state,
        showSplash: true,
      };
    default:
      return {
        ...state,
      };
  }
}

const shopMetaReducer = combineReducers({
  setup: setupRedcuer,
  layouts: layoutsRedcuer,
  componentSetting: componentSettingReducer,
  status: statusReducer,
  banner: bannerReducer,
});

export default shopMetaReducer;