import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { RootState } from "@kopapro-redux/store";
import { withTranslation, WithTranslation } from "react-i18next";

import DeliveryMethodItem from "@kopapro/components/deliveryMethod/item/item";
import { DeliveryMethod } from '@kopapro-redux/types/deliveryMethod';

interface IProps extends WithTranslation {
  item: DeliveryMethod;
  imageHeight?: string;
}

interface StateProps {}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type DeliveryMethodItemProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DeliveryMethodItem));
