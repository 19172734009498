import { Link } from "react-router-dom";
import { Component } from 'react';
import { formatUnitPrice, geti18nValue, resolveImageURL, getProductDetailPricing } from '@kopapro/utils/m18';
import ShopImage from '@kopapro/components/commons/shopImage';
import { ProductItemProps } from '@kopapro/components/products/item';
import { AppImages } from '@kopapro/utils/constants/images';
import { productItemConfig as config } from '@kopapro/utils/config';
import classnames from 'classnames';
import { Currency } from '@kopapro-redux/utils/constant';
import InfoIcon from '@mui/icons-material/Info';
import { ImageType } from '@kopapro/utils/constants/constants';
import utils from '@kopapro-redux/utils/utils';
import Button from 'react-bootstrap/Button';
export default class ProductItem extends Component<ProductItemProps> {
  itemDefaultHeight = config.itemHeight;
  border = config.border;
  shadow = config.shadow;
  alignTextCenter = config.textCenter;
  priceHeight = config.textCenter ? 48 : 24;
  allowBuyButton = config.allowBuyButton;

  renderPrice = () => {
    const { t, item } = this.props;
    const { memTypeShortName, soldOut } = item!;
    const { regularPrice, salesPrice, isShowMemberUpDesc } = getProductDetailPricing(item!);
    let memberPriceDesc = geti18nValue(memTypeShortName);

    let soldOutMessage = null;
    if (soldOut) {
      soldOutMessage = (
        <div>
          <InfoIcon /> {t('ce01_pmpcore.react.soldOut')}
        </div>
      );
    }
    let offPrice: any = (
      <>
        {formatUnitPrice(salesPrice, Currency.SELECTED)}
        {soldOutMessage}
      </>
    );
    if (regularPrice > salesPrice) {
      offPrice = (
        <>
          <div className={classnames('me-1', { 'd-inline-block': !this.alignTextCenter })}>
            <del>{formatUnitPrice(regularPrice, Currency.SELECTED)}</del>
          </div>
          <div className={classnames('text-danger', { 'd-inline-block': !this.alignTextCenter })}>
            {isShowMemberUpDesc ? memberPriceDesc + ': ' : ''}
            {formatUnitPrice(salesPrice, Currency.SELECTED)}
          </div>
          {soldOutMessage}
        </>
      );
    }
    return offPrice;
  };

  renderProIconsMore = (icons: string[]) => {
    if (!utils.isEmptyList(icons)) {
      return (
        <div
          className=" d-flex py-2 position-absolute"
          style={{ top: '0', left: '0.5rem', flexFlow: 'column', zIndex: 2 }}>
          {icons.map((icon) => {
            return (
              <ShopImage
                width="40"
                className="mb-1"
                key={icon}
                src={resolveImageURL(icon, false, ImageType.mobilePro)}
              />
            );
          })}
        </div>
      );
    }
    return null;
  };

  // demonstrate concept only,  a lot of checking requried to add in cart
  renderBuyButton = () => {
    const { t, buyButton } = this.props;
    if (buyButton === false || !this.allowBuyButton) {
      return null;
    }
    // if tailor / variant, then go to detail
    return (
      <div className="d-flex flex-grow-1 align-items-end">
        <Button
          variant="main"
          // style={{ ...customStyle, borderColor: buyButtonStyle?.fontColor }}
          // onClick={this.buyButtonHandler.bind(null, isGoToCheckout)}
          className="mt-1 py-2 w-100">
          {t('ce01_pmpcore.kopapro.react.addToCart')}
        </Button>
      </div>
    );
  };

  render() {
    const { item, imageHeight } = this.props;
    if (!item) {
      return null;
    }
    const { proIconsMore } = item;
    let iconTextBox;
    let icons = this.renderProIconsMore(proIconsMore.icon);
    const iconsText = geti18nValue(proIconsMore.textLabel);
    if (iconsText.length > 0) {
      iconTextBox = <div className="text-center p-1 bg-dark text-white">{iconsText}</div>;
    }

    const productName = geti18nValue(item.desc);
    let link = item.code;
    if (item.m18ProGpId === 0) {
      link = 'item/' + item.code;
    }

    const itemImages = item.imageList;
    let resolvedImages = itemImages?.map((elem) => resolveImageURL(elem));

    return (
      <Link to={`/products/${link}`} className="product-item col text-decoration-none">
        <div
          className={classnames('card', { 'shadow-sm': this.shadow, 'border-0': !this.border, border: this.border })}>
          {icons}
          <ShopImage
            className={`main-img card-img-top ratio ratio-1x1 ${config.objectFit}`}
            height={imageHeight === 'unset' ? '' : imageHeight || this.itemDefaultHeight}
            alt=""
            src={resolveImageURL(item.image, false, ImageType.webPro)}
            fallbackImage={item.coupon ? AppImages.couponPlaceholder : AppImages.productPlaceholder}
            isPro={true}
            proItem={item}
            images={resolvedImages}
          />
          {iconTextBox}
          <div className={classnames('card-body d-flex flex-column', { 'text-center': this.alignTextCenter })}>
            <h6 className="card-title text-dark text-truncate">{productName}</h6>
            <div className="card-text text-muted flex-grow-1" style={{ minHeight: this.priceHeight }}>
              {this.renderPrice()}
            </div>
            {this.renderBuyButton()}
          </div>
        </div>
      </Link>
    );
  }
}


