import { Link } from "react-router-dom";
import { Component } from "react";
import { geti18nValue, resolveImageURL } from '@kopapro/utils/m18';
import ShopImage from '@kopapro/components/commons/shopImage';
import { deliveryMethodItemConfig as config } from '@kopapro/utils/config';
import { DeliveryMethodItemProps } from '@kopapro/components/deliveryMethod/item';
import { ImageType } from '@kopapro/utils/constants/constants';

export default class DeliveryMethodItem extends Component<DeliveryMethodItemProps> {
  itemDefaultHeight = config.itemHeight;

  render() {
    const { item, imageHeight } = this.props;

    const deliveryMethodName = geti18nValue(item.desc);
    return (
      <Link to={`/delivery-methods/${item.code}`} className="delivery-method-item col text-decoration-none">
        <div className="card shadow-sm">
          <ShopImage
            className="main-img card-img-top  ratio ratio-1x1"
            height={imageHeight === 'unset' ? '' : imageHeight || this.itemDefaultHeight}
            alt=""
            src={resolveImageURL(item.image, false, ImageType.webPro)}
          />
          <div className="card-body">
            <h5 className="card-title text-center text-dark text-truncate">{deliveryMethodName}</h5>
          </div>
        </div>
      </Link>
    );
  }
}
