import { Component, ReactNode } from 'react';
import { formatAmountWithSelectedCurrency } from '@kopapro/utils/m18';
import { GiftRedeemModalProps } from '@kopapro/components/cart/giftRedeemModal';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import ShopImage from '@kopapro/components/commons/shopImage';
import { AppImages } from '@kopapro/utils/constants/images';
import ListGroup from 'react-bootstrap/ListGroup';
import SpinnerButton from '@kopapro/components/commons/spinnerButton';
import {
  GiftRedemptionData,
  GiftRedemptionDataKey,
  GiftRedemptionDataDetail,
  GiftRedemptionDataDetailProduct,
} from '@kopapro-redux/types/cart';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import classnames from 'classnames';
import utils from '@kopapro/utils/utils';
import CheckIcon from '@mui/icons-material/Check';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Dictionary } from '@kopapro-redux/types/utilities';
interface GiftRedeemModalState {
  sending: boolean;
  errorMessage: string;
  successMessage: string;
  // selectedList: GiftRedemptionDataKey[];
  selectedList: Dictionary<GiftRedemptionDataKey>;
}

export default class GiftRedeemModal extends Component<GiftRedeemModalProps, GiftRedeemModalState> {
  defaultState = {
    sending: false,
    errorMessage: '',
    successMessage: '',
    selectedList: {},
  };

  constructor(props: GiftRedeemModalProps) {
    super(props);
    if (props.list && props.list.length > 0) {
      let selectedList: Dictionary<GiftRedemptionDataKey> = this.getDefaultSelectedList(props.list);
      this.state = { ...this.defaultState, selectedList };
    } else {
      this.state = this.defaultState;
    }
  }

  componentDidUpdate(prevProps: Readonly<GiftRedeemModalProps>, prevState: Readonly<GiftRedeemModalState>): void {
    if (prevProps.show !== this.props.show && this.props.show) {
      const selectedList = this.getDefaultSelectedList(this.props.list);
      this.setState({ selectedList });
    }
  }

  getDefaultSelectedList = (list: GiftRedemptionData[] | undefined) => {
    if (list && list.length > 0) {
      let selectedList: Dictionary<GiftRedemptionDataKey> = {};
      for (let item of list) {
        for (let detail of item.details) {
          let { ppId, cat, subCat, ppRatio } = detail;
          let key = this.getKey(detail);
          if (!this.props.isGift && detail.exPriceOption === 'range') {
            const list = detail.proIdsInfo.map((info) => {
              return {
                qty: info.qty,
                lot: info.lot,
                proId: info.proId,
              };
            });
            selectedList[key] = { ppId, cat, subCat, ppRatio, manualList: list };
          } else if (detail.used) {
            selectedList[key] = { ppId, cat, subCat, ppRatio };
          }
        }
      }
      return selectedList;
    }
    return {};
  };

  handleClose = () => {
    this.props.onCloseHandler({});
  };

  handleClear = () => {
    let clearedList: Dictionary<GiftRedemptionDataKey> = {};
    const list = this.props.list;
    if (list && list.length > 0) {
      for (let item of list) {
        for (let detail of item.details) {
          let { ppId, cat, subCat, ppRatio } = detail;
          let key = this.getKey(detail);
          if (!this.props.isGift && detail.exPriceOption === 'range') {
            const list = detail.proIdsInfo.map((info) => {
              return {
                qty: 0,
                lot: info.lot,
                proId: info.proId,
              };
            });
            clearedList[key] = { ppId, cat, subCat, ppRatio, manualList: list };
          }
        }
      }
    }
    this.setState({ selectedList: clearedList });
  };

  getMainKey = (item: GiftRedemptionDataDetail | GiftRedemptionDataKey) => {
    return `${item.ppId}-${item.cat}`;
  };

  getKey = (item: GiftRedemptionDataDetail | GiftRedemptionDataKey) => {
    return `${item.ppId}-${item.cat}-${item.subCat}-${item.ppRatio}`;
  };

  handleSubmit = () => {
    const self = this;

    this.setState({ sending: true, errorMessage: '', successMessage: '' });

    this.props.onSubmitHandler(Object.values(this.state.selectedList), function (isSuccess: boolean, message = '') {
      if (isSuccess) {
        self.setState({ sending: false });
        self.handleClose();
      } else {
        self.setState({ sending: false, errorMessage: message });
      }
    });
  };

  // handle form value change
  selectItem = (item: GiftRedemptionDataKey, isActiveItem: boolean) => {
    let newList = { ...this.state.selectedList };
    const mainKey = this.getMainKey(item);
    const key = this.getKey(item);

    if (isActiveItem) {
      delete newList[key];
    } else {
      for (let selectedKey of Object.keys(newList)) {
        let selectedItem = newList[selectedKey];
        let selectedMainKey = this.getMainKey(selectedItem);
        if (selectedMainKey === mainKey) {
          delete newList[selectedKey];
        }
      }

      newList[key] = item;
    }

    this.setState({ selectedList: newList });
  };

  isActiveItem = (item: GiftRedemptionDataKey): boolean => {
    const key = this.getKey(item);
    const obj = this.state.selectedList[key];
    return utils.isDefined(obj);
  };

  getItemByKey = (key: string) => {
    const { list } = this.props;
    if (list && list.length > 0) {
      for (let item of list) {
        for (let detail of item.details) {
          let { ppId, cat, subCat, ppRatio } = detail;
          if (key === `${ppId}-${cat}-${subCat}-${ppRatio}`) {
            return item;
          }
        }
      }
    }
    return null;
  };
  getSelectedItemByKey = (key: string) => {
    let item = this.state.selectedList[key];
    return item || null;
  };

  onChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>, key: string, info: GiftRedemptionDataDetailProduct) => {
    const newValue: any = e.currentTarget.value;
    let quantity = 1;
    if (utils.isEmpty(newValue)) {
      this.setManualListQty(undefined, key, info);
      return;
    } else if (!utils.isIntegerString(newValue)) {
      return;
    }
    quantity = Math.max(0, parseInt(newValue || 0));
    this.setManualListQty(quantity, key, info);
  };

  qtyButtonHandler = (key: string, info: GiftRedemptionDataDetailProduct, change: number) => {
    const stateItem = this.getSelectedItemByKey(key);
    const currentQty = stateItem.manualList?.find((item) => item.proId === info.proId)?.qty || 0;
    const newQty = Math.max(0, currentQty + change);

    this.setManualListQty(newQty, key, info);
  };

  setManualListQty = (newQty: number | undefined, key: string, info: GiftRedemptionDataDetailProduct) => {
    const item = this.getItemByKey(key);
    const stateItem = this.getSelectedItemByKey(key);

    if (item && stateItem && !utils.isEmptyList(item.details)) {
      // range should only 1 element
      const detail = item.details[0];
      const newManualList = (stateItem.manualList || []).filter((item) => item.proId !== info.proId);

      const modifyItem = {
        proId: info.proId,
        lot: info.lot,
        qty: newQty,
      };
      newManualList.push(modifyItem);

      let isValid = true;

      if (utils.isDefined(newQty)) {
        const sum = newManualList.reduce((accumulator, object) => {
          return accumulator + (object.qty || 0);
        }, 0);
        if (sum > detail.maxQty) {
          isValid = false;
        }
      }
      if (isValid) {
        let selectedList = { ...this.state.selectedList };
        selectedList[key] = { ...selectedList[key], manualList: newManualList };
        this.setState({ selectedList });
      }
    }
  };

  renderQuantity(key: string, info: GiftRedemptionDataDetailProduct): ReactNode {
    const { sending } = this.state;
    const stateItem = this.getSelectedItemByKey(key);
    const qty = stateItem?.manualList?.find((item) => item.proId === info.proId)?.qty;
    return (
      <InputGroup className="quantity-input-group">
        <Button
          variant="link"
          className="less-btn"
          onClick={() => this.qtyButtonHandler(key, info, -1)}
          disabled={sending}>
          <RemoveIcon />
        </Button>
        <Form.Control
          name="quantity"
          value={utils.isDefined(qty) ? qty : ''}
          type="number"
          className="quantity-input"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeQuantity(e, key, info)}
          disabled={sending}
        />
        <Button
          variant="link"
          className="add-btn"
          onClick={() => this.qtyButtonHandler(key, info, 1)}
          disabled={sending}>
          <AddIcon />
        </Button>
      </InputGroup>
    );
  }

  getPricePerItem = (item: GiftRedemptionDataDetailProduct) => {
    const { pmpcoreDisplayUp } = item;
    if (pmpcoreDisplayUp && pmpcoreDisplayUp !== 0) {
      return formatAmountWithSelectedCurrency(pmpcoreDisplayUp);
    }
    return formatAmountWithSelectedCurrency(0);
  };

  // Components
  renderDataDetailItem(item: GiftRedemptionDataDetail) {
    const { t, isGift } = this.props;
    const { sending } = this.state;
    const { ppId, cat, subCat, ppRatio, exPriceOption, maxQty } = item;
    const isRange = !isGift && exPriceOption === 'range';
    const key: string = `${item.ppId}-${item.cat}-${item.subCat}-${item.ppRatio}`;
    const active: boolean = this.isActiveItem(item) || isRange;
    return (
      <ListGroup.Item
        key={key}
        className={classnames({ diabled: sending, selceted: active })}
        onClick={() => {
          if (!isRange) {
            this.selectItem({ ppId, cat, subCat, ppRatio }, active);
          }
        }}>
        <div className="mb-3 d-flex justify-content-between">
          <div className="d-flex">
            {!isRange && `+ ${formatAmountWithSelectedCurrency(item.amt)}`}
            {isRange && <div>{t('ce01_pmpcore.kopapro.react.redeemOptionalMessage', { maxQty })}</div>}
          </div>

          {active && <CheckIcon className="text-success selected-svg" fontSize="large" />}
        </div>
        <div>
          {item.proIdsInfo.map((obj: GiftRedemptionDataDetailProduct) => {
            let qtyNode: any = `x ${obj.qty}`;
            let price = null;
            if (isRange) {
              qtyNode = this.renderQuantity(key, obj);
              price = (
                <div className="">
                  <span className="text-danger">{`+ ${this.getPricePerItem(obj)}`}</span>
                </div>
              );
            }
            return (
              <div key={obj.proId} className="mb-2 d-flex align-items-center">
                <ShopImage
                  src={obj.photoCode}
                  height={50}
                  width={50}
                  className="cover shadow-sm"
                  fallbackImage={AppImages.productPlaceholder}
                />
                <div className="ms-3 flex-grow-1">
                  {geti18nValue(obj.descObj)}
                  {price}
                  <div className="text-muted">{geti18nValue(obj.attrObj)}</div>
                </div>
                <div className="ms-3 flex-shrink-0">{qtyNode}</div>
              </div>
            );
          })}
        </div>
      </ListGroup.Item>
    );
  }

  renderDataItem(item: GiftRedemptionData): ReactNode {
    const key = `${item.ppId}-${item.cat}`;
    return (
      <Accordion.Item key={key} eventKey={key}>
        <Accordion.Header className="text-break">{`${geti18nValue(item.ppDescObj)} (${item.cat})`}</Accordion.Header>
        <Accordion.Body>
          <ListGroup variant="flush">
            {item.details.map((detail) => {
              return this.renderDataDetailItem(detail);
            })}
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    );
  }

  render(): ReactNode {
    const { t, show, title, list } = this.props;
    const { sending, successMessage, errorMessage } = this.state;

    let key = '';
    if (list && list?.length > 0) {
      let item = list[0];
      key = `${item.ppId}-${item.cat}`;
    }
    return (
      <Modal
        className="gift-redemption-modal"
        show={show}
        onHide={this.handleClose}
        fullscreen={'sm-down'}
        size={'lg'}
        scrollable={true}
        backdrop={'static'}
        keyboard={false}>
        <Modal.Header closeButton={!sending}>{<Modal.Title>{t(title)}</Modal.Title>}</Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey={[key]} alwaysOpen>
            {list?.map((item) => {
              return this.renderDataItem(item);
            })}
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <ErrorMessage message={t(errorMessage)} />
          <SuccessMessage message={t(successMessage)} />

          <div className="d-flex justify-content-between w-100">
            <Button variant="outline-secondary" onClick={this.handleClear}>
              {t('ce01_pmpcore.react.clear')}
            </Button>
            <SpinnerButton
              variant="primary"
              className="btn-main"
              onClick={this.handleSubmit}
              spinning={sending}
              disabled={sending}>
              {t('ce01_pmpcore.react.submitBtn')}
            </SpinnerButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
