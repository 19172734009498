import { ToastContainerProps } from 'react-toastify';
//  UI control varible in here

import { KOPAPRO } from '@kopapro-redux/utils/constant';
import { Product } from '@kopapro/utils/constants/constants';

// website setting
export const kopaproConfig = {
  showScrollToTopButton: true,
  muiThemeMainColor: '#1ea952',
};

export const homePageConfig = {
  componentDefaultHeight: 'auto',
};

export const footerConfig = {
  iconSize: '2x',
  imageSize: 32,
  isFluid: false,
};

export const headerConfig = {
  isShowShopName: true,
  isFluid: true,
  isFixedTop: false, // default only, no effect for grid layout
  iconSize: '1x',
  menuFloatRight: false,
  imageSize: 32,
  disableAutoCollapse: false,
};

export const minicartConfig = {
  showPromotionDiscountAmount: true,
  showDeliveryFee: false,
  showPaymentMethodSurcharge: false,
  showServiceSurcharge: true,
};

export const productDetailConfig = {
  useVerticalScroller: false,
  thumbnailSize: 70,
  relatedItem: {
    imageSize: 70,
  },
  showQtyInput: true,
  maxOrderQty: Math.min(999999, Product.MAX_ORDER_ITEM_PER_PRODUCT),
};

export const productListConfig = {
  showFilter: true,
  categoryPerRow: true,
  usePagination: true, // if false, then use scroll to fetch
  pageSize: KOPAPRO.PAGE_SIZE,
  mobilePageSize: KOPAPRO.MOBILE_PAGE_SIZE,
};

export const productItemConfig = {
  itemHeight: 200,
  textCenter: false,
  border: true,
  shadow: true,
  allowBuyButton: false,
  objectFit: 'contain',
};

export const blogListConfig = {
  usePagination: false, // if false, then use scroll to fetch
  pageSize: KOPAPRO.PAGE_SIZE,
};

export const deliveryMethodItemConfig = {
  itemHeight: 200,
};

export const cartConfig = {
  showDiscountCodeInput: true,
  needRefreshCartInInitial: false,
};

export const cartItemConfig = {
  imageSize: 80,
};

export const checkoutConfig = {
  delivery: {
    imageSize: 75,
  },
  payment: {
    imageSize: 75,
  },
  extra: {
    imageSize: 100,
  },
  coupon: {
    imageSize: 150,
  },
};

export const paymentConfig = {
  interval: 3000,
  qrCode: {
    widthHeight: 300,
    color: '#1ea952',
  },
};

export const orderConfig = {
  delivery: {
    imageSize: 75,
    showMethodName: false,
  },
  payment: {
    imageSize: 75,
    showMethodName: true,
  },
  extra: {
    imageSize: 100,
  },
};

export const memberCenterConfig = {
  orders: {
    usePagination: true, // if false, then use scroll to fetch
    defaultStartDate: new Date(new Date().getFullYear(), new Date().getMonth() - 7, 1),
    defaultEndDate: new Date(),
  },
  schedule: { enable: false },
  wishlist: {
    usePagination: true, // if false, then use scroll to fetch
  },
  coupons: {
    defaultStartDate: new Date(new Date().getFullYear(), new Date().getMonth() - 7, 1),
    defaultEndDate: new Date(),
  },
};

// home page components
export const bannerConfig = {
  cardShadow: 'shadow-sm',
  defaultHeight: 'auto',
};
export const contactUsConfig = {
  showImage: true,
};

export const loginConfig = {
  showImage: true,
};

export const registerConfig = {
  showImage: true,
};

export const shareButtonsConfig = {
  enableWhatsapp: true,
  enableTelegram: true,
  enableFacebook: true,
  enableTwitter: true,
  enableLine: true,
  iconProps: {
    size: 24,
    round: true,
    borderRadius: 4,
    bgStyle: {},
    iconFillColor: 'white',
  },
  buttonProps: {
    className: 'me-1',
  },
};

export const toastOptions: ToastContainerProps = {
  position: 'top-right',
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
};

// safetyURL
export const safetyEmbedUrlConfig = {
  google: 'https://www.google.com/maps/embed',
  youtube: 'https://www.youtube.com/embed',
};
