import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import Amounts from '@kopapro/components/order/amounts/amounts';
import { OrderAmountDetails, OrderPayment } from '@kopapro-redux/types/order';
import { getOrderAmountDetails, getOrderInfo } from '@kopapro-redux/selectors/entities/order';
import { getOrderPayment } from '@kopapro-redux/selectors/entities/order';

interface IProps extends WithTranslation {}

interface StateProps {
  payStatus?: string;
  payment?: OrderPayment;
  amountDetails?: OrderAmountDetails;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    payStatus: getOrderInfo(state)?.payStatus,
    payment: getOrderPayment(state),
    amountDetails: getOrderAmountDetails(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type AmountsProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Amounts));
