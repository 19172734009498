import keyMirror from "@kopapro-redux/utils/key-mirror";

export default keyMirror({
  GET_PRODUCT_REQUEST: null,
  GET_PRODUCT_BY_ID_REQUEST: null,
  RECEIVED_PRODUCT: null,
  RECEIVED_PRODUCTS: null,
  GET_PRODUCT_FAILURE: null,
  GET_PRODUCTS_BY_CATEGORY_REQUEST: null,
  RECEIVED_PRODUCTS_BY_CATEGORY: null,
  GET_CATEGORIES_REQUEST: null,
  RECEIVED_CATEGORIES: null,
  APPLY_FILTER: null,
  CLEAR_FILTER: null,
  SEARCH_PRODUCT_REQUEST: null,

  // wishlist
  CHECK_PRODUCT_IN_WISHLIST: null,
  ADD_PRODUCT_TO_WISHLIST: null,
  REMOVE_PRODUCT_FROM_WISHLIST: null,

  //comment
  LOAD_PRODUCT_COMMENT: null,
  GET_PRODUCT_COMMENT_DETAIL: null,
});