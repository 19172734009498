import KppBreadcrumb from '@kopapro/components/commons/breadcrumb/breadcrumb';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import { isM18PreviewMode } from '@kopapro-redux/utils/m18';

interface IProps extends WithTranslation {}
class NotFoundPage extends React.Component<IProps> {
  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t } = this.props;
    const items = [{ text: t('ce01_pmpcore.react.home'), to: '/', active: false }];
    return <KppBreadcrumb items={items} />;
  };

  render() {
    const { t } = this.props;
    const pathname = window.location.pathname;
    if (isM18PreviewMode() && !pathname.startsWith('/account')) {
      return <Navigate to={'/'} />;
    } else {
      if (pathname !== '/' && pathname !== '/page-not-found') {
        const trimPath = pathname.substring(0, pathname.lastIndexOf('/'));
        if (trimPath !== '') {
          return <Navigate to={trimPath} />;
        }
      }
    }
    return (
      <div className="page-container container  px-xl-5">
        {this.renderTop()}
        <ScrollToTopOnMount />
        <div className="m-auto  p-5  text-center w-75">
          <p className="fs-1">{t('ce01_pmpcore.react.notFoundPage')}</p>
          <Link to="/categories/All" className="fs-5 link btn-link">
            {t('ce01_pmpcore.react.browseOther')}
          </Link>
        </div>
      </div>
    );
  }
}
export default NotFoundPage;
