import { Component } from "react";
import { geti18nValue } from "@kopapro/utils/m18";
import { AboutUsProps } from '@kopapro/components/shop/aboutUs';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb/breadcrumb';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';

interface AboutUsState {}

export default class AboutUs extends Component<AboutUsProps, AboutUsState> {
  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t } = this.props;
    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.aboutUs'), to: '', active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  render() {
    const { aboutUs } = this.props;

    return (
      <div className="page-container container px-xl-5">
        {this.renderTop()}
        <div id={`about-us`}>
          <SafetyDOM html={geti18nValue(aboutUs)} />
        </div>
      </div>
    );
  }
}
