
import { UserType } from '@kopapro-redux/action-types';
import {
  ChangeLanguage,
  LoadRegisterFormAction,
  LoadRegisterFormCallback,
  LoadRegisterFormPayload,
  ResetPasswordPayload,
  SetCurrency,
  SetDisplayLanguage,
  SetSessionId,
  SetUserInfo,
  UserInfo,
} from '@kopapro-redux/types/user';
import { CommonAction } from '@kopapro-redux/actions/misc';

export const changeLanguage = (lang: string): ChangeLanguage => ({
  type: UserType.CHANGE_LANGUAGE,
  lang,
});

export const setDisplayLanguage = (lang: string, saveInCookie = true): SetDisplayLanguage => ({
  type: UserType.SET_DISPLAY_LANGUAGE,
  lang,
  saveInCookie,
});

export const changeCurrency = (currency: string): CommonAction => ({
  type: UserType.CHANGE_CURRENCY,
  payload: { currency },
});

export const setCurrency = (currency: string, saveInCookie = true): SetCurrency => ({
  type: UserType.SET_CURRENCY,
  currency,
  saveInCookie,
});

export const setUserInfo = (userInfo: UserInfo, saveInCookie = true): SetUserInfo => ({
  type: UserType.SET_USERINFO,
  userInfo,
  saveInCookie,
});

export const setSessionId = (sessionId: string, userInfo: UserInfo, saveInCookie = true): SetSessionId => ({
  type: UserType.SET_SESSIONID,
  sessionId,
  userInfo,
  saveInCookie,
});

export const submitContactUs = (payload: any, callback?: Function): CommonAction => {
  return {
    type: UserType.SUMBIT_CONTACT_US,
    payload,
    callback,
  };
};

export const subscribeNewsletter = (payload: any, callback?: Function): CommonAction => {
  return {
    type: UserType.SUBSCRIBE_NEWSLETTER,
    payload,
    callback,
  };
};

export const resetUserPassword = (payload: ResetPasswordPayload, callback?: Function): CommonAction => {
  return {
    type: UserType.RESET_PASSWORD,
    payload,
    callback,
  };
};

export const register = (payload: any, callback?: Function): CommonAction => {
  return {
    type: UserType.REGISTER,
    payload,
    callback,
  };
};

export const requestSMS = (payload: any, callback?: Function): CommonAction => {
  return {
    type: UserType.REQUEST_SMS,
    payload,
    callback,
  };
};

export const verifySMS = (payload: any, callback?: Function): CommonAction => {
  return {
    type: UserType.VERIFY_SMS,
    payload,
    callback,
  };
};

export const getLoginInfo = (payload: any, callback?: Function): CommonAction => {
  return {
    type: UserType.GET_LOGIN_INFO,
    payload,
    callback,
  };
};

export const login = (payload: any, callback?: Function): CommonAction => {
  return {
    type: UserType.LOGIN,
    payload,
    callback,
  };
};

export const autoLogin = (payload: any, callback?: Function): CommonAction => {
  return {
    type: UserType.AUTO_LOGIN,
    payload,
    callback,
  };
};

export const logout = (payload?: any, callback?: Function): CommonAction => {
  return {
    type: UserType.LOGOUT,
    payload,
    callback,
  };
};

export const forgotPassword = (payload: any, callback?: Function): CommonAction => {
  return {
    type: UserType.FORGOT_PASSWORD,
    payload,
    callback,
  };
};

export const refreshCaptcha = (payload: any, callback?: Function): CommonAction => {
  return {
    type: UserType.REFRESH_CAPTCHA,
    payload,
    callback,
  };
};

export const uploadTempImage = (payload: any, callback?: Function): CommonAction => {
  return {
    type: UserType.UPLOAD_TEMP_IMAGE,
    payload,
    callback,
  };
};

export const loadRegisterForm = (
  payload: LoadRegisterFormPayload,
  callback?: LoadRegisterFormCallback
): LoadRegisterFormAction => {
  return {
    type: UserType.LOAD_REGISTER_FORM,
    payload,
    callback,
  };
};

export const requestEmail = (payload: { email: string }, callback?: Function): CommonAction => {
  return {
    type: UserType.REQUEST_EMAIL,
    payload,
    callback,
  };
};

export const verifyEmail = (payload: { veriId: string; veriNum: string }, callback?: Function): CommonAction => {
  return {
    type: UserType.VERIFY_EMAIL,
    payload,
    callback,
  };
};