import { ForgetPasswordProps } from '@kopapro/components/pages/login/forgetPassword';
import utils from '@kopapro/utils/utils';
import { Component, ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import { M18ViewCheckMsg } from '@kopapro-redux/types/m18View';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import { geti18nValue } from '@kopapro-redux/utils/m18';

interface ForgetPasswordState {
  email: '';
  errorMessage: string;
  successMessage: string;
  sending: boolean;
  isEmailAlreadySubmit: boolean;
}

export default class ForgetPassword extends Component<ForgetPasswordProps, ForgetPasswordState> {
  defaultState = {};

  constructor(props: ForgetPasswordProps) {
    super(props);
    this.state = {
      ...this.defaultState,
      email: '',
      errorMessage: '',
      successMessage: '',
      sending: false,
      isEmailAlreadySubmit: false,
    };
  }

  // handle password value change
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: any = e.currentTarget.value;
    this.setState({ email: newValue });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { submitEmail } = this.props;
    const self = this;
    event.preventDefault();
    event.stopPropagation();
    this.setState({ sending: true, errorMessage: '', successMessage: '', isEmailAlreadySubmit: false });
    let email: string = this.state.email.trim();

    if (utils.isEmpty(email)) {
      this.setState({ errorMessage: 'ce01_pmpcore.react.emptyEmail' });
      return;
    } else if (!utils.isValidEmail(email)) {
      this.setState({ errorMessage: 'ce01_pmpcore.react.invalidEmail' });
      return;
    }

    // dispatch request
    submitEmail({ email }, function (isSuccess: boolean, checkMsg: M18ViewCheckMsg) {
      // update state errorMessage and successMessage
      let message: string = '';
      if (checkMsg) {
        message = geti18nValue(checkMsg.msg);
        if (checkMsg.info) {
          const mess: I18nDictionary = checkMsg.info?.mess || { en: '' };
          message = geti18nValue(mess);
        }
      }
      if (isSuccess) {
        self.setState({ sending: false, successMessage: message, isEmailAlreadySubmit: true });
      } else {
        self.setState({ sending: false, errorMessage: message, isEmailAlreadySubmit: false });
      }
    });
  };

  renderForm(): ReactNode {
    const { t } = this.props;
    const { email, errorMessage } = this.state;

    return (
      <div className={`login`}>
        <Form noValidate action="/" onSubmit={this.handleSubmit} autoComplete="off">
          <Form.Group as={Col}>
            <Form.Control
              className="field"
              placeholder={t(`ce01_pmpcore.react.email`)}
              name={'email'}
              value={email}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
            />
          </Form.Group>

          <Button disabled={false} variant="main" type="submit" className="w-100 text-uppercase">
            {t('ce01_pmpcore.react.confirm')}
          </Button>
        </Form>

        <ErrorMessage message={t(errorMessage)} />
      </div>
    );
  }

  render(): ReactNode {
    const { t } = this.props;
    const { email, isEmailAlreadySubmit } = this.state;

    if (isEmailAlreadySubmit) {
      return (
        <div id={`forget-password`}>
          <p className="fs-5">{t('ce01_pmpcore.react.forgotUrPasswordSent', { email: email })}</p>
        </div>
      );
    }

    return (
      <div id={`forget-password`}>
        <div className="col-12">
          <p className="fs-5">{t('ce01_pmpcore.react.forgotUrPasswordMsg')}</p>
        </div>
        {this.renderForm()}
      </div>
    );
  }
}
