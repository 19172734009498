import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Navbar from '@kopapro/components/header/components/navbar/navbar';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ShopLayoutStyle, ShopNavbarLayoutMenuComponent } from '@kopapro-redux/types/shop';
import { getNavbarComponents, getNavbar } from '@kopapro-redux/selectors/entities/shop';

interface IProps extends WithTranslation {
  toggleDrawer: Function;
  toggleStyle?: any;
  showToggleOnly?: boolean;
}

interface StateProps {
  layout: ShopLayoutStyle | undefined;
  components: ShopNavbarLayoutMenuComponent[];
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    layout: getNavbar(state)?.layout,
    components: getNavbarComponents(state) as ShopNavbarLayoutMenuComponent[],
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type NavbarProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Navbar));
