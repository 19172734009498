import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import Extra from '@kopapro/components/checkout/extra/extra';
import { M18ComboList, M18LookupList } from '@kopapro-redux/types/m18Option';
import { getM18Combo, getM18Lookup } from '@kopapro-redux/selectors/entities/m18Option';
import { InputFormatList } from '@kopapro-redux/types/componentSetting';
import { getOrderViewId, getOrderViewInputFormatList } from '@kopapro-redux/selectors/entities/checkout';
import { loadCheckoutExtraInfo, saveCheckoutExtraInfo } from '@kopapro-redux/actions/checkout';
import { uploadTempImage } from '@kopapro-redux/actions/user';

interface IProps extends WithTranslation {
  orderId: string;
  componentPrevious: Function;
  componentNext: Function;
}

interface StateProps {
  extraViewId: number;
  m18Fields: InputFormatList;
  combo?: { [k: string]: M18ComboList };
  lookup?: { [k: string]: M18LookupList };
}

interface DispatchProps {
  uploadTempImage: typeof uploadTempImage;
  loadData: typeof loadCheckoutExtraInfo;
  saveCheckoutExtraInfo: typeof saveCheckoutExtraInfo;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    extraViewId: getOrderViewId(state),
    m18Fields: getOrderViewInputFormatList(state),
    combo: getM18Combo(state),
    lookup: getM18Lookup(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  uploadTempImage: uploadTempImage,
  loadData: loadCheckoutExtraInfo,
  saveCheckoutExtraInfo: saveCheckoutExtraInfo,
};

export type ExtraProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Extra));
