import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import ImageWithText from '@kopapro/components/shop/imageWithText/imageWithText';
import { getDataByComponent } from '@kopapro-redux/selectors/entities/shop';
import { ImageWithTextContent } from '@kopapro-redux/types/componentSetting';

interface IProps extends WithTranslation {
  compId: string;
  compName: string;
  showIn: string;
}

interface StateProps {
  content?: ImageWithTextContent;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const { compId, compName } = props;
  return {
    content: getDataByComponent(state, { compId, compName })?.imageWithText,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};
export type ImageWithTextProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ImageWithText));
