import { MemberType } from '@kopapro-redux/action-types';
import {
  MemberGetQueryDataPayload,
  MemberGetQueryDataAction,
  MemberQueryRequestPayload,
  MemberQueryRequestAction,
  ChangePasswordPayload,
  GetInfoPayload,
  UpdateInfoPayload,
  LoadMemberFormAction,
  LoadMemberFormPayload,
  LoadMemberFormCallback,
  MemberGetCouponVouchersPayload,
  MemberGetCouponVouchersAction,
  MemberGetCouponVoucherLedgerPayload,
  MemberGetCouponVoucherLedgerAction,
  MemberGetBonusPointsLedgerPayload,
  MemberGetBonusPointsLedgerAction,
} from '@kopapro-redux/types/member';
import { CommonAction } from '@kopapro-redux/actions/misc';

export const queryMemberRequest = (
  payload: MemberQueryRequestPayload,
  callback?: Function
): MemberQueryRequestAction => {
  return {
    type: MemberType.QUERY_MEMBER_REQUEST,
    payload,
    callback,
  };
};

export const getMemberQueryData = (
  payload: MemberGetQueryDataPayload,
  callback?: Function
): MemberGetQueryDataAction => {
  return {
    type: MemberType.GET_MEMBER_QUERY_DATA,
    payload,
    callback,
  };
};

export const changeUserPassword = (payload: ChangePasswordPayload, callback?: Function): CommonAction => {
  return {
    type: MemberType.CHANGE_PASSWORD,
    payload,
    callback,
  };
};

export const getContactInfo = (payload: GetInfoPayload, callback?: Function): CommonAction => {
  return {
    type: MemberType.GET_INFO,
    payload,
    callback,
  };
};

export const updateContactInfo = (payload: UpdateInfoPayload, callback?: Function): CommonAction => {
  return {
    type: MemberType.UPDATE_INFO,
    payload,
    callback,
  };
};

export const getAddress = (payload: GetInfoPayload, callback?: Function): CommonAction => {
  return {
    type: MemberType.GET_INFO,
    payload,
    callback,
  };
};

export const updateAddress = (payload: UpdateInfoPayload, callback?: Function): CommonAction => {
  return {
    type: MemberType.UPDATE_INFO,
    payload,
    callback,
  };
};

export const createUdfInfo = (payload: UpdateInfoPayload, callback?: Function): CommonAction => {
  return {
    type: MemberType.CREATE_INFO,
    payload,
    callback,
  };
};

export const loadMemberForm = (
  payload: LoadMemberFormPayload,
  callback?: LoadMemberFormCallback
): LoadMemberFormAction => {
  return {
    type: MemberType.LOAD_MEMBER_FORM,
    payload,
    callback,
  };
};

export const loadMemberCouponVouchers = (
  payload: MemberGetCouponVouchersPayload,
  callback?: Function
): MemberGetCouponVouchersAction => {
  return {
    type: MemberType.LOAD_COUPON_VOUCHERS_LIST,
    payload,
    callback,
  };
};

export const loadMemberCouponVoucherLedger = (
  payload: MemberGetCouponVoucherLedgerPayload,
  callback?: Function
): MemberGetCouponVoucherLedgerAction => {
  return {
    type: MemberType.LOAD_COUPON_VOUCHER_LEDGER,
    payload,
    callback,
  };
};

export const loadMemberBonusPointsLedger = (
  payload: MemberGetBonusPointsLedgerPayload,
  callback?: Function
): MemberGetBonusPointsLedgerAction => {
  return {
    type: MemberType.LOAD_BONUS_POINTS_LEDGER,
    payload,
    callback,
  };
};