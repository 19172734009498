import { M18OptionType } from '@kopapro-redux/action-types';
import { CommonAction } from '@kopapro-redux/actions/misc';
import { UpdateM18Combo, UpdateM18Lookup } from '@kopapro-redux/types/m18Option';

export const getM18ComboList = (payload: any, callback?: Function): CommonAction => {
  return {
    type: M18OptionType.GET_M18_COMBO_LIST_REQUEST,
    payload,
    callback,
  };
};

export const getM18LookupList = (payload: any, callback?: Function): CommonAction => {
  return {
    type: M18OptionType.GET_M18_LOOKUP_LIST_REQUEST,
    payload,
    callback,
  };
};

// from server
export const comboUpdated = (payload: UpdateM18Combo, callback?: Function): CommonAction => {
  return {
    type: M18OptionType.COMBO_UPDATED,
    payload,
    callback,
  };
};

// from server
export const lookupUpdated = (payload: UpdateM18Lookup, callback?: Function): CommonAction => {
  return {
    type: M18OptionType.LOOKUP_UPDATED,
    payload,
    callback,
  };
};
