import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { LocalRootState } from '@app-redux/store';
import { isShowDemoMessage } from '@app-redux/selectors/app';

import Footer from '@kopapro-original/components/footer';
import wrapper from '@kopapro-original/wrapper';
import withState from '@kopapro/components/footer/withState';
import { FooterProps as KopaproFooterProps } from '@kopapro-original/components/footer';
import { State as ExtendedFooterState } from '@kopapro-custom/components/footer/withState';
import { triggerDemoMessage } from '@app-redux/actions/misc';

interface IProps {
  updateLocalState: Function; // from withState
  stateData: ExtendedFooterState; // from withState
}

interface StateProps {
  showDemoMessage: boolean;
}

interface DispatchProps {
  toggleDemo: typeof triggerDemoMessage;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, LocalRootState> = (state: LocalRootState, props: IProps) => {
  return {
    showDemoMessage: isShowDemoMessage(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  toggleDemo: triggerDemoMessage,
};

// export the FooterProps merged from original and custom
export type FooterProps = KopaproFooterProps & IProps & StateProps & DispatchProps;

const statedFooter = withState(Footer);
export default connect(mapStateToProps, mapDispatchToProps)(wrapper(statedFooter));
