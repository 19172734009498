import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Redirect from '@kopapro/components/misc/redirect/redirect';

import { fetchProductCategories, getProductByIdRequest } from '@kopapro-redux/actions/products';
import { fetchBlogCategories } from '@kopapro-redux/actions/blog';

interface IProps {}
interface StateProps {}

interface DispatchProps {
  fetchProductCategories: typeof fetchProductCategories;
  fetchProduct: typeof getProductByIdRequest;
  fetchBlogCategories: typeof fetchBlogCategories;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  fetchProductCategories: fetchProductCategories,
  fetchProduct: getProductByIdRequest,
  fetchBlogCategories: fetchBlogCategories,
};

export type RedirectProps = IProps & StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Redirect);
