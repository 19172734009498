import { RootState } from '@kopapro-redux/store';
import { createSelector } from 'reselect';

export const getM18Option = (state: RootState) => {
  return state.kopapro.m18Option;
};

export const getM18Combo = createSelector(getM18Option, (m18Option) => {
  if (m18Option) {
    return m18Option.combo;
  }
});

export const getM18Lookup = createSelector(getM18Option, (m18Option) => {
  if (m18Option) {
    return m18Option.lookup;
  }
});
