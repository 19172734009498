import { geti18nValue } from '@kopapro/utils/m18';
import { Component, ReactNode } from 'react';
import { NewsletterPageProps } from '@kopapro/components/pages/newsletter';
import utils from '@kopapro/utils/utils';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb/breadcrumb';
import NewsletterForm from '@kopapro/components/shop/newsletter/form';

export default class NewsletterPage extends Component<NewsletterPageProps> {
  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t } = this.props;
    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.subscribe'), to: '', active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  render(): ReactNode {
    if (utils.isUndefined(this.props.content)) {
      return null;
    }
    const { headerHtml, footerHtml } = this.props.content!;

    return (
      <div id={`newsletter-page`} className={`newsletter-page page-container container px-xl-5`}>
        {this.renderTop()}

        <SafetyDOM html={geti18nValue(headerHtml)} />

        <div className={`row ignore-height-xs`}>
          <NewsletterForm showIn="both" {...this.props} />
        </div>

        <SafetyDOM html={geti18nValue(footerHtml)} />
      </div>
    );
  }
}
