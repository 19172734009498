import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import AssistiveTouch from '@kopapro/components/preview/assistiveTouch/assistiveTouch';

import { withTranslation, WithTranslation } from 'react-i18next';
import { withNavigation, withLocation } from '@kopapro/components/commons/router-hoc';
import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';

interface IProps extends WithTranslation {
  navigate: any; // from withNavigation
  location: any; // from withLocation
}

interface StateProps {
  userLoggedIn: boolean;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    userLoggedIn: userLoggedIn(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type AssistiveTouchProps = IProps & StateProps & DispatchProps;
export default withLocation(
  withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AssistiveTouch)))
);
