import { all, call, put, takeLatest } from 'redux-saga/effects';

import { BonusPointsSetupType } from '@kopapro-redux/action-types';

import KopaproApi from '@kopapro-redux/api';
import { QueryList } from '@kopapro-redux/types/general';
import { fetchBonusPointsSetupSuccess } from '@kopapro-redux/actions/bonusPoints';
import { BonusPointsSetup } from '@kopapro-redux/types/bonusPoints';

export function* getBonusPointsSetupSaga() {
  try {
    const resp: QueryList<BonusPointsSetup> = yield call(KopaproApi.getBonusPointsSetup);

    yield put(fetchBonusPointsSetupSuccess(resp.data));
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
      // show error message or ignore?
    }
  }
}

function* bonusPointsSaga() {
  yield all([takeLatest(BonusPointsSetupType.GET_BONUS_POINTS_SETUP_REQUEST, getBonusPointsSetupSaga)]);
}

export default bonusPointsSaga;
