import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from "@kopapro-redux/store";
import Minicart from "@kopapro/components/header/components/minicart/minicart";
import { withTranslation, WithTranslation } from 'react-i18next';

import { getCartItems, getCartAmountDetails } from '@kopapro-redux/selectors/entities/cart';
import { CartItem, CartAmountDetails } from '@kopapro-redux/types/cart';
import { removeProductFromCart } from '@kopapro-redux/actions/cart';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { getSelectedCurrency } from '@kopapro-redux/selectors/entities/user';

interface IProps extends WithTranslation {
  popper?: any;
  navigate: any;
  triggerHideCart: Function;
}

interface StateProps {
  items?: CartItem[];
  amountDetails?: CartAmountDetails;
  selectedCurrency: string;
}

interface DispatchProps {
  removeFromCart: typeof removeProductFromCart;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const amountDetails = getCartAmountDetails(state);
  return {
    items: getCartItems(state),
    amountDetails,
    selectedCurrency: getSelectedCurrency(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  removeFromCart: removeProductFromCart,
};

export type MiniCartProps = IProps & StateProps & DispatchProps;
export default withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Minicart)));



