import { combineReducers } from "redux";
import productsReducer from "@kopapro-redux/reducers/entities/products";
import userReducer from "@kopapro-redux/reducers/entities/user";
import shopMetaReducer from '@kopapro-redux/reducers/entities/shopMeta';
import productCategoriesReducer from '@kopapro-redux/reducers/entities/productCategories';
import deliveryMethodsReducer from '@kopapro-redux/reducers/entities/deliveryMethods';
import paymentMethodsReducer from '@kopapro-redux/reducers/entities/paymentMethods';
import shippingRegionReducer from '@kopapro-redux/reducers/entities/shippingRegion';
import bonusPointsReducer from '@kopapro-redux/reducers/entities/bonusPoints';
import cartReducer from '@kopapro-redux/reducers/entities/cart';
import orderReducer from '@kopapro-redux/reducers/entities/order';
import m18OptionReducer from '@kopapro-redux/reducers/entities/m18Option';
import blogReducer from '@kopapro-redux/reducers/entities/blog';
import { MiscType } from '@kopapro-redux/action-types';
import { setCookie } from '@kopapro-redux/utils/cookies';

const initialState = {
  initializing: true,
  expired: false,
  showAnnouncement: true,
  updating: false,
};

function appReducer(state = initialState, action: any) {
  // logic here
  switch (action.type) {
    case MiscType.INIT_SHOP_REQUEST:
      return {
        ...state,
        initializing: true,
      };
    case MiscType.SHOP_INITIALIZED:
      return {
        ...state,
        initializing: false,
      };

    case MiscType.UPDATE_KOPAPRO_EXPIRED:
      if (state.expired !== action.status) {
        return {
          ...state,
          initializing: false,
          expired: action.status,
        };
      }
      return state;
    case MiscType.CLOSE_ANNOUNCEMENT:
      // update cookie
      setCookie('close_announcement', '1', 1);
      return {
        ...state,
        showAnnouncement: false,
      };
    case MiscType.DISMISS_ANNOUNCEMENT:
      return {
        ...state,
        showAnnouncement: false,
      };
    case MiscType.RESET_ANNOUNCEMENT:
      return {
        ...state,
        showAnnouncement: true,
      };
    case MiscType.SHOP_UPDATING:
      if (state.updating !== action.status) {
        return {
          ...state,
          updating: action.status,
        };
      }
      return state;
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  products: productsReducer,
  user: userReducer,
  shopMeta: shopMetaReducer,
  productCategories: productCategoriesReducer,
  deliveryMethods: deliveryMethodsReducer,
  paymentMethods: paymentMethodsReducer,
  shippingRegion: shippingRegionReducer,
  bonusPoints: bonusPointsReducer,
  cart: cartReducer,
  order: orderReducer,
  m18Option: m18OptionReducer,
  blog: blogReducer,
  app: appReducer,
});

export default rootReducer;
