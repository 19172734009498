import React, { Component, ReactNode } from 'react';
import { BlogListExtProps } from '@kopapro/components/blog/list/list';
import { getDate, getMonth, getYear } from 'date-fns';
import { BlogDetail } from '@kopapro-redux/types/blog';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { geti18nValue } from '@kopapro/utils/m18';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import utils from '@kopapro/utils/utils';

// todo, put in utils if reuse in other files
const isSameYearAndMonth = function (origin: Date, compare: Date) {
  return getYear(origin) === getYear(compare) && getMonth(origin) === getMonth(compare);
};
export class DateList extends Component<BlogListExtProps> {
  renderMonthView = (date: Date): ReactNode => {
    const { t, dataList } = this.props;

    const records = dataList.filter((item: BlogDetail) => {
      const lastModifyDate: Date = new Date(item.lastModifyDate);
      return isSameYearAndMonth(date, lastModifyDate);
    });

    return (
      <Card key={date.toISOString()} className={`mb-2`}>
        <Card.Body>
          <Card.Title>
            {t('{ date, custom }', { date: date, dateFormat: { year: 'numeric', month: 'long' } })}
          </Card.Title>
          {records.map((blog) => {
            return this.renderItem(blog);
          })}
        </Card.Body>
      </Card>
    );
  };

  renderItem = (item: BlogDetail): ReactNode => {
    const { t, openBlogDetail } = this.props;
    const { lastModifyDate, titleObj, detailObj, blogId } = item;
    return (
      <div key={blogId} className="d-flex mt-3 pb-3 pm-sm-none blog-item">
        <div className="text-center me-4 date">
          <span className={`fs-1`}>{getDate(lastModifyDate)}</span>
        </div>
        <div className="d-sm-flex flex-grow-1">
          <div className="flex-grow-1">
            <h5 className={`title fw-bold mb-1`}>{geti18nValue(titleObj)}</h5>
            <div className={`overflow-hidden`}>
              <SafetyDOM html={geti18nValue(detailObj)} />
            </div>
          </div>
          <div className="align-self-center flex-shrink-0 px-3">
            <Button
              variant="outline-main"
              onClick={() => {
                openBlogDetail(blogId);
              }}>
              {t('ce01_pmpcore.react.readMore')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderContent = () => {
    const { dataList, renderEmptyRecordMessage } = this.props;
    // group by year-month
    let yearView: ReactNode[] = [];
    if (utils.isEmptyList(dataList) && renderEmptyRecordMessage) {
      return (
        <div className={`d-flex h-100 align-items-center justify-content-center`}>{renderEmptyRecordMessage()}</div>
      );
    }

    let sdate: Date = new Date('9999-12-31');
    let dataSet: Date[] = [sdate];

    for (const item of dataList) {
      const lastModifyDate: Date = new Date(item.lastModifyDate);

      let sameYearAndMonth: boolean = false;
      for (const date of dataSet) {
        if (isSameYearAndMonth(date, lastModifyDate)) {
          sameYearAndMonth = true;
          break;
        }
      }

      if (!sameYearAndMonth) {
        yearView.push(this.renderMonthView(lastModifyDate));
        dataSet.push(lastModifyDate);
      }
    }
    return yearView;
  };

  render() {
    const { showYearMonth, renderYearMonthSelector } = this.props;

    return (
      <div className={`blog-list date-list`}>
        <Row className={`justify-content-center`}>
          {showYearMonth && renderYearMonthSelector && (
            <Col xs={12} md={3} lg={2} className="mb-4">
              {renderYearMonthSelector()}
            </Col>
          )}
          <Col xs={12} md={7} lg={10}>
            {this.renderContent()}
          </Col>
        </Row>
      </div>
    );
  }
}
