import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import PickUp from '@kopapro/components/checkout/delivery/pickup/pickup';
import { ShippingRegionOptions } from '@kopapro-redux/types/shippingRegion';
import { getShippingRegionOptions } from '@kopapro-redux/selectors/entities/shippingRegion';
import { PickupAddress } from '@kopapro-redux/types/deliveryMethod';

interface IProps extends WithTranslation {
  addressList: PickupAddress[];
  selectedCode?: string;
  selectHandler: Function;
  sending: boolean;
}

interface StateProps {
  shippingRegionOptions: ShippingRegionOptions;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    shippingRegionOptions: getShippingRegionOptions(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type PickUpProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PickUp));
