import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import Reward from '@kopapro/components/pages/account/reward/reward';

import { getMemberCenterViewById } from '@kopapro-redux/selectors/entities/member';
import { InputFormatList } from '@kopapro-redux/types/componentSetting';

import withAccount from '@kopapro/components/pages/account/accountHoc';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { HomeView } from '@kopapro/utils/constants/constants';
import { getInputFormatListInView } from '@kopapro-redux/utils/m18View';
import { getMemberQueryData, loadMemberBonusPointsLedger, queryMemberRequest } from '@kopapro-redux/actions/member';
import { getBonusPointsSetup } from '@kopapro-redux/selectors/entities/bonusPoints';
import { BonusPointsSetup } from '@kopapro-redux/types/bonusPoints';
import { Dictionary } from '@kopapro-redux/types/utilities';

interface IProps extends WithTranslation {
  navigate: any;
}

interface DispatchProps {
  queryRequest: typeof queryMemberRequest;
  getQueryData: typeof getMemberQueryData;
  loadLedger: typeof loadMemberBonusPointsLedger;
}

interface StateProps {
  viewId: string | number;
  viewJsonParam?: Dictionary<any>;
  m18Fields: InputFormatList;
  bonusPointsSetup: BonusPointsSetup;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const compId = HomeView.MyBonusPoints;
  const content = getMemberCenterViewById(state, compId);
  return {
    viewId: content?.view.viewId || '',
    viewJsonParam: content?.view.jsonParam || {},
    m18Fields: getInputFormatListInView(content),
    bonusPointsSetup: getBonusPointsSetup(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  queryRequest: queryMemberRequest,
  getQueryData: getMemberQueryData,
  loadLedger: loadMemberBonusPointsLedger,
};

export type RewardProps = IProps & StateProps & DispatchProps;
export default withNavigation(
  withAccount(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Reward)), HomeView.MyBonusPoints)
);
