import keyMirror from '@kopapro-redux/utils/key-mirror';

// misc action type
export default keyMirror({
  GET_ORDER_REQUEST: null,
  ORDER_UPDATED: null,
  ORDER_EXTRA_UPDATED: null,
  CANCEL_ORDER_REQUEST: null,
  GET_ONLINE_PAYMENT_REQUEST: null,
  APPROVE_PAYPAL_REQUEST: null,
  ADD_PRODUCT_COMMENT_REQUEST: null,
  GET_SINGLE_PAGE_ORDER: null,
});
