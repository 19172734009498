import { BonusPointsSetupType } from '@kopapro-redux/action-types';
import { BonusPointsSetup } from '@kopapro-redux/types/bonusPoints';

export const fetchBonusPointsSetupSuccess = (payload: BonusPointsSetup[]) => ({
  type: BonusPointsSetupType.RECEIVED_BONUS_POINTS_SSETUP,
  payload,
});

export const getBonusPointsSetup = () => ({
  type: BonusPointsSetupType.GET_BONUS_POINTS_SETUP_REQUEST,
});
