import { FC } from 'react';

interface Props {
  width: number | string;
  height: number | string;
  videoId: string;
}

const YouTube: FC<Props> = ({ width, height, videoId }) => {
  return (
    <>
      <iframe
        title={videoId}
        width={width}
        height={height}
        src={'https://www.youtube.com/embed/' + videoId}
        allow={'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'}
        allowFullScreen={true}
      />
    </>
  );
};

export default YouTube;
