import { Component, ReactNode } from 'react';
import { InformationProps } from '@kopapro/components/cart/information';
import { PromotionAlert, MemberTypeAlert } from '@kopapro-redux/types/cart';
import utils from '@kopapro-redux/utils/utils';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import { formatAmount, getShowInClass } from '@kopapro/utils/m18';
import { showInType } from '@kopapro/utils/constants/constants';
import { Currency } from '@kopapro-redux/utils/constant';
import InfoIcon from '@mui/icons-material/Info';

interface InformationState {}

export default class Information extends Component<InformationProps, InformationState> {
  defaultState = {};

  constructor(props: InformationProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount(): void {
    this.props.loadInformation();
  }

  renderPromotionAlert(): ReactNode {
    const { t } = this.props;
    const alert: PromotionAlert = this.props.promotionAlert!;

    if (utils.isUndefined(alert) || utils.isEmptyJSONObject(alert)) {
      return null;
    }

    const message = t('ce01_pmpcore.react.promoAlertMsg', {
      amount: formatAmount(alert.condAmt, Currency.SELECTED),
      desc: alert.desc,
    });
    return (
      <div className="mb-3 checkout-alert">
        <div><InfoIcon  className="me-2"/> <span>{message}</span></div>
        <div>
        <SafetyDOM className={`${getShowInClass(showInType.desktop)}`} html={alert.msgDesktop} />
        <SafetyDOM className={`${getShowInClass(showInType.mobile)}`} html={alert.msgMobile} />
        </div>
      </div>
    );
  }

  renderMemberTypeAlert(): ReactNode {
    const { t } = this.props;
    const alert: MemberTypeAlert = this.props.memberTypeAlert!;
    if (utils.isUndefined(alert) || utils.isEmptyJSONObject(alert)) {
      return null;
    }

    let reachMessage = `${t('ce01_pmpcore.react.reachMemberAlert', {
      memType: geti18nValue(alert.reach.memTypeDescObj),
    })}`;
    if (utils.isNotEmpty(geti18nValue(alert.reach.upgradeDescObj))) {
      reachMessage = `${reachMessage} (${geti18nValue(alert.reach.upgradeDescObj)})`;
    }

    let upgradeMessage = `${t('ce01_pmpcore.react.upgradeMemberAlert', {
      amt: formatAmount(alert.upgrade.diffAmt, Currency.SELECTED),
      memType: geti18nValue(alert.upgrade.nextMemTypeDescObj),
    })}`;
    if (utils.isNotEmpty(geti18nValue(alert.upgrade.nextUpgradeDescObj))) {
      upgradeMessage = `${upgradeMessage} (${geti18nValue(alert.upgrade.nextUpgradeDescObj)})`;
    }

    return (
      <div className="mb-3">
        {alert.reach.status && (
          <div className="checkout-alert success">
            <InfoIcon className="me-2" /> <span>{reachMessage}</span>
          </div>
        )}
        {alert.upgrade.status && (
          <div className="checkout-alert">
            <InfoIcon className="me-2" /> <span>{upgradeMessage}</span>
          </div>
        )}
      </div>
    );
  }

  render(): ReactNode {
    return (
      <div className="mb-3">
        {this.renderMemberTypeAlert()}
        {this.renderPromotionAlert()}
      </div>
    );
  }
}
