import { Component, ReactNode } from 'react';
import { BlogListExtProps } from '@kopapro/components/blog/list/list';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import { BlogDetail } from '@kopapro-redux/types/blog';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import utils from '@kopapro-redux/utils/utils';

export class CoverAndContentList extends Component<BlogListExtProps> {
  renderHeader = (item: BlogDetail): ReactNode => {
    const { openBlogDetail, renderHeaderComp } = this.props;
    const { blogId } = item;

    return (
      <div
        className="w-100 ratio ratio-1x1"
        onClick={() => {
          openBlogDetail(blogId);
        }}>
        {renderHeaderComp && renderHeaderComp(item)}
      </div>
    );
  };

  renderItem = (item: BlogDetail): ReactNode => {
    const { showAuthor, showDate, t, openBlogDetail } = this.props;
    const { titleObj, detailObj, author, lastModifyDate, blogId } = item;
    return (
      <Card key={`item-${blogId}`} className="mb-3">
        <Row className="g-0">
          <Col md={4}>{this.renderHeader(item)}</Col>
          <Col md={8} className="d-flex">
            <Card.Body className={'pt-1 pb-1 d-flex flex-column'}>
              <Card.Title>{geti18nValue(titleObj)}</Card.Title>
              {showAuthor && <label className={'text-muted'}>{author}</label>}
              <SafetyDOM className="overflow-hidden flex-grow-1" html={geti18nValue(detailObj)} />
              <div>
                <Button
                  className="mt-2"
                  variant="outline-main"
                  onClick={() => {
                    openBlogDetail(blogId);
                  }}>
                  {t(`ce01_pmpcore.react.readMore`)}
                </Button>

                {showDate && (
                  <p className="card-text mt-1">
                    <small className="text-muted">{t('{date, full}', { date: lastModifyDate })}</small>
                  </p>
                )}
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    const { dataList, renderYearMonthSelector, renderEmptyRecordMessage } = this.props;
    const { showYearMonth } = this.props;

    let content: ReactNode = <></>;
    if (utils.isEmptyList(dataList)) {
      if (renderEmptyRecordMessage) {
        content = (
          <div className={`d-flex h-100 align-items-center justify-content-center`}>{renderEmptyRecordMessage()}</div>
        );
      }
    } else {
      content = dataList.map((item: BlogDetail) => {
        return this.renderItem(item);
      });
    }

    return (
      <div className="blog-list cover-and-content">
        <Row className={`justify-content-center`}>
          {showYearMonth && renderYearMonthSelector && (
            <Col xs={4} md={2}>
              {renderYearMonthSelector()}
            </Col>
          )}
          <Col xs={8} md={10}>
            {content}
          </Col>
        </Row>
      </div>
    );
  }
}
