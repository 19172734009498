import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Preview from '@kopapro/components/preview/preview';
import {
  updatePreviewMetaBanners,
  updatePreviewMetaComponentSetting,
  updatePreviewMetaLayout,
  updatePreviewMetaSetup,
} from '@kopapro-redux/actions/preview';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { loadRegisterForm } from '@kopapro-redux/actions/user';

interface IProps {
  navigate: any; // from withNavigation
}

interface StateProps {}

interface DispatchProps {
  updateSetup: typeof updatePreviewMetaSetup;
  updateLayout: typeof updatePreviewMetaLayout;
  updateComponentSetting: typeof updatePreviewMetaComponentSetting;
  updateBanners: typeof updatePreviewMetaBanners;
  // update form
  loadRegisterFormData: typeof loadRegisterForm;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  updateSetup: updatePreviewMetaSetup,
  updateLayout: updatePreviewMetaLayout,
  updateComponentSetting: updatePreviewMetaComponentSetting,
  updateBanners: updatePreviewMetaBanners,
  // update form
  loadRegisterFormData: loadRegisterForm,
};

export type PreviewProps = IProps & StateProps & DispatchProps;
export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Preview));
