import utils from '@kopapro/utils/utils';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

interface Props {
  message: string;
  className?: string;
}

function remindMessage(props: Props) {
  const { message } = props;

  if (utils.isEmpty(message)) {
    return null;
  }

  return (
    <p className={`${props.className}`}>
      <PriorityHighIcon /> {message}
    </p>
  );
}
export default remindMessage;
