import BlogList from '@kopapro/components/blog/list/list';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { getBlogCategoryById } from '@kopapro-redux/selectors/entities/blog';
import { BlogCategory, BlogListDisplayMode } from '@kopapro-redux/types/blog';
import { getBlogListRequest } from '@kopapro-redux/actions/blog';

interface IProps extends WithTranslation {
  navigate: any;
  categoryId: number;
  length?: number;
  sortBy?: 'recommand' | 'pop' | 'latest' | 'oldest';
  // HOC
  showDate: boolean;
  showAuthor: boolean;
  showHashTags: boolean;
  showYearMonth: boolean;
  displayMode: BlogListDisplayMode;
}

interface StateProps {
  blogCategory?: BlogCategory;
}

interface DispatchProps {
  loadBlogList: typeof getBlogListRequest;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    blogCategory: getBlogCategoryById(state, props.categoryId),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadBlogList: getBlogListRequest,
};

export type BlogListProps = IProps & StateProps & DispatchProps;

export default withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BlogList)));
