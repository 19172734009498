import React, { Component } from "react";
import { geti18nValue } from "@kopapro/utils/m18";
import { PolicyProps } from "@kopapro/components/shop/policy";
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb/breadcrumb';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';

interface PolicyState {}

export default class Policy extends Component<PolicyProps, PolicyState> {
  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t } = this.props;
    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.privacyPolicy'), to: '', active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  render() {
    const { policy } = this.props;

    return (
      <div className="page-container container px-xl-5">
        <ScrollToTopOnMount />
        {this.renderTop()}
        <div id={`policy`}>
          <SafetyDOM html={geti18nValue(policy)} />
        </div>
      </div>
    );
  }
}
