import { RootState } from '@kopapro-redux/store';
import { getComponentSetting } from '@kopapro-redux/selectors/entities/shop';
import { HomeViewContent } from '@kopapro-redux/types/componentSetting';
import utils from '@kopapro-redux/utils/utils';
import { createSelector } from 'reselect';
import { getInputFormatListInView } from '@kopapro-redux/utils/m18View';
import {
  isMemberContactPageContent,
  isMemberAddressPageContent,
  isMemberPurchasePageContent,
} from '@kopapro-redux/utils/m18';

export const getMemberCenterView = (state: RootState) => {
  const views: HomeViewContent[] | undefined = getComponentSetting(state).homeview;

  if (utils.isDefined(views)) {
    return views!.sort(function (a, b) {
      return a.view.viewOrder - b.view.viewOrder;
    });
  }

  return views;
};

export const getMemberCenterViewById = createSelector(
  [getMemberCenterView, (_, compId: string) => compId],
  (homeview, compId) => {
    if (homeview) {
      return homeview.find((content) => content.compId === compId);
    }
    return undefined;
  }
);

export const getMemberCenterInputFormatList = createSelector(
  [getMemberCenterView, (_, compId: string) => compId],
  (homeview, compId) => {
    let content: HomeViewContent | undefined = homeview?.find((content) => content.compId === compId);
    return getInputFormatListInView(content);
  }
);


export const getMemberCenterViewDefaultOrders = createSelector([getMemberCenterView], (homeview) => {
  if (homeview) {
    return homeview.find((content) => isMemberPurchasePageContent(content));
  }
  return undefined;
});

export const getMemberCenterViewDefaultContact = createSelector([getMemberCenterView], (homeview) => {
  if (homeview) {
    return homeview.find((content) => isMemberContactPageContent(content));
  }
  return undefined;
});

export const getMemberCenterViewDefaultAddress = createSelector([getMemberCenterView], (homeview) => {
  if (homeview) {
    return homeview.find((content) => isMemberAddressPageContent(content));
  }
  return undefined;
});


export const getMemberCenterDefaultView = (state: RootState) => {
  const views: HomeViewContent[] | undefined = getMemberCenterView(state);
  const orderView: HomeViewContent | undefined = getMemberCenterViewDefaultOrders(state);

  if (utils.isDefined(orderView)) {
    return orderView;
  }

  if (utils.isDefined(views)) {
    const sortedView = views!.sort(function (a, b) {
      return a.view.viewOrder - b.view.viewOrder;
    });
    return sortedView[0];
  }

  return undefined;
};

