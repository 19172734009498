import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import HtmlText from '@kopapro/components/shop/htmlText/htmlText';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getDataByComponent } from '@kopapro-redux/selectors/entities/shop';
import { HtmlComponent } from '@kopapro-redux/types/componentSetting';

interface IProps extends WithTranslation {
  compId: string;
  compName: string;
  showIn: string;
}

interface DispatchProps {}

interface StateProps {
  content: HtmlComponent;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const data = getDataByComponent(state, { ...props });
  return {
    content: data,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type HtmlTextProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(HtmlText));
