import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withNavigation, withParams, withLocation } from '@kopapro/components/commons/router-hoc';
import ProductDetail from '@kopapro-original/components/productDetail';
import { ContainerProps as KopaproProductDetailProps } from '@kopapro-original/components/productDetail';
import { getCartId } from '@kopapro-redux/selectors/entities/cart';

interface IProps extends WithTranslation {
  params: any; // from withParams}
}

interface StateProps {
  orderId: string;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    orderId: getCartId(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type ContainerProps = KopaproProductDetailProps & StateProps & DispatchProps;

const connected = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProductDetail));

export default withNavigation(withLocation(withParams(connected)));
