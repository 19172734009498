import Summary from '@kopapro-original/components/checkout/summary/summary';
import { Component, ReactNode } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import KopaproApi from '@kopapro-redux/api';
import { geti18nValue } from '@kopapro/utils/m18';

class CustomSummary extends Summary {
  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { checkedTerm } = this.state;
    const { t } = this.props;

    if (!checkedTerm) {
      this.setState({ errorMessage: t('ce01_kpptwa.checkAgreeTerm') });
      return;
    }

    const { orderSetup } = this.props;
    if (orderSetup.isNeedVerify) {
      this.setState({ showEmailVerifyModal: true });
    } else {
      this.confirmOrderHandler();
    }
  };

  handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: any = e.currentTarget.checked;
    this.setState({ checkedTerm: newValue });
  };

  componentDidMount() {
    this.loadDataHandler();
    this.getTermMsg();
  }

  getTermMsg = async () => {
    const portalUrl = KopaproApi.getPortalUrl();
    const classArgs = { portalUrl: portalUrl };

    const res = await KopaproApi.callServlet('0', 'KpptwaServletExtension', 'getTermMsg', classArgs);
    console.log('Called');
    if (res) {
      this.setState({ termMsg: res.termMsg });
    }
  };

  renderOrderInformation(): ReactNode {
    const { t } = this.props;
    const { checkedTerm, termMsg } = this.state;
    const termMsgDisplay = geti18nValue(termMsg);

    return (
      <div className="section">
        <h4 className="sub-title">{t('ce01_pmpcore.react.orderInfo')}</h4>
        <Row>
          {this.renderPayment()}
          {this.renderDelivery()}
        </Row>
        <Row>
          <Col xs={12} sm={6} md className="mb-4"></Col>
          <Col xs={12} sm={6} md className="mb-4"></Col>
          <Col xs={12} sm={6} md className="mb-4">
            <Form.Check
              onChange={this.handleValueChange}
              name="agreeTermsCond"
              required
              checked={checkedTerm}
              label={termMsgDisplay}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default CustomSummary;
