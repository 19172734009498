import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { CartType } from '@kopapro-redux/action-types';
import { getCartId } from '@kopapro-redux/selectors/entities/cart';
import KopaproApi from '@kopapro-redux/api';
import {
  cartRemoved,
  cartUpdated,
  giftListUpdated,
  redemptionListUpdated,
  requestingCart,
} from '@kopapro-redux/actions/cart';
import { memberTypeAlertUpdated, promotionAlertUpdated } from '@kopapro-redux/actions/cart';
import { CommonAction } from '@kopapro-redux/actions/misc';
import { getStorageItem, setStorageItem } from '@kopapro-redux/utils/cookies';
import utils from '@kopapro-redux/utils/utils';
import {
  ApplyGiftToCartAction,
  ApplyRedemptionToCartAction,
  BatchModifyItemInCartAction,
  CartItem,
  GiftRedemptionData,
  ModifyItemInCartAction,
  RemoveProductFromCartAction,
  SelectGiftRedemptionFromCartAction,
  ModifyItemInCartWithPriceAction,
  ModifyItemInCartMultipleAction,
} from '@kopapro-redux/types/cart';
import { MemberTypeAlert, PromotionAlert } from '@kopapro-redux/types/cart';
import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';
import { handleSessionKeySaga } from '@kopapro-redux/sagas/miscSaga';

// same as order
export type CartResponse = {
  entity: {
    orderId: string;
    data: CartItem[];
    sessionKey?: string;
    trafficSource?: string;
    referLink?: string;
  };
  status: boolean;
  message: string;
};

export function* modifyCartSaga(action: ModifyItemInCartAction) {
  try {
    const { proId, qty } = action.payload;
    const callback = action.callback;
    const orderId: string = yield select(getCartId);

    const resp: CartResponse = yield call(KopaproApi.modifyCartItem, orderId, proId, qty);

    // check order and merge response
    const resp2: CartResponse = yield call(KopaproApi.checkCart, resp.entity.orderId);

    if (resp2.entity && resp2.entity.data) {
      resp.entity.data = [...resp2.entity.data];
      resp.message = resp2.message;
    }
    yield call(cartUpdatedSaga, resp);

    //callback
    if (callback) {
      callback(resp.status, resp2.entity);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* modifyCartSagaMultiple(action: ModifyItemInCartMultipleAction) {
  try {
    const { proList } = action.payload;
    const callback = action.callback;
    const orderId: string = yield select(getCartId);

    const resp: CartResponse = yield call(KopaproApi.modifyCartItemMultiple, orderId, proList);

    // check order and merge response
    const resp2: CartResponse = yield call(KopaproApi.checkCart, resp.entity.orderId);

    if (resp2.entity && resp2.entity.data) {
      resp.entity.data = [...resp2.entity.data];
      resp.message = resp2.message;
    }
    yield call(cartUpdatedSaga, resp);

    //callback
    if (callback) {
      callback(resp.status, resp2.entity);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* modifyCartSagaPrice(action: ModifyItemInCartWithPriceAction) {
  try {
    const { proId, qty, greetPlateId } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    // const resp: CartResponse = yield call(KopaproApi.modifyCartItem, orderId, proId, qty);
    const resp: CartResponse = yield call(KopaproApi.modifyCartItem, orderId, greetPlateId, qty);
    // const resp1: CartResponse = yield call(KopaproApi.modifyCartItem, orderId, 4, 1);

    // check order and merge response
    const resp2: CartResponse = yield call(KopaproApi.checkCart, resp.entity.orderId);

    if (resp2.entity && resp2.entity.data) {
      resp.entity.data = [...resp2.entity.data];
      resp.message = resp2.message;
    }

    yield call(cartUpdatedSaga, resp);

    //callback
    if (callback) {
      callback(resp.status, resp2.entity);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* batchModifyCartSaga(action: BatchModifyItemInCartAction) {
  try {
    const { orderId, items } = action.payload;

    if (utils.isNotEmpty(orderId) && utils.isNotEmptyList(items)) {
      let lastResponse: CartResponse = {
        entity: {
          data: [],
          orderId: '',
        },
        status: false,
        message: '',
      };
      for (let item of items) {
        let proId = item.proId;
        let qty = item.qty;
        lastResponse = yield call(KopaproApi.modifyCartItem, orderId, proId, qty);
      }
      if (lastResponse.status) {
        yield call(cartUpdatedSaga, lastResponse);
      }
    }

    //callback
    // if (callback) {
    //   callback(lastResponse.status, lastResponse.entity);
    // }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* batchModifyCartAddOnSaga(action: BatchModifyItemInCartAction) {
  try {
    const { items } = action.payload;
    const callback = action.callback;
    const orderId: string = yield select(getCartId);

    if (utils.isNotEmptyList(items)) {
      let lastResponse: CartResponse = {
        entity: {
          data: [],
          orderId: '',
        },
        status: false,
        message: '',
      };
      for (let item of items) {
        let proId = item.proId;
        let qty = item.qty;
        lastResponse = yield call(KopaproApi.modifyCartItem, orderId, proId, qty);
      }

      const resp2: CartResponse = yield call(KopaproApi.checkCart, orderId);

      if (resp2.entity && resp2.entity.data) {
        lastResponse.entity.data = [...resp2.entity.data];
        lastResponse.message = resp2.message;
      }

      if (lastResponse.status) {
        yield call(cartUpdatedSaga, lastResponse);
      }

      //callback
      if (callback) {
        callback(lastResponse.status, lastResponse.entity);
      }
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* removeProductFromCartSaga(action: RemoveProductFromCartAction) {
  try {
    const { proId } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    const resp: CartResponse = yield call(KopaproApi.removeProductFromCart, orderId, proId);
    const resp2: CartResponse = yield call(KopaproApi.checkCart, resp.entity.orderId);
    if (resp2.entity && resp2.entity.data) {
      resp.entity.data = [...resp2.entity.data];
      resp.message = resp2.message;
    }
    let needRemove: boolean = false;
    if (resp.status && resp.entity.data.length <= 0) {
      needRemove = true;
    }

    if (needRemove) {
      yield call(cartRemovedSaga);
    } else {
      yield call(cartUpdatedSaga, resp);
    }

    //callback
    if (callback) {
      callback(resp.status, resp.entity);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* selectGiftRedemptionFromCartSaga(action: SelectGiftRedemptionFromCartAction) {
  try {
    const { proId, promoInfos, isSelect } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    const resp: CartResponse = yield call(
      KopaproApi.selectGiftRedemptionFromCart,
      orderId,
      proId,
      promoInfos,
      isSelect
    );
    yield call(cartUpdatedSaga, resp);

    //callback
    if (callback) {
      callback(resp.status, resp.entity);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* applyRedemptionToCartSaga(action: ApplyRedemptionToCartAction) {
  try {
    const { redeemList } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    const resp: CartResponse = yield call(KopaproApi.applyRedemptionToCart, orderId, redeemList);
    yield call(cartUpdatedSaga, resp);
    yield call(getCartInformationFromOrderSaga);

    //callback
    if (callback) {
      callback(resp.status, resp.entity);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* applyGiftToCartSaga(action: ApplyGiftToCartAction) {
  try {
    const { giftList } = action.payload;
    const callback = action.callback;

    const orderId: string = yield select(getCartId);

    const resp: CartResponse = yield call(KopaproApi.applyGiftToCart, orderId, giftList);
    yield call(cartUpdatedSaga, resp);
    yield call(getCartInformationFromOrderSaga);

    //callback
    if (callback) {
      callback(resp.status, resp.entity);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* getCartSaga(action?: CommonAction) {
  // if orderId then use Order, else
  try {
    yield put(requestingCart());
    // const { orderId } = action.payload;
    let orderId: string = yield select(getCartId);
    if (utils.isEmpty(orderId)) {
      orderId = getStorageItem('orderId');
    }
    if (utils.isNotEmpty(orderId)) {
      const resp: CartResponse = yield call(KopaproApi.retrieveCartItem, orderId);
      if (resp.status) {
        yield call(cartUpdatedSaga, resp);
        if (action && action.payload.isInit) {
          yield call(handleSessionKeySaga, resp.entity.sessionKey, resp.entity.trafficSource, resp.entity.referLink);
        }
      } else {
        yield call(cartRemovedSaga);
      }
    } else {
      yield call(cartRemovedSaga);
    }

    //callback
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* reloadCartSaga(orderId: string) {
  if (utils.isNotEmpty(orderId)) {
    setStorageItem('orderId', orderId);
    yield call(getCartSaga);
  }
}

export function* checkCartSaga(action?: CommonAction) {
  try {
    // const { orderId } = action.payload;
    const callback = action?.callback;

    const orderId: string = yield select(getCartId);

    const resp: CartResponse = yield call(KopaproApi.checkCart, orderId);
    yield call(cartUpdatedSaga, resp);

    //callback
    if (callback) {
      callback(resp.status, resp.message, resp.entity.data);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* cartUpdatedSaga(resp: CartResponse) {
  yield put(cartUpdated(resp));
}

export function* cartRemovedSaga() {
  yield put(cartRemoved());
}

export function* getPromotionAlertSaga(orderId: string) {
  const resp: { status: boolean; promoAlert: PromotionAlert } = yield call(KopaproApi.getPromotionAlert, orderId);
  if (resp.status) {
    yield put(promotionAlertUpdated({ alert: resp.promoAlert }));
  } else {
    yield put(promotionAlertUpdated({}));
  }
}

export function* getMemberTypeAlertSaga(orderId: string) {
  const isLogin: boolean = yield select(userLoggedIn);
  let checkMemberType = true;
  if (!isLogin) {
    checkMemberType = false;
  }
  if (checkMemberType) {
    const resp: { status: boolean; memberAlert: MemberTypeAlert } = yield call(KopaproApi.getMemberTypeAlert, orderId);
    if (resp.status) {
      yield put(memberTypeAlertUpdated({ alert: resp.memberAlert }));
    } else {
      yield put(memberTypeAlertUpdated({}));
    }
  }
}

export function* getCartInformationFromOrderSaga(action?: CommonAction) {
  let checkPromotion = true;
  let checkMemberType = true;
  let checkRedemption = true;
  let checkGift = true;
  try {
    const orderId: string = yield select(getCartId);

    // promotion alert
    if (checkPromotion) {
      yield call(getPromotionAlertSaga, orderId);
    }

    // member alert
    if (checkMemberType) {
      yield call(getMemberTypeAlertSaga, orderId);
    }

    // redemption list
    if (checkRedemption) {
      const resp: { status: boolean; redemptionList: { data: GiftRedemptionData[] } } = yield call(
        KopaproApi.getRedemptionList,
        orderId
      );
      if (resp.status) {
        yield put(redemptionListUpdated({ list: resp.redemptionList.data }));
      } else {
        yield put(redemptionListUpdated({}));
      }
    }

    // gift list
    if (checkGift) {
      const resp: { status: boolean; list: { data: GiftRedemptionData[] } } = yield call(
        KopaproApi.getGiftList,
        orderId
      );
      if (resp.status) {
        yield put(giftListUpdated({ list: resp.list.data }));
      } else {
        yield put(giftListUpdated({}));
      }
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function* cartSaga() {
  yield all([takeEvery(CartType.MODIFY_CART_ITEM_REQUEST, modifyCartSaga)]);
  yield all([takeEvery(CartType.MODIFY_CART_ITEM_REQUEST_PRICE, modifyCartSagaPrice)]);
  yield all([takeEvery(CartType.BATCH_MODIFY_CART_ITEM_REQUEST, batchModifyCartSaga)]);
  yield all([takeEvery(CartType.BATCH_MODIFY_CART_ITEM_REQUEST_ADD_ON, batchModifyCartAddOnSaga)]);
  yield all([takeEvery(CartType.GET_CART_REQUEST, getCartSaga)]);
  yield all([takeEvery(CartType.REMOVE_PRODUCT_FROM_CART_REQUEST, removeProductFromCartSaga)]);
  yield all([takeEvery(CartType.SELECT_GIFT_REDEMPTION_FROM_CART_REQUEST, selectGiftRedemptionFromCartSaga)]);
  yield all([takeEvery(CartType.APPLY_REDEMPTION_TO_CART, applyRedemptionToCartSaga)]);
  yield all([takeEvery(CartType.APPLY_GIFT_TO_CART, applyGiftToCartSaga)]);
  yield all([takeEvery(CartType.CHECK_CART_ITEM_REQUEST, checkCartSaga)]);
  yield all([takeEvery(CartType.LOAD_CART_INFORMATION, getCartInformationFromOrderSaga)]);
  yield all([takeEvery(CartType.MODIFY_CART_ITEM_MULTIPLE_REQUEST, modifyCartSagaMultiple)]);
}

export default cartSaga;
