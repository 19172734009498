import { withTranslation, WithTranslation } from 'react-i18next';
import { CategoryListContent } from '@kopapro-redux/types/componentSetting';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { getDataByComponent } from '@kopapro-redux/selectors/entities/shop';
import CategoryList  from '@kopapro/components/shop/categoryList/categoryList';

interface IProps extends WithTranslation {
  compId: string;
  compName: string;
  showIn: string;
}

interface StateProps {
  content?: CategoryListContent;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const { compId, compName } = props;
  return {
    content: getDataByComponent(state, { compId, compName })?.cateList,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};
export type CategoryListProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CategoryList));
