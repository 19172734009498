import { I18nDictionary } from '@kopapro-redux/types/utilities';
import Image from '@kopapro/components/productDetail/mainImage/image';

interface IProps {
  images?: string[];
  image: string;
  lite?: boolean;
  proIconsMore: {
    icon: string[];
    textLabel: I18nDictionary;
  };
  imageHeight?: number;
  productType?: string;
}
export type MainImageProps = IProps;

export default Image;
