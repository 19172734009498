import React, { Component, ReactNode } from 'react';
import { BlogListExtProps } from '@kopapro/components/blog/list/list';
import { BlogDetail } from '@kopapro-redux/types/blog';
import { Carousel, Col, Row } from 'react-bootstrap';
import { geti18nValue } from '@kopapro/utils/m18';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';

export class ExtendedCoverList extends Component<BlogListExtProps> {

  renderHeader = (item: BlogDetail): ReactNode => {
    const { openBlogDetail, renderHeaderComp } = this.props;
    const { blogId } = item;
    return (
      <div
        className={`header text-center col-12`}
        onClick={() => {
          openBlogDetail(blogId);
        }}>
        {renderHeaderComp && renderHeaderComp(item)}
      </div>
    );
  };

  renderItem = (item: BlogDetail): ReactNode => {
    const { showHashTags, showAuthor, showDate, t, openBlogDetail } = this.props;
    const { titleObj, detailObj, hashTags, author, lastModifyDate, blogId } = item;
    return (
      <Carousel.Item key={item.blogId}>
        <div className={`d-flex flex-column`}>
          <h5
            className={`tilte fw-bold mb-1 fs-5 btn text-start`}
            onClick={() => {
              openBlogDetail(blogId);
            }}>
            {geti18nValue(titleObj)}
          </h5>
          <Row className={`mb-1`} style={{ height: '50vh', overflow: 'hidden' }}>
            {this.renderHeader(item)}
          </Row>
          <Row className={`overflow-hidden mb-1 align-self-center`} style={{ height: '80px' }}>
            <SafetyDOM html={geti18nValue(detailObj)} />
          </Row>
          <Row className={`mb-1`}>
            {showHashTags && (
              <Col className={`text-start`}>
                {hashTags.map((tag: string, index: number) => {
                  return (
                    <span key={index} className={`ms-1 me-1 `}>
                      {'#' + tag}
                    </span>
                  );
                })}
              </Col>
            )}
            {showAuthor && (
              <Col className={`text-end`}>
                <label>{author}</label>
              </Col>
            )}
          </Row>
          {showDate && (
            <Row className={`mb-1 text-end`}>
              <label>{t('{date, full}', { date: lastModifyDate })}</label>
            </Row>
          )}
        </div>
      </Carousel.Item>
    );
  };

  render() {
    const { dataList } = this.props;

    return (
      <div className={`blog-list extend-cover`}>
        <Carousel style={{ height: '80vh' }} indicators={true} variant={`dark`}>
          {dataList.map((item: BlogDetail) => {
            return this.renderItem(item);
          })}
        </Carousel>
      </div>
    );
  }
}
