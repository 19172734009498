import { combineReducers } from 'redux';
import { MiscType } from '@app-redux/action-types';
import rootReducer from '@kopapro-redux/reducers';

const initialState = {
  showDemoMessage: false,
};

function appReducer(state = initialState, action: any) {
  // logic here
  switch (action.type) {
    case MiscType.SHOW_DEMO_MESSAGE:
      return {
        ...state,
        showDemoMessage: true,
      };
    case MiscType.HIDR_DEMO_MESSAGE:
      return {
        ...state,
        showDemoMessage: false,
      };

    default:
      return state;
  }
}

export const customReducer = combineReducers({
  app: appReducer,
});

const reducer = combineReducers({
  kopapro: rootReducer,
  local: customReducer,
});

export default reducer;