import { Link } from "react-router-dom";
import { Component } from "react";
import { geti18nValue, resolvePaymentMethodImageURL } from '@kopapro/utils/m18';
import ShopImage from '@kopapro/components/commons/shopImage';
import { deliveryMethodItemConfig as config } from '@kopapro/utils/config';
import { PaymentMethodItemProps } from '@kopapro/components/paymentMethod/item';

export default class PaymentMethodItem extends Component<PaymentMethodItemProps> {
  itemDefaultHeight = config.itemHeight;

  render() {
    const { item, imageHeight } = this.props;

    const paymentMethodName = geti18nValue(item.desc);
    return (
      <Link to={`/payment-methods/${item.code}`} className="payment-method-item col text-decoration-none">
        <div className="card shadow-sm">
          <ShopImage
            className="main-img card-img-top ratio ratio-1x1"
            height={imageHeight === 'unset' ? '' : imageHeight || this.itemDefaultHeight}
            alt=""
            src={resolvePaymentMethodImageURL(item)}
          />
          <div className="card-body">
            <h5 className="card-title text-center text-dark text-truncate">{paymentMethodName}</h5>
          </div>
        </div>
      </Link>
    );
  }
}
