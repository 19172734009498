import { RootState } from '@kopapro-redux/store';
import { getShopMeta } from '@kopapro-redux/selectors/entities/shop';
import { createSelector } from 'reselect';

export const getGoogleMeta = (state: RootState) => {
  return state.kopapro.shopMeta.setup.google;
};

export const getRecaptchaSetup = createSelector([getShopMeta], (meta) => {
  if (meta && meta.setup) {
    const { login, register } = meta.setup;
    // load google recaptcha js
    const googleCaptchaKey = login.captchaPublicKey || register.captchaPublicKey;
    const useCaptcha = login.useCaptcha || register.useCaptcha;
    const useGoogleCaptcha = login.captchaType === 'google' || register.captchaType === 'google';

    if (useCaptcha && useGoogleCaptcha && googleCaptchaKey) {
      return true;
    }
  }
  return false;
});
