import Delivery from '@kopapro-original/components/order/delivery/delivery';
import { Component, ReactNode } from 'react';
import { DeliveryProps } from '@kopapro/components/order/delivery';
import Col from 'react-bootstrap/Col';
import utils from '@kopapro-redux/utils/utils';
import ShopImage from '@kopapro/components/commons/shopImage';
import { orderConfig } from '@kopapro/utils/config';
import { geti18nValue } from '@kopapro/utils/m18';
import { formatMobile, formatPersonName, parseDatetime } from '@kopapro/utils/m18';
import DefaultModal from '@kopapro/components/commons/modals';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Button from 'react-bootstrap/Button';
import KopaproApi from '@kopapro-redux/api';

class CustomDelivery extends Delivery {
  renderShippingAddress(): ReactNode {
    const { t, delivery } = this.props;
    const { shipDetail, firstName, lastName, province, city, zipcode, mobileCountry } = delivery!;
    const { mobile, shipAd1, shipAd2, shipAd3, shipAd4, company, country, pickDate, pickTime, shipDate, shipTime } =
      delivery!;

    const name = formatPersonName(firstName, lastName);
    const chainedAddress = [province, city, zipcode].filter((text) => !utils.isEmpty(text)).join(', ');

    let pickupDateTimeText = '';
    let timeText = '';

    const mobileStr = formatMobile(mobile, mobileCountry);
    let messcode = 'ce01_pmpcore.react.shippingAddress';
    console.log(pickTime);
    if (shipDetail.selfPickUp) {
      messcode = 'ce01_pmpcore.react.pickUpAddress';
      const parsedDate = parseDatetime(pickDate, pickTime);
      if (parsedDate) {
        pickupDateTimeText = t('{date, long}', { date: parsedDate });
        timeText = this.parseShipTimeText(pickTime, 'pickup');
        console.log(timeText);
      }
    } else {
      const parsedDate = parseDatetime(shipDate, shipTime);
      if (parsedDate) {
        pickupDateTimeText = t('{date, long}', { date: parsedDate });
        timeText = this.parseShipTimeText(shipTime, 'ship');
      }
    }

    const addressComponents = [name, shipAd1 + shipAd2 + shipAd3 + shipAd4, country, mobileStr, company].filter(
      (text) => !utils.isEmpty(text)
    );

    return (
      <>
        <h5>{t(messcode)}</h5>
        <address>
          {addressComponents.map(function (address, index) {
            return (
              <span className="text-break" key={index}>
                {address} <br />
              </span>
            );
          })}
          <span>{pickupDateTimeText}</span>
          <div>
            <span>{timeText}</span>
          </div>
          {this.renderPickupRemark()}
        </address>
      </>
    );
  }

  parseShipTimeText = (shipTime: string, type: string) => {
    const { t } = this.props;
    if (type === 'ship') {
      switch (shipTime) {
        case '10:00':
          return '10:00am – 11:59am';
        case '12:00':
          return '12:00pm – 1:59pm';
        case '14:00':
          return '2:00pm – 3:59pm';
        case '16:00':
          return '4:00pm – 6:00pm';
        default:
          return shipTime;
      }
    } else if (type === 'pickup') {
      switch (shipTime) {
        case '13:00':
          return '1:00pm – 1:29pm';
        case '13:30':
          return '1:30pm – 1:59pm';
        case '14:00':
          return '2:00pm – 2:29pm';
        case '14:30':
          return '2:30pm – 2:59pm';
        case '15:00':
          return '3:00am – 3:29am';
        case '15:30':
          return '3:30pm – 3:59pm';
        case '16:00':
          return '4:00pm – 4:29pm';
        case '16:30':
          return '4:30pm – 4:59pm';
        case '17:00':
          return '5:00pm – 5:29pm';
        case '17:30':
          return '5:30pm – 6:00pm';
        default:
          return shipTime;
      }
    }

    return shipTime;
  };

  componentDidMount() {
    this.prepareDeliveryCondition();
  }

  prepareDeliveryCondition = async () => {
    const portalUrl = KopaproApi.getPortalUrl();
    const classArgs = { portalUrl: portalUrl };

    const res = await KopaproApi.callServlet('0', 'KpptwaServletExtension', 'getDeliveryCondition', classArgs);
    if (res) {
      this.setState({ deliveryCondition: res.deliveryCondition });
    }
  };

  renderShippingMethod(): ReactNode {
    const { t, delivery } = this.props;

    const { shipDetail } = delivery!;
    const { desc, image, showTerm } = shipDetail!;
    const methodName = geti18nValue(desc);
    return (
      <>
        <h5>{t('ce01_pmpcore.react.deliveryMethod')}</h5>
        <ShopImage className="mb-1" src={image} height={this.imageSize} />
        {this.showMethodName && <p className="image-text">{methodName}</p>}

        {showTerm && (
          <div>
            <Button className="modal-btn link-secondary" onClick={this.showTerm} variant="link">
              {t('ce01_kpptwa.deliveryCondition')}
              <HelpOutlineIcon fontSize="small" />
            </Button>
          </div>
        )}
      </>
    );
  }

  render(): ReactNode {
    const { t, delivery } = this.props;
    const { showModal, showPickupRemarkModal, deliveryCondition } = this.state;
    if (utils.isUndefined(delivery) || utils.isEmptyM18Id(delivery?.deliveryId)) {
      return null;
    }

    const { shipDetail, pickRemarks } = delivery!;
    const { term } = shipDetail;

    // const modalTitle = showModal ? 'ce01_pmpcore.react.order.shipTermTitle' : 'ce01_pmpcore.kopapro.react.pickupRemark';

    const modalTitle = showModal ? 'ce01_kpptwa.deliveryCondition' : 'ce01_kpptwa.deliveryCondition';
    // const modalBody = showModal ? geti18nValue(term) : pickRemarks;
    let deliCondDisplay = '';
    if (deliCondDisplay !== undefined) {
      deliCondDisplay = geti18nValue(deliveryCondition);
    }

    return (
      <>
        <Col xs={12} sm={6} md className="mb-4">
          {this.renderShippingMethod()}
        </Col>
        <Col xs={12} sm={6} md className="mb-4">
          {this.renderShippingAddress()}
        </Col>
        {this.renderShippingRemark()}

        <DefaultModal
          show={showModal || showPickupRemarkModal}
          centered={true}
          title={t(modalTitle)}
          body={deliCondDisplay}
          onCloseHandler={this.handleClose}
        />
      </>
    );
  }
}

export default CustomDelivery;
