import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Block from '@kopapro/components/pages/account/block/block';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  getMemberCenterViewById,
  getMemberCenterViewDefaultOrders,
  getMemberCenterViewDefaultContact,
  getMemberCenterViewDefaultAddress,
} from '@kopapro-redux/selectors/entities/member';

import { I18nDictionary } from '@kopapro-redux/types/utilities';
import { HomeView } from '@kopapro/utils/constants/constants';

interface IProps extends WithTranslation {
  compId: string;
  isHeader: boolean;
}

interface DispatchProps {}

interface StateProps {
  content: I18nDictionary | string;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const compId = props.compId || '';
  let content = getMemberCenterViewById(state, compId);

  if (compId === HomeView.MyPurchase) {
    content = getMemberCenterViewDefaultOrders(state);
  } else if (compId === HomeView.DeliveryAddress) {
    content = getMemberCenterViewDefaultAddress(state);
  } else if (compId === HomeView.ChangeContact) {
    content = getMemberCenterViewDefaultContact(state);
  }

  if (!content) {
    return {
      content: '',
    };
  }

  const view = content.view;
  return {
    content: props.isHeader ? view.headerHtml : view.footerHtml,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type BlockProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Block));
