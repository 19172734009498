import { RootState } from "@kopapro-redux/store";
import { getShopInitializing } from "@kopapro-redux/selectors/entities/shop";
import { isUpdating } from '@kopapro-redux/selectors/app';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, MapStateToProps } from 'react-redux';

// HOC
function LoadingSpinner(isLoading: boolean, isUpdating: boolean) {
  return (
    <>
      <div className="dots-spinner">
        <div>{isUpdating ? 'Updating' : 'Loading'}</div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  );
}

function withSplashScreen(WrappedComponent: any) {
  class SplashScreen extends Component<any> {
    render() {
      // while checking user session, show "loading" message
      if (this.props.updating) return LoadingSpinner(false, true);
      if (this.props.initializing) return LoadingSpinner(true, false);

      // otherwise, show the desired route
      return <WrappedComponent {...this.props} />;
    }
  }

  return withTranslation()(connect(mapStateToProps, null)(SplashScreen));
}

interface IProps extends WithTranslation {}

interface StateProps {
  initializing: boolean;
  updating: boolean;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    initializing: getShopInitializing(state),
    updating: isUpdating(state),
  };
};

export default withSplashScreen;
