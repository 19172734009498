import { gtag } from '@kopapro/components/misc/integration/google/google';
import { store } from '@kopapro-redux/store';
import { getCart, getCartAmountDetails } from '@kopapro-redux/selectors/entities/cart';
import { getBaseCurrency } from '@kopapro-redux/selectors/entities/currency';
import { CartItem } from '@kopapro-redux/types/cart';
import { geti18nValue } from '@kopapro/utils/m18';
import { Product } from '@kopapro-redux/types/products';
import { getOrder } from '@kopapro-redux/selectors/entities/order';
import { PayStatus } from '@kopapro-redux/utils/constant';

const getCurrencyCode = () => {
  const state = store.getState();
  const currency = getBaseCurrency(state) || { code: '' };
  return currency.code;
};

const convertToGAItem = (item: CartItem) => {
  return {
    item_id: item.proCode || item.proGpCode,
    item_name: geti18nValue(item.descObj),
    price: item.up,
    quantity: item.qty,
  };
};

const addPaymentInfo = (method: any) => {
  const state = store.getState();
  const cart = getCart(state);
  const value = getCartAmountDetails(state)?.totalAmount || 0;
  const currency = getCurrencyCode();
  // currency, value, coupon, items

  sendFacebookEvent('AddPaymentInfo', {
    currency,
    value,
    payment_type: method,
  });
  sendGoogleEcommerceEvent('add_payment_info', {
    currency,
    value,
    coupon: cart.discCodes.join(', '),
    payment_type: method,
    items: cart.items.map(convertToGAItem),
  });
};

const addToCart = (product: Product, qty: number) => {
  const currency = getCurrencyCode();
  const fbAttr = {
    content_name: geti18nValue(product.bDesc),
    contents: [{ id: product.code, quantity: qty }],
    content_type: 'product',
    value: product.up,
    currency,
  };
  sendFacebookEvent('AddToCart', fbAttr);
  sendGoogleEcommerceEvent('add_to_cart', {
    currency: currency,
    value: product.up,
    items: [
      {
        item_id: product.code || product.gpCode,
        item_name: geti18nValue(product.bDesc),
        price: product.up,
        qty,
      },
    ],
  });
};

const removeFromCart = (item: CartItem) => {
  const currency = getCurrencyCode();

  // sendFacebookEvent('RemoveFromCart', {}, false);
  sendGoogleEcommerceEvent('remove_from_cart', {
    currency,
    value: item.up * item.qty,
    items: [item].map(convertToGAItem),
  });
};

const addToWishlist = (product: Product) => {
  const currency = getCurrencyCode();

  sendFacebookEvent('AddToWishlist', {
    content_name: geti18nValue(product.bDesc),
    contents: [{ id: product.code }],
    content_type: 'product',
    value: product.up,
    currency,
  });
  sendGoogleEcommerceEvent('add_to_wishlist', {
    currency: currency,
    value: product.up,
    items: [
      {
        item_id: product.code || product.gpCode,
        item_name: geti18nValue(product.bDesc),
        price: product.up,
      },
    ],
  });
};

const initiateCheckout = () => {
  const state = store.getState();
  const cart = getCart(state);
  const value = getCartAmountDetails(state)?.totalAmount || 0;
  const currency = getCurrencyCode();
  // currency, value, coupon, items

  sendFacebookEvent('InitiateCheckout', {
    content_name: cart.items.map((item) => geti18nValue(item.descObj)).join(', '),
    content_ids: cart.items.map((item) => geti18nValue(item.proCode)).join(', '),
    content_type: 'product',
    value,
    currency,
    num_items: cart.items.length,
  });
  sendGoogleEcommerceEvent('begin_checkout', {
    currency,
    value,
    coupon: cart.discCodes.join(', '),
    items: cart.items.map(convertToGAItem),
  });
};

const search = (keyword: any) => {
  sendFacebookEvent('Search', { search_string: keyword });
  sendGoogleEcommerceEvent('search', { search_term: keyword });
};

const viewContent = (attr: any) => {
  sendFacebookEvent('ViewContent', attr);
};

const viewPage = (attr?: any) => {
  sendGoogleEvent('page_view');
};

const viewProduct = (product: Product) => {
  const currency = getCurrencyCode();

  sendGoogleEcommerceEvent('view_item', {
    currency,
    value: product.up,
    items: [
      {
        item_id: product.code || product.gpCode,
        item_name: geti18nValue(product.bDesc),
        price: product.up,
        quantity: 1,
      },
    ],
  });
};

// purchase success
const purchase = (orderId: string) => {
  const currency = getCurrencyCode();
  const state = store.getState();
  const order = getOrder(state);

  if (order && order.orderId === orderId) {
    const payment = order.payment;
    const orderInfo = order.orderInfo;
    if (orderInfo.payStatus === PayStatus.PAID) {
      if (payment && payment.paymDetail) {
        const paidDate = payment.paymDetail.paymentCompleteDate;
        const current = new Date().getTime();

        // if within 5 min
        if (current > paidDate && current - 1000 * 60 * 5 <= paidDate) {
          // sendFacebookEvent('Purchase', {
          //   content_name: order.items.map((item) => geti18nValue(item.descObj)).join(', '),
          //   content_ids: order.items.map((item) => geti18nValue(item.proCode)).join(', '),
          //   content_type: 'product',
          //   currency,
          //   value: order.amountDetails.totalAmount,
          //   num_items: order.items.length,
          // });
          sendGoogleEcommerceEvent('purchase', {
            transaction_id: orderInfo.orderCode,
            currency,
            value: order.amountDetails.totalAmount,
            shipping: order.amountDetails.shipFeeAmt,

            coupon: order.discCodes.join(', '),
            items: order.items.map(convertToGAItem),
          });
        }
      }
    }
  }
};

const sendFacebookEvent = (eventName: string, params?: any, isStandardEvent = true) => {
  // pixel event
  let trackType = isStandardEvent ? 'track' : 'trackCustom';

  console.log('sendFacebookEvent: ' + eventName, window.fbq);
  // or check metaSelector.setup.getFBPixel(state),
  if (window && window.fbq) {
    if (params) {
      window.fbq(trackType, eventName, params);
    } else {
      window.fbq(trackType, eventName);
    }
  }
};

const sendGoogleEvent = (eventName: string, params?: any) => {
  gtag('event', eventName, params ? params : {});
};

const sendGoogleEcommerceEvent = (event: string, ecommerce?: any) => {
  // window.dataLayer.push({ ecommerce: null });
  // window.dataLayer.push({ event, ecommerce });
  gtag('event', event, ecommerce);
};

export default {
  addPaymentInfo,
  search,
  viewPage,
  initiateCheckout,
  addToCart,
  removeFromCart,
  addToWishlist,
  purchase,
  viewProduct,
};
