import { getCompHeight, geti18nValue } from '@kopapro/utils/m18';
import { Component, ReactNode } from 'react';
import { HtmlTextProps } from '@kopapro/components/shop/htmlText';
import utils from '@kopapro/utils/utils';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';

interface HtmlTextState {}

export default class HtmlText extends Component<HtmlTextProps, HtmlTextState> {
  renderHtmlText(): ReactNode {
    const htmlStr: string = geti18nValue(this.props.content.html);
    return <SafetyDOM html={htmlStr} />;
  }

  render(): ReactNode {
    const { compId, content } = this.props;

    if (utils.isUndefined(content)) {
      return null;
    }

    const { heightOption, udfHeight } = content;

    const height: number = Number(getCompHeight(heightOption, udfHeight, 'auto'));
    return (
      <div className="container">
        <div
          id={`htmlText-${compId}`}
          className={`htmlText row ignore-height-xs`}
          style={{ height, minHeight: 'fit-content' }}>
          {this.renderHtmlText()}
        </div>
      </div>
    );
  }
}
