import React, { Component, ReactNode } from 'react';
import { BlogDetail, GetBlogListPayload } from '@kopapro-redux/types/blog';
import utils from '@kopapro/utils/utils';
import { addMonths, addSeconds, addYears, getMonth, getYear } from 'date-fns';
import { ListGroup } from 'react-bootstrap';
import { BlogListProps } from '@kopapro/components/blog/list';
import { ExtendedCoverList } from '@kopapro/components/blog/list/extendCover';
import { DefaultList } from '@kopapro/components/blog/list/default';
import { formatDate, getCustomStyle, getWindowScrollTop } from '@kopapro/utils/m18';
import RemindMessage from '@kopapro/components/commons/remindMessage';
import { DateList } from '@kopapro/components/blog/list/dateList';
import { CoverAndContentList } from '@kopapro/components/blog/list/coverAndContent';
import Accordion from 'react-bootstrap/Accordion';
import { ImageColumnList } from '@kopapro/components/blog/list/imageColumn';
import YouTube from '@kopapro/components/commons/youtube/youtube';
import ShopImage from '@kopapro/components/commons/shopImage';
import Pager from '@kopapro/components/commons/pager';
import { blogListConfig as config } from '@kopapro/utils/config';

// pass general function to sub list
export interface BlogListExtProps extends BlogListProps {
  dataList: BlogDetail[];
  openBlogDetail: Function;
  renderYearMonthSelector?: Function;
  renderEmptyRecordMessage?: Function;
  renderHeaderComp?: Function;
  square?: boolean; // for Squared Image Column
}

interface BlogListState {
  expandYear: number;
  blogList: BlogDetail[];
  // query
  querySize: number;
  startPoint: number;
  dataCount: number;
  startDate?: number | string | undefined;
  endDate?: number | string | undefined;
  page: number;
}

export default class BlogList extends Component<BlogListProps, BlogListState> {
  usePagination = config.usePagination;
  _isMounted = false;
  isUsePagination = (): boolean => {
    if (this.isFixedLength()) {
      return true;
    }
    return this.usePagination;
  };

  fetching = false;

  defaultState: BlogListState = {
    expandYear: getYear(new Date()),
    blogList: [],
    querySize: 0,
    startPoint: 0,
    dataCount: config.pageSize,
    page: 0,
  };

  listRef: React.RefObject<any>;

  constructor(props: BlogListProps) {
    super(props);
    this.state = this.defaultState;
    this.listRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.onPageChange(0);
    if (!this.isUsePagination()) {
      document.addEventListener('scroll', this.handleScroll);
    }
  }

  componentDidUpdate(prevProps: Readonly<BlogListProps>, prevState: Readonly<BlogListState>, snapshot?: any): void {
    if (this.props.blogCategory?.m18Id !== prevProps.blogCategory?.m18Id || prevProps.length !== this.props.length) {
      this.resetBlogList();
    }
    if (!this.isFixedLength()) {
      if (prevProps.sortBy !== this.props.sortBy) {
        this.resetBlogList();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (!this.isUsePagination()) {
      document.removeEventListener('scroll', this.handleScroll);
    }
  }

  isFixedLength = (): boolean => {
    if (utils.isNumber(this.props.length)) {
      return true;
    }
    return false;
  };

  resetBlogList = () => {
    const { blogList } = this.defaultState;
    this.setState({ blogList }, () => this.onPageChange(0));
  };

  loadBlogListWithParams = (
    page: number = 0,
    extParams: Omit<GetBlogListPayload, 'blogCat'> = {},
    callback?: Function
  ) => {
    const { loadBlogList, blogCategory, categoryId, length } = this.props;
    const { sortBy } = this.props;
    const self = this;

    let params: GetBlogListPayload = {
      ...extParams,
      blogCat: categoryId || blogCategory?.m18Id || 0,
    };

    if (this.isFixedLength()) {
      params.startPoint = 0;
      params.dataCount = length;
      params.sortBy = 'latest';
    } else {
      let startPoint = this.state.startPoint;
      let dataCount = this.state.dataCount;
      if (this.isUsePagination()) {
        if (page <= 0) {
          startPoint = this.defaultState.startPoint;
        } else {
          startPoint = page * dataCount;
        }
      } else {
        if (page <= 0) {
          startPoint = this.defaultState.startPoint;
        }
      }
      params.startPoint = startPoint;
      params.dataCount = dataCount;
      params.sortBy = sortBy || 'recommand';

      if (!params.startDate && this.state.startDate) {
        params.startDate = this.state.startDate;
      }
      if (!params.endDate && this.state.endDate) {
        params.endDate = this.state.endDate;
      }
    }

    this.fetching = true;

    loadBlogList(params, function (data: BlogDetail[], querySize: number) {
      let size: number = querySize;
      if (self.isFixedLength()) {
        size = length || 1;
      }
      self.onFetchHandler(data, size);
      if (callback) {
        callback();
      }
      self.fetching = false;
    });
  };

  onFetchHandler = (data: BlogDetail[], querySize: number) => {
    const { page, blogList: currentData } = this.state;
    if (this._isMounted) {
      if (!this.isUsePagination()) {
        if (this.isFixedLength()) {
          const newStartPoint = this.state.startPoint + data.length;
          this.setState({ blogList: [...currentData, ...data], startPoint: newStartPoint, querySize });
        } else {
          this.setState({ blogList: data, startPoint: 0, querySize });
        }
      } else {
        const newStartPoint = (this.state.querySize / this.state.dataCount) * page;
        this.setState({ blogList: data, startPoint: newStartPoint, querySize });
      }
    }
  };

  onPageChange = (newPage: number, extParams: Omit<GetBlogListPayload, 'blogCat'> = {}) => {
    const date = {
      startDate: extParams.startDate || this.state.startDate,
      endDate: extParams.endDate || this.state.endDate,
    };
    this.setState({ ...this.state, page: newPage, ...date });
    const { listRef, usePagination } = this;
    this.loadBlogListWithParams(newPage, extParams, function () {
      if (usePagination && listRef.current) {
        window.scrollTo(0, listRef.current.offsetTop - 50);
      }
    });
  };

  handleScroll = (e: Event): void => {
    if (this.listRef.current) {
      const scroll = window.innerHeight + getWindowScrollTop();
      const ref = this.listRef.current.offsetTop + this.listRef.current.offsetHeight;

      const { querySize, blogList } = this.state;
      const isScrolledToBottom = scroll >= ref;
      const canFetchMoreData = querySize > blogList.length;
      if (!this.fetching && isScrolledToBottom && canFetchMoreData) {
        this.onPageChange(this.state.page + 1);
      }
    }
  };

  openBlogDetail = (blogId: number) => {
    const { navigate } = this.props;
    navigate('/blog-detail/' + blogId);
  };

  getFontSize = (textSize: 'm' | 'l'): number => {
    switch (textSize) {
      case 'l':
        return 40;
      default:
        return 20;
    }
  };

  onYearClick = (year: number) => {
    const startDate: Date = new Date(year, 0, 1);
    const endDate: Date = addSeconds(addYears(startDate, 1), -1);
    this.onPageChange(0, { startDate: formatDate(startDate), endDate: formatDate(endDate) });
  };

  onMonthClick = (month: number) => {
    const { expandYear } = this.state;
    const startDate: Date = new Date(expandYear, month, 1);
    const endDate: Date = addSeconds(addMonths(startDate, 1), -1);
    this.onPageChange(0, { startDate: formatDate(startDate), endDate: formatDate(endDate) });
  };

  renderYearMonthSelector = (): ReactNode => {
    const { t } = this.props;

    const now: Date = new Date();
    const children: ReactNode[] = [];

    for (let i = 0; i < 5; ++i) {
      let resComp: ReactNode[] = [];
      const pastYear: Date = addYears(now, 0 - i);
      const year: number = getYear(pastYear);
      let month: number = 11;
      if (getYear(now) === year) {
        month = getMonth(now);
      }
      let emptyDate: Date = new Date('1900/01/01');
      for (let j = 0; j <= month; j++) {
        resComp.push(
          <ListGroup.Item
            key={`${i}-${j}`}
            className="ps-4"
            action
            onClick={() => {
              this.onMonthClick(month - j);
            }}>
            {t('{date, long-month}', {
              date: addMonths(emptyDate, month - j),
            })}
          </ListGroup.Item>
        );
      }

      children.push(
        <Accordion.Item eventKey={`${i}`} key={i}>
          <Accordion.Header onClick={(e) => this.onYearClick(year)}>
            {t('{date, custom}', {
              date: pastYear,
              dateFormat: { year: 'numeric' },
            })}
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <ListGroup variant="flush" className={`year-month-selector`}>
              {resComp}
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      );
    }
    //
    return <Accordion defaultActiveKey="0">{children}</Accordion>;
  };

  renderEmptyRecordMessage = (): ReactNode => {
    const { t } = this.props;
    return <RemindMessage className={`text-center`} message={t(`ce01_pmpcore.react.emptyRecord`)} />;
  };

  renderHeaderComp = (item: BlogDetail): ReactNode => {
    const { headerType, youtubeVideoId, photoCode, coverText, textSize, textColor, textBackgroundColor } = item;

    let headerComp: ReactNode = <></>;
    switch (headerType) {
      case 'youtube':
        headerComp = <YouTube width={`100%`} height={`100%`} videoId={youtubeVideoId} />;
        break;
      case 'photo':
        if (utils.isEmpty(photoCode)) {
          headerComp = <div style={{ backgroundColor: 'lightgray', height: '100%' }} />;
        } else {
          headerComp = <ShopImage src={photoCode} width={`100%`} height={`100%`} className={'cover'} />;
        }
        break;
      case 'text':
        let textStyle = getCustomStyle({
          useUdfStyle: true,
          fontSize: this.getFontSize(textSize),
          fontColor: textColor,
        });
        headerComp = (
          <div
            className="d-flex flex-column justify-content-center align-items-center h-100 text-break p-2"
            style={{ backgroundColor: textBackgroundColor }}>
            <h5 style={{ ...textStyle }}>{coverText}</h5>
          </div>
        );
        break;
      default:
        return <></>;
    }
    return headerComp;
  };

  renderPagination = () => {
    if (this.isUsePagination() && !this.isFixedLength()) {
      const { t } = this.props;
      const { page, dataCount, querySize: size } = this.state;
      const pageSize = dataCount;

      const indexOfLastRecord = (page + 1) * pageSize;
      const indexOfFirstRecord = indexOfLastRecord - pageSize;

      return (
        <div className="d-flex align-items-center mt-auto">
          <span className="text-muted ">
            {t('ce01_pmpcore.kopapro.react.pagination.showNumber', {
              currentFirstRecord: `${size <= 0 ? 0 : indexOfFirstRecord + 1}`,
              currentLastRecord: `${Math.min(indexOfLastRecord, size)}`,
              size,
            })}
          </span>
          <nav aria-label="Page navigation" className="ms-auto">
            <Pager current={page} itemPerPage={pageSize} total={size} onPageChange={this.onPageChange} />
          </nav>
        </div>
      );
    }
  };

  getComponentProps = () => {
    return {
      ...this.props,
      dataList: this.state.blogList,
      openBlogDetail: this.openBlogDetail,
      renderYearMonthSelector: this.renderYearMonthSelector,
      renderEmptyRecordMessage: this.renderEmptyRecordMessage,
      renderHeaderComp: this.renderHeaderComp,
    };
  };

  getDisplayMode = (): string => {
    const { blogCategory } = this.props;
    return this.props.displayMode || blogCategory?.displayMode;
  };

  render = (): ReactNode => {
    const displayMode = this.getDisplayMode();
    const childProps = this.getComponentProps();

    let tarListComp: ReactNode;
    switch (displayMode) {
      case 'verticalListWithImage':
        // Cover and Content List (has yearMonthSelector)
        tarListComp = <CoverAndContentList {...childProps} />;
        break;
      case 'verticalListWithLargeImage':
        // Extended Cover List
        tarListComp = <ExtendedCoverList {...childProps} />;
        break;
      case 'verticalListWithDate':
        // Date List (has yearMonthSelector)
        tarListComp = <DateList {...childProps} />;
        break;
      case 'columnListWithImage':
        // Image Column List
        tarListComp = <ImageColumnList {...childProps} />;
        break;
      case 'columnListWithSquareImage':
        // Squared Image Column List
        tarListComp = <ImageColumnList {...childProps} square />;
        break;
      default:
        // Default
        tarListComp = <DefaultList {...childProps} />;
        break;
    }

    return (
      <div ref={this.listRef}>
        {tarListComp}
        {/* pagination */}
        {this.renderPagination()}
      </div>
    );
  };
}
