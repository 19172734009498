import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import CartInfo from '@kopapro/components/checkout/cartInfo/cartInfo';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getCartDiscountCodes, getCartId, getCartItems } from '@kopapro-redux/selectors/entities/cart';
import { CartItem } from '@kopapro-redux/types/cart';
import { addDiscountCodesToOrder } from '@kopapro-redux/actions/checkout';
import { getShopOrderSetup } from '@kopapro-redux/selectors/entities/shop';
import { ShopCurrencySetup } from '@kopapro-redux/types/shop';
import { getDisplayCurrency } from '@kopapro-redux/selectors/entities/currency';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
interface IProps extends WithTranslation {
  currentSection: string;
  allowEditDiscount: boolean;
  navigate: any;
}

interface StateProps {
  currency?: ShopCurrencySetup;
  orderId: string;
  items?: CartItem[];
  discountCodes: string[];
  allowMultiDiscountCode: boolean;
}

interface DispatchProps {
  addDiscountCodes: typeof addDiscountCodesToOrder;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    currency: getDisplayCurrency(state),
    orderId: getCartId(state),
    items: getCartItems(state),
    discountCodes: getCartDiscountCodes(state),
    allowMultiDiscountCode: getShopOrderSetup(state).allowMutiDiscCode,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  addDiscountCodes: addDiscountCodesToOrder,
};

export type CheckoutCartInfoProps = IProps & StateProps & DispatchProps;
export default withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CartInfo)));
