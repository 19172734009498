import Template from '@kopapro/template/Template';
import ProductDetail from '@kopapro/components/productDetail';
import { Route, Routes } from 'react-router-dom';
import ProductList from '@kopapro/components/products/productList';
import Category from '@kopapro/components/products/category';

import '@kopapro/App.scss';
import Home from '@kopapro/components/home';
import withSplashScreen from '@kopapro/components/splashScreen/splashScreen';
import withExpiredScreen from '@kopapro/components/expiredScreen/expiredScreen';
import DeliveryMethodList from '@kopapro/components/deliveryMethod/list';
import DeliveryMethodDetail from '@kopapro/components/deliveryMethod/detail';
import AboutUs from '@kopapro/components/shop/aboutUs';
import Policy from '@kopapro/components/shop/policy';
import Login from '@kopapro/components/pages/login';
import UdfHtml from '@kopapro/components/shop/udfHtml';
import PaymentMethodList from '@kopapro/components/paymentMethod/list';
import PaymentMethodDetail from '@kopapro/components/paymentMethod/detail';
import NotFoundPage from '@kopapro/components/pages/notFound';
import Order from '@kopapro/components/order';
import Cart from '@kopapro/components/cart';
import Checkout from '@kopapro/components/checkout';
import ResetPassword from '@kopapro/components/resetPassword';
import Register from '@kopapro/components/pages/register';
import BlogDetail from '@kopapro/components/blog/detail';
import Account from '@kopapro/components/pages/account';
import Wishlist from '@kopapro/components/pages/account/wishlist';
import Orders from '@kopapro/components/pages/account/orders';
import ChangePassword from '@kopapro/components/pages/account/changePassword';
import Contact from '@kopapro/components/pages/account/contact';
import Coupons from '@kopapro/components/pages/account/coupons';
import CouponVouchers from '@kopapro/components/pages/account/coupons/vouchers';
import CouponVoucherLedger from '@kopapro/components/pages/account/coupons/ledger';
import Reward from '@kopapro/components/pages/account/reward';
import Payment from '@kopapro/components/payment';
import ContactUsPage from '@kopapro/components/pages/contactUs';
import NewsletterPage from '@kopapro/components/pages/newsletter';
import { kopaproConfig, toastOptions } from '@kopapro/utils/config';
import { isPageEnabled } from '@kopapro-redux/utils/m18';
// date picker localization
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS, zhCN, zhHK } from 'date-fns/locale';
import Address from '@kopapro/components/pages/account/address';
import Blogs from '@kopapro/components/blog/page';

// connect
import { getCurrentLang } from '@kopapro-redux/selectors/entities/user';
import { RootState } from '@kopapro-redux/store';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import Dynamic from '@kopapro/components/pages/account/dynamic';
import RouteRedirect from '@kopapro/components/misc/redirect';
import { CheckoutComponents } from '@kopapro/utils/constants/constants';
import OnePageCheckout from '@kopapro/components/order/onePageCheckout';
import PreviewCheckoutExtra from '@kopapro/components/preview/checkout-extra';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HomeViewContent } from '@kopapro-redux/types/componentSetting';
import { getMemberCenterView } from '@kopapro-redux/selectors/entities/member';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import EventUtils from '@kopapro/utils/event';
//route

const locales = { en: enUS, 'zh-TW': zhHK, 'zh-CN': zhCN };
const theme = createTheme({
  palette: {
    primary: {
      main: kopaproConfig.muiThemeMainColor,
    },
  },
});

function App({ lang, memberViews }: StateProps) {
  const locale = locales[lang as keyof typeof locales] || enUS;
  let location = useLocation();

  useEffect(() => {
    EventUtils.viewPage();

    if (location) {
      const { search, pathname } = location;

      if (pathname === '/products/search') {
        const keyword = search.replace('?q=', '');
        if (keyword.length > 0) {
          EventUtils.search(keyword);
        }
      } else if (pathname === '/checkout') {
        EventUtils.initiateCheckout();
      }
    }
  }, [location]);

  const backwardCompatibility = () => {
    return (
      <>
        {/* backward compatibility (Server side generated url only) */}

        {/* url parameter changed */}
        <Route path="/proPanel/:cateId" element={<RouteRedirect />} />
        <Route path="/proDetail/:m18ProGpId/:m18ProId" element={<RouteRedirect />} />
        {/* <Route path="/proDetail/:seoUrl" element={<RouteRedirect />} /> */}
        <Route path="/blog/:blogCategoryId" element={<RouteRedirect />} />

        {/* url parameter not change */}
        <Route path="/resetPw/:token" element={<RouteRedirect />} />
        <Route path="/orderRead/:orderId" element={<RouteRedirect />} />
        <Route path="/blogDetail/:blogId" element={<RouteRedirect />} />
        <Route path="/orderQuick/:orderToken" element={<RouteRedirect />} />

        <Route path="/reg" element={<RouteRedirect />} />
        <Route path="/shop" element={<RouteRedirect />} />
        <Route path="/aboutUs" element={<RouteRedirect />} />
        <Route path="/delivery" element={<RouteRedirect />} />
        <Route path="/payMethod" element={<RouteRedirect />} />
        <Route path="/proCategory" element={<RouteRedirect />} />
      </>
    );
  };

  const memberRoutes = () => {
    return (
      <>
        <Route path="account" element={<Account />} />
        {isPageEnabled(memberViews, '/account/orders') && <Route path="/account/orders" element={<Orders />} />}
        {isPageEnabled(memberViews, '/account/address') && <Route path="/account/address" element={<Address />} />}
        {isPageEnabled(memberViews, '/account/information') && (
          <Route path="/account/information" element={<Contact />} />
        )}
        {isPageEnabled(memberViews, '/account/schedule') && <Route path="/account/schedule" element={<Account />} />}
        {isPageEnabled(memberViews, '/account/coupons') && (
          <>
            <Route path="/account/coupons" element={<Coupons />} />
            <Route path="/account/coupons/:svId" element={<CouponVouchers />} />
            <Route path="/account/coupons/:svId/:svfId" element={<CouponVoucherLedger />} />
          </>
        )}
        {isPageEnabled(memberViews, '/account/wishlist') && <Route path="/account/wishlist" element={<Wishlist />} />}
        {isPageEnabled(memberViews, '/account/password') && (
          <Route path="/account/password" element={<ChangePassword />} />
        )}

        <Route path="/account/form" element={<Dynamic />} />
        <Route path="/account/reward" element={<Reward />} />
      </>
    );
  };

  return (
    <Template>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
          <Routes>
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/page-not-found" element={<NotFoundPage />} />
            <Route path="/" element={<Home />} />

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            <Route path="/reset-password/:token" element={<ResetPassword />} />

            <Route path="/categories" element={<Category />} />
            <Route path="/categories/:code" element={<ProductList />} />

            {/* search products */}
            <Route path="/products/search" element={<ProductList isSearch />} />
            {/* product detail */}
            <Route path="/products/:gpCode" element={<ProductDetail />} />
            <Route path="/products/item/:proCode" element={<ProductDetail />} />
            <Route path="/proDetail/:seoUrl" element={<ProductDetail />} />

            {/* delivery methods */}
            <Route path="/delivery-methods" element={<DeliveryMethodList />} />
            <Route path="/delivery-methods/:code" element={<DeliveryMethodDetail />} />
            {/* payment methods */}
            <Route path="/payment-methods" element={<PaymentMethodList />} />
            <Route path="/payment-methods/:code" element={<PaymentMethodDetail />} />
            {/* html */}
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/content/:compId" element={<UdfHtml />} />

            {/* contact us */}
            <Route path="/contact-us" element={<ContactUsPage />} />

            {/* newsletter */}
            <Route path="/subscribe" element={<NewsletterPage />} />

            {/* blog */}
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:code" element={<Blogs />} />
            <Route path="/blog-detail/:blogId" element={<BlogDetail />} />

            {/* order */}
            <Route path="/order/:orderId" element={<Order />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/checkout/summary" element={<Checkout currentSection={CheckoutComponents.Summary} />} />
            <Route path="/payment/:orderId" element={<Payment />} />
            <Route path="/one-page-checkout/:orderToken" element={<OnePageCheckout />} />
            <Route path="/preview/checkout-extra" element={<PreviewCheckoutExtra />} />

            {/* account / member center */}
            {memberRoutes()}
            {/* backward compatibility */}
            {backwardCompatibility()}
          </Routes>
        </LocalizationProvider>
      </ThemeProvider>
      <ToastContainer {...toastOptions} />
    </Template>
  );
}

interface IProps {}

interface DispatchProps {}

interface StateProps {
  lang: string;
  memberViews: HomeViewContent[] | undefined;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    lang: getCurrentLang(state),
    memberViews: getMemberCenterView(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};
export default withExpiredScreen(withSplashScreen(connect(mapStateToProps, mapDispatchToProps)(App)));
