import { Component, ReactNode } from 'react';
import { debounce } from 'lodash';
import {
  formatAmount,
  formatUnitPrice,
  geti18nValue,
  getRetailUnitPrice,
  isShowRetailUnitPrice,
  resolveProductItemImageURL,
} from '@kopapro/utils/m18';
import ShopImage from '@kopapro/components/commons/shopImage';
import { CartItemProps } from '@kopapro/components/cart/cartItem';
import { cartItemConfig } from '@kopapro/utils/config';
import { productDetailConfig } from '@kopapro/utils/config';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Currency } from '@kopapro-redux/utils/constant';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { AppImages } from '@kopapro/utils/constants/images';
import utils from '@kopapro/utils/utils';
import RedeemIcon from '@mui/icons-material/Redeem';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { Trans } from 'react-i18next';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import EventUtils from '@kopapro/utils/event';

interface CartItemState {
  quantity: string | number;
  sending: boolean;
  editing: boolean;
}

export default class CartItem extends Component<CartItemProps, CartItemState> {
  maxOrderQty = productDetailConfig.maxOrderQty;
  defaultState = {
    quantity: 0,
    sending: false,
    editing: false,
  };

  _isMounted = false;

  constructor(props: CartItemProps) {
    super(props);

    if (this.props.item) {
      this.state = { ...this.defaultState, quantity: this.props.item.qty };
    } else {
      this.state = this.defaultState;
    }

    this.changeQuantityHandler = debounce(this.changeQuantityHandler, 500);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps: CartItemProps, prevState: CartItemState): void {
    if (prevState.quantity === this.state.quantity && this.state.quantity !== this.props.item.qty) {
      if (!this.state.sending) {
        this.setState({ quantity: this.props.item.qty });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeQuantityHandler = () => {
    const { changeItemQuantity } = this.props;
    const self = this;

    const { item } = this.props;
    const proId = item.proId;
    const proQty = item.qty;
    let safeValue = utils.getSafetyNumber(this.state.quantity);
    let inputValue = safeValue > 0 ? safeValue : proQty;
    const quantity = Math.min(inputValue, this.maxOrderQty);
    const changeQty = quantity - proQty;

    if (changeQty === 0) {
      this.setState({ quantity: inputValue, editing: false });
      return;
    }

    this.setState({ quantity: inputValue, sending: true });

    // dispatch request
    changeItemQuantity({ qty: changeQty, proId: proId }, function (isSuccess: boolean) {
      if (self._isMounted) {
        self.setState({ sending: false, editing: false });
      }
      // updated, so set pending to false
      self.props.updatePending(item.proCode, false);

      if (isSuccess) {
        self.props.loadInformation();
      }
    });
  };

  removeProductHandler = () => {
    const { removeFromCart } = this.props;
    const self = this;

    const { item } = this.props;
    const proId = item.proId;

    this.setState({ sending: true });
    EventUtils.removeFromCart(item);
    // dispatch request
    removeFromCart({ proId: proId }, function (isSuccess: boolean) {
      if (self._isMounted) {
        self.setState({ sending: false });
      }
      if (isSuccess) {
        self.props.loadInformation();
      }
    });
  };

  selectGiftRedemptionHandler = () => {
    const { selectGiftRedemption } = this.props;
    const self = this;

    const { item } = this.props;
    const { proId, promoInfos, isExclude } = item;

    this.setState({ sending: true });

    // dispatch request
    selectGiftRedemption({ proId, promoInfos, isSelect: isExclude }, function (isSuccess: boolean) {
      self.setState({ sending: false });
      if (isSuccess) {
        self.props.loadInformation();
      }
    });
  };

  onChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { item } = this.props;
    const originalQty = item.qty;

    const newValue: any = e.currentTarget.value;
    let quantity = 1;

    if (utils.isEmpty(newValue)) {
      this.setState({ quantity: '', editing: true });
      return;
    } else if (!utils.isIntegerString(newValue)) {
      return;
    }
    quantity = Math.max(1, parseInt(newValue || 1));

    this.props.updatePending(item.proCode, originalQty !== quantity);
    this.setState({ quantity, editing: originalQty !== quantity });
  };

  qtyButtonHandler = (change: number) => {
    const { qty } = this.props.item;
    const { quantity } = this.state;
    const currentValue = Math.max(utils.getSafetyNumber(this.state.quantity), qty);
    let value = Math.min(Math.max(1, currentValue + change), this.maxOrderQty);
    if (value !== quantity) {
      this.setState({ quantity: value });
      this.changeQuantityHandler();
    }
  };

  getProductLink = () => {
    const { item } = this.props;
    const { proGpCode, proCode, proGpId, proId } = item;

    if (!utils.isEmpty(proGpCode)) {
      return `/products/${proGpCode}`;
    } else if (!utils.isEmpty(proCode)) {
      return `/products/item/${proCode}`;
    }

    return `/proDetail/${proGpId}/${proId}`;
  };

  renderNameAndAttribute(): ReactNode {
    const { item } = this.props;
    const desc = geti18nValue(item.descObj);
    const attr = geti18nValue(item.attrObj);
    return (
      <div>
        <Link to={this.getProductLink()} state={item.proId ? item.proId : null}>
          <h6 className="text-break">{desc}</h6>
        </Link>
        <div>
          <span className="d-block text-muted">{attr}</span>
        </div>
      </div>
    );
  }

  renderQuantity(): ReactNode {
    const { item, cartUpdating } = this.props;
    if (item.disableChangeQty) {
      return <div>{`x ${this.state.quantity}`}</div>;
    }

    const { sending } = this.state;
    const disable = sending || cartUpdating;
    return (
      <InputGroup className="quantity-input-group">
        <Button variant="link" className="less-btn" onClick={() => this.qtyButtonHandler(-1)} disabled={disable}>
          <RemoveIcon />
        </Button>
        <Form.Control
          name="quantity"
          value={this.state.quantity}
          type="number"
          className="quantity-input"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeQuantity(e)}
          onBlur={() => this.changeQuantityHandler()}
          disabled={disable}
        />
        <Button variant="link" className="add-btn" onClick={() => this.qtyButtonHandler(1)} disabled={disable}>
          <AddIcon />
        </Button>
      </InputGroup>
    );
  }

  renderPrice(): ReactNode {
    const { item } = this.props;
    const { isPackSubPro, isGift, isRedemption } = item;
    if (isPackSubPro || (isGift && !isRedemption)) {
      return null;
    }

    const { oriUp, up, adpUp } = item;

    let price: any = formatUnitPrice(up, Currency.SELECTED);

    const isShowRetail = isShowRetailUnitPrice(oriUp, up, adpUp);
    const retailUp = getRetailUnitPrice(oriUp, up, adpUp);

    if (isShowRetail) {
      price = (
        <div className="d-flex flex-column flex-sm-row">
          <del className="me-1">{`${formatUnitPrice(retailUp, Currency.SELECTED)}`}</del>
          <div className="text-danger">{`${formatUnitPrice(up, Currency.SELECTED)}`}</div>
        </div>
      );
    }

    return <>{price}</>;
  }

  renderAmount(): ReactNode {
    const { t, item } = this.props;
    const { isPackSubPro, isGift, isRedemption } = item;
    if (isPackSubPro || (isGift && !isRedemption)) {
      return null;
    }

    const amount: any = formatAmount(item.amt, Currency.SELECTED);
    return (
      <div>
        <span className="d-none d-sm-inline">{`${t('ce01_pmpcore.react.total')}: `}</span>
        {amount}
      </div>
    );
  }

  renderPromotion(): ReactNode {
    const { t, item } = this.props;
    const { sending } = this.state;
    const { isPackSubPro, isRedemption, isGift, isExclude } = item;

    let remark: ReactNode = null;
    if (isPackSubPro) {
      // do nothing
    } else if (isRedemption) {
      remark = (
        <Form.Check
          name="needRedemption"
          disabled={sending}
          onChange={() => this.selectGiftRedemptionHandler()}
          checked={!isExclude}
          label={<Trans t={t} i18nKey="ce01_pmpcore.react.needRedemption" />}
        />
      );
    } else if (isGift) {
      remark = (
        <Form.Check
          name="needGift"
          disabled={sending}
          onChange={() => this.selectGiftRedemptionHandler()}
          checked={!isExclude}
          label={<Trans t={t} i18nKey="ce01_pmpcore.react.needGift" />}
        />
      );
    } else {
      remark = geti18nValue(item.ppDescObj)
        .split('\n')
        .map((message) => {
          return <div key={`remark-${message}`}>{message} </div>;
        });
    }

    return (
      <div>
        <div className="small">{geti18nValue(item.discDescObj)}</div>
        <div>{remark}</div>
      </div>
    );
  }

  renderProductTypeIcon(): ReactNode {
    const { t, item } = this.props;
    const { isPackSubPro, isRedemption, isGift } = item;

    let remark: ReactNode = null;
    let promotionDesc = geti18nValue(item.ppDescObj);
    if (!utils.isEmpty(promotionDesc)) {
      promotionDesc = `(${promotionDesc})`;
    }
    if (isPackSubPro) {
      remark = (
        <>
          <Inventory2OutlinedIcon /> {t('ce01_pmpcore.react.packageProduct')}
        </>
      );
    } else if (isRedemption) {
      remark = (
        <>
          <RedeemIcon /> {t('ce01_pmpcore.react.redemption')} {promotionDesc}
        </>
      );
    } else if (isGift) {
      remark = (
        <>
          <RedeemIcon /> {t('ce01_pmpcore.react.gift')} {promotionDesc}
        </>
      );
    }

    return <>{remark}</>;
  }

  renderProductError(): ReactNode {
    const { proRowStatus, checkMsgDescObj } = this.props.item;
    if (proRowStatus === 'error') {
      return <ErrorMessage message={geti18nValue(checkMsgDescObj)} />;
    }
    return null;
  }

  render(): ReactNode {
    const { sending, editing } = this.state;
    const { item, cartUpdating } = this.props;

    const photoCode = item.photoCode;
    return (
      <ListGroup.Item className="cart-item">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <Link to={this.getProductLink()} state={item.proId ? item.proId : null}>
              <ShopImage
                src={resolveProductItemImageURL(photoCode, item.isSV)}
                height={cartItemConfig.imageSize}
                width={cartItemConfig.imageSize}
                className="contain"
                fallbackImage={AppImages.productPlaceholder}
              />
            </Link>
            <div className="ms-3">
              {this.renderNameAndAttribute()}
              <div className="mt-1">{this.renderProductTypeIcon()}</div>
              <div className="mt-1">{this.renderPrice()}</div>
            </div>
          </div>
          {!item.disableDeletePro && (
            <div className="text-end">
              <button
                className="btn px-2"
                onClick={() => this.removeProductHandler()}
                disabled={sending || editing || cartUpdating}>
                <DeleteIcon />
              </button>
            </div>
          )}
        </div>

        <div className="mt-2 mb-2 d-flex justify-content-end">{this.renderQuantity()}</div>

        <div className="mt-2 mb-2 d-flex justify-content-between">
          {this.renderPromotion()}
          {this.renderAmount()}
        </div>

        <div className="mt-2 mb-2">{this.renderProductError()}</div>
      </ListGroup.Item>
    );
  }
}
