import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Theme from '@kopapro/components/misc/themeBuilder/theme';
import { getShopInfo, getDesktopHeaderStyle, getMobileHeaderStyle } from '@kopapro-redux/selectors/entities/shop';
import { BasicStyle } from '@kopapro-redux/types/componentSetting';
interface IProps {}
interface StateProps {
  css: string;
  enableCSS: boolean;
  backgroundImage?: string;
  mobileBackgroundImage?: string;
  desktopStyle?: BasicStyle;
  mobileStyle?: BasicStyle;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const shopInfo = getShopInfo(state);
  const data = shopInfo?.customCSS || { css: '', enableCSS: false };
  return {
    ...data,

    backgroundImage: shopInfo?.background,
    mobileBackgroundImage: shopInfo?.backgroundMobile,
    desktopStyle: getDesktopHeaderStyle(state),
    mobileStyle: getMobileHeaderStyle(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type CustomCssProps = IProps & StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Theme);
