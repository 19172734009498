import Blogs from '@kopapro/components/blog/page/blogs';
import { withParams } from '@kopapro/components/commons/router-hoc';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { getBlogCategoryByCode } from '@kopapro-redux/selectors/entities/blog';
import { BlogCategory } from '@kopapro-redux/types/blog';
interface IProps extends WithTranslation {
  params: any; //withParam
}

interface StateProps {
  blogCategory?: BlogCategory;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const code: string = props.params['code'];
  return {
    blogCategory: getBlogCategoryByCode(state, code),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type BlogPageProps = IProps & StateProps & DispatchProps;
export default withParams(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Blogs)));
