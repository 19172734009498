import Google from '@kopapro/components/misc/integration/google';
import Facebook from '@kopapro/components/misc/integration/facebook';

export default function Integration() {
  return (
    <>
      <Google />
      <Facebook />
    </>
  );
}
