import { Component, ReactNode } from 'react';
import {
  formatBaseAmount,
  formatDate,
  geti18nValue,
  getLedgerClosingBalance,
  getLedgerOpenBalance,
} from '@kopapro/utils/m18';
import { Card, Table, Form, Col, Row, Button } from 'react-bootstrap';
import { LedgerProps } from '@kopapro/components/pages/account/coupons/ledger';
import { CouponVoucherLedger, CouponVoucherLedgerData } from '@kopapro-redux/types/coupon';
import ShopImage from '@kopapro/components/commons/shopImage';
import { AppImages } from '@kopapro/utils/constants/images';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { memberCenterConfig } from '@kopapro/utils/config';
import ErrorMessage from '@kopapro/components/commons/errorMessage';

interface LedgerState {
  sending: boolean;
  successMessage: string;
  errorMessage: string;
  startDate: Date | null;
  endDate: Date | null;
  ledgerStartDate: Date;
  ledgerEndDate: Date;
  ledger: CouponVoucherLedger;
  showLedger: boolean;
}

export default class Ledger extends Component<LedgerProps, LedgerState> {
  defaultLedger = {
    svDesc: '',
    svDescObj: { en: '' },
    svfCode: '',
    photoCode: '',
    openBal: 0,
    closeBal: 0,
    expireBal: 0,
    data: [],
  };

  defaultState = {
    sending: false,
    successMessage: '',
    errorMessage: '',
    startDate: memberCenterConfig.coupons.defaultStartDate,
    endDate: memberCenterConfig.coupons.defaultEndDate,
    ledgerStartDate: memberCenterConfig.coupons.defaultStartDate,
    ledgerEndDate: memberCenterConfig.coupons.defaultEndDate,
    ledger: this.defaultLedger,
    showLedger: true,
  };

  constructor(props: LedgerProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount() {
    this.loadRecords();
  }

  loadRecords = () => {
    const { loadLedger, svId, svfId } = this.props;
    const self = this;

    this.setState({ sending: true, successMessage: '', errorMessage: '' });

    const startDate = this.state.startDate!;
    const endDate = this.state.endDate!;

    if (startDate === null) {
      this.setErrorState('ce01_pmpcore.kopapro.react.askInputDate');
      return;
    } else if (formatDate(startDate) === 'Invalid Date') {
      this.setErrorState('ce01_pmpcore.kopapro.react.fieldInvalidDate');
      return;
    }

    if (endDate === null) {
      this.setErrorState('ce01_pmpcore.kopapro.react.askInputDate');
      return;
    } else if (formatDate(endDate) === 'Invalid Date') {
      this.setErrorState('ce01_pmpcore.kopapro.react.fieldInvalidDate');
      return;
    }

    if (startDate > endDate) {
      this.setErrorState('ce01_pmpcore.react.sDateLaterEDate');
      return;
    }

    loadLedger({ svId, svfId, startDate, endDate }, function (isSuccess: boolean, content: CouponVoucherLedger) {
      if (isSuccess) {
        self.setState({
          sending: false,
          ledger: content,
          ledgerStartDate: startDate,
          ledgerEndDate: endDate,
          showLedger: true,
        });
      }
    });
  };

  setErrorState = (message: string) => {
    this.setState({
      sending: false,
      successMessage: '',
      errorMessage: message,
      showLedger: false,
    });
  };

  backToVouchers = () => {
    const { navigate, svId } = this.props;
    navigate(`/account/coupons/${svId}`);
  };

  renderLedgerItem = (item: CouponVoucherLedgerData): ReactNode => {
    const { t } = this.props;
    const { ledger } = this.state;
    const vouOneOff = ledger.vouOneOff;
    const svfIniBal = ledger.svfIniBal || 1;
    const svLedger = vouOneOff ? item.svLedger * svfIniBal : item.svLedger;
    const balance = vouOneOff ? item.balance * svfIniBal : item.balance;
    return (
      <tr key={`${item.sourceCode}`}>
        <td>{t('{ date, short }', { date: item.sourceDate })}</td>
        <td>{`${item.sourceCode}`}</td>
        <td className="text-primary">{`${svLedger > 0 ? formatBaseAmount(Math.abs(svLedger)) : ''}`}</td>
        <td className="text-danger">{`${svLedger < 0 ? '-' + formatBaseAmount(Math.abs(svLedger)) : ''}`}</td>
        <td className="text-end">{formatBaseAmount(balance)}</td>
      </tr>
    );
  };

  renderLedger(): ReactNode {
    const { t } = this.props;
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>{t('ce01_pmpcore.react.date')}</th>
            <th>{t('ce01_pmpcore.react.invoiceNo')}</th>
            <th>{t('ce01_pmpcore.react.gain')}</th>
            <th>{t('ce01_pmpcore.react.less')}</th>
            <th className="text-end">{t('ce01_pmpcore.react.balance')}</th>
          </tr>
        </thead>
        <tbody>
          {this.state.ledger.data.map((item) => {
            return this.renderLedgerItem(item);
          })}
        </tbody>
      </Table>
    );
  }

  renderDateRange(): ReactNode {
    const { t } = this.props;
    const { ledgerStartDate, ledgerEndDate } = this.state;
    return (
      <Row>
        <Col className="text-center">
          {`${t('ce01_pmpcore.react.from')} ${formatDate(ledgerStartDate)} ${t('ce01_pmpcore.react.to')} ${formatDate(
            ledgerEndDate
          )}`}
        </Col>
      </Row>
    );
  }

  renderOpeningBalance(): ReactNode {
    const { t } = this.props;
    const { ledger } = this.state;
    const openBal = getLedgerOpenBalance(ledger);
    return (
      <Row>
        <Col>{t('ce01_pmpcore.react.openingBal')}</Col>
        <Col className="text-end">{formatBaseAmount(openBal)}</Col>
      </Row>
    );
  }

  renderClosingBalance(): ReactNode {
    const { t } = this.props;
    const { ledger } = this.state;
    const closeBal = getLedgerClosingBalance(ledger);
    return (
      <Row>
        <Col>{t('ce01_pmpcore.react.closingBal')}</Col>
        <Col className="text-end">{formatBaseAmount(closeBal)}</Col>
      </Row>
    );
  }

  renderBody(): ReactNode {
    const { t } = this.props;
    const { sending, showLedger } = this.state;
    return (
      <>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>{t('ce01_pmpcore.react.dateFrom')}</Form.Label>
              <DatePicker
                value={this.state.startDate}
                inputFormat={'yyyy-MM-dd'}
                onChange={(value) => this.setState({ startDate: value })}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="d-flex align-items-end flex-column date-picker">
                    <Form.Control ref={inputRef} {...inputProps} />
                    {InputProps?.endAdornment}
                  </div>
                )}
                disabled={sending}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>{t('ce01_pmpcore.react.dateTo')}</Form.Label>
              <DatePicker
                value={this.state.endDate}
                inputFormat={'yyyy-MM-dd'}
                onChange={(value) => this.setState({ endDate: value })}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="d-flex align-items-end flex-column date-picker">
                    <Form.Control ref={inputRef} {...inputProps} />
                    {InputProps?.endAdornment}
                  </div>
                )}
                disabled={sending}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <ErrorMessage message={t(this.state.errorMessage)} />
        </Row>

        <Row className="mb-3">
          <Col>
            <Button variant="link" disabled={sending} onClick={() => this.backToVouchers()}>
              <ArrowBackIcon />
              {t('ce01_pmpcore.react.backBtn')}
            </Button>
          </Col>

          <Col className="text-end">
            <Button variant="main" disabled={sending} onClick={() => this.loadRecords()}>
              <SearchIcon />
              {t('ce01_pmpcore.react.search')}
            </Button>
          </Col>
        </Row>

        {showLedger && (
          <Card>
            <Card.Header>{this.renderDateRange()}</Card.Header>
            <Card.Header>{this.renderOpeningBalance()}</Card.Header>
            <Card.Body>{this.renderLedger()}</Card.Body>
            <Card.Footer>{this.renderClosingBalance()}</Card.Footer>
          </Card>
        )}
      </>
    );
  }

  render(): ReactNode {
    const { viewName } = this.props;
    const { ledger } = this.state;

    const title = `${geti18nValue(viewName)} - ${geti18nValue(ledger.svDescObj)} (#${ledger.svfCode})`;
    const photoCode = ledger.photoCode;
    return (
      <Card className="border-0 account-coupons-voucher-ledger">
        <Card.Body>
          <Card.Title>
            <ShopImage className="title-logo" src={photoCode} fallbackImage={AppImages.couponPlaceholder} width={30} />
            <span className="text-break">{title}</span>
          </Card.Title>
          <hr />

          {this.renderBody()}
        </Card.Body>
      </Card>
    );
  }
}
