import React, { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function UploadButton(inputProps: any) {
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };
  const handleDisplayFileDetails = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { key, onChange } = inputProps;
    if (inputRef.current?.files) {
      setUploadedFileName(inputRef.current.files[0].name);
      if (onChange) {
        onChange(e, key);
      }
    }
  };

  const { key, onChange, t, ...data } = inputProps;
  return (
    <div>
      <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file" {...data} />

      <InputGroup className="mb-3">
        <Button variant="" className="border bg-light text-dark" id={`button-addon_${key}`} onClick={handleUpload}>
          {t('ce01_pmpcore.kopapro.react.imageUploadButton.label')}
        </Button>
        <Form.Control
          readOnly
          style={{ backgroundColor: 'inherit', cursor: 'pointer' }}
          aria-label="file input"
          aria-describedby={`button-addon_${key}`}
          value={uploadedFileName || t('ce01_pmpcore.kopapro.react.imageUploadButton.emptyValue')}
          onClick={handleUpload}
        />
      </InputGroup>
    </div>
  );
}

export default UploadButton;
