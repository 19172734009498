import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { RedirectProps } from '@kopapro/components/misc/redirect';
import utils from '@kopapro-redux/utils/utils';
import React, { useCallback } from 'react';
import { ProductCategory } from '@kopapro-redux/types/productCategory';
import { Product, ProductGroup } from '@kopapro-redux/types/products';
import { BlogCategory } from '@kopapro-redux/types/blog';

export default function Redirect(props: RedirectProps) {
  let navigate = useNavigate();
  let location = useLocation();
  let params = useParams();

  const changeRoute = useCallback((pathname: string, search: string) => {
    let to = '';
    if (pathname.startsWith('/resetPw/')) {
      to = pathname.replace('/resetPw/', '/reset-password/');
    } else if (pathname.startsWith('/orderRead/')) {
      to = pathname.replace('/orderRead/', '/order/');
    } else if (pathname.startsWith('/shop')) {
      to = pathname.replace('/shop', '/');
    } else if (pathname.startsWith('/blogDetail/')) {
      to = pathname.replace('/blogDetail/', '/blog-detail/');
    } else if (pathname.startsWith('/reg')) {
      to = pathname.replace('/reg', '/register');
    } else if (pathname.startsWith('/aboutUs')) {
      to = pathname.replace('/aboutUs', '/about-us');
    } else if (pathname.startsWith('/delivery')) {
      to = pathname.replace('/delivery', '/delivery-methods');
    } else if (pathname.startsWith('/payMethod')) {
      to = pathname.replace('/payMethod', '/payment-methods');
    } else if (pathname.startsWith('/proCategory')) {
      to = pathname.replace('/proCategory', '/categories');
    } else if (pathname.startsWith('/orderQuick/')) {
      to = pathname.replace('/orderQuick/', '/one-page-checkout/');
    }

    // need call API
    if (pathname.startsWith('/proPanel/')) {
      const { cateId } = params;

      // /proPanel/:cateId to /categories/:code
      if (cateId === '0') {
        return navigate('/categories/All', { replace: true });
      } else {
        const { fetchProductCategories } = props;
        fetchProductCategories(null, function (categories: ProductCategory[]) {
          const match = categories.find((category) => String(category.m18Id) === cateId);
          if (match) {
            return navigate(`/categories/${match.code}`, { replace: true });
          } else {
            return navigate('/categories/All', { replace: true });
          }
        });
      }
    } else if (pathname.startsWith('/proDetail/')) {
      // /proDetail/:m18ProGpId/:m18ProId to /products/:gpCode or /products/item/:proCode
      const { m18ProGpId, m18ProId, seoUrl } = params;
      const { fetchProduct } = props;
      fetchProduct(
        { proId: utils.getSafetyNumber(m18ProId!), gpId: utils.getSafetyNumber(m18ProGpId!), seoUrl },
        function (product: Product, productGroup: ProductGroup) {
          if (productGroup && productGroup.m18ProGpId > 0) {
            if (m18ProId === '0') {
              return navigate(`/products/${productGroup.code}`, { replace: true });
            }
            return navigate(`/products/${productGroup.code}`, { replace: true, state: m18ProId });
          } else if (product) {
            return navigate(`/products/item/${product.code}`, { replace: true });
          } else {
            return navigate('/categories/All', { replace: true });
          }
        }
      );
    } else if (pathname.startsWith('/blog/')) {
      const { blogCategoryId } = params;
      const { fetchBlogCategories } = props;

      fetchBlogCategories(null, function (blogCategories: BlogCategory[]) {
        const match = blogCategories.find((blogCategory) => String(blogCategory.m18Id) === blogCategoryId);
        if (match) {
          return navigate(`/blogs/${match.code}`, { replace: true });
        }
      });
    }

    if (utils.isNotEmpty(search)) {
      to = to + search;
    }
    if (to !== '') {
      navigate(to, { replace: true });
    }
  }, []);

  React.useEffect(() => {
    const { pathname, search } = location;
    changeRoute(pathname, search);
  }, [location, changeRoute]);

  return null;
}
