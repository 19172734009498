import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import CakeOptionFilter from './cakeOptionFilter';
import { RootState } from '../../../../../../src/kopapro-redux/store';

interface IProps extends WithTranslation {
  title: string;
  type: string;
  onCakeOptionChange: Function;
}

interface StateProps {}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type CakeOptionProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CakeOptionFilter));
