import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from "@kopapro-redux/store";
import Currnecy from "@kopapro/components/header/components/currency/currency";
import { withTranslation, WithTranslation } from 'react-i18next';

import { ShopCurrencySetup } from '@kopapro-redux/types/shop';
import { getCurrencies } from '@kopapro-redux/selectors/entities/currency';
import { getSelectedCurrency } from '@kopapro-redux/selectors/entities/user';
import { changeCurrency } from '@kopapro-redux/actions/user';

interface IProps extends WithTranslation {
  style?: any;
  fontStyle?: any;
}

interface StateProps {
  currencies: ShopCurrencySetup[] | undefined;
  selectedCurrency: string;
}

interface DispatchProps {
  changeCurrency: typeof changeCurrency;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    currencies: getCurrencies(state),
    selectedCurrency: getSelectedCurrency(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  changeCurrency: changeCurrency,
};

export type CurrencyDropdownProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Currnecy));
