import { RootState } from '@kopapro-redux/store';
import { getComponentSetting, getNavbar, getFooter } from '@kopapro-redux/selectors/entities/shop';
import {
  ShopNavbarLayoutContent,
  ShopNavbarLayoutComponent,
  ShopLayoutContent,
  ShopLayoutComponent,
} from '@kopapro-redux/types/shop';

export const getUdfHtmlTitle = (state: RootState, compId: string = '') => {
  const udfMenu: ShopNavbarLayoutContent = getNavbar(state)!;
  if (udfMenu) {
    const comps: ShopNavbarLayoutComponent[] = udfMenu.comps;
    const obj = comps.find((element) => element.compId === compId);
    if (obj) {
      return obj.compDesc;
    }
  }

  const udfFooterMenu: ShopLayoutContent = getFooter(state)!;
  if (udfFooterMenu) {
    const comps: ShopLayoutComponent[] = udfFooterMenu.comps;
    const obj = comps.find((element) => element.compId === compId);
    if (obj) {
      return obj.compDesc;
    }
  }
  return { en: '' };
};

export const getUdfHtmlContent = (state: RootState, compId: string = '') => {
  const list = getComponentSetting(state).udfhtml || [];
  const obj = list.find((element) => element.compId === compId);
  return obj ? obj.html : { en: '' };
};
