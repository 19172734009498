import { createSelector } from "reselect";
import { RootState } from "@kopapro-redux/store";
import { DeliveryMethod } from '@kopapro-redux/types/deliveryMethod';

export const getDeliveryMethods = (state: RootState) => {
  return state.kopapro.deliveryMethods.methods;
};

export const getDeliveryMethodList = (state: RootState) => {
  let list: DeliveryMethod[] = [];
  if (state) {
    const methods = state.kopapro.deliveryMethods.methods;
    list = Object.values(methods);
  }
  return list;
};

export const getDeliveryMethodByCode = createSelector(
  [getDeliveryMethods, (state, code: string) => code],
  (methods, code) => {
    if (methods) {
      return methods[code];
    }
    return undefined;
  }
);
