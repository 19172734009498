import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import Extra from '@kopapro/components/order/extra/extra';
import { OrderExtraInfoField } from '@kopapro-redux/types/order';
import { getDisplayOrderExtraInfo } from '@kopapro-redux/selectors/entities/order';

interface IProps extends WithTranslation {}

interface StateProps {
  fields?: OrderExtraInfoField[];
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    fields: getDisplayOrderExtraInfo(state)?.fields,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type ExtraProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Extra));
