import { Component, ReactNode } from 'react';
import { formatDate, formatDisplayAmount, geti18nValue, getCouponBalance } from '@kopapro/utils/m18';
import { CouponModalProps } from '@kopapro/components/checkout/payment/couponModal';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import CheckIcon from '@mui/icons-material/Check';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import ShopImage from '@kopapro/components/commons/shopImage';
import { checkoutConfig } from '@kopapro/utils/config';
import { CheckoutCouponFormData } from '@kopapro-redux/types/checkout';
import { CouponVoucher } from '@kopapro-redux/types/coupon';
import { AppImages } from '@kopapro/utils/constants/images';
import utils from '@kopapro-redux/utils/utils';
import classnames from 'classnames';
import SpinnerButton from '@kopapro/components/commons/spinnerButton';

interface CouponModalState {
  formData: CheckoutCouponFormData;
  sending: boolean;
  errorMessage: string;
  successMessage: string;
}

export default class CouponModal extends Component<CouponModalProps, CouponModalState> {
  imageSize = checkoutConfig.coupon.imageSize;

  defaultState = {
    formData: {
      storedValues: [],
    },
    sending: false,
    errorMessage: '',
    successMessage: '',
  };

  constructor(props: CouponModalProps) {
    super(props);
    this.state = {
      ...this.defaultState,
      formData: {
        storedValues: this.props.paymentCoupons,
      },
    };
  }

  handleClose = () => {
    if (this.props.onCloseHandler) {
      this.props.onCloseHandler();
    }
  };

  componentDidUpdate = (prevProps: CouponModalProps) => {
    const previousCoupons = JSON.stringify(prevProps.paymentCoupons.sort());
    const currentCoupons = JSON.stringify(this.props.paymentCoupons.sort());
    if (previousCoupons !== currentCoupons) {
      this.setState({ formData: { storedValues: this.props.paymentCoupons } });
    }
  };

  // handle form value change
  selectCoupon = (svId: number, svfId: number, selected: boolean) => {
    let newValue = this.state.formData.storedValues;
    if (!selected) {
      newValue.push({ svId, svfId });
    } else {
      newValue = newValue.filter(function (element) {
        return !(element.svId === svId && element.svfId === svfId);
      });
    }
    this.setState({ formData: { storedValues: newValue } });
  };

  handleClear = () => {
    this.setState({ formData: { storedValues: [] } });
  };

  handleSubmit = () => {
    const { applyCoupon } = this.props;
    const self = this;

    const { formData } = this.state;

    this.setState({ sending: true, errorMessage: '', successMessage: '' });

    // // dispatch request
    applyCoupon({ formData }, function (isSuccess: boolean, message = '') {
      if (isSuccess) {
        self.setState({ sending: false });
        self.handleClose();
      } else {
        self.setState({ sending: false, errorMessage: message });
      }
    });
  };

  // Components
  renderCouponModalItem(item: CouponVoucher): ReactNode {
    const { t } = this.props;
    const { sending, formData } = this.state;
    const { storedValues } = formData;

    const { svId, svfId } = item;
    const { svfCode, svDescObj } = item;
    const { svExpDate, photoCode } = item;

    const balance = getCouponBalance(item);
    const balanceMessage = formatDisplayAmount(balance);
    const expDate = formatDate(svExpDate);

    const active = utils.isDefined(storedValues.find((element) => element.svId === svId && element.svfId === svfId));
    const couponName = geti18nValue(svDescObj);
    return (
      <Col key={`coupon-modal-item-${svId}-${svfId}`} md="6">
        <Card
          className={classnames('mb-3 coupon-item', { active: active, diabled: sending })}
          onClick={() => this.selectCoupon(svId, svfId, active)}>
          <div className="d-flex">
            <ShopImage
              height={this.imageSize}
              width={this.imageSize}
              alt=""
              src={photoCode}
              fallbackImage={AppImages.couponPlaceholder}
            />
            <div className="coupon-info">
              {active && <CheckIcon className="text-success " fontSize="large" />}
              <p className="text-muted">{`#${svfCode}`}</p>
              <h5 title={couponName} className="mb-3 text-truncate">
                {couponName}
              </h5>
              <h6>{`${t('ce01_pmpcore.react.amount')} : ${balanceMessage}`}</h6>
              <h6 className="mb-0">{`${t('ce01_pmpcore.react.expiredDate')} : ${expDate}`}</h6>
            </div>
          </div>
        </Card>
      </Col>
    );
  }

  render(): ReactNode {
    const { t, coupons, show } = this.props;
    const { sending, successMessage, errorMessage } = this.state;
    return (
      <Modal
        className="chectout-coupon-modal"
        show={show}
        onHide={this.handleClose}
        fullscreen={'lg-down'}
        size={'lg'}
        scrollable={true}
        backdrop={'static'}
        keyboard={false}>
        <Modal.Header closeButton={!sending}>
          {<Modal.Title>{t('ce01_pmpcore.react.storedValue')}</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          <Row>
            {coupons.map((item) => {
              return this.renderCouponModalItem(item);
            })}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ErrorMessage message={t(errorMessage)} />
          <SuccessMessage message={t(successMessage)} />

          <div className="coupon-modal-footer">
            <Button variant="outline-secondary" onClick={this.handleClear}>
              {t('ce01_pmpcore.react.clear')}
            </Button>
            <SpinnerButton
              variant="primary"
              className="btn-main"
              onClick={this.handleSubmit}
              spinning={sending}
              disabled={sending}>
              {t('ce01_pmpcore.react.submitBtn')}
            </SpinnerButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
