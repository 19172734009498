import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { OrderType } from '@kopapro-redux/action-types';
import KopaproApi from '@kopapro-redux/api';
import { CommonAction } from '@kopapro-redux/actions/misc';
import { orderUpdated, orderExtraUpdated } from '@kopapro-redux/actions/order';
import { OrderExtraInfoField, OrderExtraInfoValue, OnePageCheckoutData } from '@kopapro-redux/types/order';
import { UdfInputFieldType } from '@kopapro-redux/utils/constant';
import { getM18ComboList, getM18LookupList } from '@kopapro-redux/actions/m18Option';
import { getOrderExtraInfoFields } from '@kopapro-redux/selectors/entities/order';
import { Product, ProductGroup } from '@kopapro-redux/types/products';

// same as order
type OrderResponse = {
  entity: any;
  message: string;
  status: boolean;
};

type OnlinePaymentResponse = {
  data: any;
  pass: boolean;
};

type OrderExtraResponse = {
  inputs: { [k: string]: OrderExtraInfoValue };
  status: boolean;
};

export function* getOrderSaga(action: CommonAction) {
  try {
    const { orderId, stopPropagation } = action.payload;
    const callback = action.callback;
    const resp: OrderResponse = yield call(KopaproApi.retrieveCartItem, orderId);

    yield put(orderUpdated(resp));

    if (!stopPropagation && resp.status) {
      const { extraViewId } = resp.entity;

      yield call(getOrderComboLookupSaga, true, true);

      // load order extra value
      if (extraViewId) {
        const respExtra: OrderExtraResponse = yield call(KopaproApi.retrieveOrderExtra, orderId, extraViewId);
        if (respExtra.status) {
          yield put(orderExtraUpdated(respExtra.inputs));
        }
      }
    }
    //callback
    if (callback) {
      callback(resp.status, resp.entity);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* cancelOrderSaga(action: CommonAction) {
  try {
    const { orderId } = action.payload;
    const callback = action.callback;
    const resp: OrderResponse = yield call(KopaproApi.cancelOrder, orderId);

    yield put(orderUpdated(resp));

    //callback
    if (callback) {
      callback(resp.status, resp.message);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* onlineOrderSaga(action: CommonAction) {
  try {
    const { orderId } = action.payload;
    const callback = action.callback;
    const resp: OnlinePaymentResponse = yield call(KopaproApi.processOnlinePayment, orderId);

    yield put(orderUpdated(resp));

    //callback
    if (callback) {
      callback(resp.pass, resp);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* approvalPayPalSaga(action: CommonAction) {
  try {
    const { orderId } = action.payload;
    const callback = action.callback;
    const resp: OrderResponse = yield call(KopaproApi.approvePayPal, orderId);
    yield put(orderUpdated(resp));

    //callback
    if (callback) {
      callback(resp.status, resp);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* getOrderComboLookupSaga(isNeedLookup = true, iNeedCombo = false) {
  const extraFields: OrderExtraInfoField[] = yield select(getOrderExtraInfoFields);

  // TODO: might be called duplicate requests
  const calls: any[] = [];
  extraFields.forEach((inputFormat) => {
    const { type, pattern } = inputFormat;

    if (iNeedCombo && UdfInputFieldType.COMBO === type) {
      calls.push(put(getM18ComboList({ pattern })));
    }

    if (isNeedLookup && UdfInputFieldType.LOOKUP === type) {
      calls.push(put(getM18LookupList({ pattern })));
    }
  });

  yield all(calls);
}

export function* addProductCommentSaga(action: CommonAction) {
  try {
    const { orderId, proId, score, comment } = action.payload;
    const callback = action.callback;
    const resp: { status: boolean; message: string } = yield call(
      KopaproApi.applyProductComment,
      orderId,
      proId,
      score,
      comment
    );
    if (resp.status) {
      yield call(getOrderSaga, { type: OrderType.GET_ORDER_REQUEST, payload: { orderId, stopPropagation: true } });
    }

    //callback
    if (callback) {
      callback(resp.status, resp.message);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* getSinglePageOrderSaga(action: CommonAction) {
  try {
    const { orderToken } = action.payload;
    const callback = action.callback;
    const resp: { status: boolean; data: OnePageCheckoutData } = yield call(KopaproApi.getSinglePageOrder, orderToken);
    let productCodes: string[] = [];

    if (resp.data) {
      try {
        const { proIds, expiredProIds } = resp.data;
        const effectList = proIds.map((proId) => call(KopaproApi.getProduct, 0, proId, 0));
        let proResp: { products: { [k: number]: Product }; proGroup: ProductGroup }[] = yield all(effectList);
        let products: Product[] = [];

        proResp.forEach((item) => {
          let temp = Object.values(item.products)
            .filter(
              (pro) =>
                proIds.find((i) => i === pro.m18ProId) &&
                !expiredProIds.find((i) => i === pro.m18ProId) &&
                !products.find((i) => i.m18ProId === pro.m18ProId)
            )
            .map((pro) => {
              return pro;
            });
          products = [...products, ...temp];
        });

        productCodes = products.map((pro) => {
          return pro.code;
        });
      } catch (e) {
        // error
      }
    }

    //callback
    if (callback) {
      callback(resp.status, resp.data, productCodes);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function* orderSaga() {
  yield all([takeLatest(OrderType.GET_ORDER_REQUEST, getOrderSaga)]);
  yield all([takeLatest(OrderType.CANCEL_ORDER_REQUEST, cancelOrderSaga)]);
  yield all([takeLatest(OrderType.GET_ONLINE_PAYMENT_REQUEST, onlineOrderSaga)]);
  yield all([takeLatest(OrderType.APPROVE_PAYPAL_REQUEST, approvalPayPalSaga)]);
  yield all([takeLatest(OrderType.ADD_PRODUCT_COMMENT_REQUEST, addProductCommentSaga)]);
  yield all([takeLatest(OrderType.GET_SINGLE_PAGE_ORDER, getSinglePageOrderSaga)]);
}

export default orderSaga;
