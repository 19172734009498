import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { RootState } from "@kopapro-redux/store";
import { withTranslation, WithTranslation } from "react-i18next";

import { PaymentMethod } from '@kopapro-redux/types/paymentMethod';
import { getPaymentMethodList } from '@kopapro-redux/selectors/entities/paymentMethods';
import PaymentMethodList from "@kopapro/components/paymentMethod/list/list";

interface IProps extends WithTranslation {}

interface StateProps {
  list: PaymentMethod[];
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    list: getPaymentMethodList(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type PaymentMethodListProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PaymentMethodList));
