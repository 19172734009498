import { BrowserRouter } from 'react-router-dom';
// connect
import { getCurrentLang } from '@kopapro-redux/selectors/entities/user';
import { RootState } from '@kopapro-redux/store';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { getMetaBasicSetup } from '@kopapro-redux/selectors/entities/shop';
//route

function Router({ lang, children, defaultLang, enableLangPath, excludeDefaultLangInURL }: RouterProps) {
  let language = lang.toLowerCase();
  if (!enableLangPath || (excludeDefaultLangInURL && language === defaultLang.toLowerCase())) {
    language = '';
  }

  let props: any = {};
  if (language.length > 0) {
    props.basename = language;
    props.key = language;
  }

  return <BrowserRouter {...props}>{children}</BrowserRouter>;
}

interface IProps {
  children: React.ReactNode;
}

interface DispatchProps {}

interface StateProps {
  lang: string;
  defaultLang: string;
  enableLangPath: boolean;
  excludeDefaultLangInURL: boolean;
}
type RouterProps = IProps & StateProps & DispatchProps;
const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const { language, enableLangPath, excludeDefaultLangInURL } = getMetaBasicSetup(state);
  return {
    lang: getCurrentLang(state),
    defaultLang: language,
    enableLangPath,
    excludeDefaultLangInURL,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};
export default connect(mapStateToProps, mapDispatchToProps)(Router);
