import { RootState } from '@kopapro-redux/store';
import { createSelector } from 'reselect';

export const getFacebookMeta = (state: RootState) => {
  return state.kopapro.shopMeta.setup.facebook;
};

export const isEnableFacebookComment = createSelector([getFacebookMeta], (facebook) => {
  if (facebook) {
    return facebook.facebookComment.isEnable;
  }
  return false;
});
