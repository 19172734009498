import { ReactNode } from 'react';
import { ExtraProps } from '@kopapro/components/checkout/extra';
import Card from 'react-bootstrap/Card';
import utils from '@kopapro-redux/utils/utils';
import { AllowUdfInputFieldType } from '@kopapro-redux/utils/constant';
import Form from 'react-bootstrap/Form';
import { InputValues } from '@kopapro-redux/types/componentSetting';
import { CheckoutExtraFormData } from '@kopapro-redux/types/checkout';
import Button from 'react-bootstrap/Button';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import InputForm, { InputFormState } from '@kopapro/components/commons/inputs/inputs';
import { orderConfig } from '@kopapro/utils/config';
import SpinnerButton from '@kopapro/components/commons/spinnerButton';

interface ExtraState extends InputFormState {
  imageMap: { [key: string]: string };
  sending: boolean;
  errorMessage: string;
  successMessage: string;
}

export default class Extra extends InputForm<ExtraProps, ExtraState> {
  defaultState = {
    formData: {},
    imageMap: {},
    sending: false,
    errorMessage: '',
    successMessage: '',
    errorFields: {},
  };

  imageSize = orderConfig.extra.imageSize;

  constructor(props: ExtraProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount() {
    this.prepareInitialData();
  }

  componentDidUpdate(prevProps: ExtraProps) {
    if (prevProps.orderId !== this.props.orderId && utils.isNotEmpty(this.props.orderId)) {
      this.prepareInitialData();
    }
  }

  prepareInitialData = () => {
    const { extraViewId, loadData } = this.props;
    const self = this;

    this.setState({ sending: true });

    loadData({ viewId: extraViewId }, function (status: boolean, inputs: InputValues) {
      // update initial form data
      let formData = self.state.formData;
      if (status) {
        formData = { ...inputs };
      }
      self.setState({ sending: false, formData });
    });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { extraViewId } = this.props;
    const { saveCheckoutExtraInfo } = this.props;
    const self = this;
    event.preventDefault();
    event.stopPropagation();
    const errorMessage = 'ce01_pmpcore.react.submitFailed';
    const invalidRequiredFields = this.checkRequiredFields();
    let errorFields: { [x: string]: string } = {};

    // check required
    if (utils.isNotEmptyList(invalidRequiredFields)) {
      for (let id of invalidRequiredFields) {
        errorFields[id] = 'ce01_pmpcore.kopapro.react.fieldNotEmpty2';
      }
      this.setState({ errorMessage, errorFields });
      return;
    }

    // check format
    const invalidFields = this.checkFieldsValidation();
    if (utils.isNotEmptyList(invalidFields)) {
      for (let field of invalidFields) {
        const { inputId, error } = field;
        errorFields[inputId] = error || '';
      }

      this.setState({ errorMessage, errorFields });
      return;
    }

    // handle form data
    const formData: CheckoutExtraFormData = {
      viewId: extraViewId,
      inputs: this.toM18Values(),
    };
    const imageMap = this.state.imageMap;
    const imageList: string[] = Object.keys(imageMap).filter((imageCode) => utils.isNotEmpty(imageCode));

    this.setState({ sending: true, errorMessage: '', successMessage: '', errorFields: {} });
    // dispatch request
    saveCheckoutExtraInfo({ formData, imageList }, function (isSuccess: boolean, message = '') {
      // update state errorMessage and successMessage
      if (isSuccess) {
        self.setState({ sending: false, successMessage: 'ce01_pmpcore.react.submitSuccess' });
        self.props.componentNext();
      } else {
        self.setState({ sending: false, errorMessage: message });
      }
    });
  };

  renderFooter(): ReactNode {
    const { t } = this.props;
    const { sending } = this.state;
    return (
      <div className="chectout-footer">
        <Button variant="outline-secondary" disabled={sending} onClick={() => this.props.componentPrevious()}>
          {t('ce01_pmpcore.react.backBtn')}
        </Button>
        <SpinnerButton spinning={sending} disabled={sending} variant="main" type="submit">
          {t('ce01_pmpcore.react.next')}
        </SpinnerButton>
      </div>
    );
  }

  render(): ReactNode {
    const { t, m18Fields: extraFields } = this.props;
    if (utils.isEmptyJSONObject(extraFields)) {
      return null;
    }

    const allowedFields = extraFields.filter((field) => {
      return AllowUdfInputFieldType.includes(field.type);
    });

    const { successMessage, errorMessage } = this.state;
    return (
      <div className="extra">
        <Card>
          <Card.Body>
            <Card.Title>{t('ce01_pmpcore.kopapro.react.extraInfo')}</Card.Title>
            <Form noValidate onSubmit={this.handleSubmit} autoComplete="off">
              {allowedFields.map((item) => {
                return this.renderM18Field(item);
              })}

              <ErrorMessage message={t(errorMessage)} />
              <SuccessMessage message={t(successMessage)} />
              {this.renderFooter()}
            </Form>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
