import KopaproApi from '@kopapro-redux/api';
import { CaptchaPatterns, CaptchatPattern } from '@kopapro-redux/types/user';
import { AppImages } from '@kopapro/utils/constants/images';
import { Component, ReactNode } from 'react';
import { CaptchaProps } from '@kopapro/components/commons/captcha';
import ShopImage from '@kopapro/components/commons/shopImage';
import classnames from 'classnames';
import Row from 'react-bootstrap/Row';
import utils from '@kopapro/utils/utils';
interface CaptchaState {
  captchaID: string;
  questions: string[];
  patterns: CaptchaPatterns;
  selectedCaptchaList: CaptchaPatterns;
}

export default class Captcha extends Component<CaptchaProps, CaptchaState> {
  defaultState = {};

  constructor(props: CaptchaProps) {
    super(props);
    this.state = {
      ...this.defaultState,
      captchaID: '',
      questions: [],
      patterns: [],
      selectedCaptchaList: [],
    };
  }

  componentDidMount() {
    this.refreshCaptcha();
  }

  componentDidUpdate(prevProps: CaptchaProps) {
    if (prevProps.nonce !== this.props.nonce) {
      this.refreshCaptcha();
    }
  }

  refreshCaptcha = () => {
    const { captchaID } = this.state;
    const self = this;

    // dispatch request
    this.props.refreshCaptcha(
      { captchaID },
      function (captchaID: string, patterns: CaptchaPatterns, questions: string[]) {
        // update state
        self.setState({
          captchaID,
          patterns,
          questions,
          selectedCaptchaList: [],
        });
      }
    );
  };

  onClickCaptcha = (item: CaptchatPattern) => {
    const selectedList: CaptchaPatterns = this.state.selectedCaptchaList;
    const isItemSelected = selectedList.find((e) => e.id === item.id);
    let newList: CaptchaPatterns;

    if (isItemSelected) {
      newList = selectedList.filter((element) => {
        return item.id !== element.id;
      });
    } else {
      newList = [...selectedList, item];
    }
    this.setState({ selectedCaptchaList: newList });
    this.props.onSelected(this.state.captchaID, newList);
  };

  renderItem = (item: CaptchatPattern) => {
    const id = item.id;

    if (!id) {
      return null;
    }

    const { selectedCaptchaList, captchaID } = this.state;
    const isSelected = selectedCaptchaList.find((e) => e.id === item.id);
    const imagePath: string = `${KopaproApi.getUsersRoute()}/resources/getCaptcha?captchaID=${captchaID}&imageID=${id}`;

    // /KopaproApi.getCaptcha, captchaID, imageID
    return (
      <div key={'captcha' + id} className={`captcha-item`} onClick={() => this.onClickCaptcha(item)}>
        <ShopImage
          src={imagePath}
          height="50"
          width="50"
          className={classnames('captcha-image img-fluid ratio ratio-1x1', { 'selected-captcha': isSelected })}
        />
      </div>
    );
  };

  renderQuestionBox(): ReactNode {
    const { t } = this.props;
    const { questions } = this.state;
    let questionMessage: string = t('share.vc.question');

    if (questions) {
      // check comma case
      questionMessage += questions.map((question) => t(question)).join(', ');
      return <div>{<h5 className="message-box">{questionMessage}</h5>}</div>;
    }
    return <div />;
  }

  render() {
    const { patterns } = this.state;

    if (utils.isEmptyList(patterns)) {
      return null;
    }

    return (
      <div className="col captcha">
        {this.renderQuestionBox()}

        <Row className="captcha-list mt-2 mb-2" xs={5}>
          {patterns.map((item) => {
            return this.renderItem(item);
          })}
          <div key="captcha-refresh" className={`captcha-item`} onClick={this.refreshCaptcha}>
            <ShopImage src={AppImages.refreshIcon} className={`captcha-image img-fluid ratio ratio-1x1`} />
          </div>
        </Row>
      </div>
    );
  }
}
