import { Component, ReactNode } from 'react';
import { CheckoutExtraProps } from '@kopapro/components/preview/checkout-extra';
import Extra from '@kopapro/components/checkout/extra';

interface CheckoutExtraState {}

export default class CheckoutExtra extends Component<CheckoutExtraProps, CheckoutExtraState> {
  render(): ReactNode {
    const controlProps = { componentPrevious: () => {}, componentNext: () => {} };
    return (
      <div id="preview-checkout-extra" className="page-container container-xl px-xl-5">
        <Extra orderId={this.props.orderId} {...controlProps} />
      </div>
    );
  }
}
