import { all, call, delay, select, takeLatest, put } from 'redux-saga/effects';
import KopaproApi from '@kopapro-redux/api';
import { MemberType } from '@kopapro-redux/action-types';
import {
  LoadMemberFormAction,
  MemberGetQueryDataAction,
  MemberQuery,
  MemberQueryDetail,
  MemberQueryRequestAction,
  QueryResult,
  QueryUserInfo,
  MemberViewMode,
  MemberGetCouponVouchersAction,
  MemberGetCouponVoucherLedgerAction,
  MemberGetBonusPointsLedgerAction,
} from '@kopapro-redux/types/member';
import { M18ViewCheckMsg } from '@kopapro-redux/types/m18View';
import { UserInfo } from '@kopapro-redux/types/user';
import { getCurrentUserInfo } from '@kopapro-redux/selectors/entities/user';
import { CouponVoucher, CouponVoucherLedger } from '@kopapro-redux/types/coupon';
import utils from '@kopapro-redux/utils/utils';
import { InputFormatList, InputValues } from '@kopapro-redux/types/componentSetting';
import { UdfInputFieldType } from '@kopapro-redux/utils/constant';
import { getM18ComboList, getM18LookupList } from '@kopapro-redux/actions/m18Option';
import { CommonAction } from '@kopapro-redux/actions/misc';
import { setUserInfo } from '@kopapro-redux/actions/user';
import { getMemberCenterInputFormatList } from '@kopapro-redux/selectors/entities/member';
import { formatDate } from '@kopapro-redux/utils/m18';

type QueryRequestResponse = {
  status: boolean;
  checkMsg: M18ViewCheckMsg;
  query: QueryResult;
};

type GetQueryDataResponse = {
  status: boolean;
  results: {
    [k: string]: {
      detail: { [k: string]: MemberQueryDetail };
    };
  };
};

function* queryRequestSaga(action: MemberQueryRequestAction) {
  try {
    const { formData } = action.payload;
    const callback = action.callback;
    const currentUserInfo: UserInfo = yield select(getCurrentUserInfo);
    const memberId = currentUserInfo.memId;
    const userInfo: QueryUserInfo = {
      userId: memberId,
      userModule: 'poscoreMember',
    };

    const resp: QueryRequestResponse = yield call(KopaproApi.queryMemberRequest, formData, userInfo);
    if (callback) {
      callback(resp.status, resp.checkMsg.msg, resp.query);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function* getQueryDataSaga(action: MemberGetQueryDataAction) {
  try {
    const { viewId, queryId, querySize, startPoint, dataCount } = action.payload;
    const callback = action.callback;

    let status: boolean = false;
    let count: number = 1;
    do {
      const resp: GetQueryDataResponse = yield call(
        KopaproApi.getMemberQueryData,
        viewId,
        queryId,
        startPoint,
        dataCount
      );
      if (resp.status) {
        let data: MemberQuery = [];
        let resultsAarry = Object.values(resp.results);
        for (let obj of resultsAarry) {
          let detailArray = Object.values(obj.detail);
          data.push({ detail: detailArray });
        }

        if (
          querySize <= data.length ||
          dataCount <= data.length ||
          (startPoint > dataCount && querySize - startPoint <= data.length)
        ) {
          if (callback) {
            callback(resp.status, data);
            status = true;
          }
          break;
        }
      }
      count++;
      yield delay(2000);
    } while (count <= 10);

    if (!status) {
      if (callback) {
        callback(status, []);
      }
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function* changePasswordSaga(action: CommonAction) {
  const { newPassword, oldPassword } = action.payload;
  const callback = action.callback;

  const userInfo: UserInfo = yield select(getCurrentUserInfo);
  const { mobile: phoneNumber, uid: userId } = userInfo;

  try {
    const response: { status: boolean; checkMsg: M18ViewCheckMsg; needSMS: boolean; paramId: string } = yield call(
      KopaproApi.changeUserPassword,
      newPassword,
      oldPassword,
      phoneNumber,
      userId
    );
    if (callback) {
      callback(response.status, response.checkMsg, response.needSMS, response.paramId);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

export function* loadMemberFormSaga(action: LoadMemberFormAction) {
  try {
    const viewId: string | number = action.payload.viewId;
    const extraFields: InputFormatList = yield select(getMemberCenterInputFormatList, viewId as string);
    yield call(getComboLookupSaga, extraFields, true, true);
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

export function* getComboLookupSaga(extraFields: InputFormatList, isNeedLookup = true, iNeedCombo = false) {
  if (utils.isNotEmptyList(extraFields)) {
    yield all(
      // TODO: might be called duplicate requests
      extraFields.map((inputFormat) => {
        const { type, pattern } = inputFormat;
        if (iNeedCombo && UdfInputFieldType.COMBO === type) {
          return put(getM18ComboList({ pattern }));
        }

        if (isNeedLookup && UdfInputFieldType.LOOKUP === type) {
          return put(getM18LookupList({ pattern }));
        }

        return undefined;
      })
    );
  }
}

function* getMemberInfoSaga(action: CommonAction) {
  const { viewId } = action.payload;
  const callback = action.callback;

  const userInfo: UserInfo = yield select(getCurrentUserInfo);
  const { uid: userId } = userInfo;
  const viewMode: MemberViewMode = {
    mode: 'update',
    entityId: userId,
  };

  try {
    const response: {
      status: boolean;
      inputs: InputValues;
    } = yield call(KopaproApi.getUserInfo, viewId, viewMode);

    if (callback) {
      callback(response.status, response.inputs);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* createMemberInfoSaga(action: CommonAction) {
  const { formData, imageList } = action.payload;
  const callback = action.callback;

  const userInfo: UserInfo = yield select(getCurrentUserInfo);
  const { mobile: phoneNumber } = userInfo;
  const viewMode: MemberViewMode = {
    mode: 'create',
    entityId: 0,
  };

  try {
    const response: {
      status: boolean;
      checkMsg: M18ViewCheckMsg;
      userInfo: UserInfo;
      needSMS: true;
      paramId: true;
    } = yield call(KopaproApi.updateMemberInfo, phoneNumber, formData, viewMode, imageList);

    if (callback) {
      callback(response.status, response.checkMsg);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

function* updateMemberInfoSaga(action: CommonAction) {
  const { formData, imageList } = action.payload;
  const callback = action.callback;

  const userInfo: UserInfo = yield select(getCurrentUserInfo);
  const { mobile: phoneNumber, uid: userId } = userInfo;
  const viewMode: MemberViewMode = {
    mode: 'update',
    entityId: userId,
  };

  try {
    const response: {
      status: boolean;
      checkMsg: M18ViewCheckMsg;
      userInfo: UserInfo;
      needSMS: true;
      paramId: true;
    } = yield call(KopaproApi.updateMemberInfo, phoneNumber, formData, viewMode, imageList);

    if (response.status) {
      const userInfo: UserInfo = response.userInfo;
      if (userInfo) {
        yield put(setUserInfo(userInfo));
      }
    }

    if (callback) {
      callback(response.status, response.checkMsg, response.needSMS, response.paramId);
    }
  } catch (e) {
    console.error(e);
    if (callback) {
      callback(false);
    }
  }
}

type CouponVouchersResponse = {
  status: boolean;
  content: {
    vouchers: {
      [k: string]: CouponVoucher[];
    };
  };
};

type CouponVoucherLedgerResponse = {
  status: boolean;
  content: CouponVoucherLedger;
};

function* getCouponVouchers(action: MemberGetCouponVouchersAction) {
  try {
    const { svId, orderBy, orderByAsc } = action.payload;
    const callback = action.callback;

    const resp: CouponVouchersResponse = yield call(KopaproApi.loadCouponVouchers, svId, orderBy, orderByAsc);
    if (resp.status) {
      const data: CouponVoucher[] = Object.values(resp.content.vouchers)[0];
      if (callback) {
        callback(resp.status, data);
      }
    } else {
      if (callback) {
        callback(resp.status, []);
      }
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function* getCouponVoucherLedger(action: MemberGetCouponVoucherLedgerAction) {
  try {
    const { svId, svfId, startDate, endDate } = action.payload;
    const startDateStr = formatDate(startDate);
    const endDateStr = formatDate(endDate);

    const callback = action.callback;

    const resp: CouponVoucherLedgerResponse = yield call(
      KopaproApi.loadCouponVoucherLedger,
      svId,
      svfId,
      startDateStr,
      endDateStr
    );
    if (callback) {
      callback(resp.status, resp.content);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function* getBonusPointsLedger(action: MemberGetBonusPointsLedgerAction) {
  try {
    const { svId, startDate, endDate } = action.payload;
    const startDateStr = formatDate(startDate);
    const endDateStr = formatDate(endDate);

    const callback = action.callback;

    const resp: CouponVoucherLedgerResponse = yield call(
      KopaproApi.loadBonusPointsLedger,
      svId,
      startDateStr,
      endDateStr
    );
    if (callback) {
      callback(resp.status, resp.content);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function* memberSaga() {
  yield all([
    takeLatest(MemberType.QUERY_MEMBER_REQUEST, queryRequestSaga),
    takeLatest(MemberType.GET_MEMBER_QUERY_DATA, getQueryDataSaga),
    takeLatest(MemberType.LOAD_MEMBER_FORM, loadMemberFormSaga),
    takeLatest(MemberType.GET_INFO, getMemberInfoSaga),
    takeLatest(MemberType.CREATE_INFO, createMemberInfoSaga),
    takeLatest(MemberType.UPDATE_INFO, updateMemberInfoSaga),
    takeLatest(MemberType.CHANGE_PASSWORD, changePasswordSaga),
    takeLatest(MemberType.LOAD_COUPON_VOUCHERS_LIST, getCouponVouchers),
    takeLatest(MemberType.LOAD_COUPON_VOUCHER_LEDGER, getCouponVoucherLedger),
    takeLatest(MemberType.LOAD_BONUS_POINTS_LEDGER, getBonusPointsLedger),
  ]);
}

export default memberSaga;
