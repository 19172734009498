import { Card, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputForm, { InputFormState } from '@kopapro/components/commons/inputs/inputs';
import { M18ViewCheckMsg } from '@kopapro-redux/types/m18View';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import { ContactProps } from '@kopapro/components/pages/account/contact';
import utils from '@kopapro-redux/utils/utils';
import { InputValues } from '@kopapro-redux/types/componentSetting';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import { UpdateInfoFormData } from '@kopapro-redux/types/member';
import Loading from '@kopapro/components/commons/loading';
import SmsMobileModal from '@kopapro/components/commons/verification/smsMobileModal';
import SmsVerifyModal from '@kopapro/components/commons/verification/smsVerifyModal';
interface State extends InputFormState {
  errorMessage: string;
  successMessage: string;

  // sms
  showSmsMobileDialog: boolean;
  showSmsVerifyDialog: boolean;
  smsParamId: string;
  smsMobileCountry: string;
  smsMobile: string;
}

export default class Contact extends InputForm<ContactProps, State> {
  constructor(props: ContactProps) {
    super(props);

    this.state = {
      formData: {},
      imageMap: {},
      errorFields: {},
      errorMessage: '',
      successMessage: '',
      sending: false,

      // sms
      showSmsMobileDialog: false,
      showSmsVerifyDialog: false,
      smsParamId: '',
      smsMobileCountry: '852',
      smsMobile: '',
    };
  }

  componentDidMount() {
    this.loadInitialData();

    const { loadData, viewId } = this.props;
    loadData({ viewId }, function () {});
  }

  componentDidUpdate(prevProps: ContactProps) {
    const { viewId } = this.props;
    if (prevProps.viewId !== viewId) {
      this.loadInitialData();
    }
  }

  loadInitialData = () => {
    const { getContactInfo, viewId } = this.props;
    const self = this;

    this.setState({ sending: true });

    getContactInfo({ viewId }, function (status: boolean, inputs: InputValues) {
      if (!inputs) {
        inputs = {};
      }
      self.setState({ formData: inputs, sending: false });
    });
  };

  handleUpdate = (event: React.FormEvent<HTMLFormElement>) => {
    const { updateContactInfo, viewId } = this.props;
    const self = this;
    event.preventDefault();
    event.stopPropagation();

    // check required field
    const invalidRequiredFields = this.checkRequiredFields();
    let errorFields: { [x: string]: string } = {};

    if (utils.isNotEmptyList(invalidRequiredFields)) {
      for (let id of invalidRequiredFields) {
        errorFields[id] = 'ce01_pmpcore.kopapro.react.fieldNotEmpty2';
      }
      this.setErrorState('', errorFields);
      return;
    }

    // handle form data
    const formData: UpdateInfoFormData = {
      viewId: viewId as number,
      inputs: this.toM18Values(),
    };
    const imageMap = this.state.imageMap;
    const imageList: string[] = Object.keys(imageMap).filter((imageCode) => utils.isNotEmpty(imageCode));

    this.setState({ sending: true, errorMessage: '', successMessage: '' });

    // dispatch changecontact request
    updateContactInfo(
      { formData, imageList },
      function (status: boolean, checkMsg: M18ViewCheckMsg, needSMS: boolean, paramId: string) {
        // update state errorMessage and successMessage
        let message: string = '';
        if (checkMsg) {
          message = geti18nValue(checkMsg.msg);
          if (checkMsg.info) {
            const mess: I18nDictionary = checkMsg.info?.mess || { en: '' };
            message = geti18nValue(mess);
          }
        }

        if (status) {
          let smsMobile: string = self.props.userInfo.mobile;
          let successMessage: string = '';
          let errorMessage: string = '';
          let showSmsMobileDialog: boolean = false;
          let showSmsVerifyDialog: boolean = false;
          let smsParamId: string = paramId;

          if (needSMS) {
            if (!utils.isEmpty(smsMobile)) {
              showSmsVerifyDialog = true;
            } else {
              showSmsMobileDialog = true;
              smsMobile = '';
            }
          } else {
            successMessage = 'ce01_pmpcore.react.submitSuccess';
            smsMobile = '';
            smsParamId = '';
          }

          self.setState({
            sending: false,
            successMessage,
            errorMessage,
            errorFields,
            showSmsMobileDialog,
            showSmsVerifyDialog,
            smsParamId,
            smsMobileCountry: '',
            smsMobile,
          });
        } else {
          self.setErrorState(message);
        }
      }
    );
  };

  setErrorState = (message: string, errorFields: { [key: string]: string } = {}) => {
    if (Object.keys(errorFields).length > 0) {
      message = '';
    }
    this.setState({
      sending: false,
      successMessage: '',
      errorMessage: message,
      errorFields: errorFields,
    });
  };

  // sms mobile dialog
  handleSmsMobileDialogClose = () => {
    this.setState({ showSmsMobileDialog: false, smsMobileCountry: '', smsMobile: '', smsParamId: '' });
  };

  handleSmsMobileDialogConfirm = (smsMobileCountry: string, smsMobile: string) => {
    if (!utils.isEmpty(smsMobileCountry) && !utils.isEmpty(smsMobile)) {
      this.setState({ showSmsMobileDialog: false, showSmsVerifyDialog: true, smsMobileCountry, smsMobile });
    }
  };

  // sms verify dialog
  handleSmsVerifyDialogClose = () => {
    this.setState({ showSmsVerifyDialog: false, smsMobileCountry: '', smsMobile: '', smsParamId: '' });
  };

  handleSmsVerifyDialogConfirm = (status: boolean) => {
    if (status) {
      let successMessage: string = 'ce01_pmpcore.react.submitSuccess';
      let smsParamId: string = '';
      this.setState({
        sending: false,
        successMessage,
        showSmsVerifyDialog: false,
        smsParamId,
        smsMobileCountry: '',
        smsMobile: '',
      });
    }
  };

  render = () => {
    const { t, m18Fields, viewName } = this.props;
    const {
      successMessage,
      errorMessage,
      sending,
      showSmsMobileDialog,
      showSmsVerifyDialog,
      smsMobileCountry,
      smsMobile,
      smsParamId,
    } = this.state;

    if (sending) {
      return <Loading />;
    }

    return (
      <div id="account-contact" className="account-contact">
        <Card className="border-0">
          <Card.Body>
            <Card.Title>{geti18nValue(viewName)}</Card.Title>
            <hr />
            <Form noValidate onSubmit={this.handleUpdate} autoComplete="off">
              <Form.Group as={Col}>
                {m18Fields.map((item, index) => {
                  return <div key={index}>{this.renderM18Field(item, true)}</div>;
                })}
              </Form.Group>
              <div className="text-end">
                <Button variant="main" type="submit">
                  {t('ce01_pmpcore.react.update')}
                </Button>
              </div>
              <SuccessMessage message={t(successMessage)} />
              <ErrorMessage message={t(errorMessage)} />
            </Form>
          </Card.Body>
        </Card>
        <SmsMobileModal
          show={showSmsMobileDialog}
          onConfirmHandler={this.handleSmsMobileDialogConfirm}
          onCloseHandler={this.handleSmsMobileDialogClose}
        />
        <SmsVerifyModal
          show={showSmsVerifyDialog}
          mobileCountry={smsMobileCountry}
          mobile={smsMobile}
          paramId={smsParamId}
          onConfirmHandler={this.handleSmsVerifyDialogConfirm}
          onCloseHandler={this.handleSmsVerifyDialogClose}
        />
      </div>
    );
  };
}
