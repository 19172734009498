import { PreviewType, ShopMetaType } from '@kopapro-redux/action-types';
import { Banner } from '@kopapro-redux/types/shop';
import { handlePreviewMetaBanners } from '@kopapro-redux/reducers/preview';

const defaultSetup: any = {};

const bannerReducer = (state = defaultSetup, action: any): any => {
  switch (action.type) {
    case ShopMetaType.RECEIVED_SHOP_BANNERS:
      let newState: { [key: string]: any } = {};
      const banners = action.payload.data as Banner[];

      banners.forEach((banner) => {
        const { meta, compId, displayMode } = banner;
        newState[banner.compId] = Object.assign({}, { meta, compId, displayMode });
      });

      return newState;

    case PreviewType.PREVIEW_SHOP_BANNERS:
      return handlePreviewMetaBanners(state, action.payload.m18CompId, action.payload.m18Data);

    default:
      return {
        ...state,
      };
  }
};

export default bannerReducer;