import { HomeViewContent, ShopComponentSetting } from '@kopapro-redux/types/componentSetting';
import { PreviewType, ShopMetaType } from '@kopapro-redux/action-types';
import { handlePreviewMetaComponentSetting } from '@kopapro-redux/reducers/preview';

const defaultSetup: ShopComponentSetting = {};

function handleHomeViewDefaultPage(contents: HomeViewContent[]) {
  const homeview = contents.sort(function (a, b) {
    return a.view.viewOrder - b.view.viewOrder;
  });

  // set my purchase default
  let myPurchase = homeview.find((content) => {
    const { viewType, viewModule } = content.view;
    return viewType === 'pmpcoreCard' && viewModule === 'pos';
  });

  let defaultDelivery = homeview.find((content) => {
    const { viewType, viewModule, viewFormat } = content.view;
    if (viewType === 'pmpcoreForm' && viewModule === 'poscoreMember') {
      return Object.values(viewFormat.inputFormat).find((input) => input.field === 'pmpcoreDefDelivery') !== undefined;
    }
    return false;
  });

  let defaultContactInfo = homeview.find((content) => {
    const { viewType, viewModule, viewFormat } = content.view;
    if (viewType === 'pmpcoreForm' && viewModule === 'poscoreMember') {
      return Object.values(viewFormat.inputFormat).find((input) => input.field === 'pmpcoreDefDelivery') === undefined;
    }
    return false;
  });

  if (myPurchase) {
    myPurchase!.view.isDefault = true;
  }
  if (defaultDelivery) {
    defaultDelivery!.view.isDefault = true;
  }
  if (defaultContactInfo) {
    defaultContactInfo!.view.isDefault = true;
  }

  return homeview;
}

const componentSettingReducer = (state = defaultSetup, action: any): ShopComponentSetting => {
  switch (action.type) {
    case ShopMetaType.GET_SHOP_COMPONENTS_SUCCESS:
      const homeview = handleHomeViewDefaultPage(action.payload.homeview);

      return {
        ...state,
        ...action.payload,
        homeview,
      };

    case PreviewType.PREVIEW_SHOP_COMPONENT_SETTING:
      return handlePreviewMetaComponentSetting(state, action.payload.m18CompId, action.payload.m18Data);

    default:
      return {
        ...state,
      };
  }
};
export default componentSettingReducer;