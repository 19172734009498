import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import ChangePassword from '@kopapro/components/pages/account/changePassword/changePassword';
import { getMemberCenterInputFormatList, getMemberCenterViewById } from '@kopapro-redux/selectors/entities/member';
import { HomeViewContent, InputFormatList } from '@kopapro-redux/types/componentSetting';
import { HomeView } from '@kopapro/utils/constants/constants';
import withAccount from '@kopapro/components/pages/account/accountHoc';
import { changeUserPassword } from '@kopapro-redux/actions/member';
import { getCurrentUserInfo } from '@kopapro-redux/selectors/entities/user';
import { UserInfo } from '@kopapro-redux/types/user';

interface IProps extends WithTranslation {}

interface DispatchProps {
  changePassword: typeof changeUserPassword;
}

interface StateProps {
  userInfo: UserInfo;
  m18Fields: InputFormatList;
  content?: HomeViewContent;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    userInfo: getCurrentUserInfo(state),
    content: getMemberCenterViewById(state, HomeView.ChangePassword),
    m18Fields: getMemberCenterInputFormatList(state, HomeView.ChangePassword),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = { changePassword: changeUserPassword };

export type ChangePasswordProps = IProps & StateProps & DispatchProps;

const connectedChangePassword = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
export default withAccount(connectedChangePassword, HomeView.ChangePassword);
