import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Checkout from '@kopapro/components/checkout/checkout';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withNavigation, withLocation } from '@kopapro/components/commons/router-hoc';
import { isAppinitializing } from '@kopapro-redux/selectors/app';
import {
  getCartDiscountCodes,
  getCartId,
  getCartItems,
  isCartRetrieving,
} from '@kopapro-redux/selectors/entities/cart';
import { cartRequireExtra, cartRequirePayment } from '@kopapro-redux/selectors/entities/cart';
import { CartItem } from '@kopapro-redux/types/cart';
import { ShopOrderSetup } from '@kopapro-redux/types/shop';
import { getShopOrderSetup } from '@kopapro-redux/selectors/entities/shop';

interface IProps extends WithTranslation {
  navigate: any;
  location: any;
  currentSection?: string;
  onePage?: boolean;
  onePageBackToCartHandler?: Function;
}

interface StateProps {
  orderId: string;
  retrieving: boolean;
  items?: CartItem[];
  discountCodes: string[];
  isRequirePayment: boolean;
  isRequireExtra: boolean;
  orderSetup: ShopOrderSetup;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    orderId: getCartId(state),
    retrieving: isAppinitializing(state) || isCartRetrieving(state),
    items: getCartItems(state),
    discountCodes: getCartDiscountCodes(state),
    isRequirePayment: cartRequirePayment(state),
    isRequireExtra: cartRequireExtra(state),
    orderSetup: getShopOrderSetup(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type CheckoutProps = IProps & StateProps & DispatchProps;
export default withNavigation(withLocation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Checkout))));
