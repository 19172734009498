import { Component, ReactNode } from 'react';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import { OrderProps } from '@kopapro/components/order';
import utils from '@kopapro-redux/utils/utils';
import Info from '@kopapro/components/order/info';
import Amounts from '@kopapro/components/order/amounts';
import Items from '@kopapro/components/order/items';
import Delivery from '@kopapro/components/order/delivery';
import Payment from '@kopapro/components/order/payment';
import Extra from '@kopapro/components/order/extra';
import Row from 'react-bootstrap/Row';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DefaultModal from '@kopapro/components/commons/modals';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import { OrderStatus, PayStatus, ShipStatus } from '@kopapro-redux/utils/constant';
import SpinnerButton from '@kopapro/components/commons/spinnerButton';
import Button from 'react-bootstrap/Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { toast } from 'react-toastify';
import Loading from '@kopapro/components/commons/loading';
import EventUtils from '@kopapro/utils/event';

/*
TODO:
 1. lookup lable when change language
*/

interface OrderState {
  loadingData: boolean;
  sending: boolean;
  errorMessage: string;
  successMessage: string;
  showCancelModal: boolean;
}

export default class Order extends Component<OrderProps, OrderState> {
  defaultState = {
    loadingData: false,
    sending: false,
    errorMessage: '',
    successMessage: '',
    showCancelModal: false,
  };

  constructor(props: OrderProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount() {
    const { orderId, loadOrder } = this.props;
    this.setState({ loadingData: true });
    loadOrder({ orderId }, this.loadDataCallback);
  }

  loadDataCallback = (status: boolean, data: any) => {
    this.setState({ loadingData: false });
    if (!status && utils.isEmptyJSONObject(data)) {
      this.props.navigate('/page-not-found');
      return;
    }
    if (OrderStatus.CONFIRMED === data.orderStatus && data.payStatus === PayStatus.PAID) {
      EventUtils.purchase(data.orderId);
    }
  };

  cancelOrderHandler = () => {
    const { cancelOrder, orderId, t } = this.props;
    const self = this;
    this.setState({ sending: true, successMessage: '', errorMessage: '' });

    cancelOrder({ orderId }, function (isSuccess: boolean, message: string) {
      if (isSuccess) {
        self.setState({ sending: false });
        toast.info(t('ce01_pmpcore.kopapro.react.orderCancelled'));
        window.scrollTo(0, 0);
      } else {
        self.setState({ sending: false, errorMessage: message });
      }
    });
  };

  showCancelModal = () => {
    this.setState({ showCancelModal: true });
  };

  handleConfirmCancelModal = () => {
    this.setState({ showCancelModal: false });
    this.cancelOrderHandler();
  };

  handleCloseCancelModal = () => {
    this.setState({ showCancelModal: false });
  };

  renderTop(): ReactNode {
    return <>{this.renderNav()}</>;
  }

  renderNav(): ReactNode {
    const { t } = this.props;

    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.order'), active: true },
    ];
    return <KppBreadcrumb items={items} />;
  }

  renderInfo(): ReactNode {
    return <Info />;
  }

  renderAmounts(): ReactNode {
    return <Amounts />;
  }

  renderItems(): ReactNode {
    return <Items />;
  }

  renderDelivery(): ReactNode {
    return <Delivery />;
  }

  renderPayment(): ReactNode {
    return <Payment />;
  }

  renderExtra(): ReactNode {
    return <Extra />;
  }

  renderOrderInformation(): ReactNode {
    const { t } = this.props;
    return (
      <div className="section">
        <h4 className="sub-title">{t('ce01_pmpcore.react.orderInfo')}</h4>
        <Row>
          {this.renderPayment()}
          {this.renderDelivery()}
        </Row>
      </div>
    );
  }

  renderFooter(): ReactNode {
    const { t, orderId, orderStatus, payStatus, shipStatus, hideCancelOrder, payment } = this.props;
    const { sending } = this.state;

    let cancelButton: ReactNode = null;
    let paymentButton: ReactNode = null;

    if (OrderStatus.CONFIRMED === orderStatus && payStatus === PayStatus.UNPAID) {
      if (!hideCancelOrder && (ShipStatus.UNFULFILLED === shipStatus || ShipStatus.PACKING === shipStatus)) {
        cancelButton = (
          <SpinnerButton
            disabled={sending}
            spinning={sending}
            variant="outline-secondary"
            onClick={() => this.showCancelModal()}>
            <DeleteForeverIcon />
            {t('ce01_pmpcore.react.cancel')}
          </SpinnerButton>
        );
      }

      const isOnlinePayment = payment?.paymDetail.online;
      if (isOnlinePayment && PayStatus.PAID !== payStatus) {
        paymentButton = (
          <Button
            disabled={sending}
            variant="outline-secondary"
            onClick={() => this.props.navigate(`/payment/${orderId}`)}>
            <CreditCardIcon />
            {t('ce01_pmpcore.react.payment')}
          </Button>
        );
      }
    }

    if (cancelButton === null && paymentButton === null) {
      return null;
    }
    return (
      <div className="mt-5 d-flex justify-content-between">
        {cancelButton}
        {paymentButton}
      </div>
    );
  }

  render(): ReactNode {
    const { t, items } = this.props;
    const { loadingData } = this.state;

    if (loadingData) {
      return <Loading />;
    }
    if (utils.isEmptyList(items)) {
      return null;
    }

    const { errorMessage, successMessage } = this.state;
    return (
      <div className="page-container container px-xl-5 my-order">
        <ScrollToTopOnMount />
        {this.renderTop()}
        {this.renderInfo()}

        {this.renderItems()}
        {this.renderAmounts()}
        {this.renderOrderInformation()}
        {this.renderExtra()}

        <ErrorMessage message={t(errorMessage)} />
        <SuccessMessage message={t(successMessage)} />

        {this.renderFooter()}

        <DefaultModal
          show={this.state.showCancelModal}
          title={t('ce01_pmpcore.react.cancelOrderMsg')}
          body={''}
          showConfirmButton={true}
          onConfirmHandler={this.handleConfirmCancelModal}
          showCloseButton={true}
          onCloseHandler={this.handleCloseCancelModal}
        />
      </div>
    );
  }
}
