import Header from "@kopapro/components/header";
import Content from "@kopapro/template/Content";
import Footer from "@kopapro/components/footer";
import Integration from '@kopapro/components/misc/integration/integration';
import Theme from '@kopapro/components/misc/themeBuilder';
import ScrollToTopButton from '@kopapro/components/misc/scrollToTop';
import Preview from '@kopapro/components/preview';
import AppControl from '@kopapro/components/misc/appControl';

function Template(props) {
  return (
    <>
      <div id="kopapro-app" className="kopapro-app">
        <Header />
        <Content className="app-content">{props.children}</Content>
        <Footer />
      </div>
      <ScrollToTopButton />
      <Integration />
      <AppControl />
      <Theme />
      <Preview />
    </>
  );
}

export default Template;
