import { Component, ReactNode } from 'react';
import { DeliveryProps } from '@kopapro/components/order/delivery';
import Col from 'react-bootstrap/Col';
import utils from '@kopapro-redux/utils/utils';
import ShopImage from '@kopapro/components/commons/shopImage';
import { orderConfig } from '@kopapro/utils/config';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import { formatMobile, formatPersonName, parseDatetime } from '@kopapro/utils/m18';
import DefaultModal from '@kopapro/components/commons/modals';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Button from 'react-bootstrap/Button';
import { I18nDictionary } from '@kopapro-redux/types/utilities';

interface DeliveryState {
  showModal: boolean;
  showPickupRemarkModal: boolean;
  deliveryCondition: I18nDictionary | undefined;
}

export default class Delivery extends Component<DeliveryProps, DeliveryState> {
  defaultState = {
    showModal: false,
    showPickupRemarkModal: false,
    deliveryCondition: undefined,
  };

  imageSize = orderConfig.delivery.imageSize;
  showMethodName = orderConfig.delivery.showMethodName;

  constructor(props: DeliveryProps) {
    super(props);
    this.state = { ...this.defaultState };
  }

  showTerm = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showModal: true });
  };

  showPickupRemark = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showPickupRemarkModal: true });
  };

  // handle term modal close
  handleClose = () => {
    this.setState({ showModal: false, showPickupRemarkModal: false });
  };

  renderShippingMethod(): ReactNode {
    const { t, delivery } = this.props;

    const { shipDetail } = delivery!;
    const { desc, image, showTerm } = shipDetail!;
    const methodName = geti18nValue(desc);
    return (
      <>
        <h5>{t('ce01_pmpcore.react.deliveryMethod')}</h5>
        <ShopImage className="mb-1" src={image} height={this.imageSize} />
        {this.showMethodName && <p className="image-text">{methodName}</p>}

        {showTerm && (
          <div>
            <Button className="modal-btn link-secondary" onClick={this.showTerm} variant="link">
              {t('ce01_pmpcore.kopapro.react.order.showTermPolicy')}
              <HelpOutlineIcon fontSize="small" />
            </Button>
          </div>
        )}
      </>
    );
  }

  renderShippingAddress(): ReactNode {
    const { t, delivery } = this.props;
    const { shipDetail, firstName, lastName, province, city, zipcode, mobileCountry } = delivery!;
    const { mobile, shipAd1, shipAd2, shipAd3, shipAd4, company, country, pickDate, pickTime } = delivery!;

    const name = formatPersonName(firstName, lastName);
    const chainedAddress = [province, city, zipcode].filter((text) => !utils.isEmpty(text)).join(', ');

    let pickupDateTimeText = '';
    const mobileStr = formatMobile(mobile, mobileCountry);
    let messcode = 'ce01_pmpcore.react.shippingAddress';
    if (shipDetail.selfPickUp) {
      messcode = 'ce01_pmpcore.react.pickUpAddress';
      const parsedDate = parseDatetime(pickDate, pickTime);
      if (parsedDate) {
        pickupDateTimeText = t('{date, full}', { date: parsedDate });
      }
    }

    const addressComponents = [
      name,
      shipAd1,
      shipAd2,
      shipAd3,
      shipAd4,
      chainedAddress,
      country,
      mobileStr,
      company,
    ].filter((text) => !utils.isEmpty(text));

    return (
      <>
        <h5>{t(messcode)}</h5>
        <address>
          {addressComponents.map(function (address, index) {
            return (
              <span className="text-break" key={index}>
                {address} <br />
              </span>
            );
          })}
          <span>{pickupDateTimeText}</span>
          {this.renderPickupRemark()}
        </address>
      </>
    );
  }

  renderShippingRemark(): ReactNode {
    const { t, delivery } = this.props;

    const { shipRemark } = delivery!;
    if (utils.isEmpty(shipRemark)) {
      return null;
    }

    return (
      <Col xs={12} sm={6} md className="mb-4">
        <h5>{t('ce01_pmpcore.react.shipRemark')}</h5>
        <div className="details text-break">{shipRemark}</div>
      </Col>
    );
  }

  renderPickupRemark(): ReactNode {
    const { t, delivery } = this.props;

    const { shipDetail, pickRemarks } = delivery!;
    if (!shipDetail.selfPickUp || utils.isEmpty(pickRemarks)) {
      return null;
    }

    return (
      <div>
        <Button className="modal-btn link-secondary" onClick={this.showPickupRemark} variant="link">
          {t('ce01_pmpcore.kopapro.react.pickupRemark')}
          <HelpOutlineIcon fontSize="small" />
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { t, delivery } = this.props;
    const { showModal, showPickupRemarkModal } = this.state;
    if (utils.isUndefined(delivery) || utils.isEmptyM18Id(delivery?.deliveryId)) {
      return null;
    }

    const { shipDetail, pickRemarks } = delivery!;
    const { term } = shipDetail;

    const modalTitle = showModal ? 'ce01_pmpcore.react.order.shipTermTitle' : 'ce01_pmpcore.kopapro.react.pickupRemark';
    const modalBody = showModal ? geti18nValue(term) : pickRemarks;

    return (
      <>
        <Col xs={12} sm={6} md className="mb-4">
          {this.renderShippingMethod()}
        </Col>
        <Col xs={12} sm={6} md className="mb-4">
          {this.renderShippingAddress()}
        </Col>
        {this.renderShippingRemark()}

        <DefaultModal
          show={showModal || showPickupRemarkModal}
          centered={true}
          title={t(modalTitle)}
          body={modalBody}
          onCloseHandler={this.handleClose}
        />
      </>
    );
  }
}
