import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from "@kopapro-redux/store";
import Banner from "@kopapro/components/shop/banner/banner";
import { withTranslation, WithTranslation } from 'react-i18next';

import { getBanner, } from '@kopapro-redux/selectors/entities/shop'
import { Banner as BannerType } from '@kopapro-redux/types/shop';

interface IProps extends WithTranslation {
    compId: string,
    compName: string,
    showIn: string,
}

interface DispatchProps {
}

interface StateProps {
    content?: BannerType
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {

    const data = getBanner(state, props.compId);
    return {
        content: data
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
};


export type BannerProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Banner));



