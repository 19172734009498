import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withParams } from '@kopapro/components/commons/router-hoc';

import Items from '@kopapro/components/order/items/items';
import { OrderItem } from '@kopapro-redux/types/order';
import { getOrderItems, getOrderInfo } from '@kopapro-redux/selectors/entities/order';
import { getMetaBasicSetup } from '@kopapro-redux/selectors/entities/shop';
import { OrderStatus } from '@kopapro-redux/utils/constant';
import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';

interface IProps extends WithTranslation {
  params: any; // from withParams
}

interface StateProps {
  orderId: string;
  isAllowComment: boolean;
  items?: OrderItem[];
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const orderId = props.params['orderId'] || '';
  const basicSetup = getMetaBasicSetup(state);
  const isOrderCompleted = getOrderInfo(state)?.orderStatus === OrderStatus.COMPLETED;
  const isLogin = userLoggedIn(state);
  return {
    orderId: orderId,
    isAllowComment: basicSetup.enableProComment && isOrderCompleted && isLogin,
    items: getOrderItems(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type ItemsProps = IProps & StateProps & DispatchProps;
export default withParams(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Items)));
