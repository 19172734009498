import React, { ReactNode } from 'react';
import InputForm, { InputFormProps, InputFormState } from '@kopapro/components/commons/inputs/inputs';
import { MemberQuery, QueryFormData, QueryResult } from '@kopapro-redux/types/member';
import utils from '@kopapro-redux/utils/utils';
import Pager from '@kopapro/components/commons/pager';
import { getMemberQueryData, queryMemberRequest } from '@kopapro-redux/actions/member';
import { getWindowScrollTop } from '@kopapro/utils/m18';

export interface QueryProps extends InputFormProps {
  viewId: string | number;
  queryRequest: typeof queryMemberRequest;
  getQueryData: typeof getMemberQueryData;
}

export interface QueryState extends InputFormState {
  sending: boolean;
  errorMessage: string;
  successMessage: string;
  query: QueryResult;
  startPoint: number;
  dataCount: number;
  data: MemberQuery;
  page: number;
}

export default class Query<T extends QueryProps, S extends QueryState> extends InputForm<T, S> {
  usePagination = false;

  fetching = false;

  defaultState = {
    formData: {},
    imageMap: {},
    errorFields: {},
    sending: false,
    errorMessage: '',
    successMessage: '',
    query: { queryId: '', querySize: 0 },
    startPoint: 1,
    dataCount: 10,
    data: [],
    page: 0,
  };

  listRef: React.RefObject<any>;

  constructor(props: T) {
    super(props);
    this.listRef = React.createRef();
  }

  componentDidMount() {
    this.handleQuery();
    if (!this.usePagination) {
      document.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (!this.usePagination) {
      document.removeEventListener('scroll', this.handleScroll);
    }
  }

  onPageChange = (newPage: number) => {
    this.setState({ ...this.state, page: newPage });
    const { listRef, usePagination } = this;
    this.handleData(newPage, false, function () {
      if (usePagination && listRef.current) {
        window.scrollTo(0, listRef.current.offsetTop - 50);
      }
    });
  };

  resetData = () => {
    const { data, startPoint, dataCount, query, page } = this.defaultState;
    this.setState({ data, startPoint, dataCount, query, page });
  };

  validate = (): boolean => {
    return true;
  };

  handleQuery = () => {
    const { queryRequest, viewId } = this.props;
    const self = this;
    if (!this.validate()) {
    }
    // handle form data
    const formData: QueryFormData = {
      viewId: viewId,
      inputs: this.toM18Values(),
    };

    if (!this.validate()) {
      return;
    }

    this.setState({ sending: true, successMessage: '', errorMessage: '' });
    this.resetData();

    // dispatch request
    queryRequest({ formData }, function (isSuccess: boolean, message: string, query: QueryResult) {
      if (isSuccess) {
        let mess: string = message;
        if (query.querySize <= 0) {
          mess = 'ce01_pmpcore.react.emptyRecord';
        }
        self.setState({ sending: false, successMessage: mess, query }, () => self.handleData(0, true));
      } else {
        self.setState({ sending: false, errorMessage: message });
      }
    });
  };

  handleData = (page: number, showLoading: boolean, callback?: Function) => {
    const { getQueryData, viewId } = this.props;
    const self = this;

    // handle form data
    const { query, dataCount, data } = this.state;
    const { queryId, querySize } = query;

    if (data.length >= querySize || utils.isEmpty(queryId) || querySize <= 0) {
      return;
    }

    let startPoint = this.state.startPoint;
    if (this.usePagination) {
      if (page <= 0) {
        startPoint = 1;
      } else {
        startPoint = page * dataCount + 1;
      }
    }

    if (showLoading) {
      this.setState({ sending: true });
    }

    this.fetching = true;

    // dispatch request
    getQueryData(
      { viewId, queryId, querySize, startPoint, dataCount },
      function (isSuccess: boolean, data: MemberQuery) {
        if (isSuccess) {
          self.onFetchHandler(data);
          if (callback) {
            callback();
          }
        }
        self.fetching = false;
      }
    );
  };

  onFetchHandler = (data: MemberQuery) => {
    const { page, data: currentData } = this.state;
    if (!this.usePagination) {
      const newStartPoint = this.state.startPoint + data.length;
      this.setState({ sending: false, data: [...currentData, ...data], startPoint: newStartPoint });
    } else {
      const newStartPoint = (this.state.query.querySize / this.state.dataCount) * page;
      this.setState({ sending: false, data: data, startPoint: newStartPoint });
    }
  };

  handleScroll = (e: Event): void => {
    if (this.listRef.current) {
      const scroll = window.innerHeight + getWindowScrollTop();
      const ref = this.listRef.current.offsetTop + this.listRef.current.offsetHeight;

      const { query, data } = this.state;
      const isScrolledToBottom = scroll >= ref;
      const canFetchMoreData = query && query.querySize > data.length;
      if (!this.fetching && isScrolledToBottom && canFetchMoreData) {
        this.onPageChange(this.state.page + 1);
      }
    }
  };

  renderPagination = () => {
    const { sending, errorMessage } = this.state;
    if (this.usePagination && !sending && utils.isEmpty(errorMessage)) {
      const { t } = this.props;
      const { page, dataCount, query } = this.state;
      const { querySize: size } = query;
      const pageSize = dataCount;

      const indexOfLastRecord = (page + 1) * pageSize;
      const indexOfFirstRecord = indexOfLastRecord - pageSize;

      return (
        <div className="d-flex align-items-center mt-auto">
          <span className="text-muted ">
            {t('ce01_pmpcore.kopapro.react.pagination.showNumber', {
              currentFirstRecord: `${size <= 0 ? 0 : indexOfFirstRecord + 1}`,
              currentLastRecord: `${Math.min(indexOfLastRecord, size)}`,
              size,
            })}
          </span>
          <nav aria-label="Page navigation" className="ms-auto">
            <Pager current={page} itemPerPage={pageSize} total={size} onPageChange={this.onPageChange} />
          </nav>
        </div>
      );
    }
  };

  render(): ReactNode {
    return <></>;
  }
}
