import { bannerConfig as config } from '@kopapro/utils/config';
import classnames from 'classnames';
import React, { Component, ReactNode } from 'react';
import { getBannerImageFitClass, getCustomStyle, geti18nValue, resolveImageURL } from '@kopapro/utils/m18';
import { Link } from 'react-router-dom';
import { BannerMetaData } from '@kopapro-redux/types/shop';

interface ResponsiveState {
  curPhotoCode: string;
  photoList: String[];
}

export class ResponsiveBanner extends Component<any, ResponsiveState> {
  defaultState: ResponsiveState = {
    curPhotoCode: '',
    photoList: [],
  };

  constructor(props: ResponsiveState) {
    super(props);
    this.state = this.defaultState;
  }

  defaultHeight = 400;

  renderInfoPlate = (item: BannerMetaData) => {
    const { addUrl, url, infoPlateDto: plate } = item;
    const { fontColor, fontSize, fontStyle, font } = plate;

    let captionStyle = {};
    captionStyle = getCustomStyle({ fontColor, fontSize, fontStyle, font, useUdfStyle: true });
    let view = (
      <div
        onMouseEnter={() => {
          this.addPhotoList(item.photoCode);
        }}
        onMouseLeave={() => {
          this.removePhotoList(item.photoCode);
        }}>
        {plate.useInfoPlate && (
          <div className="textStyle">
            <label style={captionStyle}>{geti18nValue(plate.infoPlateObj)}</label>
          </div>
        )}
      </div>
    );

    if (addUrl) {
      return <Link to={url ? url : ''}>{view}</Link>;
    } else {
      return <div>{view}</div>;
    }
  };

  addPhotoList = (photoCode: string) => {
    let photoList = this.state.photoList;
    if (photoList && !photoList.includes(photoCode)) {
      photoList.push(photoCode);
    }
    this.setState({ photoList: photoList, curPhotoCode: photoCode });
  };

  removePhotoList = (photoCode: string) => {
    let photoList = this.state.photoList;
    let newPhotoList = [];
    if (photoList) {
      for (let key in photoList) {
        let code = photoList[key];
        if (photoCode !== code) {
          newPhotoList.push(code);
        }
      }
    }
    this.setState({ photoList: newPhotoList, curPhotoCode: '' });
  };

  searchPhotoList = (photoCode: string) => {
    let photoList = this.state.photoList;
    if (photoList && photoList.includes(photoCode)) {
      return true;
    }
    return false;
  };

  searchImgAnimationClass = (photoCode: string) => {
    const { data } = this.props;

    let className = '';
    if (photoCode && data) {
      for (let key in data) {
        let code = data[key].photoCode;
        if (photoCode === code) {
          className = 'responsiveBanner_' + data[key].imgChgAnimation;
          break;
        }
      }
    }
    return className;
  };

  render(): ReactNode {
    const { meta } = this.props.content!;
    const { data, allowUdfHeight, imageLayout, udfHeight, imageObjectFit } = meta;
    const height = allowUdfHeight ? udfHeight : this.defaultHeight;
    const isFullLayout = imageLayout === 'full';
    const { curPhotoCode } = this.state;

    let defPhoto = curPhotoCode;
    if (defPhoto === '' && data[0] && data[0].photoCode) {
      defPhoto = data[0].photoCode;
    }

    let className = '';
    if (this.searchPhotoList(defPhoto)) {
      className = this.searchImgAnimationClass(defPhoto);
    }

    return (
      <div className={classnames('responsive-banner')}>
        <div className="content" style={{ height }}>
          <div className="d-flex flex-column justify-content-center textOverlay w-100 px-3" style={{ height }}>
            {data.map((item: BannerMetaData, index: number) => {
              return <React.Fragment key={index}>{this.renderInfoPlate(item)}</React.Fragment>;
            })}
          </div>
          <div key={curPhotoCode} className={className}>
            <img
              alt="banner"
              src={resolveImageURL(defPhoto)}
              style={{ height }}
              className={`d-block w-100 img-fluid ${getBannerImageFitClass(imageObjectFit, 'cover')} imageView`}
            />
          </div>
        </div>
      </div>
    );
  }
}
