import React, { Component, ReactNode } from 'react';
import { formatBaseAmount, getCouponBalance, geti18nValue } from '@kopapro/utils/m18';
import { Card, Table, Form, Button } from 'react-bootstrap';
import { VouchersProps } from '@kopapro/components/pages/account/coupons/vouchers';
import { CouponVoucher } from '@kopapro-redux/types/coupon';
import { Link } from 'react-router-dom';
import ShopImage from '@kopapro/components/commons/shopImage';
import { AppImages } from '@kopapro/utils/constants/images';
import { CouponVouchersOrderBy } from '@kopapro/utils/constants/constants';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Pager from '@kopapro/components/commons/pager';
import classnames from 'classnames';
import Loading from '@kopapro/components/commons/loading';

interface VouchersState {
  sending: boolean;
  successMessage: string;
  vouchers: CouponVoucher[];
  orderBy: string;
  orderByAsc: boolean;
  displayVouchers: CouponVoucher[];
  displayCount: number;
  page: number;
}

export default class Vouchers extends Component<VouchersProps, VouchersState> {
  defaultState = {
    sending: false,
    successMessage: '',
    vouchers: [],
    orderBy: CouponVouchersOrderBy.SerialNo.value,
    orderByAsc: false,
    displayVouchers: [],
    displayCount: 20,
    page: 0,
  };

  constructor(props: VouchersProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount() {
    this.loadRecords();
  }

  onOrderByChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const newValue = e.currentTarget.value;
    this.setState({ orderBy: newValue, page: 0 });
    this.loadRecords({ orderBy: newValue });
  };

  onOrderByAscChange = () => {
    const newValue = !this.state.orderByAsc;
    this.setState({ orderByAsc: newValue, page: 0 });
    this.loadRecords({ orderByAsc: newValue });
  };

  onPageChange = (newPage: number) => {
    const count: number = newPage * this.state.displayCount;
    const displayVouchers = this.state.vouchers.slice(count, count + this.state.displayCount);
    this.setState({ page: newPage, displayVouchers });
  };

  loadRecords = (newState = {}) => {
    const { loadVouchers, svId } = this.props;
    const self = this;

    const query = {
      orderBy: this.state.orderBy,
      orderByAsc: this.state.orderByAsc,
    };

    this.setState({ sending: true, successMessage: '' });

    loadVouchers({ svId, ...query, ...newState }, function (isSuccess: boolean, data: CouponVoucher[]) {
      if (isSuccess && data.length > 0) {
        const displayVouchers = data.slice(0, self.state.displayCount);
        self.setState({ sending: false, vouchers: data, displayVouchers });
      } else {
        self.setState({ sending: false, successMessage: 'ce01_pmpcore.react.emptyRecord', vouchers: [] });
      }
    });
  };

  backToCoupons = () => {
    const { navigate } = this.props;
    navigate('/account/coupons');
  };

  renderVoucherItem = (item: CouponVoucher): ReactNode => {
    const { t } = this.props;
    const svId = item.svId;
    const svfId = item.svfId;
    const balance = getCouponBalance(item);
    return (
      <tr key={`${svId}-${svfId}`}>
        <td>
          <Link to={`/account/coupons/${svId}/${svfId}`}>{`#${item.svfCode}`}</Link>
        </td>
        <td>{t('{ date, short }', { date: item.svfSourceDate })}</td>
        <td>{formatBaseAmount(item.svfIniBal)}</td>
        <td className={classnames({ 'text-danger': balance <= 0 })}>{formatBaseAmount(balance)}</td>
        <td>{t('{ date, short }', { date: item.svExpDate })}</td>
      </tr>
    );
  };

  renderPagination = () => {
    const { sending } = this.state;
    if (!sending) {
      const { t } = this.props;
      const { page, displayCount } = this.state;
      const size = this.state.vouchers.length;
      const pageSize = displayCount;

      const indexOfLastRecord = (page + 1) * pageSize;
      const indexOfFirstRecord = indexOfLastRecord - pageSize;

      return (
        <div className="d-flex align-items-center mt-auto">
          <span className="text-muted">
            {t('ce01_pmpcore.kopapro.react.pagination.showNumber', {
              currentFirstRecord: `${size <= 0 ? 0 : indexOfFirstRecord + 1}`,
              currentLastRecord: `${Math.min(indexOfLastRecord, size)}`,
              size,
            })}
          </span>
          <nav aria-label="Page navigation" className="ms-auto">
            <Pager current={page} itemPerPage={pageSize} total={size} onPageChange={this.onPageChange} />
          </nav>
        </div>
      );
    }
  };

  renderBody(): ReactNode {
    const { t } = this.props;
    const { sending, displayVouchers } = this.state;

    if (sending) {
      return <Loading />;
    }

    return (
      <>
        <div className="d-flex mb-3">
          <div className="flex-grow-1">
            <Button variant="link" disabled={sending} onClick={() => this.backToCoupons()}>
              <ArrowBackIcon />
              {t('ce01_pmpcore.react.backBtn')}
            </Button>
          </div>
          <div className="d-flex flex-row">
            <Form.Select
              className="border-0 border-bottom"
              value={this.state.orderBy}
              onChange={this.onOrderByChange}
              disabled={sending}>
              {Object.values(CouponVouchersOrderBy).map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {t(item.mess)}
                  </option>
                );
              })}
            </Form.Select>
            <button className="d-flex btn btn-link" onClick={() => this.onOrderByAscChange()} disabled={sending}>
              <ArrowDownwardIcon />
              <SortIcon className={classnames({ 'order-by-ascending-con': this.state.orderByAsc })} />
            </button>
          </div>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>
                <h6>{t('ce01_pmpcore.react.serialNo')}</h6>
              </th>
              <th>
                <h6>{t('ce01_pmpcore.react.purchaseDate')}</h6>
              </th>
              <th>
                <h6>{t('ce01_pmpcore.react.svAmount')}</h6>
              </th>
              <th>
                <h6>{t('ce01_pmpcore.react.balance')}</h6>
              </th>
              <th>
                <h6>{t('ce01_pmpcore.react.expiredDate')}</h6>
              </th>
            </tr>
          </thead>
          <tbody>
            {displayVouchers.map((item) => {
              return this.renderVoucherItem(item);
            })}
          </tbody>
        </Table>
        {this.renderPagination()}
      </>
    );
  }

  render(): ReactNode {
    const { viewName } = this.props;
    const { vouchers } = this.state;

    let title = geti18nValue(viewName);
    let photoCode = '';
    if (vouchers.length > 0) {
      title = `${title} - ${geti18nValue(vouchers[0].svDescObj)}`;
      photoCode = vouchers[0].photoCode;
    }
    return (
      <Card className="border-0 account-coupons-vouchers">
        <Card.Body>
          <Card.Title className="text-break">
            <ShopImage className="title-logo" src={photoCode} fallbackImage={AppImages.couponPlaceholder} width={30} />
            {title}
          </Card.Title>
          <hr />

          {this.renderBody()}
        </Card.Body>
      </Card>
    );
  }
}
