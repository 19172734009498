import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import PayPal from '@kopapro/components/payment/paypal/paypal';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { getCurrentLang } from '@kopapro-redux/selectors/entities/user';
import { approvePayPal } from '@kopapro-redux/actions/order';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IProps extends WithTranslation {
  navigate: any;
  paypalOrderId: string;
  kppOrderId: string;
  clientId: string;
  currency: string;
}

interface StateProps {
  language: string;
}

interface DispatchProps {
  approvePayPal: typeof approvePayPal;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    language: getCurrentLang(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  approvePayPal,
};

export type PayPalButtonProps = IProps & StateProps & DispatchProps;
export default withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PayPal)));
