import { all, call, delay, put, takeLatest } from 'redux-saga/effects';

import { MiscType } from '@kopapro-redux/action-types';
import { getShopMetaSaga } from '@kopapro-redux/sagas/entities/shopMetaSaga';
import { getCartSaga } from '@kopapro-redux/sagas/entities/cartSaga';
import { getDeliveryMethodsSaga } from '@kopapro-redux/sagas/entities/deliveryMethodSaga';
import { getPaymentMethodsSaga } from '@kopapro-redux/sagas/entities/paymentMethodSaga';
import { getShippingRegionSaga } from '@kopapro-redux/sagas/entities/shippingRegionSaga';
import { getBonusPointsSetupSaga } from '@kopapro-redux/sagas/entities/bonusPointsSaga';
import { autoLoginSaga } from '@kopapro-redux/sagas/entities/userSaga';
import { getBlogCategoriesSaga } from '@kopapro-redux/sagas/entities/blogSaga';
import { initializedData, shopUpdating } from '@kopapro-redux/actions/misc';
import { getStorageItem, setStorageItem, removeStorageItem, getCookie } from '@kopapro-redux/utils/cookies';
import utils from '@kopapro-redux/utils/utils';
import KopaproApi from '@kopapro-redux/api';

export function* resetStorageDataSaga() {
  setStorageItem('sessionKey', utils.newUuid());
  removeStorageItem('trafficSource');
  removeStorageItem('referLink');
}

export function* handleSessionKeySaga(sessionKey?: string, trafficSource?: string, referLink?: string) {
  if (utils.isNotEmpty(sessionKey)) {
    setStorageItem('sessionKey', sessionKey!);
  }
  if (utils.isNotEmpty(trafficSource)) {
    setStorageItem('trafficSource', trafficSource!);
  }
  if (utils.isNotEmpty(referLink)) {
    setStorageItem('referLink', referLink!);
  }
}

function* initSaga() {
  try {
    // get sessionkey, if missing then generate new uuid
    const sessionKey = getStorageItem('sessionKey');
    const kppReferer = getStorageItem('kppReferer');
    const showAnnouncement = getCookie('close_announcement');
    if (utils.isEmpty(sessionKey)) {
      setStorageItem('sessionKey', utils.newUuid());
    }

    if (utils.isNotEmpty(kppReferer)) {
      setStorageItem('trafficSource', kppReferer);
      removeStorageItem('kppReferer');
    }
    if (utils.isNotEmpty(showAnnouncement) && showAnnouncement === '1') {
      yield put({ type: MiscType.DISMISS_ANNOUNCEMENT });
    }

    // must be the first
    yield call(autoLoginSaga);

    yield call(getShopMetaSaga);
    yield call(getDeliveryMethodsSaga);
    yield call(getPaymentMethodsSaga);
    yield call(getShippingRegionSaga);
    yield call(getBonusPointsSetupSaga);

    yield call(getBlogCategoriesSaga);

    // must be the last
    yield call(getCartSaga, { type: '', payload: { isInit: true } });

    yield put(initializedData());
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function* checkShopSaga(action: any) {
  if (action.isInit) {
    yield put(shopUpdating(true));
  }
  let isChecking = true;

  while (isChecking) {
    const resp: { status: boolean } = yield call(KopaproApi.checkPortalUpdate);
    if (!resp.status) {
      isChecking = false;
      yield put(shopUpdating(false));
      yield call(initSaga);
    }
    yield delay(3000);
  }
}

function* miscSaga() {
  yield all([takeLatest(MiscType.INIT_SHOP_REQUEST, initSaga)]);
  yield all([takeLatest(MiscType.CHECK_SHOP_UPDATE, checkShopSaga)]);
}

export default miscSaga;
