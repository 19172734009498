import { Component, ReactNode } from 'react';
import { ExtraProps } from '@kopapro/components/order/extra';
import utils from '@kopapro-redux/utils/utils';
import { OrderExtraInfoField } from '@kopapro-redux/types/order';
import { geti18nValue } from '@kopapro/utils/m18';
import ShopImage from '@kopapro/components/commons/shopImage';
import { orderConfig } from '@kopapro/utils/config';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { UdfInputFieldType } from '@kopapro-redux/utils/constant';

const orderExtraConfig = orderConfig.extra;

interface ExtraState {}

export default class Extra extends Component<ExtraProps, ExtraState> {
  imageSize = orderExtraConfig.imageSize;

  renderExtraValue = (displayValue: any, value: any, type: string): ReactNode => {
    const { t } = this.props;
    switch (type) {
      case UdfInputFieldType.CHECKBOX:
        return value === true ? <CheckIcon className="text-success" /> : <CloseIcon />;

      case UdfInputFieldType.IMAGE:
        return <ShopImage src={value} height={this.imageSize} />;

      case UdfInputFieldType.DATE:
        const dateValue = value ? t('{ date, full }', { date: new Date(value) }) : '';
        return <span>{dateValue}</span>;
    }
    return <span>{displayValue}</span>;
  };

  renderExtraField = (item: OrderExtraInfoField): ReactNode => {
    const { id, type, name, value, displayValue } = item;
    return (
      <Row className="extra-item mb-2" key={id}>
        <Col sm={3}>{`${geti18nValue(name)}`}</Col>
        <Col sm={9}>{this.renderExtraValue(displayValue, value, type)}</Col>
      </Row>
    );
  };

  render(): ReactNode {
    const { t, fields } = this.props;
    if (utils.isEmptyJSONObject(fields)) {
      return null;
    }

    const sortedFields: OrderExtraInfoField[] = fields!.sort(function (a, b) {
      return a.order - b.order;
    });

    return (
      <div className='section'>
        <h4 className="sub-title">{t('ce01_pmpcore.kopapro.react.extraInfo')}</h4>
        <div className="px-3">
          {sortedFields.map((item) => {
            return this.renderExtraField(item);
          })}
        </div>
      </div>
    );
  }
}
