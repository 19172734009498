import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getMemberCenterViewById, getMemberCenterViewDefaultAddress } from '@kopapro-redux/selectors/entities/member';
import { InputFormatList } from '@kopapro-redux/types/componentSetting';
import withAccount from '@kopapro/components/pages/account/accountHoc';
import Address from '@kopapro/components/pages/account/address/address';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import utils from '@kopapro-redux/utils/utils';
import { isMemberAddressPageContent } from '@kopapro-redux/utils/m18';
import { getInputFormatListInView } from '@kopapro-redux/utils/m18View';
import { withSearchParams } from '@kopapro/components/commons/router-hoc';
import { getM18Combo, getM18Lookup } from '@kopapro-redux/selectors/entities/m18Option';
import { M18ComboList, M18LookupList } from '@kopapro-redux/types/m18Option';
import { getAddress, loadMemberForm, updateAddress } from '@kopapro-redux/actions/member';
import { getShippingRegionList } from '@kopapro-redux/selectors/entities/shippingRegion';
import { ShippingRegion } from '@kopapro-redux/types/shippingRegion';
import { HomeView } from '@kopapro/utils/constants/constants';

interface IProps extends WithTranslation {
  searchParams: any; // from withSearchParams
}

interface DispatchProps {
  loadData: typeof loadMemberForm;
  getAddress: typeof getAddress;
  updateAddress: typeof updateAddress;
}

interface StateProps {
  viewId: string | number;
  viewName: I18nDictionary;
  shipRegionList: ShippingRegion[];

  m18Fields: InputFormatList;
  combo?: { [k: string]: M18ComboList };
  lookup?: { [k: string]: M18LookupList };
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const compId = props.searchParams[0].get('id') || '';
  let content = getMemberCenterViewById(state, compId);

  if (utils.isUndefined(content) || !isMemberAddressPageContent(content)) {
    content = getMemberCenterViewDefaultAddress(state);
  }

  return {
    viewId: content?.view.viewId || '',
    viewName: content?.view.viewName || { en: '' },
    shipRegionList: getShippingRegionList(state),

    m18Fields: getInputFormatListInView(content),
    combo: getM18Combo(state),
    lookup: getM18Lookup(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadData: loadMemberForm,
  getAddress: getAddress,
  updateAddress: updateAddress,
};

export type AddressProps = IProps & StateProps & DispatchProps;

const connectedAddress = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Address));
export default withSearchParams(withAccount(connectedAddress, HomeView.DeliveryAddress));
