import { MiscType } from '@app-redux/action-types';

export const triggerDemoMessage = () => ({
  type: MiscType.TRIGGER_DEMO_ACTION,
});

export const showMessage = () => ({
  type: MiscType.SHOW_DEMO_MESSAGE,
});

export const hideMessage = () => ({
  type: MiscType.HIDR_DEMO_MESSAGE,
});
