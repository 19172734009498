import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Cart from '@kopapro/components/cart/cart';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isAppinitializing } from '@kopapro-redux/selectors/app';
import {
  cartUpdating,
  isCartRetrieving,
  getCartItems,
  getGiftList,
  getRedemptionList,
  getCartMessage,
} from '@kopapro-redux/selectors/entities/cart';
import { CartItem, GiftRedemptionData } from '@kopapro-redux/types/cart';
import { withNavigation, withLocation } from '@kopapro/components/commons/router-hoc';
import { applyGiftToCart, applyRedemptionToCart, checkCart, getCart } from '@kopapro-redux/actions/cart';
import { getSelectedCurrency } from '@kopapro-redux/selectors/entities/user';
import { ShopOrderSetup } from '@kopapro-redux/types/shop';
import { getShopOrderSetup } from '@kopapro-redux/selectors/entities/shop';
import withConfigurable from '@kopapro/components/preview/configurable';
import { M18RnMeta } from '@kopapro-redux/utils/constant';
import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';

interface IProps extends WithTranslation {
  navigate: any;
  location: any;
  onePage?: boolean;
  onePageCartNext?: Function;
}

interface StateProps {
  orderSetup: ShopOrderSetup;
  updating: boolean;
  retrieving: boolean;
  selectedCurrency: string;
  items?: CartItem[];
  redemptionList?: GiftRedemptionData[];
  giftList?: GiftRedemptionData[];
  userLoggedIn: boolean;
  cartMessage?: string;
}

interface DispatchProps {
  refreshCart: typeof getCart;
  checkCart: typeof checkCart;
  applyRedemption: typeof applyRedemptionToCart;
  applyGift: typeof applyGiftToCart;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    orderSetup: getShopOrderSetup(state),
    updating: cartUpdating(state),
    retrieving: isAppinitializing(state) || isCartRetrieving(state),
    selectedCurrency: getSelectedCurrency(state),
    items: getCartItems(state, false),
    cartMessage: getCartMessage(state),
    redemptionList: getRedemptionList(state),
    giftList: getGiftList(state),
    userLoggedIn: userLoggedIn(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  refreshCart: getCart,
  checkCart: checkCart,
  applyRedemption: applyRedemptionToCart,
  applyGift: applyGiftToCart,
};

const configurableProps = {
  m18CompId: M18RnMeta.ORDERVIEW,
};

export type CartProps = IProps & StateProps & DispatchProps;

const component = withLocation(withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Cart))));
export default withConfigurable(component, configurableProps);


