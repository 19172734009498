import keyMirror from "@kopapro-redux/utils/key-mirror";

// misc action type
export default keyMirror({
  INIT_SHOP_REQUEST: null,
  SHOP_INITIALIZED: null,
  CHECK_SHOP_UPDATE: null,
  SHOP_UPDATING: null,
  UPDATE_KOPAPRO_EXPIRED: null,
  CLOSE_ANNOUNCEMENT: null,
  DISMISS_ANNOUNCEMENT: null,
  RESET_ANNOUNCEMENT: null,
});



