export const KOPAPRO = {
  PAGE_SIZE: 15,
  MOBILE_PAGE_SIZE: 10,
};

export const M18RnMeta = {
  // layout
  SHOP: 'Shop',
  LOGIN: 'Login',
  REG: 'Register',
  HOME: 'Home',
  ORDER: 'Order',
  // component setting
  LOGINVIEW: 'LoginView',
  REGVIEW: 'RegView',
  HOMEVIEW: 'HomeView',
  ORDERVIEW: 'OrderView',
  WILDCARD: 'WildCard',
  BANNER: 'Banner',
  HTML: 'Html',
  BLOGPOST: 'BlogPost',
  CATELIST: 'CateList',
  FEATUREDCATE: 'FeaturedCate',
  GALLERY: 'Gallery',
  IMAGEWITHTEXT: 'ImageWithText',
  IMAGEWITHTEXTOVERLAY: 'ImageWithTextOverlay',
  FEATUREDPRO: 'FeaturedPro',
  NEWSLETTER: 'Newsletter',
  NEWSLETTERPAGE: 'NewsletterPage',
  CONTACTUS: 'ContactUs',
  CONTACTUSPAGE: 'ContactUsPage',
  LOGOLIST: 'LogoList',
  PRODUCT: 'Product',
  PROSETUP: 'ProductSetup',
  BLOG: 'Blog',
  SHOPINFO: 'ShopInfo',
  CATEGORY: 'CategoryList',
  DELIVERY: 'DeliveryMethod',
  PAYMENT: 'PaymentMethod',
  ABOUTUS: 'AboutUs',
  POLICY: 'Policy',
  UDFHTML: 'UdfHtml',
  UDFCATE: 'UdfCate',
  UDFBLOGCATE: 'UdfBlogCate',
  UDFSOCIALMEDIA: 'UdfSocalMedia',
  UDFFOLDER: 'UdfFolder',
  UDFMENU: 'UdfMenu',
  UDFFOOTERMENU: 'UdfFooterMenu',
  SHOPNAME: 'ShopName',
  // setup
  SETUP: 'Setup',
  SOCIAL_MEDIA_TYPES: {
    FACEBOOK: 'facebook',
    LINE: 'line',
    TELEGRAM: 'telegram',
    MEWE: 'mewe',
    INSTAGRAM: 'instagram',
    YOUTUBE: 'youtube',
    WHATSAPP: 'whatsapp',
    TWITTER: 'twitter',
  },
};

export const Currency = {
  BASE: 1,
  SELECTED: 2,
};

export const UdfInputFieldType = {
  STRING: 'inputText',
  MEMO: 'inputTextArea',
  NUMBER: 'inputNum',
  DATE: 'inputDate',
  CHECKBOX: 'inputCheck',
  COLOR: 'inputColor',
  TIME: 'inputTime',
  IMAGE: 'inputImage',
  FILE: 'inputFile',
  COMBO: 'inputCombo',
  LOOKUP: 'inputLookup',
  HTML: 'inputHtml',
  MOBILE: 'inputMobile',
  PASSWORD: 'inputPassword',
};

export const CriteriaFieldType = {
  DATE: 'criteriaDate',
};

export const AllowUdfInputFieldType = [
  UdfInputFieldType.STRING,
  UdfInputFieldType.MEMO,
  UdfInputFieldType.NUMBER,
  UdfInputFieldType.DATE,
  UdfInputFieldType.CHECKBOX,
  UdfInputFieldType.COLOR,
  UdfInputFieldType.TIME,
  UdfInputFieldType.IMAGE,
  UdfInputFieldType.COMBO,
  UdfInputFieldType.LOOKUP,
  UdfInputFieldType.MOBILE,
  UdfInputFieldType.PASSWORD,
];

export const OrderStatus = {
  NA: 'na',
  CONFIRMED: 'confirmed',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

export const PayStatus = {
  UNPAID: 'unpaid',
  PAID: 'paid',
  REFUNDING: 'refunding',
  REFUNDED: 'refunded',
};

export const ShipStatus = {
  UNFULFILLED: 'unfulfilled',
  PACKING: 'packing',
  SHIPPED: 'shipped',
  COLLECTED: 'collected',
  RETURNING: 'returning',
  RETURNED: 'returned',
  PARTIALSHIP: 'partialship',
  PARTIALRETURN: 'partialreturn',
};
