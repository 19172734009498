import { v4 as uuidv4 } from 'uuid';

const mailformat =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const cjkRegex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f\u3131-\uD79D]/;

const timeRegex = /(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)/;

var alphanumericRegex = /^[0-9a-zA-Z]+$/;

const isDefined = function (value: any) {
  return typeof value !== 'undefined';
};
const isUndefined = function (value: any) {
  return typeof value === 'undefined' || value === undefined;
};
const isEmpty = (text: string | undefined) => {
  return isUndefined(text) || text == null || (typeof text === 'string' && text.trim().length === 0);
};
const isNotEmpty = (text: string | undefined) => {
  return !isEmpty(text);
};
const isEmptyList = (array: any[] | undefined) => {
  return isUndefined(array) || array == null || array.length <= 0;
};
const isNotEmptyList = (array: any[] | undefined) => {
  return !isEmptyList(array);
};
const isEmptyJSONObject = (obj: {} | undefined) => {
  return isUndefined(obj) || obj == null || Object.keys(obj).length <= 0;
};
const isNotEmptyJSONObject = (obj: {} | undefined) => {
  return !isEmptyJSONObject(obj);
};
const isNumber = (test: number | undefined) => typeof test === 'number' && !isNaN(test);
const isEmptyM18Id = (id: number = 0) => {
  if (isNumber(id)) {
    return id <= 0;
  }
  return false;
};
const isNotEmptyM18Id = (id: number = 0) => {
  return !isEmptyM18Id(id);
};

const isValidEmail = (mail: string) => {
  if (mail.match(mailformat)) {
    return true;
  }
  return false;
};

const isAlphanumeric = (input: string) => {
  if (alphanumericRegex.test(input)) {
    return true;
  }
  return false;
};

const isCJKText = (text: string): boolean => {
  return cjkRegex.test(text);
};

const isTimeText = (text: any): boolean => {
  return timeRegex.test(text);
};

const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
const capitalizeFirstLetterOnly = (text: string) => {
  return capitalizeFirstLetter(text.toLowerCase());
};

const lowerCaseFirstLetter = (text: string) => {
  return text.charAt(0).toLowerCase() + text.slice(1);
};

const getSafetyNumber = (value: number | string) => {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return 0;
  }
  let numberValue: number = 0;

  if (typeof value === 'string') {
    numberValue = parseFloat(value);
  } else {
    numberValue = value;
  }
  if (!isNumber(numberValue)) {
    return 0;
  }
  return numberValue;
};

const getBooleanValue = (value: any): boolean => {
  return value.toString() === 'true';
};

const genPayloadKey = (tarList: { [key: string]: any }): string => {
  if (isUndefined(tarList)) {
    return '';
  }

  const keyList: any[] = [];
  Object.keys(tarList).forEach((item) => {
    if (!isUndefined(item)) {
      keyList.push(tarList[item]);
    }
  });
  if (keyList.length > 0) {
    return keyList.join('_');
  }

  return '';
};

const parseJSON = (value: any | undefined, throwException: boolean = false) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    if (throwException) {
      throw e;
    }
  }
  return undefined;
};

const newUuid = (): string => {
  return uuidv4();
};

const utils = {
  isDefined,
  isUndefined,
  isEmpty,
  isNotEmpty,
  isEmptyList,
  isNotEmptyList,
  isEmptyJSONObject,
  isNotEmptyJSONObject,
  isNumber,
  isEmptyM18Id,
  isNotEmptyM18Id,
  isValidEmail,
  isAlphanumeric,
  isCJKText,
  isTimeText,
  capitalizeFirstLetter,
  capitalizeFirstLetterOnly,
  lowerCaseFirstLetter,
  getSafetyNumber,
  getBooleanValue,
  genPayloadKey,
  parseJSON,
  newUuid,
};
export default utils;
