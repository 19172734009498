import { ProductType } from "@kopapro-redux/action-types";
import { ProductCategory, CategoriesState, CategoriesAction } from "@kopapro-redux/types/productCategory";

const initialState: CategoriesState = {
    pending: false,
    categories: {},
    error: ""
};

export function handleCategoriesReceived(nextState: CategoriesState, list: ProductCategory[]) {

    list.forEach((category) => {
        let temp: any = Object.assign({}, category);
        const code = category.code;
        nextState.categories[code] = temp;
    })
    return nextState;
}

const productCategoriesReducer = (state = initialState, action: CategoriesAction): CategoriesState => {
  switch (action.type) {
    case ProductType.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ProductType.RECEIVED_CATEGORIES:
      return handleCategoriesReceived({ ...state, pending: false }, action.payload as ProductCategory[]);

    default:
      return state;
  }
};

export default productCategoriesReducer; 