import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getMemberCenterViewById } from '@kopapro-redux/selectors/entities/member';
import { InputFormatList } from '@kopapro-redux/types/componentSetting';
import withAccount from '@kopapro/components/pages/account/accountHoc';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import { getInputFormatListInView } from '@kopapro-redux/utils/m18View';
import { withSearchParams } from '@kopapro/components/commons/router-hoc';
import { getM18Combo, getM18Lookup } from '@kopapro-redux/selectors/entities/m18Option';
import { M18ComboList, M18LookupList } from '@kopapro-redux/types/m18Option';
import { loadMemberForm, createUdfInfo } from '@kopapro-redux/actions/member';
import Dynamic from '@kopapro/components/pages/account/dynamic/dynamic';

interface IProps extends WithTranslation {
  searchParams: any; // from withSearchParams
}

interface DispatchProps {
  loadData: typeof loadMemberForm;
  updateInfo: typeof createUdfInfo;
}

interface StateProps {
  viewId: string | number;
  viewName: I18nDictionary;

  m18Fields: InputFormatList;
  combo?: { [k: string]: M18ComboList };
  lookup?: { [k: string]: M18LookupList };
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const compId = props.searchParams[0].get('id') || '';
  let content = getMemberCenterViewById(state, compId);

  return {
    viewId: content?.view.viewId || '',
    viewName: content?.view.viewName || { en: '' },

    m18Fields: getInputFormatListInView(content),
    combo: getM18Combo(state),
    lookup: getM18Lookup(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadData: loadMemberForm,
  updateInfo: createUdfInfo,
};

export type DynamicProps = IProps & StateProps & DispatchProps;
export default withSearchParams(withAccount(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Dynamic))));
