import { store } from '@kopapro-redux/store';
import { format } from 'date-fns';
import { Dictionary, I18nDictionary } from '@kopapro-redux/types/utilities';
import { ProductCategory } from '@kopapro-redux/types/productCategory';
import { ShopLanguageSetup, ShopLayoutComponent } from '@kopapro-redux/types/shop';
import { M18RnMeta } from '@kopapro/utils/constants/m18';
import utils from '@kopapro/utils/utils';
import KopaproApi from '@kopapro-redux/api';
import { Product, ProductGroup } from '@kopapro-redux/types/products';
import { Currency, OrderStatus, PayStatus, ShipStatus } from '@kopapro-redux/utils/constant';
import reduxUtils from '@kopapro-redux/utils/utils';
import { getCurrentLang } from '@kopapro-redux/selectors/entities/user';
import {
  getDisplayCurrency,
  roundUnitPrice,
  getBaseCurrency,
  convertToDisplayUnitPrice,
  roundAmount,
  convertToDisplayAmount,
  getCurrencyByM18Id,
} from '@kopapro-redux/selectors/entities/currency';
import { CurrencyDigit } from '@kopapro/utils/constants/currency';
import { homePageConfig } from '@kopapro/utils/config';
import { M18ComboList, M18LookupList, M18Options } from '@kopapro-redux/types/m18Option';
import { CaptchaInfo, CaptchaPatterns } from '@kopapro-redux/types/user';
import { showInType } from '@kopapro/utils/constants/constants';
import { PaymentMethod } from '@kopapro-redux/types/paymentMethod';
import { AppImages } from '@kopapro/utils/constants/images';
import { getLanguages } from '@kopapro-redux/selectors/entities/shop';
import { isM18PreviewMode } from '@kopapro-redux/utils/m18';
import { CouponVoucher, CouponVoucherLedger } from '@kopapro-redux/types/coupon';

export function geti18nValue(data: I18nDictionary | string | undefined): string {
  if (typeof data === 'undefined') {
    return '';
  }

  const state = store.getState();
  const { lang } = state.kopapro.user;
  let i18nValue = '';

  if (typeof data === 'string') {
    return data;
  } else if (typeof data === 'object') {
    i18nValue = data[lang] || '';

    if (i18nValue.length === 0) {
      const languages: ShopLanguageSetup[] = getLanguages(state) || [];

      // check sub language
      if (i18nValue.length === 0 && languages) {
        const subLang = languages.find((v) => {
          return v.id === lang;
        })?.subLangId;
        if (!utils.isEmpty(subLang)) {
          i18nValue = data[subLang!] || '';
        }
      }

      // check other language by order
      if (i18nValue.length === 0 && languages) {
        for (let v of languages) {
          i18nValue = data[v.id] || '';
          if (i18nValue.length > 0) {
            break;
          }
        }
      }
    }
  }

  return i18nValue;
}

export function getRouteTarget(componentName: string, node: ShopLayoutComponent) {
  let target: string = '';
  let targetType: string = '';
  switch (componentName) {
    case M18RnMeta.CATEGORY:
      target = '/categories';
      break;
    case M18RnMeta.UDFCATE:
      target = `/proPanel/${node.cateId}`;
      break;
    case M18RnMeta.DELIVERY:
      target = '/delivery-methods';
      break;
    case M18RnMeta.PAYMENT:
      target = '/payment-methods';
      break;
    case M18RnMeta.ABOUTUS:
      target = '/about-us';
      break;
    case M18RnMeta.BLOG:
      target = '/blogs';
      break;
    case M18RnMeta.UDFBLOGCATE:
      target = `/blog/${node.blogCateId}`;
      break;
    case M18RnMeta.POLICY:
      target = '/policy';
      break;
    case M18RnMeta.UDFHTML:
      target = `/content/${node.compId}`;
      break;
    case M18RnMeta.UDFSOCIALMEDIA:
      target = node.socialMediaUrl;
      break;
    case M18RnMeta.CONTACTUS:
      targetType = node.targetType || 'goToComponent';
      if ('goToPage' === targetType) {
        target = `/contact-us`;
      } else {
        let targetCompId = node.targetCompId;
        if (!utils.isEmpty(targetCompId) && targetCompId !== 'na') {
          targetCompId = '-' + node.targetCompId;
        }
        target = `#contact-us${targetCompId}`;
      }
      break;
    case M18RnMeta.NEWSLETTER:
      targetType = node.targetType || 'goToComponent';
      if ('goToPage' === targetType) {
        target = `/subscribe`;
      } else {
        let targetCompId = node.targetCompId;
        if (!utils.isEmpty(targetCompId) && targetCompId !== 'na') {
          targetCompId = '-' + node.targetCompId;
        }
        target = `#newsetter${targetCompId}`;
      }
      break;
  }
  return target;
}

function checkImageUrl(photoSrc: string, resloveUrl: string) {
  if (!photoSrc) {
    return '';
  } else if (
    photoSrc.startsWith('http://') ||
    photoSrc.startsWith('https://') ||
    photoSrc.startsWith('/resources/') ||
    photoSrc.startsWith('/static/') ||
    photoSrc.startsWith('data:image/')
  ) {
    return photoSrc;
  } else if (false) {
    // if resource, then no need to use
  }

  return resloveUrl;
}

// process.env.NODE_ENV === 'development'

export function resolvePaymentMethodImageURL(item: PaymentMethod) {
  let photoSrc = item.image;
  if (item.isStripepay) {
    photoSrc = AppImages.stripepayIcon;
  }

  return resolveImageURL(photoSrc);
}

export function resolveProductItemImageURL(proPhotoCode: string, isCoupon: boolean = false) {
  let photoSrc = proPhotoCode;
  if (utils.isEmpty(proPhotoCode)) {
    if (isCoupon) {
      photoSrc = AppImages.couponPlaceholder;
    } else {
      photoSrc = AppImages.productPlaceholder;
    }
  }

  return resolveImageURL(photoSrc);
}

export function resolveImageURL(photoSrc: string, thumbnail: boolean = false, imageType: string = ''): string {
  if (isM18PreviewMode()) {
    let photoSource: string = photoSrc;
    let photoSourceUrl: string = photoSrc;

    if (!utils.isEmpty(photoSource)) {
      if (photoSource.indexOf('/jsf/javax.faces.resource') >= 0) {
        photoSourceUrl = photoSource;
      } else if (photoSource.indexOf('/static/media/') >= 0) {
        const name = photoSource.replace('/static/media/', '');
        photoSource = name;
        photoSourceUrl = `/jsf/rfws/pmpcore/preview?action=getImage&name=${name}&themeUuid=still&thumbnail=${thumbnail}`;
      } else {
        const proxyServerUrl = KopaproApi.getUrl();
        const portalUrl = KopaproApi.getPortalUrl();
        photoSourceUrl = `${proxyServerUrl}/users/pmp/resources/getM18Image?portalUrl=${portalUrl}&imageName=${photoSrc}&thumbnail=${thumbnail}&imageType=${imageType}`;
      }
    }
    return checkImageUrl(photoSource, photoSourceUrl);
  }

  let pendingUrl = `${window.location.origin}/images/${photoSrc}`;
  if (!utils.isEmpty(imageType)) {
    pendingUrl += `/${imageType}`;
  }

  if (process.env.NODE_ENV === 'development' || window.configSetter.mode === 'development') {
    const proxyServerUrl = KopaproApi.getUrl();
    const portalUrl = KopaproApi.getPortalUrl();
    pendingUrl = `${proxyServerUrl}/users/pmp/resources/getM18Image?portalUrl=${portalUrl}&imageName=${photoSrc}&thumbnail=${thumbnail}&imageType=${imageType}`;
  }

  return checkImageUrl(photoSrc, pendingUrl);
}

export function resolveTempImageURL(photoSrc: string) {
  const proxyServerUrl = KopaproApi.getUrl();
  const portalUrl = KopaproApi.getPortalUrl();
  const pendingUrl = `${proxyServerUrl}/users/pmp/temp/getImage?imageName=${portalUrl}@${photoSrc}`;
  return checkImageUrl(photoSrc, pendingUrl);
}

export function getCustomStyle(styleProps: any, needBackgroundColor = false) {
  const { backgroundImage, useUdfStyle, backgroundColor, font, fontColor, fontSize, fontStyle, height } = styleProps;
  const fontFamily = !font || font === '' || font === 'none' ? '' : font;

  let udfStyle: any = {};

  if (needBackgroundColor) {
    if (backgroundImage && backgroundImage.length > 0) {
      udfStyle.backgroundImage = `url(${resolveImageURL(backgroundImage)})`;
    } else if (useUdfStyle && typeof backgroundColor == 'string' && backgroundColor.length > 0) {
      udfStyle.backgroundColor = backgroundColor;
    }
  }
  // if not use udf style, then return backgroundImage only
  if (!useUdfStyle) {
    return udfStyle;
  }
  udfStyle.fontSize = fontSize;
  if (typeof fontColor === 'string' && fontColor.length > 0) {
    udfStyle.color = fontColor;
  }
  if (typeof fontFamily === 'string' && fontFamily.length > 0) {
    udfStyle.fontFamily = fontFamily;
    utils.appendFontFamilyLink(fontFamily);
  }

  if (fontStyle) {
    if (fontStyle.indexOf('underline') >= 0) {
      udfStyle.textDecoration = 'underline';
    }
    if (fontStyle.indexOf('bold') >= 0) {
      udfStyle.fontWeight = 'bold';
    }
    if (fontStyle.indexOf('italic') >= 0) {
      udfStyle.fontStyle = 'italic';
    }
  } else {
    udfStyle.fontWeight = 'unset';
  }

  if (height) {
    udfStyle.height = height;
  }

  return udfStyle;
}

export function getCompHeight(
  heightOption: string,
  udfHeight: number,
  defaultHeight: number | string = homePageConfig.componentDefaultHeight
) {
  // "def" case = default depends on theme
  let height = defaultHeight;
  switch (heightOption) {
    case 'xs':
      height = 75;
      break;
    case 's':
      height = 150;
      break;
    case 'm':
      height = 300;
      break;
    case 'l':
      height = 375;
      break;
    case 'xl':
      height = 450;
      break;
    case 'udf':
      height = udfHeight;
      break;
  }

  return height;
}

export function getFullLayoutClass(imageLayout: string): { container: boolean } {
  return { container: imageLayout !== 'full' };
}

// assume mobile width is xs
export function getShowInClass(showIn: string, displayValue = 'block'): string {
  if (showIn === showInType.desktop) {
    // Hidden only on xs
    return ` d-none d-sm-${displayValue} `;
  } else if (showIn === showInType.mobile) {
    // Visible only on xs
    return ` d-${displayValue} d-sm-none `;
  }

  return '';
}

export function getBannerImageFitClass(imageObjectFit: string | undefined, defaultClass = 'cover'): string {
  if (imageObjectFit === 'cover') {
    return 'cover';
  } else if (imageObjectFit === 'contain') {
    return 'contain';
  }
  return defaultClass;
}

export function getCategoryName(
  category: ProductCategory | { code: string; desc: string; eshopDesc?: string }
): string {
  if (!category) {
    return '';
  }

  const { code, desc, eshopDesc } = category;
  return geti18nValue(desc) || geti18nValue(eshopDesc) || code;
}

export function getProductUrl(product: ProductGroup): string {
  if (product) {
    if (product.m18ProGpId > 0) {
      return `/products/${product.code}`;
    } else {
      return `/products/item/${product.code}`;
    }
  }

  return '';
}

const getCurrencyDigit = (currency: string) => {
  if (CurrencyDigit.hasOwnProperty(currency)) {
    return CurrencyDigit[currency];
  }
  return CurrencyDigit.DEFAULT;
};

export function formatToCurrency(value: number, currency: string, isoCode: string, showCurrency = true): string {
  const digit = getCurrencyDigit(isoCode);
  const state = store.getState();
  const language = getCurrentLang(state);
  const options = {
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
  };
  // use en locale to format general number (e.g: 12300 => 12,300.00)
  var v = new Intl.NumberFormat(language, options).format(value);

  if (showCurrency) {
    return `${currency}${v}`;
  }
  return `${v}`;
}

export function formatToBaseCurrency(value: number, showCurrency = true): string {
  const state = store.getState();
  const currency = getBaseCurrency(state);
  let { sym, isoCurCode } = currency || { sym: '', isoCurCode: '' };
  return formatToCurrency(value, sym, isoCurCode, showCurrency);
}

export function formatToSelectedCurrency(value: number, showCurrency = true): string {
  const state = store.getState();
  const currency = getDisplayCurrency(state);
  let { sym, isoCurCode } = currency || { sym: '', isoCurCode: '' };
  return formatToCurrency(value, sym, isoCurCode, showCurrency);
}

export function getSelectedCurrencySym(): string {
  const state = store.getState();
  const currency = getDisplayCurrency(state);
  let { sym, isoCurCode } = currency || { sym: '', isoCurCode: '' };
  return sym;
}

export function formatToCurrencyByM18Id(value: number, m18Id: number, showCurrency = true): string {
  const state = store.getState();
  const currency = getCurrencyByM18Id(state, m18Id);
  let { sym, isoCurCode } = currency || { sym: '', isoCurCode: '' };
  return formatToCurrency(value, sym, isoCurCode, showCurrency);
}

// As affect display so will not put in selector
export function formatBaseUnitPrice(value: number | string): string {
  return formatUnitPrice(value, Currency.BASE);
}
export function formatUnitPriceWithSelectedCurrency(value: number | string): string {
  return formatUnitPrice(value, Currency.SELECTED);
}

export function formatUnitPrice(value: number | string, currencyType = 0): string {
  const state = store.getState();

  const numberValue = utils.getSafetyNumber(value);

  if (currencyType === Currency.BASE) {
    return formatToBaseCurrency(roundUnitPrice(state, numberValue));
  } else if (currencyType === Currency.SELECTED) {
    return formatToSelectedCurrency(convertToDisplayUnitPrice(state, numberValue));
  }

  return `${roundUnitPrice(state, numberValue)}`;
}

export function formatBaseAmount(value: number | string): string {
  return formatAmount(value, Currency.BASE);
}
export function formatAmountWithSelectedCurrency(value: number | string): string {
  return formatAmount(value, Currency.SELECTED);
}

export function formatAmount(value: number | string, currencyType = 0): string {
  const state = store.getState();

  const numberValue = utils.getSafetyNumber(value);

  if (currencyType === Currency.BASE) {
    return formatToBaseCurrency(roundAmount(state, numberValue));
  } else if (currencyType === Currency.SELECTED) {
    return formatToSelectedCurrency(convertToDisplayAmount(state, numberValue));
  }

  return `${roundAmount(state, numberValue)}`;
}

export function formatDisplayAmount(value: number | string, showZero: boolean = false): string {
  let displayAmount: string = '';

  const amount = formatBaseAmount(value);
  const estimateAmount = formatAmount(value, Currency.SELECTED);

  let tempAmount = amount;
  if (value !== 0) {
    if (amount !== estimateAmount) {
      tempAmount = `${amount} (${estimateAmount})`;
    }
    displayAmount = tempAmount;
  } else if (showZero) {
    displayAmount = tempAmount;
  }
  return displayAmount;
}

export function isShowRetailUnitPrice(oriUp: number | string, up: number | string, adpUp = 0): boolean {
  const state = store.getState();
  const safetyOriUp = roundUnitPrice(state, oriUp);
  const safetyAdpUp = roundUnitPrice(state, adpUp);
  const safetyUp = roundUnitPrice(state, up);

  if (safetyAdpUp !== 0 && safetyAdpUp !== safetyUp) {
    return safetyAdpUp > safetyUp;
  } else {
    return safetyOriUp > safetyUp;
  }
}

export function getRetailUnitPrice(oriUp: number | string, up: number | string, adpUp = 0): number {
  const state = store.getState();
  const safetyOriUp = roundUnitPrice(state, oriUp);
  const safetyAdpUp = roundUnitPrice(state, adpUp);
  const safetyUp = roundUnitPrice(state, up);

  if (isShowRetailUnitPrice(oriUp, up, adpUp)) {
    if (safetyAdpUp !== 0 && safetyAdpUp !== safetyUp) {
      return safetyAdpUp;
    } else {
      return safetyOriUp;
    }
  }
  return safetyUp;
}

export function getProductDetailPricing(item: Product | ProductGroup) {
  const { hasMemTypeUp, memTypeUp, up, oriUp, memTypeShortName } = item;

  const oriUnitPrice = utils.getSafetyNumber(oriUp);
  const unitPrice = utils.getSafetyNumber(up);
  const memberUnitPrice = utils.getSafetyNumber(memTypeUp);
  let regularPrice = 0;
  let salesPrice = unitPrice;

  const isShowOriUp = oriUnitPrice > unitPrice;
  // const isShowMemberUp = hasMemTypeUp;
  const isShowMemberUpDesc = hasMemTypeUp && !utils.isEmpty(geti18nValue(memTypeShortName));

  if (isShowOriUp) {
    regularPrice = oriUnitPrice;
  }
  if (hasMemTypeUp) {
    if (unitPrice > memberUnitPrice) {
      regularPrice = unitPrice;
      salesPrice = memberUnitPrice;
    }
  }

  return {
    // isShowOriUp,
    // isShowMemberUp,
    isShowMemberUpDesc,
    regularPrice,
    salesPrice,
  };
}

export function formatMobile(mobile: string, mobileCountry = ''): string {
  return utils.isEmpty(mobileCountry) ? '' : `(${mobileCountry}) ` + mobile;
}

export function formatPersonName(firstName: string = '', lastName: string = ''): string {
  if (reduxUtils.isCJKText(`${firstName}${lastName}`)) {
    return `${lastName} ${firstName}`.trim();
  }

  return `${firstName} ${lastName}`.trim();
}

export const getM18ComboOptions = (combos: Dictionary<M18ComboList> | undefined, pattern: string) => {
  if (combos && combos[pattern]) {
    const combo: M18ComboList = combos[pattern];
    const option: M18Options = Object.keys(combo).map((key) => {
      const obj = combo[key];
      const label = geti18nValue(obj);

      return {
        label: label,
        value: key,
      };
    });

    return option;
  }
};

export const getM18LookupOptions = (lookups: Dictionary<M18LookupList> | undefined, pattern: string) => {
  if (lookups && lookups[pattern]) {
    const lookup: M18LookupList = lookups[pattern];
    const option: M18Options = [];
    Object.keys(lookup).forEach((item) => {
      const key: number = Number(item);
      const label = lookup[key].label;
      const expired = lookup[key].expired;
      if (!expired) {
        option.push({
          label: label,
          value: item,
        });
      }
    });

    return option;
  }
};

export const submitCaptchaDataParams = (captchaInfo: CaptchaInfo) => {
  let params = {};
  let data = {};

  const captchaID: string = captchaInfo.id;
  const useCaptcha: boolean = captchaInfo.useCaptcha;
  const captchaType: string = captchaInfo.type;
  const list: CaptchaPatterns = captchaInfo.selectedList;

  if (useCaptcha) {
    if (captchaType === 'builtIn') {
      const captchaAns = list.map((item) => item.id);
      data = { captchaAns };
      params = { captchaID };
    } else if (captchaType === 'google') {
      const grecaptcha = window.grecaptcha;
      if (grecaptcha) {
        const token = grecaptcha.getResponse(captchaID);
        data = { captchaToken: token };
      }
    }
  }

  return {
    data,
    params,
  };
};

export const formatDate = (datetime: Date | number): string => {
  const date = new Date(datetime);
  if (date.toString() !== 'Invalid Date') {
    return format(date, 'yyyy-MM-dd');
  }
  return date.toString();
};

export const formatTime = (date: Date | string): string => {
  if (typeof date === 'object' && date.toString() !== 'Invalid Date') {
    return format(date!, 'HH:mm:ss');
  }
  if (typeof date === 'string') {
    return date.toString();
  }
  return '';
};

export const getOrderStatusMesscode = (orderStatus: string = ''): string => {
  let code: string = 'ce01_pmpcore.react.orderStatus.notConfirmed';
  switch (orderStatus) {
    case OrderStatus.CONFIRMED:
    case OrderStatus.COMPLETED:
    case OrderStatus.CANCELLED:
      code = `ce01_pmpcore.react.orderStatus.${orderStatus}`;
      break;
  }
  return code;
};

export const getPayStatusMesscode = (payStatus: string = ''): string => {
  let code: string = 'ce01_pmpcore.react.payStatus.unpaid';
  switch (payStatus) {
    case PayStatus.UNPAID:
    case PayStatus.PAID:
    case PayStatus.REFUNDING:
    case PayStatus.REFUNDED:
      code = `ce01_pmpcore.react.payStatus.${payStatus}`;
      break;
  }
  return code;
};

export const getShipStatusMesscode = (shipStatus: string = ''): string => {
  let code: string = 'ce01_pmpcore.react.shipStatus.unfulfilled';
  switch (shipStatus) {
    case ShipStatus.UNFULFILLED:
    case ShipStatus.PACKING:
    case ShipStatus.SHIPPED:
    case ShipStatus.COLLECTED:
    case ShipStatus.RETURNING:
    case ShipStatus.RETURNED:
    case ShipStatus.PARTIALSHIP:
    case ShipStatus.PARTIALRETURN:
      code = `ce01_pmpcore.react.shipStatus.${shipStatus}`;
      break;
  }
  return code;
};

export const isHideMinicart = () => {
  const path = window.location.pathname;
  if (path.startsWith('/checkout') || path.startsWith('/payment/')) {
    return true;
  }
  return false;
};

export const getWindowScrollTop = () => {
  if (isM18PreviewMode()) {
    return window.scrollY;
  }
  return document.documentElement.scrollTop;
};

export const parseDatetime = (date: string, time: string) => {
  // if not empty date
  if (utils.isNotEmpty(date) && date !== '1900-01-01' && typeof time === 'string') {
    return new Date(`${date} ${time}`);
  }

  return null;
};

export const getCouponBalance = (coupon: CouponVoucher) => {
  const { vouOneOff, svBalance, svfIniBal } = coupon;
  return vouOneOff && svBalance !== 0 ? svfIniBal : svBalance;
};

export const getLedgerOpenBalance = (coupon: CouponVoucherLedger) => {
  const { vouOneOff, openBal, svfIniBal } = coupon;

  if (typeof vouOneOff === 'undefined' || typeof svfIniBal === 'undefined') {
    return openBal;
  }

  return vouOneOff ? openBal * svfIniBal : openBal;
};

export const getLedgerClosingBalance = (coupon: CouponVoucherLedger) => {
  const { vouOneOff, closeBal, svfIniBal } = coupon;

  if (typeof vouOneOff === 'undefined' || typeof svfIniBal === 'undefined') {
    return closeBal;
  }

  return vouOneOff ? closeBal * svfIniBal : closeBal;
};