import { Component, ReactNode } from 'react';
import { AmountsProps } from '@kopapro/components/order/amounts';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import { formatBaseAmount } from '@kopapro/utils/m18';
import { PayStatus } from '@kopapro-redux/utils/constant';

interface AmountState {}

export default class Amounts extends Component<AmountsProps, AmountState> {
  amountText = (amount: number = 0, positive: boolean = true) => {
    return `${positive ? '' : '-'} ${formatBaseAmount(amount)}`;
  };

  renderRow(label: string, amount: number = 0, positive: boolean = true, props: any = {}): ReactNode {
    const displayAmount = this.amountText(amount, positive);
    return (
      <div className="text-end" {...props}>
        {`${label} : ${displayAmount}`}
      </div>
    );
  }

  render(): ReactNode {
    const { t, payStatus, amountDetails } = this.props;
    const {
      serviceSurchargeAmt,
      shipFeeAmt,
      pmSurchargeAmt,
      promoDiscAmt,
      promoDiscDescObj,
      totalAmount,
      couponAmt,
      outstandAmt,
    } = amountDetails!;
    const { payment } = this.props;

    return (
      <div className="border-top p-3 text-end card border-0">
        <div>
          {serviceSurchargeAmt > 0 &&
            this.renderRow(t('ce01_pmpcore.kopapro.react.serviceSurchargeAmt'), serviceSurchargeAmt)}

          {shipFeeAmt > 0 && this.renderRow(t('ce01_pmpcore.kopapro.react.shipFee'), shipFeeAmt)}

          {pmSurchargeAmt > 0 &&
            this.renderRow(
              `${geti18nValue(payment?.paymDetail.desc!)} ${t('ce01_pmpcore.kopapro.react.paymSurchargeAmt')}`,
              pmSurchargeAmt
            )}

          {promoDiscAmt > 0 && (
            <>
              {this.renderRow(t('ce01_pmpcore.kopapro.react.promoDiscAmt'), promoDiscAmt, false, {
                className: 'text-danger',
              })}
              <div>
                <div className={'text-danger text-end'}>{`${geti18nValue(promoDiscDescObj)}`}</div>
              </div>
            </>
          )}

          {this.renderRow(t('ce01_pmpcore.kopapro.react.totalAmount'), totalAmount)}

          {couponAmt > 0 &&
            this.renderRow(t('ce01_pmpcore.kopapro.react.couponAmt'), couponAmt, false, {
              className: 'text-danger',
            })}

          {PayStatus.UNPAID === payStatus &&
            outstandAmt > 0 &&
            this.renderRow(t('ce01_pmpcore.kopapro.react.netPayment'), outstandAmt)}
        </div>
      </div>
    );
  }
}
