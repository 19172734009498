import { Component } from 'react';
import { AppControlProps } from '@kopapro/components/misc/appControl';
import htmlUtil from '@kopapro/utils/utils';
export default class AppControl extends Component<AppControlProps> {
  componentDidMount(): void {
    window.addEventListener('beforeunload', this.checkCartHandler);
    htmlUtil.setDocumentTitle(this.props.companyName);
  }

  componentDidUpdate = () => {
    htmlUtil.setDocumentTitle(this.props.companyName);
  };

  componentWillUnmount(): void {
    window.removeEventListener('beforeunload', this.checkCartHandler);
  }

  checkCartHandler = (event: BeforeUnloadEvent) => {
    const { numberOfCartItems, checkShopCart } = this.props;
    if (checkShopCart && numberOfCartItems > 0) {
      const confirmationMessage = 'You have items in Shopping Cart. Confirm to leave?';
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    }
    return null;
  };

  render() {
    return null;
  }
}
