import { Component, ReactNode } from 'react';
import { ImageWithTextOverlayProps } from '@kopapro/components/shop/imageWithTextOverlay';
import { getCompHeight, getCustomStyle, getFullLayoutClass, geti18nValue } from '@kopapro/utils/m18';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import ShopImage from '@kopapro/components/commons/shopImage';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import classnames from 'classnames';
import utils from '@kopapro-redux/utils/utils';
import { faDribbbleSquare } from '@fortawesome/free-brands-svg-icons';

export default class ImageWithTextOverlay extends Component<ImageWithTextOverlayProps> {
  //TODO: put in utils if reuse
  getTextAlignClass = (alignment: string) => {
    let value = '';
    switch (alignment) {
      case 'left':
        value = 'text-left';
        break;
      case 'center':
        value = 'text-center';
        break;
      case 'right':
        value = 'text-end';
        break;
    }
    return value;
  };

  renderHeading(): ReactNode {
    const { enableHeadingStyle, headingDto, headingObj, headingAlignment } = this.props.content!;

    let styleTitle = {};
    if (enableHeadingStyle) {
      styleTitle = getCustomStyle({ ...headingDto, useUdfStyle: true });
    }

    return (
      <div className={`image-with-text-overlay-heading ${this.getTextAlignClass(headingAlignment)}`}>
        <h5 style={{ ...styleTitle }}>{geti18nValue(headingObj)}</h5>
      </div>
    );
  }

  renderDetailBtn(): ReactNode {
    const { hideButton } = this.props.content!;
    if (hideButton) {
      return null;
    }

    const { buttonLabelObj, buttonAlignment, url } = this.props.content!;

    const textClass = this.getTextAlignClass(buttonAlignment);

    return (
      <div className={textClass}>
        <Link to={url} className={`btn btn-main`}>
          {geti18nValue(buttonLabelObj)}
        </Link>
      </div>
    );
  }

  renderImage(photoCode: string): ReactNode {
    const { heightOption, udfHeight } = this.props.content!;
    const height = getCompHeight(heightOption, udfHeight);
    return <ShopImage className="w-100 cover" src={photoCode} height={height} />;
  }

  renderImageList(): ReactNode {
    const { photoCode, photoCodeList, heightOption, udfHeight } = this.props.content!;
    const { url, urlBuildInPhoto } = this.props.content!;
    const height = getCompHeight(heightOption, udfHeight);

    if (utils.isNotEmptyList(photoCodeList)) {
      let component: any = undefined;
      let componentProps = { to: '' };
      if (utils.isNotEmpty(url) && urlBuildInPhoto) {
        component = Link;
        componentProps = { to: url };
      }

      return (
        <Carousel controls={photoCodeList.length > 1} style={{ height }} interval={null} indicators={false}>
          {photoCodeList.map((item, i) => {
            return (
              <Carousel.Item key={`${item}_${i}`} as={component} {...componentProps} className="w-100">
                {this.renderImage(item)}
              </Carousel.Item>
            );
          })}
        </Carousel>
      );
    }

    if (utils.isNotEmpty(url) && urlBuildInPhoto) {
      return <Link to={url}>{this.renderImage(photoCode)}</Link>;
    }
    return this.renderImage(photoCode);
  }

  renderContent(): ReactNode {
    const { detailObj } = this.props.content!;
    return <SafetyDOM html={geti18nValue(detailObj)} />;
  }

  render(): ReactNode {
    const { compId, content } = this.props;
    if (utils.isEmptyJSONObject(content)) {
      return null;
    }

    // const { textSize } = content!;
    const { heightOption, udfHeight, imageLayout } = this.props.content!;
    const height = getCompHeight(heightOption, udfHeight, 300);
    return (
      <div
        id={`image-with-text-overlay-${compId}`}
        className={classnames('image-with-text-overlay', getFullLayoutClass(imageLayout))}>
        <div className="content" style={{ height }}>
          <div className="d-flex flex-column justify-content-center textOverlay w-100 px-3" style={{ height }}>
            {this.renderHeading()}
            {this.renderContent()}
            {this.renderDetailBtn()}
          </div>
          {this.renderImageList()}
        </div>
      </div>
    );
  }
}