import { Component } from "react";
import KppBreadcrumb from "@kopapro/components/commons/breadcrumb";
import ScrollToTopOnMount from "@kopapro/template/ScrollToTopOnMount";
import { PaymentMethodListProps } from "@kopapro/components/paymentMethod/list";
import PaymentMethodItem from "@kopapro/components/paymentMethod/item";

export default class PaymentMethodList extends Component<PaymentMethodListProps> {
  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t } = this.props;

    const items = [
      { text: t("ce01_pmpcore.react.home"), to: "/", active: false },
      { text: t("ce01_pmpcore.react.paymentMethods"), to: "/payment-methods", active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  render() {
    const { list: paymentMethods } = this.props;

    return (
      <div className="payment-method-list-container page-container container px-xl-5">
        <ScrollToTopOnMount />
        {this.renderTop()}
        <div className="payment-method-list row">
          <div className="d-flex flex-column h-100">
            <div className={"row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-3 mb-4 flex-shrink-0 "}>
              {paymentMethods.map((item) => {
                return <PaymentMethodItem key={item.code} item={item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
