import { PaymentMethodType } from "@kopapro-redux/action-types";
import { PaymentMethod, PaymentMethodsAction, PaymentMethodsState } from '@kopapro-redux/types/paymentMethod';

const initialState: PaymentMethodsState = {
  pending: false,
  methods: {},
  error: '',
};

export function handlePaymentMethodsReceived(nextState: PaymentMethodsState, list: PaymentMethod[]) {
  const methods: any = {};
  list.forEach((method) => {
    let temp: any = Object.assign({}, method);
    const code = method.code;
    methods[code] = temp;
  });
  nextState.methods = methods;
  return nextState;
}

const paymentMethodsReducer = (state = initialState, action: PaymentMethodsAction): PaymentMethodsState => {
  switch (action.type) {
    case PaymentMethodType.RECEIVED_PAYMENTMETHODS:
      return handlePaymentMethodsReceived({ ...state, pending: false }, action.payload as PaymentMethod[]);

    default:
      return state;
  }
};

export default paymentMethodsReducer;