import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { ProductType } from '@kopapro-redux/action-types';
import { getProductSuccess, getProductFailure, fetchProductsByCategorySuccess } from '@kopapro-redux/actions/products';

import KopaproApi from '@kopapro-redux/api';
import {
  GetProductRequest,
  Product,
  ProductFilter,
  ProductGroup,
  ProductReviewInfo,
} from '@kopapro-redux/types/products';
import { QueryList } from '@kopapro-redux/types/general';
import { getProductCategoryByCode } from '@kopapro-redux/selectors/entities/productCategories';
import { ProductCategory } from '@kopapro-redux/types/productCategory';
import { getCategoriesSaga } from '@kopapro-redux/sagas/entities/productCategorySaga';
import { getAppliedFilter } from '@kopapro-redux/selectors/entities/products';
import utils from '@kopapro-redux/utils/utils';
import { getCurrentUserInfo, getMemberTypeId } from '@kopapro-redux/selectors/entities/user';
import { UserInfo } from '@kopapro-redux/types/user';
import { removeStorageItem, setStorageItem } from '@kopapro-redux/utils/cookies';

/*
TODO:
 2. define route and sub
*/

function* getProductSaga(action: GetProductRequest) {
  try {
    const { payload, callback } = action;
    const memberTypeId: number = yield select(getMemberTypeId);

    const data: { products: { [k: number]: Product }; proGroup: ProductGroup } = yield call(
      KopaproApi.getProductByCode,
      payload.proCode as string,
      payload.gpCode as string,
      payload.seoUrl as string,
      memberTypeId
    );

    let relatedItems: ProductGroup[] = [];
    let packageProducts: ProductGroup[] = [];
    if (data && data.products) {
      // assume first id is products
      const products = Object.values(data.products);
      let packageProdustList: number[] = [];
      if (products.length > 0) {
        const firstProduct = products[0];
        const { relatedProGp } = firstProduct!;

        if (relatedProGp.length > 0) {
          const relatedItemsResponse: QueryList<ProductGroup> = yield call(
            KopaproApi.getRelatedItems,
            relatedProGp,
            memberTypeId
          );
          relatedItems = relatedItemsResponse.data;
        }

        products.forEach((product) => {
          packageProdustList = [...packageProdustList, ...product.packagePro];
        });

        if (packageProdustList.length > 0) {
          const packageProductResponse: QueryList<ProductGroup> = yield call(
            KopaproApi.getPackageProducts,
            packageProdustList,
            memberTypeId
          );
          packageProducts = packageProductResponse.data;
        }
      }
    }

    yield put(
      getProductSuccess({
        data,
        relatedItems,
        packageProducts,
      })
    );
    if (callback) {
      callback(data);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
      yield put(
        getProductFailure({
          error: e.message,
        })
      );
    }
  }
}

function* getProductByIdSaga(action: GetProductRequest) {
  try {
    const { payload, callback } = action;
    const { proId, gpId, seoUrl } = payload;
    const memberTypeId: number = yield select(getMemberTypeId);

    const data: { products: { [k: number]: Product }; proGroup: ProductGroup } = yield call(
      KopaproApi.getProduct,
      gpId,
      proId,
      memberTypeId,
      seoUrl
    );

    let relatedItems: ProductGroup[] = [];
    let packageProducts: ProductGroup[] = [];
    let product;
    if (data && data.products) {
      // assume first id is products
      const products = Object.values(data.products);
      product = products.find((product) => {
        return product.m18ProId === proId;
      });
    }

    if (callback) {
      callback(product, data.proGroup);
    }

    // do not update redux store

    yield put(
      getProductSuccess({
        data,
        relatedItems,
        packageProducts,
      })
    );
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function* getProductByCategorySaga(action: any) {
  const { payload, callback } = action;

  const { categoryId } = payload;
  let productCategoryId = categoryId;
  if (!utils.isNumber(categoryId)) {
    let category: ProductCategory = yield select(getProductCategoryByCode(payload.code));
    if (!category) {
      // try get one first
      yield call(getCategoriesSaga);
    }
    category = yield select(getProductCategoryByCode(payload.code));
    if (category) {
      productCategoryId = category.m18Id;
    } else {
      if (callback) {
        // refactor later
        callback(new Error('invalid_code'), { redirect: true });
      }
      return;
    }
  }

  const { sortBy, page, pageSize, keyword } = payload;

  const filter: ProductFilter = yield select(getAppliedFilter);
  const startPoint = page * pageSize || 0;
  const memberTypeId: number = yield select(getMemberTypeId); // get from state
  const response: QueryList<Product> = yield call(
    KopaproApi.getProductByCategory,
    productCategoryId,
    sortBy,
    startPoint,
    pageSize,
    memberTypeId,
    filter,
    keyword
  );
  yield put(fetchProductsByCategorySuccess(response, productCategoryId));
  if (callback) {
    callback(response);
  }
}

function* searchProductsSaga(action: any) {
  const { payload, callback } = action;
  const { keyword, pageSize } = payload;
  const memberTypeId: number = yield select(getMemberTypeId);
  const response: QueryList<ProductGroup> = yield call(KopaproApi.searchProducts, keyword, memberTypeId, 0, pageSize);

  // update state

  if (callback) {
    callback(response);
  }
}

function* checkProductInWishListSaga(action: any) {
  const { payload, callback } = action;
  const { proId } = payload;

  const userInfo: UserInfo = yield select(getCurrentUserInfo);
  const userId = userInfo.uid;
  if (userId > 0) {
    const response: { status: boolean } = yield call(KopaproApi.checkProductInWishlist, userId, proId);
    if (callback) {
      callback(response.status);
    }
  }
}

function* addProductToWishListSaga(action: any) {
  const { payload, callback } = action;
  const { proId, noticeStock } = payload;

  const userInfo: UserInfo = yield select(getCurrentUserInfo);
  const userId = userInfo.uid;
  if (userId > 0) {
    const response: { status: boolean } = yield call(KopaproApi.addProductToWishlist, userId, proId, noticeStock);
    if (callback) {
      callback(response.status);
    }
  }
}

function* removeProductFromWishListSaga(action: any) {
  const { payload, callback } = action;
  const { proId } = payload;

  const userInfo: UserInfo = yield select(getCurrentUserInfo);
  const userId = userInfo.uid;
  if (userId > 0) {
    const response: { status: boolean } = yield call(KopaproApi.removeProductFromWishlist, userId, proId);
    if (callback) {
      callback(response.status);
    }
  }
}

function* loadProductCommentSaga(action: any) {
  const { payload, callback } = action;
  const { proIdList } = payload;

  const response: { status: boolean; queryId: string; querySize: number } = yield call(
    KopaproApi.loadProductComment,
    proIdList
  );
  if (callback) {
    callback(response.status, response.queryId, response.querySize);
  }
}

function* getProductCommentDetailSaga(action: any) {
  const { payload, callback } = action;
  const { proIdList, queryId, startPoint, dataCount } = payload;

  const response: { status: boolean; results: string[]; info: ProductReviewInfo } = yield call(
    KopaproApi.getProductComment,
    proIdList,
    queryId,
    startPoint,
    dataCount
  );
  if (callback) {
    callback(response.status, response.results, response.info);
  }
}

function* updateFilter(action: any) {
  const { payload } = action;
  if (action.type === ProductType.CLEAR_FILTER) {
    removeStorageItem('kpp_filter');
  } else {
    setStorageItem('kpp_filter', JSON.stringify(payload));
  }
}

function* productsSaga() {
  yield all([
    takeEvery(ProductType.GET_PRODUCT_REQUEST, getProductSaga),
    takeEvery(ProductType.GET_PRODUCT_BY_ID_REQUEST, getProductByIdSaga),
    takeEvery(ProductType.GET_PRODUCTS_BY_CATEGORY_REQUEST, getProductByCategorySaga),
    takeLatest(ProductType.SEARCH_PRODUCT_REQUEST, searchProductsSaga),
    takeLatest(ProductType.CHECK_PRODUCT_IN_WISHLIST, checkProductInWishListSaga),
    takeLatest(ProductType.ADD_PRODUCT_TO_WISHLIST, addProductToWishListSaga),
    takeLatest(ProductType.REMOVE_PRODUCT_FROM_WISHLIST, removeProductFromWishListSaga),
    takeLatest(ProductType.LOAD_PRODUCT_COMMENT, loadProductCommentSaga),
    takeLatest(ProductType.GET_PRODUCT_COMMENT_DETAIL, getProductCommentDetailSaga),
    takeLatest(ProductType.APPLY_FILTER, updateFilter),
    takeLatest(ProductType.CLEAR_FILTER, updateFilter),
  ]);
}

export default productsSaga;
