import { Component, ReactNode } from 'react';
import { CommentModalProps } from '@kopapro/components/order/items/commentModal';
import Modal from 'react-bootstrap/Modal';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import SpinnerButton from '@kopapro/components/commons/spinnerButton';
import RichTextEditor from '@kopapro/components/commons/richTextEditor';
import {
  convertEditorStateToString,
  convertStringToEditorState,
} from '@kopapro/components/commons/richTextEditor/richTextEditor';
import { EditorState } from 'react-draft-wysiwyg';
import Ratings from '@kopapro/components/commons/ratings';

interface CommentModalState {
  sending: boolean;
  errorMessage: string;
  successMessage: string;
  currentScore: number;
  currentComment?: EditorState;
}

export default class CommentModals extends Component<CommentModalProps, CommentModalState> {
  ratingSize = 32;

  defaultState = {
    sending: false,
    errorMessage: '',
    successMessage: '',
    currentScore: 1,
  };

  constructor(props: CommentModalProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount(): void {
    const { item } = this.props;
    const { score, comment } = item;
    this.setState({
      currentScore: score || this.state.currentScore,
      currentComment: convertStringToEditorState(comment),
    });
  }

  componentDidUpdate(prevProps: Readonly<CommentModalProps>): void {
    const { show, item } = this.props;
    const { score, comment } = item;
    const { show: previousShow, item: previousItem } = prevProps;
    const { score: previousScore, comment: previousComment } = previousItem;

    const isChanged = (show && show !== previousShow) || score !== previousScore || comment !== previousComment;

    if (isChanged) {
      this.setState({
        currentScore: score,
        currentComment: convertStringToEditorState(comment),
      });
    }
  }

  handleClose = () => {
    this.setState({ ...this.defaultState });
    if (this.props.onCloseHandler) {
      this.props.onCloseHandler();
    }
  };

  handleSubmit = () => {
    const { addComment, orderId, item } = this.props;
    const self = this;

    this.setState({ sending: true, errorMessage: '', successMessage: '' });

    const formData = {
      orderId,
      proId: item?.proId || 0,
      score: this.state.currentScore,
      comment: convertEditorStateToString(this.state.currentComment),
    };

    // // dispatch request
    addComment(formData, function (isSuccess: boolean, message: string = '') {
      if (isSuccess) {
        self.setState({ sending: false, successMessage: 'ce01_pmpcore.react.submitSuccess' });
      } else {
        self.setState({ sending: false, errorMessage: message });
      }
    });
  };

  render(): ReactNode {
    const { t, show } = this.props;
    const { sending, successMessage, errorMessage } = this.state;
    return (
      <Modal
        className="order-item-comment-modal"
        show={show}
        onHide={this.handleClose}
        fullscreen={'lg-down'}
        size={'lg'}
        scrollable={true}
        backdrop={'static'}
        keyboard={false}
        centered={true}>
        <Modal.Header closeButton={!sending}>
          {<Modal.Title>{t('ce01_pmpcore.react.comment')}</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Ratings
              size={this.ratingSize}
              rating={this.state.currentScore}
              onChange={(value: number) => this.setState({ currentScore: value })}
            />
          </div>
          <RichTextEditor
            editorState={this.state.currentComment}
            onEditorStateChange={(value: EditorState) => this.setState({ currentComment: value })}
          />
        </Modal.Body>
        <Modal.Footer>
          <ErrorMessage message={t(errorMessage)} />
          <SuccessMessage message={t(successMessage)} />

          <div className="d-flex">
            <SpinnerButton variant="primary" className="btn-main" onClick={this.handleSubmit} spinning={sending}>
              {t('ce01_pmpcore.react.submitBtn')}
            </SpinnerButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
