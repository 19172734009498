import React from 'react';
import ReactDOM from 'react-dom';

import '@kopapro/scss/custom.scss';
import '@kopapro/index.css';
// import { BrowserRouter as Router } from 'react-router-dom';
import Router from '@kopapro/router';
import App from '@kopapro/App';
import { createStore } from '@kopapro-redux/store';
import { customReducer } from '@app-redux/reducers';
import localSaga from '@app-redux/sagas';
import { Provider } from 'react-redux';

import i18n from 'i18next';
import { enI18n, twI18n, cnI18n } from '@kopapro/i18n';

import { initReactI18next } from 'react-i18next';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { initDataRequest, checkUpdate } from '@kopapro-redux/actions/misc';

import { format as formatDate, formatDistance, formatRelative, isDate, isValid, toDate } from 'date-fns';
import { enUS, zhCN, zhHK } from 'date-fns/locale';

const locales = { en: enUS, 'zh-TW': zhHK, 'zh-CN': zhCN };

const store = createStore(customReducer, localSaga);
const dispatch = store.dispatch;
library.add(fab);

// assumed  ?checkUpdate=xxxx
const queryParam = window.location.search.slice(1).split('&')[0].split('=');
if (queryParam[0] === 'checkUpdate' && queryParam[1] === 'true') {
  dispatch(checkUpdate(true));
} else {
  // init data first
  dispatch(initDataRequest());
}

// i18n example
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: enI18n,
      },
      'zh-TW': {
        translation: twI18n,
      },
      'zh-CN': {
        translation: cnI18n,
      },
    },
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      prefix: '{',
      suffix: '}',
      format: (value, format, lng, options) => {
        let date = value;
        if (typeof value === 'number' && isValid(value)) {
          date = new Date(value);
        }
        if (isDate(date)) {
          // @ts-ignore
          const locale = locales[lng] || locales.enUS;

          if (format === 'short') return formatDate(date, 'PP', { locale });
          if (format === 'long') return formatDate(date, 'PPPP', { locale });
          if (format === 'full') return formatDate(date, 'PPPpp', { locale });
          if (format === 'long-month') return formatDate(date, 'LLLL', { locale });
          if (format === 'custom' && locale.code && options?.dateFormat)
            return date.toLocaleDateString(locale.code, options.dateFormat);
          if (format === 'relative') return formatRelative(date, new Date(), { locale });
          if (format === 'ago')
            return formatDistance(date, new Date(), {
              locale,
              addSuffix: true,
            });
          // @ts-ignore
          return toDate(date, format, { locale });
        }

        return value;
      },
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
