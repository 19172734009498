import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import ImageWithTextOverlay from '@kopapro/components/shop/imageWithTextOverlay/imageWithTextOverlay';
import { getDataByComponent } from '@kopapro-redux/selectors/entities/shop';
import { ImageWithTextOverlayContent } from '@kopapro-redux/types/componentSetting';

interface IProps extends WithTranslation {
    compId: string;
    compName: string;
    showIn: string;
}

interface StateProps {
    content?: ImageWithTextOverlayContent;
}
  
interface DispatchProps {}
  
const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
    const { compId, compName } = props;
    return {
      content: getDataByComponent(state, { compId, compName })?.imageWithTextOverlay,
    };
};
  
const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};
export type ImageWithTextOverlayProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ImageWithTextOverlay));
  