import { RootState } from "@kopapro-redux/store";
import { getComponentSetting } from '@kopapro-redux/selectors/entities/shop';
import { I18nDictionary } from '@kopapro-redux/types/utilities';

export const getPolicy = (state: RootState) => {
  let policy: I18nDictionary = {};
  let list = getComponentSetting(state).policy;

  if (list && list.length > 0) {
    return list[0];
  }

  return policy;
};
