import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";

import { RootState } from "@kopapro-redux/store";
import { withTranslation, WithTranslation } from "react-i18next";
import { withParams } from "@kopapro/components/commons/router-hoc";
import { getPaymentMethodByCode } from '@kopapro-redux/selectors/entities/paymentMethods';
import PaymentMethodDetail from '@kopapro/components/paymentMethod/detail/detail';
import { PaymentMethod } from '@kopapro-redux/types/paymentMethod';

interface IProps extends WithTranslation {
  params: any; // from withParams
  item: PaymentMethod;
}

interface StateProps {}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const code = props.params["code"];
  return {
    item: getPaymentMethodByCode(state, code),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type PaymentMethodDetailProps = IProps & StateProps & DispatchProps;
export default withParams(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PaymentMethodDetail)));
