import { ShippingRegionType } from '@kopapro-redux/action-types';
import { ShippingRegionAction, ShippingRegionState, ShippingRegion } from '@kopapro-redux/types/shippingRegion';

const initialState: ShippingRegionState = {
  pending: false,
  data: {},
  error: '',
};

export function handleShippingRegionReceived(nextState: ShippingRegionState, list: ShippingRegion[]) {
  const methods: any = {};
  list.forEach((data) => {
    let temp: any = Object.assign({}, data);
    const m18Id = data.m18Id;
    methods[m18Id] = temp;
  });
  nextState.data = methods;
  return nextState;
}

const shippingRegionReducer = (state = initialState, action: ShippingRegionAction): ShippingRegionState => {
  switch (action.type) {
    case ShippingRegionType.RECEIVED_SHIPPING_REGION:
      return handleShippingRegionReceived({ ...state, pending: false }, action.payload as ShippingRegion[]);

    default:
      return state;
  }
};

export default shippingRegionReducer;