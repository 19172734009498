import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '@kopapro-redux/reducers';
import rootSaga from '@kopapro-redux/sagas';
const sagaMiddleware = createSagaMiddleware();

let store = configureStore({
  reducer: { kopapro: rootReducer },
  middleware: [sagaMiddleware],
});
// export const store = configureStore({
//   reducer: { kopapro: rootReducer },
//   middleware: [sagaMiddleware],
// });
// sagaMiddleware.run(rootSaga);

export const createStore = function (reducer: any, saga: any): any {
  store = configureStore({
    reducer: { kopapro: rootReducer, local: reducer },
    middleware: [sagaMiddleware],
  });
  sagaMiddleware.run(rootSaga);
  if (saga) {
    sagaMiddleware.run(saga);
  }
  return store;
};

export { store };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
