import { CartType } from '@kopapro-redux/action-types';
import { CommonAction } from '@kopapro-redux/actions/misc';
import {
  ModifyItemInCartPayload,
  BatchModifyItemInCartPayload,
  RemoveProductFromCartPayload,
  ModifyItemInCartAction,
  BatchModifyItemInCartAction,
  RemoveProductFromCartAction,
  PromotionAlert,
  MemberTypeAlert,
  SelectGiftRedemptionFromCartAction,
  SelectGiftRedemptionFromCartPayload,
  GiftRedemptionData,
  ApplyRedemptionToCartPayload,
  ApplyRedemptionToCartAction,
  ApplyGiftToCartPayload,
  ApplyGiftToCartAction,
  ModifyItemInCartWithPriceAction,
  ModifyItemInCartWithPricePayload,
  BatchModifyItemInCartAddonPayload,
  batchModifyItemInCartAddOnAction,
  ModifyItemInCartMultipleAction,
  ModifyItemInCartMultiplePayload,
} from '@kopapro-redux/types/cart';

export const getCart = (payload: any, callback?: Function): CommonAction => {
  return {
    type: CartType.GET_CART_REQUEST,
    payload,
    callback,
  };
};

export const modifyItemInCart = (payload: ModifyItemInCartPayload, callback?: Function): ModifyItemInCartAction => {
  return {
    type: CartType.MODIFY_CART_ITEM_REQUEST,
    payload,
    callback,
  };
};

export const modifyItemInCartMultiple = (
  payload: ModifyItemInCartMultiplePayload,
  callback?: Function
): ModifyItemInCartMultipleAction => {
  return {
    type: CartType.MODIFY_CART_ITEM_MULTIPLE_REQUEST,
    payload,
    callback,
  };
};

export const modifyItemInCartWithPrice = (
  payload: ModifyItemInCartWithPricePayload,
  callback?: Function
): ModifyItemInCartWithPriceAction => {
  return {
    type: CartType.MODIFY_CART_ITEM_REQUEST_PRICE,
    payload,
    callback,
  };
};

export const batchModifyItemInCart = (
  payload: BatchModifyItemInCartPayload,
  callback?: Function
): BatchModifyItemInCartAction => {
  return {
    type: CartType.BATCH_MODIFY_CART_ITEM_REQUEST,
    payload,
    callback,
  };
};

export const batchModifyItemInCartAddOn = (
  payload: BatchModifyItemInCartAddonPayload,
  callback?: Function
): batchModifyItemInCartAddOnAction => {
  return {
    type: CartType.BATCH_MODIFY_CART_ITEM_REQUEST_ADD_ON,
    payload,
    callback,
  };
};

export const removeProductFromCart = (
  payload: RemoveProductFromCartPayload,
  callback?: Function
): RemoveProductFromCartAction => {
  return {
    type: CartType.REMOVE_PRODUCT_FROM_CART_REQUEST,
    payload,
    callback,
  };
};

export const selectGiftRedemptionFromCart = (
  payload: SelectGiftRedemptionFromCartPayload,
  callback?: Function
): SelectGiftRedemptionFromCartAction => {
  return {
    type: CartType.SELECT_GIFT_REDEMPTION_FROM_CART_REQUEST,
    payload,
    callback,
  };
};

export const applyRedemptionToCart = (
  payload: ApplyRedemptionToCartPayload,
  callback?: Function
): ApplyRedemptionToCartAction => {
  return {
    type: CartType.APPLY_REDEMPTION_TO_CART,
    payload,
    callback,
  };
};

export const applyGiftToCart = (payload: ApplyGiftToCartPayload, callback?: Function): ApplyGiftToCartAction => {
  return {
    type: CartType.APPLY_GIFT_TO_CART,
    payload,
    callback,
  };
};


export const checkCart = (payload: any, callback?: Function): CommonAction => {
  return {
    type: CartType.CHECK_CART_ITEM_REQUEST,
    payload,
    callback,
  };
};

export const loadCartInformation = (callback?: Function): CommonAction => {
  return {
    type: CartType.LOAD_CART_INFORMATION,
    callback,
  };
};

// from server
export const cartUpdated = (payload: any, callback?: Function): CommonAction => {
  return {
    type: CartType.CART_UPDATED,
    payload,
    callback,
  };
};

export const cartRemoved = (): CommonAction => {
  return {
    type: CartType.CART_REMOVED,
  };
};

export const requestingCart = (): CommonAction => {
  return {
    type: CartType.SET_REQUESTING_CART,
  };
};

export const promotionAlertUpdated = (payload: { alert?: PromotionAlert }, callback?: Function): CommonAction => {
  return {
    type: CartType.PROMOTION_ALERT_UPDATED,
    payload,
    callback,
  };
};

export const memberTypeAlertUpdated = (payload: { alert?: MemberTypeAlert }, callback?: Function): CommonAction => {
  return {
    type: CartType.MEMBER_TYPE_ALERT_UPDATED,
    payload,
    callback,
  };
};

export const redemptionListUpdated = (payload: { list?: GiftRedemptionData[] }, callback?: Function): CommonAction => {
  return {
    type: CartType.REDEMPTION_LIST_UPDATED,
    payload,
    callback,
  };
};

export const giftListUpdated = (payload: { list?: GiftRedemptionData[] }, callback?: Function): CommonAction => {
  return {
    type: CartType.GIFT_LIST_UPDATED,
    payload,
    callback,
  };
};