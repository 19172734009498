import React, { Component, ReactNode } from 'react';
import { BlogPageProps } from '@kopapro/components/blog/page/index';
import BlogList from '@kopapro/components/blog/list';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb';
import { geti18nValue } from '@kopapro/utils/m18';
import { BlogSortBy } from '@kopapro/utils/constants/constants';
import { Form, Col } from 'react-bootstrap';

interface BlogPageState {
  sortBy: string;
}

export default class Blogs extends Component<BlogPageProps, BlogPageState> {
  defaultState = {
    sortBy: 'recommand',
  };

  constructor(props: BlogPageProps) {
    super(props);
    this.state = this.defaultState;
  }

  onSortByChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const newValue = e.currentTarget.value;
    this.setState({ ...this.state, sortBy: newValue });
  };

  renderNav = (): ReactNode => {
    const { t, blogCategory } = this.props;

    const items: any[] = [{ text: t('ce01_pmpcore.react.home'), to: '/', active: false }];

    if (blogCategory) {
      const { desc } = blogCategory!;
      items.push({ text: geti18nValue(desc), active: true });
    }

    return <KppBreadcrumb items={items} />;
  };

  renderTop = (): ReactNode => {
    return this.renderNav();
  };

  renderSorting = () => {
    const { t } = this.props;
    return (
      <div className="row mb-3">
        <Form.Group as={Col} xs lg={4} className="d-lg-block" controlId="sortBy">
          {/* <Form.Label>{t('ce01_pmpcore.react.proSortBy')}</Form.Label> */}
          <Form.Select className="kopapro-select" onChange={(e) => this.onSortByChange(e)}>
            {Object.values(BlogSortBy).map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {t(item.mess)}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </div>
    );
  };

  render() {
    const { blogCategory } = this.props;
    const categoryId = blogCategory?.m18Id;

    return (
      <div className={`blog-page-container page-container container px-xl-5`}>
        <ScrollToTopOnMount />
        {this.renderTop()}
        {this.renderSorting()}
        <BlogList
          categoryId={categoryId}
          showAuthor={true}
          showDate={true}
          showHashTags={true}
          showYearMonth={blogCategory?.showYearMonth}
          // query
          sortBy={this.state.sortBy}
        />
      </div>
    );
  }
}
