import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';

import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import { Col, Row } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import NavigationList from '@kopapro/components/pages/account/navigationList';
import Block from '@kopapro/components/pages/account/block';

interface IProps {}

interface DispatchProps {}

interface StateProps {
  userLoggedIn: boolean;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    userLoggedIn: userLoggedIn(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};
type AccountProp = IProps & StateProps & DispatchProps;

const accountHOC = (WrappedComponent: any, viewName?: string) => {
  let compId = viewName || '';
  const AccountHOC = (props: AccountProp) => {
    if (!props.userLoggedIn) {
      return <Navigate to="/login" />;
    }
    return (
      <div className="page-container my-account container-xl my-5">
        <ScrollToTopOnMount />
        <Row>
          <Col className="mt-5" sm={4} md={3}>
            <NavigationList />
          </Col>
          <Col className="mt-5" sm={8} md={9}>
            <Block compId={compId} isHeader={true} />
            <WrappedComponent {...props} />
            <Block compId={compId} isHeader={false} />
          </Col>
        </Row>
      </div>
    );
  };

  return connect(mapStateToProps, mapDispatchToProps)(AccountHOC);
};

export default accountHOC;
