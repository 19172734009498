import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import Ledger from '@kopapro/components/pages/account/coupons/ledger/ledger';

import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { withParams } from '@kopapro/components/commons/router-hoc';
import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';
import { getMemberCenterViewById } from '@kopapro-redux/selectors/entities/member';
import { HomeView } from '@kopapro/utils/constants/constants';
import { I18nDictionary } from '@kopapro-redux/types/utilities';
import withAccount from '@kopapro/components/pages/account/accountHoc';
import { loadMemberCouponVoucherLedger } from '@kopapro-redux/actions/member';
import { getCurrencies } from '@kopapro-redux/selectors/entities/currency';
import { ShopCurrencySetup } from '@kopapro-redux/types/shop';

interface IProps extends WithTranslation {
  navigate: any;
  params: any; // from withParams
}

interface DispatchProps {
  loadLedger: typeof loadMemberCouponVoucherLedger;
}

interface StateProps {
  userLoggedIn: boolean;
  currencies?: ShopCurrencySetup[];
  viewId: string | number;
  viewName: I18nDictionary;
  svId: number;
  svfId: number;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const compId = HomeView.MyPrepaidCoupon;
  const content = getMemberCenterViewById(state, compId);
  const svId = props.params['svId'] || 0;
  const svfId = props.params['svfId'] || 0;
  return {
    userLoggedIn: userLoggedIn(state),
    currencies: getCurrencies(state),
    viewId: content?.view.viewId || '',
    viewName: content?.view.viewName || { en: '' },
    svId,
    svfId,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadLedger: loadMemberCouponVoucherLedger,
};

export type LedgerProps = IProps & StateProps & DispatchProps;
export default withParams(
  withNavigation(withAccount(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Ledger))))
);
