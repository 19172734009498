import keyMirror from '@kopapro-redux/utils/key-mirror';

// misc action type
export default keyMirror({
  ADD_DISCOUNT_CODES_IN_CHECK_OUT: null,
  ADD_MEMBER_IN_CHECK_OUT: null,
  ADD_DELIVERY_IN_CHECK_OUT: null,
  CHECK_DELIVERY_CHARGE_IN_CHECK_OUT: null,
  ADD_PAYMENT_IN_CHECK_OUT: null,
  CHECK_PAYMENT_SURCHARGE_IN_CHECK_OUT: null,
  LOAD_COUPON_IN_CHECK_OUT: null,
  APPLY_COUPON_IN_CHECK_OUT: null,
  SAVE_EXTRA_INFO_IN_CHECK_OUT: null,
  LOAD_EXTRA_INFO_IN_CHECK_OUT: null,
  CONFIRM_ORDER_IN_CHECK_OUT: null,
});
