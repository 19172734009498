import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import Summary from '@kopapro/components/checkout/summary/summary';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { confirmOrder } from '@kopapro-redux/actions/checkout';
import { getOrder } from '@kopapro-redux/actions/order';
import { CheckoutMember, ConfirmOrderParam } from '@kopapro-redux/types/checkout';
import { ShopOrderSetup } from '@kopapro-redux/types/shop';
import { getShopOrderSetup } from '@kopapro-redux/selectors/entities/shop';
import { getCartMember } from '@kopapro-redux/selectors/entities/cart';
import { UserInfo } from '@kopapro-redux/types/user';
import { getCurrentUserInfo, userLoggedIn } from '@kopapro-redux/selectors/entities/user';

interface IProps extends WithTranslation {
  navigate: any;
  orderId: string;
  confirmParam: ConfirmOrderParam;
  updateConfirmParam: Function;
  componentPrevious: Function;
  componentNext: Function;
}

interface StateProps {
  orderSetup: ShopOrderSetup;
  cartMember?: CheckoutMember;
  userLoggedIn: boolean;
  userInfo: UserInfo;
}

interface DispatchProps {
  loadOrder: typeof getOrder;
  confirmOrder: typeof confirmOrder;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    orderSetup: getShopOrderSetup(state),
    cartMember: getCartMember(state),
    userLoggedIn: userLoggedIn(state),
    userInfo: getCurrentUserInfo(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadOrder: getOrder,
  confirmOrder: confirmOrder,
};

export type SummaryProps = IProps & StateProps & DispatchProps;
export default withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Summary)));
