import productList from '@kopapro-original/components/products/productList/productList';
import { productListConfig as config } from '@kopapro/utils/config';
import FilterMenu from '@kopapro/components/products/productList/filters';
import CakeOptionFilter from '@kopapro/components/products/productList/cakeOptionFilter';
import KopaproApi from '@kopapro-redux/api';
import ProductItem from '@kopapro/components/products/item';
import classnames from 'classnames';
import React, { Component, Fragment } from 'react';
import { QueryList } from '@kopapro-redux/types/general';
import { Product } from '@kopapro-redux/types/products';
import Accordion from 'react-bootstrap/Accordion';
import ScrollToTopOnMount from '@kopapro/template/ScrollToTopOnMount';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import utils from '@kopapro/utils/utils';
import Card from 'react-bootstrap/Card';

class CustomProductList extends productList {
  onCakeOptionChange = async (id: number, type: string) => {
    const { flavorId, weightId } = this.state;

    if (type === 'flavor') {
      const classArgs = { flavorId: id, weightId: weightId };
      const res = await KopaproApi.callServlet('0', 'KpptwaServletExtension', 'getExcludeProduct', classArgs);

      this.setState({ flavorId: id, excludeProduct: res.resultArr });
    } else {
      const classArgs = { flavorId: flavorId, weightId: id };
      const res = await KopaproApi.callServlet('0', 'KpptwaServletExtension', 'getExcludeProduct', classArgs);

      this.setState({ weightId: id, excludeProduct: res.resultArr });
    }

    this.onPageChange(0);
    this.loadProducts();
  };

  renderItems = () => {
    let { lite, column, row } = this.props!;
    const { items: products, excludeProduct } = this.state;
    let liteStyle = this.getDynamicRow();

    let list = products.map((product, i) => {
      return <ProductItem key={product.code} code={product.code} imageHeight="unset" />;
    });

    if (lite && column && row) {
      const col = column ? column : 2; // prevent typescript checking
      list = Array.from(Array(row)).map((_, i) => {
        const productsPerRow = products.slice(i * col, col * (i + 1));
        if (productsPerRow.length === 0) {
          return <Fragment key={i}></Fragment>;
        }
        return (
          <div
            key={i}
            className={classnames('row g-3 mb-4 flex-shrink-0 justify-content-center', { [liteStyle]: lite })}>
            {productsPerRow.map((product, i) => {
              return <ProductItem key={product.code} code={product.code} imageHeight="unset" />;
            })}
          </div>
        );
      });
      return <div ref={this.listRef}>{list}</div>;
    }

    const cols = config.showFilter ? 3 : 4;
    const colsClass = `row-cols-2 row-cols-md-${cols}`;

    return (
      <div
        ref={this.listRef}
        className={classnames('row g-3 mb-4 flex-shrink-0', {
          [colsClass]: !lite,
          [liteStyle]: lite,
        })}>
        {list}
      </div>
    );
  };

  renderLeft = () => {
    const { categoryCode, t } = this.props;
    if (!config.showFilter) {
      return null;
    }

    const flavorStr = t('ce01_kpptwa.cakeFlavor');
    const weightStr = t('ce01_kpptwa.cakeWeight');

    return (
      <div className="d-none d-lg-block col-lg-3">
        <div className="border shadow-sm">
          <FilterMenu
            categoryCode={categoryCode}
            onFilterApplied={this.onFilterApplied}
            hideCategory={this.isSearchResultPage()}
          />
        </div>
        <CakeOptionFilter
          title={flavorStr}
          type={'flavor'}
          onCakeOptionChange={this.onCakeOptionChange}></CakeOptionFilter>
        <CakeOptionFilter
          title={weightStr}
          type={'weight'}
          onCakeOptionChange={this.onCakeOptionChange}></CakeOptionFilter>
      </div>
    );
  };

  loadProducts = (newState = {}, callback?: Function) => {
    const { keyword, navigate } = this.props;

    const query = {
      categoryId: this.props.categoryId,
      code: this.props.categoryCode,
      pageSize: 20000,
      sortBy: this.state.sortBy,
      page: 0,
      keyword: this.isSearchResultPage() ? keyword : '',
    };

    const batchCallback = async (data: QueryList<Product> | Error, errorParams: any) => {
      this.fetching = false;
      if (data instanceof Error) {
        if (errorParams.redirect) {
          navigate('/categories');
        }
        return;
      }

      // await this.getNotDisplayList();
      this.onFetchHandler(data as QueryList<Product>);
      if (callback) {
        callback();
      }
      this.fetching = false;
    };
    this.fetching = true;
    this.props.loadProducts({ ...query, ...newState }, batchCallback);
  };

  /*
  getNotDisplayList = async () => {
    const portalUrl = KopaproApi.getPortalUrl();
    const classArgs = { portalUrl: portalUrl };
    const res = await KopaproApi.callServlet('0', 'KpptwaServletExtension', 'getNotDisplayList', classArgs);

    if (res) {
      this.setState({ notDisplayList: res.resultArr });
    }
  };
  */
  onFetchHandler = (result: QueryList<Product>) => {
    const { page, items, pageSize, excludeProduct, notDisplayList } = this.state;
    const { data } = result;

    let filteredData = data.filter((product) => !excludeProduct.includes(product.m18ProGpId));
    // filteredData = filteredData.filter((product) => !notDisplayList.includes(product.m18ProGpId));

    const filteredDataWithPage = filteredData.slice(page * pageSize, (page + 1) * pageSize);
    const filteredResult: QueryList<Product> = {
      queryId: result.queryId,
      querySize: filteredData.length,
      data: filteredDataWithPage,
    };

    if (this._isMounted) {
      if (!this.usePagination && page !== 0) {
        this.setState({ query: result, items: [...items, ...data] });
      } else {
        this.setState({ query: filteredResult, items: filteredDataWithPage });
      }
    }
  };

  onPageChange = (newPage: number) => {
    this.setState({ ...this.state, page: newPage });
    const { listRef, usePagination } = this;
    this.loadProducts({}, function () {
      if (usePagination) {
        window.scrollTo(0, listRef.current.offsetTop - 50);
      }
    });
  };

  render() {
    const { t, categoryCode, lite, category } = this.props;
    const { items: products } = this.state;
    const showFilter = config.showFilter;
    let items = this.renderItems();
    const flavorStr = t('ce01_kpptwa.cakeFlavor');
    const weightStr = t('ce01_kpptwa.cakeWeight');

    if (lite) {
      return <div className="product-list-container lite">{items}</div>;
    }

    if (!category) {
      return null;
    }
    return (
      <div className="product-list-container page-container container px-xl-5">
        <ScrollToTopOnMount />
        {this.renderTop()}
        {/* Filter Menu Top for mobile / tablet device */}

        {showFilter && (
          <div className="row mb-3 d-block d-lg-none">
            <div className="col-12">
              <Accordion defaultActiveKey="">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{t('ce01_pmpcore.react.filter')}</Accordion.Header>
                  <Accordion.Body>
                    <FilterMenu
                      categoryCode={categoryCode}
                      onFilterApplied={this.onFilterApplied}
                      hideCategory={this.isSearchResultPage()}
                      isMobile={true}
                    />
                    <CakeOptionFilter
                      title={flavorStr}
                      type={'flavor'}
                      onCakeOptionChange={this.onCakeOptionChange}></CakeOptionFilter>
                    <CakeOptionFilter
                      title={weightStr}
                      type={'weight'}
                      onCakeOptionChange={this.onCakeOptionChange}></CakeOptionFilter>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        )}
        <div className="product-list row">
          {/* Filter Menu Left */}
          {this.renderLeft()}

          <div className={classnames({ 'col-lg-9': showFilter, 'col-lg-12': !showFilter })}>
            <div className="d-flex flex-column h-100">
              <SafetyDOM html={geti18nValue(category.header)} />
              {this.renderSorting()}
              <SafetyDOM html={geti18nValue(category.eshopDesc)} />
              {!this.fetching && utils.isEmptyList(products) && <Card body> {t(this.getEmptyMessage())}</Card>}
              {items}
              {/* pagination */}
              {this.renderPagination()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomProductList;
