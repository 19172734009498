import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import KopaproApi from '@kopapro-redux/api';
import { BlogType } from '@kopapro-redux/action-types';
import { fetchBlogCategoriesSuccess, fetchBlogDetailSuccess, fetchBlogListSuccess } from '@kopapro-redux/actions/blog';
import { QueryList } from '@kopapro-redux/types/general';
import { BlogCategory, BlogDetail, GetBlogListPayload } from '@kopapro-redux/types/blog';
import utils from '@kopapro-redux/utils/utils';
import { CommonAction } from '@kopapro-redux/actions/misc';

function* getBlogDetailSaga(action: CommonAction) {
  const { payload, callback } = action;
  const resp: QueryList<BlogDetail> = yield call(KopaproApi.getBlogDetail, payload.blogId as string);
  let blogDetail: any = undefined;

  if (resp.querySize === 1 && resp.data) {
    blogDetail = resp.data[0];
  }

  if (!utils.isUndefined(blogDetail)) {
    yield put(fetchBlogDetailSuccess(blogDetail));
    if (callback) {
      callback(blogDetail);
    }
  }
}

function* getBlogListSaga(action: CommonAction) {
  const { payload, callback } = action;
  const resp: QueryList<BlogDetail> = yield call(KopaproApi.getBlogList, payload);
  if (resp.data) {
    yield put(fetchBlogListSuccess(resp.data, payload as GetBlogListPayload));
    if (callback) {
      callback(resp.data, resp.querySize);
    }
  }
}

export function* getBlogCategoriesSaga(action?: CommonAction) {
  const resp: QueryList<BlogCategory> = yield call(KopaproApi.getBlogCategories);
  if (resp.data) {
    yield put(fetchBlogCategoriesSuccess(resp.data));
    if (action && action.callback) {
      action.callback(resp.data);
    }
  }
}

function* blogSaga() {
  yield all([
    takeEvery(BlogType.GET_BLOG_DETAIL_REQUEST, getBlogDetailSaga),
    takeEvery(BlogType.GET_BLOG_LIST_REQUEST, getBlogListSaga),
    takeLatest(BlogType.GET_BLOG_CATEGORIES_REQUEST, getBlogCategoriesSaga),
  ]);
}

export default blogSaga;
