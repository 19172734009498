import { UserType } from "@kopapro-redux/action-types";
import { UserInfo, UserState } from '@kopapro-redux/types/user';
import { getStorageItem, removeStorageItem, setStorageItem } from '@kopapro-redux/utils/cookies';
import { triggerM18Action } from '@kopapro-redux/utils/m18';
import utils from '@kopapro-redux/utils/utils';

export const defaultUserInfo: UserInfo = {
  uid: 0,
  code: '',
  module: '',
  name: {},
  mobile: '',
  mobileCountry: '',
  mobileNumber: '',
  logo: '',
  email: '',
  disableSchedule: false,
  memId: 0,
  memTypeId: 0,
};

const initialState: UserState = {
  lang: 'en',
  currency: 'HKD',
  userInfo: defaultUserInfo,
  sessionId: '',
};

function setLang(nextState: UserState, lang: string, saveInCookie = true) {
  // check if cookie not exists
  const selectedLang = getStorageItem('lang');
  if (saveInCookie && selectedLang !== lang) {
    setStorageItem('lang', lang);
  }

  triggerM18Action(`portalChangeLanguage_${lang}`);

  if (nextState.lang === lang) {
    return { ...nextState };
  }

  return { ...nextState, lang };
}

function setCurrency(nextState: UserState, currency: string, saveInCookie = true) {
  // check if cookie not exists
  const selectedCurrency = getStorageItem('currency');
  if (saveInCookie && selectedCurrency !== currency) {
    setStorageItem('currency', currency);
  }
  if (nextState.currency === currency) {
    return nextState;
  }

  return { ...nextState, currency };
}

function setUserInfo(nextState: UserState, userInfo: UserInfo, saveInCookie = true) {
  if (nextState.userInfo === userInfo) {
    return nextState;
  }

  return { ...nextState, userInfo };
}

function setSessionId(nextState: UserState, sessionId: string, userInfo: UserInfo, saveInCookie = true) {
  // check if cookie not exists
  const selectedSessionId = getStorageItem('sessionId');
  if (saveInCookie && selectedSessionId !== sessionId) {
    if (utils.isNotEmpty(sessionId)) {
      setStorageItem('sessionId', sessionId);
    } else {
      removeStorageItem('sessionId');
    }
  }
  if (nextState.sessionId === sessionId) {
    return nextState;
  }

  return { ...nextState, sessionId, userInfo };
}

const userReducers = (state = initialState, action: any): UserState => {
  switch (action.type) {
    case UserType.SET_DISPLAY_LANGUAGE:
      return setLang(state, action.lang, action.saveInCookie);
    case UserType.SET_CURRENCY:
      return setCurrency(state, action.currency, action.saveInCookie);
    case UserType.SET_USERINFO:
      return setUserInfo(state, action.userInfo, action.saveInCookie);
    case UserType.SET_SESSIONID:
      return setSessionId(state, action.sessionId, action.userInfo, action.saveInCookie);

    default:
      return {
        ...state,
      };
  }
};

export default userReducers;
