import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import Delivery from '@kopapro/components/order/delivery/delivery';
import { getOrderDelivery } from '@kopapro-redux/selectors/entities/order';
import { OrderDelivery } from '@kopapro-redux/types/order';

interface IProps extends WithTranslation {}

interface StateProps {
  delivery?: OrderDelivery;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    delivery: getOrderDelivery(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type DeliveryProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Delivery));
