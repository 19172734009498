import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { getBlogDetailRequest } from '@kopapro-redux/actions/blog';
import BlogDetail from '@kopapro/components/blog/detail/detail';
import { withNavigation, withParams, withLocation } from '@kopapro/components/commons/router-hoc';
import { userLoggedIn } from '@kopapro-redux/selectors/entities/user';
import { CodeMappedObjects } from '@kopapro-redux/types/utilities';
import { BlogCategory } from '@kopapro-redux/types/blog';
import { getBlogCategories } from '@kopapro-redux/selectors/entities/blog';

interface IProps extends WithTranslation {
  params: any; // withParam
  navigate: any; // withNavigation
  location: any;
}

interface StateProps {
  userLoggedIn: boolean;
  blogId: number;
  blogCategories?: CodeMappedObjects<BlogCategory>;
}

interface DispatchProps {
  loadBlogDetail: typeof getBlogDetailRequest;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const blogId: number = props.params['blogId'];
  return {
    userLoggedIn: userLoggedIn(state),
    blogId: blogId,
    blogCategories: getBlogCategories(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  loadBlogDetail: getBlogDetailRequest,
};

export type BlogDetailProps = IProps & StateProps & DispatchProps;
export default withLocation(
  withNavigation(withParams(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BlogDetail))))
);
