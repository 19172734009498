import { all, call, put, takeLatest } from "redux-saga/effects";

import { PaymentMethodType } from "@kopapro-redux/action-types";

import KopaproApi from "@kopapro-redux/api";
import { QueryList } from "@kopapro-redux/types/general";
import { PaymentMethod } from '@kopapro-redux/types/paymentMethod';
import { fetchPaymentMethodsSuccess } from '@kopapro-redux/actions/paymentMethods';

export function* getPaymentMethodsSaga() {
  try {
    const resp: QueryList<PaymentMethod> = yield call(KopaproApi.getPaymentMethods);

    yield put(fetchPaymentMethodsSuccess(resp.data));
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
      // show error message or ignore?
    }
  }
}

function* paymentMethodsSaga() {
  yield all([takeLatest(PaymentMethodType.GET_PAYMENTMETHODS_REQUEST, getPaymentMethodsSaga)]);
}

export default paymentMethodsSaga;
