import utils from '@kopapro/utils/utils';

import { shareButtonsConfig as config } from '@kopapro/utils/config';

import {
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

interface Props {
  url: string;
  title?: string;
}

function ShareButtons(props: Props) {
  const { url, title } = props;
  const { buttonProps, iconProps } = config;
  const { enableWhatsapp, enableFacebook, enableLine, enableTelegram, enableTwitter } = config;

  if (utils.isEmpty(url)) {
    return null;
  }
  return (
    <div className="my-1">
      {enableFacebook && (
        <FacebookShareButton {...buttonProps} url={url} quote={title} children={<FacebookIcon {...iconProps} />} />
      )}
      {enableLine && (
        <LineShareButton {...buttonProps} url={url} title={title} children={<LineIcon {...iconProps} />} />
      )}
      {enableTelegram && (
        <TelegramShareButton {...buttonProps} url={url} title={title} children={<TelegramIcon {...iconProps} />} />
      )}
      {enableTwitter && (
        <TwitterShareButton {...buttonProps} url={url} title={title} children={<TwitterIcon {...iconProps} />} />
      )}
      {enableWhatsapp && (
        <WhatsappShareButton {...buttonProps} url={url} title={title} children={<WhatsappIcon {...iconProps} />} />
      )}
    </div>
  );
}
export default ShareButtons;
