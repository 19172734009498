import { RootState } from '@kopapro-redux/store';
import { getComponentSetting, getShopLoginSetup } from '@kopapro-redux/selectors/entities/shop';
import { LoginViewContent } from '@kopapro-redux/types/componentSetting';
import { defaultViewContent, getInputFormatInView, getInputFormatListInView } from '@kopapro-redux/utils/m18View';
import utils from '@kopapro-redux/utils/utils';
import { ShopLoginSetup } from '@kopapro-redux/types/shop';
import { createSelector } from 'reselect';

export const getLoginView = (state: RootState) => {
  const loginView: LoginViewContent = defaultViewContent;
  const loginViewList = getComponentSetting(state).loginview;

  if (loginViewList && utils.isNotEmptyList(loginViewList)) {
    return loginViewList[0];
  }
  return loginView;
};

export const getLoginInputFormat = createSelector([getLoginView], (loginView) => {
  return getInputFormatInView(loginView);
});

export const getLoginInputFormatList = createSelector([getLoginView], (loginView) => {
  return getInputFormatListInView(loginView);
});

export const getLoginSetupLogo = (state: RootState) => {
  const loginInfo: ShopLoginSetup = getShopLoginSetup(state);
  let photoCode: string = '';
  if (loginInfo) {
    photoCode = loginInfo.promotionLogo;
  }
  return photoCode;
};

export const isLoginSetupUseCaptcha = (state: RootState) => {
  const loginInfo: ShopLoginSetup = getShopLoginSetup(state);
  let useCaptcha: boolean = false;
  if (loginInfo) {
    useCaptcha = loginInfo.useCaptcha;
  }
  return useCaptcha;
};

export const getLoginSetupCaptchaType = (state: RootState) => {
  const loginInfo: ShopLoginSetup = getShopLoginSetup(state);
  let captchaType: string = '';
  if (loginInfo) {
    captchaType = loginInfo.captchaType;
  }
  return captchaType;
};