import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ShippingRegionType } from '@kopapro-redux/action-types';

import KopaproApi from '@kopapro-redux/api';
import { QueryList } from '@kopapro-redux/types/general';
import { ShippingRegion } from '@kopapro-redux/types/shippingRegion';
import { fetchShippingRegionSuccess } from '@kopapro-redux/actions/shippingRegion';

export function* getShippingRegionSaga() {
  try {
    const resp: QueryList<ShippingRegion> = yield call(KopaproApi.getShippingRegion);

    yield put(fetchShippingRegionSuccess(resp.data));
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
      // show error message or ignore?
    }
  }
}

function* shippingRegionSaga() {
  yield all([takeLatest(ShippingRegionType.GET_SHIPPING_REGION_REQUEST, getShippingRegionSaga)]);
}

export default shippingRegionSaga;
