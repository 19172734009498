import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import ForgetPassword from '@kopapro/components/pages/login/forgetPassword/forgetPassword';
import { forgotPassword } from '@kopapro-redux/actions/user';

interface IProps extends WithTranslation {}

interface DispatchProps {
  submitEmail: typeof forgotPassword;
}

interface StateProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  submitEmail: forgotPassword,
};

export type ForgetPasswordProps = IProps & StateProps & DispatchProps;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ForgetPassword));
