import { Component, ReactNode } from 'react';
import { PickUpProps } from '@kopapro/components/checkout/delivery/pickup';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import Select from 'react-select';
import { ShippingRegionOption } from '@kopapro-redux/types/shippingRegion';
import { PickupAddress } from '@kopapro-redux/types/deliveryMethod';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import utils from '@kopapro-redux/utils/utils';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import { geti18nValue } from '@kopapro-redux/utils/m18';

interface State {
  selectedRegion: ShippingRegionOption | null;
  selectedCode: string;
}

export default class PickUp extends Component<PickUpProps, State> {
  defaultState = {
    selectedRegion: null,
    selectedCode: '',
  };

  constructor(props: PickUpProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount(): void {
    this.presetRegion();
  }

  componentDidUpdate(prevProps: Readonly<PickUpProps>, prevState: Readonly<State>): void {
    if (prevProps.addressList !== this.props.addressList) {
      this.setState({ ...this.defaultState });
      this.presetRegion();
    }
    // selected / changed region
    const { selectedRegion } = this.state;
    if (prevState.selectedRegion != selectedRegion && selectedRegion !== null) {
      if (selectedRegion.detail.shopId) {
        this.setDefaultShop(selectedRegion.detail.shopId);
      } else {
        this.props.selectHandler(null);
      }
    }
  }

  presetRegion = () => {
    const { shippingRegionOptions, addressList, selectedCode } = this.props;
    const selectedAddress = addressList.find((address) => `${address.shipCode}` === selectedCode);
    if (selectedAddress) {
      const preSelectRegion = shippingRegionOptions.find(
        (option) => option.value === `${selectedAddress.shipRegionId}`
      );
      if (preSelectRegion) {
        this.setState({
          selectedRegion: preSelectRegion,
        });
      }
    }
  };

  selectedShippingRegion = (option: ShippingRegionOption | null | undefined) => {
    this.setState({
      selectedRegion: option || null,
    });
  };

  // local state filter only
  renderShippingRegionList = () => {
    const { t, addressList, shippingRegionOptions, sending } = this.props;
    const { selectedRegion } = this.state;
    const regionIds = addressList.map((address) => `${address.shipRegionId}`);

    const filtedOptions = shippingRegionOptions.filter((option) => regionIds.includes(option.value));

    return (
      <Row>
        <Form.Group className="mb-3">
          <Form.Label className="delivery-input-label" required={true}>
            {t('ce01_pmpcore.kopapro.react.region')}
          </Form.Label>
          <Select<ShippingRegionOption>
            placeholder={t('ce01_pmpcore.react.select')}
            className="react-select-container"
            classNamePrefix="select"
            value={selectedRegion}
            isSearchable={true}
            name="shipping-region"
            options={filtedOptions}
            isDisabled={sending}
            onChange={this.selectedShippingRegion}
            noOptionsMessage={({ inputValue }) => t('ce01_pmpcore.react.noOptions')}
          />
        </Form.Group>
      </Row>
    );
  };

  getAddressText = (item: PickupAddress) => {
    const { shipAd1, shipAd2, shipAd3, shipAd4 } = item;
    const { shopId, shipAd1Obj, shipAd2Obj, shipAd3Obj, shipAd4Obj } = item;

    let ad1: string = shipAd1;
    let ad2: string = shipAd2;
    let ad3: string = shipAd3;
    let ad4: string = shipAd4;

    if (shopId > 0) {
      ad1 = geti18nValue(shipAd1Obj);
      ad2 = geti18nValue(shipAd2Obj);
      ad3 = geti18nValue(shipAd3Obj);
      ad4 = geti18nValue(shipAd4Obj);
    }

    let list = [ad1, ad2, ad3, ad4].filter((ad) => ad.trim().length > 0);
    return `${list.join(', ')}`;
  };

  selectPickupAddress = (address: PickupAddress) => {
    this.props.selectHandler(address);
  };

  setDefaultShop = (shopId: number) => {
    const { addressList } = this.props;
    const address = addressList.find((address) => address.shopId === shopId);
    if (address) {
      this.selectPickupAddress(address);
    }
  };

  renderPickUpOptions = () => {
    const { selectedCode, addressList, sending } = this.props;

    const { selectedRegion } = this.state;
    if (!selectedRegion || utils.isEmpty(selectedRegion.value)) {
      return null;
    }
    const options = addressList.filter((address) => `${address.shipRegionId}` === selectedRegion.value);

    return (
      <ListGroup className="flex-fill pickup-address flex-wrap">
        {options.map((item) => {
          const { shipCode } = item;
          const isSelected = shipCode === selectedCode;
          return (
            <ListGroup.Item
              key={`pickup-address-item-${item.shipCode}`}
              className="pickup-address-item d-flex bg-light"
              onClick={() => this.selectPickupAddress(item)}
              disabled={sending}>
              <span className="align-self-center me-2">
                {isSelected && <CheckBoxIcon className="text-success" />}
                {!isSelected && <CheckBoxOutlineBlankIcon />}
              </span>
              <span>{this.getAddressText(item)}</span>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  renderRemarks = () => {
    const { selectedCode, addressList } = this.props;
    const selectedAddress = addressList.find((address) => `${address.shipCode}` === selectedCode);
    if (selectedAddress) {
      return <SafetyDOM html={selectedAddress.remarks} />;
    }

    return null;
  };

  render(): ReactNode {
    return (
      <div className="pickup mb-3">
        {this.renderShippingRegionList()}
        {this.renderPickUpOptions()}
        {this.renderRemarks()}
      </div>
    );
  }
}
