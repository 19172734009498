import { DeliveryMethodType } from "@kopapro-redux/action-types";
import { DeliveryMethod } from "@kopapro-redux/types/deliveryMethod";

export const fetchDeliveryMethodsSuccess = (payload: DeliveryMethod[]) => ({
  type: DeliveryMethodType.RECEIVED_DELIVERYMETHODS,
  payload,
});

export const getDeliveryMethods = () => ({
  type: DeliveryMethodType.GET_DELIVERYMETHODS_REQUEST,
});
