import { all, put, select, takeLatest } from 'redux-saga/effects';

import { MiscType } from '@app-redux/action-types';
import { isShowDemoMessage } from '@app-redux/selectors/app';

function* triggerDemoSaga() {
  try {
    const isShow: boolean = yield select(isShowDemoMessage);
    if (isShow) {
      yield put({ type: MiscType.HIDR_DEMO_MESSAGE });
    } else {
      yield put({ type: MiscType.SHOW_DEMO_MESSAGE });
    }
    // yield put(endDemo());
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function* miscSaga() {
  yield all([takeLatest(MiscType.TRIGGER_DEMO_ACTION, triggerDemoSaga)]);
}

export default miscSaga;
