import React, { Component, Fragment } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { CakeOptionProps } from '@kopapro/components/products/productList/cakeOptionFilter';
import KopaproApi from '@kopapro-redux/api';
import { CakeOption } from '@kopapro-redux/types/other';
import item from '@kopapro/components/products/item';
import { geti18nValue } from '@kopapro-redux/utils/m18';

interface CakeOptionState {
  cakeOptionObj: Array<CakeOption> | undefined;
}

class CakeOptionFilter extends Component<CakeOptionProps, CakeOptionState> {
  defaultState = {
    cakeOptionObj: [],
  };

  constructor(props: CakeOptionProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount() {
    this.initData();
  }

  initData = async () => {
    const portalUrl = KopaproApi.getPortalUrl();
    const { type } = this.props;

    const classArgs = { type: type, portalUrl: portalUrl };
    const res = await KopaproApi.callServlet('0', 'KpptwaServletExtension', 'getCakeOption', classArgs);

    this.setState({
      cakeOptionObj: res.resultArr,
    });
  };

  onOptionChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const { type } = this.props;
    const value = e.currentTarget.value;

    this.props.onCakeOptionChange(value, type);
  };

  render() {
    const { title } = this.props;
    const { cakeOptionObj } = this.state;

    if (cakeOptionObj && cakeOptionObj.length > 0) {
      return (
        <div className="border shadow-sm" style={{ marginTop: 10 }}>
          <div>
            <div className="option-title">
              <p className="option-title-text">{title}</p>
            </div>
            <Form.Group as={Col} className="d-lg-block" style={{ margin: 10 }}>
              <Form.Select
                className="kopapro-select-option"
                defaultValue={'empty'}
                onChange={(e) => this.onOptionChange(e)}>
                <option key={'empty'} value={0}>
                  {'  '}
                </option>
                {cakeOptionObj.map((elem, key) => {
                  return (
                    <option key={elem.id} value={elem.id}>
                      {geti18nValue(elem.descObj)}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default CakeOptionFilter;
