import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Amounts from '@kopapro/components/cart/amounts/amounts';
import { withTranslation, WithTranslation } from 'react-i18next';

import { getCartAmountDetails, getCartPaymentId } from '@kopapro-redux/selectors/entities/cart';
import { CartAmountDetails } from '@kopapro-redux/types/cart';
import { withNavigation } from '@kopapro/components/commons/router-hoc';
import { getDisplayCurrency } from '@kopapro-redux/selectors/entities/currency';
import { ShopCurrencySetup } from '@kopapro-redux/types/shop';
import { PaymentMethod } from '@kopapro-redux/types/paymentMethod';
import { getPaymentMethodList } from '@kopapro-redux/selectors/entities/paymentMethods';

interface IProps extends WithTranslation {
  navigate: any;
}

interface StateProps {
  currency?: ShopCurrencySetup;
  amountDetails?: CartAmountDetails;
  paymentId: number;
  paymentMethods: PaymentMethod[];
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    currency: getDisplayCurrency(state),
    amountDetails: getCartAmountDetails(state),
    paymentId: getCartPaymentId(state),
    paymentMethods: getPaymentMethodList(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type AmountsProps = IProps & StateProps & DispatchProps;
export default withNavigation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Amounts)));
