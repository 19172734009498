import { Component } from 'react';
import { CustomCssProps } from '@kopapro/components/misc/themeBuilder';
import utils from '@kopapro-redux/utils/utils';
import webUtil from '@kopapro/utils/utils';
import { resolveImageURL } from '@kopapro/utils/m18';
import { isM18PreviewMode } from '@kopapro-redux/utils/m18';
import { BasicStyle } from '@kopapro-redux/types/componentSetting';

export default class ThemeBuilder extends Component<CustomCssProps> {
  componentDidMount(): void {
    this.applyCustomCss();
  }

  componentDidUpdate(prevProps: Readonly<CustomCssProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (isM18PreviewMode()) {
      this.applyCustomCss();
    }
  }

  getHeaderCss = (style?: BasicStyle) => {
    if (!style) {
      return ``;
    }
    const { height, fontSize, fontStyle, font, fontColor } = style;
    webUtil.appendFontFamilyLink(style.font);
    return `
    .kpp-header .default-navbar {
      height:  ${height + 'px' || 'unset'};
    }
    .kpp-nav, .kpp-nav.navbar-nav .nav-link  {
      font-size: ${fontSize + 'px' || 'unset'};
      color: ${fontColor || 'unset'};
      text-decoration: ${fontStyle.includes('underline') ? 'underline' : 'inherit'};
      font-style: ${fontStyle.includes('italic') ? 'italic' : 'inherit'};
      font-weight: ${fontStyle.includes('bold') ? 'bold' : 'inherit'};
      font-family: ${font && font !== 'none' ? style.font : 'inherit'};
    }
    .kpp-nav.navbar-nav .nav-link svg {
      font-size: ${style.fontSize + 'px' || 'unset'};
    }
    `;
  };

  applyCustomCss = () => {
    const { css, enableCSS, backgroundImage, mobileBackgroundImage, desktopStyle, mobileStyle } = this.props;

    // custom CSS
    const customCssStyleId = 'custom-css';
    webUtil.removeStyleElement(customCssStyleId);
    if (enableCSS) {
      const style = document.createElement('style');
      style.id = customCssStyleId;
      style.innerHTML = css;
      webUtil.appendStyleElement(style);
    }

    // custom ho
    const customThemeStyleId = 'custom-theme';
    webUtil.removeStyleElement(customThemeStyleId);
    if (utils.isNotEmpty(backgroundImage) || utils.isNotEmpty(mobileBackgroundImage)) {
      const style = document.createElement('style');
      style.id = customThemeStyleId;
      const elementId = 'app-content';
      let css = `
            #${elementId} {
                flex-grow: 1;
                height:100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }
        `;
      if (utils.isNotEmpty(backgroundImage)) {
        css += `
            @media (min-width: 576px) { 
                #${elementId} {
                    background-image:url(${resolveImageURL(backgroundImage!)})
                }
            }
        `;
      }

      if (utils.isNotEmpty(mobileBackgroundImage)) {
        css += `
            @media (max-width: 575.98px) { 
                #${elementId} {
                    background-image:url(${resolveImageURL(mobileBackgroundImage!)})
                }
            }
        `;
      }
      style.innerHTML = css;
      webUtil.appendStyleElement(style);
    }

    const customDesktopStyleId = 'custom-desktop-header';
    webUtil.removeStyleElement(customDesktopStyleId);
    if (utils.isDefined(desktopStyle)) {
      const style = document.createElement('style');
      let css = `
          @media (min-width: 576px) { 
            ${this.getHeaderCss(desktopStyle)}
        }
      `;
      style.id = customDesktopStyleId;
      style.innerHTML = css;
      webUtil.appendStyleElement(style);
    }

    const customMobileStyleId = 'custom-mobile-header';
    webUtil.removeStyleElement(customMobileStyleId);
    if (utils.isDefined(mobileStyle)) {
      const style = document.createElement('style');
      let css = `
          @media (max-width: 575.98px) { 
            ${this.getHeaderCss(mobileStyle)}
        }
      `;
      style.id = customMobileStyleId;
      style.innerHTML = css;
      webUtil.appendStyleElement(style);
    }
  };

  render() {
    return null;
  }
}
